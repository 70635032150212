import React from "react";

const Box = ({label, children, className, ...rest }) => {
  return (
    <section className={'box ' + (className ? className : '')} {...rest}>
        <div className='box-title'>{label}</div>
        <section className='box-content'>
            {children}
        </section>
    </section>
  );
};

export default Box;
