/*
 *  @2019 cscore.dms project.
 *
 */

import APIServiceFile from "./ApiServiceFile";
import {API_DMS_URI } from "../config";

export default class Api_dms {

    // UploadFile = async ({token, data} = {}) => {
    //     return fetch(API_DMS_URI + 'upload', {
    //     method: 'POST',
    //     headers: {Authorization: "Bearer " + token},
    //     body: data
    //     }).then(response => { return response.json()})
    // }

    UploadFile = async ({ token, data } = {}) => {
        return new APIServiceFile({ token: token }).postDataUp({
          path: `upload`,
          project: "cscore.dms",
          data: data,
        });
      }

    DownloadFile = async ({ token, data, downLoad} = {}) => {
        return new APIServiceFile({ token: token }).postDataDown({
          path: `download`,
          project: "cscore.dms",
          data: data,
          downLoad: downLoad
        });
    }
    // DownloadFile = async ({token, data} = {}) => {
    //     var downFile = true;
    //     if(data && data.filename){
    //         var ls = data.filename.split(".")
    //         if(ls && ls.length > 0 && ls[ls.length - 1].toUpperCase() === "PDF")downFile = false;
    //     }
    //     if(downFile){
    //         return fetch(API_DMS_URI + 'download', {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "Bearer " + token
    //             },
    //             responseType: 'blob',
    //             body: JSON.stringify(data),
    //         })
    //         .then((response) => {return response.blob()})
    //         .then((blob) => {
    //             const url = window.URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', data.filename);
    //             document.body.appendChild(link);
    //             link.click();
    //         })
    //     }
    //     else{
    //         return fetch(API_DMS_URI + 'download', {
    //             method: 'POST',
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "Bearer " + token
    //             },
    //             responseType: 'blob',
    //             body: JSON.stringify(data),
    //         })
    //         .then(response => {
    //             return response.arrayBuffer()
    //         })
    //         .then(buffer => {
    //             var blob2 = new Blob([buffer], {type: 'application/pdf'});
    //             var blobURL = URL.createObjectURL(blob2);
    //             window.open(blobURL);
    //         })
    //     }
    // }

}