import APIService from "./APIService";

export default class Api_spatial {

   //TODO:[Common] Get spatial huyen
   GetSpatialHuyens = async ({ token, tenhuyen } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialhuyen?tenhuyen=${tenhuyen}`,
      project: "cscore.spatial"
    });
  };

    //TODO:[Common] Get spatial tinh
    GetSpatialTinhs = async ({ token, tentinh } = {}) => {
      return new APIService({ token: token }).getData({
        path: `TNNSpatial/getspatialtinh?tentinh=${tentinh}`,
        project: "cscore.spatial"
      });
    };


    GetSpatialSearchApis = async ({ token = "", searchText } = {}) => {
      return new APIService({ token: token }).getData({
        path: `https://nominatim.openstreetmap.org/search/${searchText}?format=json&limit=1`,
        project: "others"
      });
    };

  GetSpatialXas = async ({ token, tenxa } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialxa?tenxa=${tenxa}`,
      project: "cscore.spatial"
    });
  };
  GetSpatialThuocDVHC = async ({ token, tenhuyen, tentinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialthuocdvhc?tenhuyen=${tenhuyen}&tentinh=${tentinh}`,
      project: "cscore.spatial"
    });
  };

  //TODO
  GetSpatialClickInfor = async ({ token, geom} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialclickinfor?geom=${geom}`,
      project: "cscore.spatial"
    });
  };
  //
  GetSpatialCTByGP = async ({ token, idcongtrinh, loaicongtrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialctbygp?idcongtrinh=${idcongtrinh}&loaicongtrinh=${loaicongtrinh}`,
      project: "cscore.spatial"
    });
  };

  GetLVS_SC = async ({ token} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getdslvs`,
      project: "cscore.spatial"
    });
  };
  GetSpatialCTThuocLVS = async ({ token, idsong} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialctthuoclvs?idsong=${idsong}`,
      project: "cscore.spatial",
      data: idsong
    });
  };

  GetSpatialDXTThuocLVS = async ({ token, idsong} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialdxtthuoclvs?idsong=${idsong}`,
      project: "cscore.spatial",
      data: idsong
    });
  };

  GetSpatialLVSs = async ({ token, idlvsong} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatiallvs?gid=${idlvsong}`,
      project: "cscore.spatial"
    });
  };
  
  GetSpatialKTTVs = async ({ token} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialkttv`,
      project: "cscore.spatial"
    });
  };
  GetSpatialKKs = async ({ token} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialkk`,
      project: "cscore.spatial"
    });
  };
  // TODO: Danh sách song theo cap
  GetDSSong = async ({ token, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getdssong`,
      data: {strSearch},
      project: "cscore.spatial"
    });
  };
  // TODO: Thong tin song
  GetSpatialSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };
  // TODO: Thong tin cong trinh thuoc song
  GetSpatialCTThuocSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialctthuocsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };
  // TODO: Thong tin cong trinh thuoc song
  GetSpatialThuocLVS = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialthuoclvs`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };
  // TODO: Kiểm tra vùng trên bản dồ
  CheckCongTrinhVung = async ({ token, toado, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNNSpatial/checkvung?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}&toado=${toado}`,
      project: "cscore.spatial"
    });
  };
  // TODO: Kiểm tra điểm trên bản dồ
  CheckCongTrinhDiem = async ({ token, toado, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNNSpatial/checkdiem?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}&toado=${toado}`,
      // data: toado,
      project: "cscore.spatial"
    });
  };

  // TODO: Thong tin spatial thuoc song
  GetSpatialThuocSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialthuocsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };

  GetSpatialDXTThuocSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialdxtthuocsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };

   // TODO: Thong tin cong trinh thuoc song
   GetSpatialCT = async ({ token, idcongtrinh,loaicongtrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialct`,
      data: {idcongtrinh,loaicongtrinh},
      project: "cscore.spatial"
    });
  };
  // TODO: Thong tin cong trinh thuoc song
  GetSpatialHM = async ({ token, idhangmuc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialhm`,
      data: {idhangmuc},
      project: "cscore.spatial"
    });
  };
  GetSpatialDXT = async ({ token, iddiemxathai } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialdxt`,
      data: {iddiemxathai},
      project: "cscore.spatial"
    });
  };
  GetSpatialDQT = async ({ token, iddiemquantrac } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialdqt`,
      data: {iddiemquantrac},
      project: "cscore.spatial"
    });
  };
  GetSpatialTQT = async ({ token, idtramquantrac } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialtqt`,
      data: {idtramquantrac},
      project: "cscore.spatial"
    });
  };

    // TODO: Thong tin cong trinh thuoc song
    GetSpatialGieng = async ({ token, idgieng } = {}) => {
      return new APIService({ token: token }).getData({
        path: `TNNSpatial/getspatialgieng`,
        data: {idgieng},
        project: "cscore.spatial"
      });
    };

    // TODO: Thong tin gieng thuoc song
    GetSpatialGiengThuocSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialgiengthuocsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };
  // TODO: Thong tin hang muc thuoc song
  GetSpatialHMThuocSong = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialhmthuocsong`,
      data: {idsong},
      project: "cscore.spatial"
    });
  };
  // TODO: Tọa độ điểm QT
  LuuDiemQuanTrac = async ({ token, obj, ktt_do, ktt_phut, muichieu} = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNNSpatial/diemquantrac?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}`,
      data: obj,
      project: "cscore.spatial"
    });
  };
  // TODO: Xóa toa do diem QT
  xoageodiemQT = async ({ token, iddiemquantrac, phanphanloaiquantrac } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNNSpatial/xoadiemquantrac?iddiemquantrac=${iddiemquantrac}&phanphanloaiquantrac=${phanphanloaiquantrac}`,
      project: "cscore.spatial"
    });
  };
  // TODO: Tọa độ tram QT
  LuuQuanTracDiem = async ({ token, obj, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QuanTracSpatial/luuquantracdiem?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}`,
      data: obj,
      project: "cscore.spatial"
    });
  };
  // TODO: Xóa toa do tram QT
  xoageoTramQT = async ({ token, idtramquantrac, phanphanloaiquantrac } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNNSpatial/xoatramquantrac?idtramquantrac=${idtramquantrac}&phanphanloaiquantrac=${phanphanloaiquantrac}`,
      project: "cscore.spatial"
    });
  };

  LuuQuanTracTNNDiem_Temp = async ({ token, obj, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token }).postData({
      path: `QuanTracSpatial/luuquantractnndiem_temp?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}`,
      data: obj,
      project: "cscore.spatial"
    })
  }

  LuuQuanTracTNNDiem = async ({ token, obj, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token }).postData({
      path: `QuanTracSpatial/luuquantractnndiem?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}`,
      data: obj,
      project: "cscore.spatial"
    })
  }

   post_CTDiem = async ({token, point_CT, degree, minute, muiChieu} = {}) => {
      return new APIService({token}).postData({
        path: `TNNSpatial/congtrinhdiem?ktt_do=${degree}&ktt_phut=${minute}&muichieu=${muiChieu}`,
        data: point_CT,
        project: "cscore.spatial"
      })
   };

   post_CTVung = async ({token, area_CT, degree, minute, muiChieu} = {}) => {
      return new APIService({token}).postData({
        path: `TNNSpatial/congtrinhvung?ktt_do=${degree}&ktt_phut=${minute}&muichieu=${muiChieu}`,
        data: area_CT,
        project: "cscore.spatial"
      })
   }

   GetSpatialCT_BySong = async ({ token, congtrinh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNNSpatial/getspatialctbysong`,
      data: congtrinh,
      project: "cscore.spatial"
    });
  };

  LuuDiemHo = async ({ token, obj, ktt_do, ktt_phut, muichieu } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNNSpatial/luudiemho?ktt_do=${ktt_do}&ktt_phut=${ktt_phut}&muichieu=${muichieu}`,
      data: obj,
      project: "cscore.spatial"
    });
  };
  GetSpatialDiemHo = async ({ token, idho } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNNSpatial/getspatialdiemho?idho=${idho}`,
      project: "cscore.spatial"
    });
  };
  //TODO: [Common] Put kinh tuyen truc
  TransformWgs84ToVn2000 = async ({ token, kttDo, kttPhut, cap, geoastext } = {}) => {
    return new APIService({ token: token }).postData({
        path: `TNNSpatial/transformwgs84tovn2000`,
        data: { kttDo, kttPhut, cap, geoastext },
        project: "cscore.spatial"
    });
  };
}