import React, {Component} from "react";
import {connect} from 'react-redux'
import {Row} from 'antd';

//component
import ViewTable from '../../../Table/ViewTable'
import typo, {MESSAGE} from '../../../../constants/dictionaries'

//helper
import CT_GiayPhepCongTrinh from '../../../../models/CT_GiayPhepCongTrinh'
import helper from "../../../../helper/helper";

const columns = () =>
  [
    // {
    //   title: "#",
    //   dataIndex: "stt",
    //   width: "45px",
    //   align: 'center'
    // },
    {
      title: "Số giấy phép",
      dataIndex: "soVanBan",
    },
    {
      title: typo.cscore_tnn_license_signed_person,
      dataIndex: "nguoiKy",
    },
    {
      title: typo.cscore_tnn_license_available_date,
      dataIndex: "ngayBDHieuLuc",
      render: (value, row) => (helper.formatTableDate(row.ngayCapPhep)),
    },
    {
      title: typo.cscore_tnn_license_available_end_date,
      dataIndex: "ngayKTHieuLuc",
      render: (value, row) => (helper.formatTableDate(row.ngayCapPhep)),
    },
    {
      title: "Thời hạn",
      dataIndex: "thoiHan",
    },
  ];

class ThamSoHC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      selectedRow: null,
    }
  }

  handleShowDetail = (row) => {
    this.setState({selectedRow: row, isShowModal: true})
  };

  handleCloseModal = () => {
    this.setState({isShowModal: false})
  };

  render() {
    const {isShowModal, selectedRow} = this.state;
    const {dataSource = []} = this.props;

    return (
      <Row>
        <ViewTable
          locale={{emptyText: MESSAGE.cscore_tnn_THERE_IS_NO_DATA}}
          columns={columns({handleShowDetail: this.handleShowDetail})}
          dataSource={helper.addSttToDataSource(dataSource)} pagination={false}
        />
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(ThamSoHC);