import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const { Title } = Typography;

// Components
const BieuMauSo0203 = lazy(() => import('./BieuMauSo0203'));
const BieuMau0205 = lazy(() => import('./BieuMau0205'));
const BieuMau0206 = lazy(() => import('./BieuMau0206'));


export default ({ match }) => {
  const groupName = 'Biểu mẫu thông tư 20/2018/TT-BTNMT'
  
  const BieuMauSo0203Link = 'bieu-mau-so-0203'
  const BieuMau0205Link = 'bieu-mau-0205'
  const BieuMau0206Link = 'bieu-mau-0206'


  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
      
        <Route exact path={`${match.path}/${BieuMauSo0203Link}`} render={(props) => <BieuMauSo0203 {...props} path={`${match.path}/${BieuMauSo0203Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo0203} />} />
        <Route exact path={`${match.path}/${BieuMau0205Link}`} render={(props) => <BieuMau0205 {...props} path={`${match.path}/${BieuMau0205Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMau0205} />} />
        <Route exact path={`${match.path}/${BieuMau0206Link}`} render={(props) => <BieuMau0206 {...props} path={`${match.path}/${BieuMau0206Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMau0206} />} />
      
      </Switch>
    </Suspense>
  )
}