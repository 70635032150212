//common
import React from "react";
// Components
import RowTools from "../../../Table/RowTools";
import { Link } from "react-router-dom";
const columns = ({handleEdit, handleDelete} = {}) =>
  [
    {
      title: "#",
      dataIndex: "stt",
      width: "65px",
      align: 'center'
    },
    {
      title: "Tọa độ X",
      dataIndex: "x",
    },
    {
      title: "Tọa độ Y",
      dataIndex: "y",
    },
    
    {
        key: "tools",
        // title: "Thao tác",
        dataIndex: "tools",
        width: "70px",
        render: (value, row) => (
        // <RowTools row={{row, action: "edit"}} showEditModal={() => handleEdit(row)} deleteRowModal={() => {}} iconEdit={true}
        <RowTools row={{row, action: "edit"}} showEditModal={() => handleEdit(row)} deleteRowModal={() => {}}
          popconfirmInfor={{
            placement: 'top',
            title: "Gỡ tạm thời tọa độ này ?",
            okText: "Gỡ",
            cancelText: "Hủy",
            onConfirm: () => handleDelete(row.stt)
          }}
        />)
      },
  ].map(col => ({ ...col}));

export default columns;
