/*
 *  @2019 Cs-core project
 *
 */

import queryString from "query-string";
import { ErrorHandlingLS } from "../lightState/ErrorHandling";
import { cachingMiddleware } from "./CachingMiddleware";
import { add as addLoading } from "../lightState/Loader";
import { getCCU } from "./ccuServices";
import Log from "../services/log";
import typo, { MESSAGE } from '../constants/dictionaries'
import message from "../components/Messages/";
import { API_CORE_URI, API_TNN_URI, API_DMS_URI, API_SPATIAL_URI } from "../config";

const baseUri = API_CORE_URI;

export class HttpException extends Error {
  constructor({ message, status, statusText, url, ErrorCode, data } = {}) {
    super(message);
    this.pureMessage = message;
    this.status = status;
    this.statusText = statusText;
    this.url = url;
    this.ErrorCode = ErrorCode;
    this.data = data;
  }
}

function throwError(status, method) {
  const _err = {
    message: "Internal server error.",
    ErrorCode: status,
    status: status,
    statusText: `HttpException[${method}]`
  };
  ErrorHandlingLS.setState({ error: _err });
  throw new HttpException({ ..._err });
}

function throwError404(status, method) {
  const _err = {
    message: "Internal server error.",
    ErrorCode: status,
    status: status,
    statusText: `HttpException[${method}]`
  };
  ErrorHandlingLS.setState({ error: _err });
  throw new HttpException({ ..._err });
}

export default class APIService {
  constructor({ token, ccuConfig }) {
    this.baseUri = baseUri || "";
    this.credentials = token && token.length > 0 ? token : null;
    this.ccuConfig = ccuConfig;
    this.headersBuilder = newToken => {
      const token = newToken || this.credentials;
      const headers = token
        ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
        : {
          "Content-Type": "application/json"
        };
      if (process.env.NODE_ENV !== "development") {
        delete headers["Ocp-Apim-Subscription-Key"];
      }
      return headers;
    };
  }

  // callAPI({
  //   method,
  //   path,
  //   data,
  //   isRefreshTokenApi = false,
  //   isRequestAfterRefreshToken = false,
  //   newToken = null,
  //   project = "cscore"
  // }) {
  //   const removeLoading = addLoading();
  //   let body = data;
  //   if (this.ccuConfig && this.ccuConfig.get) {
  //     let ccuData = getCCU(this.ccuConfig.get);
  //     body = { ...body, ...ccuData };
  //   }
  //   switch (project) {
  //     case "cscore.tnn":
  //       path = API_TNN_URI + path;
  //       break;
  //     case "cscore.dms":
  //       path = API_DMS_URI + path;
  //       break;
  //     case "cscore.spatial":
  //       path = API_SPATIAL_URI + path;
  //       break;
  //     case "others":
  //       path = path;
  //       break;
  //     default:
  //       path = baseUri + path;
  //   }
  //   return fetch(path, {
  //     method: method,
  //     mode: "cors",
  //     headers: this.headersBuilder(newToken),
  //     body: data !== null ? JSON.stringify(body) : null
  //   })
  //     .then(response => {
  //       debugger
  //       removeLoading();
  //       if (!response) {
  //         ErrorHandlingLS.setState({
  //           error: {
  //             message: "Server maintaine.",
  //             ErrorCode: 503,
  //             status: 503,
  //             statusText: `HttpException[${method}]`
  //           }
  //         });
  //       }
  //       // if response is ok transform response.text to json object
  //       // else throw error
  //       if (response.ok) {
  //         if (response.status === 299) {
  //           return response.json().then(err => {
  //             throw new Error(err && err.message)
  //           })
  //         }
  //         return response.json();
  //       } else if (response.status === 401) {
  //         message.error(MESSAGE.cscore_tnn_ERROR)
  //         // TODO: handle logout
  //       } else if (response.status === 500) {
  //         message.error(MESSAGE.cscore_tnn_ERROR)
  //         // TODO: handle logout
  //       } else {
  //         // throw new Error(MESSAGE.cscore_tnn_ERROR)
  //         message.error(MESSAGE.cscore_tnn_ERROR)
  //       }
  //     })
  //     .then(jsonData => {
  //       if (!jsonData) {
  //         // throw new Error(MESSAGE.cscore_tnn_ERROR);
  //       } else {
  //         /*TODO: Concurrent users*/
  //         new Log().info("APIService successful at ", jsonData);
  //         return jsonData.result;
  //       }
  //     });
  // }
  callAPI({
    method,
    path,
    data,
    isRefreshTokenApi = false,
    isRequestAfterRefreshToken = false,
    newToken = null,
    project = "cscore"
  }) {
    const removeLoading = addLoading();
    let body = data;
  
    // Add CCU data to the request body if applicable
    if (this.ccuConfig && this.ccuConfig.get) {
      const ccuData = getCCU(this.ccuConfig.get);
      body = { ...body, ...ccuData };
    }
  
    // Determine the base URI based on the project
    switch (project) {
      case "cscore.tnn":
        path = API_TNN_URI + path;
        break;
      case "cscore.dms":
        path = API_DMS_URI + path;
        break;
      case "cscore.spatial":
        path = API_SPATIAL_URI + path;
        break;
      case "others":
        break; // Keep path as is
      default:
        path = baseUri + path;
    }
  
    // Fetch API call
    return fetch(path, {
      method: method,
      mode: "cors",
      headers: this.headersBuilder(newToken),
      body: data !== null ? JSON.stringify(body) : null
    })
    .then(response => {
      removeLoading();
  
      if (!response) {
        ErrorHandlingLS.setState({
          error: {
            message: "Server maintenance.",
            ErrorCode: 503,
            status: 503,
            statusText: `HttpException[${method}]`
          }
        });
        return Promise.reject(new Error("Server maintenance."));
      }
  
      if (response.ok) {
        if (response.status === 299) {
          return response.json().then(err => {
            throw new Error(err && err.message);
          });
        }
        return response.json();
      } else if (response.status === 401 || response.status === 500) {
        message.error(MESSAGE.cscore_tnn_ERROR);
        // TODO: handle logout
        return Promise.reject(new Error(MESSAGE.cscore_tnn_ERROR));
      } else {
         message.error(MESSAGE.cscore_tnn_ERROR);
         return Promise.reject(new Error(MESSAGE.cscore_tnn_ERROR));
      }
    })
    .then(jsonData => {
      if (!jsonData) {
        return Promise.reject(new Error(MESSAGE.cscore_tnn_ERROR));
      } else {
        new Log().info("APIService successful at ", jsonData);
        return jsonData.result;
      }
    })
    .catch(error => {
      console.error("API call failed: ", error);
      throw error;
    });
  }
  
  getData({ path, data = {}, caching = false, cacheTime = 30000, project }) {
    const paramsString = queryString.stringify({
      ...data
    });

    const fullpath = path + (paramsString === "" ? "" : "?" + paramsString);
    if (caching) {
      return cachingMiddleware({
        pathName: fullpath,
        fetchApi: () =>
          this.callAPI({ method: "GET", path: fullpath, data: null }),
        expireTime: cacheTime
      });
    }
    return this.callAPI({ method: "GET", path: fullpath, data: null, project });
  }

  deleteData({ path, data, project }) {
    return this.callAPI({
      path,
      method: "DELETE",
      data,
      project
    });
  }

  postData({ path, data, isRefreshTokenApi, project }) {
    return this.callAPI({
      path,
      method: "POST",
      data,
      isRefreshTokenApi,
      project
    });
  }

  putData({ path, data, project }) {
    return this.callAPI({
      path,
      method: "PUT",
      data,
      project
    });
  }
}
