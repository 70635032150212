import React, { Component } from 'react'
import { Col, Row, Icon } from "antd";
import typo from "../../constants/dictionaries";
import BarChart from './bar'
import helper from '../../helper/helper';
import Select from "../../components/Common/Select";
import { constant, values } from 'lodash';
import Api from '../../api';
import { connect } from 'react-redux'
import message from "../../components/Messages"
import Constant from '../../constants/constant';
import Button from '../../components/Buttons'
import Api_tnn from '../../api/api.tnn';
import messages from '../../components/Messages'
import moment from 'moment'
class BoxChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DL: [],
            data_huyen: [],
            madvhc: "",
            from: moment(new Date().setMonth(new Date().getMonth() - 12)),
            to: moment(new Date()),
        }
    }
    componentDidMount() {
        this.setState({
            DL: this.props.data
        })
        this.getApiHuyenByTinhs(Constant.MA_TINH);
        this.getYears();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.data !== prevProps.data) {
            {
                this.setState({
                    DL: this.props.data
                })
            }
        }
    };

    getApiTinhs = () => {
        new Api()
            .getTinhs({ token: this.props.user.access_token, })
            .then(res => { this.setState({ data_tinh: res }); })
            .catch(err => {
                message.error(err.message)
            });
    };
    getApiHuyenByTinhs = (maTinh) => {
        new Api()
            .getHuyenByTinhs({
                token: this.props.user.access_token,
                maTinh: maTinh
            })
            .then(res => { this.setState({ data_huyen: res }) })
            .catch(err => {
                message.error(err.message)
            });
    };

    getYears = () => {
        var c_year = new Date().getFullYear(); var years = []; var nam = [];
        for (var i = c_year + 20; i >= 1975; i--) { years.push({ year: i }) }
        for (var i = c_year; i >= 1975; i--) { nam.push({ year: i }) }

        this.setState({ nam: nam, years: years, selectedFromYear: c_year - 14, selectedToYear: c_year })
    }

    groupDataByKey = (data) => {
        var outData = []; var valuesOfKey = []
        data.map(item => {
            if (valuesOfKey.includes(item.time)) {
                outData.filter((dataItem, index) => {
                    if (dataItem.time === item.time) { outData[index][item.id] = item.value }
                })
            } else {
                if (item.time) {
                    var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
                    outData.push(outDataItem); valuesOfKey.push(item.time)
                }
            }
        })
        return outData
    }

    ThongKe = () => {
        var inputs = {
            madvhc: this.state.madvhc,
            from: this.state.selectedFromYear + "-01-01",
            to: this.state.selectedToYear + "-12-31",
            chart: "thay_doi",
            obj: "giay_phep",
            group: "nhom_giay_phep",
            phase: "theo_thoi_gian",
            period: "nam"
        }
        new Api_tnn().statistic({ token: this.props.user.access_token, params: inputs })
            .then((result) => {
                if (result) {
                    this.setState({
                        DL: this.groupDataByKey(result)
                    })
                } else { messages.error("Lấy dữ liệu lỗi") }
            })
            .catch((err) => { messages.error("Lấy dữ liệu lỗi") })
    }

    render() {
        const { data_huyen, years, nam, selectedFromYear, selectedToYear, madvhc, DL } = this.state
        const { functions, history, } = this.props
        return (
            <div className='box-chart' >
                <div className='box-chart-title'>{this.props.title}</div>
                <Row type='flex' gutter={16}>
                    <Col style={{ marginLeft: '10px' }} >
                        <Select
                            mandatory={false} label="Từ năm"
                            onChange={value => this.setState({ selectedFromYear: value })}
                            dataSource={nam}
                            name="year"
                            width="100px"
                            name_value="year"
                            defaultValue={selectedFromYear} value={selectedFromYear}
                         />
                            
                    </Col>
                    <Col   style={{ paddingLeft: '10px' }}>
                        <Select
                            mandatory={false} label="Đến năm"
                            onChange={value => this.setState({ selectedToYear: value })}
                            dataSource={years}
                            width="100px"
                            name="year" name_value="year"
                            defaultValue={selectedToYear} value={selectedToYear} />
                    </Col>


                    <Col >
                        <Select
                            mandatory={false}
                            modeSelect="multiple"
                            placeholder={helper.genSelectPL(typo.cscore_tnn_district)}
                            label={typo.cscore_tnn_district}
                            onChange={(value) => {
                                this.setState({
                                    madvhc: value

                                })
                            }}
                            dataSource={data_huyen}
                            width="300px"
                            name="tenDVHC"
                            name_value="id"
                            defaultValue={madvhc ? madvhc : undefined}
                            allowClear={true}
                        />

                    </Col>
                    <Col style={{ marginTop: '15px' }} >
                        <Button onClick={this.ThongKe} className='chart-btn-submit'>
                            <Icon type='bar-chart'></Icon>

                            {typo.cscore_tnn_statistic}</Button>

                    </Col>

                </Row>

                <div className='box-chart-content'>
                    {DL &&
                        <BarChart data={DL} hiddenLegends={false} history={history} MaDVHC={this.state.madvhc} functions={functions}
                        />}

                </div>
            </div>
        )
    }
};


const mapPropsToState = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});
export default connect(mapPropsToState)(BoxChart)