import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Layout, Row, Col, Collapse, Icon, Tooltip } from "antd";
import Select from "../../components/Common/Select";
import DateField from '../../components/Common/DateField'
import typo from "../../constants/dictionaries";
import Container from "../../containers/Container";
import messages from '../../components/Messages'
import Button from '../../components/Buttons'
import BarChart from './bar'
import PieChart from './pie'
import BoxChart from './boxChart'
import ContainerTotalStatistic from './containerTotalStatistic'
import helper from '../../helper/helper';
import { MESSAGE } from "../../constants/dictionaries";
import Clock from 'react-live-clock';
import Api_tnn from '../../api/api.tnn';
import Api from '../../api';
import Helper from '../../helper/helper'
import Constant from '../../constants/constant';
import Message from "../../components/Messages";

import * as ActionTypes from "../../reducers/actions/action-type"
const { Panel } = Collapse;
let date = moment().locale("vi").format('L');
let day = moment().locale("vi").format('dddd');
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChartTitle: false,
      chartTitle: undefined,
      data: [],
      giayPhephData: [],
      chartType: "ty_le",
      objects: doiTuongs, selectedObject: "cong_trinh",
      groups: congTrinhs, selectedGroup: "cong_trinh_nuoc_mat",
      units: congTrinhNuocMats, selectedUnit: undefined,
      charts: charts, selectedChart: "ty_le",
      phases: phases, selectedPhase: "tat_ca",
      periods: periods, selectedPeriod: undefined,
      loaihinhgp: undefined,
      data_tinh: [],
      madvhc: "",
      from: (new Date().getFullYear() - 14) + "-01-01",
      to: new Date().getFullYear() + "-12-31",
      tongSoGiayPhepData: [],
      capnguoidung: 0,
      madvhc_byuser: "1000",
      thoigianthongke: moment(new Date()).format('HH:mm:ss, DD/MM/YYYY')

    }
  }
  componentDidMount() {
    const { profile, tinhs, cauHinhGP } = this.props
    this.getYears();
    if (profile && profile.userName) {
      let resSort = Helper.sortArrayNumber(profile.roles, "cap")
      if (resSort && resSort.length > 0) {
        this.setState({
          capnguoidung: resSort[0].cap,
          madvhc_byuser: profile.maDVHC
        })
        if (resSort[0].cap === Constant.Role_user.LD_CV_BO) {
          this.getGiayPhepData(profile.maDVHC, resSort[0].cap, Constant.Select_Cap[0].id)
        } else if (resSort[0].cap === Constant.Role_user.LD_CV_SO) {
          this.getGiayPhepData(profile.maDVHC, resSort[0].cap, Constant.Select_Cap[2].id)
        } else {
          this.getGiayPhepData(profile.maDVHC, resSort[0].cap, Constant.Select_Cap[0].id)
        }
        this.handleSubmit(resSort[0].cap)
        if (!Helper.isNullOrUndefined(cauHinhGP)) {
          this.GetCauHinhs(resSort[0].cap, profile.maDVHC, cauHinhGP, 0);
        }
      }
    }
    if (!Helper.isNullOrUndefined(tinhs)) {
      this.setState({ data_tinh: tinhs })
    }
  }
  reload_tongso = () => {
    const { capnguoidung, madvhc_byuser } =  this.state
    const { cauHinhGP } = this.props
    this.GetCauHinhs(capnguoidung, madvhc_byuser, cauHinhGP, 1)
  }
  GetCauHinhs = (capnguoidung, madvhc_byuser, cauHinhGP, cache) => {
    let shhl_xt = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.XT).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.XT)[0].giaTriCauHinh : 120
    let shhl_nm = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NM).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NM)[0].giaTriCauHinh : 120
    let shhl_nb = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NB).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NB)[0].giaTriCauHinh : 120
    let shhl_ndd_kt = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_KT).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_KT)[0].giaTriCauHinh : 120
    let shhl_ndd_td = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_TD).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_TD)[0].giaTriCauHinh : 120
    let shhl_hn = cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.HN).length > 0 ? cauHinhGP.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.HN)[0].giaTriCauHinh : 120
    this.tongso_gp(shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn, capnguoidung, madvhc_byuser, cache)
  };

  tongso_gp = (shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn, capnguoidung, madvhc_byuser, cache) => {
    new Api_tnn().tongso_gp({
      token: this.props.user.access_token,
      cache: cache,
      shhl_xt: shhl_xt,
      shhl_nm: shhl_nm,
      shhl_nb: shhl_nb,
      shhl_ndd_kt: shhl_ndd_kt,
      shhl_ndd_td: shhl_ndd_td,
      shhl_hn: shhl_hn,
      capnguoidung: capnguoidung,
      madvhc: madvhc_byuser
    }).then((result) => {
      if (result) {
        this.setState({
          thoigianthongke: moment(result.thoigiancache * 1000).format('HH:mm:ss, DD/MM/YYYY'),
          tongSoGiayPhepData: [[
            { indicator: Constant.Ten_GP_Dashboard.GP_XT, backgroundColor: "#F49917", title: 'XẢ THẢI', value: result ? result.count_xnt : 0, value_dp: result.count_xnt_dp, value_bo: result.count_xnt_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_xnt : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_xnt_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? result.count_xnt_chuapheduyet : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_xnt : 0, image: '', bordercolor: '#ecae4f' },
            { indicator: Constant.Ten_GP_Dashboard.GP_NM, backgroundColor: "#6ab3e6", title: 'NƯỚC MẶT', value: result ? result.count_nm : 0, value_dp: result.count_nm_dp, value_bo: result.count_nm_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_nm : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_nm_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? result.count_nm_chuapheduyet : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_nm : 0, image: '', bordercolor: '#27c24c' },
            { indicator: Constant.Ten_GP_Dashboard.GP_NB, backgroundColor: "#003d7e", title: 'NƯỚC BIỂN', value: result ? result.count_nb : 0, value_dp: result.count_nb_dp, value_bo: result.count_nb_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_nb : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_nb_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? result.count_nb_chuapheduyet : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_nb : 0, image: '', bordercolor: '#5d9cec' },
            { indicator: Constant.Ten_GP_Dashboard.GP_NDDKT, backgroundColor: "#7d5f3a", title: 'KHAI THÁC NƯỚC DƯỚI ĐẤT', value: result ? result.count_ndd_kt : 0, value_dp: result.count_ndd_kt_dp, value_bo: result.count_ndd_kt_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_ndd_kt : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_ndd_kt_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? result.count_ndd_kt_chuapheduyet : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_ndd_kt : 0, image: '', bordercolor: '#61cdbb' },
            { indicator: Constant.Ten_GP_Dashboard.GP_NDDTD, backgroundColor: "#00b297", title: 'THĂM DÒ NƯỚC DƯỚI ĐẤT', value: result ? result.count_ndd_td : 0, value_dp: result.count_ndd_td_dp, value_bo: result.count_ndd_td_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_ndd_td : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_ndd_td_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? result.count_ndd_td_chuapheduyet : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_ndd_td : 0, image: '', bordercolor: '#dcacacc4' },
            { indicator: Constant.Ten_GP_Dashboard.GP_HN, backgroundColor: "#dc3545", title: 'HÀNH NGHỀ KHOAN NƯỚC', value: result ? result.count_hanhnghe : 0, value_dp: result.count_hanhnghe_dp, value_bo: result.count_hanhnghe_bo, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_hanhnghe : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_hanhnghe_hhl : 0, indicator_chopheduyet: 'Chưa phê duyệt', value_cpd: result ? 0 : 0, image: '', indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_hanhnghe : 0, bordercolor: '#e29f6aeb' }
          ]]
        })
      }
      else { messages.error("Lấy dữ liệu lỗi") }
    })
  }

  getGiayPhepData(maDVHC, capnguoidung, capthamquyen) {
    new Api_tnn().statistic({
      token: this.props.user.access_token,
      obj: 'giay_phep', group: undefined, unit: undefined, chart: 'thay_doi', period: 'nam', loaihinhgp: undefined, madvhc: maDVHC === "1000" ? "" : maDVHC,
      capnguoidung: capnguoidung,
      phase: 'theo_thoi_gian',
      from: (new Date().getFullYear() - 14) + "-01-01",
      to: new Date().getFullYear() + "-12-31",
      capthamquyen: capthamquyen
    }).then((result) => {
      if (result) {
        this.setState({ giayPhephData: this.groupDataByKey(result) })
        //this.setState({giayPhephData: this.groupDataByKeyForCongTrinh(result, "Giấy phép công trình nước mặt")})
      }
      else { messages.error("Lấy dữ liệu lỗi") }
    })
  }

  getYears = () => {
    var c_year = new Date().getFullYear(); var years = []; var years_tunam = [];
    for (var i = c_year + 20; i >= 1975; i--) { years.push({ year: i }) }
    for (var i = c_year; i >= 1975; i--) { years_tunam.push({ year: i }) }
    this.setState({ years_tunam: years_tunam, years: years, selectedFromYear: c_year, selectedToYear: c_year })
  }

  selectObject = (value) => { this.setState({ selectedObject: value, selectedGroup: undefined, selectedUnit: undefined, groups: value === "cong_trinh" ? congTrinhs : (value === "giay_phep" ? giayPheps : (value === "quan_trac" ? quanTracs : [])) }) }
  selectGroup = (value) => {
    const { selectedObject } = this.state
    this.setState({ selectedGroup: value, selectedUnit: undefined })
    if (selectedObject === "cong_trinh") { this.setState({ units: value === "cong_trinh_nuoc_mat" ? congTrinhNuocMats : (value === "cong_trinh_nuoc_duoi_dat" ? congTrinhNuocDuoiDats : (value === "cong_trinh_nuoc_xa_thai" ? congTrinhXaThais : (value === "cong_trinh_nuoc_bien" ? congTrinhNuocBiens : []))) }) }
    if (selectedObject === "giay_phep") { this.setState({ units: value === "nhom_giay_phep" ? nhomGiayPheps : [] }) }
    if (selectedObject === "quan_trac") { this.setState({ units: value === "quan_trac_tai_nguyen_nuoc" ? quanTracTaiNguyenNuocs : (value === "quan_trac_khi_tuong_thuy_van" ? quanTracKhiTuongThuyVans : []) }) }
  }

  selectUnit = (value) => {
    const { selectedGroup } = this.state; var unit = undefined
    if (selectedGroup === "cong_trinh_nuoc_mat") { unit = congTrinhNuocMats.filter(item => item.value === value) }
    this.setState({ selectedUnit: value, focusSlice: unit ? unit[0].name : undefined })
  }
  selectChart = (value) => { this.setState({ selectedChart: value, selectedPhase: undefined, selectedPeriod: undefined, from: undefined, to: undefined, phases: value === "thay_doi" ? phaseChanges : ((value === "ty_le") ? phases : []) }) }
  selectPhase = (value) => { this.setState({ selectedPhase: value, selectedPeriod: undefined, from: undefined, to: undefined }) }
  selectPeriod = (value) => { this.setState({ selectedPeriod: value, from: undefined, to: undefined }) }
  selectedHL = (value) => { this.setState({ selectedHL: value }) }
  addRate = (data) => {
    var total = 0; data.map(item => total += (item.value ? parseInt(item.value) : 0))
    if (total > 0) data.map(item => item.id = item.label = item.label + "(" + (Math.round(parseInt(item.value) / total * 100)) + "%)")
    return data
  }

  groupDataByKey = (data) => {

    var outData = []; var valuesOfKey = []
    data.map(item => {
      if (valuesOfKey.includes(item.time)) {


        outData.filter((dataItem, index) => {
          if (dataItem.time === item.time) { outData[index][item.id] = item.value }
        })
      } else {

        if (item.time) {
          var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
          outData.push(outDataItem); valuesOfKey.push(item.time)
        }
      }
    })
    return outData
  }

  groupDataByKeyForCongTrinh = (data, congTrinh) => {
    var outData = []; var valuesOfKey = []
    data.map(item => {
      if (item.id === congTrinh) {
        if (valuesOfKey.includes(item.time)) {
          outData.filter((dataItem, index) => {
            if (dataItem.time === item.time) { outData[index][item.id] = item.value }
          })
        } else {
          if (item.time) {
            var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
            outData.push(outDataItem); valuesOfKey.push(item.time)
          }
        }
      }
    })
    return outData
  }

  validateInput() {
    const { selectedPhase, selectedPeriod, from, to, selectedFromYear, selectedToYear } = this.state
    if (!selectedPhase) { messages.warning("Bạn chưa chọn giai đoạn"); return false }
    if (selectedPhase === "theo_thoi_gian") {
      if (!selectedPeriod) { messages.warning("Bạn chưa chọn chu kỳ"); return false }
      if (selectedPeriod === 'thang') {
        if (!from) { messages.warning("Bạn chưa chọn tháng bắt đầu"); return false }
        if (!to) { messages.warning("Bạn chưa chọn tháng kết thúc"); return false }
      }
      if (selectedPeriod === 'nam') {
        if (!selectedFromYear) { messages.warning("Bạn chưa chọn năm bắt đầu"); return false }
        if (!selectedToYear) { messages.warning("Bạn chưa chọn năm kết thúc"); return false }
      }
    }
    return true;
  }

  handleInput() {
    var inputs = {
      obj: this.state.selectedObject, group: this.state.selectedGroup, loaihinhgp: this.state.loaihinhgp, madvhc: this.state.madvhc,
      unit: this.state.selectedUnit, chart: this.state.selectedChart, period: this.state.selectedPeriod,
      phase: this.state.selectedPhase, from: this.state.from, to: this.state.to
    }
    if (inputs.period === "thang") {
      inputs.from = inputs.from.startOf('month'); inputs.to = inputs.to.endOf('month')
    }
    if (inputs.period === "nam") {
      inputs.from = this.state.selectedFromYear + "-01-01"
      inputs.to = this.state.selectedToYear + "-12-31"
    }
    return inputs
  }

  handleSubmit = (capnguoidung) => {
    if (!this.validateInput()) return
    const params = this.handleInput();
    new Api_tnn().statistic({
      token: this.props.user.access_token,
      chart: params.chart,
      obj: params.obj,
      group: params.group,
      unit: params.unit,
      loaihinhgp: params.loaihinhgp,
      capnguoidung: capnguoidung,
      madvhc: params.madvhc,
      phase: params.phase,
      period: params.period,
      from: params.from,
      to: params.to,
      capthamquyen: 0
    }).then((result) => {
      if (result) {
        this.setState({ data: this.state.selectedChart === "ty_le" ? this.addRate(result) : this.groupDataByKey(result) })
      } else { messages.error("Lấy dữ liệu lỗi") }
    })
    this.setState({ chartType: this.state.selectedChart })
  }

  componentDidUpdate = () => {
    if (this.state.showChartTitle) {
      const input = document.getElementById('chart-to-export');
      Helper.exportPdf(input, this.state.chartType === 'ty_le' ? 'landscape' : 'portrait', "BieuDo.pdf")
      this.setState({ showChartTitle: false })
    }
  }

  exportPdf = () => {
    const { selectedObject, selectedChart, selectedPhase, selectedPeriod, selectedFromYear, selectedToYear, from, to } = this.state
    var title = 'Biểu đồ'
    title += selectedChart === 'ty_le' ? ' tỷ lệ và tống số lượng' : (selectedChart === 'thay_doi' ? ' biến động số lượng' : '')
    title += selectedObject === 'cong_trinh' ? ' công trình' : (selectedObject === 'giay_phep' ? ' giấy phép' : '')
    title += selectedPhase === 'theo_thoi_gian' ? (' từ ' + (selectedPeriod === 'nam' ? selectedFromYear : (selectedPeriod === 'thang' ? from.format('MM/YYYY') : '')) + ' đến ' + (selectedPeriod === 'nam' ? selectedToYear : (selectedPeriod === 'thang' ? to.format('MM/YYYY') : ''))) : ''
    this.setState({ showChartTitle: true, chartTitle: title })
  }

  render() {
    const { showChartTitle, chartTitle, data, madvhc_byuser, data_tinh, giayPhephData, chartType, focusSlice, objects, selectedObject, groups, selectedGroup, units, selectedUnit, charts,
      selectedChart, phases, selectedPhase, periods, selectedPeriod, from, to, years, selectedFromYear, selectedToYear, tongSoGiayPhepData, loaihinhgp, madvhc, years_tunam,
      capnguoidung, thoigianthongke
    } = this.state
    const { functions } = this.props
    return (
      <Layout style={{ height: "100%", backgroundColor: "#eaecef" }}>
        <Content className='pagewrapper-layout' style={{ backgroundColor: "#eaecef" }} >
          <Container className="container-content-dashboard">
            <Row>
              <Col>
                <div style={{ margin: 10, display: "flex", justifyContent: "space-between" }} className="linh-vuc-title">
                  <div style={{ color: "#3c4b79", display: "flex" }}> {`Số liệu thống kê giấy phép được tổng hợp vào lúc  ${thoigianthongke}`}
                    <Tooltip placement="bottom" title="Tổng hợp số liệu thống kê mới nhất">
                      <div style={{ paddingLeft: 5 }} onClick={() => this.reload_tongso()}>
                        <Icon type="reload" className="link" />
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ fontSize: "unset", color: "#3c4b79", paddingLeft: "60px" }}>

                    {day.charAt(0).toUpperCase() + day.slice(1) + ", " + date.charAt(0).toUpperCase() + date.slice(1)},

                    <Clock interval={1000}
                      format={'h:mm:ss'}
                      timezone={'Asia/Ho_Chi_Minh'}
                      ticking={true} />
                    {/* <div style={{ fontSize: 14, fontWeight: 600 }}>{`Số liệu thống kê giấy phép được tổng hợp vào lúc ${thoigianthongke}`}</div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <ContainerTotalStatistic data={tongSoGiayPhepData} history={this.props.history} functions={functions} />
            {giayPhephData && giayPhephData.length > 0 &&
              <Row>
                <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                  <BoxChart
                    title='Số lượng giấy phép công trình được cấp theo năm'
                    data={giayPhephData}
                    history={this.props.history}
                    functions={functions}
                    chartType={this.state.chartType}
                    madvhc_byuser={madvhc_byuser}
                    capnguoidung={capnguoidung}
                  />
                </Col>
              </Row>}
            <hr />
            {data && data.length > 0 && madvhc_byuser === "1000" &&
              <Row type='flex' justify='start' style={{ height: "800px", width: "100%", marginLeft: 10 }}>
                <Col >
                  <Collapse bordered={false} defaultActiveKey={["1"]}>
                    <Panel header={<a >Tùy chọn</a>} key="1" className='detail_collapse_item'>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_chart} mandatory={false}
                          onChange={value => this.selectChart(value)}
                          dataSource={charts} name="name" name_value="value" defaultValue={selectedChart} value={selectedChart} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_object} mandatory={false}
                          onChange={value => this.selectObject(value)}
                          dataSource={objects} name="name" name_value="value" defaultValue={selectedObject} value={selectedObject} />
                      </div>

                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_group} mandatory={false}
                          onChange={value => this.selectGroup(value)}
                          dataSource={groups} name="name" name_value="value" defaultValue={selectedGroup} value={selectedGroup} />
                      </div>
                      <div className='chart-option-select-div'>
                        {selectedChart === "thay_doi" &&
                          <Select
                            mandatory={false}
                            placeholder={helper.genSelectPL(typo.cscore_tnn_province_placeholder)}
                            label={typo.cscore_tnn_province}
                            onChange={(value) => {
                              this.setState({
                                madvhc: value
                              })
                            }}
                            dataSource={data_tinh}
                            width="242px"
                            name="tenDVHC"
                            name_value="id"
                            value={madvhc}
                            allowClear={true}
                          />
                        }
                      </div>

                      {selectedObject === "giay_phep" &&

                        <div className='chart-option-select-div'>
                          <Select className='chart-option-select' label={typo.cscore_tnn_HLGP} mandatory={false} allowClear={true}
                            onChange={(value) => this.setState({ loaihinhgp: value })} defaultValue={loaihinhgp} value={loaihinhgp}
                            dataSource={Constant.HIEU_LUC_GIAY_PHEP_BO} name="ten" name_value="id" />
                        </div>
                      }
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_groupUnit} mandatory={false}
                          onChange={value => this.selectUnit(value)}
                          dataSource={units} name="name" name_value="value" defaultValue={selectedUnit} value={selectedUnit} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_phase} mandatory={false}
                          onChange={value => this.selectPhase(value)}
                          dataSource={phases} name="name" name_value="value" defaultValue={selectedPhase} value={selectedPhase} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' disabled={selectedPhase !== "theo_thoi_gian"} label={typo.cscore_tnn_period} mandatory={false}
                          onChange={value => this.selectPeriod(value)}
                          dataSource={periods} name="name" name_value="value" defaultValue={selectedPeriod} value={selectedPeriod} />
                      </div>
                      {selectedPeriod === "thang" &&
                        <div>
                          <div className='chart-option-select-div'>
                            <DateField type='month' className='chart-option-select' label={typo.cscore_tnn_from} mandatory={false}
                              onChange={(value) => this.setState({ from: value })} defaultValue={from} value={from} />
                          </div>
                          <div className='chart-option-select-div'>
                            <DateField type='month' className='chart-option-select' label={typo.cscore_tnn_to} mandatory={false}
                              onChange={(value) => this.setState({ to: value })} defaultValue={to} value={to} />
                          </div>
                        </div>
                      }
                      {selectedPeriod === "nam" &&
                        <div>
                          <div className='chart-option-select-div'>
                            <Select className='chart-option-select' mandatory={false} label={typo.cscore_tnn_from}
                              onChange={value => this.setState({ selectedFromYear: value })}
                              dataSource={years_tunam} name="year" name_value="year" defaultValue={selectedFromYear} value={selectedFromYear} />
                          </div>
                          <div className='chart-option-select-div'>
                            <Select className='chart-option-select' mandatory={false} label={typo.cscore_tnn_to}
                              onChange={value => this.setState({ selectedToYear: value })}
                              dataSource={years} name="year" name_value="year" defaultValue={selectedToYear} value={selectedToYear} />
                          </div>
                        </div>}
                      <Button onClick={this.handleSubmit} className='chart-btn-submit'><Icon type='bar-chart'></Icon>{typo.cscore_tnn_statistic}</Button>
                      <Button className='mg-r-10 chart-btn-submit' id='anhmd' type="pdf" onClick={this.exportPdf}><Icon type='file-pdf'></Icon>{typo.cscore_tnn_exportPdf}</Button>
                    </Panel>
                  </Collapse>
                </Col>
                <Col id="chart-to-export" span={16} style={{ height: "100%" }}>
                  <Row style={{ height: "55%" }}>
                    <div style={{ height: "100%" }}>{(chartType === "thay_doi") && <BarChart history={this.props.history} functions={functions} data={data} />}{chartType === "ty_le" && <PieChart data={data} focusSlice={focusSlice} />}</div>
                  </Row>
                  {showChartTitle && <Row style={{ height: "5%", width: "100%" }}>
                    <Row type='flex' justify='center' style={{ width: "100%" }}><span className='chart-title'>{chartTitle}</span></Row>
                    <Row type='flex' justify='center' style={{ width: "100%" }}><span>Nguồn: hệ thống quản lý cơ sở dữ liệu tài nguyên nước</span></Row>
                  </Row>}
                </Col>
              </Row>}
          </Container>
        </Content>
        {/* <Footer className='layout-footer'>Cs-core ©2019 Created by Cirensoft team</Footer> */}
      </Layout>

    )
  }
};

const { Content, Footer } = Layout;
const doiTuongs = [{ name: "Công trình", value: "cong_trinh" }, { name: "Giấy phép", value: "giay_phep" }]
const congTrinhs = [{ name: "Công trình nước mặt", value: "cong_trinh_nuoc_mat" }, { name: "Công trình nước dưới đất", value: "cong_trinh_nuoc_duoi_dat" }, { name: "Công trình nước xả thải", value: "cong_trinh_nuoc_xa_thai" }, { name: "Công trình nước biển", value: "cong_trinh_nuoc_bien" }]
const giayPheps = [{ name: "Nhóm giấy phép", value: "nhom_giay_phep" }]
const quanTracs = [{ name: "Quan trắc tài nguyên nước", value: "quan_trac_tai_nguyen_nuoc" }, { name: "Quan trắc khí tượng thủy văn", value: "quan_trac_khi_tuong_thuy_van" }]
const congTrinhNuocMats = [{ name: "Thủy điện", value: "thuy_dien" }, { name: "Hồ chứa", value: "ho_chua" }, { name: "Trạm bơm", value: "tram_bom" }, { name: "Đập", value: "bai_dap" }, { name: "Cống", value: "cong" }, { name: "Trạm cấp nước", value: "tram_cap_nuoc" }, { name: "Nhà máy nước", value: "nha_may_nuoc" }]
const congTrinhNuocDuoiDats = [{ name: "Khai thác", value: "khai_thac" }, { name: "Thăm dò", value: "tham_do" }]
const congTrinhXaThais = [{ name: "Công trình nước xả thải", value: "cong_trinh_nuoc_xa_thai" }]
const congTrinhNuocBiens = [{ name: "Công trình nước biển", value: "cong_trinh_nuoc_bien" }]
const nhomGiayPheps = [{ name: "Giấy phép xả thải", value: "gp_xa_thai" }, { name: "GP khai thác nước mặt", value: "gp_khai_thac_nuoc_mat" }, { name: "GP khai thác nước biển", value: "gp_khai_thac_nuoc_bien" }, { name: "GP khai thác nước dưới đất", value: "gp_khai_thac_nuoc_duoi_dat" },
{ name: "GP thăm dò nước dưới đất", value: "gp_tham_do_nuoc_duoi_dat" }, { name: "GP hành nghề khoan nước dưới đất", value: "gp_hanh_nghe_khoan_nuoc_duoi_dat" }]
const quanTracTaiNguyenNuocs = [{ name: "Điểm quan trắc", value: "diem_quan_trac" }, { name: "Trạm quan trắc nước", value: "tram_quan_trac_nuoc" }]
const quanTracKhiTuongThuyVans = [{ name: "Trạm quan trắc", value: "tram_quan_trac" }, { name: "Kỳ quan trắc", value: "ky_quan_trac" }]
const charts = [{ name: "Thay đổi", value: "thay_doi" }, { name: "Tỷ lệ", value: "ty_le" }]
const phases = [{ name: "Theo thời gian", value: "theo_thoi_gian" }, { name: "Tất cả", value: "tat_ca" }]
const phaseChanges = [{ name: "Theo thời gian", value: "theo_thoi_gian" }]
const periods = [{ name: "Năm", value: "nam" }, { name: "Tháng", value: "thang" }]


const mapPropsToState = (state) => ({
  user: state.oidc.user,
  functions: state.functions,
  tinhs: state.commonReducer.dvhcTinhs.data,
  profile: state.commonReducer.profile.data,
  cauHinhGP: state.commonReducer.cauHinhGP.data
});
export default connect(mapPropsToState)(Dashboard)