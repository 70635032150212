const columns = ({} = {}) =>
  [
    // {
    //   title: "#",
    //   dataIndex: "stt",
    //   width: "45px",
    //   align: 'center'
    // },
    {
      title: "Hạng mục",
      dataIndex: "tenHangMuc",
      defaultSortOrder: "descend",
    },
    {
      title: "Tọa độ X",
      dataIndex: "x",
      width: "30%",
    },
    {
      title: "Tọa độ Y",
      dataIndex: "y",
      width: "30%",
    },
  ].map(col => ({ ...col}));

export default columns;
