import React, { Component } from 'react'
import {connect} from 'react-redux'
import { ResponsivePie } from '@nivo/pie'

class PieChart extends Component {
    render() {
        const {data, focusSlice} = this.props
        return (
            <ResponsivePie
                data={data}
                margin={{ top: 40, right: 80, bottom: 50, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'nivo' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#000"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                defs={[
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.7)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[{match: {id: focusSlice},id: 'lines'}]}
            />
        )
    }
};
const mapPropsToState = (state) => ({user: state.oidc.user});
export default connect(mapPropsToState)(PieChart)