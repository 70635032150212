import React from 'react'
import { Row, Col, Modal } from 'antd'
import PropTypes from 'prop-types'
//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import helper from "../../../../helper/helper";
import DVHCModal from "../Modal/AddDMNN";
import NguonNuocBaoVe from '../../../../models/NguonNuocBaoVe';
import typo, { MESSAGE } from "../../../../constants/dictionaries";
// Columns
const columns_form = (showEditModal, deleteRowModal, mode) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "65px",
  },
  {
    title: "Tên huyện",
    dataIndex: "tenHuyen",
    width: "20%"
  },
  {
    title: "Tên xã",
    dataIndex: "tenXa",
  },
  {
    title: "",
    render: (value, row) => (
      <RowTools
        row={row}
        iconDelete={mode !== "view" ? false : true}
        iconEdit={mode !== "view" ? false : true}
        deleteRowModal={() => deleteRowModal(row)}
        showEditModal={showEditModal} />
    ),
    width: "65px"
  },
];
export default class DMNC extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      miningQuantity: [],
      selectedRow: null
    }
  }

  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };

  handleShowDetail = (row) => {
    this.setState({
      selectedRow: row,
    })
  };

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    const { dataSource, handleUpdate } = this.props;
    if (item) {
      for (var i = 0; i < item.length; i++) {
        let newDataAdd = { ...item[i], id: 0 }
        dataSource.push(newDataAdd)
      }
    }
    const newData = [...dataSource];
    this.handleCloseModal();
    handleUpdate(newData);
  };

  handleEdit = (item) => {
    const { dataSource, handleUpdate, field } = this.props;
    let dataUpdate = dataSource.map((item, index) => ({ ...item, stt: index + 1 }))
    const newData = helper.replaceItem(item[0], dataUpdate, "stt");
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const { dataSource, handleUpdate, field, isEdit } = this.props;
    let dataUpdate = dataSource.filter(x => x.maHuyen !== item.maHuyen)
    let newData = dataUpdate
    handleUpdate(newData, field);
  };

  addSttToItems = (items) => {
    const { IdNguonNuoc } = this.props
    if (!items) return [];
    let newItems = [];
    if (items) {
      let newData = []
      const array_huyen = []
      items.map((bvnn) => {
        if (array_huyen.indexOf(bvnn.maHuyen) === -1) {
          array_huyen.push(bvnn.maHuyen)
        }
      })
      array_huyen.map((value, idx) => {
        let data = {
          idNguonNuoc: IdNguonNuoc,
          maTinh: undefined,
          maHuyen: undefined,
          maXa: [],
          tenXa: []
        }
        let TenXa = []
        let data_huyen = items.filter(x => x.maHuyen === value)
        for (let i = 0; i < data_huyen.length; i++) {
          data.maTinh = data_huyen[0].maTinh
          data.maHuyen = data_huyen[0].maHuyen
          data.tenHuyen = data_huyen[0].tenHuyen
          data.maXa.push(data_huyen[i].maXa)
          TenXa.push(data_huyen[i].tenXa)
        }
        data.tenXa = String(TenXa)
        newData.push(data)
      })
      newItems = newData.map((item, index) => ({
        ...item,
        stt: index + 1
      }))
    }
    return newItems;
  };

  render() {
    const { isAddModalOpen, isEditModalOpen, selectedRow } = this.state;
    const { dataSource, mode, IdNguonNuoc } = this.props;
    return (
      <Row>
        <Row type="flex" justify="end" align="middle">
          {mode !== "view" &&
            <Button type="primary" onClick={this.handleOpenAddModal} icon="plus" />}
        </Row>
        <div className="mt-10" />
        <div style={{ maxHeight: mode !== "view" ? "350px" : "auto", overflowY: "auto" }}>
          <ViewTable
            bordered pagination={false}
            columns={columns_form(this.handleOpenEditModal, this.handleDelete, mode)}
            dataSource={this.addSttToItems(dataSource)}
            scroll={{ x: 500 }}
          />
        </div>

        {isEditModalOpen &&
          <Modal
            title={typo.cscore_tnn_Edit_Nguon_Nuoc}
            centered
            closable={true}
            visible={isEditModalOpen}
            width="800px"
            footer={null}
            onCancel={this.handleCloseModal}
            maskClosable={false}
          >
            <DVHCModal
              handleAdd={this.handleEdit}
              handleCloseModal={this.handleCloseModal}
              selectedRow={selectedRow}
              isEdit={true}
              IdNguonNuoc={IdNguonNuoc}
            />
          </Modal>
        }
        {isAddModalOpen &&
          <Modal
            title={typo.cscore_tnn_Add_Nguon_Nuoc}
            centered
            closable={true}
            visible={isAddModalOpen}
            width="800px"
            footer={null}
            onCancel={this.handleCloseModal}
            maskClosable={false}
          >
            <DVHCModal
              handleCloseModal={this.handleCloseModal}
              handleAdd={this.handleAdd}
              IdNguonNuoc={IdNguonNuoc}
            />
          </Modal>}
      </Row>
    )
  }
}

DMNC.propTypes = {
  // MODE: string "form" and "presentation"
  mode: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
};