import React, { useState, useEffect } from "react";
import { Menu, Icon, Row } from "antd";
import { NavLink } from "react-router-dom";
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Scrollbars from "react-custom-scrollbars";
import message from '../../components/Messages'
import typo, { MESSAGE } from "../../constants/dictionaries";
import Api from '../../api'
import "./antd-menu.scss";
import helper from '../../helper/helper';
import Constant, { groupFunctionsCode, functionsCode } from "../../constants/constant";
import { faChartBar, faHistory, faInfoCircle, faGlobeAsia, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrangChu from '../../assets/images/TrangChu.png';
import DMQuanTrac from '../../assets/images/DMQuanTrac.png'
import GP from '../../assets/images/GP.png'
import CTNM from '../../assets/images/CTNM.png'
import TTTNN from '../../assets/images/TTTNN.png'
import DLQuanTracDK from '../../assets/images/DLQuanTracDK.png'
import DM from '../../assets/images/DM.png'
import CauHinh from '../../assets/images/CauHinh.png'
import BanDo from '../../assets/images/BanDo.png'
import BaoCaoThongKe from '../../assets/images/BaoCaoThongKe.png'
import NKHT from '../../assets/images/NKHT.png'
import HDSD from '../../assets/images/HDSD.png'
const SubMenu = Menu.SubMenu;

const Navigation =
  ({ collapsed, user, addFunctions, isCollapsed, handleCollapsed }) => {
    const [openKeys, setOpenKeys] = useState(localStorage.getItem("GEN_menu_isCollapsed") === "true"
      ? []
      : localStorage.getItem("GEN_defaultOpenKeys")
        ? JSON.parse(localStorage.getItem("GEN_defaultOpenKeys"))
        : []);

    const [listFunctions, setListFunctions] = useState([]);
    const rest = collapsed ? {} : { openKeys };

    const updateKey = key => {
      const keysToUpdate = openKeys.includes(key) ? [] : [key];
      localStorage.setItem("GEN_defaultOpenKeys", JSON.stringify(keysToUpdate));
      setOpenKeys(keysToUpdate)
    };

    useEffect(() => {
      if (collapsed) {
        window.cacheOpenKeys = openKeys;
        setOpenKeys([]);
      } else {
        if (window.cacheOpenKeys) {
          setOpenKeys(window.cacheOpenKeys)
        }
      }
    }, [collapsed]);

    useEffect(() => {
      getFunctionsByRole()
    }, user);

    const getFunctionsByRole = () => {
      if (user) {
        new Api().getFunctionsByRole({ token: user.access_token })
          .then(data => {
            setListFunctions(data);
            addFunctions(data)
          }).catch(err => {
            message.error(MESSAGE.cscore_tnn_ERROR)
          })
      }
    };

    const groupFunctions = helper.handleGroupFunctions(listFunctions, groupFunctionsCode);

    return (
      <div style={{ height: "calc(100vh - 124px)" }}>
        <Scrollbars
          autoHide renderThumbVertical={() => (<div style={{ backgroundColor: "#fff", borderRadius: 5, opacity: 0.4 }} />)}
          style={{ height: window.innerHeight - 150, }}
        >
          <Menu className='menu-box' defaultSelectedKeys={[]} // defaultOpenKeys={defaultOpenKeys}
            {...rest} key={collapsed} mode="inline"
            style={{ backgroundColor: "transparent", color: "#fff", border: "none" }}>
            {!isEmpty(groupFunctions[groupFunctionsCode.PORTAL]) &&
              <Menu.Item className='menu-item-box' key="item1">
                <NavLink activeClassName='active-menu-item-content' to="/dashboard">
                  <span className='menu-item-content'>
                    {/* <Icon style={{ color: '#007bc3' }} type="home" />   */}
                    <img className='menu-item-img1' src={TrangChu} />
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Trang chủ</span>
                  </span>
                </NavLink>
              </Menu.Item>}
            {!isEmpty(groupFunctions[groupFunctionsCode.TKDN]) &&
              <Menu.Item className='menu-item-box' key="item9">
                <NavLink activeClassName='active-menu-item-content' to="/Tra-cuu-doanh-nghiep">
                  <span className='menu-item-content'>

                    <img className='menu-item-img1' src={DMQuanTrac} />
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Tra cứu doanh nghiệp</span>
                  </span>
                </NavLink>
              </Menu.Item>}

            {!isEmpty(groupFunctions[groupFunctionsCode.BCDN]) &&
              <SubMenu className='menu-item-subbox' key="sub19" onTitleClick={() => {
                updateKey("sub19")
              }}
                title={<span className='menu-item-content'>

                  <img className='menu-item-img1' src={GP} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Dành cho doanh nghiệp</span>
                </span>}>
                {/* Thủ tục hành chính */}
                {groupFunctions[groupFunctionsCode.BCDN].includes(functionsCode.BCDN_TTHC) &&
                  <div>
                    <hr className="hr_custom"></hr>
                    <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Thủ tục hành chính</p>

                    <Menu.Item className='menu-item-box'>
                      <NavLink activeClassName='active-menu-item-content'
                        to="/BCDN/TTHC"> Thủ tục hành chính </NavLink>
                    </Menu.Item></div>}

                <hr className="hr_custom"></hr>
                <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Báo cáo định kỳ</p>
                {groupFunctions[groupFunctionsCode.BCDN].includes(functionsCode.BCDN_TNN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/BCDN/TNN"> Tài nguyên nước </NavLink>
                  </Menu.Item>}

                {/* Hướng dẫn sử dụng */}
                {groupFunctions[groupFunctionsCode.BCDN].includes(functionsCode.BCDN_HDSD) &&
                  <div>
                    <hr className="hr_custom"></hr>
                    <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Hướng dẫn sử dụng</p>
                    <Menu.Item className='menu-item-box' key="item4"
                    >
                      <a onClick={() => window.open("https://www.youtube.com/watch?v=ERDl2W0Svbw&t=6s")} activeClassName='active-menu-item-content'>
                        <span className='menu-item-content'>
                          <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Clip hướng dẫn nhập báo cáo</span>
                        </span>
                      </a>
                    </Menu.Item></div>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.LICENSE]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => {
                updateKey("sub3")
              }} key="sub3" title={
                <span className='menu-item-content'>
                  {/* <Icon type="file-text" style={{ color: '#0138a2' }} /> */}
                  <img className='menu-item-img1' src={GP} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_cp_tnn}</span>
                </span>}>

                {/*{!isEmpty(groupFunctions[functionsCode.LICENSE_XT]) &&*/}
                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/xa-thai">{typo.cscore_tnn_drawer_cp_xt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) &&
                  <Menu.Item
                    className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/khai-thac-nuoc-mat">{typo.cscore_tnn_drawer_cp_ktnm}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/khai-thac-nuoc-bien">{typo.cscore_tnn_drawer_cp_ktnb}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/khai-thac-nuoc-duoi-dat">{typo.cscore_tnn_drawer_cp_ktndd}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/tham-do-nuoc-duoi-dat">{typo.cscore_tnn_drawer_cp_tdndd}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/hanh-nghe-khoan-nuoc">{typo.cscore_tnn_drawer_cp_hnkndd}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_IMPORT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/import-du-lieu">{typo.cscore_tnn_drawer_import_dulieu}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_QLKN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cap-phep/quanlyketnoi">{typo.cscore_tnn_drawer_quanlyketnoi}</NavLink>
                  </Menu.Item>}
              </SubMenu>}
            {!isEmpty(groupFunctions[groupFunctionsCode.CongTrinh]) &&
              <SubMenu className='menu-item-subbox' key="sub1" onTitleClick={() => {
                updateKey("sub1")
              }}
                title={<span className='menu-item-content'>
                  <img className='menu-item-img1' src={CTNM} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_ct}</span>
                </span>}>
                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_XT) &&
                  <Menu.Item className='menu-item-box' key="item2">
                    <NavLink
                      to="/cong-trinh-xa-thai" activeClassName='active-menu-item-content'>
                      {typo.cscore_tnn_ctxt}
                    </NavLink>
                  </Menu.Item>
                }
                <hr className="hr_custom"></hr>
                <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Nước mặt</p>

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_TD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/thuy-dien"> {typo.cscore_tnn_drawer_ctnm_qltd} </NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_HC) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/ho-chua"> {typo.cscore_tnn_drawer_ctnm_qlhc} </NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_TB) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/tram-bom"> {typo.cscore_tnn_drawer_ctnm_qltb} </NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_BD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/bai-dap"> {typo.cscore_tnn_drawer_ctnm_qlbd} </NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_Cong) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/cong"> {typo.cscore_tnn_drawer_ctnm_qlc} </NavLink>
                  </Menu.Item>}


                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_TCN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/tram-cap-nuoc"> {typo.cscore_tnn_drawer_ctnm_qltcn}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NM_NMN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-mat/nha-may-nuoc"> {typo.cscore_tnn_drawer_ctnm_qlnmn}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NB) &&
                  <div>
                    <hr className="hr_custom"></hr>
                    <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Nước biển</p>
                  </div>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NB) &&
                  <Menu.Item className='menu-item-box' key="item3">
                    <NavLink activeClassName='active-menu-item-content' to="/cong-trinh-nuoc-bien"> {typo.cscore_tnn_nuocbien}
                    </NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NDD_KT) &&
                  <div>
                    <hr className="hr_custom"></hr>
                    <p style={{ padding: '10px 0px 0px 5px', color: '#ffffff47' }}>Nước dưới đất</p>
                  </div>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NDD_KT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-duoi-dat/khai-thac"> {typo.cscore_tnn_KhaiThac} </NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CongTrinh].includes(functionsCode.CongTrinh_NDD_TD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/cong-trinh-nuoc-duoi-dat/tham-do">{typo.cscore_tnn_ThamDo}</NavLink>
                  </Menu.Item>}

              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.TNN_INFO]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub4")} key="sub4"
                title={
                  <span className='menu-item-content'>
                    <img className='menu-item-img1' src={TTTNN} />
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_management_tnn}</span>
                  </span>}>

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_VBQPPL) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Van-ban-quy-pham-phap-luat">{typo.cscore_tnn_drawer_management_vbqppl}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_QHTNN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Quy-hoach-tai-nguyen-nuoc">{typo.cscore_tnn_drawer_management_csdlqh}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_DGTNND) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Danh-gia-tai-nguyen-nuoc-duoi-dat">{typo.cscore_tnn_TNN_duoi_dat}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_DTDG) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Dieu-tra-danh-gia">{typo.cscore_tnn_drawer_management_ttdt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_TT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Thanh-tra">{typo.cscore_tnn_ttkt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_TCQ) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Tien-cap-quyen">{typo.cscore_tnn_tiencapquyen}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_BVPCKP) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Bao-ve-phong-chong-khac-phuc">{typo.cscore_tnn_drawer_management_bcpckp}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_TTTNNK) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Thong-tin-tai-nguyen-nuoc-khac">{typo.cscore_tnn_drawer_management_other}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_DLBD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Du-lieu-ban-do">{typo.cscore_tnn_drawer_management_map}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_NCCBV) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Nguon-nuoc-can-bao-ve">{typo.cscore_tnn_drawer_management_nccbv}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_NNNT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Nguon-nuoc-noi-tinh">{typo.cscore_tnn_dm_nguon_nuoc_noi_tinh}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_KQGPKN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Ket-qua-giai-quyet-kien-nghi">{typo.cscore_tnn_ketquakiennghi}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_KQTLPABC) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Ket-qua-tra-loi-phan-anh-bao-chi">{typo.cscore_tnn_ketquaphananhbaochi}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_QLKTTV) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Quan-ly-khi-tuong-thuy-van">{typo.cscore_tnn_title_kttv}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_QLVBDP) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Thong-tin-tai-nguyen-nuoc/Quan-ly-van-ban-dia-phuong">{typo.cscore_tnn_drawer_ql_dp}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.DLQT]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub21")} key="sub21"
                title={<span className='menu-item-content'>
                  {/* <FontAwesomeIcon icon={faDatabase} style={{ color: '#f8c82d' }} /> */}
                  {/* <Icon type="database" style={{ color: '#f8c82d' }} />
                <span>{typo.cscore_tnn_drawer_dlqt_dk}</span> */}
                  <img className='menu-item-img1' src={DLQuanTracDK} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Quan trắc chất lượng nước</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.DLQT].includes(functionsCode.DLQT_NM) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Du-lieu-quan-trac-dinh-ky/Nuoc-mat">{typo.cscore_tnn_drawer_dlqt_nm}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.DLQT].includes(functionsCode.DLQT_NDD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Du-lieu-quan-trac-dinh-ky/Nuoc-duoi-dat">{typo.cscore_tnn_drawer_dlqt_ndd}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.DLQT].includes(functionsCode.DLQT_XT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Du-lieu-quan-trac-dinh-ky/Nuoc-xa-thai">{typo.cscore_tnn_drawer_dlqt_nxt}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.DLQT]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub22")} key="sub22"
                title={<span className='menu-item-content'>
                  {/* <FontAwesomeIcon icon={faDatabase} style={{ color: '#f8c82d' }} /> */}
                  {/* <Icon type="database" style={{ color: '#f8c82d' }} />
                <span>{typo.cscore_tnn_drawer_dlqt_dk}</span> */}
                  <img className='menu-item-img1' src={DLQuanTracDK} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Quan trắc khí tượng, thủy văn</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.DLQT].includes(functionsCode.DLQT_KT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Du-lieu-quan-trac-dinh-ky/Khi-tuong">{typo.cscore_tnn_drawer_dlqt_kt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.DLQT].includes(functionsCode.DLQT_TV) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Du-lieu-quan-trac-dinh-ky/Thuy-van">{typo.cscore_tnn_drawer_dlqt_tv}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.CATEGORY]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub7")} key="sub7"
                title={<span className='menu-item-content'>
                  {/* <Icon type="menu" style={{ color: '#028276' }} />
                <span>{typo.cscore_tnn_drawer_categories}</span> */}
                  <img className='menu-item-img1' src={DM} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_categories}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LH) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Thong-tin-lien-he">Thông tin liên hệ</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LBC) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Phan-loai-bao-cao">{typo.cscore_tnn_phan_loai_bao_cao}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LBD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-ban-do">{typo.cscore_tnn_loai_ban_do}</NavLink>
                  </Menu.Item>}


                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LVB) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-van-ban">{typo.cscore_tnn_drawer_categories_qlvb}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_CSDL) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content' to="/Danh-muc/Co-so-du-lieu">{typo.cscore_tnn_ma_csdl}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LCT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-cong-trinh">{typo.cscore_tnn_loaicongtrinh}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_KTT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Kinh-tuyen-truc">{typo.cscore_tnn_kinh_tuyen_truc}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_MDSD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Muc-dich-su-dung-nuoc">{typo.cscore_tnn_muc_dich_su_dung_nuoc}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LHNT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-hinh-nuoc-thai">{typo.cscore_tnn_loaiHinhNuocThai}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_DVQLCT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Don-vi-quan-ly-cong-trinh">{typo.cscore_tnn_drawer_categories_qlct}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_KCN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Khu-cong-nghiep">{typo.cscore_tnn_drawer_categories_qlkcn}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_TCCNDCP) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Don-vi-xin-cap-phep">{typo.cscore_tnn_license_organization}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Lang-nghe">{typo.cscore_tnn_drawer_categories_qlln}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_NM) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Nha-may">{typo.cscore_tnn_drawer_categories_qlnm}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_DVTD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Don-vi-tham-dinh">{typo.cscore_tnn_donvithamdinh}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_VKT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Vung-kinh-te-xa-hoi">{typo.cscore_tnn_vung_kinh_te}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LSC) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-su-co">{typo.cscore_tnn_dm_loai_su_co}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_CQBH) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Co-quan-ban-hanh">{typo.cscore_tnn_title_coquanbanhanh}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.CATEGORY].includes(functionsCode.CATEGORY_LVB_DP) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc/Loai-van-ban-dia-phuong">{typo.cscore_tnn_drawer_lvb_dp}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.CATEGORY_QT]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub9")} key="sub9"
                title={<span className='menu-item-content'>
                  {/* <Icon type="menu" style={{ color: '#f8c82d' }} />
                <span>{typo.cscore_tnn_drawer_dm_qt}</span> */}
                  <img className='menu-item-img1' src={DMQuanTrac} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_dm_qt}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_DVD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Don-vi-do">{typo.cscore_tnn_don_vi_do}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_PPLM) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Phuong-phap-lay-mau">{typo.cscore_tnn_phuong_phap_LM}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_PPPT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Phuong-phap-phan-tich">{typo.cscore_tnn_phuong_phap_PT}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_MDSD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Muc-dich-su-dung">{typo.cscore_tnn_mucdichsudung}</NavLink>
                  </Menu.Item>}


                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_TQT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Tuyen-quan-trac">{typo.cscore_tnn_tuyen_QT}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_DQT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Don-vi-thuc-hien">{typo.cscore_tnn_don_vi_thuc_hien}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_KQT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Dot-quan-trac">{typo.cscore_tnn_drawer_qt_batch}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_DVTH) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Ky-quan-trac-kttv">{typo.cscore_tnn_drawer_qtkttv_kqt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_TSQT) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Thong-so-quan-trac">{typo.cscore_tnn_drawer_qt_tt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_TCAD) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Tieu-chuan-ap-dung">{typo.cscore_tnn_drawer_qt_tcad}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_TCDG) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Tieu-chuan-danh-gia">{typo.cscore_tnn_drawer_qt_tcdg}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CATEGORY_QT].includes(functionsCode.CATEGORY_QT_TQTN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Danh-muc-quan-trac/Tram-quan-trac-nuoc">{typo.cscore_tnn_drawer_qt_tqtn}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.CHTSQT]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub20")} key="sub20"
                title={<span className='menu-item-content'>
                  {/* <Icon type="setting" style={{ color: '#57a1e7' }} />
                <span>{typo.cscore_tnn_drawer_qt_chts}</span> */}
                  <img className='menu-item-img1' src={CauHinh} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_qt_chts}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.CHTSQT].includes(functionsCode.CHTSQT_TS) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Cau-hinh-thong-so/Cau-hinh-thong-so-doi-tuong">{typo.cscore_tnn_drawer_qt_chtdt}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.CHTSQT].includes(functionsCode.CHTSQT_QC) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Cau-hinh-thong-so/Cau-hinh-thong-so-theo-quy-chuan">{typo.cscore_tnn_drawer_qt_chtstc}</NavLink>
                  </Menu.Item>}
              </SubMenu>}
            {!isEmpty(groupFunctions[groupFunctionsCode.MAP]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub10")} key="sub10"
                title={<span className='menu-item-content'>

                  {/* <Icon type="global" style={{ color: '#3f88f5' }} />
                <span>{typo.cscore_tnn_drawer_map}</span> */}
                  <img className='menu-item-img1' src={BanDo} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_map}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.MAP].includes(functionsCode.MAP_TCTNN) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Ban-do/Tra-cuu-tai-nguyen-nuoc">{typo.cscore_tnn_drawer_map_tnn}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.MAP].includes(functionsCode.MAP_SVLVS) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/Ban-do/Song-va-luu-vuc-song">{Constant.CAP_TRIEN_KHAI === 1 ? typo.cscore_tnn_drawer_management_svlvs : typo.cscore_tnn_drawer_management_tcbds}</NavLink>
                  </Menu.Item>
                }
              </SubMenu>}


            {!isEmpty(groupFunctions[groupFunctionsCode.BM_TT_31]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub15")} key="sub15"
                title={<span className='menu-item-content'>
                  <img className='menu-item-img1' src={BaoCaoThongKe} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_BieuMauTT31}</span>
                </span>}>
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_6) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-6">{typo.cscore_tnn_BieuMauSo6}</NavLink>
                  </Menu.Item>
                }

                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_9) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-9">{typo.cscore_tnn_BieuMauSo9}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_10) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-10">{typo.cscore_tnn_BieuMauSo10}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_11) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-11">{typo.cscore_tnn_BieuMauSo11}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_12) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-12">{typo.cscore_tnn_BieuMauSo12}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_15) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-15">{typo.cscore_tnn_BieuMauSo15}</NavLink>
                  </Menu.Item>}

                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_16) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-16">{typo.cscore_tnn_BieuMauSo16}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_17) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-17">{typo.cscore_tnn_BieuMauSo17}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_19) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-19">{typo.cscore_tnn_BieuMauSo19}</NavLink>
                  </Menu.Item>
                }

                {groupFunctions[groupFunctionsCode.BM_TT_31].includes(functionsCode.BM_TT_31_20) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt31/bieu-mau-so-20">{typo.cscore_tnn_BieuMauSo20}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

            {!isEmpty(groupFunctions[groupFunctionsCode.BM_TT_20]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub16")} key="sub16"
                title={<span className='menu-item-content'>
                  <img className='menu-item-img1' src={BaoCaoThongKe} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_BieuMauTT20}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.BM_TT_20].includes(functionsCode.BM_TT_20_0203) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt20/bieu-mau-so-0203">{typo.cscore_tnn_BieuMauSo0203}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_20].includes(functionsCode.BM_TT_20_0205) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt20/bieu-mau-0205">{typo.cscore_tnn_BieuMau0205}</NavLink>
                  </Menu.Item>
                }
                {groupFunctions[groupFunctionsCode.BM_TT_20].includes(functionsCode.BM_TT_20_0206) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/bieu-mau-tt20/bieu-mau-0206">{typo.cscore_tnn_BieuMau0206}</NavLink>
                  </Menu.Item>
                }

              </SubMenu>}
            {!isEmpty(groupFunctions[groupFunctionsCode.PL]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub17")} key="sub17"
                title={<span className='menu-item-content'>
                  <img className='menu-item-img1' src={BaoCaoThongKe} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_Phu_Luc_TT}</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.PL].includes(functionsCode.PL_2) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/phu-luc/phu-luc-2">{typo.cscore_tnn_PhuLuc2}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.PL].includes(functionsCode.PL_3) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/phu-luc/phu-luc-3">{typo.cscore_tnn_PhuLuc3}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.PL].includes(functionsCode.PL_5) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/phu-luc/phu-luc-5">{typo.cscore_tnn_PhuLuc5}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.PL].includes(functionsCode.PL_6) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/phu-luc/phu-luc-6">{typo.cscore_tnn_PhuLuc6}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.PL].includes(functionsCode.PL_GP_SO) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/phu-luc/thongke-gp-so">{typo.cscore_tnn_TK_GP_SO}</NavLink>
                  </Menu.Item>}
              </SubMenu>}

              {/* {!isEmpty(groupFunctions[groupFunctionsCode.TKDKY]) &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub25")} key="sub25"
                title={<span className='menu-item-content'>
                  <img className='menu-item-img1' src={BaoCaoThongKe} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>Tờ khai đăng ký</span>
                </span>}>

                {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_1) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/cong-trinh-khai-thac-nuoc-mat">{'Đăng ký công trình khai thác nước mặt'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_2) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/su-dung-nuoc-mat">{'Đăng ký sử dụng nước mặt'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_3) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/dao-ho-ao">{'Tờ khai đào hồ, ao, sông suối'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_4) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/ke-khai-khai-thac-ndd-ho-gd">{'Tờ kê khai, khai thác nước dưới đất, hộ gia đình'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_5) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/ke-khai-khai-thac-ndd-quy-mo-nho">{'Tờ kê khai, khai thác nước dưới đất, quy mô nhỏ'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_6) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/ke-khai-khai-thac-ndd-ks">{'Tờ kê khai, khai thác nước dưới đất, khai thác khoáng sản'}</NavLink>
                  </Menu.Item>}
                  {groupFunctions[groupFunctionsCode.TKDKY].includes(functionsCode.TOKHAI_7) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/to-khai/ke-khai-khai-thac-nuoc-bien">{'Giấy xác nhận đăng ký khai thác, sử dụng nước biển'}</NavLink>
                  </Menu.Item>}
               
              </SubMenu>} */}
            {!isEmpty(groupFunctions[groupFunctionsCode.LOG]) && Constant.CAP_TRIEN_KHAI === 2 &&
              <Menu.Item className='menu-item-box' key="item5">
                <NavLink activeClassName='active-menu-item-content' to="/Nhat-ky-he-thong">
                  <span className='menu-item-content'>
                    <img className='menu-item-img1' src={NKHT} />
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_drawer_log}</span>

                  </span>
                </NavLink>
              </Menu.Item>}

            {!isEmpty(groupFunctions[groupFunctionsCode.QTHeThong]) && Constant.CAP_TRIEN_KHAI === 1 &&
              <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub18")} key="sub18"
                title={<span className='menu-item-content'>


                  <img className='menu-item-img1' src={CauHinh} />
                  <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_QTHT}</span>
                </span>}>
                {groupFunctions[groupFunctionsCode.QTHeThong].includes(functionsCode.QTHeThong_NhatKy) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/quan-tri-he-thong/nhat-ky-he-thong">{typo.cscore_tnn_drawer_log}</NavLink>
                  </Menu.Item>}
                {groupFunctions[groupFunctionsCode.QTHeThong].includes(functionsCode.QTHeThong_NhatKy) &&
                  <Menu.Item className='menu-item-box'>
                    <NavLink activeClassName='active-menu-item-content'
                      to="/quan-tri-he-thong/nhat-ky-dong-bo">{typo.cscore_tnn_drawer_log_db}</NavLink>
                  </Menu.Item>}
              </SubMenu>}


            <SubMenu className='menu-item-subbox' onTitleClick={() => updateKey("sub23")} key="sub23"
              title={<span className='menu-item-content'>
                <img className='menu-item-img1' src={CauHinh} />
                <span style={{ color: isCollapsed === true ? "#314558" : "#fff", marginLeft: "10px" }}>{typo.cscore_tnn_hdsd}</span>
              </span>}>
              <Menu.Item className='menu-item-box' onClick={() => helper.DownloadHDSD(user)}>
                <a activeClassName='active-menu-item-content'>
                  <span className='menu-item-content'>
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff" }}>{typo.cscore_tnn_hdsd}</span>
                  </span>
                </a>
              </Menu.Item>
              <Menu.Item className='menu-item-box' onClick={() => { window.open("https://www.youtube.com/watch?v=KIpdfBjCbOM") }}>
                <a activeClassName='active-menu-item-content'>
                  <span className='menu-item-content'>
                    <span style={{ color: isCollapsed === true ? "#314558" : "#fff" }}>Hướng dẫn nhập giấy phép</span>
                  </span>
                </a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Scrollbars>
        <div style={{
          display: isCollapsed === true ? "none" : "block",
          position: "absolute",
          bottom: 50, left: 10,
          width: 270, textAlign: 'center',
          color: "#f5f5f582",
          fontWeight: 600,
          fontSize: 14,
          textShadow: "1px 1px #000000",
          fontStyle: "italic",
          borderTop: "0.01rem solid"
        }}>Được phát triển bởi Trung tâm CNPM & Nền tảng số</div>
      </div>
    )
  };

Navigation.propTypes = {
  collapsed: PropTypes.any.isRequired,
};
export default Navigation
