/*
 *  @2019 CS-Core project.
 *
 */

export const Constant = {
  /*Project configuration*/
  /*Cấp triển khai 1: Bộ, 2: Địa phương*/
  CAP_TRIEN_KHAI: Number(process.env.REACT_APP_CAP_TRIEN_KHAI),
  MA_DVHC: process.env.REACT_APP_MA_DVHC,
  MA_TINH: process.env.REACT_APP_MA_TINH,
  TEN_DVHC: process.env.REACT_APP_TEN_DVHC,
  GEO_WMS: process.env.REACT_APP_GEO_WMS,
  KEY_GG_BO: 'AIzaSyAKt0vpwNaGAgbRC5eukCzIAp0nNKb-NyE',
  KEY_GG_DP: 'AIzaSyBnJuZ8iXGI_XyxLKD_gsa5ii5koqJsTMA',
  KEY_GG_IF: 'AIzaSyCDJVZlu8THnMxQVMX-1xyWMyEzFLphzuw',
  CAP_THAM_QUYEN: [
    { id: 1, ten: "Cấp trung ương" },
    { id: 2, ten: "Cấp địa phương" },
  ],
  CAP_TK: {
    TRUNGUONG: 1,
    DIAPHUONG: 2
  },

  MUI_CHIEU: [
    { id: 3, ten: "Múi chiều 3 độ" },
    { id: 6, ten: "Múi chiều 6 độ" },
  ],

  NHOM_CONG_TRINH: [
    { id: 3, maCongTrinh: 3, moTa: "Công trình nước mặt", tenNhomCongTrinh: "Công trình nước mặt", key: "CTNM_GiayPhepCongTrinh" },
    { id: 1, maCongTrinh: 1, moTa: "Công trình nước dưới đất", tenNhomCongTrinh: "Công trình nước dưới đất", key: "CTNDD_GiayPhepCongTrinh" },
    { id: 4, maCongTrinh: 4, moTa: "Công trình nước biển", tenNhomCongTrinh: "Công trình nước biển", key: "CTNB_GiayPhepCongTrinh" },
    { id: 2, maCongTrinh: 2, moTa: "Công trình xả thải", tenNhomCongTrinh: "Công trình xả thải", key: "CTXT_GiayPhepCongTrinh" },
  ],
  NHOM_CONG_TRINH_KET_NOI: [
    { id: 3, maCongTrinh: 3, tenNhomCongTrinh: "Công trình nước mặt"},
    { id: 1, maCongTrinh: 1, tenNhomCongTrinh: "Công trình nước dưới đất khai thác"},
    { id: 0, maCongTrinh: 0, tenNhomCongTrinh: "Công trình nước dưới đất thăm dò"},
    { id: 4, maCongTrinh: 4, tenNhomCongTrinh: "Công trình nước biển"},
    { id: 2, maCongTrinh: 2, tenNhomCongTrinh: "Công trình xả thải"},
  ],
  METHOD:[
    {name:'POST'},{name:'GET'},{name:'PUT'},{name:'DELETE'}
  ],

  LOAI_API:[
    { isFirst_Api: 1, name: "API Cập nhật lần đầu" },
    { isFirst_Api: 0, name: "API cập nhật hàng ngày" },
  ],

  Status:[
    { status: 1, name: "Thành công" },
    { status: 0, name: "Lỗi" },
  ],

  DICT_LHGP: {
    CAP_MOI: 1,
    CAP_LAI: 2,
    GIA_HAN: 3,
    THU_HOI: 4,
  },

  DICT_NHOM_CT: {
    NDD: 1,
    XT: 2,
    NM: 3,
    NB: 4
  },

  LOAI_CONG_TRINH: [
    { id: 3, maLoaiCongTrinh: 3, nhomCongTrinh: 3, moTa: "Công trình thủy điện", tenLoaiCongTrinh: "Công trình thủy điện", key: "ctnM_ThuyDien", thongTinCT: "ctnM_ThuyDien" },
    { id: 4, maLoaiCongTrinh: 4, nhomCongTrinh: 3, moTa: "Công trình hồ chứa", tenLoaiCongTrinh: "Công trình hồ chứa", key: "ctnM_HoChua", thongTinCT: "ctnM_HoChua" },
    { id: 5, maLoaiCongTrinh: 5, nhomCongTrinh: 3, moTa: "Công trình đập", tenLoaiCongTrinh: "Công trình đập", key: "ctnM_BaiDap", thongTinCT: "ctnM_BaiDap" },
    { id: 6, maLoaiCongTrinh: 6, nhomCongTrinh: 3, moTa: "Công trình trạm bơm", tenLoaiCongTrinh: "Công trình trạm bơm", key: "ctnM_TramBom", thongTinCT: "ctnM_TramBom" },
    { id: 7, maLoaiCongTrinh: 7, nhomCongTrinh: 3, moTa: "Công trình cống", tenLoaiCongTrinh: "Công trình cống", key: "ctnM_Cong", thongTinCT: "ctnM_Cong" },
    { id: 8, maLoaiCongTrinh: 8, nhomCongTrinh: 3, moTa: "Công trình trạm cấp nước", tenLoaiCongTrinh: "Công trình trạm cấp nước", key: "ctnM_TramCapNuoc", thongTinCT: "ctnM_TramCapNuoc" },
    { id: 9, maLoaiCongTrinh: 9, nhomCongTrinh: 3, moTa: "Công trình nhà máy nước", tenLoaiCongTrinh: "Công trình nhà máy nước", key: "ctnM_NhaMayNuoc", thongTinCT: "ctnM_NhaMayNuoc" },
    { id: 10, maLoaiCongTrinh: 10, nhomCongTrinh: 2, moTa: "Công trình xả thải", tenLoaiCongTrinh: "Công trình xả thải", key: "ctxT_CongTrinh_Input", thongTinCT: "ctxT_CongTrinh_Input" },
    { id: 11, maLoaiCongTrinh: 11, nhomCongTrinh: 4, moTa: "Công trình khai thác nước biển", tenLoaiCongTrinh: "Công trình khai thác nước biển", key: "ctktnB_CongTrinh_Input", thongTinCT: "ctktnB_CongTrinh_Input" },
    { id: 2, maLoaiCongTrinh: 2, nhomCongTrinh: 1, moTa: "Công trình khai thác nước dưới đất", tenLoaiCongTrinh: "Công trình khai thác nước dưới đất", key: "ctndD_CongTrinh_Input", thongTinCT: "ctndD_CongTrinh_Input" },
    { id: 1, maLoaiCongTrinh: 1, nhomCongTrinh: 1, moTa: "Công trình thăm dò nước dưới đất", tenLoaiCongTrinh: "Công trình thăm dò nước dưới đất", key: "ctndD_CongTrinhThamDo_Input", thongTinCT: "ctndD_CongTrinhThamDo" }
  ],

  DICT_LOAI_CT: {
    NDD_THAMDO: 1,
    NDD_KHAITHAC: 2,
    NM_THUYDIEN: 3,
    NM_HOCHUA: 4,
    NM_BAIDAP: 5,
    NM_TRAMBOM: 6,
    NM_CONG: 7,
    NM_TRAMCAPNUOC: 8,
    NM_NHAMAYNUOC: 9,
    XT: 10,
    NB_KT: 11
  },

  DON_VI_THAM_DINH: [
    { id: 1, ten: "Phòng QLLVS & BVTNN" },
    { id: 2, ten: "Phòng QLQH & ĐTCB TNN" },
    { id: 3, ten: "Phòng QLKT nước mặt" },
    { id: 4, ten: "Phòng QLKT Nước dưới đất" },
    { id: 5, ten: "Văn Phòng"},
    { id: 6, ten: "Phòng Lưu vực sông Hồng - Thái Bình" },
    { id: 7, ten: "Phòng Lưu vực sông Bắc Trung Bộ" },
    { id: 8, ten: "Phòng Lưu vực sông Nam Trung Bộ" },
    { id: 9, ten: "Phòng Lưu vực sông Đông Nam Bộ" },
    { id: 10, ten: "Phòng Lưu vực sông Mê Công" },
  ],

  HE_SO_KQ: [
    { id: 0, ten: "0.6", value: 0.6 },
    { id: 1, ten: "0.8", value: 0.8 },
    { id: 2, ten: "0.9", value: 0.9 },
    { id: 3, ten: "1", value: 1 },
    { id: 4, ten: "1.1", value: 1.1 },
    { id: 5, ten: "1.2", value: 1.2 },
    { id: 6, ten: "1.3", value: 1.3 },
  ],

  HE_SO_KF: [
    { id: 0, ten: "0.9", value: 0.9 },
    { id: 1, ten: "1.0", value: 1.0 },
    { id: 2, ten: "1.1", value: 1.1 },
    { id: 3, ten: "1.2", value: 1.2 },
    { id: 4, ten: "1.3", value: 1.3 },
  ],

  DOI_TUONG_QUAN_TRAC: [
    { id: 1, ten: "Nước mặt" },
    { id: 2, ten: "Nước dưới đất" },
    { id: 3, ten: "Nước xả thải" },
    { id: 4, ten: "Giam sat khai thac, su dung nuoc mat" },
    { id: 5, ten: "Khi tuong" },
    { id: 6, ten: "Thuy van" },
    { id: 7, ten: "Nước biển" },
  ],

  LOAI_THOI_HAN: [
    { id: 1, ten: "Toàn thời gian" },
    { id: 2, ten: "Theo giai đoạn" },
  ],

  GiayPhep_Sort: [
    { id: 1, name: "Sắp xếp theo số giấy phép" },
    { id: 2, name: "Sắp xếp theo ngày ký" },
    { id: 3, name: "Sắp xếp theo tên công trình" },
    { id: 4, name: "Sắp xếp theo tên ĐVXCP" },
    { id: 5, name: "Sắp xếp theo ngày bắt đầu hiệu lực" },
    { id: 6, name: "Sắp xếp theo ngày kết thúc hiệu lực" },

  ],
  GiayPhep_SortHN: [
    { id: 1, name: "Sắp xếp theo số giấy phép" },
    { id: 2, name: "Sắp xếp theo ngày ký" },
    { id: 4, name: "Sắp xếp theo tên ĐVXCP" },
    { id: 5, name: "Sắp xếp theo ngày bắt đầu hiệu lực" },
    { id: 6, name: "Sắp xếp theo ngày kết thúc hiệu lực" },

  ],
  Sort: [
    { id: 1, name: "Tăng dần" },
    { id: 2, name: "Giảm dần" },
  ],
  TRANG_THAI_CAP_PHEP_NDD: [
    { id: 2, name: "Chưa cấp phép" },
    { id: 1, name: "Đã cấp phép" },
  ],
  GIAY_PHEP_CHECK: [
    { id: 1, name: "Có giấy phép" },
    { id: 2, name: "Chưa có giấy phép" }
  ],
  HIEU_LUC_GIAY_PHEP_BO: [
    { id: 0, ten: "Còn hiệu lực" },
    { id: 1, ten: "Hết hiệu lực" },
    { id: 2, ten: "Chờ phê duyệt" },
    { id: 3, ten: "Sắp hết hiệu lực" },
    { id: 4, ten: "Hết hiệu lực nhưng chưa có GP thay thế" },
    { id: -1, ten: "Chưa bắt đầu hiệu lực" },
    { id: 5, ten: "Chưa trình duyệt" },
    { id: 6, ten: "Đã trả lại" },
  ],

  HIEU_LUC_GIAY_PHEP_HN: [
    { id: 0, ten: "Còn hiệu lực" },
    { id: 1, ten: "Hết hiệu lực" },
    { id: 3, ten: "Sắp hết hiệu lực" },
    { id: 4, ten: "Hết hiệu lực nhưng chưa có GP thay thế" },
    { id: -1, ten: "Chưa bắt đầu hiệu lực" },
  ],
  HIEU_LUC_GIAY_PHEP_TINH: [
    { id: 0, ten: "Còn hiệu lực" },
    { id: 1, ten: "Hết hiệu lực" },
    { id: 3, ten: "Sắp hết hiệu lực" },
    { id: 4, ten: "Hết hiệu lực nhưng chưa có GP thay thế" },
    { id: -1, ten: "Chưa bắt đầu hiệu lực" },
  ],
  LOAI_HINH_GIAY_PHEP: [
    { id: 1, ten: "Cấp mới giấy phép" },
    { id: 2, ten: "Cấp lại giấy phép" },
    { id: 3, ten: "Gia hạn, điều chỉnh giấy phép" },
    { id: 4, ten: "Thu hồi giấy phép" },
  ],
  NNNT: [
    { id: 1, ten: "Sông nội tỉnh" },
    { id: 2, ten: "Sông liên tỉnh" },
    { id: 3, ten: "Sông lớn" },
    { id: 4, ten: "Hồ đô thị" },
    { id: 8, ten: "Hồ đảo" },
    { id: 5, ten: "Kênh" },
    { id: 6, ten: "Suối" },
    { id: 7, ten: "Cửa sông" },
  ],
  TEN_GP: {
    GPCTKTNDD: "Khai thác, sử dụng nước dưới đất ",
    GPCTNM: "Khai thác, sử dụng nước mặt ",
    GPCTNXT: "Xả nước thải vào nguồn nước ",
    GPCTTDNDD: "Thăm dò nước dưới đất ",
    GPCTNB: "Khai thác, sử dụng nước biển ",
    GPHNKNDD: "Hành nghề khoan nước dưới đất ",
  },
  LOAI_GP: [
    { id: 1, ten: "Khai thác, sử dụng nước dưới đất" },
    { id: 2, ten: "Khai thác, sử dụng nước mặt" },
    { id: 3, ten: "Xả nước thải vào nguồn nước" },
    { id: 4, ten: "Thăm dò nước dưới đất" },
    { id: 5, ten: "Khai thác, sử dụng nước biển" },
    { id: 6, ten: "Hành nghề khoan nước dưới đất" },
  ],
  Ten_GP_Dashboard: {
    GP_XT: ' Giấy phép',
    GP_NM: 'Giấy phép ',
    GP_NDDKT: 'Giấy phép  ',
    GP_NDDTD: '   Giấy phép',
    GP_NB: '  Giấy phép',
    GP_HN: 'Giấy phép   ',
  },
  TRANG_THAI: {
    conhieuluc: 0,
    hethieuluc: 1,
    chopheduyet: 2,
    saphethieuluc: 3,
    hethieulucchuathaythe: 4,
    chuabatdauhieuluc: -1,
    trinhpheduyet:5,
    tuchoiduyet:6
  },
  DATA_SELECT: [
    { name: "=", value: "=", },
    { name: ">", value: ">", },
    { name: "<", value: "<", },
    { name: "<=", value: "<=", },
    { name: ">=", value: ">=", },
  ],
  DATA_SELECT_GP: [
    { name: "=", value: "=", },
    { name: ">=", value: ">=", },
    { name: "<=", value: "<=", },
  ],
  TRANG_THAI_BCDK: [
    { id: 1, ten: "Chưa gửi phê duyệt" },
    { id: 2, ten: "Chờ phê duyệt" },
    { id: 3, ten: "Phê duyệt" },
    { id: 4, ten: "Không phê duyệt" },
  ],
  DANH_GIA_KQQT: [
    { id: 1, ten: "Vượt chỉ số" },
    { id: 2, ten: "Trong ngưỡng cho phép" },
  ],
  TRANG_THAI_DNCP: [
    { id: 1, ten: "Chưa gửi đề nghị" },
    { id: 2, ten: "Chờ xác nhận" },
    { id: 3, ten: "Đã xác nhận" },
    { id: 4, ten: "Trả lại" },
  ],
  HUONG_GIO: [
    { id: 9, ten: "-" },
    { id: 1, ten: "B" },
    { id: 2, ten: "ĐB" },
    { id: 3, ten: "Đ" },
    { id: 4, ten: "ĐN" },
    { id: 5, ten: "N" },
    { id: 6, ten: "TN" },
    { id: 7, ten: "T" },
    { id: 8, ten: "TB" },
  ],
  HE_SO_KQ: [
    { id: 0, ten: "0.6", value: 0.6 },
    { id: 1, ten: "0.8", value: 0.8 },
    { id: 2, ten: "0.9", value: 0.9 },
    { id: 3, ten: "1", value: 1 },
    { id: 4, ten: "1.1", value: 1.1 },
    { id: 5, ten: "1.2", value: 1.2 },
    { id: 6, ten: "1.3", value: 1.3 },
  ],

  HE_SO_KF: [
    { id: 0, ten: "0.9", value: 0.9 },
    { id: 1, ten: "1.0", value: 1.0 },
    { id: 2, ten: "1.1", value: 1.1 },
    { id: 3, ten: "1.2", value: 1.2 },
    { id: 4, ten: "1.3", value: 1.3 },
  ],
  TOAN_THOI_GIAN: [
    { id: 0, ten: "Toàn thời gian" },
    { id: 1, ten: "Theo giai đoạn" },
  ],
  PHE_DUYET: [
    { id: 0, name: "Đã phê duyệt" },
    { id: 1, name: "Chưa phê duyệt" },
  ],
  SU_CO: [
    { id: 0, name: "Đã xử lý" },
    { id: 1, name: "Chưa xử lý" },
  ],
  KET_QUA_QUAN_TRAC: [
    { id: 0, name: "Chưa đánh giá" },
    { id: 1, name: "Vượt chỉ số" },
    { id: 2, name: "Bình thường" },
  ],
  TRANG_THAI_CAP_NHAT: [
    { id: "THEM", name: "Thêm mới dữ liệu" },
    { id: "SUA", name: "Cập nhật dữ liệu" },
    { id: "XOA", name: "Xóa dữ liệu" },
  ],
  PHUONG_THUC_XAC_THUC: [
    { id: 0, name: "Xác thực token SSO" },
    { id: 1, name: "Xác thực qua bộ kết nối LGSP" },
  ],
  CAU_HINH_THOI_GIAN: {
    NM: "SHHL_NM",
    NDD_KT: "SHHL_NDD_KT",
    NDD_TD: "SHHL_NDD_TD",
    XT: "SHHL_XT",
    NB: "SHHL_NB",
    HN: "SHHL_HN",
    BCDK: "SoLanTraLai"
  },
  LOAI_TRAM_GIAM_SAT: [
    { id: -2, ten: "Chưa sử dụng" },
    { id: -1, ten: "Chưa kết nối" },
    { id: 1, ten: "Trạm tự động (FTP)" },
    { id: 2, ten: "Trạm tự động (WebService)" },
    { id: 3, ten: "Trạm định kỳ" }
  ],
  STATUS_GIAMSAT: [
    { id: 0, ten: "Bình thường" },
    { id: -2, ten: "Mất kết nối" },
    { id: -99, ten: "Chưa gửi dữ liệu" },
  ],
  LOAI_HINH_TIM_KIEM: [
    { id: "ngay", ten: "Ngày" },
    { id: "tuan", ten: "Tuần" },
    { id: "thang", ten: "Tháng" },
    { id: "quy", ten: "Quý" },
    { id: "nam", ten: "Năm" },
  ],
  Cap_Role: [
    { id: 1, ten: "superadmin" },
    { id: 2, ten: "admin theo lĩnh vực" },
    { id: 3, ten: "lãnh đạo, chuyên viên nghiệp vụ, phòng ban thuộc bộ" },
    { id: 4, ten: "doanh nghiệp" },
    { id: 5, ten: "lãnh đạo, chuyên viên nghiệp vụ, phòng ban thuộc sở" }
  ],
  Role_user: {
    Sa: 1,
    Admin: 2,
    LD_CV_BO: 3,
    DN: 4,
    LD_CV_SO: 5
  },

  MaTinh: {
    ThaiBinh: 34,
    HaiPhong: 31
  },
  Select_Cap: [
    { id: 0, name: "Toàn Quốc" },
    { id: 1, name: "Cấp Bộ" },
    { id: 2, name: "Cấp Sở" },
  ],
  Color_das: [
    { label: "Hành nghề khoan nước dưới đất ", color: "#dc3545" },
    { label: "Thăm dò nước dưới đất ", color: "#00b297" },
    { label: "Khai thác, sử dụng nước dưới đất ", color: "#7d5f3a" },
    { label: "Khai thác, sử dụng nước biển ", color: "#003d7e" },
    { label: "Khai thác, sử dụng nước mặt ", color: "#6ab3e6" },
    { label: "Xả nước thải vào nguồn nước ", color: "#F49917" },
  ],
   congTrinhToKhai :{
    3: "thuydien",
    4: "hochua",
    5: "dap",
    6: "trambom",
    7: "cong",
    8: "tramcapnuoc",
    9:"nhamaynuoc"
  },
  DonViLuuLuong: [
    { id: 1, ten: "m3/s" },
    { id: 2, ten: "m3/ngày đêm" },
  ],
};

export const groupFunctionsCode = {
  PORTAL: "tnn_cscore_1_1",
  CT_NM: "tnn_cscore_2",
  CT_NDD: "tnn_cscore_3",
  CT_XT: "tnn_cscore_4_1",
  CT_NB: "tnn_cscore_5_1",
  LICENSE: "tnn_cscore_6",
  TNN_INFO: "tnn_cscore_7",
  CATEGORY_QT: "tnn_cscore_8",
  TNN_QT: "tnn_cscore_9",
  KTTV_QT: "tnn_cscore_10",
  CATEGORY: "tnn_cscore_11",
  MAP: "tnn_cscore_12",
  STATISTIC: "tnn_cscore_13",
  LOG: "tnn_cscore_14_1",
  DLQT: "tnn_cscore_15",
  CHTSQT: "tnn_cscore_16",
  DN: "tnn_cscore_17",
  BM_TT_31: "tnn_cscore_18",
  BM_TT_20: "tnn_cscore_19",
  PL: "tnn_cscore_20",
  QTHeThong: "tnn_cscore_21",
  CongTrinh: "tnn_cscore_22",
  BCDN: "tnn_cscore_23",
  TKDN: "tnn_cscore_24_1",
  TKDKY: "tnn_cscore_25",

};

export const functionsCode = {
  PORTAL: "tnn_cscore_1_1",

  //CT
  CongTrinh_XT: "tnn_cscore_22_1",
  CongTrinh_NB: "tnn_cscore_22_2",
  CongTrinh_NM_TD: "tnn_cscore_22_3",
  CongTrinh_NM_HC: "tnn_cscore_22_4",
  CongTrinh_NM_TB: "tnn_cscore_22_5",
  CongTrinh_NM_BD: "tnn_cscore_22_6",
  CongTrinh_NM_Cong: "tnn_cscore_22_7",
  CongTrinh_NM_TCN: "tnn_cscore_22_8",
  CongTrinh_NM_NMN: "tnn_cscore_22_9",
  CongTrinh_NDD_KT: "tnn_cscore_22_10",
  CongTrinh_NDD_TD: "tnn_cscore_22_11",
  //Công trình
  CT_NM_TD: "tnn_cscore_2_1",
  CT_NM_HC: "tnn_cscore_2_2",
  CT_NM_TB: "tnn_cscore_2_3",
  CT_NM_BD: "tnn_cscore_2_4",
  CT_NM_C: "tnn_cscore_2_5",
  CT_NM_TCN: "tnn_cscore_2_6",
  CT_NM_NMN: "tnn_cscore_2_7",
  CT_NDD_KT: "tnn_cscore_3_1",
  CT_NDD_TD: "tnn_cscore_3_2",
  CT_XT: "tnn_cscore_4_1",
  CT_NB: "tnn_cscore_5_1",
  //Giấy phép
  LICENSE_XT: "tnn_cscore_6_1",
  LICENSE_NM: "tnn_cscore_6_2",
  LICENSE_NB: "tnn_cscore_6_3",
  LICENSE_NDD_KT: "tnn_cscore_6_4",
  LICENSE_NDD_TD: "tnn_cscore_6_5",
  LICENSE_HNKNDD: "tnn_cscore_6_6",
  LICENSE_IMPORT: "tnn_cscore_6_7",
  LICENSE_QLKN: "tnn_cscore_6_8",
  //Thông tin TNN
  TNN_INFO_PLBC: "tnn_cscore_7_1",
  TNN_INFO_LBD: "tnn_cscore_7_2",
  TNN_INFO_SVLVS: "tnn_cscore_7_3",
  TNN_INFO_VBQPPL: "tnn_cscore_7_4",
  TNN_INFO_QHTNN: "tnn_cscore_7_5",
  TNN_INFO_DGTNND: "tnn_cscore_7_6",
  TNN_INFO_DTDG: "tnn_cscore_7_7",
  TNN_INFO_TT: "tnn_cscore_7_8",
  TNN_INFO_BVPCKP: "tnn_cscore_7_9",
  TNN_INFO_TTTNNK: "tnn_cscore_7_10",
  TNN_INFO_DLBD: "tnn_cscore_7_11",
  TNN_INFO_DNBC: "tnn_cscore_7_12",
  TNN_INFO_TCQ: "tnn_cscore_7_13",
  TNN_INFO_KQGPKN: "tnn_cscore_7_14",
  TNN_INFO_NCCBV: "tnn_cscore_7_15",
  TNN_INFO_KQTLPABC: "tnn_cscore_7_16",
  TNN_INFO_NNNT: "tnn_cscore_7_17",
  TNN_INFO_QLKTTV: "tnn_cscore_7_18",
  TNN_INFO_QLVBDP: "tnn_cscore_7_19",
  //Danh mục quan trắc
  CATEGORY_QT_DVD: "tnn_cscore_8_1",
  CATEGORY_QT_PPLM: "tnn_cscore_8_2",
  CATEGORY_QT_PPPT: "tnn_cscore_8_3",
  CATEGORY_QT_MDSD: "tnn_cscore_8_4",
  CATEGORY_QT_TQTN: "tnn_cscore_8_5",
  CATEGORY_QT_DQT: "tnn_cscore_8_6",
  CATEGORY_QT_KQT: "tnn_cscore_8_7",
  CATEGORY_QT_DVTH: "tnn_cscore_8_8",
  CATEGORY_QT_TSQT: "tnn_cscore_8_9",
  CATEGORY_QT_TCAD: "tnn_cscore_8_10",
  CATEGORY_QT_TCDG: "tnn_cscore_8_11",
  CATEGORY_QT_TQT: "tnn_cscore_8_12",
  //Dữ liệu quan trắc
  DLQT_NM: "tnn_cscore_15_1",
  DLQT_NDD: "tnn_cscore_15_2",
  DLQT_XT: "tnn_cscore_15_3",
  DLQT_KT: "tnn_cscore_15_4",
  DLQT_TV: "tnn_cscore_15_5",
  //Cấu hình thông số
  CHTSQT_TS: "tnn_cscore_16_1",
  CHTSQT_QC: "tnn_cscore_16_2",
  //Quan trắc TNN
  TNN_QT_TSQT: "tnn_cscore_9_1",
  TNN_QT_TCAP: "tnn_cscore_9_2",
  TNN_QT_TCDG: "tnn_cscore_9_3",
  TNN_QT_DQTDK: "tnn_cscore_9_4",
  TNN_QT_DQTCT: "tnn_cscore_9_5",
  TNN_QT_TQTN: "tnn_cscore_9_6",
  TNN_QT_MQTDK: "tnn_cscore_9_7",
  TNN_QT_MQTCT: "tnn_cscore_9_8",
  TNN_QT_CHTSQTN: "tnn_cscore_9_9",
  TNN_QT_CHTSTC: "tnn_cscore_9_12",
  TNN_QT_DGKTQTN: "tnn_cscore_9_10",
  TNN_QT_DGKTQTCT: "tnn_cscore_9_11",
  //Quan trắc KTTV
  KTTV_QT_TSQTKT: "tnn_cscore_10_1",
  KTTV_QT_TSQTTV: "tnn_cscore_10_2",
  KTTV_QT_TQT: "tnn_cscore_10_3",
  KTTV_QT_KQQT: "tnn_cscore_10_4",
  KTTV_QT_KQTS: "tnn_cscore_10_5",
  //Danh mục
  CATEGORY_LVB: "tnn_cscore_11_1",
  CATEGORY_CSDL: "tnn_cscore_11_2",
  CATEGORY_LCT: "tnn_cscore_11_3",
  CATEGORY_KTT: "tnn_cscore_11_4",
  CATEGORY_DVQLCT: "tnn_cscore_11_5",
  CATEGORY_KCN: "tnn_cscore_11_6",
  CATEGORY_TCCNDCP: "tnn_cscore_11_7",
  CATEGORY_LN: "tnn_cscore_11_8",
  CATEGORY_NM: "tnn_cscore_11_9",
  CATEGORY_MDSD: "tnn_cscore_11_10",
  CATEGORY_LHNT: "tnn_cscore_11_11",
  CATEGORY_DVTD: "tnn_cscore_11_12",
  CATEGORY_LBC: "tnn_cscore_11_13",
  CATEGORY_LBD: "tnn_cscore_11_14",
  CATEGORY_VKT: "tnn_cscore_11_15",
  CATEGORY_CQBH: "tnn_cscore_11_16",
  CATEGORY_NNNT: "tnn_cscore_11_17",
  CATEGORY_LSC: "tnn_cscore_11_18",
  CATEGORY_LH: "tnn_cscore_11_19",
  CATEGORY_LVB_DP: "tnn_cscore_11_20",
  //Map
  MAP_TCTNN: "tnn_cscore_12_1",
  MAP_SVLVS: "tnn_cscore_12_2",

  //Bao cáo thống kê
  STATISTIC_1: "tnn_cscore_13_1",
  STATISTIC_9: "tnn_cscore_13_9",
  STATISTIC_10: "tnn_cscore_13_10",
  STATISTIC_11: "tnn_cscore_13_11",
  STATISTIC_16: "tnn_cscore_13_16",
  ADDENDUM_2: "tnn_cscore_13_PL_2",
  ADDENDUM_3: "tnn_cscore_13_PL_3",
  ADDENDUM_5: "tnn_cscore_13_PL_5",
  ADDENDUM_6: "tnn_cscore_13_PL_6",


  //Nhật ký hệ thống
  LOG: "tnn_cscore_14_1",
  LOG_DB: "tnn_cscore_14_2",
  //Doanh nghiep
  DN_BCDN: "tnn_cscore_17_1",

  // Biểu mẫu thông tư 31
  BM_TT_31_9: "tnn_cscore_18_1",
  BM_TT_31_10: "tnn_cscore_18_2",
  BM_TT_31_11: "tnn_cscore_18_3",
  BM_TT_31_16: "tnn_cscore_18_4",
  BM_TT_31_20: "tnn_cscore_18_5",
  BM_TT_31_15: "tnn_cscore_18_6",
  BM_TT_31_6: "tnn_cscore_18_7",
  BM_TT_31_12: "tnn_cscore_18_8",
  BM_TT_31_17: "tnn_cscore_18_9",
  BM_TT_31_19: "tnn_cscore_18_10",

  // Biểu mẫu thông tư 20
  BM_TT_20_0203: "tnn_cscore_19_1",
  BM_TT_20_0205: "tnn_cscore_19_2",
  BM_TT_20_0206: "tnn_cscore_19_3",
  // phụ lục 
  PL_2: "tnn_cscore_20_1",
  PL_3: "tnn_cscore_20_2",
  PL_5: "tnn_cscore_20_3",
  PL_6: "tnn_cscore_20_4",
  PL_GP_SO: "tnn_cscore_20_5",
  //Quản trị hệ thống
  QTHeThong_NhatKy: "tnn_cscore_21_1",
  QTHeThong_ThongKeSuCo: "tnn_cscore_21_2",
  QTHeThong_HDSD: "tnn_cscore_21_3",
  QTHeThong_NhatKy_db: "tnn_cscore_21_4",
  //Báo cáo doanh nghiệp
  BCDN_TNN: "tnn_cscore_23_1",
  BCDN_CNCP: "tnn_cscore_23_2",
  BCDN_HDSD: "tnn_cscore_23_3",
  BCDN_TTHC: "tnn_cscore_23_4",
  //Tìm kiếm doanh nghiệp
  TKDN: "tnn_cscore_24_1",
  //Tờ khai đăng ký
  TOKHAI_1: 'tnn_cscore_25_1',
  TOKHAI_2: 'tnn_cscore_25_2',
  TOKHAI_3: 'tnn_cscore_25_3',
  TOKHAI_4: 'tnn_cscore_25_4',
  TOKHAI_5: 'tnn_cscore_25_5',
  TOKHAI_6: 'tnn_cscore_25_6',
  TOKHAI_7: 'tnn_cscore_25_7',


};

export default Constant;
