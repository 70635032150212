import React from "react";
// Components
import RowTools from "../../../../components/Table/RowTools";

const columns = ({ showFile } = {}) =>
  [
    {
      // key: "Id",
      title: "#",
      dataIndex: "stt",
      width: "35px"
    },
    {
      // key: "",
      title: "Số giấy phép, ngày ký",
      dataIndex: "svb_ngayky",
      width: "130px",
      render: value => <span className="row-name">{value}</span>
    },
    // {
    //   // key: "",
    //   title: "Tên công trình",
    //   dataIndex: "a",
    //   width: "200px",
    //   render: value => <span className="row-name">{value}</span>
    // },
    {
      key: "tools",
      title: "",
      dataIndex: "tools",
      align: "center",
      width: "40px",
      render: (value, row) => (
        <RowTools row={row} iconDelete={true} iconEdit={true} iconFile={true} showFile={showFile}/>
      )
    }
  ].map(col => ({
    ...col,
  }));

export default columns;
