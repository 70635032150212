import React, { Component } from "react";
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import * as L from 'leaflet';
import { MapContainer, TileLayer, WMSTileLayer, LayersControl, Marker, Popup, useMap } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import CustomWMSLayer from "./ClickInfo";
import Api_tnn from "../../../api/api.tnn";
import message from "../../../components/Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import constants from "../../../constants/constant"
import { Modal } from "antd"
import ControllerSearch from "./ThongTinChiTiet"
import Api_spatial from "../../../api/api.spatial";
import icon_NM from '../../../assets/images/CTNM.png'
import icon_XT from '../../../assets/images/CTXT.png';
import icon_NDD from '../../../assets/images/CTNDD.png';
import { APP_GEO_URI } from "../../../config"
import BanDoVietNam from "../TraCuuTNN/BanDoVN";
import Geocoder_marker from "../TraCuuTNN/geocode";
class SongVaLuuVuc extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      displayTab: "none",
      url_clickInfo: undefined,
      gid_song: undefined,
      data_marker: {
        data_diemXT: [],
        data_diemNM: [],
        data_diemNDD: []
      }
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  GetSpatialCT_BySong = (congtrinh) => {
    new Api_spatial()
      .GetSpatialCT_BySong({ token: this.props.user.access_token, congtrinh: congtrinh })
      .then((res) => {
        let data_diemXT = []
        let data_diemNM = []
        let data_diemNDD = []
        if (res && res.tnn_diemxathai_intersect && res.tnn_diemxathai_intersect.length > 0) {
          data_diemXT = res.tnn_diemxathai_intersect
          data_diemXT.map((value, index) => {
            let toado_diem = value.toado.slice(6, -1).split(" ")
            value.toado = [Number(toado_diem[1]), Number(toado_diem[0])]
          })
        }
        if (res && res.tnn_congtrinh_diem_intersect && res.tnn_congtrinh_diem_intersect.length > 0) {
          let data = res.tnn_congtrinh_diem_intersect
          data.map((value, index) => {
            let toado_diem = value.toado.slice(6, -1).split(" ")
            value.toado = [Number(toado_diem[1]), Number(toado_diem[0])]
          })
          data_diemNM = data.filter(item => item.loaicongtrinh !== 1 && item.loaicongtrinh !== 2)
          data_diemNDD = data.filter(item => item.loaicongtrinh === 1 || item.loaicongtrinh === 2)
        }
        this.setState({
          data_marker: {
            data_diemXT: data_diemXT,
            data_diemNM: data_diemNM,
            data_diemNDD: data_diemNDD
          }
        })
      })
      .catch((err) => {
        message.error(err.message);
      })
  }

  showData = (data) => {
    this.setState({
      url_clickInfo: data,
      displayTab: "block",
      gid_song: data[0].gid_song
    })
    this.GetSpatialCT_BySong(data)
  }

  hideTab = () => {
    const { displayTab } = this.state;
    if (displayTab === "none") {
      this.setState({
        displayTab: "block",
      });
    } else {
      this.setState({
        displayTab: "none",
      });
    }
  };

  render() {
    const center = [16.371824, 107.549195]
    const { displayTab, url_clickInfo, gid_song, data_marker } = this.state
    const { user } = this.props
    const URL = APP_GEO_URI

    //  Create the Icon

    const NM_Icon = L.icon({
      iconUrl: icon_NM,
      className: 'custom-icon-song',
      popupAnchor: [3, -19]
    })
    const XT_Icon = L.icon({
      iconUrl: icon_XT,
      className: 'custom-icon-song',
      popupAnchor: [3, -19]
    })
    const NDD_Icon = L.icon({
      iconUrl: icon_NDD,
      className: 'custom-icon-song',
      popupAnchor: [3, -19]
    });
    function Location_Tinh() {
      const map = useMap();
      if (Number(constants.CAP_TRIEN_KHAI) === 2) {
        new Api_spatial()
          .GetSpatialTinhs({
            token: user.access_token,
            tentinh: constants.TEN_DVHC
          })
          .then((res) => {
            if (res && res.result) {
              let toado = res.result.slice(6, -1).split(" ")
              map.flyTo([Number(toado[1]), Number(toado[0])], 10);
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
      return null
    }
    return (
      <div>
        <div id="mapid" style={{ height: window.innerHeight - 42 }}>
          <MapContainer center={center} zoom={6} style={{ height: '100%' }}>
            <Location_Tinh />
            <ControllerSearch displayTab={displayTab} url_clickInfo={url_clickInfo} hideTab={() => this.hideTab()} gid_song={gid_song} data_congtrinh={(congtrinh) => this.GetSpatialCT_BySong(congtrinh)} />
            <LayersControl position="topright">
              <BanDoVietNam opacity={1} />
              <LayersControl.Overlay name="Google map vệ tinh (Tham khảo)">
                {Number(constants.CAP_TRIEN_KHAI) === 1 &&
                  <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_BO} type={'satellite'} zIndex='3' />
                }
                {Number(constants.CAP_TRIEN_KHAI) === 2 && Number(constants.MA_TINH) === 31 &&
                  <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_DP} type={'satellite'} zIndex='3' />
                }
              </LayersControl.Overlay>
              <LayersControl.Overlay name="Google map đường phố (Tham khảo)">
                {Number(constants.CAP_TRIEN_KHAI) === 1 &&
                  <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_BO} type={'hybrid'} zIndex='4' />
                }
                {Number(constants.CAP_TRIEN_KHAI) === 2 && Number(constants.MA_TINH) === 31 &&
                  <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_DP} type={'hybrid'} zIndex='4' />
                }
              </LayersControl.Overlay>
              <LayersControl.Overlay checked name="Lớp sông">
                < WMSTileLayer
                  layers={"cscore.spatial" + constants.GEO_WMS + ":tnn_songtoanquoc"}
                  params={{
                    transparent: true,
                    format: 'image/png',
                  }}
                  url={URL}
                />
              </LayersControl.Overlay >
              <CustomWMSLayer
                url={URL}
                showData={(data, url) => this.showData(data)}
                layer={["cscore.spatial" + constants.GEO_WMS + ":tnn_songtoanquoc"]}
                user={user}
              />
              {data_marker.data_diemXT.map((item, index) => (
                <Marker position={item.toado} icon={XT_Icon}>
                  <Popup>
                    {item.tencongtrinh}
                  </Popup>
                </Marker>
              ))}
              {data_marker.data_diemNM.map((item, index) => (
                <Marker position={item.toado} icon={NM_Icon}>
                  <Popup>
                    {item.tencongtrinh}
                  </Popup>
                </Marker>
              ))}
              {data_marker.data_diemNDD.map((item, index) => (
                <Marker position={item.toado} icon={NDD_Icon}>
                  <Popup>
                    {item.tencongtrinh}
                  </Popup>
                </Marker>
              ))}
            </LayersControl>
          </MapContainer >
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(SongVaLuuVuc);
