import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import L from "leaflet";
import "leaflet-measure";
import { useMap } from "react-leaflet";
import message from "../../../../components/Messages";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import { Icon, Typography } from "antd";
import styled from "styled-components";
import Button from "../../../../components/Buttons";
import 'leaflet-measure/dist/leaflet-measure.vn.js'
const { Title } = Typography;

const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;
var check = 0
var measure = null
const DoKhoangCach = ({ }) => {
    const divRef = React.useRef(null);
    const [khoangCach, setkhoangCach] = useState(null);
    const [dienTich, setdienTich] = useState(null);
    const map = useMap();

    if (check === 0) {
        var measureControl = L.control.measure({
            primaryLengthUnit: "kilometers",
            secondaryLengthUnit: 'meters',
            primaryAreaUnit: 'hectares'
        });
        measureControl.addTo(map);
        measure = measureControl
        check += 1
    }

    return (
        <div ref={divRef}>
            <Title level={4}>{typo.cscore_tnn_khoangcach}</Title>
            <hr />
            <div>
                {khoangCach && <div style={{display: 'flex'}}><b>{"Khoảng cách: "}</b>{khoangCach}</div>}
                {dienTich && <div style={{display: 'flex'}}><b>{"Diện tích: "}</b>{dienTich}</div>}
            </div>
            <ControllerButton>
                <Button type="excel" style={{marginRight: '10px'}} onClick={() => measure !== null && measure._startMeasure()}>
                    <Icon type="check" theme="outlined" />
                    {typo.cscore_tnn_start}
                </Button>
                <Button type="outline" style={{marginRight: '10px'}} onClick={() => {
                    setkhoangCach(measure._resultsModel && measure._resultsModel.lengthDisplay)
                    setdienTich(measure._resultsModel && measure._resultsModel.areaDisplay)
                }}>
                    <Icon type="drag" theme="outlined" />
                    Đo
                </Button>
                <Button type="cancel" onClick={() => { measure !== null && measure._latlngs && measure._latlngs.length > 0 && measure._finishMeasure() }}>
                    <Icon type="close" theme="outlined" />
                    {typo.cscore_tnn_end}
                </Button>
            </ControllerButton>
        </div>
    );
};
export default DoKhoangCach;
