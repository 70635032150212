import React from "react";
import {Input, Form, Icon, Row} from "antd";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./Input.module.scss";
import styled from "styled-components";

export default function InputComponents({
                                          width,
                                          type,
                                          placeholder = "Tìm kiếm",
                                          ...rest
                                        }) {
  const noteLabel = label => {
    return (
      <span>
        <span style={{color: "red"}}>* </span>
        {label}
      </span>
    );
  };

  return (
    <DivInput>
      <Input
        placeholder={placeholder}
        style={{width: width || "100%"}}
        className={cx(styles[type], styles.Input)}
        {...rest}
        addonAfter={<Icon type="search"/>}
      />
    </DivInput>
  );
}
const DivInput = styled.div`
  margin-top: 1px;
`
InputComponents.propTypes = {
  width: PropTypes.string,
};
