import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon } from 'antd'
import { Formik, FastField } from 'formik'
import typo from '../../../../constants/dictionaries'
import { isEmpty } from "lodash";
import NumberField from "../../../Common/NumberField"
import moment from "moment";
// Utils

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";
import DateField from "../../../Common/DateField";
import Helper from "../../../../helper/helper";

const schema = Yup.object().shape({
  so_quyetdinh: Yup.string().required(typo.cscore_tnn_required),
});

class TCQKTSDModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  handleUpdateCLNuocThai = (list) => {
    this.setState({ listCLNuocThai: list })
  };

  render() {
    const { isOpen, handleCancel, handleOk, initialValues, mode, user } = this.props;

    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_tcqktsd}
        footer={false}
        onCancel={handleCancel}
        width="40%"
        centered
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(value, actions) => {
            handleOk(value);
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({ handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <FastField
                  name="so_quyetdinh"
                  render={({ field }) => <Input label={typo.cscore_tnn_soQuyetDinh} id="so_quyetdinh" {...field} mode={mode} />}
                />
                {touched.so_quyetdinh && <ValidatedText type="danger">{errors.so_quyetdinh}</ValidatedText>}

                <DateField
                  placeholder={Helper.genSelectPL(typo.cscore_tnn_license_signed_date)}
                  label={typo.cscore_tnn_license_signed_date}
                  onChange={(value) => {
                    setFieldValue("ngayky", value && value.format())
                  }}
                  value={values.ngayky ? moment(values.ngayky) : null}
                />

                <NumberField
                  label={typo.cscore_tnn_tongTien}
                  placeholder={typo.cscore_tnn_tongTien}
                  mandatory={false} id="tong_tien"
                  onChange={value => setFieldValue("tong_tien", value ? value : 0)}
                  value={values.tong_tien}
                />

              </Row>
              <div className="mt-20" />
              {mode !== "presentation" &&
                <Row type="flex" justify="end" align="middle">
                  <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                  <div className="ml-10" />
                  <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
                </Row>}
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )} />
      </Modal>
    )
  }
}

TCQKTSDModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  /*Mode: add, edit, presentation*/
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(TCQKTSDModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

