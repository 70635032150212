import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon } from 'antd'
import { Formik, FastField } from 'formik'
import typo from '../../../../constants/dictionaries'
import { isEmpty } from "lodash";
import NumberField from "../../../Common/NumberField"
import DateField from "../../../../components/Common/DateField"
import Helper from "../../../../helper/helper"
import moment from "moment";
import FileDinhKemPlus from "../../../../components/ThongTinTNN/FileDinhKem/TablePlus"
import Box from "../../../../components/Box";
// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";
import Select from '../../../Common/Select';
import helper from "../../../../helper/helper";
import Api_tnn from "../../../../api/api.tnn";
import message from "../../../Messages";
const dateFormat = 'DD/MM/YYYY';
const ControllerFile = styled.div`
  padding-top: 30px;
`;
const schema = Yup.object().shape({
  // so_quyetdinh: Yup.string().required(typo.cscore_tnn_required),
});

class BVPCKPModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      initialValues: {},
      vungbaoho: false,
      modeAdd: true,
      date:null,
      data_QDPD:[],
      qdpd:undefined
    }
  }
  componentDidMount() {
    const {mode, initialValues} = this.props
    this.GetAPIDS_QDPheDuyetBVPCKP()
    if(mode === "edit"){
      this.setState({
        initialValues: initialValues,
        fileList: initialValues.fileDinhKems ? initialValues.fileDinhKems : [],
        vungbaoho: true,
        modeAdd: false
      })
    }
  }
  updateFileDinhKems = (items) => {
    this.setState({
      fileList: items
    })
  }
  GetAPIDS_QDPheDuyetBVPCKP = () => {
    const { user } = this.props;
    new Api_tnn()
      .GetBVPCKP_BaoCaos({
        token: this.props.user.access_token,
        strSearch: "",
        page: 0,
        size: 0,
      })
      .then(res => {
        this.setState({ data_QDPD: res.data })
      })
      .catch((err) => message.error(err.message))
  };
  render() {
    const { isOpen, handleCancel, handleOk, mode, user } = this.props;
    const { fileList, initialValues, data_QDPD, vungbaoho,date,qdpd, modeAdd } = this.state
    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_Add_Quyet_Dinh}
        footer={false}
        onCancel={handleCancel}
        width="800px"
        centered
      >
        <div>
          {modeAdd &&
          <div>
            <Row type="flex">
              <Col span={8}>
              <Select
                mandatory={false}
                placeholder={Helper.genSelectPL(typo.cscore_tnn_quyetDinhPheDuyet)}
                width="100%"
                label={typo.cscore_tnn_quyetDinhPheDuyet}
                name="quyetDinhPheDuyet"
                name_value="id"
                onSelect={(value, e) => {
                  let obj = data_QDPD.find(o => o.id === value);
                  this.setState({
                    vungbaoho: true,
                    initialValues: obj,
                    date: obj.ngayPheDuyet,
                    fileList:obj.fileDinhKems,
                    qdpd:value

                  })
                }}
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
                notFoundContent={null}
                value={qdpd}
                dataSource={data_QDPD}
              />
              </Col>
              <Col span={8} style={{paddingLeft:10}}>
              <DateField
                format={dateFormat}
                mandatory={false}
                placeholder={Helper.genSelectPL(typo.cscore_tnn_ngaypheduyet)}
                label={typo.cscore_tnn_ngaypheduyet}
                onChange={value => { this.setState({date: value && value.format()})}}
                value={date ? moment(date) : null}
              />
              </Col>
             <Col span={8} style={{paddingTop:24, paddingLeft:10}}>
             <Button onClick={() => {
                this.setState({
                  vungbaoho: true,
                  initialValues: {},
                  date:null,
                  fileList:[],
                  qdpd:undefined

                })
              }}><Icon type="plus" />Thêm mới quyết định phê duyệt</Button>
             </Col>
             
            </Row></div>}
          {vungbaoho &&
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(value, actions) => {
                  handleOk({...value, fileDinhKems: fileList ? fileList : []});
                  actions.resetForm({});
                }}
                validationSchema={schema}
                render={({ handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
                  <form onSubmit={handleSubmit} autoComplete={false}>
                    <Row>
                      <FastField
                        name="tenQuyHoach"
                        render={({ field }) => <Input label={typo.cscore_tnn_ten_quy_hoach} id="tenQuyHoach" {...field} mandatory={false}/>}
                      />
                      <FastField name="quyetDinhPheDuyet" render={({ field }) => (
                        <Input placeholder={typo.cscore_tnn_quyetDinhPheDuyet} label={typo.cscore_tnn_quyetDinhPheDuyet} mandatory={false}{...field} />)} />
                      <DateField
                        mandatory={false}
                        format={dateFormat}
                        placeholder={Helper.genSelectPL(typo.cscore_tnn_ngaypheduyet)}
                        label={typo.cscore_tnn_ngaypheduyet}
                        onChange={value => { setFieldValue("ngayPheDuyet", value && value.format()) }}
                        defaultValue={values.ngayPheDuyet}
                        value={values.ngayPheDuyet ? moment(values.ngayPheDuyet) : null}
                      />
                    </Row>
                    <Row>
                      <Col>
                        <ControllerFile>
                          <Box label={typo.cscore_tnn_fileDinhkem}>
                            <FileDinhKemPlus dataSource={fileList} updateDataSource={this.updateFileDinhKems} />
                          </Box>
                        </ControllerFile>
                      </Col>
                    </Row>
                    <div className="mt-20" />
                      <Row type="flex" justify="end" align="middle">
                        <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                        <div className="ml-10" />
                        <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
                      </Row>
                    {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
                  </form>
                )} />
            </div>}
        </div>
      </Modal>
    )
  }
}

BVPCKPModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  /*Mode: add, edit, presentation*/
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(BVPCKPModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

