import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as Yup from 'yup'
import {Row, Col, Modal, Icon} from 'antd'
import {Formik, FastField} from 'formik'
import typo from '../../../../constants/dictionaries'
import {isEmpty} from "lodash";
import helper from "../../../../helper/helper"

// Utils
import constant from "../../../../constants/constant";

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import Select from '../../../Common/Select'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";
import DateField from "../../../Common/DateField";
import moment from "moment";
import Box from "../../../Box";
import CLNuocThai from "../../CLNuocThai/Table";
import TSNDNuocThai from "../../TSNDNuocThai";
import ViewField from "../../../Common/ViewField";

const schema = Yup.object().shape({
  tenGiaiDoan: Yup.string().required(typo.cscore_tnn_required),
  loaiThoiHan: Yup.string().required(typo.cscore_tnn_required),
  thoiGianTu: Yup.string(),
  thoiGianDen: Yup.string(),
});

class TSQTModal extends React.Component {
  constructor(props) {
    super(props);
    const {list_qcvn, list_gioihan_thongso} = props.initialValues || {};
    this.state = {
      listCLNuocThai: list_qcvn ? JSON.parse(list_qcvn) : [],
      listGHThongSo: list_gioihan_thongso ? JSON.parse(list_gioihan_thongso) : [],
    };
    this.TSNDNuocThaiRef = React.createRef()
  }

  componentDidMount() {

  }

  handleUpdateCLNuocThai = (list) => {
    this.setState({listCLNuocThai: list})
  };

  render() {
    const {isOpen, handleCancel, handleOk, initialValues, mode, user} = this.props;
    const {listCLNuocThai, listGHThongSo} = this.state;

    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_chatLuongNuocThai}
        footer={false}
        onCancel={handleCancel}
        width="80%"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(value, actions) => {
            const list_qcvn = JSON.stringify(listCLNuocThai);
            const list_gioihan_thongso = this.TSNDNuocThaiRef && JSON.stringify(this.TSNDNuocThaiRef.current.getDataSource());
            handleOk({...value, list_qcvn, list_gioihan_thongso});
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty}) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <Col span={12}>
                  <GroupWrapper>
                    <Select
                      mode={mode}
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_loaiThoiHan)}
                      label={typo.cscore_tnn_loaiThoiHan}
                      dataSource={constant.LOAI_THOI_HAN}
                      name="ten"
                      name_value="id"
                      onSelect={(value, e) => {
                        setFieldValue("loaiThoiHan", value);
                        if (value === 1) setFieldValue("tenGiaiDoan", typo.cscore_tnn_toanThoiGian);
                        setFieldValue("tenThoiHan", e.props.children);
                      }} defaultValue={values.loaiThoiHan} key={values.loaiThoiHan}/>

                    <FastField
                      name="tenGiaiDoan"
                      render={({field}) => <Input label={typo.cscore_tnn_tenGiaiDoan} id="tenGiaiDoan" {...field} mode={mode}/>}
                    />
                    {touched.tenGiaiDoan && <ValidatedText type="danger">{errors.tenGiaiDoan}</ValidatedText>}

                    <FastField
                      name="tenDiemXT"
                      render={({field}) => <Input mandatory={false} label={typo.cscore_tnn_tenDiemXT} id="tenDiemXT" {...field} mode={mode}/>}
                    />
                  </GroupWrapper>
                </Col>
                <Col span={12}>
                  <GroupWrapper>
                    <DateField
                      mode={mode}
                      mandatory={false}
                      placeholder={helper.genSelectPL(typo.cscore_tnn_thoiGianTu)}
                      label={typo.cscore_tnn_thoiGianTu}
                      onChange={(value) => {
                        setFieldValue("thoiGianTu", value && value.format());
                      }} value={values.thoiGianTu ? moment(values.thoiGianTu) : null}/>

                    <DateField
                      mode={mode}
                      mandatory={false}
                      placeholder={typo.cscore_tnn_thoiGianDen}
                      label={typo.cscore_tnn_thoiGianDen}
                      onChange={(value) => {
                        setFieldValue("thoiGianDen", value && value.format())
                      }} value={values.thoiGianDen ? moment(values.thoiGianDen) : null}/>
                  </GroupWrapper>
                </Col>
              </Row>
              <Row className="mt-15"/>
              <Row>
                <Box size="small" label={typo.cscore_tnn_quyChuanApDung}>
                  <CLNuocThai mode={mode} dataSource={listCLNuocThai} handleUpdate={this.handleUpdateCLNuocThai}/>
                </Box>
                <Box size="small" label={typo.cscore_tnn_tsndNuocThai}>
                  <TSNDNuocThai
                    mode={mode}
                    user={user}
                    ref={this.TSNDNuocThaiRef}
                    listCLNuocThai={listCLNuocThai}
                    initialValue={listGHThongSo}/>
                </Box>
              </Row>
              <div className="mt-20"/>
              {mode !== "presentation" &&
              <Row type="flex" justify="end" align="middle">
                <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save"/>{typo.cscore_tnn_save}</Button>
                <div className="ml-10"/>
                <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
              </Row>}
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )}/>
      </Modal>
    )
  }
}
TSQTModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  /*Mode: add, edit, presentation*/
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(TSQTModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

