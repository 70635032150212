import React from "react";
import {Tag} from 'antd'
const Status = ({ trangthai } = {}) => {
  if (trangthai === "Đã được cấp phép") {
    return <Tag color="green">{trangthai}</Tag>
  } else if (trangthai === "Chưa được cấp phép") {
    return <Tag color="gold">{trangthai}</Tag>
  } else {
    return <div></div>
  }
};

export default Status;
