import React, { Component } from 'react';
import { Row, Col, Badge } from 'antd'
import Constant, { functionsCode, groupFunctionsCode } from '../../constants/constant';
import { isEmpty } from 'lodash'
import Helper from '../../helper/helper';
import Message from "../../components/Messages";
import typo, { MESSAGE } from "../../constants/dictionaries";
import XaThai from "../../assets/images/discharge.png";
import NuocMat from "../../assets/images/NuocMat.png"
import NuocBien from "../../assets/images/NuocBien.png"
import NDD from "../../assets/images/NuocDuoiDat.png"
import HanhNghe from "../../assets/images/HanhNghe.png"
export default class ContainerTotalStatistic extends Component {

    render() {
        const { functions } = this.props
        return (
            <Row style={{ background: "#eaecef", minWidth: 1115 }} type="flex" justify="space-between" align="middle">
                {this.props.data.map(items => (items.map(item =>
                    <Col span={8} className="col-dashboard">
                        <BoxTotalStatistic data={item} history={this.props.history} functions={functions} /></Col>)))}
            </Row>
        )
    }
}

class BoxTotalStatistic extends Component {
    ChuyenTrang = (value, capthamquyen) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
        if (value === Constant.Ten_GP_Dashboard.GP_NDDKT) {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&MienGTLHNT=%3D&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
        else if (value === Constant.Ten_GP_Dashboard.GP_NDDTD) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
                    this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&MienGTLHNT=%3D&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value === Constant.Ten_GP_Dashboard.GP_HN) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
                    this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value === Constant.Ten_GP_Dashboard.GP_NM) {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&MienGTLHNT=%3D&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
        else if (value === Constant.Ten_GP_Dashboard.GP_XT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&MienGTLHNT=%3D&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&Column=FullName&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}&MienGTLHNT=%3D&NangCao=false&PageNumber=1&PageSize=10&StrSearch=`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
    }

    ChuyenTrangshhl = (value_shhl) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_shhl === Constant.Ten_GP_Dashboard.GP_NDDKT) {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_shhl === Constant.Ten_GP_Dashboard.GP_NDDTD) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
                    this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_shhl === Constant.Ten_GP_Dashboard.GP_HN) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
                    this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_shhl === Constant.Ten_GP_Dashboard.GP_NM) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_shhl === Constant.Ten_GP_Dashboard.GP_XT) {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

    }
    ChuyenTrangcpd = (value_cpd) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_cpd === Constant.Ten_GP_Dashboard.GP_NDDKT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_cpd === Constant.Ten_GP_Dashboard.GP_NM) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_cpd === Constant.Ten_GP_Dashboard.GP_HN) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
                    this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${Constant.Select_Cap[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_cpd === Constant.Ten_GP_Dashboard.GP_NDDTD) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
                    this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_cpd === Constant.Ten_GP_Dashboard.GP_XT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }

    }
    ChuyenTranghhl = (value_hhl) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_hhl === Constant.Ten_GP_Dashboard.GP_NDDKT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_hhl === Constant.Ten_GP_Dashboard.GP_NM) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_hhl === Constant.Ten_GP_Dashboard.GP_HN) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
                    this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_hhl === Constant.Ten_GP_Dashboard.GP_NDDTD) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
                    this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_hhl === Constant.Ten_GP_Dashboard.GP_XT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }

    }
    ChuyenTrangctt = (value_ctt) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_ctt === Constant.Ten_GP_Dashboard.GP_NDDKT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[4].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_ctt === Constant.Ten_GP_Dashboard.GP_NM) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[4].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_ctt === Constant.Ten_GP_Dashboard.GP_HN) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
                    this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[3].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_ctt === Constant.Ten_GP_Dashboard.GP_NDDTD) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
                    this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[4].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_ctt === Constant.Ten_GP_Dashboard.GP_XT) {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[4].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${Constant.Select_Cap[1].id}&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[4].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }

    }
    setImg = (item) => {
        if (item === 'XẢ THẢI') {
            return XaThai
        } else if (item === 'NƯỚC MẶT') {
            return NuocMat
        } else if (item === 'NƯỚC BIỂN') {
            return NuocBien
        } else if (item === 'KHAI THÁC NƯỚC DƯỚI ĐẤT') {
            return NDD
        } else if (item === 'THĂM DÒ NƯỚC DƯỚI ĐẤT') {
            return NDD
        } else if (item === 'HÀNH NGHỀ KHOAN NƯỚC') {
            return HanhNghe
        }
    }
    render() {
        const { data } = this.props
        return (
            <div style={{ background: data.backgroundColor, borderRadius: 4, maxWidth: "600px", margin: 10 }}>
                <div className="box-dashboard">
                    <Row type="flex" style={{ borderBottom: "2px solid #fff", paddingBottom: 10 }}>
                        <Col span={8} style={{ textAlign: "center", paddingTop: 5 }}>
                            <img src={this.setImg(data.title)} style={{ width: 80, height: 80 }} />
                        </Col>
                        <Col span={16} style={{ paddingLeft: 10 }}>
                            <Row>
                                <div className="title-dashboard">{data.title}</div>
                                <div className="value-dashboard">{data.value}</div>
                            </Row>
                            <Row>
                                <Col span={12} >
                                    <Row type="flex" className="link hover-transform" onClick={() => { this.ChuyenTrang(data.indicator, 1) }}>
                                        <div className="title-chil">{typo.cscore_tnn_cap_bo + ":"}</div>
                                        <div className="value-chil" style={{paddingLeft: 5}}>{data.value_bo}</div>
                                    </Row>
                                </Col>
                                <Col span={12} style={{paddingLeft: 2}}>
                                    <Row type="flex" className="link hover-transform" onClick={() => { this.ChuyenTrang(data.indicator, 2) }}>
                                        <div className="title-chil">{typo.cscore_tnn_cap_dp + ":"}</div>
                                        <div className="value-chil" style={{paddingLeft: 5}}>{data.value_dp}</div>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span={12} className="link hover-transform" onClick={() => this.ChuyenTrangshhl(data.indicator)}>
                            <div className="title-chil">{data.indicator_shhl}</div>
                            <div className="value-chil" >{data.value_shhl}</div>
                        </Col>
                        <Col span={12} className="link hover-transform" style={{ paddingLeft: 10 }} onClick={() => this.ChuyenTranghhl(data.indicator)}>
                            <div className="title-chil">{data.indicator_hhl}</div>
                            <div className="value-chil">{data.value_hhl}</div>
                        </Col>
                    </Row>
                    <Row type="flex">
                        <Col span={12} className="link hover-transform" onClick={() => this.ChuyenTrangcpd(data.indicator)}>
                            <div className="title-chil">{data.indicator_chopheduyet}</div>
                            <div className="value-chil">{data.value_cpd}</div>
                        </Col>
                        <Col span={12} className="link hover-transform" style={{ paddingLeft: 10 }} onClick={() => this.ChuyenTrangctt(data.indicator)}>
                            <div className="title-chil">{data.indicator_chuathaythe}</div>
                            <div className="value-chil">{data.value_ctt}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        )

    }

}