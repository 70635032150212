import React from "react";
import { Popconfirm, Form, Row } from 'antd';
import PropTypes from "prop-types";

import Input from '../../Common/Input'
import Table from '../index.js'
import ViewTable from '../ViewTable'
import helper from '../../../helper/helper'


const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  static defaultProps = {
    Component: Input
  };
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, Component } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              pattern: '^([0-9]*[.])?[0-9]+$',
              message: `Trường thông tin kiểu số`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Component ref={node => (this.input = node)}
                  onPressEnter={this.save}
                  onBlur={this.save}
                  label={false} mandatory={false}/>)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {this.renderCell}
          </EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

EditableCell.propTypes = {
  Component: PropTypes.any
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSave = row => {
    const {updateDataSource, dataSource} = this.props;
    const newData = helper.replaceItem(row, dataSource, "stt");
    updateDataSource(newData)
  };

  render() {
    const {} = this.state;
    const {dataSource, columns} = this.props;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const editableColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => {
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }
        },
      };
    });
    return (
      <Row>
        <ViewTable
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={editableColumns}
          pagination={false}
          scroll={{ x: 500 }}
        />
      </Row>
    );
  }
}

EditableTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  updateDataSource: PropTypes.func.isRequired
};

export default EditableTable