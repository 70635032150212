import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const { Title } = Typography;

// Components

const PhuLuc2 = lazy(() => import('./PhuLuc2'));
const PhuLuc3 = lazy(() => import('./PhuLuc3'));
const PhuLuc5 = lazy(() => import('./PhuLuc5'));
const PhuLuc6 = lazy(() => import('./PhuLuc6'));
const TK_GP_SO= lazy(() => import('./ThongKeGPSo'));

export default ({ match }) => {
  const groupName = 'Phụ lục'
  const PhuLuc2Link = 'phu-luc-2'
  const PhuLuc3Link = 'phu-luc-3'
  const PhuLuc5Link = 'phu-luc-5'
  const PhuLuc6Link = 'phu-luc-6'
  const TK_GP_SO_Link = 'thongke-gp-so'

  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
       
        <Route exact path={`${match.path}/${PhuLuc2Link}`} render={(props) => <PhuLuc2 {...props} path={`${match.path}/${PhuLuc2Link}`} groupName={groupName} name={typo.cscore_tnn_PhuLuc2} />} />
        <Route exact path={`${match.path}/${PhuLuc3Link}`} render={(props) => <PhuLuc3 {...props} path={`${match.path}/${PhuLuc3Link}`} groupName={groupName} name={typo.cscore_tnn_PhuLuc3} />} />
        <Route exact path={`${match.path}/${PhuLuc5Link}`} render={(props) => <PhuLuc5 {...props} path={`${match.path}/${PhuLuc5Link}`} groupName={groupName} name={typo.cscore_tnn_PhuLuc5} />} />
        <Route exact path={`${match.path}/${PhuLuc6Link}`} render={(props) => <PhuLuc6 {...props} path={`${match.path}/${PhuLuc6Link}`} groupName={groupName} name={typo.cscore_tnn_PhuLuc6} />} />
        <Route exact path={`${match.path}/${TK_GP_SO_Link}`} render={(props) => <TK_GP_SO {...props} path={`${match.path}/${TK_GP_SO_Link}`} groupName={groupName} name={typo.cscore_tnn_TK_GP_SO} />} />

      </Switch>
    </Suspense>
  )
}