import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import helper from "../../../../helper/helper";
import DateView from "../../../Common/View/DateView";
import KQKTDNModal from "../Modal";

// Columns
const ketqua = {
  0: "Đạt",
  1: "Không đạt",
};
const columns_form = (showEditModal, deleteRowModal) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
    fixed: 'left',
  },
  {
    title: "Tên doanh nghiệp",
    dataIndex: "tenDoanhNghiep",
  },
  {
    title: "Tên công trình",
    dataIndex: "tenCongTrinh",
  },
  {
    title: "Ngày kiểm tra",
    dataIndex: "soTienDaThu",
    render: (value, row) => <DateView value={value} />,
    width: "120px",
  },
  {
    title: "Kết quả kiểm tra",
    dataIndex: "kqKiemTra",
    render: (value, row) => <div>{ketqua[value]}</div>,
    width: "120px",
  },
  {
    title: "Kết quả khăc phục",
    dataIndex: "kqKhacPhuc",
  },
  {
    title: "Các lỗi chuyển xử phạt",
    dataIndex: "cacLoiXuPhat",
  },
  {
    title: "Kết quả xử phạt",
    dataIndex: "kqXuPhat",
  },
  {
    title: "Số tiền",
    dataIndex: "soTien",
  },
  {
    title: "Thao tác",
    fixed: 'right',
    render: (value, row) => (
      <RowTools row={row} deleteRowModal={() => deleteRowModal(row)} showEditModal={showEditModal} />
    ),
    width: "80px"
  },
];

const columns_presentation = (handleShowDetail) => [
  // {
  //   title: "#",
  //   dataIndex: "stt",
  //   width: "45px",
  //   fixed: 'left',
  // },
  {
    title: "Ngày kiểm tra",
    dataIndex: "soTienDaThu",
    render: (value, row) => <DateView value={value} />,
    width: "120px",
  },
  {
    title: "Kết quả kiểm tra",
    dataIndex: "kqKiemTra",
    render: (value, row) => <div>{ketqua[value]}</div>,
    width: "120px",
  },
  {
    title: "Các lỗi chuyển xử phạt",
    dataIndex: "cacLoiXuPhat",
  },
  {
    title: "Kết quả xử phạt",
    dataIndex: "kqXuPhat",
  },
  {
    title: "Số tiền",
    dataIndex: "soTien",
  },
 
];

export default class ThanhTraDN extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      miningQuantity: [],
      selectedRow: null
    }
  }

  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };

  handleShowDetail = (row) => {
    this.setState({
      selectedRow: row,
    })
  };

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    // console.log("item", item)
    const { dataSource, handleUpdate } = this.props;
    const newData = [{ ...item }, ...dataSource];
    this.handleCloseModal();
    handleUpdate(newData);
  };

  handleEdit = (item) => {
    const { dataSource, handleUpdate, field } = this.props;
    const newData = helper.replaceItem(item, dataSource);
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const { dataSource, handleUpdate, field } = this.props;
    const newData = helper.removeItem(item, dataSource);
    handleUpdate(newData, field);
  };

  addSttToItems = (items) => {
    if (!items) return [];
    let newItems = [];
    if (items) {
      newItems = items.map((item, index) => ({ ...item, stt: index + 1 }))
    }
    return newItems;
  };

  render() {
    const { isAddModalOpen, isEditModalOpen, selectedRow } = this.state;
    const { dataSource, mode } = this.props;

    return (
      <Row>
        <Row type="flex" justify="end" align="middle">
          {mode !== "presentation" &&
            <Button type="primary" onClick={this.handleOpenAddModal} icon="plus" />}
        </Row>
        <div className="mt-10" />
          <ViewTable
            bordered pagination={false}
            columns={mode !== "presentation" ? columns_form(this.handleOpenEditModal, this.handleDelete) : columns_presentation(this.handleShowDetail)}
            dataSource={this.addSttToItems(dataSource)}
          />


        {isAddModalOpen &&
          <KQKTDNModal
            mode="add"
            isOpen={isAddModalOpen}
            handleCancel={this.handleCloseModal}
            handleOk={this.handleAdd} />}

        {isEditModalOpen &&
          <KQKTDNModal
            mode="edit"
            initialValues={selectedRow}
            isOpen={isEditModalOpen}
            handleCancel={this.handleCloseModal}
            handleOk={this.handleEdit} />}
      </Row>
    )
  }
}

ThanhTraDN.propTypes = {
  // MODE: string "form" and "presentation"
  mode: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
};