/*
 *  @2019 CS-Core project.
 *
 */

import React from "react";
import SomethingWentWrong from "../SomethingWentWrong";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any collections below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <SomethingWentWrong error={this.state.error} />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
