import React from 'react'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import TSQTModal from "./../Modal";
import helper from "../../../../helper/helper";
import DateView from "../../../Common/View/DateView";

// Columns
const columns_form = (showEditModal, deleteRowModal) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
    fixed: 'left',
  },
  {
    title: "Tên giai đoạn",
    dataIndex: "tenGiaiDoan",
    // width: "150px",
    fixed: 'left',
  },
  {
    title: "Tên điểm xả thải",
    dataIndex: "tenDiemXT",
    // width: "200px",
  },
  {
    title: "Thời hạn",
    dataIndex: "tenThoiHan",
    width: "150px",
  },
  {
    title: "Từ ngày",
    dataIndex: "thoiGianTu",
    width: "150px",
    render: (value, row) => <DateView value={value}/>,
  },
  {
    title: "Đến ngày",
    dataIndex: "thoiGianDen",
    width: "150px",
    render: (value, row) => <DateView value={value}/>,
  },
  {
    title: "Thao tác",
    render: (value, row) => (
      <RowTools row={row} deleteRowModal={() => deleteRowModal(row)} showEditModal={showEditModal}/>
    ),
    width: "80px"
  },
];

const columns_presentation = (handleShowDetail) => [
  // {
  //   title: "#",
  //   dataIndex: "stt",
  //   width: "45px",
  //   fixed: 'left',
  // },
  {
    title: "Tên giai đoạn",
    dataIndex: "tenGiaiDoan",
    render: (value, row) => <a onClick={() => handleShowDetail(row)}>{value}</a>
  },
  {
    title: "Tên điểm xả thải",
    dataIndex: "tenDiemXT",
  },
  {
    title: "Thời hạn",
    dataIndex: "tenThoiHan",
  },
  {
    title: "Từ ngày",
    dataIndex: "thoiGianTu",
    render: (value, row) => <DateView value={value}/>,
  },
  {
    title: "Đến ngày",
    dataIndex: "thoiGianDen",
    render: (value, row) => <DateView value={value}/>,
  },
];

export default class TSQT extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      isDetailModalOpen: false,
      miningQuantity: [],
      selectedRow: null
    }
  }

  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };

  handleShowDetail = (row) => {
    this.setState({
      selectedRow: row,
      isDetailModalOpen: true
    })
  };

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      isDetailModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    const {dataSource, handleUpdate} = this.props;
    const newData = [{...item,id: 0, stt: dataSource.length + 1}, ...dataSource];
    this.handleCloseModal();
    handleUpdate(newData);
  };

  handleEdit = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.replaceItem(item, dataSource, "stt");
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.updateArray(item, dataSource);
    handleUpdate(newData, field);
  };
  

  addSttToItems = (items) => {
    if (!items) return [];
    let newItems = [];
    if (items) {
      newItems = items.map((item, index) => ({...item, stt: index + 1}))
    }
    return newItems;
  };

  render() {
    const {isAddModalOpen, isEditModalOpen, isDetailModalOpen, selectedRow} = this.state;
    const {dataSource, mode} = this.props;

    return (
      <Row>
        <Row type="flex" justify="end" align="middle">
          {mode !== "presentation" &&
          <Button type="primary" onClick={this.handleOpenAddModal} icon="plus"/>}
        </Row>
        <div className="mt-10"/>
        <ViewTable
          bordered pagination={false}
          columns={mode !== "presentation" ? columns_form(this.handleOpenEditModal, this.handleDelete) : columns_presentation(this.handleShowDetail)}
          dataSource={this.addSttToItems(dataSource)}
        />
        {isAddModalOpen &&
        <TSQTModal
          mode="add"
          isOpen={isAddModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleAdd}/>}

        {isEditModalOpen &&
        <TSQTModal
          mode="edit"
          initialValues={selectedRow}
          isOpen={isEditModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleEdit}/>}

        {isDetailModalOpen &&
        <TSQTModal
          mode="presentation"
          initialValues={selectedRow}
          isOpen={isDetailModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleEdit}/>} 
      </Row>
    )
  }
}

TSQT.propTypes = {
  // MODE: string "form" and "presentation"
  dataSource: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
  mode: PropTypes.string.isRequired,
};