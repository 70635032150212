import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import message from "../../../../components/Messages";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import { Modal, Row, Icon, Typography } from "antd";
import styled from "styled-components";
import Constant from "../../../../constants/constant";
import ToaDoPlus from "../../../../components/CongTrinh/ToaDo/TableToado";
import Api from "../../../../api";
import Button from "../../../../components/Buttons";
import Box from "../../../../components/Box";
import helper from "../../../../helper/helper";
import Select from "../../../../components/Common/Select";
import Api_spatial from "../../../../api/api.spatial";
const { Title } = Typography;

const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;

const KiemTraToaDo = ({ user, CheckCongTrinhDiem, CheckCongTrinhVung }) => {
    const [LoaiKTT, setLoaiKTT] = useState(1);
    const [KTT, setKTT] = useState(72);
    const [MuiChieu, setMuiChieu] = useState(6);
    const [toaDo, settoaDo] = useState(false);
    const [data_KinhTuyenTruc, setdata_KinhTuyenTruc] = useState([]);
    useEffect(() => {
        GetApidmkinhtuyentrucs(LoaiKTT);
    }, []);
    function GetApidmkinhtuyentrucs(cap) {
        new Api().Getdmkinhtuyentrucs({
            token: user.access_token,
            page: "0",
            size: "0",
            cap: cap,
        })
            .then((res) => {
                setdata_KinhTuyenTruc(res && res.data.map((value) => ({
                    ...value,
                    tenHienThi: value.ten + " - " + value.tenDVHC,
                })))
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    function onSelectLKTT(value) {
        setLoaiKTT(value)
        setKTT(undefined)
        GetApidmkinhtuyentrucs(value)
    }
    function onSelectKTT(value) {
        setKTT(value)
    }
    function onSelectMC(value) {
        setMuiChieu(value)
    }
    function updateToaDos(value) {
        settoaDo(value)
    }
    async function searchToaDo() {
        const { degree, minute } = await helper.getKTTById(user.access_token, KTT);
        if (toaDo) {
            if (LoaiKTT === 1 && KTT === 72 && MuiChieu === 6) {
                if (toaDo.split(";").length === 1) {
                    CheckCongTrinhDiem(0, 0, 0, toaDo);
                } else if (toaDo.split(";").length === 2) {
                    // message.warning(MESSAGE.cscore_tnn_CHON_TOA_DO);
                    const toaDoHandleAddPointTheFirst = toaDo + ';' + toaDo.split(";")[0]
                    CheckCongTrinhVung(0, 0, 0, toaDoHandleAddPointTheFirst);
                } else {
                    CheckCongTrinhVung(0, 0, 0, toaDo);
                }
            } else {
                if (toaDo.split(";").length === 1) {
                    CheckCongTrinhDiem(degree, minute, MuiChieu, toaDo);
                } else if (toaDo.split(";").length === 2) {
                    message.warning(MESSAGE.cscore_tnn_CHON_TOA_DO);
                } else {
                    CheckCongTrinhVung(degree, minute, MuiChieu, toaDo);
                }
            }
        }

    }
    return (
        <div>
            <Title level={3}>{typo.cscore_tnn_kttd}</Title>
            <hr />
            <Select
                dataSource={Constant.CAP_THAM_QUYEN}
                label={typo.cscore_tnn_loaiKTT}
                onChange={(value) => onSelectLKTT(value)}
                placeholder={helper.genSelectPL(typo.cscore_tnn_chon_cap)}
                mandatory={false}
                width="200px"
                name="ten"
                name_value="id"
                defaultValue={LoaiKTT}
                key={LoaiKTT}
            />
            <Select
                mandatory={false}
                placeholder={helper.genSelectPL(typo.cscore_tnn_kinh_tuyen_truc)}
                label={typo.cscore_tnn_kinh_tuyen_truc}
                onChange={(value, name) => onSelectKTT(value)}
                dataSource={data_KinhTuyenTruc}
                width="200px"
                name={LoaiKTT === 2 ? "tenHienThi" : "ten"}
                name_value="id"
                defaultValue={KTT}
                key={KTT}
            />
            <Select
                mandatory={false}
                placeholder={helper.genSelectPL(typo.cscore_tnn_mui_chieu)}
                label={typo.cscore_tnn_mui_chieu}
                onChange={(value) => onSelectMC(value)}
                dataSource={Constant.MUI_CHIEU}
                width="200px"
                name="ten"
                name_value="id"
                defaultValue={MuiChieu}
                key={MuiChieu}
            />
            <Box label={typo.cscore_tnn_toaDo} style={{ marginTop: "20px" }}>
                <ToaDoPlus dataSource={toaDo} updateDataSource={(value) => updateToaDos(value)} />
            </Box>
            <ControllerButton>
                <Button type="outline" onClick={() => searchToaDo()}>
                    <Icon type="environment" theme="outlined" />
                    {typo.cscore_tnn_kiem_tra}
                </Button>
            </ControllerButton>
        </div>
    );
};
export default KiemTraToaDo;
