import React, {Component} from "react";
import {connect} from 'react-redux'
import {MESSAGE} from '../../../../constants/dictionaries'

//component
import {Table} from 'antd';

//helper
import columns from './columns'

class Categories extends Component {
  render() {
    const {dataSource = []} = this.props;
    var dataSourceIndex = dataSource ? dataSource.map((item, index) => ({
      ...item,
      x: item.toaDo.replace("(", "").replace(")", "").split(",")[0],
      y: item.toaDo.replace("(", "").replace(")", "").split(",")[1],
      stt: index + 1
    })) : []
    return (
      <div className='div-table-detail'>
        <Table
          locale={{emptyText: MESSAGE.cscore_tnn_THERE_IS_NO_DATA}}
          columns={columns({})}
          dataSource={dataSourceIndex}
          pagination={{hideOnSinglePage: true}}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(Categories);