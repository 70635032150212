import React from 'react'
import { Tag, Row } from 'antd'
import PropTypes from 'prop-types'

//Components
import TextField from '../../Common/ViewField'

// Constant
import typo from '../../../constants/dictionaries'
import helper from '../../../helper/helper'
import { RowTableDetail, TableDetail } from "../../Common/View/TableViewDetail";
import constant from '../../../constants/constant'
import DateView from "../../Common/View/DateView";
import TTKT from "../../AttachTable/TTKT/Table";
import Container from "../../../containers/Container";

const LicenseResult = ({ data, loaiGP }) => {
  const {
    chucVu,
    coQuanCap,
    ngayBDHieuLuc,
    ngayKTHieuLuc,
    ngayKy,
    nguoiKy,
    soVanBan,
    tenVanBan,
    thoiHan,
    loaiCapPhep,
    loaiHinhGP,
    ghiChu,
    ngayCapPhepCu,
    soGiayPhepCu,
    noiDungCapLai,
    noiDungDieuChinh,
    thoiHanGiaHan
  } = data || {};
  return (
    <Row>
      <TableDetail>
        {loaiGP && <RowTableDetail title={typo.cscore_tnn_drawer_cp_tnn}><Tag color="green">{loaiGP}</Tag></RowTableDetail>}
        <RowTableDetail title={typo.cscore_tnn_license_type}>{loaiCapPhep}</RowTableDetail>
        {(loaiHinhGP == constant.DICT_LHGP.CAP_MOI || loaiHinhGP == constant.DICT_LHGP.CAP_LAI) &&
          <RowTableDetail title={typo.cscore_tnn_thoiHanGiayPhep}>{thoiHan}</RowTableDetail>}

        {loaiHinhGP != constant.DICT_LHGP.CAP_MOI &&
          <>
            <RowTableDetail title={typo.cscore_tnn_soGiayPhepCu}>{soGiayPhepCu}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_ngayCapPhepCu}>{helper.formatDate(ngayCapPhepCu)}</RowTableDetail>
          </>}

        {loaiHinhGP == constant.DICT_LHGP.CAP_LAI &&
          <RowTableDetail title={typo.cscore_tnn_noiDungCapLai}>{noiDungCapLai}</RowTableDetail>}

        {loaiHinhGP == constant.DICT_LHGP.GIA_HAN &&
          <>
            <RowTableDetail title={typo.cscore_tnn_thoiHanGiaHan}>{thoiHanGiaHan}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_noiDungDieuChinh}>{noiDungDieuChinh}</RowTableDetail>
          </>}

        <RowTableDetail title={typo.cscore_tnn_license_number}>{soVanBan}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_document}>{tenVanBan}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_agency}>{coQuanCap}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_signed_person}>{nguoiKy}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_position}>{chucVu}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_signed_date}>{helper.formatDate(ngayKy)}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_available_date}>{helper.formatDate(ngayBDHieuLuc)}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_end_date}>{helper.formatDate(ngayKTHieuLuc)}</RowTableDetail>
        <RowTableDetail title={typo.cscore_tnn_ghiChu}>{ghiChu}</RowTableDetail>
        {/*<RowTableDetail title={typo.cscore_tnn_license_place_received}>{noiNhan}</RowTableDetail>*/}
      </TableDetail>
    </Row>
  )
};

LicenseResult.propTypes = {
  data: PropTypes.any.isRequired,
};

export default LicenseResult
