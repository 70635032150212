import React, { Component, useState } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON } from 'react-leaflet'
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import Api_spatial from "../../../api/api.spatial";

class GeoJson_Polygon extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: {
        data: null,
        style: null,
        key: null
      }
    }
  }



  componentDidMount() {
    const { tentinh, tenhuyen } = this.props
    tentinh && this.getApiTinhSpatial(tentinh)
    tenhuyen && this.getApiHuyenSpatial(tenhuyen)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { tentinh, tenhuyen } = this.props
    if (prevProps.tentinh !== tentinh) {
      tentinh && this.getApiTinhSpatial(tentinh)
    }
    if (prevProps.tenhuyen !== tenhuyen) {
      tenhuyen && this.getApiHuyenSpatial(tenhuyen)
    }
  }

  getApiTinhSpatial = (tentinh) => {
    const { zoomOut } = this.props
    new Api_spatial()
      .GetSpatialTinhs({
        token: this.props.user.access_token,
        tentinh: tentinh
      })
      .then((res) => {
        if (res && res.result) {
          let toado = res.result.slice(6, -1).split(" ")
          zoomOut([Number(toado[1]), Number(toado[0])])
        } else {
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  getApiHuyenSpatial = (tenhuyen) => {
    const { zoomOut } = this.props
    new Api_spatial()
      .GetSpatialHuyens({
        token: this.props.user.access_token,
        tenhuyen: tenhuyen,
      })
      .then((res) => {
        if (res && res.result) {
          let toado = res.result.slice(6, -1).split(" ")
          zoomOut([Number(toado[1]), Number(toado[0])])
        } else {
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  style(feature) {
    return {
      weight: 0.3,
      opacity: 0,
      color: "#000",
    };
  };

  render() {
    return (
      <div>
        {this.state.GeoJson_data.data != null &&
          <GeoJSON key={this.state.GeoJson_data.key} data={this.state.GeoJson_data.data} style={this.style} ref="geojson" />
        }
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Polygon);
