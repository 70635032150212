import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON, Popup, Tooltip } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import icon_NB from '../../../assets/images/NB-24.png';
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import constants from "../../../constants/constant"
const NB_Icon = new L.Icon({
  iconUrl: icon_NB,
  className: 'custom-icon',
  popupAnchor: [1, -34]
})
class GeoJson_Layer extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: [],
    }

  }



  componentDidMount() {
    const { GeoJson_data } = this.props
    this.setState({GeoJson_data})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
   
  }

  render() {
    const { GeoJson_data } = this.state
    const { viewToado } = this.props
    return (
      <div>
        {GeoJson_data && GeoJson_data.length > 0 &&
          <div>
            <div className="leaflet-mini-map-congtrinh">{"Công trình: " + viewToado.tenCongTrinh}</div>
            {GeoJson_data.map((item, index) => (
              item.data != null &&
              <GeoJSON ref="geojson" key={1} data={item.data} pointToLayer={(geoJsonPoint, latlng) => { return L.marker(latlng, { icon: NB_Icon }) }}>
                <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                  <b>{"Hạng mục: "}</b> {viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc).length > 0 ? viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc)[0].tenHangMuc : ""}
                  <Popup><b>{"Công trình: "}</b>{viewToado.tenCongTrinh}
                    <br />
                    <b>{"Địa điểm: "}</b> {viewToado.diaDiem}
                    <br />
                    <b>{"Tên hạng mục: "}</b> {viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc).length > 0 ? viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc)[0].tenHangMuc : ""}
                    <br />
                    <b>{"Tọa độ (x, y): "}</b>{viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc).length > 0 ? viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc)[0].toaDo : ""}
                  </Popup>
                </Tooltip>
              </GeoJSON>
            ))}
          </div>
        }
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

