import * as ActionTypes from "../../reducers/actions/action-type"
const types = {
  ADD_LIST_FUNCTIONS: "ADD_LIST_FUNCTIONS",
};

const addListFunctions = data => {
  return {
    type: types.ADD_LIST_FUNCTIONS,
    payload: data
  };
};
const addTinh = user => {
  return {
    type: ActionTypes.GET_DVHC_DS_TINH_REQUEST,
    user: user
  };
};
const addHuyenByTinh = (user, maTinh) => {
  return {
    type: ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_REQUEST,
    user,
    maTinh
  };
};
const addXaByHuyen = (user, maHuyen) => {
  return {
    type: ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_REQUEST,
    user,
    maHuyen
  };
};
const addProfile = (user) => {
  return {
    type: ActionTypes.GET_PROFILE_REQUEST,
    user,
  };
};
const addTokenGiamSat = (user) => {
  return {
    type: ActionTypes.GET_TOKEN_GIAMSAT_REQUEST,
    user,
  };
};
const addTokenLGSP = (user) => {
  return {
    type: ActionTypes.GET_TOKEN_LGSP_REQUEST,
    user,
  };
};
const addCauHinhGP = (user) => {
  return {
    type: ActionTypes.GET_CAUHINH_GP_REQUEST,
    user,
  };
};
const addLoaiCT_NM = (user, nhomct) => {
  return {
    type: ActionTypes.GET_LOAI_CT_REQUEST,
    user,
    nhomct
  };
};
const addKTT_TW = (user) => {
  return {
    type: ActionTypes.GET_KTT_TW_REQUEST,
    user,
  };
};
const addKTT_DP = (user) => {
  return {
    type: ActionTypes.GET_KTT_DP_REQUEST,
    user,
  };
};
const addLUUVUC_SONG = (user) => {
  return {
    type: ActionTypes.GET_LUUVUC_SONG_REQUEST,
    user,
  };
};

export default {
  types, addListFunctions, addTinh, addHuyenByTinh, addXaByHuyen, addProfile, addLUUVUC_SONG,
  addTokenGiamSat, addTokenLGSP, addCauHinhGP, addLoaiCT_NM, addKTT_TW, addKTT_DP
};
