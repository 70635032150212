import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'
import typo from "../../constants/dictionaries";
const {Title} = Typography;

// Components
const DonViQuanLyCongTrinh = lazy(() => import('./DonViQuanLyCongtrinh'));
const KhuCongNghiep = lazy(() => import('./KhuCongNghiep'));
const LangNghe = lazy(() => import('./LangNghe'));
const LoaiVanBan = lazy(() => import('./LoaiVanBan'));
const NhaMay = lazy(() => import('./NhaMay'));
const LoaiCongTrinh = lazy(() => import('./LoaiCongTrinh'));
const CoSoDuLieu = lazy(() => import('./CoSoDuLieu'));
const MucDichSuDungNuoc = lazy(() => import('./MucDichSuDungNuoc'));
const LoaiHinhNuocThai = lazy(() => import('./LoaiHinhNuocThai'));
const KinhTuyenTruc = lazy(() => import('./KinhTuyenTruc'));
const DVXCPList = lazy(() => import('./DonViXinCapPhep/List'));
const DVXCPForm = lazy(() => import('./DonViXinCapPhep/Form'));
const DonViThamDinh = lazy(() => import('./DonViThamDinh'));
const PhanLoaiBaoCao = lazy(() => import('./PhanLoaiBaoCao'));
const LoaiBanDo = lazy(() => import('./LoaiBanDo'));
const VungKinhTe = lazy(() => import('./VungKinhTe'));
const NguonNuocNoiTinh = lazy(() => import('./NguonNuocNoiTinh'))
const LoaiSuCo = lazy(() => import('./DanhMucLoaiSuCo'));
const CoQuanBanHanh = lazy(() => import('./CoQuanBanHanh'));
const ThongTinLienHe = lazy(() => import('./ThongTinLienHe'));
const LoaiVanBanDiaPhuong = lazy(() => import('./LoaiVanBanDiaPhuong'));
export default ({match}) => {
  const groupName = 'Danh mục'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/Don-vi-quan-ly-cong-trinh`} component={DonViQuanLyCongTrinh}/>
        <Route exact path={`${match.path}/Loai-van-ban-dia-phuong`} component={LoaiVanBanDiaPhuong}/>
        <Route exact path={`${match.path}/Khu-cong-nghiep`} component={KhuCongNghiep}/>
        <Route exact path={`${match.path}/Loai-van-ban`} component={LoaiVanBan}/>
        <Route exact path={`${match.path}/Lang-nghe`} component={LangNghe}/>
        <Route exact path={`${match.path}/Nha-may`} component={NhaMay}/>
        <Route exact path={`${match.path}/Loai-cong-trinh`} component={LoaiCongTrinh}/>
        <Route exact path={`${match.path}/Co-so-du-lieu`} component={CoSoDuLieu}/>
        <Route exact path={`${match.path}/Kinh-tuyen-truc`} component={KinhTuyenTruc}/>
        <Route exact path={`${match.path}/Muc-dich-su-dung-nuoc`} component={MucDichSuDungNuoc}/>
        <Route exact path={`${match.path}/Loai-hinh-nuoc-thai`} component={LoaiHinhNuocThai}/>
        <Route exact path={`${match.path}/Phan-loai-bao-cao`} component={PhanLoaiBaoCao}/>
        <Route exact path={`${match.path}/Loai-ban-do`} component={LoaiBanDo}/>
        <Route exact path={`${match.path}/Vung-kinh-te-xa-hoi`} component={VungKinhTe}/>
        <Route exact path={`${match.path}/Don-vi-tham-dinh`} component={DonViThamDinh}/>
        <Route exact path={`${match.path}/Nguon-nuoc-noi-tinh`} component={NguonNuocNoiTinh}/>
        <Route exact path={`${match.path}/Loai-su-co`} component={LoaiSuCo}/>
        <Route exact path={`${match.path}/Co-quan-ban-hanh`} component={CoQuanBanHanh}/>
        <Route exact path={`${match.path}/Thong-tin-lien-he`} component={ThongTinLienHe}/>
        <Route exact path={`${match.path}/Don-vi-xin-cap-phep`} render={(props) => <DVXCPList {...props} path={`${match.path}/Don-vi-xin-cap-phep`} groupName={groupName} name={typo.cscore_tnn_license_organization}/>}/>
        <Route path={`${match.path}/Don-vi-xin-cap-phep/add`} render={(props) => <DVXCPForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_license_organization}/>}/>
        <Route path={`${match.path}/Don-vi-xin-cap-phep/:id/edit`} render={(props) => <DVXCPForm {...props} groupName={groupName} name={typo.cscore_tnn_license_organization}/>}/>
      </Switch>
    </Suspense>
  )
}