/*
 *  @2019 CS-Core project.
 *
 */

import React from "react";
import LightState from "react-light-state";
import uuid from "uuid/v4";
import styled, {css} from "styled-components";
import SpinnerImage from '../assets/images/spiner.png';
import PuffLoader from "react-spinners/PuffLoader";


const LoaderStore = new LightState({stacks: []}, "loader");

export default LoaderStore;

export function remove(key) {
  LoaderStore.setState((setState, getState) => {
    setState({stacks: getState().stacks.filter(o => o.key !== key)});
  });
}

export function add() {
  const key = uuid();
  LoaderStore.setState((setState, getState) => {
    setState({stacks: [...getState().stacks, {key}]});
  });

  return () => remove(key);
}

// render
export const LoaderComponent = LoaderStore.withLight()(({loader}) => (
  <Spin isShow={loader.stacks.length}/>
));

const Spin = ({isShow}) => {
  return isShow ? <SpinWrapper>
    <PuffLoader
    size={50}
    color={"#007bc3"}
    />
  </SpinWrapper> : ''
};

const SpinWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(103, 103, 103, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  flex-direction: column;
  .load-img {
    animation: spin 1.5s linear infinite;
    width: 40px;
    height: 40px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
