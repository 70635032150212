import React, {useEffect, useState} from 'react'
import {Col, Row} from 'antd'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
//Components
import {TableDetail, RowTableDetail} from "../../Common/View/TableViewDetail";
// Constant
import typo from '../../../constants/dictionaries'
import Api from "../../../api";
import Message from "../../Messages";
import helper from '../../../helper/helper'

const Organization = ({data, user}) => {
  return (
    <Row>
      <TableDetail>
        <RowTableDetail title={typo.cscore_tnn_license_organization}>{data.tenDonViXCP}</RowTableDetail>
        <RowTableDetail title="Địa chỉ">{data.diaChiDonViXCP}</RowTableDetail>
      </TableDetail>
    </Row>
  )
};

Organization.propTypes = {
  data: PropTypes.any.isRequired
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(Organization)