import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const {Title} = Typography;

// Components
const PhanLoaiBaoCao = lazy(() => import('./PhanLoaiBaoCao'))
const LoaiBanDoPage = lazy(() => import('./LoaiBanDo'))
const QPPL = lazy(() => import('./VanBanQPPL'))
const QuyHoachTNN = lazy(() => import('./QuyHoachTNN'))
const DGTNNDuoiDat = lazy(() => import('./DanhGiaTNNDuoiDat'))
const DTDG = lazy(() => import('./DieuTraDanhGia'))
const ThanhTraTNN = lazy(() => import('./ThanhTraTNN'))
const BVPCKPTNN = lazy(() => import('./BaoVePhongChongKhacPhuc'))
const ThongTinTNN = lazy(() => import('./ThongTinTNN'))
const CSDLBanDo = lazy(() => import('./DuLieuBanDo'))
const SongVaLuuVuc = lazy(() => import('./SongVaLuuVuc'))
const BaoCaoGiayPhep = lazy(() => import('./DoanhNghiepBaoCao'))
const TienCapQuyen = lazy(() => import('./TienCapQuyen'))
const NguonNuoccanBaoVe = lazy(() => import('./NguonNuocCanBaove'))
const KetQuaGiaiQuyetKienNghi = lazy(() => import('./KetQuaGiaiQuyetKienNghi'))
const KetQuaTraLoiPhanAnhBaoChi = lazy(() => import('./KetQuaTraLoiPhanAnhBaoChi'))
const NguonNuocNoiTinh = lazy(() => import('./NguonNuocNoiTinh'))
const QuanLyKhiTuongThuyVan= lazy(()=>import('./QuanLyKhiTuongThuyVan'))
const QuanLyVanBanDiaPhuong= lazy(()=>import('./QuanLyVanBanDiaPhuong'))

export default ({match}) => {
  const groupName = 'Thong-tin-tai-nguyen-nuoc'
  const VBQPPL = 'Van-ban-quy-pham-phap-luat'
  const QuyHoach = 'Quy-hoach-tai-nguyen-nuoc'
  const DieuTra = 'Dieu-tra-danh-gia'
  const BaoVe = 'Bao-ve-phong-chong-khac-phuc'
  const ThongTin = 'Thong-tin-tai-nguyen-nuoc-khac'
  const BanDo = 'Du-lieu-ban-do'
  const ThanhTra = 'Thanh-tra'
  const DuoiDat = 'Danh-gia-tai-nguyen-nuoc-duoi-dat'
  const PhanLoai = 'Phan-loai-bao-cao'
  const LoaiBanDo = 'Loai-ban-do'
  const SongVaLuuVucSong = 'Song-va-luu-vuc'
  const BCGP = "Doanh-Nghiep-Bao-Cao"
  const TCQ = "Tien-cap-quyen"
  const NCCBV = "Nguon-nuoc-can-bao-ve"
  const KQGQKN = "Ket-qua-giai-quyet-kien-nghi"
  const KQTLPABC = "Ket-qua-tra-loi-phan-anh-bao-chi"
  const QLKTTV = "Quan-ly-khi-tuong-thuy-van"
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/${PhanLoai}`} component={PhanLoaiBaoCao}/>
        <Route exact path={`${match.path}/${LoaiBanDo}`} component={LoaiBanDoPage}/>
        <Route exact path={`${match.path}/${VBQPPL}`} component={QPPL}/>
        <Route exact path={`${match.path}/${QuyHoach}`} component={QuyHoachTNN}/>
        <Route exact path={`${match.path}/${DuoiDat}`} component={DGTNNDuoiDat}/>
        <Route exact path={`${match.path}/${DieuTra}`} component={DTDG}/>
        <Route exact path={`${match.path}/${ThanhTra}`} component={ThanhTraTNN}/>
        <Route exact path={`${match.path}/${BaoVe}`} component={BVPCKPTNN}/>
        <Route exact path={`${match.path}/${ThongTin}`} component={ThongTinTNN}/>
        <Route exact path={`${match.path}/${BanDo}`} component={CSDLBanDo}/>
        <Route exact path={`${match.path}/${SongVaLuuVucSong}`} component={SongVaLuuVuc}/>
        <Route exact path={`${match.path}/${BCGP}`} component={BaoCaoGiayPhep}/>
        <Route exact path={`${match.path}/${TCQ}`} component={TienCapQuyen}/>
        <Route exact path={`${match.path}/${NCCBV}`} component={NguonNuoccanBaoVe}/>
        <Route exact path={`${match.path}/${KQGQKN}`} component={KetQuaGiaiQuyetKienNghi}/>
        <Route exact path={`${match.path}/${KQTLPABC}`} component={KetQuaTraLoiPhanAnhBaoChi}/>
        <Route exact path={`${match.path}/${QLKTTV}`} component={QuanLyKhiTuongThuyVan}/>
        <Route exact path={`${match.path}/Nguon-nuoc-noi-tinh`} component={NguonNuocNoiTinh}/>
        <Route exact path={`${match.path}/Quan-ly-van-ban-dia-phuong`} component={QuanLyVanBanDiaPhuong}/>
      </Switch>
    </Suspense>
  )
}