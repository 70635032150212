
import React, { useState } from "react";
import L from 'leaflet'
import { Row, Icon } from "antd"
import styled from "styled-components";
import FuncMap from "./FuncMap"
import GeoJson_Layer from "../../../../components/BanDo/GeoJson_traCuu/GeoJson_Layer";
import GeoJson_Polygon from "../../../../components/BanDo/GeoJson_traCuu/GeoJson_Polygon";
import { useMap } from 'react-leaflet'

const ControllerTable = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: #ffffffcf;
  z-index: 1000
`;
const ControllerIcon = styled.div`
  cursor: pointer;
  float: right;
  margin-right: -23px;
  border: 1px solid #f6f6f6;
  height: 48px;
  line-height: 48px;
  width: 23px;
  text-align: center;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
  color: #20a8d8;
  &:hover {
    background-color: #20a8d8;
    color:  #fff;
  }
`;
const ControllerSearch = ({ displayTab, hideTab, dulieu_congtrinh, user, cap, maDVHCbyUser, maTinh, dvhcTinhs }) => {
  const divRef = React.useRef(null);
  const [idcongtrinh, setidcongtrinh] = useState(null);
  const [loaicongtrinh, setloaicongtrinh] = useState(null);
  const [tenTinh, settenTinh] = useState(null);
  const [tenHuyen, settenHuyen] = useState(null);
  const map = useMap();

  function FlyToButton(latlong) {
    map.flyTo(latlong, 10);
  }
  React.useEffect(() => {
    L.DomEvent.disableClickPropagation(divRef.current);
  });

  return (
    <ControllerTable ref={divRef} style={{ backgroundColor: displayTab === "none" ? "#ffffff00" : "#ffffffcf", }}>
      <Row>
        <ControllerIcon onClick={() => hideTab()}>
          <Icon type={displayTab === "none" ? "search" : "caret-left"} />
        </ControllerIcon>
      </Row>
      {displayTab === 'block' &&
        <Row style={{ width: "500px" }}>
          <FuncMap dulieu_congtrinh={(id, loai) => {
            dulieu_congtrinh(id, loai);
            setidcongtrinh(id);
            setloaicongtrinh(loai)
          }}
            tenTinh={(value) => settenTinh(value)}
            tenHuyen={(value) => settenHuyen(value)}
            user={user}
            cap={cap}
            maDVHCbyUser={maDVHCbyUser}
            maTinh={maTinh}
            dvhcTinhs={dvhcTinhs}
          />
        </Row>}
      <GeoJson_Polygon tentinh={tenTinh} tenhuyen={tenHuyen} zoomOut={(latlong) => FlyToButton(latlong)} />
      <GeoJson_Layer idcongtrinh={idcongtrinh} loaicongtrinh={loaicongtrinh} zoomOut={(latlong) => FlyToButton(latlong)} />
    </ControllerTable>
  );
}
export default ControllerSearch