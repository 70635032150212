import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Field, Formik, FastField, Form } from 'formik'
// Components
import Container from "../../containers/Container";
import PageWrapper from "../../components/Common/PageWrapper";
import { Row, Col, Icon, Typography } from "antd";
import message from "../../components/Messages";
import DisplayFormikState from '../../components/Table/DisplayFormikState'
import Box from "../../components/Box"
import Input from '../../components/Common/Input'
import InputPassword from '../../components/Common/Input/Password'
import Button from '../../components/Buttons'
import * as Yup from "yup";
import styles from "./Modal.module.scss";
// typo
import typo, { MESSAGE } from "../../constants/dictionaries";
import Api from "../../api";
const { Text } = Typography;
const Wrapper = styled.div`
  // padding: 16px 0 20px 0;
  // max-width: 80%;
  padding: 5px 10px; 
`;
const editParaSchema = Yup.object().shape({
  password: Yup.string().required(typo.cscore_tnn_input_Required).matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    'Mật khẩu của bạn phải có ít nhất 1 ký tự viết hoa [A-Z], 1 ký tự đặc biệt [!-@], 1 số [0-9]'
  ).min(6, 'Mật khẩu có tối thiểu 6 ký tự'),
  confirmPassword: Yup.string().required(typo.cscore_tnn_input_Required).matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    'Mật khẩu của bạn phải có ít nhất 1 ký tự viết hoa [A-Z], 1 ký tự đặc biệt [!-@], 1 số [0-9]'
  ).min(6, 'Mật khẩu có tối thiểu 6 ký tự'),
  curentPassword: Yup.string().required(typo.cscore_tnn_input_Required),
});
class Profile extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      data: {},
    };

  }

  componentDidMount() {
    this.setState({
      data: {...this.state.data, userName:  this.props.user.profile.name}
    })
  }

  render() {
    const {
      data,
    } = this.state;
    const { user } = this.props;
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          if (values.password !== values.confirmPassword) {
            message.error(MESSAGE.cscore_tnn_UPDATE_PASSWORD);
          } else {
            new Api()
              .ResetPassword({
                token: user.access_token,
                curentPassword: values.curentPassword,
                model: values
              })
              .then(res => {
                message.success(MESSAGE.cscore_tnn_UPDATE_SUCCESS);
              }).catch(err => {
                message.error(err.message)
              })
          }
        }}
        enableReinitialize
        validationSchema={editParaSchema}
        render={({ values, errors, dirty, handleChange, handleSubmit, setFieldValue, touched }) => (
          <form action="">
            <PageWrapper title={typo.cscore_tnn_profile_change_password}>
              <Container>
                <Row>
                  <Col span={8}></Col>
                  <Col span={8}>
                    <Box size="small">
                      <Wrapper>
                        <Row>
                          <FastField
                            name="userName"
                            render={({ field }) => <Input label={typo.cscore_tnn_login_user_name} mandatory={false} disabled={true} style={{ marginBottom: "5px" }} {...field} />}
                          />

                          <FastField
                            name="curentPassword"
                            render={({ field }) => <InputPassword label={typo.cscore_tnn_old_password} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          {touched.curentPassword && (<Text type="danger" className={styles.textFont}>{errors.curentPassword}</Text>)}

                          <FastField
                            name="password"
                            render={({ field }) => <InputPassword label={typo.cscore_tnn_forgot_password_reset} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          {touched.password && (<Text type="danger" className={styles.textFont}>{errors.password}</Text>)}

                          <FastField
                            name="confirmPassword"
                            render={({ field }) => <InputPassword label={typo.cscore_tnn_re_password} style={{ marginBottom: "10px" }} {...field} />}
                          />
                          {touched.confirmPassword && (<Text type="danger" className={styles.textFont}>{errors.confirmPassword}</Text>)}
                        </Row>
                        <Row type="flex" justify="end" align="middle">
                          <Button type="submit" onClick={handleSubmit}
                            disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                        </Row>
                      </Wrapper>
                    </Box>
                  </Col>
                </Row>
                {/* <DisplayFormikState values={values} errors={errors} /> */}

              </Container>
            </PageWrapper>
          </ form>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(Profile);
