/*
 *  @2019 CS-Core project.
 *
 */
import React, { Component } from "react";
import { Layout, Typography, Row, Col } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isEmpty } from 'lodash';
import AuthenticatedNavigation from "../components/Navigation";
import ProfileMenu from "../components/ProfileMenu";
import ErrorBoundary from "../components/ErrorBoundary";
import SomethingWentWrong from "../components/SomethingWentWrong";
import Button from "../components/Buttons";
import { LoaderComponent } from "../lightState/Loader";
import SmallMenu from '../components/smallMenu'
import Select from '../components/Common/Select'
import SearchByURI from "../helper/SearchByURI";
import message from "../components/Messages";
import helper from "../helper/helper"
import { ErrorHandlingLS } from "../lightState/ErrorHandling";
import typo, { MESSAGE } from "../constants/dictionaries";
import { APP_TNN_URI } from "../config"
import * as ActionTypes from "../reducers/actions/action-type"
import imageBTNMT from "../assets/images/defaultappicon.png"
import PropagateLoader from "react-spinners/PropagateLoader";
// Redux
import { connect } from "react-redux";

// Styles
import styles from "./LayoutAuthenticated.module.scss";
import { FUNCTION_ACTIONS } from "../redux/actions";
import Api from '../api'
import Constant, { functionsCode } from "../constants/constant";
let timeout;
let currentValue;
const { Sider, Content } = Layout;

const SomethingWentWrongNotify = styled.div`
  background-color: #f44336;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
`;

const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 99999;
`;

const SiderWrapper = styled(Sider)`
  background-color: #ffffff !important;
`;
function fetch(value, callback, user) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  if (value === "null") {
    callback([]);
  } else {
    function fake() {
      new Api().Get_DonViXinCapPheps_paged({ token: user.access_token, page: 0, size: 0, strSearch: currentValue })
        .then(res => {
          if (currentValue === value) {
            callback(res && res.data);
          }
        })
        .catch((err) => message.error(err.message))
    }
    timeout = setTimeout(fake, 800);
  }
}

class LayoutAuthenticated extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      data: [],
      selectedRow: undefined,
      timeout: null,
      loading: false,
      checkUpdate: 1,
      actionType_tinh: "",
      actionType_profile: "",
      actionType_token_giamsat: "",
      actionType_token_lgsp: "",
      actionType_token_cauhinh_gp: "",
      actionType_token_loaict_nm: "",
      actionType_token_ktt_tw: "",
      actionType_token_ktt_dp: "",
      actionType_token_luuvuc_song: "",
      editable: !isEmpty(functions) ? helper.handleGetEditableFunction(functions, functionsCode.TKDN).editable : false,
    }
    this.searchByURI = new SearchByURI({ defaultParams: this.searchParams });
    this.path = window.location.pathname
  }
  state = {
    isCollapsed: localStorage.getItem("GEN_menu_isCollapsed") === "true",
    minimizeMenu: localStorage.getItem("GEN_menu_isCollapsed") === "true"
  };

  onCollapse = collapse => {
    this.setState({ minimizeMenu: !this.state.minimizeMenu });
    this.setState({ isCollapsed: collapse });
    localStorage.setItem("GEN_menu_isCollapsed", collapse ? "true" : "false");
    if (collapse) {
      localStorage.setItem("GEN_defaultOpenKeys", "[]");
    }
  };

  // eslint-disable-next-line
  componentWillReceiveProps() {
    if (ErrorHandlingLS.getState().error) {
      ErrorHandlingLS.setState({ error: false });
    }

  }
  componentDidMount = () => {
    this.setState({ loading: true })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    //lưu vực sông
    if (nextProps.danhmuc.luuvuc_song.actionType !== prevState.actionType_token_luuvuc_song) {
      if (nextProps.danhmuc.luuvuc_song.actionType === ActionTypes.GET_LUUVUC_SONG_SUCCEED) {
        return { actionType_token_luuvuc_song: nextProps.danhmuc.luuvuc_song.actionType }
      }
    }
    //kinh tuyến trục
    if (nextProps.danhmuc.ktt_tw.actionType !== prevState.actionType_token_ktt_tw) {
      if (nextProps.danhmuc.ktt_tw.actionType === ActionTypes.GET_KTT_TW_SUCCEED) {
        return { actionType_token_ktt_tw: nextProps.danhmuc.ktt_tw.actionType }
      }
    }
    if (nextProps.danhmuc.ktt_dp.actionType !== prevState.actionType_token_ktt_dp) {
      if (nextProps.danhmuc.ktt_dp.actionType === ActionTypes.GET_KTT_DP_SUCCEED) {
        return { actionType_token_ktt_dp: nextProps.danhmuc.ktt_dp.actionType }
      }
    }
    //tinh
    if (nextProps.danhmuc.dvhcTinhs.actionType !== prevState.actionType_danhmuc_tinh) {
      if (nextProps.danhmuc.dvhcTinhs.actionType === ActionTypes.GET_DVHC_DS_TINH_SUCCEED) {
        return { actionType_danhmuc_tinh: nextProps.danhmuc.dvhcTinhs.actionType }
      }
    }
    //profile
    if (nextProps.danhmuc.profile.actionType !== prevState.actionType_danhmuc_profile) {
      if (nextProps.danhmuc.profile.actionType === ActionTypes.GET_PROFILE_SUCCEED) {
        return { actionType_danhmuc_profile: nextProps.danhmuc.profile.actionType }
      }
    }
    //cấu hình gp
    if (nextProps.danhmuc.cauHinhGP.actionType !== prevState.actionType_token_cauhinh_gp) {
      if (nextProps.danhmuc.cauHinhGP.actionType === ActionTypes.GET_CAUHINH_GP_SUCCEED) {
        return { actionType_token_cauhinh_gp: nextProps.danhmuc.cauHinhGP.actionType }
      }
    }
    //loaict nm
    if (nextProps.danhmuc.loaiCT_NM.actionType !== prevState.actionType_token_loaict_nm) {
      if (nextProps.danhmuc.loaiCT_NM.actionType === ActionTypes.GET_LOAI_CT_SUCCEED) {
        return { actionType_token_loaict_nm: nextProps.danhmuc.loaiCT_NM.actionType }
      }
    }
    //token giam sat
    if (nextProps.danhmuc.tokenGiamSat.actionType !== prevState.actionType_token_giamsat) {
      if (nextProps.danhmuc.tokenGiamSat.actionType === ActionTypes.GET_TOKEN_GIAMSAT_SUCCEED) {
        return { actionType_token_giamsat: nextProps.danhmuc.tokenGiamSat.actionType }
      }
    }
    //token lgsp
    if (nextProps.danhmuc.tokenLGSP.actionType !== prevState.actionType_token_lgsp) {
      if (nextProps.danhmuc.tokenLGSP.actionType === ActionTypes.GET_TOKEN_LGSP_SUCCEED) {
        return { actionType_token_lgsp: nextProps.danhmuc.tokenLGSP.actionType }
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, addTinh, addProfile, addTokenGiamSat, addTokenLGSP, addCauHinhGP, addLoaiCT_NM, addKTT_TW, addKTT_DP, addLUUVUC_SONG } = this.props
    const { checkUpdate, loading, actionType_danhmuc_tinh, actionType_danhmuc_profile, actionType_token_ktt_dp, actionType_token_ktt_tw,
      actionType_token_cauhinh_gp, actionType_token_loaict_nm, actionType_token_giamsat, actionType_token_lgsp, actionType_token_luuvuc_song } = this.state
    if (prevProps.user !== user && user && checkUpdate === 1) {
      this.setState({ checkUpdate: 2 })
      addTinh(user)
      addProfile(user)
      Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[0].id && addTokenGiamSat(user)
      Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[0].id && addTokenLGSP(user)
      addCauHinhGP(user)
      addLoaiCT_NM(user, Constant.DICT_NHOM_CT.NM)
      addKTT_TW(user)
      addKTT_DP(user)
      addLUUVUC_SONG(user)
    }
    if (actionType_danhmuc_tinh === ActionTypes.GET_DVHC_DS_TINH_SUCCEED &&
      actionType_danhmuc_profile === ActionTypes.GET_PROFILE_SUCCEED &&
      actionType_token_cauhinh_gp === ActionTypes.GET_CAUHINH_GP_SUCCEED &&
      actionType_token_loaict_nm === ActionTypes.GET_LOAI_CT_SUCCEED &&

      actionType_token_ktt_tw === ActionTypes.GET_KTT_TW_SUCCEED &&
      actionType_token_ktt_dp === ActionTypes.GET_KTT_DP_SUCCEED &&
      actionType_token_luuvuc_song === ActionTypes.GET_LUUVUC_SONG_SUCCEED &&
      checkUpdate === 2 && loading
    ) {
      this.setState({ loading: false })
      // if (Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[0].id) {
      //   if (actionType_token_giamsat === ActionTypes.GET_TOKEN_GIAMSAT_SUCCEED &&
      //     actionType_token_lgsp === ActionTypes.GET_TOKEN_LGSP_SUCCEED) {
      //     this.setState({ loading: false })
      //   }
      // } else if (Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[1].id) {
      //   this.setState({ loading: false })
      // }
    }
    if (prevProps !== this.props) {
      const { functions } = this.props;
      if (!isEmpty(functions)) {
        const func = helper.handleGetEditableFunction(functions, functionsCode.TKDN);
        if (func && func.editable) {
          this.setState({ editable: func.editable })
        }
      }
    }
  }
  SelectSearch = value => {
    const { user } = this.props;
    if (value && value !== "" && value !== undefined) {
      fetch(
        value,
        res => this.setState({ data: res.map((item) => ({ ...item, tenvsdiachi: item.ten + ". Địa chỉ: " + item.diaChi })) }),
        user
      );
    } else {
      fetch(
        "null",
        res => this.setState({ data: res }),
        user
      );
    }
  };
  searchDoanhNghiep = (value, name) => {
    this.searchByURI.navigate({
      idDoanhNghiep: value,
      // tenDoanhNghiep: name.split(". Địa chỉ:")[0]
    })
  }
  handleCollapsed = () => {
    this.setState({ minimizeMenu: !this.state.minimizeMenu });
    this.setState({ isCollapsed: true });
    localStorage.setItem("GEN_menu_isCollapsed", "true");
  }

  render() {
    const { children, user } = this.props;
    var unitName = (process.env.REACT_APP_CAP_TRIEN_KHAI === "2" || process.env.REACT_APP_CAP_TRIEN_KHAI === "3") ? (" " + process.env.REACT_APP_TEN_DVHC) : "";
    const searchParams = this.searchByURI.getSearchParmas();
    const { data, loading, editable } = this.state
    return (user && user.profile) ? (
      loading ?
        <div style={{ height: window.innerHeight }}>
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <div style={{ width: 200, height: 200, textAlign: "center" }}>
              <div style={{ marginLeft: 10 }}>
                <img src={imageBTNMT} alt="" style={{ width: 120 }} />
              </div>
              <div>
                <PropagateLoader size={14} color={"#007bc3"} />
              </div>
            </div>
          </div>
        </div>
        :
        <Layout theme="light" key="neverchange" className={styles.layout}>
          <div className='layout-box-head'>
            <div className='layout-box-logout'><ProfileMenu /></div>
            {editable &&
              <div style={{ float: 'right', marginTop: '10px', borderradius: '20px' }} className="select-doanhnghiep">
                <Select
                  placeholder={helper.genSelectPL("Nhập tên Tổ chức/Doanh nghiệp để tìm kiếm dữ liệu cấp phép")}
                  name="tenvsdiachi"
                  name_value="id"
                  mandatory={false}
                  defaultValue={Number(searchParams.idDoanhNghiep) ? Number(searchParams.idDoanhNghiep) : undefined}
                  key={Number(searchParams.idDoanhNghiep)}
                  onSearch={this.SelectSearch}
                  onSelect={(value, e) => {
                    let iddoanhnghiep = JSON.stringify([value])
                    window.location.replace(APP_TNN_URI + `Tra-cuu-doanh-nghiep?idDoanhNghiep=${iddoanhnghiep}`);
                  }
                  }
                  showArrow={false}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  notFoundContent={null}
                  style={{ float: 'right', width: '450px' }}
                  dataSource={data}
                  classSelect={styles.custom}
                  size="default"
                />
              </div>}
            <div className='layout-box-menu' style={{ float: 'left' }} ><SmallMenu /></div>
            <div className='box-layout-name-app'>{typo.cscore_tnn_nameApp}{unitName}</div>
          </div>
          <Layout>
            <SiderWrapper
              className='box-sidebar'
              breakpoint="lg"
              collapsedWidth="80"
              collapsible
              collapsed={this.state.isCollapsed}
              onCollapse={this.onCollapse}
              width={290}
            >
              <AuthenticatedNavigation collapsed={this.state.minimizeMenu} isCollapsed={this.state.isCollapsed} handleCollapsed={this.handleCollapsed} {...this.props} />
            </SiderWrapper>
            <Layout className={styles.layoutHasSlider}>
              <Content>
                <ErrorBoundary>
                  <SomethingWentWrongLS>
                     <LoaderComponent /> 
                    {user ? children : <h3 style={{ marginLeft: 20 }}>{typo.cscore_tnn_unauthorized}</h3>}
                  </SomethingWentWrongLS>
                </ErrorBoundary>
              </Content>
            </Layout>
          </Layout>
        </Layout>
   
    ) : ""
  }
  
  
}

const SomethingWentWrongLS = ErrorHandlingLS.withLight()(
  ({ error, children }) => (
    <>
      <SomethingWentWrong {...error} className="mb-20" />
      {error.error === false && children}
    </>
  )
);

const Handle409 = ErrorHandlingLS.withLight()(({ error }) => {
  // console.log("++++++++", error);
  return error.error409 ? (
    <Fixed>
      <SomethingWentWrongNotify>
        {MESSAGE.cscore_tnn_THERE_IS_SOMETHING_WRONG}{" "}
        <Link to={window.location.pathname}>
          <Button
            type="grayOutline"
            onClick={() => {
              ErrorHandlingLS.setState({ error409: false });
            }}
          >
            Reload
          </Button>
        </Link>
      </SomethingWentWrongNotify>
    </Fixed>
  ) : false;
});

const mapStateToProps = state => ({
  user: state.oidc.user,
  functions: state.functions,
  danhmuc: state.commonReducer
});

const mapDispatchToProps = (dispatch) => ({
  addFunctions: (payload) => dispatch(FUNCTION_ACTIONS.addListFunctions(payload)),
  addTinh: (payload) => dispatch(FUNCTION_ACTIONS.addTinh(payload)),
  addProfile: (payload) => dispatch(FUNCTION_ACTIONS.addProfile(payload)),
  addTokenGiamSat: (payload) => { Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[0].id && dispatch(FUNCTION_ACTIONS.addTokenGiamSat(payload)) },
  addTokenLGSP: (payload) => { Constant.CAP_TRIEN_KHAI === Constant.CAP_THAM_QUYEN[0].id && dispatch(FUNCTION_ACTIONS.addTokenLGSP(payload)) },
  addCauHinhGP: (payload) => dispatch(FUNCTION_ACTIONS.addCauHinhGP(payload)),
  addLoaiCT_NM: (user, nhomct) => dispatch(FUNCTION_ACTIONS.addLoaiCT_NM(user, nhomct)),
  addKTT_TW: (payload) => dispatch(FUNCTION_ACTIONS.addKTT_TW(payload)),
  addKTT_DP: (payload) => dispatch(FUNCTION_ACTIONS.addKTT_DP(payload)),
  addLUUVUC_SONG: (payload) => dispatch(FUNCTION_ACTIONS.addLUUVUC_SONG(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAuthenticated);