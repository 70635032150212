import React, { Component } from "react";
import { connect } from 'react-redux';
import { MapContainer, TileLayer, WMSTileLayer, LayersControl } from 'react-leaflet'
import BanDoVietNam from "../../../routes/BanDo/TraCuuTNN/BanDoVN"
import GeoJson_Layer from "./GeoJson_Layer";
import GeoJson_Layer_NM from "./GeoJson_Layer_NM";
import GeoJson_Layer_NB from "./GeoJson_Layer_NB";
import GeoJson_Layer_NDD from "./GeoJson_Layer_NDD";
import constants from "../../../constants/constant"
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import GeoJson_Layer_HO from "./GeoJson_Layer_HO";
import Geocode_gg from "../../../helper/geocode_gg";
class MiniMap extends Component {
    constructor(props) {
        super(props);
        const { functions } = props;
        this.state = {
            center: [16.371824, 107.549195]
        }
    }

    componentDidMount() {
        const {toado} = this.props
        if(toado?.length > 1) {
            this.setState({
                center: toado
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    FlyToButton = (latlong) => {
        this.setState({
            center: latlong
        })
    }

    render() {
        const { center } = this.state
        const { user, loaiCongTrinh, viewToado, idho, GeoJson_data, tenCongTrinh, GeoJson_CT } = this.props

        return (
            <div id="mapid" style={{ height: window.innerHeight / 2, margin: "-10px" }} key={center}>
                <MapContainer center={center} zoom={idho ? 17 : 13} style={{ height: '100%' }}>
                    <LayersControl position="topright">
                        <BanDoVietNam opacity={0}/>
                        <LayersControl.Overlay checked name="Google map vệ tinh (Tham khảo)">
                            <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_BO} type={'hybrid'} zIndex='4' />
                        </LayersControl.Overlay>
                        <Geocode_gg />
                    </LayersControl>
                    {loaiCongTrinh === constants.DICT_LOAI_CT.XT && <GeoJson_Layer tenCongTrinh={tenCongTrinh} loaicongtrinh={loaiCongTrinh} viewToado={viewToado} GeoJson_data={GeoJson_data}/>}
                    {loaiCongTrinh === constants.DICT_LOAI_CT.NB_KT && <GeoJson_Layer_NB loaicongtrinh={loaiCongTrinh} viewToado={viewToado} GeoJson_data={GeoJson_data}/>}
                    {loaiCongTrinh !== constants.DICT_LOAI_CT.XT && loaiCongTrinh !== constants.DICT_LOAI_CT.NDD_THAMDO &&
                        loaiCongTrinh !== constants.DICT_LOAI_CT.NDD_KHAITHAC && loaiCongTrinh !== constants.DICT_LOAI_CT.NB_KT && !idho &&
                        <GeoJson_Layer_NM loaicongtrinh={loaiCongTrinh} GeoJson_data={GeoJson_data} GeoJson_CT={GeoJson_CT} viewToado={viewToado} />
                    }
                    {loaiCongTrinh === constants.DICT_LOAI_CT.NDD_KHAITHAC && <GeoJson_Layer tenCongTrinh={tenCongTrinh} loaicongtrinh={loaiCongTrinh} viewToado={viewToado} GeoJson_data={GeoJson_data}/>}
                    {loaiCongTrinh === constants.DICT_LOAI_CT.NDD_THAMDO && <GeoJson_Layer_NDD loaicongtrinh={loaiCongTrinh} GeoJson_data={GeoJson_data} viewToado={viewToado} />}
                    {idho && <GeoJson_Layer_HO idho={idho} GeoJson_data={GeoJson_data} />}
                </MapContainer >
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});

export default connect(mapStateToProps)(MiniMap);
