import * as ActionTypes from './actions/action-type';

const initialState = {
    dvhcTinhs: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    dvhcHuyensByTinh: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    dvhcXasByHuyen: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    profile: {
        isLoading: false,
        data: null,
        message: '',
        actionType: '',
    },
    tokenGiamSat: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    tokenLGSP: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    cauHinhGP: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    loaiCT_NM: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    ktt_tw: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    ktt_dp: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
    luuvuc_song: {
        isLoading: false,
        data: [],
        message: '',
        actionType: '',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        // Get lưu vực sông
        case ActionTypes.GET_LUUVUC_SONG_REQUEST:
            return {
                ...state, luuvuc_song: {
                    ...state.luuvuc_song,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_LUUVUC_SONG_SUCCEED:
            return {
                ...state, luuvuc_song: {
                    ...state.luuvuc_song,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_LUUVUC_SONG_FAILED:
            return {
                ...state, luuvuc_song: {
                    ...state.luuvuc_song,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get ktt dp
        case ActionTypes.GET_KTT_DP_REQUEST:
            return {
                ...state, ktt_dp: {
                    ...state.ktt_dp,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_KTT_DP_SUCCEED:
            return {
                ...state, ktt_dp: {
                    ...state.ktt_dp,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_KTT_DP_FAILED:
            return {
                ...state, ktt_dp: {
                    ...state.ktt_dp,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get ktt tw
        case ActionTypes.GET_KTT_TW_REQUEST:
            return {
                ...state, ktt_tw: {
                    ...state.ktt_tw,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_KTT_TW_SUCCEED:
            return {
                ...state, ktt_tw: {
                    ...state.ktt_tw,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_KTT_TW_FAILED:
            return {
                ...state, ktt_tw: {
                    ...state.ktt_tw,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get loại ct nm
        case ActionTypes.GET_LOAI_CT_REQUEST:
            return {
                ...state, loaiCT_NM: {
                    ...state.loaiCT_NM,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_LOAI_CT_SUCCEED:
            return {
                ...state, loaiCT_NM: {
                    ...state.loaiCT_NM,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_LOAI_CT_FAILED:
            return {
                ...state, loaiCT_NM: {
                    ...state.loaiCT_NM,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get cấu hình giấy phép
        case ActionTypes.GET_CAUHINH_GP_REQUEST:
            return {
                ...state, cauHinhGP: {
                    ...state.cauHinhGP,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_CAUHINH_GP_SUCCEED:
            return {
                ...state, cauHinhGP: {
                    ...state.cauHinhGP,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_CAUHINH_GP_FAILED:
            return {
                ...state, cauHinhGP: {
                    ...state.cauHinhGP,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get token lgsp
        case ActionTypes.GET_TOKEN_LGSP_REQUEST:
            return {
                ...state, tokenLGSP: {
                    ...state.tokenLGSP,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_TOKEN_LGSP_SUCCEED:
            return {
                ...state, tokenLGSP: {
                    ...state.tokenLGSP,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_TOKEN_LGSP_FAILED:
            return {
                ...state, tokenLGSP: {
                    ...state.tokenLGSP,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // Get token giám sát
        case ActionTypes.GET_TOKEN_GIAMSAT_REQUEST:
            return {
                ...state, tokenGiamSat: {
                    ...state.tokenGiamSat,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_TOKEN_GIAMSAT_SUCCEED:
            return {
                ...state, tokenGiamSat: {
                    ...state.tokenGiamSat,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_TOKEN_GIAMSAT_FAILED:
            return {
                ...state, tokenGiamSat: {
                    ...state.tokenGiamSat,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // profile
        case ActionTypes.GET_PROFILE_REQUEST:
            return {
                ...state, profile: {
                    ...state.profile,
                    isLoading: true,
                    data: null,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_PROFILE_SUCCEED:
            return {
                ...state, profile: {
                    ...state.profile,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_PROFILE_FAILED:
            return {
                ...state, profile: {
                    ...state.profile,
                    isLoading: false,
                    data: null,
                    message: '',
                    actionType: action.type
                }
            };
        // tinh
        case ActionTypes.GET_DVHC_DS_TINH_REQUEST:
            return {
                ...state, dvhcTinhs: {
                    ...state.dvhcTinhs,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_TINH_SUCCEED:
            return {
                ...state, dvhcTinhs: {
                    ...state.dvhcTinhs,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_TINH_FAILED:
            return {
                ...state, dvhcTinhs: {
                    ...state.dvhcTinhs,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // huyen
        case ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_REQUEST:
            return {
                ...state, dvhcHuyensByTinh: {
                    ...state.dvhcHuyensByTinh,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_SUCCEED:
            return {
                ...state, dvhcHuyensByTinh: {
                    ...state.dvhcHuyensByTinh,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                },
                dvhcXasByHuyen: {
                    ...state.dvhcXasByHuyen,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_FAILED:
            return {
                ...state, dvhcHuyensByTinh: {
                    ...state.dvhcHuyensByTinh,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                },
                dvhcXasByHuyen: {
                    ...state.dvhcXasByHuyen,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        // xa
        case ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_REQUEST:
            return {
                ...state, dvhcXasByHuyen: {
                    ...state.dvhcXasByHuyen,
                    isLoading: true,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_SUCCEED:
            return {
                ...state, dvhcXasByHuyen: {
                    ...state.dvhcXasByHuyen,
                    isLoading: false,
                    data: action.data,
                    message: '',
                    actionType: action.type
                }
            };
        case ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_FAILED:
            return {
                ...state, dvhcXasByHuyen: {
                    ...state.dvhcXasByHuyen,
                    isLoading: false,
                    data: [],
                    message: '',
                    actionType: action.type
                }
            };
        default:
            return state;
    }
};