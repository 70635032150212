export default class CT_FileDinhKem {
    constructor(item) {
        this.id = item.id
        this.idCongTrinh = item.idCongTrinh
        this.soTaiLieu = item.soTaiLieu
        this.tenTaiLieu = item.tenTaiLieu
        this.ngayKy = item.ngayKy
        this.duongDan = item.duongDan
        this.dinhDang = item.dinhDang
        this.ghiChu = item.ghiChu
        this.loaiCongTrinh = item.loaiCongTrinh
        this.nhomCongTrinh = item.nhomCongTrinh
        this.idFile = item.idFile
        this.fileName = item.fileName
        this.tenFile = item.tenFile
    }
}