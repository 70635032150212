import React, { Component } from "react";
import { connect } from 'react-redux'
import styled from "styled-components";

//component
import { Row, Upload, Icon } from 'antd';
import Button from "../../../Buttons";
import ModalAdd from "../Modal/add";
import ViewTable from '../../../Table/ViewTable'

//helper
import columns from './columns'
import CT_FileDinhKem from '../../../../models/CT_FileDinhKem'
import Api_dms from '../../../../api/api.dms';
import typo from "../../../../constants/dictionaries";
import message from '../../../../components/Messages'

import { TypeFiles, MESSAGE } from '../../../../constants/dictionaries'

const ControllerFile = styled.div`
  padding-bottom: 10px;
`;
class TablePlus extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedItem: undefined, isShowModalAdd: false, action: undefined }
  }

  showModalAdd = (item) => {
    this.setState({ action: item ? "edit" : "add" })
    this.setState({ isShowModalAdd: true, selectedItem: item })
  }
  offModalAdd = () => {
    this.setState({ action: undefined })
    this.setState({ isShowModalAdd: false, selectedItem: undefined })
  }

  handleSubmitAdd = (item) => {
    var items = this.props.dataSource ? this.props.dataSource : []
    if (item.stt) {
      items[item.stt - 1] = { ...new CT_FileDinhKem(item) }
    } else {
      items.push({ ...new CT_FileDinhKem(item) })
    }
    this.props.updateDataSource(items, this.props.field)
    this.offModalAdd();
  }

  handleDelete = (stt) => {
    this.setState({ selectedItem: undefined })
    var items = this.props.dataSource ? this.props.dataSource : []
    if (stt) {
      items.splice(stt - 1, 1)
    }
    this.props.updateDataSource(items, this.props.field)
  }

  addSttToItems = (items) => {
    if (!items) return []
    var newItems = []
    if (items) {
      newItems = items.map((item, index) => ({ ...new CT_FileDinhKem(item), stt: index + 1 }))
    }
    return newItems;
  }

  downFile = (data) => {
    new Api_dms().DownloadFile({
      token: this.props.user.access_token,
      data: { oid: data.idFile, filename: data.tenTaiLieu }
    })
  }

  customRequest = (options) => {
    var data = new FormData()
    data.append('file', options.file)
    if (TypeFiles.includes(options.file.type)) {
      this.uploadFile(data, options.file)
    } else {
      message.error("File " + options.file.name + " không hỗ trợ định dạng")
    }
  }

  uploadFile = (data) => {
    new Api_dms()
      .UploadFile({ token: this.props.user.access_token, data: data })
      .then((result) => {
        if (result && result.result && result.result.length > 0) {
          this.handleSubmitAdd({
            tenTaiLieu: result.result[0].fileName,
            extension: result.result[0].extension,
            idFile: result.result[0].oid
          })
        } else { message.error(MESSAGE.cscore_tnn_UPLOAD_FILE_FAILURE) }
      })
  }
  render() {
    const { isShowModalAdd, selectedItem, action } = this.state
    const { dataSource, mode } = this.props
    return (
      <Row>
        {mode !== "view" &&
          <Row type='flex' justify="end">
            <ControllerFile>
              <Upload className="btn-add-in-box" customRequest={this.customRequest} fileList={[]} multiple={true} listType={'text'}><Button><Icon type="upload" />{typo.cscore_tnn_fileTaiLieu}</Button></Upload>
            </ControllerFile>
          </Row>}
        <ViewTable
          columns={columns({ handleEdit: this.showModalAdd, handleDelete: this.handleDelete, downFile: this.downFile, view: mode })}
          dataSource={this.addSttToItems(dataSource)} bordered pagination={false} scroll={{ x: 500 }}/>
        {isShowModalAdd && <ModalAdd action={action} item={selectedItem} handleCancel={this.offModalAdd}
          handleSubmit={this.handleSubmitAdd} />}
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({ user: state.oidc.user });
export default connect(mapStateToProps)(TablePlus);