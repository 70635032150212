import React from 'react'
import { Col, Collapse, Row, Icon, Modal } from "antd";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "styled-components";
import styles from "./Modal.module.scss";
// Components
import Container from "../../../../containers/Container";
import Organization from '../../../../components/License/Organization'
import LicenseResult from '../../../../components/License/LicenseResult'
import ThongTinCT_XT from '../../../../components/License/ThongTinCT_XT'
import Licenses from '../../../../components/CongTrinh/GiayPhep/View'
import helper from '../../../../helper/helper'
import typo, { MESSAGE } from '../../../../constants/dictionaries'
import TTKT from "../../../../components/AttachTable/TTKT/Table";
import DiemXaThais from "../../../../components/CongTrinh/DiemXaThai/View";
import TSQT from "../../../../components/AttachTable/TSQT/Table";
import FileDinhKemView from "../../../../components/CongTrinh/FileDinhKem/View";
import Constant from '../../../../constants/constant';
import ThanhTraDN from "../../../../components/AttachTable/ThanhTraDN/Table";
import Button from "../../../../components/Buttons"
import message from '../../../../components/Messages';
const { Panel } = Collapse;
const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;
class DischargeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openModal: false
    }
  }

  componentDidMount() {
    const { data, cap, maDVHCbyUser, maTinh } = this.props
    if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG) {
      if (cap === Constant.Role_user.LD_CV_BO) {
        this.setState({
          data: data,
          openModal: true
        })
      } else if (cap === Constant.Role_user.LD_CV_SO) {
        if (data.ctxT_CongTrinh && data.ctxT_CongTrinh.maTinh === maTinh) {
          this.setState({
            data: data,
            openModal: true
          })
        } else {
          message.warning(MESSAGE.CSCORE_KHONG_CO_QUYEN_TRUY_CAP)
        }
      } else if (cap < Constant.Role_user.LD_CV_BO) {
        this.setState({
          data: data,
          openModal: true
        })
      }
    } else if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG) {
      this.setState({
        data: data,
        openModal: true
      })
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, cap, maDVHCbyUser, maTinh } = this.props
    if (prevProps.data !== data) {
      if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG) {
        if (cap === Constant.Role_user.LD_CV_BO) {
          this.setState({
            data: data,
            openModal: true
          })
        } else if (cap === Constant.Role_user.LD_CV_SO) {
          if (data.ctxT_CongTrinh && data.ctxT_CongTrinh.maTinh === maTinh) {
            this.setState({
              data: data,
              openModal: true
            })
          } else {
            message.warning(MESSAGE.CSCORE_KHONG_CO_QUYEN_TRUY_CAP)
          }
        } else if (cap < Constant.Role_user.LD_CV_BO) {
          this.setState({
            data: data,
            openModal: true
          })
        }
      } else if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG) {
        this.setState({
          data: data,
          openModal: true
        })
      }
    }
  }
  handleCancel = () => {
    this.setState({
      openModal: false
    })
  }

  render() {
    const { data, openModal } = this.state;
    const { ctxT_CongTrinh, hoSoXinCapPheps, ctxT_DiemXaThais, giayPhepHis, chatLuongNuocThai_JSON, thanhTra_KiemTras, thanhTras } = data;
    const chatLuongNuocThais = chatLuongNuocThai_JSON ? JSON.parse(chatLuongNuocThai_JSON) : [];
    const ttkts = thanhTra_KiemTras ? JSON.parse(thanhTra_KiemTras) : [];

    return (
      <Modal
        title={typo.cscore_tnn_thong_tin_chi_tiet}
        centered
        closable={true}
        visible={openModal}
        width="80%"
        footer={null}
        onCancel={this.handleCancel}
      >
        <Scrollbars ref="scrollbars" style={{ height: window.innerHeight * 0.8 }} autoHeightMax={window.innerHeight} autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <Container style={{ padding: 20 }}>
            {data.soVanBan &&
              <Row type="flex">
                <Col span={12} style={{ paddingRight: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_license_organization, 1)}</a>} key="1">
                      <Organization data={data} />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_lichsu, 2)}</a>} key="1">
                      <Licenses dataSource={giayPhepHis} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>}
            <div className="mt-20" />
            <Row type="flex">
              <Col span={12} style={{ paddingRight: 10 }}>
                <Collapse bordered={false} defaultActiveKey={['1']}>
                  <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_ct_info, data.soVanBan ? 3 : 1)}</a>} key="1">
                    <ThongTinCT_XT
                      congTrinh={ctxT_CongTrinh}
                      banDo={data.soVanBan ? "Đã được cấp phép" : "Chưa được cấp phép"}
                    />
                  </Panel>
                </Collapse>
              </Col>
              {data.soVanBan &&
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_ketQuaGP, 4)}</a>} key="1">
                      <LicenseResult data={data} loaiGP="Xả thải" />
                    </Panel>
                  </Collapse>
                </Col>}
            </Row >
            <div className="mt-20" />
            <Row type="flex">
              <Col span={12} style={{ paddingRight: 10 }}>
                <a>{helper.generateIndexForLabel(typo.cscore_tnn_diemXaThai, 3.1)} </a>
                <DiemXaThais dataSource={ctxT_DiemXaThais || []} />
              </Col>
              {data.soVanBan &&
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_chatLuongNuocThai, 4.1)} </a>
                  <TSQT mode="presentation" dataSource={chatLuongNuocThais || []} />
                </Col>
              }
            </Row>
            <div className="mt-20" />
            {data.soVanBan &&
              <Row type="flex">
                {Constant.CAP_TRIEN_KHAI === 1 ?
                  <Col span={12} style={{ paddingRight: 10 }}>
                    <a>{helper.generateIndexForLabel(typo.cscore_tnn_ttkt, 4.2)} </a>
                    <TTKT mode="presentation" dataSource={ttkts || []} />
                  </Col>
                  :

                  <Col span={12} style={{ paddingRight: 10 }}>
                    <a>{helper.generateIndexForLabel(typo.cscore_tnn_ThanhTraDN, 4.3)}</a>
                    <ThanhTraDN dataSource={thanhTras || []} mode="presentation" />
                  </Col>
                }

                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_fileDinhkem, 4.4)} </a>
                  <FileDinhKemView mode="presentation" dataSource={hoSoXinCapPheps || []} />
                </Col>
              </Row>}
            <ControllerButton>
              <Button
                type="cancel"
                className={styles.buttonMargin}
                onClick={this.handleCancel}
              >
                <Icon type="arrow-right" />
                {typo.cscore_tnn_exit}
              </Button>
            </ControllerButton>
          </Container>
        </Scrollbars>
      </Modal>
    )
  }
}

DischargeDetail.propTypes = {
  idGiayPhep: PropTypes.string
};


const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(DischargeDetail)