import React, { Component } from "react";
import { connect } from 'react-redux'
import { Row } from 'antd';

//component
import ViewTable from '../../../Table/ViewTable'
import { MESSAGE } from '../../../../constants/dictionaries'
import message from "../../../../components/Messages";
import DownloadFileModal from '../../../../components/DownloadFile'
//helper
import columns from './columns'
import CT_GiayPhepCongTrinh from '../../../../models/CT_GiayPhepCongTrinh'
import Api_dms from "../../../../api/api.dms";

class Licenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      selectedRow: null,
      setModal: false,
      selectdFile: []
    }
  }
  showFile = (data) => {
    if (data.hoSoXinCapPheps !== undefined && data.hoSoXinCapPheps !== [] && data.hoSoXinCapPheps.length > 0) {
      if (data.hoSoXinCapPheps.length > 1) {
        this.setState({
          setModal: true,
          selectdFile: data.hoSoXinCapPheps
        })
      } else {
        this.downFile(data.hoSoXinCapPheps[0])
      }
    } else {
      message.warning(MESSAGE.cscore_tnn_KHONG_CO_FILE)
    }
  }
  downFile = (data) => {
    if (data === undefined || data === []) {
      message.warning(MESSAGE.cscore_tnn_KHONG_CO_GIAY_PHEP)
    } else {
      new Api_dms().DownloadFile({
        token: this.props.user.access_token,
        data: { oid: data.idFile, filename: data.tenTaiLieu }
      })
    }
  }

  handleCancel = () => {
    this.setState({ setModal: false })
  }

  render() {
    const { dataSource = [] } = this.props;
    const { setModal, selectdFile } = this.state
    let dataSourceIndex = dataSource.map((c, index) => ({ ...new CT_GiayPhepCongTrinh(c), stt: index + 1 }));

    return (
      <Row>
        <ViewTable
          locale={{ emptyText: MESSAGE.cscore_tnn_THERE_IS_NO_DATA }}
          columns={columns({ handleShowDetail: this.showFile })}
          dataSource={dataSourceIndex} pagination={false}
        />
        {setModal &&
          <DownloadFileModal
            isOpen={setModal}
            handleCancel={this.handleCancel}
            selectdFile={selectdFile}
          />}
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({ user: state.oidc.user });
export default connect(mapStateToProps)(Licenses);