import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon } from 'antd'
import { Formik, FastField } from 'formik'
import typo from '../../../../constants/dictionaries'
import { isEmpty } from "lodash";
import moment from "moment";
// Utils

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";
import NumberField from "../../../Common/NumberField"
import Helper from "../../../../helper/helper";
import Select from "../../..//Common/Select";
import DateField from "../../../Common/DateField"
import TextArea from "../../../Common/TextArea";
import DonViXCPGroup from "../../../License/DonViDCPGroup";
import Api_tnn from "../../../../api/api.tnn";
import message from "../../../../components/Messages";

const ControllerInput = styled.div`
  padding: 0 20px 0 20px;
`;
const ketqua = [
  { id: "0", name: "Đạt" },
  { id: "1", name: "Không đạt" },
]
const schema = Yup.object().shape({

});
const dateFormat = 'DD/MM/YYYY';
class TCQKTSDModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      dataGiayPhep: [],
      CongTrinh: undefined,
    }
  }

  componentDidMount() {
    const { mode, initialValues, isEdit, selectedRow } = this.props
    if (mode === "edit") {
      this.setState({
        data: initialValues
      })
      if(initialValues.idDoanhNghiep){
        this.GetCT_GiayPhep_ByIdDVXCP(initialValues.idDoanhNghiep)
      }
    }
  }
  componentDidUpdate = (prevProps) => {
    const { mode, initialValues } = this.props
    if (mode === "edit") {
      if (initialValues !== prevProps.initialValues) {
        this.setState({
          data: initialValues
        })
      }
    }
  };
  GetCT_GiayPhep_ByIdDVXCP = (iddvxcp) => {
    const { user } = this.props;
    new Api_tnn().GetCT_GiayPhep_ByIdDVXCP({ token: user.access_token, iddvxcp: iddvxcp })
      .then(res => {
        this.setState({ dataGiayPhep: res })
      })
      .catch((err) => message.error(err.message))
  };

  GetCT_CongTrinh_ByIdGP = (idgiayphep, setFieldValue) => {
    const { user } = this.props;
    new Api_tnn().GetCT_CongTrinh_ByIdGP({ token: user.access_token, idgiayphep: idgiayphep })
      .then(res => {
        setFieldValue("tenCongTrinh", res.tenCongTrinh)
        setFieldValue("diaChiCongTrinh", res.diaDiem)

      })
      .catch((err) => message.error(err.message))
  };

  selectCongTrinh = (value, setFieldValue) => {
    this.GetCT_CongTrinh_ByIdGP(value, setFieldValue)
  }
  handleGP = (value) => {
    this.GetCT_GiayPhep_ByIdDVXCP(value)
  }


  render() {
    const { isOpen, handleCancel, handleOk, mode, user, isEdit } = this.props;
    const { data, dataGiayPhep, CongTrinh } = this.state
    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_KQ_KT}
        footer={false}
        onCancel={handleCancel}
        width="700px"
        centered
      >
        <Formik
          enableReinitialize
          initialValues={data}
          onSubmit={(values, actions) => {
            handleOk({ ...values, tenDoanhNghiep: values.tenDonViXCP, idDoanhNghiep:values.idDonViXinCapPhep, diaChi:values.diaChiDonViXCP, kqKiemTra: values.kqKiemTra ? values.kqKiemTra : 0 });
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({ handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <Col span={12}>
                  <ControllerInput>
                    <DonViXCPGroup
                      values={values}
                      setFieldValue={setFieldValue}
                      mode={mode}
                      handleGP={this.handleGP}
                    />
                    <Select
                      placeholder={Helper.genSelectPL(typo.cscore_tnn_license_number)}
                      label={typo.cscore_tnn_license_number}
                      mandatory={false}
                      onChange={(value, name) => {
                        setFieldValue("soGiayPhep", name.props.children);
                        setFieldValue("idGiayPhep", value);
                        setFieldValue("ngayCapPhep", Helper.getFieldValueFromID(value, dataGiayPhep, "ngayKy"));
                        this.selectCongTrinh(value, setFieldValue)
                      }}
                      dataSource={dataGiayPhep}
                      width="100%"
                      name="soVanBan"
                      name_value="id"
                      defaultValue={values.idGiayPhep}
                    />

                    <FastField name="soGiayPhep" render={({ field }) => (
                      <Input placeholder={typo.cscore_tnn_license_number} style={{ marginTop: "10px" }} mandatory={false} {...field} />)} />

                    <DateField
                      format={dateFormat}
                      mandatory={false}
                      placeholder={Helper.genSelectPL(typo.cscore_tnn_ngay_cap_phep)}
                      label={typo.cscore_tnn_ngay_cap_phep}
                      onChange={value => { setFieldValue("ngayCapPhep", value && value.format()) }}
                      defaultValue={values.ngayCapPhep}
                      value={values.ngayCapPhep ? moment(values.ngayCapPhep) : null}
                    />

                    <FastField name="tenCongTrinh" render={({ field }) => (
                      <Input placeholder={typo.cscore_tnn_tenCongTrinh} label={typo.cscore_tnn_tenCongTrinh} {...field} />)} />

                    <FastField name="diaChiCongTrinh" render={({ field }) => (
                      <Input placeholder={typo.cscore_tnn_diachict} label={typo.cscore_tnn_diachict} {...field} />)} />

                    <DateField
                      mandatory={false}
                      format={dateFormat}
                      placeholder={Helper.genSelectPL(typo.cscore_tnn_ngaykiemtra)}
                      label={typo.cscore_tnn_ngaykiemtra}
                      onChange={value => setFieldValue("ngayKT", value && value.format())}
                      value={values.ngayKT ? moment(values.ngayKT) : null}
                    />

                    <Select
                      dataSource={ketqua}
                      defaultValue={values.kqKiemTra ? String(values.kqKiemTra) : "0"}
                      onChange={(value) => { setFieldValue("kqKiemTra", Number(value)) }}
                      placeholder={typo.cscore_tnn_KQ_KT}
                      label={typo.cscore_tnn_KQ_KT}
                      mandatory={false}
                      name="name"
                      name_value="id"
                      width="100%"
                    // key={values.kqKiemTra}
                    />


                  </ControllerInput>
                </Col>
                <Col span={12}>
                  <ControllerInput>
                    <FastField
                      name="kqKhacPhuc"
                      render={({ field }) => <TextArea placeholder={typo.cscore_tnn_kq_khac_phuc} mandatory={false} label={typo.cscore_tnn_kq_khac_phuc} {...field} rows={2} />}
                    />

                    <FastField
                      name="cacLoiXuPhat"
                      render={({ field }) => <TextArea placeholder={typo.cscore_tnn_loi_xu_phat} mandatory={false} label={typo.cscore_tnn_loi_xu_phat} {...field} rows={2} />}
                    />
                    <FastField
                      name="kqXuPhat"
                      render={({ field }) => <TextArea placeholder={typo.cscore_tnn_kq_xu_phat} mandatory={false} label={typo.cscore_tnn_kq_xu_phat} {...field} rows={2} />}
                    />
                    <NumberField
                      label={typo.cscore_tnn_so_tien}
                      placeholder={typo.cscore_tnn_so_tien}
                      mandatory={false}
                      onChange={value => setFieldValue("soTien", value ? value : 0)}
                      defaultValue={values.soTien}
                      value={values.soTien}
                    />
                    <FastField
                      name="ghiChu"
                      render={({ field }) => <TextArea placeholder={typo.cscore_tnn_note} mandatory={false} label={typo.cscore_tnn_note} {...field} rows={2} />}
                    />
                  </ControllerInput>
                </Col>
              </Row>
              <div className="mt-20" />
              {mode !== "presentation" &&
                <Row type="flex" justify="end" align="middle">
                  <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                  <div className="ml-10" />
                  <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
                </Row>}
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )} />
      </Modal>
    )
  }
}

TCQKTSDModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  // mode: add, edit, presentation
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(TCQKTSDModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

