import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import L from "leaflet";
import "leaflet-measure";
import { useMap, useMapEvents } from "react-leaflet";
import message from "../../../../components/Messages";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import { Icon, Typography } from "antd";
import Select from "../../../../components/Common/Select";
import styled from "styled-components";
import Button from "../../../../components/Buttons";
import 'leaflet-measure/dist/leaflet-measure.vn.js'
import Constant from "../../../../constants/constant";
import helper from "../../../../helper/helper";
import Api from "../../../../api";
import Api_spatial from "../../../../api/api.spatial";
const { Title } = Typography;

const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;
const LayToaDo = ({ user }) => {
    const divRef = React.useRef(null);
    const [start, setStart] = useState(null)
    const [LoaiKTT, setLoaiKTT] = useState(1);
    const [KTT, setKTT] = useState(72);
    const [MuiChieu, setMuiChieu] = useState(6);
    const [data_KinhTuyenTruc, setdata_KinhTuyenTruc] = useState([]);
    const [dataVn2000Transform, setDataVn2000Transform] = useState(null)

    useMapEvents({
        click: (evt) => {
            if(start) {
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDJVZlu8THnMxQVMX-1xyWMyEzFLphzuw&address=${evt.latlng.lat},${evt.latlng.lng}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.results && data.results.length > 0) {
                    // popup
                    // .setLatLng(evt.latlng)
                    // .setContent(data.results[0].formatted_address)
                    // .openOn(map);
                    const latLng = evt.latlng
                    const content = data.results[0].formatted_address
                    const valueKinhTuyenTruc = data_KinhTuyenTruc.find(x => x.id === KTT)

                    if(valueKinhTuyenTruc?.cap && valueKinhTuyenTruc?.do && latLng) {
                        const valueInputTransform = {
                            cap: valueKinhTuyenTruc.cap,
                            kttDo: valueKinhTuyenTruc.do,
                            kttPhut: valueKinhTuyenTruc.phut,
                            geoastext: `POINT(${latLng?.lng} ${latLng?.lat})`,
                            address: content
                        }
                        ApiTransformWgs84ToVn2000(valueInputTransform)
                    }
                    console.log(`latlng: ${latLng}, content: ${content}`)
                    }
                }).catch(error => {});
            }
        }
    })

    useEffect(() => {
        GetApidmkinhtuyentrucs(LoaiKTT);
    }, []);
    function GetApidmkinhtuyentrucs(cap) {
        new Api().Getdmkinhtuyentrucs({
            token: user.access_token,
            page: "0",
            size: "0",
            cap: cap,
        })
            .then((res) => {
                setdata_KinhTuyenTruc(res && res.data.map((value) => ({
                    ...value,
                    tenHienThi: value.ten + " - " + value.tenDVHC,
                })))
            })
            .catch((err) => {
                message.error(err.message);
            });
    }

    function ApiTransformWgs84ToVn2000({cap, kttDo, kttPhut, geoastext, address}) {
        new Api_spatial().TransformWgs84ToVn2000({
            token: user.access_token,
            cap: cap,
            kttDo: kttDo,
            kttPhut: kttPhut,
            geoastext: geoastext
        })
            .then((res) => {
                if(res) {
                    res.address = address
                    setDataVn2000Transform(res)
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    }

    function onSelectLKTT(value) {
        setLoaiKTT(value)
        setKTT(undefined)
        GetApidmkinhtuyentrucs(value)
    }
    function onSelectKTT(value) {
        setKTT(value)
    }
    function onSelectMC(value) {
        setMuiChieu(value)
    }

    const displayResultDataToaDoVN2000 =
        <div>
            <div style={{ fontSize: '16px', fontWeight: 600, margin: '10px 0px 3px 0px'}}>Kết quả tọa độ VN2000</div>
            <div style={{fontSize: '13px'}}>
                <span>Tọa độ X: {dataVn2000Transform?.x}</span> 
            </div>
            <div style={{marginTop: '2px', fontSize: '13px'}}>
                <span>Tọa độ Y: {dataVn2000Transform?.y}</span> 
            </div>
            <div style={{marginTop: '2px', fontSize: '13px', maxWidth: '350px'}}>
                <span>Địa chỉ chi tiết: {dataVn2000Transform?.address}</span> 
            </div>
        </div>
    return (
        <div>
            <Title level={3} style={{ width: '350px' }}>Lấy tọa độ theo vị trí</Title>
            <hr />
            <Select
                dataSource={Constant.CAP_THAM_QUYEN}
                label={typo.cscore_tnn_loaiKTT}
                onChange={(value) => onSelectLKTT(value)}
                placeholder={helper.genSelectPL(typo.cscore_tnn_chon_cap)}
                mandatory={true}
                width="200px"
                name="ten"
                name_value="id"
                defaultValue={LoaiKTT}
                key={LoaiKTT}
            />
            <Select
                mandatory={true}
                placeholder={helper.genSelectPL(typo.cscore_tnn_kinh_tuyen_truc)}
                label={typo.cscore_tnn_kinh_tuyen_truc}
                onChange={(value, name) => onSelectKTT(value)}
                dataSource={data_KinhTuyenTruc}
                width="200px"
                name={LoaiKTT === 2 ? "tenHienThi" : "ten"}
                name_value="id"
                defaultValue={KTT}
                key={KTT}
            />
            <Select
                mandatory={true}
                placeholder={helper.genSelectPL(typo.cscore_tnn_mui_chieu)}
                label={typo.cscore_tnn_mui_chieu}
                onChange={(value) => onSelectMC(value)}
                dataSource={Constant.MUI_CHIEU}
                width="200px"
                name="ten"
                name_value="id"
                defaultValue={MuiChieu}
                key={MuiChieu}
            />

            { dataVn2000Transform && displayResultDataToaDoVN2000 }

            <ControllerButton>
                <Button type="excel" style={{marginRight: '10px'}} onClick={() => !start && setStart(true)} disabled={start}>
                    <Icon type="check" theme="outlined"/>
                    {typo.cscore_tnn_start}
                </Button>
                <Button type="cancel" onClick={() => { start && setStart(false) }}>
                    <Icon type="close" theme="outlined" />
                    {typo.cscore_tnn_end}
                </Button>
            </ControllerButton>
        </div>
    );
};
export default LayToaDo;
