import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from 'antd'

const { Title } = Typography;

// Components
const DischargeList = lazy(() => import('./discharge/list'));
const DischargeDetail = lazy(() => import('./discharge/detail'));
const DischargeForm = lazy(() => import('./discharge/form'));
const SurfaceMiningList = lazy(() => import('./surfaceMining/list'));
const SurfaceMiningDetail = lazy(() => import('./surfaceMining/detail'));
const SurfaceMiningFormPage = lazy(() => import('./surfaceMining/form'));
const UndergroundMiningList = lazy(() => import('./undergroundMining/list'));
const UndergroundMiningDetail = lazy(() => import('./undergroundMining/detail'));
const UndergroundMiningFormPage = lazy(() => import('./undergroundMining/form'));
const UndergroundScanningList = lazy(() => import('./undergroundScanning/list'));
const UndergroundScanningDetail = lazy(() => import('./undergroundScanning/detail'));
const UndergroundScanningFormPage = lazy(() => import('./undergroundScanning/form'));
const SeaWaterMiningList = lazy(() => import('./seaWaterMining/list'));
const SeaWaterMiningDetail = lazy(() => import('./seaWaterMining/detail'));
const SeaWaterMiningFormPage = lazy(() => import('./seaWaterMining/form'));
const DrillGroundList = lazy(() => import('./drillGround/list'));
const DrillGroundDetail = lazy(() => import('./drillGround/detail'));
const DrillGroundFormPage = lazy(() => import('./drillGround/form'));
const ImportDuLieu = lazy(() => import('./ImportDuLieu'));
const QuanLyKetNoi = lazy(() => import('./quanLyKetNoi'));


export default ({ match }) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
      <Route exact path={`${match.path}/quanlyketnoi`} component={QuanLyKetNoi} />
        <Route exact path={`${match.path}/import-du-lieu`} component={ImportDuLieu} />
        <Route exact path={`${match.path}/xa-thai`} component={DischargeList} />
        <Route path={`${match.path}/xa-thai/add`} render={() => <DischargeForm />} />
        <Route path={`${match.path}/xa-thai/edit/:id`} render={() => <DischargeForm />} />
        <Route path={`${match.path}/xa-thai/:id`} component={DischargeDetail} />
        <Route exact path={`${match.path}/khai-thac-nuoc-mat`} component={SurfaceMiningList} />
        <Route path={`${match.path}/khai-thac-nuoc-mat/add`} render={() => <SurfaceMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-mat/edit/:id`} render={() => <SurfaceMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-mat/:id`} component={SurfaceMiningDetail} />
        <Route exact path={`${match.path}/khai-thac-nuoc-duoi-dat`} component={UndergroundMiningList} />
        <Route path={`${match.path}/khai-thac-nuoc-duoi-dat/add`} render={() => <UndergroundMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-duoi-dat/edit/:id`} render={() => <UndergroundMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-duoi-dat/:id`} component={UndergroundMiningDetail} />
        <Route exact path={`${match.path}/tham-do-nuoc-duoi-dat`} component={UndergroundScanningList} />
        <Route path={`${match.path}/tham-do-nuoc-duoi-dat/add`} render={() => <UndergroundScanningFormPage />} />
        <Route path={`${match.path}/tham-do-nuoc-duoi-dat/edit/:id`} render={() => <UndergroundScanningFormPage />} />
        <Route path={`${match.path}/tham-do-nuoc-duoi-dat/:id`} component={UndergroundScanningDetail} />
        <Route exact path={`${match.path}/khai-thac-nuoc-bien`} component={SeaWaterMiningList} />
        <Route path={`${match.path}/khai-thac-nuoc-bien/add`} render={() => <SeaWaterMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-bien/edit/:id`} render={() => <SeaWaterMiningFormPage />} />
        <Route path={`${match.path}/khai-thac-nuoc-bien/:id`} component={SeaWaterMiningDetail} />
        <Route exact path={`${match.path}/hanh-nghe-khoan-nuoc`} component={DrillGroundList} />
        <Route path={`${match.path}/hanh-nghe-khoan-nuoc/add`} render={() => <DrillGroundFormPage />} />
        <Route path={`${match.path}/hanh-nghe-khoan-nuoc/edit/:id`} render={() => <DrillGroundFormPage />} />
        <Route path={`${match.path}/hanh-nghe-khoan-nuoc/:id`} component={DrillGroundDetail} />
      </Switch>
    </Suspense>
  )
}