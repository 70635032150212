import React from 'react'
import {Row, Typography} from 'antd'

const {Text} = Typography;

export default ({title, unit}) => {
  return (
    <Row>
      <Row>
        <Text>{title}</Text>
      </Row>
      {unit && (
        <Row type="flex" justify="center">
          <Text>{unit}</Text>
        </Row>
      )}
    </Row>
  )
}