
import React, { useState } from "react";
import "leaflet"
import * as L from 'esri-leaflet'
import { useMap } from 'react-leaflet'
const BanDoVietNam = ({ opacity }) => {
    const divRef = React.useRef(null);
    const [hienthi, sethienthi] = useState(opacity);
    const map = useMap()

    React.useEffect(() => {
        map.on("overlayadd", (e) => {
            if(e.name === "Google map vệ tinh (Tham khảo)"){
                sethienthi(0)
            }
        });
        map.on("overlayremove", (e) => {
            if(e.name === "Google map vệ tinh (Tham khảo)"){
                sethienthi(1)
            }
        });
        L.tiledMapLayer({
            // url: "https://api.monre.gov.vn/basemap/1.0.0/server/rest/services/bandovn/MapServer",
            url: "https://basemap.bandovn.vn/server/rest/services/bandovn/MapServer",
            opacity: hienthi,
        }).addTo(map);
    });
    return (
        <div ref={divRef}></div>
    );
}
export default BanDoVietNam