import React from 'react'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash'
import {Formik} from 'formik'
import * as Yup from 'yup'

//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import CLNuocThaiModal from "./../Modal";
import TableTitle from '../../../Table/Title'
import helper from "../../../../helper/helper";
const CachTinhCmax = {
  1 : "Tính tự động",
  2 : "Nhập thủ công"
}

// Columns
const columns_form = (showEditModal, deleteRowModal) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
  },
  {
    title: "Quy chuẩn áp dụng",
    dataIndex: "tenQCApDung",
    width: "12%"
  },
  {
    title: "Cột A/B",
    dataIndex: "tenMDSD",
  },
  {
    title: "Hệ số kq",
    dataIndex: "heSoKQ",
    width: "8%"
  },
  {
    title: "Hệ số kf",
    dataIndex: "heSoKF",
    width: "8%"
  },
  {
    title: "Hệ số K",
    dataIndex: "heSoK",
    width: "8%"
  },
  {
    title: "Thao tác",
    render: (value, row) => (
      <RowTools row={row} deleteRowModal={() => deleteRowModal(row)} showEditModal={showEditModal}/>
    ),
    width: "80px"
  },
];

const columns_presentation = () => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
  },
  {
    title: "Quy chuẩn áp dụng",
    dataIndex: "tenQCApDung",
    width: "12%"
  },
  {
    title: "Cột A/B",
    dataIndex: "tenMDSD",
  },
  {
    title: "Hệ số kq",
    dataIndex: "heSoKQ",
    width: "8%"
  },
  {
    title: "Hệ số kf",
    dataIndex: "heSoKF",
    width: "8%"
  },
  {
    title: "Hệ số K",
    dataIndex: "heSoK",
    width: "8%"
  },
];

export default class CLNuocThai extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      miningQuantity: [],
      selectedRow: null
    }
  }

  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = [{...item, id: dataSource.length + 1}, ...dataSource];
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleEdit = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.replaceItem(item, dataSource);
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.removeItem(item, dataSource);
    handleUpdate(newData, field);
  };

  addSttToItems = (items) => {
    if(!items) return [];
    var newItems = [];
    if(items){
        newItems = items.map((item, index) => ({...item,stt: index + 1}))
    }
    return newItems;
  };

  render() {
    const {isAddModalOpen, isEditModalOpen, selectedRow} = this.state;
    const {dataSource, mode} = this.props;

    return (
      <Row>
        <Row type="flex" justify="end" align="middle">
          {mode !== "presentation" && <Button type="primary" onClick={this.handleOpenAddModal} icon="plus"/>}
        </Row>
        <div className="mt-10"/>
        <ViewTable
          bordered pagination={false}
          columns={mode !== "presentation" ? columns_form(this.handleOpenEditModal, this.handleDelete) : columns_presentation()}
          dataSource={this.addSttToItems(dataSource)}
          scroll={{ x: 500 }}
        />
        {isAddModalOpen && 
        <CLNuocThaiModal
          mode="add"
          isOpen={isAddModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleAdd}/>}
          {isEditModalOpen && 
        <CLNuocThaiModal
          mode="edit"
          initialValues={selectedRow}
          isOpen={isEditModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleEdit}/>}
      </Row>
    )
  }
}

CLNuocThai.propTypes = {
  // MODE: string "form" and "presentation"
  mode: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func,
  dataSource: PropTypes.array.isRequired
};