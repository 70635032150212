//DVHC
export const GET_DVHC_DS_TINH_REQUEST = 'get_dvhc_tinh_request';
export const GET_DVHC_DS_TINH_SUCCEED = 'get_dvhc_tinh_succeed';
export const GET_DVHC_DS_TINH_FAILED = 'get_dvhc_tinh_failed';

export const GET_DVHC_DS_HUYEN_BY_TINH_REQUEST = 'get_dvhc_huyen_request';
export const GET_DVHC_DS_HUYEN_BY_TINH_SUCCEED = 'get_dvhc_huyen_succeed';
export const GET_DVHC_DS_HUYEN_BY_TINH_FAILED = 'get_dvhc_huyen_failed';

export const GET_DVHC_DS_XA_BY_HUYEN_REQUEST = 'get_dvhc_xa_request';
export const GET_DVHC_DS_XA_BY_HUYEN_SUCCEED = 'get_dvhc_xa_succeed';
export const GET_DVHC_DS_XA_BY_HUYEN_FAILED = 'get_dvhc_xa_failed';

//Profile
export const GET_PROFILE_REQUEST = 'get_profile_request';
export const GET_PROFILE_SUCCEED = 'get_profile_succeed';
export const GET_PROFILE_FAILED = 'get_profile_failed';

//Get Token
export const GET_TOKEN_GIAMSAT_REQUEST = 'get_token_giamsat_request';
export const GET_TOKEN_GIAMSAT_SUCCEED = 'get_token_giamsat_succeed';
export const GET_TOKEN_GIAMSAT_FAILED = 'get_token_giamsat_failed';

export const GET_TOKEN_LGSP_REQUEST = 'get_token_lgsp_request';
export const GET_TOKEN_LGSP_SUCCEED = 'get_token_lgsp_succeed';
export const GET_TOKEN_LGSP_FAILED = 'get_token_lgsp_failed';

//Cấu hình giấy phép
export const GET_CAUHINH_GP_REQUEST = 'get_cauhinh_gp_request';
export const GET_CAUHINH_GP_SUCCEED = 'get_cauhinh_gp_succeed';
export const GET_CAUHINH_GP_FAILED = 'get_cauhinh_gp_failed';

//Loại CT
export const GET_LOAI_CT_REQUEST = 'get_loai_ct_request';
export const GET_LOAI_CT_SUCCEED = 'get_loai_ct_succeed';
export const GET_LOAI_CT_FAILED = 'get_loai_ct_failed';

//Kinh tuyến trục
export const GET_KTT_TW_REQUEST = 'get_ktt_tw_request';
export const GET_KTT_TW_SUCCEED = 'get_ktt_tw_succeed';
export const GET_KTT_TW_FAILED = 'get_ktt_tw_failed';

export const GET_KTT_DP_REQUEST = 'get_ktt_dp_request';
export const GET_KTT_DP_SUCCEED = 'get_ktt_dp_succeed';
export const GET_KTT_DP_FAILED = 'get_ktt_dp_failed';
//Lưu vực sông
export const GET_LUUVUC_SONG_REQUEST = 'get_luuvuc_song_request';
export const GET_LUUVUC_SONG_SUCCEED = 'get_luuvuc_song_succeed';
export const GET_LUUVUC_SONG_FAILED = 'get_luuvuc_song_failed';
