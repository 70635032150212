/*
 *  @2019 CS-Core project.
 *
 */

import React from "react";
import styled from "styled-components";
import { Spin, Skeleton } from "antd";
import { FullLoading, Box } from "../helper/loading";

const Fly = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
`;

export default ({
  children,
  style,
  loading = false,
  fullLoading = false,
  isModal = false,
  skeleton = false,
  ...props
}) => (
  <div
    className='layout-container'
    style={{...style}}
    {...props}
  >
    {skeleton && <Skeleton />}
    {fullLoading && <FullLoading />}
    {loading && !fullLoading && (
      <Fly>
        <Box>
          <Spin />
        </Box>
      </Fly>
    )}
    {!skeleton && <div>{children}</div>}
  </div>
);
