import React, {Component} from "react";
import {connect} from 'react-redux'

class ItemInfo extends Component {
  render() {
    const {item} = this.props
    return (
      item &&
      <table className='detail-table'>
        <tbody className='detail-tbody'>
        <tr>
          <th className='detail-th'>Tên sông</th>
          <td className='detail-td'>{item.tensong}</td>
        </tr>
        <tr>
          <th className='detail-th'>Thuộc sông chính</th>
          <td className='detail-td'>{item.thuoc_sc}</td>
        </tr>
        <tr>
          <th className='detail-th'>Tên khác</th>
          <td className='detail-td'>{item.tenkhac}</td>
        </tr>
        <tr>
          <th className='detail-th'>Tên cũ</th>
          <td className='detail-td'>{item.tencu}</td>
        </tr>
        <tr>
          <th className='detail-th'>Kênh sông</th>
          <td className='detail-td'>{item.kenh_song}</td>
        </tr>
        <tr>
          <th className='detail-th'>Diện tích (Km<sup>2</sup>)</th>
          <td className='detail-td'>{item.dtkm2}</td>
        </tr>
        <tr>
          <th className='detail-th'>Đổ vào</th>
          <td className='detail-td'>{item.do_vao}</td>
        </tr>
        <tr>
          <th className='detail-th'>Chiều dài (Km)</th>
          <td className='detail-td'>{item.daikm}</td>
        </tr>
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(ItemInfo);