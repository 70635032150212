//common
import React from "react";
// Components
import RowTools from "../../../Table/RowTools";
import { Link } from "react-router-dom";

import helper from "../../../../helper/helper";

import typo from "../../../../constants/dictionaries";
const columns = ({ handleEdit, handleDelete, downFile, view } = {}) =>
  [
    {
      title: "#",
      dataIndex: "stt",
      width: "65px",
      align: 'center'
    },
    {
      title: typo.cscore_tnn_tenTaiLieu,
      dataIndex: "tenTaiLieu",
      defaultSortOrder: "descend",
      render: (value, row) => (<a onClick={() => downFile(row)}>{row.tenTaiLieu}</a>)
    },
    {
      title: typo.cscore_tnn_soTaiLieu,
      dataIndex: "soTaiLieu",
      width: "20%",
    },
    {
      title: "Ghi chú",
      dataIndex: "ghiChu",
      width: "25%",
    },
    {
      key: "tools",
      title: "Thao tác",
      dataIndex: "tools",
      width: "80px",
      render: (value, row) => (
        <RowTools row={{ row, action: "edit" }}
          showEditModal={() => handleEdit(row)}
          deleteRowModal={() => { }}
          popconfirmInfor={{
            placement: 'top',
            title: "Gỡ tạm thời file này ?",
            okText: "Gỡ",
            cancelText: "Hủy",
            onConfirm: () => handleDelete(row.stt)
          }}
          iconDelete={view === 'view' ? true : false}
          iconEdit={view === 'view' ? true : false}
          iconDetail={view === 'view' ? true : false}
          showDetail={() => handleEdit(row)}
        />)
    },
  ].map(col => ({ ...col }));

export default columns;
