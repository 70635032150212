import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux'
import { FastField } from 'formik'
import moment from "moment";
import { isEmpty } from 'lodash'
// Components
import Table from "../../components/Table";
import Button from "../../components/Buttons"
import Container from "../../containers/Container";
import PageWrapper from "../../components/Common/PageWrapper";
import { Row, Col, Icon } from "antd";
import message from "../../components/Messages";
import SearchInput from "../../components/Common/Input/SearchInput";
import FilterWrapper from "../../components/Common/FilterWrapper";
import helper from '../../helper/helper';
import constant, { functionsCode } from '../../constants/constant'
// typo
import typo, { MESSAGE } from "../../constants/dictionaries";

// Helper
import SearchByURI from "../../helper/SearchByURI";

// Users Columns
import columns from "./NKHTList.column";

import Api_tnn from "../../api/api.tnn";
import DateField from "../../components/Common/DateField";
import Helper from "../../helper/helper";

const Wrapper = styled.div`
  padding: 16px 0 24px 0;
`;

const ControllerButton = styled.div`
  align-items: flex-end;
  text-align: right;
`;
class NhatKyHeThong extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      data: [],
      sortedInfo: {
        field: null,
        order: null
      },
      filterInfo: {
        strSearch: null,
        from: null,
        to: null,
      },
      pagination: {},
      selectedRow: null,
      editable: !isEmpty(functions) ? helper.handleGetEditableFunction(functions, functionsCode.LOG).editable : false,
    };

    this.searchParams = {
      PageNumber: 1,
      PageSize: 10,
      Column: "FullName",
      Sort: "DESC",
      Id: null,
      StrSearch: "",
      From: null,
      To: null,

    };

    this.searchByURI = new SearchByURI({ defaultParams: this.searchParams });
  }

  componentDidMount() {
    const searchParams = this.searchByURI.getSearchParmas();
    this.setState({
      sortedInfo: {
        field: searchParams.Column,
        order: searchParams.Sort === "ASC" ? "ascend" : "descend"
      },
      filterInfo: {
        strSearch: searchParams.StrSearch,
        from: searchParams.From,
        to: searchParams.To,
      },
    });
    this.getLogs(searchParams.StrSearch)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const { functions } = this.props;
      if (!isEmpty(functions)) {
        const func = helper.handleGetEditableFunction(functions, functionsCode.LOG);
        this.setState({ editable: func.editable })
      }
    }
  }

  getLogs = (searchText) => {
    const searchParams = this.searchByURI.getSearchParmas();
    var from = searchParams.From ? moment(searchParams.From).format("DD-MM-YYYY HH:mm:ss") : null
    var to = searchParams.To ? moment(searchParams.To).format("DD-MM-YYYY HH:mm:ss") : null
    new Api_tnn()
      .SearchLog({
        token: this.props.user.access_token,
        page: searchParams.PageNumber,
        size: searchParams.PageSize,
        searchText: searchText,
        from: from,
        to: to,
      })
      .then(res => {
        this.setState({
          data: res && res.data.map((log, index) => ({ ...log, stt: index + 1 + (searchParams.PageNumber - 1) * searchParams.PageSize })),
          pagination: res && res.pagination
        });
      });
  };

  DeleteApiLog = (id) => {
    const searchParams = this.searchByURI.getSearchParmas();
    new Api_tnn()
      .DeleteLog({
        token: this.props.user.access_token,
        id: id,
      })
      .then(res => {
        this.setState({
          selectedItemId: undefined,
        });
        message.success(MESSAGE.cscore_tnn_REMOVE_SUCCESS);
        this.getLogs(searchParams.StrSearch)
      }).catch(err => {
        message.error(err.message)
      });
  };
  onOkDatetime = value => { };

  showDeleteConfirm = (selectedRow) => {
    const searchParams = this.searchByURI.getSearchParmas();
    this.setState({
      selectedRow
    });
    this.DeleteApiLog(selectedRow)
  }
  downFileExcel = () => {
    const searchParams = this.searchByURI.getSearchParmas();
    var from = searchParams.From ? moment(searchParams.From).format("DD-MM-YYYY HH:mm:ss") : undefined
    var to = searchParams.To ? moment(searchParams.To).format("DD-MM-YYYY HH:mm:ss") : undefined
    new Api_tnn()
      .DownloadLog({
        token: this.props.user.access_token,
        searchText: searchParams.StrSearch ? searchParams.StrSearch : "",
        from: from ? from : "",
        to: to ? to : "",
        cache: "no"
      })
  }
  /**
   * @function handleTableParamsChange Handle table params
   */
  handleTableParamsChange = (pagination, filteredInfo, sortedInfo) => {
    this.searchByURI.navigate({
      Column: sortedInfo.field,
      Sort: sortedInfo.order
        ? sortedInfo.order === "ascend"
          ? "ASC"
          : "DESC"
        : undefined,
      PageNumber: pagination.current,
      PageSize: pagination.pageSize,

    });
  };

  handleFilterParamsChange = (filters) => {
    this.searchByURI.navigate({
      StrSearch: filters.strSearch,
      From: filters.from,
      To: filters.to,
    })
  };


  render() {
    const {
      sortedInfo,
      pagination,
      data,
      filterInfo,
      editable
    } = this.state;
    const { user } = this.props;
    const searchParams = this.searchByURI.getSearchParmas();
    return (
      <PageWrapper title={typo.cscore_tnn_dm_NKHT}>
        <Container>
          <Wrapper>
            <Row type="flex" justify="start">
              <Col>
                <FilterWrapper handleApplyFilter={this.handleFilterParamsChange} initialValues={filterInfo} hideButton={true}>
                  {({ handleChange, setFieldValue, values, handleSubmit }) => (
                    <div style={{ width: "100%" }}>
                      <Row gutter={16} type="flex">
                        <Col>
                          <FastField name="strSearch" render={({ field }) =>
                            <SearchInput
                              className='select-right' width={250} style={{ paddingRight: "10px" }}
                              {...field}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSubmit()
                                }
                              }} />
                          } />
                        </Col>
                        <Col>
                          <DateField
                            defaultPickerValue={searchParams.From && searchParams.To ? [moment(searchParams.From), moment(searchParams.To)] : undefined}
                            defaultValue={searchParams.From && searchParams.To ? [moment(searchParams.From), moment(searchParams.To)] : undefined}
                            showTime={{ format: "HH:mm:ss" }}
                            placeholder={[Helper.genSelectPL(typo.cscore_tnn_from), Helper.genSelectPL(typo.cscore_tnn_to)]}
                            onChange={value => {
                              if (value !== null || value.length < 2) {
                                setFieldValue("from", value[0]);
                                setFieldValue("to", value[1])
                              } else {
                                setFieldValue("from", null);
                                setFieldValue("to", null)
                              }
                            }
                            }
                            onOk={this.onOkDatetime}
                            width="300px"
                            type='range'
                            mandatory={false}
                          />
                        </Col>
                        <Col>
                          <Button type="submit" onClick={() => this.handleFilterParamsChange(values)}> <Icon type="search" style={{ marginLeft: '0px' }} />Tìm kiếm</Button>
                        </Col>
                        <Col>
                          <button type="primary" onClick={() => this.downFileExcel()} className="button-excel">
                          <Icon type="file-excel" style={{marginRight:'10px'}}  />
                            {typo.cscore_tnn_out_excel}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </FilterWrapper>
              </Col>
            </Row>
          </Wrapper>
          <Table
            columns={columns({
              sortedInfo,
              showEditModal: this.showEditModal,
              deleteRow: this.showDeleteConfirm,
              editable
            })}
            dataSource={data}
            hideTotal="true"
            onChange={this.handleTableParamsChange}
            pagination={{
              total: pagination.total,
              pageSize: searchParams.PageSize,
              current: pagination.currentPage
            }}
          />
        </Container>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(NhatKyHeThong);
