import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const {Title} = Typography;

// Components
const ThuyDienList = lazy(() => import('./ThuyDien/list'));
const ThuyDienDetail = lazy(() => import('./ThuyDien/detail'));
const ThuyDienForm = lazy(() => import('./ThuyDien/form'));

const HoChuaList = lazy(() => import('./HoChua/List'));
const HoChuaDetail = lazy(() => import('./HoChua/Detail'));
const HoChuaForm = lazy(() => import('./HoChua/Form'));

const TramBomList = lazy(() => import('./TramBom/List'));
const TramBomDetail = lazy(() => import('./TramBom/Detail'));
const TramBomForm = lazy(() => import('./TramBom/Form'));

const BaiDapList = lazy(() => import('./BaiDap/List'));
const BaiDapDetail = lazy(() => import('./BaiDap/Detail'));
const BaiDapForm = lazy(() => import('./BaiDap/Form'));

const CongList = lazy(() => import('./Cong/List'));
const CongDetail = lazy(() => import('./Cong/Detail'));
const CongForm = lazy(() => import('./Cong/Form'));

const TramCapNuocList = lazy(() => import('./TramCapNuoc/List'));
const TramCapNuocDetail = lazy(() => import('./TramCapNuoc/Detail'));
const TramCapNuocForm = lazy(() => import('./TramCapNuoc/Form'));

const NhaMayNuocList = lazy(() => import('./NhaMayNuoc/List'));
const NhaMayNuocDetail = lazy(() => import('./NhaMayNuoc/Detail'));
const NhaMayNuocForm = lazy(() => import('./NhaMayNuoc/Form'));

export default ({match}) => {
  const groupName = 'Công trình nước mặt'
  const thuyDien = 'thuy-dien'
  const HoChua = 'ho-chua'
  const TramBom = 'tram-bom'
  const BaiDap = 'bai-dap'
  const Cong = 'cong'
  const TramCapNuoc = 'tram-cap-nuoc'
  const NhaMayNuoc = 'nha-may-nuoc'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/${thuyDien}`} render={(props) => <ThuyDienList {...props} path={`${match.path}/${thuyDien}`} groupName={groupName}/>}/>
        <Route path={`${match.path}/${thuyDien}/add`} render={(props) => <ThuyDienForm {...props} type="add" groupName={groupName}/>}/>
        <Route path={`${match.path}/${thuyDien}/:id/edit`} render={(props) => <ThuyDienForm {...props} groupName={groupName}/>}/>
        <Route path={`${match.path}/${thuyDien}/:id/detail`} render={(props) => <ThuyDienDetail {...props} groupName={groupName}/>}/>
        
        <Route exact path={`${match.path}/${HoChua}`} render={(props) => <HoChuaList {...props} path={`${match.path}/${HoChua}`} groupName={groupName} name={typo.cscore_tnn_hoChua}/>}/>
        <Route path={`${match.path}/${HoChua}/add`} render={(props) => <HoChuaForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_hoChua}/>}/>
        <Route path={`${match.path}/${HoChua}/:id/edit`} render={(props) => <HoChuaForm {...props} groupName={groupName} name={typo.cscore_tnn_hoChua}/>}/>
        <Route path={`${match.path}/${HoChua}/:id/detail`} render={(props) => <HoChuaDetail {...props} groupName={groupName} name={typo.cscore_tnn_hoChua}/>}/>

        <Route exact path={`${match.path}/${TramBom}`} render={(props) => <TramBomList {...props} path={`${match.path}/${TramBom}`} groupName={groupName} name={typo.cscore_tnn_tramBom}/>}/>
        <Route path={`${match.path}/${TramBom}/add`} render={(props) => <TramBomForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_tramBom}/>}/>
        <Route path={`${match.path}/${TramBom}/:id/edit`} render={(props) => <TramBomForm {...props} groupName={groupName} name={typo.cscore_tnn_tramBom}/>}/>
        <Route path={`${match.path}/${TramBom}/:id/detail`} render={(props) => <TramBomDetail {...props} groupName={groupName} name={typo.cscore_tnn_tramBom}/>}/>

        <Route exact path={`${match.path}/${BaiDap}`} render={(props) => <BaiDapList {...props} path={`${match.path}/${BaiDap}`} groupName={groupName} name={typo.cscore_tnn_baiDap}/>}/>
        <Route path={`${match.path}/${BaiDap}/add`} render={(props) => <BaiDapForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_baiDap}/>}/>
        <Route path={`${match.path}/${BaiDap}/:id/edit`} render={(props) => <BaiDapForm {...props} groupName={groupName} name={typo.cscore_tnn_baiDap}/>}/>
        <Route path={`${match.path}/${BaiDap}/:id/detail`} render={(props) => <BaiDapDetail {...props} groupName={groupName} name={typo.cscore_tnn_baiDap}/>}/>

        <Route exact path={`${match.path}/${Cong}`} render={(props) => <CongList {...props} path={`${match.path}/${Cong}`} groupName={groupName} name={typo.cscore_tnn_cong}/>}/>
        <Route path={`${match.path}/${Cong}/add`} render={(props) => <CongForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_cong}/>}/>
        <Route path={`${match.path}/${Cong}/:id/edit`} render={(props) => <CongForm {...props} groupName={groupName} name={typo.cscore_tnn_cong}/>}/>
        <Route path={`${match.path}/${Cong}/:id/detail`} render={(props) => <CongDetail {...props} groupName={groupName} name={typo.cscore_tnn_cong}/>}/>

        <Route exact path={`${match.path}/${TramCapNuoc}`} render={(props) => <TramCapNuocList {...props} path={`${match.path}/${TramCapNuoc}`} groupName={groupName} name={typo.cscore_tnn_TramCapNuoc}/>}/>
        <Route path={`${match.path}/${TramCapNuoc}/add`} render={(props) => <TramCapNuocForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_TramCapNuoc}/>}/>
        <Route path={`${match.path}/${TramCapNuoc}/:id/edit`} render={(props) => <TramCapNuocForm {...props} groupName={groupName} name={typo.cscore_tnn_TramCapNuoc}/>}/>
        <Route path={`${match.path}/${TramCapNuoc}/:id/detail`} render={(props) => <TramCapNuocDetail {...props} groupName={groupName} name={typo.cscore_tnn_TramCapNuoc}/>}/>

        <Route exact path={`${match.path}/${NhaMayNuoc}`} render={(props) => <NhaMayNuocList {...props} path={`${match.path}/${NhaMayNuoc}`} groupName={groupName} name={typo.cscore_tnn_NhaMayNuoc}/>}/>
        <Route path={`${match.path}/${NhaMayNuoc}/add`} render={(props) => <NhaMayNuocForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_NhaMayNuoc}/>}/>
        <Route path={`${match.path}/${NhaMayNuoc}/:id/edit`} render={(props) => <NhaMayNuocForm {...props} groupName={groupName} name={typo.cscore_tnn_NhaMayNuoc}/>}/>
        <Route path={`${match.path}/${NhaMayNuoc}/:id/detail`} render={(props) => <NhaMayNuocDetail {...props} groupName={groupName} name={typo.cscore_tnn_NhaMayNuoc}/>}/>
      </Switch>
    </Suspense>
  )
}