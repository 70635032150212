import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Layout, Row, Col, Collapse, Icon, Pagination, Modal } from "antd";
import Select from "../../components/Common/Select";
// import Select from "cscore_component/components/Select"
import DateField from '../../components/Common/DateField'
import typo, { MESSAGE } from "../../constants/dictionaries";
import Container from "../../containers/Container";
import messages from '../../components/Messages'
import Button from '../../components/Buttons'
import BarChart from './bar'
import PieChart from './pie'
import BoxChart from './boxChart'
import ContainerTotalStatistic from './containerTotalStatistic'
import NguonNuoc from "./nguonNuoc"
import Api_tnn from '../../api/api.tnn';
import Api from '../../api';
import Helper from '../../helper/helper'
import Constant from '../../constants/constant';
import SearchInput from "../../components/Common/Input/SearchInput";
import defaultappicon from "../../assets/images/defaultappicon.png";
import MapModal from "../../components/BanDo/MiniMap"
import Api_spatial from '../../api/api.spatial';
import Message from '../../components/Messages';
const { Panel } = Collapse;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChartTitle: false, chartTitle: undefined, data: [], congTrinhData: [], giayPhephData: [], chartType: "ty_le",
      objects: doiTuongs, selectedObject: "cong_trinh", groups: congTrinhs, selectedGroup: "cong_trinh_nuoc_mat", units: congTrinhNuocMats, selectedUnit: undefined,
      charts: charts, selectedChart: "ty_le", phases: phases, selectedPhase: "tat_ca", periods: periods, selectedPeriod: undefined,
      from: (new Date().getFullYear() - 14) + "-01-01",
      to: new Date().getFullYear() + "-12-31",
      tongSoGiayPhepData: [],
      data_nguonnuoc: [],
      pagination: {},
      search_timkiem: {
        strSearch: "",
        loai_nguonnuoc: undefined,
        maHuyen: "",
        maXa: ""
      },
      data_huyen: [],
      data_xa: [],
      data_thongtinlienhe: {},
      isShowMapModal: false,
      idho: undefined,
      GeoJson_data: {},
      toado: undefined,
    }
  }
  componentDidMount() {
    this.GetCauHinhs()
    //this.getCongTrinhData()
    this.getGiayPhepData()
    this.getYears()
    this.handleSubmit()
    this.GetDM_NguonNuocNoiTinhs(1)
    this.GetDM_ThongTinLienHes()
    Number(Constant.MA_TINH) === Constant.MaTinh.HaiPhong && Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG && this.getApiHuyenByTinhs()
  }

  GetDM_ThongTinLienHes = () => {
    const { user } = this.props;
    new Api_tnn().GetDM_ThongTinLienHes({
      token: user.access_token,
      strSearch: "",
      page: 0,
      size: 0
    })
      .then(res => {
        this.setState({
          data_thongtinlienhe: res.data.length > 0 ? res.data[0] : {}
        })
      })
      .catch(err => messages.error(MESSAGE.cscore_tnn_ERROR))
  };

  getApiHuyenByTinhs = () => {
    new Api()
      .getHuyenByTinhs({
        token: this.props.user.access_token,
        maTinh: Constant.MA_TINH
      })
      .then(res => { this.setState({ data_huyen: res }) });
  };

  getXaByHuyens = (maHuyen) => {
    const { user } = this.props;
    new Api().getXaByHuyens({ token: user.access_token, maHuyen }).then(
      res => {
        this.setState({ data_xa: res })
      })
      .catch(err => {
        messages.error(err.message)
      });
  };
  getCongTrinhData() {
    new Api_tnn().statistic({
      token: this.props.user.access_token,
      obj: 'cong_trinh', unit: undefined, chart: 'thay_doi', period: 'nam',
      phase: 'theo_thoi_gian',
      from: moment(new Date()).set('year', (new Date()).getFullYear() - 5),
      to: moment(new Date())
    }).then((result) => {
      if (result) {
        this.setState({ congTrinhData: this.groupDataByKey(result) })
      }
      else { messages.error("Lấy dữ liệu lỗi") }
    })
  }
  GetDM_NguonNuocNoiTinhs = (page, strSearch, phanloainguonnuoc, mahuyen, maxa) => {
    new Api_tnn()
      .GetDM_NguonNuocNoiTinhs({
        token: this.props.user.access_token,
        strSearch: strSearch,
        phanloainguonnuoc: phanloainguonnuoc,
        page: page,
        size: 10,
        mahuyen: mahuyen,
        maxa: maxa
      })
      .then(res => {
        this.setState({
          data_nguonnuoc: res && res.data.map((value, index) => ({
            ...value,
            tenHienThi: value.tenSongSuoi +
              (value.chieuDai ? ", chiều dài: " : "") + (value.chieuDai ? value.chieuDai + "Km" : "") +
              (value.doSau ? ", độ sâu: " : "") + (value.doSau ? value.doSau + "m" : "") +
              (value.dienTichLuuVuc ? ", diện tích lưu vực: " : "") + (value.dienTichLuuVuc ? value.dienTichLuuVuc + "Km2" : "") +
              (value.chayRaSong ? ", đổ ra: " : "") + (value.chayRaSong ? value.chayRaSong : "")
          })),
          pagination: res && res.pagination
        });
      }).catch(err => {
        messages.error(err.message)
      });
  };
  GetCauHinhs = () => {
    new Api()
      .GetCauHinhs({
        token: this.props.user.access_token,
        page: 0,
        size: 0
      })
      .then(res => {
        if (res.data && res.data.length > 0) {
          let shhl_xt = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.XT).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.XT)[0].giaTriCauHinh : 120
          let shhl_nm = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NM).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NM)[0].giaTriCauHinh : 120
          let shhl_nb = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NB).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NB)[0].giaTriCauHinh : 120
          let shhl_ndd_kt = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_KT).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_KT)[0].giaTriCauHinh : 120
          let shhl_ndd_td = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_TD).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.NDD_TD)[0].giaTriCauHinh : 120
          let shhl_hn = res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.HN).length > 0 ? res.data.filter(x => x.maCauHinh === Constant.CAU_HINH_THOI_GIAN.HN)[0].giaTriCauHinh : 120
          this.tongso_gp(shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn)
        }
      })
      .catch(err => {
        messages.error(err.messages)
      })
  };

  tongso_gp = (shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn) => {
    new Api_tnn().tongso_gp({
      token: this.props.user.access_token,
      shhl_xt: shhl_xt,
      shhl_nm: shhl_nm,
      shhl_nb: shhl_nb,
      shhl_ndd_kt: shhl_ndd_kt,
      shhl_ndd_td: shhl_ndd_td,
      shhl_hn: shhl_hn
    }).then((result) => {
      if (result) {
        if (Number(Constant.MA_TINH) === Constant.MaTinh.HaiPhong) {
          this.setState({
            tongSoGiayPhepData: [[
              { indicator: 'Giấy phép', tittle: "NƯỚC MẶT", value: result ? result.count_nm : 0, indicator_shhl: ' Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_nm : 0, indicator_hhl: ' Hết hiệu lực', value_hhl: result ? result.count_nm_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_nm : 0, image: '', backGroundColor: '#27c24c' },
              { indicator: 'Giấy phép ', tittle: "XẢ NƯỚC THẢI", value: result ? result.count_xnt : 0, indicator_shhl: 'Sắp hết hiệu lực', value_shhl: result ? result.count_shhl_xnt : 0, indicator_hhl: 'Hết hiệu lực', value_hhl: result ? result.count_xnt_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_xnt : 0, image: '', backGroundColor: '#9289ca' },
              { indicator: 'Quyết định phê duyệt', tittle: "TIỀN CẤP QUYỀN", value: result ? result.count_tcq : 0, image: '', backGroundColor: '#656565b8' },
              { indicator: 'Văn bản quy phạm pháp luật', tittle: "TÀI LIỆU VĂN BẢN", value: result ? result.count_vb : 0, image: '', backGroundColor: '#5d9cec' }
            ]]
          })
        }
        else {
          this.setState({
            tongSoGiayPhepData: [[
              { indicator: 'Giấy phép', tittle: "NƯỚC DƯỚI ĐẤT", value: result ? result.count_ndd_kt : 0, indicator_shhltb: 'Sắp hết hiệu lực', value_shhltb: result ? result.count_shhl_ndd_kt : 0, indicator_hhltb: 'Hết hiệu lực', value_hhltb: result ? result.count_ndd_kt_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_ndd_kt : 0, image: '', backGroundColor: '#27c24c' },
              { indicator: 'Giấy phép ', tittle: "NƯỚC MẶT", value: result ? result.count_nm : 0, indicator_shhltb: ' Sắp hết hiệu lực', value_shhltb: result ? result.count_shhl_nm : 0, indicator_hhltb: ' Hết hiệu lực', value_hhltb: result ? result.count_nm_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_nm : 0, image: '', backGroundColor: '#9289ca' },
              { indicator: ' Giấy phép', tittle: "XẢ NƯỚC THẢI", value: result ? result.count_xnt : 0, indicator_shhltb: 'Sắp hết hiệu lực ', value_shhltb: result ? result.count_shhl_xnt : 0, indicator_hhltb: 'Hết hiệu lực ', value_hhltb: result ? result.count_xnt_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_xnt : 0, image: '', backGroundColor: '#656565b8' },
              { indicator: 'Giấy phép  ', tittle: "NƯỚC BIỂN", value: result ? result.count_nb : 0, indicator_shhltb: '  Sắp hết hiệu lực', value_shhltb: result ? result.count_shhl_nb : 0, indicator_hhltb: '  Hết hiệu lực', value_hhltb: result ? result.count_nb_hhl : 0, indicator_chuathaythe: 'Chưa có GP thay thế', value_ctt: result ? result.count_ctt_nb : 0, image: '', backGroundColor: '#5d9cec' }
            ]]
          })
        }
      }
      else { messages.error("Lấy dữ liệu lỗi") }
    })
  }

  getGiayPhepData() {
    new Api_tnn().statistic({
      token: this.props.user.access_token,
      obj: 'giay_phep',
      group: undefined,
      unit: undefined,
      chart: 'thay_doi',
      period: 'nam',
      phase: 'theo_thoi_gian',
      from: (new Date().getFullYear() - 14) + "-01-01",
      to: new Date().getFullYear() + "-12-31"
    }).then((result) => {
      if (result) {
        this.setState({ giayPhephData: this.groupDataByKey(result) })
      }
      else { messages.error("Lấy dữ liệu lỗi") }
    })
  }

  getYears = () => {
    var c_year = new Date().getFullYear(); var years = []; var years_tunam = [];
    for (var i = c_year + 20; i >= 1975; i--) { years.push({ year: i }) }
    for (var i = c_year; i >= 1975; i--) { years_tunam.push({ year: i }) }
    this.setState({ years_tunam: years_tunam, years: years, selectedFromYear: c_year - 14, selectedToYear: c_year })

  }

  selectObject = (value) => { this.setState({ selectedObject: value, selectedGroup: undefined, selectedUnit: undefined, groups: value === "cong_trinh" ? congTrinhs : (value === "giay_phep" ? giayPheps : (value === "quan_trac" ? quanTracs : [])) }) }
  selectGroup = (value) => {
    const { selectedObject } = this.state
    this.setState({ selectedGroup: value, selectedUnit: undefined })
    if (selectedObject === "cong_trinh") { this.setState({ units: value === "cong_trinh_nuoc_mat" ? congTrinhNuocMats : (value === "cong_trinh_nuoc_duoi_dat" ? congTrinhNuocDuoiDats : (value === "cong_trinh_nuoc_xa_thai" ? congTrinhXaThais : (value === "cong_trinh_nuoc_bien" ? congTrinhNuocBiens : []))) }) }
    if (selectedObject === "giay_phep") { this.setState({ units: value === "nhom_giay_phep" ? nhomGiayPheps : [] }) }
    if (selectedObject === "quan_trac") { this.setState({ units: value === "quan_trac_tai_nguyen_nuoc" ? quanTracTaiNguyenNuocs : (value === "quan_trac_khi_tuong_thuy_van" ? quanTracKhiTuongThuyVans : []) }) }
  }

  selectUnit = (value) => {
    const { selectedGroup } = this.state; var unit = undefined
    if (selectedGroup === "cong_trinh_nuoc_mat") { unit = congTrinhNuocMats.filter(item => item.value === value) }
    this.setState({ selectedUnit: value, focusSlice: unit ? unit[0].name : undefined })
  }
  selectChart = (value) => { this.setState({ selectedChart: value, selectedPhase: undefined, selectedPeriod: undefined, from: undefined, to: undefined, phases: value === "thay_doi" ? phaseChanges : ((value === "ty_le") ? phases : []) }) }
  selectPhase = (value) => { this.setState({ selectedPhase: value, selectedPeriod: undefined, from: undefined, to: undefined }) }
  selectPeriod = (value) => { this.setState({ selectedPeriod: value, from: undefined, to: undefined }) }
  addRate = (data) => {
    var total = 0; data.map(item => total += (item.value ? parseInt(item.value) : 0))
    if (total > 0) data.map(item => item.id = item.label = item.label + "(" + (Math.round(parseInt(item.value) / total * 100)) + "%)")
    return data
  }

  groupDataByKey = (data) => {
    var outData = []; var valuesOfKey = []
    data.map(item => {
      if (valuesOfKey.includes(item.time)) {
        outData.filter((dataItem, index) => {
          if (dataItem.time === item.time) { outData[index][item.id] = item.value }
        })
      } else {
        if (item.time) {
          var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
          outData.push(outDataItem); valuesOfKey.push(item.time)
        }
      }
    })
    return outData
  }

  groupDataByKeyForCongTrinh = (data, congTrinh) => {
    var outData = []; var valuesOfKey = []
    data.map(item => {
      if (item.id === congTrinh) {
        if (valuesOfKey.includes(item.time)) {
          outData.filter((dataItem, index) => {
            if (dataItem.time === item.time) { outData[index][item.id] = item.value }
          })
        } else {
          if (item.time) {
            var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
            outData.push(outDataItem); valuesOfKey.push(item.time)
          }
        }
      }
    })
    return outData
  }

  validateInput() {
    const { selectedPhase, selectedPeriod, from, to, selectedFromYear, selectedToYear } = this.state
    if (!selectedPhase) { messages.warning("Bạn chưa chọn giai đoạn"); return false }
    if (selectedPhase === "theo_thoi_gian") {
      if (!selectedPeriod) { messages.warning("Bạn chưa chọn chu kỳ"); return false }
      if (selectedPeriod === 'thang') {
        if (!from) { messages.warning("Bạn chưa chọn tháng bắt đầu"); return false }
        if (!to) { messages.warning("Bạn chưa chọn tháng kết thúc"); return false }
      }
      if (selectedPeriod === 'nam') {
        if (!selectedFromYear) { messages.warning("Bạn chưa chọn năm bắt đầu"); return false }
        if (!selectedToYear) { messages.warning("Bạn chưa chọn năm kết thúc"); return false }
      }
    }
    return true;
  }

  handleInput() {
    var inputs = {
      obj: this.state.selectedObject,
      group: this.state.selectedGroup,
      unit: this.state.selectedUnit,
      chart: this.state.selectedChart,
      period: this.state.selectedPeriod,
      phase: this.state.selectedPhase,
      from: this.state.from,
      to: this.state.to
    }
    if (inputs.period === "thang") {
      inputs.from = inputs.from.startOf('month');
      inputs.to = inputs.to.endOf('month')
    }
    if (inputs.period === "nam") {
      inputs.from = this.state.selectedFromYear + "-01-01"
      inputs.to = this.state.selectedToYear + "-12-31"
      // moment(new Date()).set('year', this.state.selectedFromYear); 
    }
    return inputs
  }

  handleSubmit = () => {
    if (!this.validateInput()) return
    const params = this.handleInput();
    new Api_tnn().statistic({
      token: this.props.user.access_token,
      chart: params.chart,
      obj: params.obj,
      group: params.group,
      unit: params.unit,
      loaihinhgp: params.loaihinhgp,
      madvhc: params.madvhc,
      phase: params.phase,
      period: params.period,
      from: params.from,
      to: params.to,
    }).then((result) => {
      if (result) {
        this.setState({ data: this.state.selectedChart === "ty_le" ? this.addRate(result) : this.groupDataByKey(result) })
      } else { messages.error("Lấy dữ liệu lỗi") }
    })
    this.setState({ chartType: this.state.selectedChart })
  }

  componentDidUpdate = () => {
    if (this.state.showChartTitle) {
      const input = document.getElementById('chart-to-export');
      Helper.exportPdf(input, this.state.chartType === 'ty_le' ? 'landscape' : 'portrait', "BieuDo.pdf")
      this.setState({ showChartTitle: false })
    }
  }

  exportPdf = () => {
    const { selectedObject, selectedChart, selectedPhase, selectedPeriod, selectedFromYear, selectedToYear, from, to } = this.state
    var title = 'Biểu đồ'
    title += selectedChart === 'ty_le' ? ' tỷ lệ và tống số lượng' : (selectedChart === 'thay_doi' ? ' biến động số lượng' : '')
    title += selectedObject === 'cong_trinh' ? ' công trình' : (selectedObject === 'giay_phep' ? ' giấy phép' : '')
    title += selectedPhase === 'theo_thoi_gian' ? (' từ ' + (selectedPeriod === 'nam' ? selectedFromYear : (selectedPeriod === 'thang' ? from.format('MM/YYYY') : '')) + ' đến ' + (selectedPeriod === 'nam' ? selectedToYear : (selectedPeriod === 'thang' ? to.format('MM/YYYY') : ''))) : ''
    this.setState({ showChartTitle: true, chartTitle: title })
  }
  downFileExcel = () => {
    const { search_timkiem } = this.state
    new Api_tnn().Download_NguonNuoc({
      token: this.props.user.access_token,
      strSearch: "",
      phanloainguonnuoc: search_timkiem.loai_nguonnuoc ? search_timkiem.loai_nguonnuoc : "",
      mahuyen: search_timkiem.maHuyen,
      maxa: search_timkiem.maXa
    })
  }
  showMapModal = (idho) => {
    this.GetSpatialDiemHo(idho)
  }
  GetSpatialDiemHo = (idho) => {
    const { user } = this.props
    new Api_spatial()
      .GetSpatialDiemHo({
        token: user.access_token,
        idho: idho,
      })
      .then((res) => {
        if (res != null && res.result != null) {
          const a = JSON.parse(res.result)
          const newGeom = { newGeom: (delete a.geometry['crs'], a) };
          const Json_newGeom = { ...newGeom.newGeom }
          const b = JSON.stringify(Json_newGeom)
          var latlong = [a.geometry.coordinates[1], a.geometry.coordinates[0]]
          this.setState({
            GeoJson_data: {
              data: Json_newGeom,
              key: b
            },
            toado: latlong,
            idho: idho,
            isShowMapModal: true
          })
        } else {
          this.setState({
            GeoJson_data: {
              data: null,
              style: null,
              key: null
            }
          })
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        //message.error(err.message);
      });
  };
  render() {
    const { GeoJson_data, toado, data_thongtinlienhe, showChartTitle, data_huyen, data_xa, chartTitle, data, years_tunam, giayPhephData, chartType, focusSlice, objects, selectedObject, groups, selectedGroup, units, selectedUnit, charts,
      selectedChart, phases, selectedPhase, periods, selectedPeriod, from, to, years, selectedFromYear, selectedToYear, tongSoGiayPhepData, pagination, data_nguonnuoc, search_timkiem, isShowMapModal, idho } = this.state
    const { functions, user } = this.props

    return (
      <Layout style={{ height: "100%" }}>
        <Content className='pagewrapper-layout'>
          <Container className="container-content-dashboard">
            <ContainerTotalStatistic data={tongSoGiayPhepData} history={this.props.history} functions={functions} />
            <Row gutter={16}>
              <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}><BoxChart title='Số lượng giấy phép công trình được cấp theo năm' data={giayPhephData} history={this.props.history} functions={functions} /></Col>
            </Row>
            {Number(Constant.MA_TINH) === Constant.MaTinh.HaiPhong && Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG ?
              <Row style={{ marginTop: 10 }}>
                <div className='box-chart' >
                  <div className='box-chart-title'>
                    <Row type="flex" gutter={16}>
                      <Col>
                        DM nguồn nước
                      </Col>
                      <Col>
                        <SearchInput
                          placeholder="Tìm kiếm"
                          width={125}
                          onChange={(item) => {
                            this.setState({
                              search_timkiem: {
                                ...search_timkiem, strSearch: item.target.value
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col>
                        <Select
                          dataSource={Constant.NNNT}
                          defaultValue={search_timkiem.loai_nguonnuoc ? Number(search_timkiem.loai_nguonnuoc) : undefined}
                          onChange={(value) => {
                            this.setState({
                              search_timkiem: {
                                ...search_timkiem, loai_nguonnuoc: value
                              }
                            })
                          }}
                          placeholder={typo.cscore_tnn_input_song}
                          mandatory={false}
                          width="140px"
                          name="ten"
                          name_value="id"
                          allowClear={true}
                        />
                      </Col>
                      <Col>
                        <Select
                          mandatory={false}
                          placeholder={typo.cscore_tnn_district_placeholder}
                          onChange={(value, name) => {
                            this.setState({
                              search_timkiem: {
                                ...search_timkiem, maHuyen: value
                              }
                            })
                            this.getXaByHuyens(value)
                          }}
                          dataSource={data_huyen}
                          width="175px"
                          name="tenDVHC"
                          name_value="maHuyen"
                          value={search_timkiem.maHuyen ? search_timkiem.maHuyen : undefined}
                          allowClear={true}
                        />
                      </Col>
                      <Col>
                        <Select
                          mandatory={false}
                          placeholder={typo.cscore_tnn_ward_placeholder}
                          onChange={(value, name) => {
                            this.setState({
                              search_timkiem: {
                                ...search_timkiem, maXa: value
                              }
                            })
                          }}
                          dataSource={data_xa}
                          width="175px"
                          name="tenDVHC"
                          name_value="maXa"
                          value={search_timkiem.maXa ? search_timkiem.maXa : undefined}
                          allowClear={true}
                        />
                      </Col>
                      <Col>
                        <Button type="submit" onClick={() => this.GetDM_NguonNuocNoiTinhs(1, search_timkiem.strSearch, search_timkiem.loai_nguonnuoc, search_timkiem.maHuyen, search_timkiem.maXa)}> <Icon type="search" style={{ marginLeft: '0px' }} />Tìm kiếm</Button>
                      </Col>
                      <Col>
                        <Button type="excel" onClick={() => this.downFileExcel()} className="button-excel">
                          <Icon type="file-excel" />
                          {typo.cscore_tnn_out_excel}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {data_nguonnuoc && data_nguonnuoc.length > 0 ?
                    <div>
                      <Collapse bordered={false} expandIconPosition="left">
                        {data_nguonnuoc.map((item, index) =>
                          <Panel header={item.tenHienThi} key={item.id} className={index % 2 === 0 ? "class-row" : ""} extra={<Icon type="global" style={{ color: item.toaDo ? '#63c2de' : '#686868' }} onClick={() => { item.toaDo && this.showMapModal(item.id) }} />}>
                            <p style={{ paddingLeft: 24 }}>
                              <NguonNuoc item={item} />
                            </p>
                          </Panel>
                        )}
                      </Collapse>
                      <div style={{ float: "right" }}>
                        <Pagination
                          total={pagination.total}
                          pageSize={10}
                          current={pagination.currentPage}
                          onChange={(item) => { this.GetDM_NguonNuocNoiTinhs(item, search_timkiem.strSearch, search_timkiem.loai_nguonnuoc, search_timkiem.maHuyen, search_timkiem.maXa) }}
                          size="small"
                        />
                      </div>
                    </div>
                    :
                    <div style={{ color: "#cfcdcd", textAlign: "center" }} >Không có sữ liệu</div>}
                </div>
              </Row>
              :
              <Row type='flex' justify='start' style={{ height: "800px", width: "100%" }}>
                <Col style={{ width: "252px" }}>
                  <Collapse bordered={false} defaultActiveKey={["1"]}>
                    <Panel header={<a >Tùy chọn</a>} key="1" className='detail_collapse_item'>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_chart} mandatory={false}
                          onChange={value => this.selectChart(value)}
                          dataSource={charts} name="name" name_value="value" defaultValue={selectedChart} value={selectedChart} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_object} mandatory={false}
                          onChange={value => this.selectObject(value)}
                          dataSource={objects} name="name" name_value="value" defaultValue={selectedObject} value={selectedObject} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_group} mandatory={false}
                          onChange={value => this.selectGroup(value)}
                          dataSource={groups} name="name" name_value="value" defaultValue={selectedGroup} value={selectedGroup} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_groupUnit} mandatory={false}
                          onChange={value => this.selectUnit(value)}
                          dataSource={units} name="name" name_value="value" defaultValue={selectedUnit} value={selectedUnit} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' label={typo.cscore_tnn_phase} mandatory={false}
                          onChange={value => this.selectPhase(value)}
                          dataSource={phases} name="name" name_value="value" defaultValue={selectedPhase} value={selectedPhase} />
                      </div>
                      <div className='chart-option-select-div'>
                        <Select className='chart-option-select' disabled={selectedPhase !== "theo_thoi_gian"} label={typo.cscore_tnn_period} mandatory={false}
                          onChange={value => this.selectPeriod(value)}
                          dataSource={periods} name="name" name_value="value" defaultValue={selectedPeriod} value={selectedPeriod} />
                      </div>
                      {selectedPeriod === "thang" &&
                        <div>
                          <div className='chart-option-select-div'>
                            <DateField type='month' className='chart-option-select' label={typo.cscore_tnn_from} mandatory={false}
                              onChange={(value) => this.setState({ from: value })} defaultValue={from} value={from} />
                          </div>
                          <div className='chart-option-select-div'>
                            <DateField type='month' className='chart-option-select' label={typo.cscore_tnn_to} mandatory={false}
                              onChange={(value) => this.setState({ to: value })} defaultValue={to} value={to} />
                          </div>
                        </div>}
                      {selectedPeriod === "nam" &&
                        <div>
                          <div className='chart-option-select-div'>
                            <Select className='chart-option-select' mandatory={false} label={typo.cscore_tnn_from}
                              onChange={value => this.setState({ selectedFromYear: value })}
                              dataSource={years_tunam} name="year" name_value="year" defaultValue={selectedFromYear} value={selectedFromYear} />
                          </div>
                          <div className='chart-option-select-div'>
                            <Select className='chart-option-select' mandatory={false} label={typo.cscore_tnn_to}
                              onChange={value => this.setState({ selectedToYear: value })}
                              dataSource={years} name="year" name_value="year" defaultValue={selectedToYear} value={selectedToYear} />
                          </div>
                        </div>}
                      <Button onClick={this.handleSubmit} className='chart-btn-submit'>{typo.cscore_tnn_statistic}</Button>
                      <Button className='mg-r-10 chart-btn-submit' id='anhmd' onClick={this.exportPdf}>{typo.cscore_tnn_exportPdf}</Button>
                    </Panel>
                  </Collapse>
                </Col>
                <Col id="chart-to-export" span={16} style={{ height: "100%" }}>
                  <Row style={{ height: "60%" }}>
                    <div style={{ height: "100%" }}>{(chartType === "thay_doi") && <BarChart data={data} />}{chartType === "ty_le" && <PieChart data={data} focusSlice={focusSlice} />}</div>
                  </Row>
                  {showChartTitle && <Row style={{ height: "5%", width: "100%" }}>
                    <Row type='flex' justify='center' style={{ width: "100%" }}><span className='chart-title'>{chartTitle}</span></Row>
                    <Row type='flex' justify='center' style={{ width: "100%" }}><span>Nguồn: hệ thống quản lý cơ sở dữ liệu tài nguyên nước</span></Row>
                  </Row>}
                </Col>
              </Row>
            }
            {isShowMapModal &&
              <Modal
                centered
                closable={true}
                visible={isShowMapModal}
                width="60%"
                footer={null}
                onCancel={() => this.setState({ isShowMapModal: false })}
              >
                <MapModal
                  user={user}
                  idho={idho}
                  GeoJson_data={GeoJson_data}
                  toado={toado}
                />
              </Modal>
            }
          </Container>
        </Content>
        {Number(Constant.MA_TINH) === Constant.MaTinh.HaiPhong &&
          <Footer className='layout-footer'>
            <div>
              <div style={{ marginTop: "15px", background: "#f7f8fb", color: "#808291", textAlign: "center" }}>
                <div style={{ marginBottom: "5px", paddingTop: 10 }}>
                  <img src={defaultappicon} style={{ width: "120px", height: "120px" }} />
                </div>
                <strong>{data_thongtinlienhe.ten && data_thongtinlienhe.ten.toUpperCase()}</strong>
                <p>{"Địa chỉ: " + data_thongtinlienhe.diaChi}
                  <br />
                  {"Điện thoại: " + data_thongtinlienhe.dienThoai + " - Fax: " + data_thongtinlienhe.fax}
                  <br />
                  {"Email: " + data_thongtinlienhe.email}
                </p>
                <p>Được phát triển bởi Trung tâm Công nghệ phần mềm và GIS - Cục Công nghệ thông tin và Dữ liệu tài nguyên môi trường</p>
              </div>
            </div>
          </Footer>
        }
      </Layout>
    )
  }
};

const { Content, Footer } = Layout;
const doiTuongs = [{ name: "Công trình", value: "cong_trinh" }, { name: "Giấy phép", value: "giay_phep" }]
const congTrinhs = [{ name: "Công trình nước mặt", value: "cong_trinh_nuoc_mat" }, { name: "Công trình nước dưới đất", value: "cong_trinh_nuoc_duoi_dat" }, { name: "Công trình nước xả thải", value: "cong_trinh_nuoc_xa_thai" }, { name: "Công trình nước biển", value: "cong_trinh_nuoc_bien" }]
const giayPheps = [{ name: "Nhóm giấy phép", value: "nhom_giay_phep" }]
const quanTracs = [{ name: "Quan trắc tài nguyên nước", value: "quan_trac_tai_nguyen_nuoc" }, { name: "Quan trắc khí tượng thủy văn", value: "quan_trac_khi_tuong_thuy_van" }]
const congTrinhNuocMats = [{ name: "Thủy điện", value: "thuy_dien" }, { name: "Hồ chứa", value: "ho_chua" }, { name: "Trạm bơm", value: "tram_bom" }, { name: "Đập", value: "bai_dap" }, { name: "Cống", value: "cong" }, { name: "Trạm cấp nước", value: "tram_cap_nuoc" }, { name: "Nhà máy nước", value: "nha_may_nuoc" }]
const congTrinhNuocDuoiDats = [{ name: "Khai thác", value: "khai_thac" }, { name: "Thăm dò", value: "tham_do" }]
const congTrinhXaThais = [{ name: "Công trình nước xả thải", value: "cong_trinh_nuoc_xa_thai" }]
const congTrinhNuocBiens = [{ name: "Công trình nước biển", value: "cong_trinh_nuoc_bien" }]
const nhomGiayPheps = [{ name: "Giấy phép xả thải", value: "gp_xa_thai" }, { name: "GP khai thác nước mặt", value: "gp_khai_thac_nuoc_mat" }, { name: "GP khai thác nước biển", value: "gp_khai_thac_nuoc_bien" }, { name: "GP khai thác nước dưới đất", value: "gp_khai_thac_nuoc_duoi_dat" },
{ name: "GP thăm dò nước dưới đất", value: "gp_tham_do_nuoc_duoi_dat" }, { name: "GP hành nghề khoan nước dưới đất", value: "gp_hanh_nghe_khoan_nuoc_duoi_dat" }]
const quanTracTaiNguyenNuocs = [{ name: "Điểm quan trắc", value: "diem_quan_trac" }, { name: "Trạm quan trắc nước", value: "tram_quan_trac_nuoc" }]
const quanTracKhiTuongThuyVans = [{ name: "Trạm quan trắc", value: "tram_quan_trac" }, { name: "Kỳ quan trắc", value: "ky_quan_trac" }]
const charts = [{ name: "Thay đổi", value: "thay_doi" }, { name: "Tỷ lệ", value: "ty_le" }]
const phases = [{ name: "Theo thời gian", value: "theo_thoi_gian" }, { name: "Tất cả", value: "tat_ca" }]
const phaseChanges = [{ name: "Theo thời gian", value: "theo_thoi_gian" }]
const periods = [{ name: "Năm", value: "nam" }, { name: "Tháng", value: "thang" }]

const mapPropsToState = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});
export default connect(mapPropsToState)(Dashboard)