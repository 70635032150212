import React, { Component } from "react";
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

//component
import {Modal, Col, Row, Icon} from 'antd';
import Input from "../../../Common/Input"
import TextArea from "../../../Common/TextArea"
import Button from "../../../Buttons";
import UpFile from './upFile'

// helper
import typo from "../../../../constants/dictionaries";
import { FastField, Formik } from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({
  });

class Add extends Component {
    render(){
        const {action, handleCancel, handleSubmit, item} = this.props
        const title = action === "add" ? typo.cscore_tnn_themFileDinhkem : typo.cscore_tnn_capNhatFileDinhkem

        return (
            <Modal title={title} centered closable={true} visible={true} width="500px" footer={null} onCancel={handleCancel} autoFocus={false}>
                <Formik key={new Date()} initialValues={item} validationSchema={schema} 
                onSubmit={(values, actions) => { handleSubmit(values)}}
                    render={({ errors,handleSubmit,touched, dirty, setFieldValue}) => (
                        <form>
                            <FastField name="soTaiLieu" render={({ field }) => (<Input mandatory={false} label={<span>{typo.cscore_tnn_soTaiLieu}</span>} {...field}/>)}/>      

                            <FastField name="ghiChu" render={({ field }) => (<TextArea label={<span>{typo.cscore_tnn_ghiChu}</span>} {...field} mandatory={false}/>)}/>

                            <UpFile item={item} setFieldValue={setFieldValue} />           
                            <Row type='flex' justify="end">
                                <Col>
                                <Button style={{marginRight: '10px'}} className="btn-save-in-modal" type="primary" htmlType="submit" onClick={handleSubmit}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                                <Button type="cancel" onClick={() => handleCancel()}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
                                </Col>
                                </Row>                   
                        </form>
                    )}
                />
            </Modal>
        )
    }
}

Add.propTypes = {
    action: PropTypes.string.isRequired,
    handleCancel: PropTypes.func.isRequired,
  };
const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(Add);