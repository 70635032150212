import React from "react";
import {Typography} from 'antd'
import styled from "styled-components";

const {Title, Text} = Typography;

const SmallText = styled(Text)`
  font-size: 12px !important;
`;

export default ({children, ...rest}) => (
  <SmallText {...rest}>
    {children}
  </SmallText>
)