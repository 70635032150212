import React from "react";
import "./App.css";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga';
import createLogger from 'redux-logger';
import { loadUser, OidcProvider, LOADING_USER } from "redux-oidc";
import configureStore from "./redux/store";
import store_tnn from "./constants/store"
import moment from "moment";
import "moment/locale/vi";
// React router dom
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// Components
import LayoutAuthenticated from "./containers/LayoutAuthenticated";

// Services
import userManager from "./services/auth";

import CallBack from "./routes/callback";
import SilentRenew from "./routes/silentRenew";
import CongTrinhNuocMat from "./routes/CongTrinhNuocMat"
import CongTrinhNuocDuoiDat from "./routes/CongTrinhNuocDuoiDat"
import CongTrinhNuocBien from "./routes/CongTrinhNuocBien"
import CongTrinhXaThai from "./routes/CongTrinhXaThai"
import BieuMauTT31 from "./routes/BieuMauTT31";
import BieuMauTT20 from "./routes/BieuMauTT20";
import PhuLuc from "./routes/PhuLuc";
import License from './routes/licenses'
import Dashboard from './routes/dashboardChung'
import withAuth from "./HOC/withAuth";
import DanhMuc from "./routes/DanhMuc";
import ThongTinTaiNguyenNuoc from "./routes/ThongTinTaiNguyenNuoc";
import DanhMucQuanTrac from "./routes/DanhMucQuanTrac";
import NhatKyHeThong from "./routes/NhatKyHeThong";
import CauHinhThongSo from "./routes/CauHinhThongSo";
import DuLieuQTDK from "./routes/DuLieuQTDK";
// import TraCuuTNN from "./routes/map/index";
import TraCuuTNN from "./routes/BanDo/TraCuuTNN/map";
// import SongVaLuuVuc from "./routes/ThongTinTaiNguyenNuoc/SongVaLuuVuc";
import SongVaLuuVuc from "./routes/BanDo/SongVaLuuVuc";
import Profile from "./routes/Profile";
import ChangePassword from "./routes/ChangePassword"
import QuanTriHeThong from "./routes/QuanTriHeThong"
import BaoCaoChoDoanhNghiep from "./routes/BaoCaoDanhChoDoanhNghiep"
import Constant from "./constants/constant";
import TCDN from "./routes/TraCuuDoanhNghiep/DNXCP";
import Policy from "./routes/Policy"
import ToKhai from './routes/ToKhaiDangKy'

moment.locale("vi");
// Retry function
const retry = (fn, retriesLeft = 1, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft < 1) {
            reject("Loading chuck failure!");
          }
          retry(fn, retriesLeft - 1, interval);
        }, interval);
      });
  });
};

// Store
// const store = configureStore(window.REDUX_INITIAL_DATA);
function App() {
  const hash = window.location.hash;
  if (hash === "" || hash === null || hash === undefined) {
    userManager.getUser()
      .then((res) => {
        if (!res) {
          if (window.location.pathname !== '/policy') {
            userManager.signinRedirect();
          }
        }
        else {
          loadUser(store_tnn, userManager).then(() => {
            userManager.events.addUserSignedOut(() => userManager.signoutRedirect())
          });
        }
      });
  }
  return (
    <Provider store={store_tnn}>
      <OidcProvider store={store_tnn} userManager={userManager}>
        <Switch>
          <Route path="/callback" component={CallBack} />
          <Route path="/silentrenew" component={SilentRenew} />
          <Route path="/policy" component={Policy} />
          <LayoutAuthenticated userManager={userManager}>
            <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
            <Route path="/dashboard" component={withAuth(Dashboard)} />
            <Route path="/collections" component={withAuth(Dashboard)} />
            <Route path="/cong-trinh-nuoc-mat" component={withAuth(CongTrinhNuocMat)} />
            <Route path="/cong-trinh-nuoc-duoi-dat" component={withAuth(CongTrinhNuocDuoiDat)} />
            <Route path="/cong-trinh-xa-thai" component={withAuth(CongTrinhXaThai)} />
            <Route path="/cong-trinh-nuoc-bien" component={withAuth(CongTrinhNuocBien)} />
            <Route path="/bieu-mau-tt31" component={withAuth(BieuMauTT31)} />
            <Route path="/bieu-mau-tt20" component={withAuth(BieuMauTT20)} />
            <Route path="/phu-luc" component={withAuth(PhuLuc)} />
            <Route path="/cap-phep" component={withAuth(License)} />
            <Route path="/Danh-muc" component={withAuth(DanhMuc)} />
            <Route path="/Thong-tin-tai-nguyen-nuoc" component={withAuth(ThongTinTaiNguyenNuoc)} />
            <Route path="/Danh-muc-quan-trac" component={withAuth(DanhMucQuanTrac)} />
            <Route path="/Nhat-ky-he-thong" component={withAuth(NhatKyHeThong)} />
            <Route path="/Cau-hinh-thong-so" component={withAuth(CauHinhThongSo)} />
            <Route path="/Du-lieu-quan-trac-dinh-ky" component={withAuth(DuLieuQTDK)} />
            <Route path="/Ban-do/Tra-cuu-tai-nguyen-nuoc" component={withAuth(TraCuuTNN)} />
            <Route path="/Ban-do/Song-va-luu-vuc-song" component={withAuth(SongVaLuuVuc)} />
            <Route path="/my-profile" component={withAuth(Profile)} />
            <Route path="/change-password" component={withAuth(ChangePassword)} />
            <Route path="/quan-tri-he-thong" component={withAuth(QuanTriHeThong)} />
            <Route path="/BCDN" component={withAuth(BaoCaoChoDoanhNghiep)} />
            <Route path="/Tra-cuu-doanh-nghiep" component={withAuth(TCDN)} />
            <Route path="/to-khai" component={withAuth(ToKhai)} />

          </LayoutAuthenticated>
        </Switch>
      </OidcProvider>
    </Provider>
  );
}

// Global router
window.globalRouter = null;
export default () => (<Router basename="" ref={node => (window.globalRouter = node)}><App /></Router>)
