import React, {Component} from "react";
import {connect} from 'react-redux'

//component
import {Row, Col, message} from 'antd';
import Button from "../../../Buttons";
import ModalAdd from "../Modal/addMap";
import ViewTable from '../../../Table/ViewTable'

//helper
import columns from './columns'
import CoordinateModel from '../../../../models/coordinate'

class TablePlus extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedItem: undefined, isShowModalAdd: false, action: undefined}
  }

  showModalAdd = (item) => {
 
    this.setState({action: item ? "edit" : "add"})
    this.setState({isShowModalAdd: true, selectedItem: item})
  }

  offModalAdd = () => {
    this.setState({action: undefined})
    this.setState({isShowModalAdd: false, selectedItem: undefined})
  }

  handleSubmitAdd = (item) => {
    let items = this.convertToaDosToCoordinateModels(this.props.dataSource)
    if (item.stt) {
      items[item.stt - 1] = {...new CoordinateModel(item)}
    } else {
      items.push({...new CoordinateModel(item)})
    }
    this.props.updateDataSource(this.convertCoordinateModelsToToados(items), this.props.field)
    this.offModalAdd();
  }

  convertCoordinateModelsToToados = (ModelsToToados) => {
    if (!ModelsToToados) return undefined
    let toaDos = "";
    ModelsToToados.map((item, index) => {
      toaDos += item.key + "(" + item.x + "," + item.y + ");"
    })
    return toaDos !== "" ? toaDos.substring(0, toaDos.length - 1) : undefined
  }

  convertToaDosToCoordinateModels = (toaDos) => {
    if (!toaDos) return []
    let items = []
    if (toaDos) {
      try {
        items = toaDos.split(';').map((c, index) => ({
          ...new CoordinateModel({
            key: c.split("(")[0],
            x: c.split("(")[1].split(")")[0].split(",")[0],
            y: c.split("(")[1].split(")")[0].split(",")[1]
          }), stt: index + 1
        }))
      } catch (err) {
        message.error('Tọa độ định dạng sai')
      }

    }
    return items;
  }

  handleDelete = (stt) => {
    let items = this.convertToaDosToCoordinateModels(this.props.dataSource)
    if (stt) {
      delete items[stt - 1]
    }
    this.props.updateDataSource(this.convertCoordinateModelsToToados(items), this.props.field)
  }

  render() {
    const {isShowModalAdd, selectedItem, action} = this.state
    const {dataSource} = this.props
    return (
      <Row>
        <Row type='flex' justify="end">
          <Col>
            <Button className="btn-add-in-box" type="primary" icon="plus"
                    onClick={() => this.showModalAdd()}/>
          </Col>
        </Row>
        <ViewTable columns={columns({handleEdit: this.showModalAdd, handleDelete: this.handleDelete})}
                   dataSource={this.convertToaDosToCoordinateModels(dataSource)} bordered pagination={false}scroll={{ x: 500 }}/>
        {isShowModalAdd &&
        <ModalAdd
          action={action} item={selectedItem} handleCancel={this.offModalAdd}
          handleSubmit={this.handleSubmitAdd}/>}
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(TablePlus);