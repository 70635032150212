import { createUserManager } from "redux-oidc";
import { UserManagerSettings, UserManager } from "oidc-client";
import { API_IDENTITY_URI, APP_TNN_URI, APP_TNN_REDIRECT_URL, APP_TNN_SILENT_REDIRECT_URL } from "../config";

const userManagerConfig = {
  client_id: "cscore_tnn_spa",
  redirect_uri: APP_TNN_REDIRECT_URL,
  response_type: "token id_token",
  scope: "openid profile CSCoreApi CSCore_TNN_Api CSCore_DMS_Api CSCore_Spatial_Api CSCore_Ocelot_ApiGateway",
  authority: API_IDENTITY_URI,
  silent_redirect_uri: APP_TNN_SILENT_REDIRECT_URL,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true
};

const userManager = new UserManager(userManagerConfig);

export default userManager;
