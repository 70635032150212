import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as Yup from 'yup'
import {Row, Col, Modal, Icon} from 'antd'
import {Formik, FastField} from 'formik'
import typo from '../../../../constants/dictionaries'
import {isEmpty} from "lodash";

// Utils

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";

const schema = Yup.object().shape({
  tenDot: Yup.string().required(typo.cscore_tnn_required)
});

class TTKTModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  handleUpdateCLNuocThai = (list) => {
    this.setState({listCLNuocThai: list})
  };

  render() {
    const {isOpen, handleCancel, handleOk, initialValues, mode, user} = this.props;

    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_ttkt}
        footer={false}
        onCancel={handleCancel}
        width="50%"
        centered
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(value, actions) => {
            handleOk(value);
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty}) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <FastField
                  name="tenDot"
                  render={({field}) => <Input label={typo.cscore_tnn_tenDot} id="tenDot" {...field} mode={mode}/>}
                />
                {touched.tenDot && <ValidatedText type="danger">{errors.tenDot}</ValidatedText>}

                <FastField
                  name="tenDonViThucHien"
                  render={({field}) => <Input mandatory={false} label={typo.cscore_tnn_tenDonViThucHien} id="tenDonViThucHien" {...field}
                                              mode={mode}/>}
                />

                <FastField
                  name="tenTruongDoan"
                  render={({field}) => <Input mandatory={false} label={typo.cscore_tnn_tenTruongDoan} id="tenTruongDoan" {...field} mode={mode}/>}
                />

                <FastField
                  name="namThucHien"
                  render={({field}) => <Input mandatory={false} label={typo.cscore_tnn_namThucHien} id="namThucHien" {...field} mode={mode}/>}
                />
              </Row>
              <div className="mt-20"/>
              {mode !== "presentation" &&
              <Row type="flex" justify="end" align="middle">
                <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save"/>{typo.cscore_tnn_save}</Button>
                <div className="ml-10"/>
                <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
              </Row>}
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )}/>
      </Modal>
    )
  }
}

TTKTModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  /*Mode: add, edit, presentation*/
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(TTKTModal)


