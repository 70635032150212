import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const {Title} = Typography;

// Components
const KhaiThacList = lazy(() => import('./KhaiThac/List'));
const KhaiThacDetail = lazy(() => import('./KhaiThac/Detail'));
const KhaiThacForm = lazy(() => import('./KhaiThac/Form'));

const ThamDoList = lazy(() => import('./ThamDo/List'));
const ThamDoDetail = lazy(() => import('./ThamDo/Detail'));
const ThamDoForm = lazy(() => import('./ThamDo/Form'));

export default ({match}) => {
  const groupName = 'Công trình nước dưới đất'
  const KhaiThac = 'khai-thac'
  const ThamDo = 'tham-do'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/${KhaiThac}`} render={(props) => <KhaiThacList {...props} path={`${match.path}/${KhaiThac}`} groupName={groupName} name={typo.cscore_tnn_KhaiThac}/>}/>
        <Route path={`${match.path}/${KhaiThac}/add`} render={(props) => <KhaiThacForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_KhaiThac}/>}/>
        <Route path={`${match.path}/${KhaiThac}/:id/edit`} render={(props) => <KhaiThacForm {...props} groupName={groupName} name={typo.cscore_tnn_KhaiThac}/>}/>
        <Route path={`${match.path}/${KhaiThac}/:id/detail`} render={(props) => <KhaiThacDetail {...props} groupName={groupName} name={typo.cscore_tnn_KhaiThac}/>}/>
        
        <Route exact path={`${match.path}/${ThamDo}`} render={(props) => <ThamDoList {...props} path={`${match.path}/${ThamDo}`} groupName={groupName} name={typo.cscore_tnn_ThamDo}/>}/>
        <Route path={`${match.path}/${ThamDo}/add`} render={(props) => <ThamDoForm {...props} type="add" groupName={groupName} name={typo.cscore_tnn_ThamDo}/>}/>
        <Route path={`${match.path}/${ThamDo}/:id/edit`} render={(props) => <ThamDoForm {...props} groupName={groupName} name={typo.cscore_tnn_ThamDo}/>}/>
        <Route path={`${match.path}/${ThamDo}/:id/detail`} render={(props) => <ThamDoDetail {...props} groupName={groupName} name={typo.cscore_tnn_ThamDo}/>}/>
      </Switch>
    </Suspense>
  )
}