import React from "react";
// Components
import RowTools from "../../components/Table/RowTools";
// helper
import helper from "../../helper/helper";
const HieuLuc = {
  1 : "Có hiệu lực",
  2 : "Không hiệu lực",
};

const columns = ({ sortedInfo, deleteRow, editable } = {}) =>{
  var cl = [
    {
      // key: "Id",
      title: "#",
      dataIndex: "stt",
      // sorter: helper.sortString("stt"),
      width: "65px"
    },
    {
      // key: "",
      title: "Người dùng",
      dataIndex: "userCreate",
      // sorter: helper.sortString("userCreate"),
      defaultSortOrder: "descend",
      width: "15%"
    },
    {
      // key: "",
      title: "Thao tác",
      dataIndex: "content",
      // sorter: helper.sortString("content"),
    },
    {
      title: "Thời gian",
      dataIndex: "createDate",
      // sorter: helper.sortString("createDate"),
      render: value => helper.formatDateTimeSecond(value),
      width: "15%"
    }
  ];
  if(editable){
    cl = cl.concat([
    {
      key: "tools",
      title: "Thao tác",
      dataIndex: "tools",
      align: "center",
      width: "80px",
      render: (value, row) => (
        <div style={{paddingLeft: "20px"}}><RowTools row={row} deleteRowModal={deleteRow} iconEdit={true}/></div>
      )
    }
  ]);
}
return cl.map(col => ({
  ...col,
  sortOrder: col.dataIndex === sortedInfo.field && sortedInfo.order
}));
};

export default columns;
