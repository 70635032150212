import React from 'react'
import {Row, Col, Typography} from 'antd'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {RowTableDetail, TableDetail} from "../../Common/View/TableViewDetail";
import typo from "../../../constants/dictionaries";
import StatusGP from "../../Table/Status/StatusGP";

import helper from '../../../helper/helper'

const {Text} = Typography;

const ThongTinCT_XT = ({congTrinh, banDo}) => {
  
  const {
    tenCongTrinh,
    diaDiem,
    nguonTiepNhan,
    phuongThucXaThai,
    cheDoXaThai,
    luuLuongNuocThaiLonNhat,
    loaiHinhNuocThai,
    thuocLVS,
    thuocSong,
  } = congTrinh || {};

  return (
    <Row>
      <Col>
        <Row>
          <TableDetail>
            {banDo && <RowTableDetail title={typo.cscore_tnn_tinh_trang}><StatusGP trangthai={banDo} /></RowTableDetail>}
            {banDo && <RowTableDetail title={typo.cscore_tnn_groupUnit}>Công trình xả thải</RowTableDetail>}
            <RowTableDetail title={typo.cscore_tnn_license_construction}>{tenCongTrinh}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_diaDiem}>{diaDiem}</RowTableDetail>
            {/* <RowTableDetail title={typo.cscore_tnn_license_type_construction}>{loaiCongTrinh}</RowTableDetail> */}
            <RowTableDetail title={typo.cscore_tnn_loaiHinhNuocThai}>{loaiHinhNuocThai}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_nguonTiepNhan}>{nguonTiepNhan}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_phuongThucXaThai}>{phuongThucXaThai}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_cheDoXaThai}>{cheDoXaThai}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_luuLuongXaLonNhat}>{luuLuongNuocThaiLonNhat}</RowTableDetail>
            {/* <RowTableDetail title={typo.cscore_tnn_license_mining_location}></RowTableDetail> */}
            <RowTableDetail title={typo.cscore_tnn_song}>{thuocSong}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_thuocLuuVucSong}>{thuocLVS}</RowTableDetail>
          </TableDetail>
        </Row>
      </Col>
    </Row>
  )
};

ThongTinCT_XT.propTypes = {
  congTrinh: PropTypes.any.isRequired,
  giayPhepCT: PropTypes.any.isRequired,
  hoSoXCP: PropTypes.any.isRequired,
  diemXT: PropTypes.array.isRequired,
};

export default connect()(ThongTinCT_XT)