import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Layout } from "antd";
import { Scrollbars } from 'react-custom-scrollbars';
import ScrollDown from '../../assets/images/ScrollDown.png'
import ScrollUp from '../../assets/images/ScrollUp32px.png'
const { Content, Footer } = Layout;

const TitleWrapper = styled.div`
  background-color: #fff;
  padding: 8px 16px;
`;

class PageWrapper extends React.Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      linkIcon: ""
    };
    this.handleClickTopPosition = this.handleClickTopPosition.bind(this);
    this.handleClickBottomPosition = this.handleClickBottomPosition.bind(this);

  }
  componentDidMount() {
    const { scrollbars } = this.refs;
    let ClientHeight = scrollbars.getClientHeight()
    let ScrollHeight = scrollbars.getScrollHeight()
    if (ScrollHeight > ClientHeight && ScrollHeight - ClientHeight > 100) {
      this.setState({ linkIcon: "end" })
    }
  }

  handleClickTopPosition() {
    const { scrollbars } = this.refs;
    scrollbars.scrollTop();
    this.setState({ linkIcon: "end" })
  }
  handleClickBottomPosition() {
    const { scrollbars } = this.refs;
    scrollbars.scrollToBottom();
    this.setState({ linkIcon: "start" })
  }
  onScroll = () => {
    const { scrollbars } = this.refs;
    let ClientHeight = scrollbars.getClientHeight()
    let ScrollHeight = scrollbars.getScrollHeight()
    if (ScrollHeight > ClientHeight && ScrollHeight - ClientHeight > 100) {
      const scroll = scrollbars.getValues();
      if (scroll.top > 0.5) {
        this.setState({ linkIcon: "start" })
      } else if (scroll.top < 0.5) {
        this.setState({ linkIcon: "end" })
      }
    }
  }
  render() {
    const { title, children } = this.props
    return (
      <Layout className='pagewrapper-layout'>
        <div className='pagewrapper-layout-container'>
          <TitleWrapper className='layout-container-title'>
            <div>{title}</div>
          </TitleWrapper>
          <Scrollbars ref="scrollbars" style={{ height: window.innerHeight - 124 }} autoHeightMax={window.innerHeight} autoHide autoHideTimeout={1000} autoHideDuration={200} onScrollStop={() => this.onScroll()}>
            <Content className='layout-container-content'>{children}</Content>
          </Scrollbars>
          {this.state.linkIcon === "end" &&
            <div style={{ position: "fixed", bottom: "100px", right: "30px" }}>
              <div onClick={this.handleClickBottomPosition} >
                <img className='css' src={ScrollDown} />
              </div>
            </div>}
          {this.state.linkIcon === "start" &&
            <div style={{ position: "fixed", bottom: "100px", right: "30px" }}>
              <div onClick={this.handleClickTopPosition} >
                <img className='css' src={ScrollUp} />
              </div>
            </div>}
        </div>
        <Footer className='layout-footer'>
         Phát triển bởi Trung tâm CNPM & GIS, Cục CNTT & Dữ liệu TNMT
      </Footer>
      </Layout>
    );
  }
};

PageWrapper.protoTypes = {
  title: PropTypes.string.isRequired
};

export default PageWrapper;
