import React, {Component} from "react";
import {connect} from 'react-redux'

class ItemInfoLVS extends Component {
  render() {
    const {item} = this.props
    return (
      item &&
      <table className='detail-table'>
        <tbody className='detail-tbody'>
        <tr>
          <th className='detail-th'>Tên lưu vực sông</th>
          <td className='detail-td'>{item}</td>
        </tr>
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(ItemInfoLVS);