import React from "react";
import { Table } from "antd";
import { MESSAGE } from "../../constants/dictionaries";

export default ({ pagination,selectedRowId,hideTotal,hideSize, ...props }) => (
  <div style={{ paddingBottom: 20 }}>
    <Table
      rowClassName={ (record, index) => record.id ? (selectedRowId && record.id && record.id === selectedRowId) ? "selected-row" : index%2 === 0 ? "class-row" : "": (selectedRowId && record.key && record.key === selectedRowId) ? "selected-row" : index%2 === 0 ? "class-row" : ""}
      pagination={
        pagination === false
          ? false
          : { ...pagination, showQuickJumper: false, size: "small", showSizeChanger: hideSize ? false : true, pageSizeOptions: ['10', '20', '50', '100'] }
      }
      locale={{ emptyText: MESSAGE.cscore_tnn_THERE_IS_NO_DATA }}
      bordered={true}
      size="middle"
      {...props}
    />
    {pagination && (
      <div
        style={{
          marginTop: -20,
          position: "relative",
          float: "left",
          width: "45%"
        }}
      >
        {hideTotal && 
          showTotal(pagination)
        }
      </div>
    )}
  </div>
);

const showTotal = ({ total, pageSize, current }) => {
  if (total === 0) {
    return "";
  }
  let strTotal = "Bản ghi từ ";
  const x1 = (current - 1) * pageSize + 1;
  let x2 = current * pageSize;
  const y = total;
  if (x2 > y) {
    x2 = y;
  }
  if (y) {
    strTotal = strTotal + `${x1} tới ${x2} của ${y}`;
  } else {
    return false;
  }
  return strTotal;
};
