import React from "react";
// Components
import RowTools from "../../../../components/Table/RowTools";


const columns = ({ showFileModal } = {}) =>
  [
    {
      // key: "Id",
      title: "#",
      dataIndex: "stt",
      width: "40px"
    },
    {
      // key: "",
      title: "Tên công trình",
      dataIndex: "tencongtrinh",
      render: value => <span className="row-name">{value}</span>
    },
    {
      key: "tools",
      title: "",
      dataIndex: "tools",
      align: "center",
      width: "40px",
      render: (value, row) => (
        <RowTools row={row} iconDelete={true} iconEdit={true} iconFile={true} showFile={showFileModal}/>
      )
    }
  ].map(col => ({
    ...col,
  }));

export default columns;
