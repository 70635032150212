//common
import React from "react";
import helper from "../../../../helper/helper";
import RowTools from "../../../../components/Table/RowTools";
const columns = ({showFile, downFile} = {}) =>
  [
    {
      title: "#",
      dataIndex: "stt",
      width: "45px",
      align: 'center'
    },
    {
      title: "Tài liệu",
      dataIndex: "tenTaiLieu",
      render: (value, row) => (<a onClick={() => showFile(row)}>{row.tenTaiLieu}</a>),
    },
    {
      title: "Số tài liệu",
      dataIndex: "soTaiLieu",
      width: "100px",
    },
    {
      title: "Ngày ký",
      dataIndex: "ngayKy",
      width: "100px",
      render: (value, row) => (helper.formatTableDate(row.ngayKy))
    },
    {
      key: "tools",
      title: "",
      align: "center",
      dataIndex: "id",
      width: "50px",
      render: (value, row) => (
        <div>
          <RowTools
            row={row}
            iconDelete={true}
            iconEdit={true}
            iconDownload={true}
            downloadFile={() => downFile(row)}
          />
        </div>
      ),
      isNotExportExcel: true
    }
  ].map(col => ({ ...col}));

export default columns;
