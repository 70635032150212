import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import Constant, { functionsCode, groupFunctionsCode } from '../../constants/constant'
import Message from "../../components/Messages";
import typo, { MESSAGE } from "../../constants/dictionaries";
import Helper from '../../helper/helper';


class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [],
      color: [],
    }
  }
  componentDidUpdate = (prevProps) => {
    const { data } = this.props
    if (prevProps.data !== data) {
      let keys = this.getKeys(data)
      let color = this.getColors(data)
      this.setState({
        keys: keys,
        color: color,
      })
    }
  }
  getKeys(data) {
    var keys = []
    if (data && data[0]) {
      Constant.Color_das.map((vl, idx) => {
        keys.push(vl.label)
      })
    }
    // Object.keys(data[0]).map(key => { if (key !== 'time') keys.push(key) })
    return keys
  }
  getColors(data) {
    var colors = []
    if (data && data[0]) {
      Constant.Color_das.map((vl, idx) => {
        let dulieu = data.filter(x => x[vl.label] !== 0)
        if (!Helper.isNullOrUndefined(dulieu)) {
          colors.push(vl.color)
        }
      })
    }
    return colors
  }
  ClickTable = (id, indexValue, capthamquyen) => {
    if (capthamquyen === Constant.Select_Cap[1].id || capthamquyen === Constant.Select_Cap[2].id) {
      var times = indexValue + "-12-31"
      const { functions, MADVHC } = this.props
      const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
      switch (id) {
        case Constant.TEN_GP.GPCTKTNDD:
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case Constant.TEN_GP.GPCTNM:

          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case Constant.TEN_GP.GPCTNXT:
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
            this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case Constant.TEN_GP.GPCTTDNDD:
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case "Xả nước thải vào nguồn nước còn hiệu lực":


          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
            this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=1&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case Constant.TEN_GP.GPCTNB:
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case Constant.TEN_GP.GPHNKNDD:
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
            this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Xả nước thải vào nguồn nước hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
            this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case "Xả nước thải vào nguồn nước chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
            this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case "Xả nước thải vào nguồn nước sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
            this.props.history.push(`/cap-phep/xa-thai?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        // GP khai thác nước mặt
        case "Khai thác, sử dụng nước mặt còn hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case "Khai thác, sử dụng nước mặt chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;

        case "Khai thác, sử dụng nước mặt hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước mặt sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        //GP khai thác nước biển
        case "Khai thác, sử dụng nước biển còn hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước biển chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước biển hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước biển sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        // GP khai thác nước dưới đất
        case "Khai thác, sử dụng nước dưới đất còn hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước dưới đất chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước dưới đất hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[1].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Khai thác, sử dụng nước dưới đất sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
            this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        // GP công trình thăm dò nước dưới đất
        case "Thăm dò nước dưới đất còn hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Thăm dò nước dưới đất chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Thăm dò nước dưới đất hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Thăm dò nước dưới đất sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_BO[3].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        // Biểu đồ thay đổi GP hành nghề khoan nước dưới đất

        case "Hành nghề khoan nước dưới đất còn hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Hành nghề khoan nước dưới đất chưa phê duyệt":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Hành nghề khoan nước dưới đất hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[0].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
        case "Hành nghề khoan nước dưới đất sắp hết hiệu lực":
          groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
            this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?Capthamquyen=${capthamquyen}&NangCao=true&PageNumber=1&PageSize=10&HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_HN[2].id}&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MADVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[1].id}&Sort=${Constant.Sort[1].id}`)
            :
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP);
          break;
      }
    }
  }

  render() {
    const { data, hiddenLegends, capthamquyen } = this.props
    const { keys, color } = this.state
    return (

      <ResponsiveBar
        //  onClick={(e) => console.log("ten", e)}
        onClick={(e) => this.ClickTable(e.id, e.indexValue, capthamquyen)}
        data={data}
        keys={keys}
        indexBy="time"
        margin={{ top: 50, right: hiddenLegends ? 10 : 270, bottom: 50, left: 60 }}
        padding={0.3}
        colors={color}
        // colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Thời gian',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Số lượng',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#000"
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 220,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 210,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />

    )
  }
};
const mapPropsToState = (state) => ({ user: state.oidc.user });
export default connect(mapPropsToState)(BarChart)