import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from 'react-redux'
// Antd
import { Typography, Row, Col, Icon, Tooltip } from "antd";

// Component
import Button from "../../../../components/Buttons";
import Input from "../../../../components/Common/Input";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import message from "../../../../components/Messages"
import Select from "../../../../components/Common/Select";
import helper from "../../../../helper/helper"
import constant from "../../../../constants/constant"
import NumberField from "../../../../components/Common/NumberField";
import NguonNuocBaoVeModels from "../../../../models/NguonNuocBaoVe.js"
import ChieuDaiNguonNuoc from "../../../../models/ChieuDaiNguonNuoc"

// Colors
import colors from "../../../../constants/colors";

import styles from "./Modal.module.scss";
import Api_tnn from "../../../../api/api.tnn";
import Api from "../../../../api";

const { Title, Text } = Typography;

const ControllerForm = styled.div`
  border-radius: 5px;
  background: ${colors.white};
`;
const ControllerInput = styled.div`
  padding: 10px 0 10px 0;
`;
const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;
class NguonNuocCanBaoVe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_huyen: undefined,
            data_xa: [],
            xa: undefined,
            NguonNuoc: [{
                huyen: undefined,
                tenhuyen: undefined,
                xa: undefined,
                tenxa: [],
            }],
        };
    }

    componentDidMount() {
        const { isEdit, selectedRow } = this.props
        this.getApiHuyenByTinhs(constant.MA_TINH)
        if (isEdit) {
            this.getApiXaByHuyens(selectedRow.maHuyen)
            this.setState({
                NguonNuoc: [{
                    huyen: selectedRow.maHuyen,
                    tenhuyen: selectedRow.tenHuyen,
                    xa: selectedRow.maXa,
                    tenxa: selectedRow.tenXa.split(","),
                }],
            })
        }

    }

    getApiTinhs = () => {
        new Api()
            .getTinhs({ token: this.props.user.access_token, })
            .then(res => { this.setState({ data_tinh: res }); })
            .catch(err => {
                message.error(err.message)
            });
    };
    getApiHuyenByTinhs = (maTinh) => {
        new Api()
            .getHuyenByTinhs({
                token: this.props.user.access_token,
                maTinh: maTinh
            })
            .then(res => { this.setState({ data_huyen: res }) })
            .catch(err => {
                message.error(err.message)
            });
    };
    getApiXaByHuyens = (mahuyen) => {
        new Api()
            .getXaByHuyens({
                token: this.props.user.access_token,
                maHuyen: mahuyen
            })
            .then(res => {
                var dataXas = this.state.data_xa
                dataXas[0] = res
                this.setState({
                    data_xa: dataXas
                })
            })
            .catch(err => {
                message.error(err.message)
            });
    };

    addMoniParamInput = () => {
        this.setState({
            NguonNuoc: [...this.state.NguonNuoc, {
                huyen: undefined,
                tenhuyen: undefined,
                xa: undefined,
                tenxa: [],
            }],
        });
    }
    handleSubmit = (e) => { e.preventDefault() }

    handleremove(index) {
        this.state.NguonNuoc.splice(index, 1)
        this.state.data_xa.splice(index, 1)
        this.setState({
            NguonNuoc: this.state.NguonNuoc,
            data_xa: this.state.data_xa
        })
    }
    onSubmit = (e) => {
        const { NguonNuoc } = this.state
        const { handleAdd, isEdit, selectedRow, IdNguonNuoc } = this.props
        let dataNguonNuoc = []
        if (NguonNuoc) {
            NguonNuoc.map((item, index) => {
                var CDNguonNuoc = { ...new ChieuDaiNguonNuoc() }
                if (isEdit) {
                    CDNguonNuoc.stt = selectedRow.stt
                }
                if (item.xa) {
                    item.xa.map((itemXa, idx) => {

                        var NguonNuocBaoVe2 = { ...new NguonNuocBaoVeModels() }
                        NguonNuocBaoVe2.idNguonNuoc = IdNguonNuoc
                        NguonNuocBaoVe2.tenHuyen = item.tenhuyen
                        NguonNuocBaoVe2.tenXa = item.tenxa[idx]
                        NguonNuocBaoVe2.maTinh = constant.MA_TINH
                        NguonNuocBaoVe2.maHuyen = item.huyen
                        NguonNuocBaoVe2.maXa = itemXa
                        dataNguonNuoc.push(NguonNuocBaoVe2)
                    })
                }
            })
        }
        handleAdd(dataNguonNuoc)
    }
    onChange = (idx, value, inputname, name) => {
        var items = this.state.NguonNuoc
        var item = items[idx];

        if (inputname === "huyen") {
            item.huyen = value
            item.tenhuyen = name
            new Api()
                .getXaByHuyens({
                    token: this.props.user.access_token,
                    maHuyen: value
                })
                .then(res => {
                    var dataXas = this.state.data_xa
                    dataXas[idx] = res
                    this.setState({
                        data_xa: dataXas
                    })
                })
                .catch(err => {
                    message.error(err.message)
                });
        }
        if (inputname === "xa") {
            item.xa = value
            var tenXa = []
            name.map((item, index) => {
                tenXa.push(item.props.children)
            })
            item.tenxa = tenXa
        }
        items[idx] = item;
        this.setState({
            NguonNuoc: items,
        })
    }
    render() {
        const {
            handleCloseModal,
            selectedRow,
            isEdit
        } = this.props;
        const { NguonNuoc, data_huyen, data_xa } = this.state
        return (
            <ControllerForm>
                <Row>
                    {!isEdit &&
                        <Col span={1} className={styles.colStyle}>
                            <Title className={styles.titleStyle} >{typo.cscore_tnn_stt}</Title>
                        </Col>}
                    <Col span={8} className={isEdit ? undefined : styles.colStyle}>
                        <Title className={styles.titleStyle}>{typo.cscore_tnn_district}</Title>
                    </Col>
                    <Col span={13} className={isEdit ? undefined : styles.colStyle}>
                        <Title className={styles.titleStyle}>{typo.cscore_tnn_ward}</Title>
                    </Col>
                    {!isEdit &&
                        <Col span={2} className={styles.colStyle}>
                            <Icon type="plus-circle" className={styles.iconAddStyle} onClick={this.addMoniParamInput} />
                        </Col>}
                </Row>
                {
                    NguonNuoc.map((values, idx) => {
                        return (
                            <ControllerInput key={idx}>
                                <Row>
                                    {!isEdit &&
                                        <Col span={1} className={styles.colStyle}>
                                            <Text strong>{`${idx + 1}`}</Text>
                                        </Col>}
                                    <Col span={8} className={isEdit ? undefined : styles.colStyle}>
                                        <Select
                                            mandatory={false}
                                            placeholder={helper.genSelectPL(typo.cscore_tnn_district_placeholder)}
                                            onChange={(value, name) => this.onChange(idx, value, "huyen", name.props.children)}
                                            dataSource={data_huyen}
                                            width="80%"
                                            name="tenDVHC"
                                            name_value="maHuyen"
                                            value={values.huyen}
                                        />
                                    </Col>
                                    <Col span={isEdit ? 16 : 13} className={isEdit ? undefined : styles.colStyle}>
                                        <Select
                                            mandatory={false}
                                            modeSelect="multiple"
                                            placeholder={helper.genSelectPL(typo.cscore_tnn_ward_placeholder)}
                                            onChange={(value, name) => this.onChange(idx, value, "xa", name)}
                                            dataSource={data_xa ? data_xa[idx] : undefined}
                                            width="95%"
                                            name="tenDVHC"
                                            name_value="maXa"
                                            value={values.xa}
                                        />
                                    </Col>
                                    {!isEdit &&
                                        <Col span={2}>
                                            <Icon type="close-circle" className={styles.iconDeleteStyle} onClick={() => this.handleremove(idx)} />
                                        </Col>}
                                </Row>
                            </ControllerInput>
                        )
                    })
                }

                <ControllerButton>
                    <Button
                        type="primary"
                        onClick={this.onSubmit.bind(this)}
                    >
                        <Icon type="save" style={{ marginRight: "8px" }} />
                        {typo.cscore_tnn_submit}
                    </Button>
                    <Button
                        type="cancel"
                        className={styles.buttonMargin}
                        onClick={handleCloseModal}
                    >
                        <Icon type="arrow-right" />
                        {typo.cscore_tnn_exit}
                    </Button>
                </ControllerButton>
            </ControllerForm>
        );
    }
}


NguonNuocCanBaoVe.propTypes = {
    handleAdd: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    isEdit: PropTypes.bool
};
const mapStateToProps = (state) => ({
    user: state.oidc.user
});
export default connect(mapStateToProps)(NguonNuocCanBaoVe)
// export default NguonNuocCanBaoVe;
