import React from "react";
import {Tag} from 'antd'
import typo from "../../../constants/dictionaries";
import constant from "../../../constants/constant";
const LoaiGP = ({ NhomCongTrinh } = {}) => {
  if (NhomCongTrinh === constant.DICT_NHOM_CT.XT) {
    return <Tag color="green">{`GP ${typo.cscore_tnn_nuoc_xa_thai}`}</Tag>
  } else if (NhomCongTrinh === constant.DICT_NHOM_CT.NM) {
    return <Tag color="green">{`GP ${typo.cscore_tnn_drawer_dlqt_nm}`}</Tag>
  } else if (NhomCongTrinh === constant.DICT_NHOM_CT.NB) {
    return <Tag color="green">{`GP ${typo.cscore_tnn_nuocbien}`}</Tag>
  } else if (NhomCongTrinh === constant.DICT_NHOM_CT.NDD) {
    return <Tag color="green">{`GP ${typo.cscore_tnn_nuoc_duoi_dat}`}</Tag>
  } else {
    return <div></div>
  }
};

export default LoaiGP;
