import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Checkbox, Input } from 'antd'
import { connect } from 'react-redux'

// Components
import Api_tnn from "../../../api/api.tnn";
import Message from "../../Messages";
import Table from "../../Table";
import InputNumber from "../../Common/NumberField"
import Constant from '../../../constants/constant'
import Select from "../../Common/Select";
import message from "../../../components/Messages";
// import Input from "../../Common/Input"

// Columns
const columnDetail = ({ } = {}) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "65px",
  },
  {
    title: "Quan trắc tự động",
    dataIndex: "qT_TuDong",
    width: 100,
    render: (value, row) =>
      <div style={{ textAlign: "center" }}>
        <Checkbox defaultChecked={row.qT_TuDong} disabled={true} />
      </div>
  },
  {
    title: "Thông số",
    dataIndex: "tenThongSo",
  },
  {
    title: "Đơn vị",
    dataIndex: "tenDonVi",
    width: 150,
  },
  {
    title: "Gía trị giới hạn nhỏ nhất",
    dataIndex: "gioiHanMin",
    width: 120,
    render: (value, row) =>
      <div>{value ? value : 0}</div>
  },
  {
    title: "Gía trị giới hạn lớn nhất",
    dataIndex: "gioiHanMax",
    width: 120,
    render: (value, row) =>
      <div>{value ? value : 0}</div>
  },
]
const column = ({ dataDVD } = {}) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "65px",
  },
  {
    title: "Quan trắc tự động",
    dataIndex: "qT_TuDong",
    width: 100,
    render: (value, row) =>
      <div style={{ textAlign: "center" }}>
        <Checkbox defaultChecked={row.qT_TuDong} onChange={value => { row.qT_TuDong = value.target.checked }} />
      </div>
  },
  {
    title: "Thông số",
    dataIndex: "tenThongSo",
    render: (value, row) =>
      <div>
        {row.tenThongSo === "" ?
          <Input
            mandatory={false}
            width="100%"
            defaultValue={row.tenThongSo}
            onChange={value => {row.tenThongSo = value.target.value; row.kyHieuThongSo = value.target.value}}
            style={{ border: "#fff" }}
          /> : <div>{row.tenThongSo}</div>}
      </div>
  },
  {
    title: "Đơn vị đo",
    dataIndex: "tenDonVi",
    width: 150,
    render: (value, row,) =>
      <div>
        <Select
          mandatory={false}
          onChange={(value, name) => { row.idDonVi = value; row.tenDonVi = name.props.children }}
          dataSource={dataDVD}
          width="100%"
          name="ten"
          name_value="id"
          defaultValue={value ? value : undefined}
        />
      </div>
  },
  {
    title: "Giá trị giới hạn nhỏ nhất",
    dataIndex: "gioiHanMin",
    width: 120,
    render: (value, row) =>
      <div>
        <InputNumber
          mandatory={false}
          width="100%"
          defaultValue={row.gioiHanMin ? row.gioiHanMin : 0}
          onChange={(value) => row.gioiHanMin = value}
        />
      </div>
  },
  {
    title: "Giá trị giới hạn lớn nhất",
    dataIndex: "gioiHanMax",
    width: 120,
    render: (value, row) =>
      <div>
        <InputNumber
          mandatory={false}
          width="100%"
          defaultValue={row.gioiHanMax ? row.gioiHanMax : 0}
          onChange={(value) => row.gioiHanMax = value}
        />
      </div>,
  },
]

class GioiHanThongSo extends React.Component {
  constructor() {
    super();
    this.state = {
      dataSource: [],
      dataDVD: [],
      key: 0
    }
  }

  componentDidMount() {
    this.get_CauHinhThongSo();
    this.GetApiQT_DonViDos();
  }


  getDataSource = () => {
    return this.state.dataSource
  };
  GetApiQT_DonViDos = () => {
    new Api_tnn()
      .GetQT_DonViDos({
        token: this.props.user.access_token,
        page: 0,
        size: 0
      })
      .then(res => {
        this.setState({
          dataDVD: res.data
        });
      }).catch(err => {
        message.error(err.message)
      });
  };


  get_CauHinhThongSo = () => {
    const { user, quyChuanApDung } = this.props;
    if (quyChuanApDung) {
      new Api_tnn().get_CauHinhThongSo_Init({
        token: user.access_token,
        id_qcvn: quyChuanApDung
      })
        .then(res => {
          this.setState({ dataSource: res.list_gioihan_thongso && res.list_gioihan_thongso.map((item, index) => ({ ...item, stt: index + 1 })) })
        })
        .catch(err => Message.error(err.message))
    } else {
      this.setState({ dataSource: [] })
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValue, quyChuanApDung, mode, dataCT } = this.props;
    const { dataSource } = this.state
    if (prevProps.initialValue !== initialValue && initialValue && initialValue.length > 0) {
      {
        mode === "presentation" ?
          this.setState({ dataSource: initialValue.filter(value => value.tenThongSo !== "").map((item, index) => ({ ...item, stt: index + 1 })) })
          :
          this.setState({ dataSource: initialValue.map((item, index) => ({ ...item, stt: index + 1 })) })
      }
    }
    else if (prevProps.quyChuanApDung !== quyChuanApDung) {
      this.get_CauHinhThongSo()
    }
    if (prevProps.dataCT !== dataCT) {
      if (dataCT.data.nhomCongTrinh === Constant.DICT_NHOM_CT.NM && dataCT.data.loaiCongTrinh === Constant.DICT_LOAI_CT.NM_THUYDIEN) {
        if (dataCT.data.luuLuongXaDongChayToiThieu || dataCT.data.luuLuongLonNhatQuaNMTD) {
          this.setState({
            dataSource: dataSource.map((e, idx) => ({
              ...e,
              gioiHanMin: e.idThongSo === 154 ? dataCT.data.luuLuongXaDongChayToiThieu ? dataCT.data.luuLuongXaDongChayToiThieu : e.gioiHanMin : e.gioiHanMin,
              gioiHanMax: e.idThongSo === 155 ? dataCT.data.luuLuongLonNhatQuaNMTD ? dataCT.data.luuLuongLonNhatQuaNMTD : e.gioiHanMax : e.gioiHanMax,
            })),
            key: Math.random()
          })
        }
      }

      else if (dataCT.data.nhomCongTrinh === Constant.DICT_NHOM_CT.NM && dataCT.data.loaiCongTrinh === Constant.DICT_LOAI_CT.NM_HOCHUA)
        if (dataCT.data.luuLuongXaDongChayMin) {
          this.setState({
            dataSource: dataSource.map((e, idx) => ({
              ...e, gioiHanMin: e.idThongSo === 154 ? dataCT.data.luuLuongXaDongChayMin : e.gioiHanMin
            })),
            key: Math.random()
          })
        }

    }

  }

  render() {
    const { mode } = this.props;
    const { dataSource, dataDVD, key } = this.state;
    return (
      <Row>
        <Table
          columns={mode !== "presentation" ? column({ dataDVD }) : columnDetail({})}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: 400 }}
          key={key}
        />
      </Row>
    )
  }
}

GioiHanThongSo.propTypes = {
  initialValue: PropTypes.object,
  mode: PropTypes.string.isRequired,
  quyChuanApDung: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(GioiHanThongSo)
