
import React from "react";
import L from 'leaflet'
import { Row, Icon, Popover, Col } from "antd"
import styled from "styled-components";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
const ControllerChuThich = styled.div`
  position: absolute;
  right: 10px;
  top: -50px;
  background-color: #fff0;
  z-index: 1000
`;
const ControllerGhiChu = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  smargin-left: 24px;
`;
const ChuThich_Custom = ({ }) => {
    const divRef = React.useRef(null);
    React.useEffect(() => {
        L.DomEvent.disableClickPropagation(divRef.current);
    });
    const content = (
        <div style={{ width: 300 }}>
            <Row>
                <Col span={6}>
                    <ControllerGhiChu style={{ background: "#54b04a", marginBottom: "10px" }} />
                </Col>
                <Col style={{ fontWeight: 600 }}>{typo.cscore_tnn_drawer_ctnm}</Col>
            </Row>
            <Row>
                <Col span={6}>
                    <ControllerGhiChu style={{ background: "#faff39", marginBottom: "10px", }} />
                </Col>
                <Col style={{ fontWeight: 600 }}>{typo.cscore_tnn_diem_xa_nươc_thai}</Col>
            </Row>
            <Row>
                <Col span={6}>
                    <ControllerGhiChu style={{ background: "#ac5b31" }} />
                </Col>
                <Col style={{ fontWeight: 600 }}>{typo.cscore_tnn_gieng_kt_ndd}</Col>
            </Row>
        </div>
    );
    return (
        <div ref={divRef}>
            <ControllerChuThich >
                <Popover content={content} title="Chú thích" trigger="hover" placement="left">
                    <div className="leaflet-control-layers leaflet-control">
                        <a className="leaflet-control-layers-toggle" href="#" style={{ backgroundImage: "none" }}>
                            <Icon type="question" style={{ fontSize: "30px", padding: "8px", color: "#808080" }} />
                        </a>
                    </div>
                </Popover>
            </ControllerChuThich>
        </div>
    );
}
export default ChuThich_Custom