import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'
import typo, { MESSAGE } from "../../constants/dictionaries";
import { groupFunctionsCode, functionsCode } from "../../constants/constant";
const {Title} = Typography;

// Components
const DuLieuQTDK = lazy(() => import('./DuLieuQuanTrac'));
export default ({match}) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/Nuoc-mat`} render={(props)=> <DuLieuQTDK {...props} phanquyen={functionsCode.DLQT_NM} doiTuongQT={1} Tram={2}  title={typo.cscore_tnn_quan_ly_du_lieu_quan_trac_nm} />}/>
        <Route exact path={`${match.path}/Nuoc-duoi-dat`} render={(props)=> <DuLieuQTDK {...props} phanquyen={functionsCode.DLQT_NDD} doiTuongQT={2} Tram={2}  title={typo.cscore_tnn_quan_ly_du_lieu_quan_trac_ndd} />}/>
        <Route exact path={`${match.path}/Nuoc-xa-thai`} render={(props)=> <DuLieuQTDK {...props} phanquyen={functionsCode.DLQT_XT} doiTuongQT={3} Tram={2}   title={typo.cscore_tnn_quan_ly_du_lieu_quan_trac_xt} />}/>
        <Route exact path={`${match.path}/Khi-tuong`} render={(props)=> <DuLieuQTDK {...props} phanquyen={functionsCode.DLQT_KT} doiTuongQT={5} Tram={1}  title={typo.cscore_tnn_quan_ly_du_lieu_quan_trac_kt} />}/>
        <Route exact path={`${match.path}/Thuy-van`} render={(props)=> <DuLieuQTDK {...props} phanquyen={functionsCode.DLQT_TV} doiTuongQT={6} Tram={1}  title={typo.cscore_tnn_quan_ly_du_lieu_quan_trac_tv} />}/>
      </Switch>
    </Suspense>
  )
}