import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON, Popup, Tooltip } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import icon_XT from '../../../assets/images/XT-24.png';
import icon_NDD from '../../../assets/images/NDD-24.png';
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import constants from "../../../constants/constant"
const XT_Icon = new L.Icon({
  iconUrl: icon_XT,
  className: 'custom-icon',
  popupAnchor: [1, -34]
})
const NDD_Icon = new L.Icon({
  iconUrl: icon_NDD,
  className: 'custom-icon',
  popupAnchor: [1, -34]
})
class GeoJson_Layer extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: [],
      tenCongTrinh: "",
    }

  }



  componentDidMount() {
    const { GeoJson_data, tenCongTrinh} = this.props
    this.setState({
      GeoJson_data: GeoJson_data,
      tenCongTrinh: tenCongTrinh,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  getApiCongTrinhSpatial = (idcongtrinh, loaicongtrinh) => {
    const { zoomOut } = this.props
    new Api_spatial()
      .GetSpatialCTByGP({
        token: this.props.user.access_token,
        idcongtrinh: idcongtrinh,
        loaicongtrinh: loaicongtrinh,
      })
      .then((res) => {
        if (res != null && res.lst_spatial !== null && res.lst_spatial.length > 0) {
          let data = []
          res.lst_spatial.map((item, index) => {
            const a = JSON.parse(item.result)
            const newGeom = { newGeom: (delete a.geometry['crs'], a) };
            const Json_newGeom = { ...newGeom.newGeom }
            const b = JSON.stringify(Json_newGeom)
            data = [...data, {
              data: Json_newGeom,
              key: b
            }]
          })
          this.setState({
            GeoJson_data: data
          })
          var latlong = [data[0].data.geometry.coordinates[1], data[0].data.geometry.coordinates[0]]
          zoomOut(latlong)
          if (loaicongtrinh === constants.DICT_LOAI_CT.XT) {
            if (data && data.length > 0) {
              this.setState({
                tenCongTrinh: data[0].data.properties.tencongtrinh
              })
            }
          }
        } else {
          this.setState({
            GeoJson_data: {
              data: null,
              style: null,
              key: null
            }
          })
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        //message.error(err.message);
      });
  };

  render() {
    const { GeoJson_data, tenCongTrinh } = this.state
    const { viewToado, loaicongtrinh } = this.props
    return (
      <div>
        {GeoJson_data && GeoJson_data.length > 0 && loaicongtrinh === constants.DICT_LOAI_CT.XT &&
          <div>
            <div className="leaflet-mini-map-congtrinh">{"Công trình: " + tenCongTrinh}</div>
            {GeoJson_data.map((item, index) => (
              item.data != null &&
              <GeoJSON ref="geojson" key={1} data={item.data} pointToLayer={(geoJsonPoint, latlng) => { return L.marker(latlng, { icon: XT_Icon }) }}>
                <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                  <b>{"Điểm xả: "}</b> {viewToado.filter(x => x.id === item.data.properties.iddiemxathai).length > 0 ? viewToado.filter(x => x.id === item.data.properties.iddiemxathai)[0].soHieu : ""}
                  <Popup><b>{"Công trình: "}</b>{item.data && item.data.properties && item.data.properties.tencongtrinh}
                    <br />
                    <b>{"Địa điểm: "}</b> {viewToado.filter(x => x.id === item.data.properties.iddiemxathai).length > 0 ? viewToado.filter(x => x.id === item.data.properties.iddiemxathai)[0].diaDiem : ""}
                    <br />
                    <b>{"Lưu lượng nước thải (m3/ngày đêm): "}</b> {viewToado.filter(x => x.id === item.data.properties.iddiemxathai).length > 0 ? viewToado.filter(x => x.id === item.data.properties.iddiemxathai)[0].luuLuongNuocThai : ""}
                    <br />
                    <b>{"Tọa độ (x, y): "}</b>{viewToado.filter(x => x.id === item.data.properties.iddiemxathai).length > 0 ? viewToado.filter(x => x.id === item.data.properties.iddiemxathai)[0].toaDo : ""}
                  </Popup>
                </Tooltip>
              </GeoJSON>
            ))}
          </div>
        }
        {GeoJson_data && GeoJson_data.length > 0 && loaicongtrinh === constants.DICT_LOAI_CT.NDD_KHAITHAC &&
          <div>
            <div className="leaflet-mini-map-congtrinh">{"Công trình: " + viewToado.tenCongTrinh}</div>
            {GeoJson_data.map((item, index) => (
              item.data != null &&
              <GeoJSON ref="geojson" key={1} data={item.data} pointToLayer={(geoJsonPoint, latlng) => { return L.marker(latlng, { icon: NDD_Icon }) }}>
                <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                  <b>{"Giếng: "}</b>{viewToado.giengs.filter(x => x.soHieu === item.data.properties.sohieu).length > 0 ? viewToado.giengs.filter(x => x.soHieu === item.data.properties.sohieu)[0].soHieu : ""}
                  <Popup><b>{"Công trình: "}</b>{viewToado.tenCongTrinh}
                    <br />
                    <b>{"Địa điểm: "}</b> {viewToado.diaDiem}
                    <br />
                    <b>{"Số hiệu: "}</b>{item.data.properties.sohieu}
                    <br />
                    <b>{"Tọa độ (x, y): "}</b>
                    {viewToado.giengs.filter(x => x.soHieu === item.data.properties.sohieu).length > 0 ? viewToado.giengs.filter(x => x.soHieu === item.data.properties.sohieu)[0].toaDo : ""}
                  </Popup>
                </Tooltip>
              </GeoJSON>
            ))}
          </div>
        }
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

