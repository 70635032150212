import React from 'react'
import {Row, Col} from 'antd'
import PropTypes from 'prop-types'
//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import helper from "../../../../helper/helper";
import TTKTModal from "../Modal";

// Columns
const columns_form = (showEditModal, deleteRowModal) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
  },
  {
    title: "Tên đợt",
    dataIndex: "tenDot",
    width: "100px",
  },
  {
    title: "Tên đơn vị thực hiện",
    dataIndex: "tenDonViThucHien",
    // width: "150px",
  },
  {
    title: "Tên trưởng đoàn",
    dataIndex: "tenTruongDoan",
    width: "200px",
  },
  {
    title: "Năm thực hiện",
    dataIndex: "namThucHien",
    width: "120px",
  },
  {
    title: "Thao tác",
    render: (value, row) => (
      <RowTools row={row} deleteRowModal={() => deleteRowModal(row)} showEditModal={showEditModal}/>
    ),
    width: "80px"
  },
];

const columns_presentation = (handleShowDetail) => [
  // {
  //   title: "#",
  //   dataIndex: "stt",
  //   width: "45px",
  //   fixed: 'left',
  // },
  {
    title: "Tên đợt",
    dataIndex: "tenDot",
    // fixed: 'left',
  },
  {
    title: "Tên đơn vị thực hiện",
    dataIndex: "tenDonViThucHien",
    // width: "150px",
  },
  {
    title: "Tên trưởng đoàn",
    dataIndex: "tenTruongDoan",
    width: "120px",
  },
  {
    title: "Năm thực hiện",
    dataIndex: "namThucHien",
    width: "120px",
  },
];

export default class TTKT extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      miningQuantity: [],
      selectedRow: null
    }
  }

  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };

  handleShowDetail = (row) => {
    this.setState({
      selectedRow: row,
    })
  };

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    const {dataSource, handleUpdate} = this.props;
    const newData = [{...item, id: dataSource.length + 1}, ...dataSource];
    this.handleCloseModal();
    handleUpdate(newData);
  };

  handleEdit = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.replaceItem(item, dataSource);
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const {dataSource, handleUpdate, field} = this.props;
    const newData = helper.removeItem(item, dataSource);
    handleUpdate(newData, field);
  };

  addSttToItems = (items) => {
    if (!items) return [];
    let newItems = [];
    if (items) {
      newItems = items.map((item, index) => ({...item, stt: index + 1}))
    }
    return newItems;
  };

  render() {
    const {isAddModalOpen, isEditModalOpen, selectedRow} = this.state;
    const {dataSource, mode} = this.props;

    return (
      <Row>
        <Row type="flex" justify="end" align="middle">
          {mode !== "presentation" &&
          <Button type="primary" onClick={this.handleOpenAddModal} icon="plus"/>}
        </Row>
        <div className="mt-10"/>
        <ViewTable
          bordered pagination={false}
          columns={mode !== "presentation" ? columns_form(this.handleOpenEditModal, this.handleDelete) : columns_presentation(this.handleShowDetail)}
          dataSource={this.addSttToItems(dataSource)}
        />

        {isAddModalOpen &&
        <TTKTModal
          mode="form"
          isOpen={isAddModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleAdd}/>}

        {isEditModalOpen &&
        <TTKTModal
          mode="form"
          initialValues={selectedRow}
          isOpen={isEditModalOpen}
          handleCancel={this.handleCloseModal}
          handleOk={this.handleEdit}/>}
      </Row>
    )
  }
}

TTKT.propTypes = {
  // MODE: string "form" and "presentation"
  mode: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
};