/*
 *  @2019 Cs-core project
 *
 */

import querystring from "query-string";

/**
 * @class SearchParams
 */
export default class SearchParams {
  /**
   * @constructor SearchParams
   * @param {object} defaultParams The search query string you want to working with.
   */
  constructor({ path = window.location.pathname, defaultParams = {} } = {}) {
    this.path = path;
    this.search = window.location.search;
    this.workingParams = Object.keys(defaultParams);
    this.defaultParams = defaultParams;
  }

  /**
   * Get current search params by current location and params you want to work with.
   */
  getSearchParmas() {
    if (this.search !== "") {
      const allParams = querystring.parse(this.search);
      const result = {};
      this.workingParams.forEach(paramKey => {
        if (allParams[paramKey] !== undefined && allParams[paramKey] !== null) {
          result[paramKey] = allParams[paramKey];
        }
      });
      return result;
    } else {
      return this.defaultParams;
    }
  }

  getAllParams() {
    if (this.search !== "") {
      return querystring.parse(this.search);
    } else {
      return {};
    }
  }

  getParam(param) {
    if (this.search !== "") {
      const allParams = querystring.parse(this.search);
      if (allParams[param]) {
        return allParams[param];
      } else {
        return null;
      }
    }
    return null;
  }

  removeParamOnUrlWithoutReload(param) {
    if (this.search !== "") {
      const allParams = querystring.parse(this.search);
      const newParams = Object.assign({}, allParams);
      if (newParams.hasOwnProperty(param)) {
        delete newParams[param];
      }
      window.history.pushState(
        null,
        null,
        "?" + querystring.stringify(newParams)
      );
    }
  }

  reset() {
    window.globalRouter.history.push(
      this.path + "?" + this.stringtify(this.defaultParams)
    );
  }

  navigate(params) {
    const currentParams = this.getSearchParmas();
    const newParams = {
      ...currentParams,
      ...params,
      ...(params.hasOwnProperty("PageNumber") ? {} : { PageNumber: 1 })
    };
    window.globalRouter.history.push(
      this.path + "?" + this.stringtify(newParams)
    );
  }

  navigate_remove(objects, isArrayValue) {
    const currentParams = this.getSearchParmas();
    const newParams = Object.assign({}, currentParams, { PageNumber: 1 });
    Object.keys(objects).forEach(key => {
      const value = objects[key];
      if (newParams.hasOwnProperty(key)) {
        if (isArrayValue) {
          const newValue = newParams[key].split(",");
          newValue.splice(newValue.findIndex(o => o === value), 1);
          newParams[key] = newValue.join(",");
        } else {
          newParams[key] = null;
        }
      }
      window.globalRouter.history.push(
        this.path + "?" + this.stringtify(newParams)
      );
    });
  }

  /**
   * @param {Ojbect} {params}
   * Pass an object and get the query stringtified by params you defined in constructor
   */
  stringtify(params) {
    const result = {};
    this.workingParams.forEach(paramKey => {
      result[paramKey] = params[paramKey];
    });
    return querystring.stringify(result);
  }
}
