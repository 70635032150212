/*
 *  @2019 CS-Core project.
 *
 */

import APIService from "./APIService";
import APIServiceFile from "./ApiServiceFile";
import { API_TNN_URI } from "../config";


export default class Api_tnn {

  /*TODO: DM TNN*/
  /*TODO: api/DM_CoSoDuLieu*/
  getMetadataCSDL = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: 'DM_CoSoDuLieu',
      project: "cscore.tnn"
    });
  }
  //TODO: [Common] GET giay phep da ton tai
  GetKiemTraGPTonTai = async ({ token, sogiayphep, ngaycapphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetKiemTraGPTonTai`,
      data: { sogiayphep, ngaycapphep },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET giay phep xt by id ct
  GetCT_GiayPhepXTByIDCongTrinh = async ({ token, idcongtrinh, loaicongtrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepXTByIDCongTrinh`,
      data: { idcongtrinh, loaicongtrinh },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_ThuyDiens
  getCTNM_ThuyDiens = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_ThuyDien`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_ThuyDien
  getCTNM_ThuyDien = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_ThuyDien/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_ThuyDien
  PostCTNM_ThuyDien = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_ThuyDien`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_ThuyDien
  PutCTNM_ThuyDien = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_ThuyDien/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_ThuyDien
  DeleteCTNM_ThuyDien = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_ThuyDien/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET doanh nghiep bao cao
  GetDoanhNghiep_BaoCaos = async ({ token, idGiayPhep, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao`,
      data: { idGiayPhep, page, size },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET doanh nghiep bao cao trees
  Get_DoanhNghiep_BaoCao_Trees = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/get_trees`,
      data: { strSearch, page, size },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET cau hinh thong so bao cao
  GetBCDK_By_GiayPhep = async ({ token, IdGiayPhep, page, size, Nam } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/BCDK_By_GiayPhep`,
      data: { IdGiayPhep, page, size, Nam },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET bao cao by giay phep
  GetBCDK_KQQT_GiayPhep = async ({ token, IdGiayPhep, Nam, idDMQCVN, DoiTuongQT, idMDSD } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/BCDK_KQQT_GiayPhep`,
      data: { IdGiayPhep, Nam, idDMQCVN, DoiTuongQT, idMDSD },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET bao cao by thong so
  GetBCDK_KQQT_By_ThongSo = async ({ token, idThongSo, idGiayPhep, nam, idDMQCVN, doiTuongQT, idMDSD, id_TramQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/KQQT_By_ThongSo`,
      data: { idThongSo, idGiayPhep, nam, idDMQCVN, doiTuongQT, idMDSD, id_TramQT },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET cau hinh thong so bao cao
  Get_BCDK_CauHinhTS = async ({ token, IdGiayPhep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/Get_BCDK_CauHinhTS?IdGiayPhep=${IdGiayPhep}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post cau hinh thong so bao cao
  Post_BCDK_CauHinhTS = async ({ token, IdGiayPhep, cauhinh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao/Post_BCDK_CauHinhTS?IdGiayPhep=${IdGiayPhep}`,
      project: "cscore.tnn",
      data: cauhinh
    });
  };
  //TODO: [Common] GET danh gia quan trac doanh nghiep
  GetQT_DanhGia_DN_TNN = async ({ token, idBaoCaoDN } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DanhGia_BCDN?idBaoCaoDN=${idBaoCaoDN}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Download danh gia quan trac doanh nghiep
  Download_DanhGia = async ({ token, idBaoCaoDN } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `QT_DanhGia_BCDN/download?idBaoCaoDN=${idBaoCaoDN}`,
      project: "cscore.tnn",
      fileName: 'Bieu_DanhGia_BCDN_' + idBaoCaoDN + '.xlsx'
    });
  };
  //TODO: [Common] GET doanh nghiep bao cao init
  GetQT_KQQT_By_QCVN_Init_dnbc = async ({ token, idgiayphep, iddmqcvn } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/khoitao_kqqt_by_qcvn`,
      data: { idgiayphep, iddmqcvn },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET doanh nghiep bao cao
  GetDoanhNgiep_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/${id}`,
      project: "cscore.tnn"
    });
  };

  GetDuLieuBySong = async ({ token, url } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ApiBanDo/GetDuLieuBySong`,
      data: { url },
      project: "cscore.tnn"
    });
  };

  GetDuLieuBySong_CapBo = async ({ token, url } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ApiBanDo/GetDuLieuBySong_CapBo`,
      data: { url },
      project: "cscore.tnn"
    });
  };

  GetDuLieuByCongTrinh = async ({ token, url } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ApiBanDo/GetDuLieuByCongTrinh`,
      data: { url },
      project: "cscore.tnn"
    });
  };


  //TODO: [Common] Post doanh nghiep bao cao
  PostDoanhNghiep_BaoCao = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };

  //TODO: [Common] Put doanh nghiep bao cao
  PutDoanhNghiep_BaoCao = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DoanhNghiep_BaoCao/${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };

  //TODO: [Common] Delete doanh nghiep bao cao
  DeleteDoanhNgiep_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DoanhNghiep_BaoCao/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET giay phep by doanh nghiep
  GetCT_GiayPhep_ByIdDVXCP = async ({ token, iddvxcp } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhep_ByIdDVXCP?iddvxcp=${iddvxcp}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET CTNM_HoChuas
  getCTNM_HoChuas = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_HoChua`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_HoChua
  getCTNM_HoChua = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_HoChua/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_HoChua
  PostCTNM_HoChua = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_HoChua`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_HoChua
  PutCTNM_HoChua = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_HoChua/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_HoChua
  DeleteCTNM_HoChua = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_HoChua/${id}`,
      project: "cscore.tnn"
    });
  };


  //TODO: [Common] GET CTNM_TramBoms
  getCTNM_TramBoms = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_TramBom`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_TramBom
  getCTNM_TramBom = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_TramBom/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_TramBom
  PostCTNM_TramBom = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_TramBom`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_TramBom
  PutCTNM_TramBom = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_TramBom/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_TramBom
  DeleteCTNM_TramBom = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_TramBom/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_BaiDaps
  getCTNM_BaiDaps = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_BaiDap`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_BaiDap
  getCTNM_BaiDap = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_BaiDap/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_BaiDap
  PostCTNM_BaiDap = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_BaiDap`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_BaiDap
  PutCTNM_BaiDap = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_BaiDap/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_BaiDap
  DeleteCTNM_BaiDap = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_BaiDap/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_Congs
  getCTNM_Congs = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_Cong`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_Cong
  getCTNM_Cong = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_Cong/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_Cong
  PostCTNM_Cong = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_Cong`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_Cong
  PutCTNM_Cong = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_Cong/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_Cong
  DeleteCTNM_Cong = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_Cong/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_TramCapNuocs
  getCTNM_TramCapNuocs = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_TramCapNuoc`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_TramCapNuoc
  getCTNM_TramCapNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_TramCapNuoc/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_TramCapNuoc
  PostCTNM_TramCapNuoc = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_TramCapNuoc`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_TramCapNuoc
  PutCTNM_TramCapNuoc = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_TramCapNuoc/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_TramCapNuoc
  DeleteCTNM_TramCapNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_TramCapNuoc/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_NhaMayNuocs
  getCTNM_NhaMayNuocs = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_NhaMayNuoc`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_NhaMayNuoc
  getCTNM_NhaMayNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_NhaMayNuoc/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNM_NhaMayNuoc
  PostCTNM_NhaMayNuoc = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNM_NhaMayNuoc`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNM_NhaMayNuoc
  PutCTNM_NhaMayNuoc = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNM_NhaMayNuoc/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNM_NhaMayNuoc
  DeleteCTNM_NhaMayNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNM_NhaMayNuoc/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CT_KTNBs
  getCT_KTNBs = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_KTNB`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CT_KTNB
  getCT_KTNB = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_KTNB/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCT_KTNB
  PostCT_KTNB = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_KTNB`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCT_KTNB
  PutCT_KTNB = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_KTNB/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCT_KTNB
  DeleteCT_KTNB = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CT_KTNB/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Search công trình by giấy phép
  GetCT_GiayPheps = async ({ token, strSearch, roledvhc, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPheps?strSearch=${strSearch}&roledvhc=${roledvhc}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTXT_CongTrinhs
  getCTXT_CongTrinhs = async ({ token, strSearch, isCapPhep, page, size, giatri, luuluongnuocthai, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTXT_CongTrinh`,
      data: { strSearch, isCapPhep, page, size, giatri, luuluongnuocthai, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTXT_CongTrinh
  getCTXT_CongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTXT_CongTrinh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTXT_CongTrinh
  PostCTXT_CongTrinh = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTXT_CongTrinh`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTXT_CongTrinh
  PutCTXT_CongTrinh = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTXT_CongTrinh/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTXT_CongTrinh
  DeleteCTXT_CongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTXT_CongTrinh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNDD_CongTrinhs
  getCTNDD_CongTrinhs = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNDD_CongTrinh`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNDD_CongTrinh
  getCTNDD_CongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNDD_CongTrinh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNDD_CongTrinh
  PostCTNDD_CongTrinh = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNDD_CongTrinh`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNDD_CongTrinh
  PutCTNDD_CongTrinh = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNDD_CongTrinh/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNDD_CongTrinh
  DeleteCTNDD_CongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNDD_CongTrinh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNDD_CongTrinhThamDos
  getCTNDD_CongTrinhThamDos = async ({ token, strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNDD_CongTrinhThamDo`,
      data: { strSearch, isCapPhep, page, size, giatri, mucdichsd, luuluongkt, madvhc, roledvhc },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNDD_CongTrinhThamDo
  getCTNDD_CongTrinhThamDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNDD_CongTrinhThamDo/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] PostCTNDD_CongTrinhThamDo
  PostCTNDD_CongTrinhThamDo = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CTNDD_CongTrinhThamDo`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] PutCTNDD_CongTrinhThamDo
  PutCTNDD_CongTrinhThamDo = async ({ token, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CTNDD_CongTrinhThamDo/${data.id}`,
      project: "cscore.tnn",
      data: data
    });
  };

  //TODO: [Common] DeleteCTNDD_CongTrinhThamDo
  DeleteCTNDD_CongTrinhThamDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CTNDD_CongTrinhThamDo/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] getCTNDD_LoaiGieng
  getCTNDD_LoaiGiengs = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNDD_LoaiGieng`,
      data: { page, size },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CT_GiayPhepCongTrinhs
  getCT_GiayPhepCongTrinhsByCongTrinhId = async ({ token, congTrinhId } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_GiayPhepCongTrinh/get`, //can cap nhat lai url
      data: { congTrinhId },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CT_FileDinhKems
  getCT_FileDinhKemsByCongTrinhId = async ({ token, congTrinhId } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_FileDinhKems/get`, //can cap nhat lai url
      data: { congTrinhId },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET CTNM_HangMucs
  getCTNM_HangMucsByCongTrinhId = async ({ token, congTrinhId } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CTNM_HangMucs/get`, //can cap nhat lai url
      data: { congTrinhId },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET thong so QT TNN
  GetQT_ThongSo_TNNs = async ({ token, strSearch, page, size, } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET thong so QT TNN by id
  GetQT_ThongSo_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post thong so QT TNN
  PostQT_ThongSo_TNN = async ({ token, thongso_TNN } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_ThongSo`,
      data: thongso_TNN,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Put thong so QT TNN
  PutQT_ThongSo_TNN = async ({ token, id, thongso_TNN } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_ThongSo/${id}`,
      data: thongso_TNN,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Delete thong so QT_ThongSo_TNN
  DeleteQT_ThongSo_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_ThongSo/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET 
  GetCT_DonViQuanLys = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_DonViQuanLy`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET thong so doi tuong 
  GetQT_ThongSos_By_DoiTuongQT = async ({ token, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DoiTuongQT_ThongSos/ThongSos_By_DoiTuongQT?doituongQT=${doituongQT}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET kqqt by thong so
  GetQT_KQQT_By_ThongSo = async ({ token, idThongSo, idTramQT, loaiTram, idKyQT, nam, idDMQCVN, idMDSD } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_Tram/KQQT_By_ThongSo`,
      data: { idThongSo, idTramQT, loaiTram, idKyQT, nam, idDMQCVN, idMDSD },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET tieu chuan ap dung
  GetQT_DMQCVN_TNNs = async ({ token, strSearch, page, size, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN?doituongqt=${doituongQT}&page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] GET tieu chuan ap dung by id
  GetQT_DMQCVN_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN/${id}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] Put tieu chuan ap dung
  PutQT_DMQCVN_TNN = async ({ token, id, dmqcvn_input } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DMQCVN_TNN/${id}`,
      project: "cscore.tnn",
      data: dmqcvn_input
    });
  };

  //TODO: [Common] Post tieu chuan ap dung
  PostQT_DMQCVN_TNN = async ({ token, dmqcvn_input, doituongQT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DMQCVN_TNN?doituongqt=${doituongQT}`,
      data: dmqcvn_input,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] Delete tieu chuan ap dung
  DeleteQT_DMQCVN_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DMQCVN_TNN/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET vung kt
  GetDM_VungKinhTeXHs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_VungKinhTeXH?page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] GET  vung kt
  GetDM_VungKinhTeXH = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_VungKinhTeXH/${id}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] Put  vung kt
  PutDM_VungKinhTeXH = async ({ token, id, vungkinhte } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_VungKinhTeXH/${id}`,
      project: "cscore.tnn",
      data: vungkinhte
    });
  };

  //TODO: [Common] Post  vung kt
  PostDM_VungKinhTeXH = async ({ token, vungkinhtexh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_VungKinhTeXH`,
      data: vungkinhtexh,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] Delete  vung kt
  DeleteDM_VungKinhTeXH = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_VungKinhTeXH/${id}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] GET doi tuong QT
  GetDoiTuongQTs = async ({ token, data } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/doituongqt`,
      data: [data],
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET kqqt tram
  GetQT_KQQT_Trams = async ({ token, idTramQT, loaiTram, idKyQT, nam, page, size, idDMQCVN, idMDSD } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_Tram`,
      project: "cscore.tnn",
      data: { idTramQT, loaiTram, idKyQT, nam, page, size, idDMQCVN, idMDSD }
    });
  };
  //TODO: [Common] GET kqqt tram trees kttv
  Get_QT_KQQT_Tram_Trees = async ({ token, loaiDT, loaiTram, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_Tram/get_trees?loaiTram=${loaiTram}&loaiDT=${loaiDT}&strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tram QT TNN
  GetQT_TramQuanTrac_TNNs = async ({ token, strSearch, page, size, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TramQuanTrac_TNN?doituongqt=${doituongQT}&page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tram QT by id
  GetQT_TramQuanTrac_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TramQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post tram QT TNN
  PostQT_TramQuanTrac_TNN = async ({ token, quanTrac_TNN, loaiquantrac } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_TramQuanTrac_TNN?loaiquantrac=${loaiquantrac}`,
      project: "cscore.tnn",
      data: quanTrac_TNN
    });
  };

  //TODO: [Common] Put tram QT TNN
  PutQT_TramQuanTrac_TNN = async ({ token, id, quanTrac_TNN } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_TramQuanTrac_TNN/${id}`,
      project: "cscore.tnn",
      data: quanTrac_TNN
    });
  };
  //TODO: [Common] Delete tram QT TNN
  DeleteQT_TramQuanTrac_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_TramQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET ds kinh tuyen truc
  GetDS_KinhTuyenTruc = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_KinhTuyenTruc/dskinhtuyentruc`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET QCVN TNN
  GetQT_QCVN_TNNs = async ({ token, strSearch, page, size, DMQCID } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_QCVN_TNN?DMQCID=${DMQCID}&page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post QCVN TNN
  PostQT_QCVN_TNN = async ({ token, Objs, idDM_QCVN, idMDSD } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_QCVN_TNN?idDM_QCVN=${idDM_QCVN}&idMDSD=${idMDSD}`,
      project: "cscore.tnn",
      data: Objs
    });
  };

  //TODO: [Common] Put QCVN TNN
  PutQT_QCVN_TNN = async ({ token, id, Obj } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_QCVN_TNN/${id}`,
      project: "cscore.tnn",
      data: Obj
    });
  };
  //TODO: [Common] Delete QCVN TNN
  Delete_QT_QCVN_TNN = async ({ token, idDM_QCVN, idMDSD } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_QCVN_TNN/delete?idDM_QCVN=${idDM_QCVN}&idMDSD=${idMDSD}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET QCDT
  GetQuyChuanByDoiTuong = async ({ token, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN/bydoituong?doituongqt=${doituongQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET QCDT init
  GetQT_QCVN_TNN_Init = async ({ token, idquychuan } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_QCVN_TNN/khoitao?idquychuan=${idquychuan}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET QCVN by mdsd
  GetQT_QCVN_TNN_By_MDSD = async ({ token, idquychuan, idmdsd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_QCVN_TNN/getbymdsd?idquychuan=${idquychuan}&idmdsd=${idmdsd}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DMQCVN by id
  GetQT_DMQCVN_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Diem QT TNN
  GetQT_DiemQuanTracs = async ({ token, strSearch, page, size, doituongQT, idTuyenQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DiemQuanTrac_TNN?doituongqt=${doituongQT}&page=${page}&size=${size}&strSearch=${strSearch}&idTuyenQT=${idTuyenQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DS Diem QT TNN
  GetDS_DiemQuanTrac_TNN = async ({ token, doituongQT, idTuyenQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DiemQuanTrac_TNN/dsdiemquantrac?doituongqt=${doituongQT}&idTuyenQT=${idTuyenQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DS Diem QT TNN
  GetQT_DiemQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DiemQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Diem QT TNN
  PostQT_DiemQuanTrac_TNN = async ({ token, diemQT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DiemQuanTrac_TNN`,
      project: "cscore.tnn",
      data: diemQT
    });
  };

  //TODO: [Common] Put Diem QT TNN
  PutQT_DiemQuanTrac_TNN = async ({ token, id, diemQT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DiemQuanTrac_TNN/${id}`,
      project: "cscore.tnn",
      data: diemQT
    });
  };
  //TODO: [Common] Delete Diem QT TNN
  DeleteQT_DiemQuanTrac_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DiemQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DSCongtrinh by idloaicongtrinh
  GetDSCTCapPhepsByLoaiCT = async ({ token, idloaicongtrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/CTCapPhep?idloaicongtrinh=${idloaicongtrinh}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DStram QT
  GetDSTram_TNN = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TramQuanTrac_TNN/dstram_tnn`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DS MuiChieu
  GetMuiChieus = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/muichieu`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Dot QT TNN
  GetQT_DotQuanTrac_TNNs = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DotQuanTrac_TNN?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Dot QT TNN by id
  GetQT_DotQuanTrac_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DotQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post Dot QT TNN
  PostQT_DotQuanTrac_TNN = async ({ token, dotQuanTrac } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DotQuanTrac_TNN`,
      project: "cscore.tnn",
      data: dotQuanTrac
    });
  };

  //TODO: [Common] Put Dot QT TNN
  PutQT_DotQuanTrac_TNN = async ({ token, id, dotQuanTrac } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DotQuanTrac_TNN/${id}`,
      project: "cscore.tnn",
      data: dotQuanTrac
    });
  };
  //TODO: [Common] Delete Dot QT TNN
  DeleteQT_DotQuanTrac_TNN = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DotQuanTrac_TNN/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET ds Dot QT TNN
  GetDS_DotQuanTrac_TNN = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DotQuanTrac_TNN/dsdotquantrac`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thong so by doi tuong
  GetDS_ThongSoByDoiTuong = async ({ token, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSoDoiTuong_TNN?doituongQT=${doituongQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thong so by doi tuong qt
  GetQT_DoiTuongQT_ThongSos = async ({ token, doituongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DoiTuongQT_ThongSos?doituongQT=${doituongQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Thong so by doi tuong qt
  PostQT_DoiTuongQT_ThongSos = async ({ token, doituongQT, objs } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DoiTuongQT_ThongSos?doituongQT=${doituongQT}`,
      project: "cscore.tnn",
      data: objs
    });
  };
  //TODO: [Common] Post Thong so by doi tuong
  PostQT_ThongSoDoiTuong_TNN = async ({ token, doituongQT, thongsodoituongs } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_ThongSoDoiTuong_TNN?doituongQT=${doituongQT}`,
      project: "cscore.tnn",
      data: thongsodoituongs
    });
  };

  //TODO: [Common] GET Mau QT TNN
  GetQT_MauQuanTracs = async ({ token, page, size, strSearch, doituongQT, idDiemQT, idDotQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTrac?doituongQT=${doituongQT}&idDiemQT=${idDiemQT}&idDotQT=${idDotQT}&page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau QT trees TNN 
  Get_QT_MauQuanTrac_Trees = async ({ token, doituong, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTrac/get_trees?doituong=${doituong}&&strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau init QT TNN
  GetQT_MauQuanTrac_Init_TNN = async ({ token, doituongqt, iddiemqt, iddotqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTrac/khoitao?doituongqt=${doituongqt}&iddiemqt=${iddiemqt}&iddotqt=${iddotqt}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau init by qcvn QT TNN
  GetQT_KQQT_By_QCVN_Init = async ({ token, iddmqcvn } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTrac/khoitao_kqqt_by_qcvn?iddmqcvn=${iddmqcvn}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau QT by ID TNN
  GetQT_MauQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Mau QT TNN
  PostQT_MauQuanTrac = async ({ token, mauQT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_MauQuanTrac`,
      project: "cscore.tnn",
      data: mauQT
    });
  };

  //TODO: [Common] Put Mau QT TNN
  PutQT_MauQuanTrac = async ({ token, id, mauQT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_MauQuanTrac/${id}`,
      project: "cscore.tnn",
      data: mauQT
    });
  };
  //TODO: [Common] Delete Mau QT TNN
  DeleteQT_MauQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_MauQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET Mau QT TNN CT
  GetQT_MauQuanTracCTs = async ({ token, page, size, strSearch, idNhomCT, idLoaiCT, idCongTrinh, idDiemQT, idDotQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTracCT?idNhomCT=${idNhomCT}&idLoaiCT=${idLoaiCT}&idCongTrinh=${idCongTrinh}&idDiemQT=${idDiemQT}&idDotQT=${idDotQT}&page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau init QT TNN CT
  GetQT_MauQuanTracCT_Init = async ({ token, idNhomCT, idLoaiCT, idCongTrinh, iddiemqt, iddotqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTracCT/khoitao?idNhomCT=${idNhomCT}&idLoaiCT=${idLoaiCT}&idCongTrinh=${idCongTrinh}&iddiemqt=${iddiemqt}&iddotqt=${iddotqt}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Mau QT CT by ID TNN
  GetQT_MauQuanTracCT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MauQuanTracCT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Mau QT TNN CT
  PostQT_MauQuanTracCT = async ({ token, mauQT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_MauQuanTracCT`,
      project: "cscore.tnn",
      data: mauQT
    });
  };

  //TODO: [Common] Put Mau QT TNN CT
  PutQT_MauQuanTracCT = async ({ token, id, mauQT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_MauQuanTracCT/${id}`,
      project: "cscore.tnn",
      data: mauQT
    });
  };
  //TODO: [Common] Delete Mau QT TNN CT
  DeleteQT_MauQuanTracCT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_MauQuanTracCT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Danh gia ket qua QT TNN
  GetQT_DanhGia_TNN = async ({ token, idMauQT, idDMQCVN, idMDSD } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DanhGia_TNN?idMauQT=${idMauQT}&idDMQCVN=${idDMQCVN}&idMDSD=${idMDSD}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thongso kt  TNN
  GetQT_ThongSo_KTs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo_KT?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thongso kt by id TNN
  GetQT_ThongSo_KT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo_KT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Thongso kt TNN
  PostQT_ThongSo_KT = async ({ token, thongso_KT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_ThongSo_KT`,
      project: "cscore.tnn",
      data: thongso_KT
    });
  };

  //TODO: [Common] Put Thongso kt TNN
  PutQT_ThongSo_KT = async ({ token, id, thongso_KT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_ThongSo_KT/${id}`,
      project: "cscore.tnn",
      data: thongso_KT
    });
  };
  //TODO: [Common] Delete Thongso kt TNN
  DeleteQT_ThongSo_KT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_ThongSo_KT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thongso TV  TNN
  GetQT_ThongSo_TVs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo_TV?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Thongso TV by id TNN
  GetQT_ThongSo_TV = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_ThongSo_TV/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Thongso TV TNN
  PostQT_ThongSo_TV = async ({ token, thongso_TV } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_ThongSo_TV`,
      project: "cscore.tnn",
      data: thongso_TV
    });
  };

  //TODO: [Common] Put Thongso TV TNN
  PutQT_ThongSo_TV = async ({ token, id, thongso_TV } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_ThongSo_TV/${id}`,
      project: "cscore.tnn",
      data: thongso_TV
    });
  };
  //TODO: [Common] Delete Thongso TV TNN
  DeleteQT_ThongSo_TV = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_ThongSo_TV/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Don vi do tnn
  GetQT_DonViDos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DonViDo`,
      data: { page, size, strSearch },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Don vi do tnn by id TNN
  GetQT_DonViDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DonViDo/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post Don vi do tnn
  PostQT_DonViDo = async ({ token, objdonvido } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DonViDo`,
      project: "cscore.tnn",
      data: objdonvido
    });
  };

  //TODO: [Common] Put Don vi do tnn
  PutQT_DonViDo = async ({ token, id, objdonvido } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DonViDo/${id}`,
      project: "cscore.tnn",
      data: objdonvido
    });
  };
  //TODO: [Common] Delete Don vi do tnn
  DeleteQT_DonViDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DonViDo/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET loai quan trac
  GetLoaiQuanTracs = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/loaiquantrac`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tram quan trac kttv
  GetQT_TramQuanTracs = async ({ token, strSearch, page, size, loaiDT, loaiTram } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TramQuanTrac?strSearch=${strSearch}&page=${page}&size=${size}&loaiDT=${loaiDT}&loaiTram=${loaiTram}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tram quan trac kttv by id 
  GetQT_TramQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TramQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post tram quan trac kttv
  PostQT_TramQuanTrac = async ({ token, quanTrac, loaiquantrac } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_TramQuanTrac?loaiquantrac=${loaiquantrac}`,
      project: "cscore.tnn",
      data: quanTrac
    });
  };

  //TODO: [Common] Put tram quan trac kttv
  PutQT_TramQuanTrac = async ({ token, id, quanTrac } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_TramQuanTrac/${id}`,
      project: "cscore.tnn",
      data: quanTrac
    });
  };
  //TODO: [Common] Delete tram quan trac kttv
  DeleteQT_TramQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_TramQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };

  //  //TODO: [Common] GET tram quan trac nuoc
  //  GetQTN_TramQuanTracs = async ({ token, strSearch, page, size, loaiQT,loaiTram } = {}) => {
  //   return new APIService({ token: token }).getData({
  //     path: `QT_TramQuanTrac?strSearch=${strSearch}&page=${page}&size=${size}&loaiQT=${loaiQT}&loaiTram=${loaiTram}`,
  //     project: "cscore.tnn"
  //   });
  // };
  // //TODO: [Common] GET tram quan trac nuoc by id 
  // GetQTN_TramQuanTrac = async ({ token, id } = {}) => {
  //   return new APIService({ token: token }).getData({
  //     path: `QT_TramQuanTrac/${id}`,
  //     project: "cscore.tnn"
  //   });
  // };
  //  //TODO: [Common] Post tram quan trac nuoc
  //  PostQTN_TramQuanTrac = async ({ token, quanTrac, loaiquantrac } = {}) => {
  //   return new APIService({ token: token }).postData({
  //     path: `QT_TramQuanTrac?loaiquantrac=${loaiquantrac}`,
  //     project: "cscore.tnn",
  //     data: quanTrac
  //   });
  // };
  //  //TODO: [Common] Put tram quan trac nuoc
  //  PutQTN_TramQuanTrac = async ({ token, id, quanTrac } = {}) => {
  //   return new APIService({ token: token }).putData({
  //     path: `QT_TramQuanTrac/${id}`,
  //     project: "cscore.tnn",
  //     data: quanTrac
  //   });
  // };
  //  //TODO: [Common] Delete tram quan trac nuoc
  //  DeleteQTN_TramQuanTrac = async ({ token, id } = {}) => {
  //   return new APIService({ token: token }).deleteData({
  //     path: `QT_TramQuanTrac/${id}`,
  //     project: "cscore.tnn"
  //   });
  // };

  //TODO: [Common] GET ky quan trac kttv
  GetQT_KyQuanTracs = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KyQuanTrac?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET ky quan trac kttv by id 
  GetQT_KyQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KyQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post ky quan trac kttv
  PostQT_KyQuanTrac = async ({ token, kyQuanTrac } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_KyQuanTrac`,
      project: "cscore.tnn",
      data: kyQuanTrac
    });
  };

  //TODO: [Common] Put ky quan trac kttv
  PutQT_KyQuanTrac = async ({ token, id, kyQuanTrac } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_KyQuanTrac/${id}`,
      project: "cscore.tnn",
      data: kyQuanTrac
    });
  };
  //TODO: [Common] Delete ky quan trac kttv
  DeleteQT_KyQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_KyQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET thong so doi tuong kttv 
  GetDS_CauHinhThongSoByLoaiQT = async ({ token, loaiQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_CauHinhTsoKTTV?loaiQT=${loaiQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post thong so doi tuong kttv 
  PostQT_CauHinhTsoKTTV = async ({ token, loaiQT, thongsodoituongs } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_CauHinhTsoKTTV?loaiQT=${loaiQT}`,
      project: "cscore.tnn",
      data: thongsodoituongs
    });
  };
  //TODO: [Common] GET loai vb kttv
  GetVB_LoaiVanBans = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `VB_LoaiVanBan?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET loai vb kttv by id 
  GetVB_LoaiVanBan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `VB_LoaiVanBan/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post loai vb kttv
  PostVB_LoaiVanBan = async ({ token, loaiVanBan } = {}) => {
    return new APIService({ token: token }).postData({
      path: `VB_LoaiVanBan`,
      project: "cscore.tnn",
      data: loaiVanBan
    });
  };

  //TODO: [Common] Put loai vb kttv
  PutVB_LoaiVanBan = async ({ token, id, loaiVanBan } = {}) => {
    return new APIService({ token: token }).putData({
      path: `VB_LoaiVanBan/${id}`,
      project: "cscore.tnn",
      data: loaiVanBan
    });
  };
  //TODO: [Common] Delete loai vb kttv
  DeleteVB_LoaiVanBan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `VB_LoaiVanBan/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET kqqt kttv
  GetQT_KQQT_KTTVs = async ({ token, idTramQT, idKyQT, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_KTTV?idTramQT=${idTramQT}&idKyQT=${idKyQT}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET kqqt kttv by id 
  GetQT_KQQT_KTTV = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_Tram/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET kqqt init kttv
  GetQT_MauQuanTrac_Init = async ({ token, doiTuongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_Tram/khoitao?doiTuongQT=${doiTuongQT}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET kqqt trees kttv
  Get_QT_KQQT_KTTV_Trees = async ({ token, loaiqt, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_KQQT_KTTV/get_trees?loaiqt=${loaiqt}&strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post kqqt vb kttv
  PostQT_KQQT_KTTV = async ({ token, kq } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_KQQT_KTTV`,
      project: "cscore.tnn",
      data: kq
    });
  };

  //TODO: [Common] Put kqqt vb kttv
  PutQT_KQQT_KTTV = async ({ token, id, kq } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_KQQT_KTTV/${id}`,
      project: "cscore.tnn",
      data: kq
    });
  };
  //TODO: [Common] Delete kqqt vb kttv
  DeleteQT_KQQT_KTTV = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_KQQT_Tram/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post kqqt tram
  PostQT_KQQT_Tram = async ({ token, kq } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_KQQT_Tram`,
      project: "cscore.tnn",
      data: kq
    });
  };

  //TODO: [Common] Put kqqt tram
  PutQT_KQQT_Tram = async ({ token, id, kq } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_KQQT_Tram/${id}`,
      project: "cscore.tnn",
      data: kq
    });
  };
  //TODO: [Common] Delete kqqt vb tram
  DeleteQT_KQQT_Tram = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_KQQT_Tram/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET muc dich su dung
  GetQT_MucDichSuDungs = async ({ token, IdDM_QCVN, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MucDichSuDung?IdDM_QCVN=${IdDM_QCVN}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET muc dich su dung by id 
  GetQT_MucDichSuDung = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MucDichSuDung/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post muc dich su dung
  PostQT_MucDichSuDung = async ({ token, objmucdich, IdDM_QCVN } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_MucDichSuDung?IdDM_QCVN=${IdDM_QCVN}`,
      project: "cscore.tnn",
      data: objmucdich
    });
  };

  //TODO: [Common] Put muc dich su dung
  PutQT_MucDichSuDung = async ({ token, id, objmucdich } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_MucDichSuDung/${id}`,
      project: "cscore.tnn",
      data: objmucdich
    });
  };
  //TODO: [Common] Delete muc dich su dung
  DeleteQT_MucDichSuDung = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_MucDichSuDung/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET don vi tham dinh
  GetDM_DonViThamDinhs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_DonViThamDinh?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET don vi tham dinh by id 
  GetDM_DonViThamDinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_DonViThamDinh/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post don vi tham dinh
  PostDM_DonViThamDinh = async ({ token, donvithamdinh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_DonViThamDinh`,
      project: "cscore.tnn",
      data: donvithamdinh
    });
  };
  //TODO: [Common] Put don vi tham dinh
  PutDM_DonViThamDinh = async ({ token, id, donvithamdinh } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_DonViThamDinh/${id}`,
      project: "cscore.tnn",
      data: donvithamdinh
    });
  };
  //TODO: [Common] Delete don vi tham dinh
  DeleteDM_DonViThamDinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_DonViThamDinh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET loai su co
  GetDM_LoaiSuCos = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiSuCo?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET loai su co by id 
  GetDM_LoaiSuCo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiSuCo/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post loai su co
  PostDM_LoaiSuCo = async ({ token, suco } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_LoaiSuCo`,
      project: "cscore.tnn",
      data: suco
    });
  };
  //TODO: [Common] Put loai su co
  PutDM_LoaiSuCo = async ({ token, id, suco } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_LoaiSuCo/${id}`,
      project: "cscore.tnn",
      data: suco
    });
  };
  //TODO: [Common] Delete loai su co
  DeleteDM_LoaiSuCo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_LoaiSuCo/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET bao cao su co
  Get_BaoCaoSuCos = async ({ token, strSearch, page, size, tungay, denngay, status, loaisuco } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BaoCaoSuCo/getBaoCaoSuCo`,
      project: "cscore.tnn",
      data: { strSearch, page, size, tungay, denngay, status, loaisuco }
    });
  };
  //TODO: [Common] GET bao su co by id 
  GetBaoCaoSuCo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BaoCaoSuCo/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post bao cao su co
  PostBaoCaoSuCo = async ({ token, BaoCaoSuCo } = {}) => {
    return new APIService({ token: token }).postData({
      path: `BaoCaoSuCo/postBaoCaoSuCo`,
      project: "cscore.tnn",
      data: BaoCaoSuCo
    });
  };

  //TODO: [Common] Post cap nhat su co
  CapNhatBaoCaoSuCo = async ({ token, id, contenxuly } = {}) => {
    return new APIService({ token: token }).postData({
      path: `BaoCaoSuCo/CapNhatBaoCaoSuCo?id=${id}&contenxuly=${contenxuly}`,
      project: "cscore.tnn",

    });
  };


  //TODO: [Common] GET y kien cu tri
  GetYKienCuTri_TraLois = async ({ token, strSearch, nam, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `YKienCuTri_TraLoi?strSearch=${strSearch}&page=${page}&size=${size}&nam=${nam}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET y kien tra loi cu tri by id 
  GetYKienCuTri_TraLoi = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `YKienCuTri_TraLoi/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post y kien tra loi cu tri
  PostYKienCuTri_TraLoi = async ({ token, objbaocao, } = {}) => {
    return new APIService({ token: token }).postData({
      path: `YKienCuTri_TraLoi`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Put y kien tra loi cu tri
  PutYKienCuTri_TraLoi = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `YKienCuTri_TraLoi/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };

  //TODO: [Common] Delete y kien tra loi cu tri
  DeleteYKienCuTri_TraLoi = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `YKienCuTri_TraLoi/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET y kien tra loi bao chi
  GetYKienBaoChi_TraLois = async ({ token, strSearch, nam, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `YKienBaoChi_TraLoi?strSearch=${strSearch}&page=${page}&size=${size}&nam=${nam}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET y kien tra loi bao chi by id 
  GetYKienBaoChi_TraLoiTP = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `YKienBaoChi_TraLoi/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post y kien tra loi bao chi
  PostYKienBaoChi_TraLoi = async ({ token, objbaocao, } = {}) => {
    return new APIService({ token: token }).postData({
      path: `YKienBaoChi_TraLoi`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Put y kien tra loi bao chi
  PutYKienBaoChi_TraLoi = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `YKienBaoChi_TraLoi/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Delete y kien tra loi bao chi
  DeleteYKienBaoChi_TraLoi = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `YKienBaoChi_TraLoi/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET tuyen quan trac
  GetQT_TuyenQuanTracs = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TuyenQuanTrac?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tuyen quan trac
  GetQT_TuyenQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_TuyenQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post tuyen quan trac
  PostQT_TuyenQuanTrac = async ({ token, objTuyenQuanTrac } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_TuyenQuanTrac`,
      project: "cscore.tnn",
      data: objTuyenQuanTrac
    });
  };
  //TODO: [Common] Put tuyen quan trac
  PutQT_TuyenQuanTrac = async ({ token, id, objTuyenQuanTrac } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_TuyenQuanTrac/${id}`,
      project: "cscore.tnn",
      data: objTuyenQuanTrac
    });
  };
  //TODO: [Common] Delete tuyen quan trac
  DeleteQT_TuyenQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_TuyenQuanTrac/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phuong phap pt
  GetQT_PhuongPhapPTs = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_PhuongPhapPT?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phuong phap pt
  GetQT_PhuongPhapPT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_PhuongPhapPT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post phuong phap pt
  PostQT_PhuongPhapPT = async ({ token, objPhuongPhapPT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_PhuongPhapPT`,
      project: "cscore.tnn",
      data: objPhuongPhapPT
    });
  };
  //TODO: [Common] Put phuong phap pt
  PutQT_PhuongPhapPT = async ({ token, id, objPhuongPhapPT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_PhuongPhapPT/${id}`,
      project: "cscore.tnn",
      data: objPhuongPhapPT
    });
  };
  //TODO: [Common] Delete phuong phap pt
  DeleteQT_PhuongPhapPT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_PhuongPhapPT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET diem QT CT
  GetQT_DiemQuanTracCTs = async ({ token, page, size, strSearch, idNhomCT, idLoaiCT, idCongTrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DiemQuanTrac_CT?page=${page}&size=${size}&idNhomCT=${idNhomCT}&idLoaiCT=${idLoaiCT}&idCongTrinh=${idCongTrinh}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET diem QT CT by id
  GetQT_DiemQuanTracCT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DiemQuanTrac_CT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post diem QT CT
  PostQT_DiemQuanTrac_CT = async ({ token, diemQT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DiemQuanTrac_CT`,
      project: "cscore.tnn",
      data: diemQT
    });
  };
  //TODO: [Common] Put diem QT CT
  PutQT_DiemQuanTrac_CT = async ({ token, id, diemQT } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DiemQuanTrac_CT/${id}`,
      project: "cscore.tnn",
      data: diemQT
    });
  };
  //TODO: [Common] Delete diem QT CT
  DeleteQT_DiemQuanTrac_CT = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DiemQuanTrac_CT/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET don vi thuc hien
  GetQT_DonViThucHiens = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DonViThucHien?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET don vi thuc hien
  GetQT_DonViThucHien = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DonViThucHien/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post don vi thuc hien
  PostQT_DonViThucHien = async ({ token, objdvthuchien } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DonViThucHien`,
      project: "cscore.tnn",
      data: objdvthuchien
    });
  };
  //TODO: [Common] Put don vi thuc hien
  PutQT_DonViThucHien = async ({ token, id, objdvthuchien } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_DonViThucHien/${id}`,
      project: "cscore.tnn",
      data: objdvthuchien
    });
  };
  //TODO: [Common] Delete don vi thuc hien
  DeleteQT_DonViThucHien = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_DonViThucHien/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phuong phap lay mau
  GetQT_PhuongPhapLMs = async ({ token, page, size, IdDM_QCVN } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_PhuongPhapLM?page=${page}&size=${size}&IdDM_QCVN=${IdDM_QCVN}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phuong phap lay mau
  GetQT_PhuongPhapLM = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_PhuongPhapLM/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post phuong phap lay mau
  PostQT_PhuongPhapLM = async ({ token, objphuonghapLM, IdDM_QCVN } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_PhuongPhapLM?IdDM_QCVN=${IdDM_QCVN}`,
      project: "cscore.tnn",
      data: objphuonghapLM
    });
  };
  //TODO: [Common] Put phuong phap lay mau
  PutQT_PhuongPhapLM = async ({ token, id, objphuonghapLM } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QT_PhuongPhapLM/${id}`,
      project: "cscore.tnn",
      data: objphuonghapLM
    });
  };
  //TODO: [Common] Delete phuong phap lay mau
  DeleteQT_PhuongPhapLM = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QT_PhuongPhapLM/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET muc dich su dung 
  GetDM_MucDichSuDung = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_MucDichSuDung/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET muc dich su dung nuoc 
  GetDM_MucDichSuDungs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_MucDichSuDung?page=${page}&size=${size}&strSearch=${strSearch}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post muc dich su dung nuoc
  PostDM_MucDichSuDung = async ({ token, mucdichsudung } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_MucDichSuDung`,
      project: "cscore.tnn",
      data: mucdichsudung

    });
  };
  //TODO: [Common] Put muc dich su dung nuoc
  PutDM_MucDichSuDung = async ({ token, id, mucdich } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_MucDichSuDung/${id}`,
      project: "cscore.tnn",
      data: mucdich

    });
  };
  //TODO: [Common] Delete muc dich su dung nuoc
  DeleteDM_MucDichSuDung = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_MucDichSuDung/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET loai hinh nuoc thai
  GetDM_LoaiHinhNuocThais = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiHinhNuocThai?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET loai hinh nuoc thai
  GetDM_LoaiHinhNuocThai = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiHinhNuocThai/${id}`,
      project: "cscore.tnn",

    });
  };
  //TODO: [Common] Post loai hinh nuoc thai
  PostDM_LoaiHinhNuocThai = async ({ token, nuocthai } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_LoaiHinhNuocThai`,
      project: "cscore.tnn",
      data: nuocthai

    });
  };
  //TODO: [Common] Put loai hinh nuoc thai
  PutDM_LoaiHinhNuocThai = async ({ token, id, nuocthai } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_LoaiHinhNuocThai/${id}`,
      project: "cscore.tnn",
      data: nuocthai

    });
  };
  //TODO: [Common] Delete loai hinh nuoc thai
  DeleteDM_LoaiHinhNuocThai = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_LoaiHinhNuocThai/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET nguon nuoc bao ve
  GetQDBV_NguonNuocs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QDBV_NguonNuoc?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET nguon nuoc bao ve
  GetQDBV_NguonNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QDBV_NguonNuoc/${id}`,
      project: "cscore.tnn",

    });
  };
  //TODO: [Common] Post nguon nuoc bao ve
  PostQDBV_NguonNuoc = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QDBV_NguonNuoc`,
      project: "cscore.tnn",
      data: objBaocao

    });
  };
  //TODO: [Common] Put nguon nuoc bao ve
  PutQDBV_NguonNuoc = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QDBV_NguonNuoc/${id}`,
      project: "cscore.tnn",
      data: objBaocao

    });
  };
  //TODO: [Common] Delete nguon nuoc bao ve
  DeleteQDBV_NguonNuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QDBV_NguonNuoc/${id}`,
      project: "cscore.tnn"
    });
  };




  /*
* TODO:///////////////// Thông tin tài nguyên nước /////////////////////////////////////////////////////
* */
  //TODO: [Common] GET phan loai bao cao
  GetTNN_PhanLoaiBaoCaos = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_PhanLoaiBaoCao?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phan loai bao cao by id 
  GetTNN_PhanLoaiBaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_PhanLoaiBaoCao/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post phan loai bao cao
  PostTNN_PhanLoaiBaoCao = async ({ token, phanLoaiBaoCao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNN_PhanLoaiBaoCao`,
      project: "cscore.tnn",
      data: phanLoaiBaoCao
    });
  };

  //TODO: [Common] Put phan loai bao cao
  PutTNN_PhanLoaiBaoCao = async ({ token, id, phanLoaiBaoCao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `TNN_PhanLoaiBaoCao/${id}`,
      project: "cscore.tnn",
      data: phanLoaiBaoCao
    });
  };
  //TODO: [Common] Delete phan loai bao cao
  DeleteTNN_PhanLoaiBaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNN_PhanLoaiBaoCao/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET Co so du lieu
  GetDMCoSoDuLieus = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_CoSoDuLieu`,
      data: { page, size, strSearch },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET  Co so du lieu by id
  GetDMCoSoDuLieu = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_CoSoDuLieu/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post  Co so du lieu
  PostDMCoSoDuLieu = async ({ token, csdl } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_CoSoDuLieu`,
      project: "cscore.tnn",
      data: csdl
    });
  };

  //TODO: [Common] Put  Co so du lieu
  PutDM_CoSoDuLieu = async ({ token, id, csdl } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_CoSoDuLieu/${id}`,
      project: "cscore.tnn",
      data: csdl
    });
  };
  //TODO: [Common] Delete  Co so du lieu
  DeleteDM_CoSoDuLieu = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_CoSoDuLieu/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET DM nguồn nước nội tỉnh
  GetDM_NguonNuocNoiTinhs = async ({ token, strSearch, page, size, phanloainguonnuoc, mahuyen, maxa } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_NguonNuocNoiTinh`,
      project: "cscore.tnn",
      data: { strSearch, page, size, phanloainguonnuoc, mahuyen, maxa }
    });
  };
  //TODO: [Common] GET  DM nguồn nước nội tỉnh by id
  GetDM_NguonNuocNoiTinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_NguonNuocNoiTinh/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post  DM nguồn nước nội tỉnh
  PostDM_NguonNuocNoiTinh = async ({ token, songsuoi } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_NguonNuocNoiTinh`,
      project: "cscore.tnn",
      data: songsuoi
    });
  };

  //TODO: [Common] Put  DM nguồn nước nội tỉnh
  PutDM_NguonNuocNoiTinh = async ({ token, id, songsuoi } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_NguonNuocNoiTinh/${id}`,
      project: "cscore.tnn",
      data: songsuoi
    });
  };
  //TODO: [Common] Delete  DM nguồn nước nội tỉnh
  DeleteDM_NguonNuocNoiTinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_NguonNuocNoiTinh/${id}`,
      project: "cscore.tnn"
    });
  };


  //TODO: [Common] GET DM cơ quan ban hành
  GetDMCoQuanBanHanhs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_CoQuanBanHanh?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET  DM cơ quan ban hành by id
  GetDMCoQuanBanHanh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_CoQuanBanHanh/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post  DM cơ quan ban hành
  PostDMCoQuanBanHanh = async ({ token, csdl } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_CoQuanBanHanh`,
      project: "cscore.tnn",
      data: csdl
    });
  };

  //TODO: [Common] Put  DM cơ quan ban hành
  PutDM_CoQuanBanHanh = async ({ token, id, csdl } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_CoQuanBanHanh/${id}`,
      project: "cscore.tnn",
      data: csdl
    });
  };
  //TODO: [Common] Delete  DM cơ quan ban hành
  DeleteDM_CoQuanBanHanh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_CoQuanBanHanh/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET DM loai VB dia phuong
  GetDMLoaiVanBans = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiVanBan_DiaPhuong?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET DM loai VB dia phuong by id
  GetDMLoaiVanBan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LoaiVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post DM loai VB dia phuong
  PostDMLoaivanBan = async ({ token, vanban } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_LoaiVanBan_DiaPhuong`,
      project: "cscore.tnn",
      data: vanban
    });
  };

  //TODO: [Common] Put DM loai VB dia phuong
  PutDM_LoaiVanBan = async ({ token, id, vanban } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_LoaiVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn",
      data: vanban
    });
  };
  //TODO: [Common] Delete DM loai VB dia phuong
  DeleteDM_LoaiVanban = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_LoaiVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn"
    });
  };




  //TODO: [Common] GET phan loai ban do
  GetBD_LoaiBanDos = async ({ token, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BD_LoaiBanDo?page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET phan loai ban do by id 
  GetBD_LoaiBanDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BD_LoaiBanDo/${id}`,
      project: "cscore.tnn"
    });
  };



  //TODO: [Common] Post phan loai ban do
  PostBD_LoaiBanDo = async ({ token, Loaibd } = {}) => {
    return new APIService({ token: token }).postData({
      path: `BD_LoaiBanDo`,
      project: "cscore.tnn",
      data: Loaibd
    });
  };

  //TODO: [Common] Put phan loai ban do
  PutBD_LoaiBanDo = async ({ token, id, Loaibando } = {}) => {
    return new APIService({ token: token }).putData({
      path: `BD_LoaiBanDo/${id}`,
      project: "cscore.tnn",
      data: Loaibando
    });
  };
  //TODO: [Common] Delete phan loai ban do
  DeleteBD_LoaiBanDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `BD_LoaiBanDo/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET VBQPPL
  GetVB_VanBanQPPLs = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `VB_VanBanQPPL?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET VBQPPL by id 
  GetVB_VanBanQPPL = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `VB_VanBanQPPL/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post VBQPPL
  PostVB_VanBanQPPL = async ({ token, vanban } = {}) => {
    return new APIService({ token: token }).postData({
      path: `VB_VanBanQPPL`,
      project: "cscore.tnn",
      data: vanban
    });
  };

  //TODO: [Common] Put VBQPPL
  PutVB_VanBanQPPL = async ({ token, id, vanban } = {}) => {
    return new APIService({ token: token }).putData({
      path: `VB_VanBanQPPL/${id}`,
      project: "cscore.tnn",
      data: vanban
    });
  };
  //TODO: [Common] Delete VBQPPL
  DeleteVB_VanBanQPPL = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `VB_VanBanQPPL/${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET quy hoach TNN
  GetQH_BaoCaos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QH_BaoCao`,
      data: { page, size, strSearch },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET quy hoach TNN by id 
  GetQH_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QH_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post quy hoach TNN
  PostQH_BaoCao = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QH_BaoCao`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };

  //TODO: [Common] Put quy hoach TNN
  PutQH_BaoCao = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QH_BaoCao/${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Delete quy hoach TNN
  DeleteQH_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QH_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET danh gia TNN duoi dat
  GetDanhGia_TNN_DuoiDats = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DanhGia_TNN_DuoiDat?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET danh gia TNN duoi dat by id 
  GetDanhGia_TNN_DuoiDat = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DanhGia_TNN_DuoiDat/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post danh gia TNN duoi dat
  PostDanhGia_TNN_DuoiDat = async ({ token, danhGia_TNN } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DanhGia_TNN_DuoiDat`,
      project: "cscore.tnn",
      data: danhGia_TNN
    });
  };

  //TODO: [Common] Put danh gia TNN duoi dat
  PutDanhGia_TNN_DuoiDat = async ({ token, id, danhGia_TNN } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DanhGia_TNN_DuoiDat/${id}`,
      project: "cscore.tnn",
      data: danhGia_TNN
    });
  };
  //TODO: [Common] Delete danh gia TNN duoi dat
  DeleteDanhGia_TNN_DuoiDat = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DanhGia_TNN_DuoiDat/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET dieu tra danh gia TNN
  GetDTDG_BaoCaos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DTDG_BaoCao?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET dieu tra danh gia TNN
  GetDTDG_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DTDG_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post dieu tra danh gia TNN
  PostDTDG_BaoCao = async ({ token, objbaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DTDG_BaoCao`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Put dieu tra danh gia TNN
  PutDTDG_BaoCao = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DTDG_BaoCao/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Delete dieu tra danh gia TNN
  DeleteDTDG_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DTDG_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET thanh tra TNN
  GetTNN_ThanhTras = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_ThanhTra?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET thanh tra TNN
  GetTNN_ThanhTra = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_ThanhTra/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post thanh tra TNN
  PostTNN_ThanhTra = async ({ token, objbaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNN_ThanhTra`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Put thanh tra TNN
  PutTNN_ThanhTra = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `TNN_ThanhTra/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Delete thanh tra TNN
  DeleteTNN_ThanhTra = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNN_ThanhTra/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET tien cap quyen TNN
  GetQD_TienCapQuyens = async ({ token, page, size, strSearch, tunam, dennam } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QD_TienCapQuyen?strSearch=${strSearch}&page=${page}&size=${size}&tunam=${tunam}&dennam=${dennam}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tien cap quyen TNN
  GetQD_TienCapQuyen = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QD_TienCapQuyen/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post tien cap quyen TNN
  PostQD_TienCapQuyen = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QD_TienCapQuyen`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Put tien cap quyen TNN
  PutQD_TienCapQuyen = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QD_TienCapQuyen/${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Delete tien cap quyen TNN
  DeleteQD_TienCapQuyen = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QD_TienCapQuyen/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET cong trinh by id gp TNN
  GetCT_CongTrinh_ByIdGP = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_CongTrinh_ByIdGP?idgiayphep=${idgiayphep}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetBVPCKP_BaoCaos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BVPCKP_BaoCao?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetBVPCKP_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BVPCKP_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post BVPCKP TNN
  PostBVPCKP_BaoCao = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `BVPCKP_BaoCao`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Put BVPCKP TNN
  PutBVPCKP_BaoCao = async ({ token, id, idcpaphep, sogiayphep, ngaycapphep, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `BVPCKP_BaoCao/PutBVPCKP_BaoCao?id=${id}&idcpaphep=${idcpaphep}&sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Delete BVPCKP TNN
  DeleteBVPCKP_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `BVPCKP_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetVBDP_VanBans = async ({ token, page, size, strSearch, loaivanban, madvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QuanLyVanBan_DiaPhuong`,
      data: { page, size, strSearch, loaivanban, madvhc },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetVBDP_VanBan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QuanLyVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post BVPCKP TNN
  PostVBDP_VanBan = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QuanLyVanBan_DiaPhuong`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Put BVPCKP TNN
  PutVBDP_VanBan = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QuanLyVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Delete BVPCKP TNN
  DeleteVBDP_VanBan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QuanLyVanBan_DiaPhuong/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetTNN_BaoCaos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BaoCao?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetTNN_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post BVPCKP TNN
  PostTNN_BaoCao = async ({ token, objbaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNN_BaoCao`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Put BVPCKP TNN
  PutTNN_BaoCao = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `TNN_BaoCao/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Delete BVPCKP TNN
  DeleteTNN_BaoCao = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNN_BaoCao/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] GET BVPCKP TNN
  GetBD_BanDos = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BD_BanDo?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET BVPCKP TNN by id
  GetBD_BanDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BD_BanDo/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post BVPCKP TNN
  PostBD_BanDo = async ({ token, objbando } = {}) => {
    return new APIService({ token: token }).postData({
      path: `BD_BanDo`,
      project: "cscore.tnn",
      data: objbando
    });
  };
  //TODO: [Common] Put BVPCKP TNN
  PutBD_BanDo = async ({ token, id, objbando } = {}) => {
    return new APIService({ token: token }).putData({
      path: `BD_BanDo/${id}`,
      project: "cscore.tnn",
      data: objbando
    });
  };
  //TODO: [Common] Delete BVPCKP TNN
  DeleteBD_BanDo = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `BD_BanDo/${id}`,
      project: "cscore.tnn",
    });
  };

  // TODO: Log
  SearchLog = async ({ token, page, size, searchText, from, to } = {}) => {
    return new APIService({ token: token }).getData({
      path: `log/searchlog`,
      data: { page, size, searchText, from, to },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Delete log TNN
  DeleteLog = async ({ token, id } = {}) => {
    return new APIService({ token: token }).postData({
      path: `log/deletelog?id=${id}`,
      project: "cscore.tnn",
    });
  };
  /*
  * TODO:///////////////// Cấp phép /////////////////////////////////////////////////////
  * */
  // TODO: Loại hình cấp phép
  getLoaiHinhGiayPhep = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/loaihinhgiayphep`,
      project: "cscore.tnn"
    });
  };

  // TODO: Loại công trình
  getCTLoaiCongTrinh = async ({ token, nhomct, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_LoaiCongTrinh`,
      data: { nhomct, page, size },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET loại công trình by id
  GetCT_LoaiCongTrinhId = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_LoaiCongTrinh/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post loại công trình
  PostCT_LoaiCongTrinh = async ({ token, lct, nhomCT } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_LoaiCongTrinh?nhomCT=${nhomCT}`,
      project: "cscore.tnn",
      data: lct
    });
  };
  //TODO: [Common] Put loại công trình
  PutCT_LoaiCongTrinh = async ({ token, id, lct } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_LoaiCongTrinh/${id}`,
      project: "cscore.tnn",
      data: lct
    });
  };
  //TODO: [Common] Delete loại công trình
  DeleteCT_LoaiCongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CT_LoaiCongTrinh/${id}`,
      project: "cscore.tnn",
    });
  };

  // TODO: Công trình theo loại công trình
  getDSCongTrinhsByLoaiCT = async ({ token, idloaicongtrinh, loaiCapPhep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DMChung/congtrinh`,
      data: { idloaicongtrinh, loaiCapPhep },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET thong so by quy chuan 
  GetQT_DMQCVN_ThongSos = async ({ token, iddmqcvn } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_ThongSos?iddmqcvn=${iddmqcvn}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post thong so by quy chuan 
  PostQT_DMQCVN_ThongSos = async ({ token, iddmqcvn, objs, tenDMQCVN } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_DMQCVN_ThongSos?iddmqcvn=${iddmqcvn}&tenDMQCVN=${tenDMQCVN}`,
      project: "cscore.tnn",
      data: objs
    });
  };
  /*
  * TODO: =============== CP CTNM ============================================*/
  // TODO: List GP CTNM
  getCT_GiayPhepCTNMs = async ({ token, capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, loaicongtrinh, giatri, ngaykytu, ngaykyden, namcapphep, giayphep_sort, sort, mucdichsd, congsuat, luuluongkt, namhethan, madvhc, roledvhc, nguonkhaithac, iddvtd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNMs`,
      project: "cscore.tnn",
      data: {
        capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, loaicongtrinh, giatri, ngaykytu, ngaykyden, namcapphep, giayphep_sort, sort, mucdichsd, congsuat, luuluongkt, namhethan, madvhc, roledvhc, nguonkhaithac, iddvtd
      }
    });
  };

  // TODO: Công trình nước mặt theo ID
  GetCT_GiayPhepCTNMById = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNM`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: List công trình nước mặt theo ID
  getCT_GiayPhepCTNMForEditing = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNM_InPut`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: Tạo mới cấp phép CTNM
  create_CTNM_GiayPhep = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCTNM_GiayPhep`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };
  // TODO: Cập nhật cấp phép
  putCTNM_GiayPhep = async ({ token, id, tralai, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_CapPhepTNN/PutCTNM_GiayPhep?id=${id}&tralai=${tralai}`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };
  // TODO: Xóa cấp phép
  deleteCT_GiayPhepCongTrinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CT_CapPhepTNN/${id}`,
      project: "cscore.tnn"
    });
  };
  /*
   * TODO: =============== CP Khai Thác NDD ============================================*/
  // TODO: List
  getCT_GiayPhepKTCTNDDs = async ({ token, capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepKTCTNDDs`,
      project: "cscore.tnn",
      data: {
        capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd
      }
    });
  };

  // TODO: Công trình nước mặt theo ID
  getCT_GiayPhepCTNDDById = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNDD`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: List công trình nước mặt theo ID
  getCT_GiayPhepCTKTNDDForEditing = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNDD_InPut`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: Tạo mới cấp phép NDD
  create_KTCTNDD_GiayPhep = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCTNDD_GiayPhep`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };

  // TODO: Cập nhật cấp phép NDD
  putKTCTNDD_GiayPhep = async ({ token, id, tralai, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_CapPhepTNN/PutCTNDD_GiayPhep?id=${id}&tralai=${tralai}`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };

  /*
   * TODO: =============== CP Thăm dò NDD ============================================*/
  // TODO: List
  getCT_GiayPhepCTTDNDDs = async ({ token, capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepTDCTNDDs`,
      project: "cscore.tnn",
      data: {
        capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd
      }
    });
  };
  /*
   * TODO: =============== CP Khai thác NB ============================================*/
  // TODO: List
  getCT_GiayPhepKTNBs = async ({ token, capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngayky, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepKTNBs`,
      project: "cscore.tnn",
      data: {
        capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngayky, ngaykytu, ngaykyden, mucdichsd, luuluongkt, namhethan, madvhc, roledvhc, iddvtd
      }
    });
  };

  // TODO: List công trình nước biển theo ID
  getCT_GiayPhepCTNBForEditing = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepKTNB_InPut`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: Công trình nước mặt theo ID
  getCT_GiayPhepKTNBById = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepKTNB`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };


  // TODO: Tạo mới cấp phép CTNB
  create_CTKTNB_GiayPhep = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCTKTNB_GiayPhep`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };
  // TODO: Cập nhật cấp phép CTNB
  putCTKTNB_GiayPhep = async ({ token, id, tralai, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_CapPhepTNN/PutCTNB_GiayPhep?id=${id}&tralai=${tralai}`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };

  /*
  * TODO: =============== CP_CTXT ============================================*/
  getCT_GiayPhepXT = async ({ token, capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, luuluongnuocthai, loaihinhnuocthai, namhethan, madvhc, roledvhc, nguontiepnhan, iddvtd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepXTs`,
      project: "cscore.tnn",
      data: {
        capthamquyen, cauhinhhl, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, giatri, ngaykytu, ngaykyden, luuluongnuocthai, loaihinhnuocthai, namhethan, madvhc, roledvhc, nguontiepnhan, iddvtd
      }
    });
  };
  // TODO: List tram qt theo ID giay phep
  GetTNN_BCDK_TramQuanTracs_ByGiayPhep = async ({ token, IdGiayPhep, DoiTuongQT, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BCDK_TramQuanTrac/GetByGiayPhep`,
      data: {
        IdGiayPhep, DoiTuongQT, page, size
      },
      project: "cscore.tnn"
    });
  };
  // TODO: List CTXT theo ID
  getCT_GiayPhepXTForEditing = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepXT_InPut`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };
  GetTPMT_Exist = async ({ token, id_giayphep, id_tramqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/TPMT_Exist`,
      data: { id_giayphep, id_tramqt },
      project: "cscore.tnn"
    });
  };
  // TODO: Thông tin chi tiết XT
  getCT_GiayPhepXTById = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepXT`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };


  // TODO: Tạo mới cấp phép CTXT
  create_CPXT_GiayPhep = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCTXT_GiayPhep`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };
  // TODO: Cập nhật cấp phép CTXT
  putCTXT_GiayPhep = async ({ token, id, tralai, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_CapPhepTNN/PutCTXT_GiayPhep?id=${id}&tralai=${tralai}`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };

  // TODO: Danh sách quy chuẩn áp dụng
  getQT_DMQCVN_TNNs = async ({ token, doituongqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN`,
      data: { doituongqt },
      project: "cscore.tnn"
    });
  };

  // TODO: Cột A/B
  getQT_MucDichSuDungs = async ({ token, IdDM_QCVN } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_MucDichSuDung`,
      data: { IdDM_QCVN },
      project: "cscore.tnn"
    });
  };

  // TODO: Khởi tạo giới hạn chất lượng nước xả thải
  postCLNT_GioiHanThongSo_Init = async ({ token, auto, mdsd_id, kq, kf, k, obj } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/Init_ChatLuongNuocThai?auto=${auto}&mdsd_id=${mdsd_id}&kq=${kq}&kf=${kf}&k=${k}`,
      data: obj,
      project: "cscore.tnn"
    });
  };

  // TODO: Khởi tạo giới hạn thông số
  get_CauHinhThongSo_Init = async ({ token, id_qcvn } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/Init_GSKTSD_NuocMat`,
      data: { id_qcvn },
      project: "cscore.tnn"
    });
  };

  // TODO: Khởi tạo giới hạn thông số
  get_ChatLuongNM_Init = async ({ token, id_qcvn, id_mdsd } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/Init_ChatLuongNuocMat`,
      data: { id_qcvn, id_mdsd },
      project: "cscore.tnn"
    });
  };

  /*
* TODO: =============== CP_HNKNDD ============================================*/
  // TODO: phê duyệt giấy phép
  PutPheDuyetGiayPhep = async ({ token, sogiayphep, ngaycapphep } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CT_CapPhepTNN/PutPheDuyetGiayPhep?sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
      project: "cscore.tnn"
    });
  };
  getCP_CapPhepHanhNghes = async ({ token,ngaykytu, ngaykyden, cauhinhhl, madvhc, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, namhethan, roledvhc, iddvtd, capthamquyen } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CP_CapPhepHanhNgheTNN/GetCP_CapPhepHanhNghes`,
      project: "cscore.tnn",
      data: {
        ngaykytu, ngaykyden, cauhinhhl, madvhc, strSearch, page, size, hieuluc, namcapphep, giayphep_sort, sort, namhethan, roledvhc, iddvtd, capthamquyen
      }
    });
  };

  // TODO: List CTXT theo ID
  getCT_CapPhepHanhNgheForEditing = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CP_CapPhepHanhNgheTNN/GetCP_CapPhepHanhNghe`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: Thông tin chi tiết HNNDD
  getCT_GiayPhepHanhNgheNDDById = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CP_CapPhepHanhNgheTNN/GetCP_CapPhepHanhNghe`,
      data: {
        idgiayphep
      },
      project: "cscore.tnn"
    });
  };

  // TODO: Tạo mới cấp phép HNNDD
  create_CapPhepHanhNgheTNN_GiayPhep = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CP_CapPhepHanhNgheTNN/PostCP_CapPhepHanhNgheTNN`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };

  // TODO: Cập nhật cấp phép HNNDD
  putCP_CapPhepHanhNghe = async ({ token, id, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `CP_CapPhepHanhNgheTNN/PutCP_CapPhepHanhNghe?id=${id}`,
      data: { ...data },
      project: "cscore.tnn"
    });
  };
  // TODO: Xóa cấp phép HNNDD
  DeleteCP_CapPhepHanhNghe = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CP_CapPhepHanhNgheTNN/${id}`,
      project: "cscore.tnn"
    });
  };

  /*TODO: Sông với cả suổi*/

  getDMSongTQ = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_SongToanQuoc`,
      data: { strSearch, page, size },
      project: "cscore.tnn",
    });
  };

  getDMTenSongTQ = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_SongToanQuoc/TenSong`,
      data: { strSearch, page, size },
      project: "cscore.tnn",
    });
  };
  GetDM_SongToanQuoc = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_SongToanQuoc/${id}`,
      project: "cscore.tnn",
    });
  };

  /*TODO: ============================ Thống kê ===============================*/
  //thong ke
  getBieu01 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu01?cache=${cache}&kybaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getBieu0203 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu0203_TT20?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getBieu0205 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu0205_TT20?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getBieu0206 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu0206_TT20?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };

  getBieu09 = async ({ token, cache, kybaocao, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu09`,
      data: { cache, kybaocao, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };

  getBieu10 = async ({ token, cache, year, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu10`,
      data: { cache, year, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };

  getBieu11 = async ({ token, cache, year, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu11`,
      data: { cache, year, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };

  getBieu16 = async ({ token, cache, kybaocao, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu16`,
      data: { cache, kybaocao, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };
  getBieu17 = async ({ token, cache, kybaocao, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu17_tt31`,
      data: { cache, kybaocao, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };
  getBieu19 = async ({ token, kybaocao, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu19_tt31`,
      data: { kybaocao, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };
  getBieu15 = async ({ token, cache, nambaocao, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu15TT31`,
      data: { cache, nambaocao, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };
  getBieu20 = async ({ token, cache, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu20_TT31`,
      data: { cache, madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };

  getBieu06_TT31 = async ({ token, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu06_tt31`,
      data: { madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };
  getBieu12_TT31 = async ({ token, madvhc, capbieu, roledvhc } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Bieu12_tt31`,
      data: { madvhc, capbieu, roledvhc },
      project: "cscore.tnn"
    });
  };

  getPhuLuc02 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `phuluc02?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getPhuLuc03 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `phuluc03?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getPhuLuc05 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `phuluc05?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getPhuLuc06 = async ({ token, cache, year } = {}) => {
    return new APIService({ token: token }).getData({
      path: `phuluc06?cache=${cache}&nambaocao=${year}`,
      project: "cscore.tnn"
    });
  };
  getTK_GP_SO = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ThongKeGiayPhep_so`,
      project: "cscore.tnn"
    });
  };
  DownloadFileTK_GP_So = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ThongKeGiayPhep_so/download`,
      project: "cscore.tnn",
      fileName: "ThongKeGiayPhepCapSo.xlsx"
    });
  }
  DownloadFileKyBaoCao = async ({ token, controller, year, nameFile, madvhc, capbieu, roledvhc } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: controller + `/download?kybaocao=${year}&madvhc=${madvhc}&capbieu=${capbieu}&roledvhc=${roledvhc}`,
      project: "cscore.tnn",
      fileName: nameFile
    });
  }



  DownloadFileTT = async ({ token, cache, controller, year, nameFile, madvhc, capbieu, roledvhc } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: controller + `/download?cache=${cache}&nambaocao=${year}&madvhc=${madvhc}&capbieu=${capbieu}&roledvhc=${roledvhc}`,
      project: "cscore.tnn",
      fileName: nameFile
    });
  }

  DownloadLog = async ({ token, cache, searchText, from, to } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `log/download?cache=${cache}&searchText=${searchText}&from=${from}&to=${to}`,
      project: "cscore.tnn",
      fileName: "DS_Log.xlsx"
    });
  }


  DownloadFile = async ({ token, controller, year, nameFile, madvhc, capbieu, roledvhc } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: controller + `/download?nambaocao=${year}&madvhc=${madvhc}&capbieu=${capbieu}&roledvhc=${roledvhc}`,
      project: "cscore.tnn",
      fileName: nameFile
    });
  }
  DownloadFile_TT31 = async ({ token, controller, nameFile, madvhc, capbieu, roledvhc } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: controller + `/download?madvhc=${madvhc}&capbieu=${capbieu}&roledvhc=${roledvhc}`,
      project: "cscore.tnn",
      fileName: nameFile
    });
  }

  // DownloadFile = async ({ token, controller, year, nameFile } = {}) => {
  //   return fetch(API_TNN_URI + controller + `/download?nambaocao=${year}`, {
  //     method: 'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token
  //     },
  //     responseType: 'blob',
  //   })
  //     .then((response) => {
  //       return response.blob()
  //     })
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', nameFile);
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  // }
  DownloadFileDanhGiaTNN = async ({ token, idMauQT, idDMQCVN } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `QT_DanhGia_TNN/download?idMauQT=${idMauQT}&idDMQCVN=${idDMQCVN}`,
      project: "cscore.tnn",
      fileName: 'Bieu_DanhGia_MauQT_' + idMauQT + '_' + idDMQCVN + '.xlsx'
    });
  }


  DownloadFileHDSD = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).postDataDown({
      path: `HDSD/download`,
      project: "cscore.tnn",
      fileName: "HDSD.pdf",
    });
  }


  DownloadFileGPXT = async ({ token, capthamquyen, cauhinhhl, ngayxuat, strSearch, hieuluc, roledvhc, namcapphep, giatri, luuluongnuocthai, namhethan, madvhc, giayphep_sort, sort, loaihinhnuocthai, ngaykytu, ngaykyden, nguontiepnhan, iddvtd } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGP/download?cache=no&capthamquyen=${capthamquyen}&cauhinhhl=${cauhinhhl}&strSearch=${strSearch}&hieuluc=${hieuluc}&roledvhc=${roledvhc}&namcapphep=${namcapphep}&giatri=${giatri}&luuluongnuocthai=${luuluongnuocthai}&namhethan=${namhethan}&madvhc=${madvhc}&giayphep_sort=${giayphep_sort}&sort=${sort}&loaihinhnuocthai=${loaihinhnuocthai}&ngaykytu=${ngaykytu}&ngaykyden=${ngaykyden}&nguontiepnhan=${nguontiepnhan}&iddvtd=${iddvtd}`,
      project: "cscore.tnn",
      fileName: `gp_xnt_${ngayxuat}.xlsx`
    });
  }

  DownloadFileGPNB = async ({ token, cauhinhhl, ngaykytu, ngaykyden, capthamquyen, ngayxuat, strSearch, hieuluc, roledvhc, namcapphep, giayphep_sort, sort, giatri, luuluongkt, namhethan, madvhc, mucdichsd, iddvtd } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGPNuocBien/download?cache=no&cauhinhhl=${cauhinhhl}&ngaykytu=${ngaykytu}&ngaykyden=${ngaykyden}&capthamquyen=${capthamquyen}&strSearch=${strSearch}&hieuluc=${hieuluc}&mucdichsd=${mucdichsd}&roledvhc=${roledvhc}&namcapphep=${namcapphep}&giayphep_sort=${giayphep_sort}&sort=${sort}&giatri=${giatri}&luuluongkt=${luuluongkt}&namhethan=${namhethan}&madvhc=${madvhc}&iddvtd=${iddvtd}`,
      project: "cscore.tnn",
      fileName: `gp_nb_${ngayxuat}.xlsx`
    });
  }


  DownloadFileGPNM = async ({ token, capthamquyen, cauhinhhl, ngayxuat, strSearch, hieuluc, loaicongtrinh, roledvhc, namcapphep, giayphep_sort, sort, giatri, luuluongkt, namhethan, madvhc, mucdichsd, ngaykytu, ngaykyden, nguonkhaithac, iddvtd } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGPNuocMat/download?cache=no&capthamquyen=${capthamquyen}&cauhinhhl=${cauhinhhl}&strSearch=${strSearch}&hieuluc=${hieuluc}&loaicongtrinh=${loaicongtrinh}&mucdichsd=${mucdichsd}&roledvhc=${roledvhc}&namcapphep=${namcapphep}&giayphep_sort=${giayphep_sort}&sort=${sort}&giatri=${giatri}&luuluongkt=${luuluongkt}&namhethan=${namhethan}&madvhc=${madvhc}&ngaykytu=${ngaykytu}&ngaykyden=${ngaykyden}&nguonkhaithac=${nguonkhaithac}&iddvtd=${iddvtd}`,
      project: "cscore.tnn",
      fileName: `gp_nm_${ngayxuat}.xlsx`
    });
  }


  DownloadFileGPKTNDD = async ({ token, capthamquyen, cauhinhhl, ngayxuat, strSearch, hieuluc, roledvhc, namcapphep, giatri, luuluongkt, namhethan, madvhc, mucdichsd, giayphep_sort, sort, ngaykytu, ngaykyden, iddvtd } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGPKTNDD/download?cache=no&capthamquyen=${capthamquyen}&cauhinhhl=${cauhinhhl}&strSearch=${strSearch}&hieuluc=${hieuluc}&mucdichsd=${mucdichsd}&roledvhc=${roledvhc}&namcapphep=${namcapphep}&giatri=${giatri}&luuluongkt=${luuluongkt}&namhethan=${namhethan}&madvhc=${madvhc}&giayphep_sort=${giayphep_sort}&sort=${sort}&ngaykytu=${ngaykytu}&ngaykyden=${ngaykyden}&iddvtd=${iddvtd}`,
      project: "cscore.tnn",
      fileName: `gp_ktndd_${ngayxuat}.xlsx`
    });
  }

  DownloadFileGPTDNDD = async ({ token, ngaykytu, cauhinhhl, ngaykyden, capthamquyen, ngayxuat, strSearch, hieuluc, roledvhc, namcapphep, giatri, luuluongkt, namhethan, madvhc, mucdichsd, giayphep_sort, sort, iddvtd } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGPThamDoNDD/download?cache=no&ngaykytu=${ngaykytu}&cauhinhhl=${cauhinhhl}&ngaykyden=${ngaykyden}&capthamquyen=${capthamquyen}&strSearch=${strSearch}&hieuluc=${hieuluc}&mucdichsd=${mucdichsd}&roledvhc=${roledvhc}&namcapphep=${namcapphep}&giatri=${giatri}&luuluongkt=${luuluongkt}&namhethan=${namhethan}&madvhc=${madvhc}&giayphep_sort=${giayphep_sort}&sort=${sort}&iddvtd=${iddvtd}`,
      project: "cscore.tnn",
      fileName: `gp_tdndd_${ngayxuat}.xlsx`
    });
  }
  DownloadFileGPHNNDD = async ({ token, cauhinhhl, ngaykytu, ngaykyden, capthamquyen, ngayxuat, strSearch, hieuluc, namcapphep, namhethan, roledvhc, iddvtd, giayphep_sort, sort, } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTTGPHNNDD/download?cache=no&cauhinhhl=${cauhinhhl}&ngaykytu=${ngaykytu}&ngaykyden=${ngaykyden}&capthamquyen=${capthamquyen}&strSearch=${strSearch}&hieuluc=${hieuluc}&namcapphep=${namcapphep}&namhethan=${namhethan}&roledvhc=${roledvhc}&iddvtd=${iddvtd}&giayphep_sort=${giayphep_sort}&sort=${sort}`,
      project: "cscore.tnn",
      fileName: `gp_hnkndd_${ngayxuat}.xlsx`
    });
  }

  DownloadFileTCQ = async ({ token, strSearch, tunam, dennam } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `DanhSachTCQ/download?cache=no&strSearch=${strSearch}&tunam=${tunam}&dennam=${dennam}`,
      project: "cscore.tnn",
      fileName: 'DSTienCapQuyen.xlsx'
    });
  }

  statistic = async ({ token, chart, obj, group, unit, loaihinhgp, capnguoidung, madvhc, phase, period, from, to, capthamquyen } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Dashboard/thongke`,
      data: {chart, obj, group, unit, loaihinhgp, capnguoidung, madvhc, phase, period, from, to, capthamquyen},
      project: "cscore.tnn"
    });
  };

  tongso_gp = async ({ token, shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn, capnguoidung, madvhc, cache } = {}) => {
    return new APIService({ token: token }).getData({
      path: `Dashboard/tongso_gp`,
      data: { shhl_xt, shhl_nm, shhl_nb, shhl_ndd_kt, shhl_ndd_td, shhl_hn, capnguoidung, madvhc, cache },
      project: "cscore.tnn"
    });
  };
  GetDVXCP_ByidGiayPhep = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetDVXCP_ByidGiayPhep?idgiayphep=${idgiayphep}`,
      project: "cscore.tnn"
    });
  };
  // TODO: post trạm quan trắc theo giấy phép
  PostTNN_BCDK_TramQuanTrac = async ({ token, TramQT_Input } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNN_BCDK_TramQuanTrac`,
      data: TramQT_Input,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET tram TNN
  GetTNN_BCDK_TramQuanTracs = async ({ token, page, size, strSearch } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BCDK_TramQuanTrac`,
      project: "cscore.tnn",
      data: { page, size, strSearch }
    });
  };
  //TODO: [Common] GET tram TNN by id
  GetTNN_BCDK_TramQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BCDK_TramQuanTrac/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Put tram TNN
  PutTNN_BCDK_TramQuanTrac = async ({ token, id, TramQT_Input } = {}) => {
    return new APIService({ token: token }).putData({
      path: `TNN_BCDK_TramQuanTrac/${id}`,
      project: "cscore.tnn",
      data: TramQT_Input
    });
  };
  //TODO: [Common] Delete tram TNN
  DeleteTNN_BCDK_TramQuanTrac = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TNN_BCDK_TramQuanTrac/${id}`,
      project: "cscore.tnn",
    });
  };
  KiemTraTrangThaiGP = async ({ token, sogiayphep, ngayky } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/KiemTraTrangThaiGP`,
      data: { sogiayphep, ngayky },
      project: "cscore.tnn"
    });
  };
  PheDuyetQD_VungBVPCKP_BVPCKP = async ({ token, id, idcapphep, sogiayphep, ngaycapphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `BVPCKP_BaoCao/CapNhatQD_VungBVPCKP?id=${id}&idcapphep=${idcapphep}&sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
      project: "cscore.tnn",
    });
  };
  PheDuyetQD_VungBVPCKP_CP = async ({ token, id, idquyetdinh, soquyetdinh, ngayquyetdinh, tenquyhoach } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/PheDuyetQD_VungBVPCKP?id=${id}&idquyetdinh=${idquyetdinh}&soquyetdinh=${soquyetdinh}&ngayquyetdinh=${ngayquyetdinh}&tenquyhoach=${tenquyhoach}`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] GET quan ly khi tương thuy van
  GetTBKhiTuongThuyVans = async ({ token, strSearch, nam, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TBKhiTuongThuyVan?strSearch=${strSearch}&nam=${nam}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET  quan ly khi tương thuy van by id
  GetTBKhiTuongThuyVan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TBKhiTuongThuyVan/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post quan ly khi tương thuy van
  PostTBKhiTuongThuyVan = async ({ token, objbaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TBKhiTuongThuyVan`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };

  //TODO: [Common] Put quan ly khi tương thuy van
  PutTBKhiTuongThuyVan = async ({ token, id, objbaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `TBKhiTuongThuyVan/${id}`,
      project: "cscore.tnn",
      data: objbaocao
    });
  };
  //TODO: [Common] Delete  quan ly khi tương thuy van
  DeleteTBKhiTuongThuyVan = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `TBKhiTuongThuyVan/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET giay phep by iddoanhnghiep
  GetGpByDoanhNghiep = async ({ token, iddoanhnghiep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/getgpbydoanhnghiep?iddoanhnghiep=${iddoanhnghiep}`,
      project: "cscore.tnn",
    });
  };
  // xuất Excel tiền cấp quyền
  DownloadFileBVPCKP = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `BieuBVPCKP/download?cache=no`,
      project: "cscore.tnn",
      fileName: 'DSBaoVePCKP.xlsx'
    });
  }
  // lưu vực sông
  GetDM_LVSongs = async ({ token, page, size, strSearch, } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_LVSong`,
      data: {page, size, strSearch},
      project: "cscore.tnn"
    });
  };

  GetMauQTs_By_TramQT = async ({ token, idGiayPhep, id_TramQT, doiTuongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/MauQTs_By_TramQT`,
      data: { idGiayPhep, id_TramQT, doiTuongQT },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET bao cao dinh ky CV
  GetDOanhNghiep_BaoCaoDinhKys_CV = async ({ token, strSearch, IdGiayPhep, trangthai, kybaocao, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/GetDOanhNghiep_BaoCaoDinhKys_CV`,
      data: { strSearch, IdGiayPhep, trangthai, kybaocao, page, size },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET bao cao dinh ky DN
  GetDOanhNghiep_BaoCaoDinhKys_DN = async ({ token, strSearch, IdGiayPhep, trangthai, kybaocao, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/GetDOanhNghiep_BaoCaoDinhKys_DN`,
      data: { strSearch, IdGiayPhep, trangthai, kybaocao, page, size },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET tram qt by dn
  GetTNN_BCDK_TramQuanTracs_ByGiayPhep_Temp = async ({ token, DoiTuongQT, IdGiayPhep, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TNN_BCDK_TramQuanTrac_Temp/GetByGiayPhep`,
      data: { DoiTuongQT, IdGiayPhep, page, size },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post tram doanh nghiep bao cao
  PostTNN_BCDK_TramQuanTrac_Temp = async ({ token, TramQT_Input } = {}) => {
    return new APIService({ token: token }).postData({
      path: `TNN_BCDK_TramQuanTrac_Temp`,
      project: "cscore.tnn",
      data: TramQT_Input
    });
  };

  //Gui email
  NoiDung_Email_YeuCauBCDK = async ({ token, nhomct } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_ThongBao/NoiDung_Email_YeuCauBCDK`,
      project: "cscore.tnn",
      data: nhomct
    });
  };
  Luu_Email_YeuCauBCDK = async ({ token, nhomct, tongso } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_ThongBao/Luu_Email_YeuCauBCDK?nhomct=${nhomct}`,
      project: "cscore.tnn",
      data: tongso
    });
  };
  //TODO: [Common] Phe duyet bcdk
  PheDuyetBCDK_Temp = async ({ token, id, trangthai, comment } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/PheDuyetBCDK_Temp`,
      data: { id, trangthai, comment },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] DS giay phep by BCDK
  GetDSGiayPhepByBCDK = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/GetDSGiayPhepByBCDK`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET doanh nghiep bao cao
  GetDoanhNghiep_BaoCaos_Temp = async ({ token, idGiayPhep, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp`,
      data: { idGiayPhep, page, size },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET bcdk theo gp
  GetBCDK_By_GiayPhep_Temp = async ({ token, IdGiayPhep, Nam, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/BCDK_By_GiayPhep`,
      data: { IdGiayPhep, Nam, page, size },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET doanh nghiep bao cao trees
  Get_DoanhNghiep_BaoCao_Trees_Temp = async ({ token, strSearch, page, size, kybaocao, nhomcongtrinh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/get_trees`,
      data: { strSearch, page, size, kybaocao, nhomcongtrinh },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET bao cao by giay phep
  GetBCDK_KQQT_GiayPhep_Temp = async ({ token, IdGiayPhep, Nam, idDMQCVN, DoiTuongQT, idMDSD, IdTramQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/BCDK_KQQT_GiayPhep`,
      data: { IdGiayPhep, Nam, idDMQCVN, DoiTuongQT, idMDSD, IdTramQT },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET bao cao by thong so
  GetBCDK_KQQT_By_ThongSo_Temp = async ({ token, idThongSo, idGiayPhep, nam, idDMQCVN, doiTuongQT, idMDSD, id_TramQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/KQQT_By_ThongSo`,
      data: { idThongSo, idGiayPhep, nam, idDMQCVN, doiTuongQT, idMDSD, id_TramQT },
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET cau hinh thong so bao cao
  Get_BCDK_CauHinhTS_Temp = async ({ token, IdGiayPhep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/Get_BCDK_CauHinhTS?IdGiayPhep=${IdGiayPhep}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Post cau hinh thong so bao cao
  Post_BCDK_CauHinhTS_Temp = async ({ token, IdGiayPhep, cauhinh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao_Temp/Post_BCDK_CauHinhTS?IdGiayPhep=${IdGiayPhep}`,
      project: "cscore.tnn",
      data: cauhinh
    });
  };
  GetQT_KQQT_By_QCVN_Init_dnbc_Temp = async ({ token, idgiayphep, iddmqcvn } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/khoitao_kqqt_by_qcvn`,
      data: { idgiayphep, iddmqcvn },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET doanh nghiep bao cao
  GetDoanhNgiep_BaoCao_Temp = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao_Temp/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Post doanh nghiep bao cao
  PostDoanhNghiep_BaoCao_Temp = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao_Temp`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };

  //TODO: [Common] Put doanh nghiep bao cao
  PutDoanhNghiep_BaoCao_Temp = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DoanhNghiep_BaoCao_Temp/${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };

  //TODO: [Common] Delete doanh nghiep bao cao
  DeleteDoanhNgiep_BaoCao_Temp = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DoanhNghiep_BaoCao_Temp/${id}`,
      project: "cscore.tnn"
    });
  };
  // TODO:deleta file
  DeleteExcelKQQT_Temp = async ({ token, filename } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao_Temp/DeleteExcelKQQT?filename=${filename}`,
      project: "cscore.tnn"
    });
  };
  // TODO: UPLOAD FILE 
  UploadFile_Temp = async ({ token, data } = {}) => {
    return new APIServiceFile({ token: token }).postDataUp({
      path: `DoanhNghiep_BaoCao_Temp/UploadExcelKQQT`,
      project: "cscore.tnn",
      data: data,
    });
  }
  // TODO: get thiet lap mau 
  GetExcelFromThietLapConvert_Temp = async ({ token, sheetname, cot_mau_kyhieu, cot_mau_ten, cot_mau_tinhtrang, cot_mau_mota, cot_donvithuchien, cot_nguoilaymaus, cot_nguoigiamsats, cot_ngaylaymau, cot_ngaynhanmau, cot_ngayphantich, cot_dotQuanTracSo, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitriquantrac_diadiem, cot_vitriquantrac_x, cot_vitriquantrac_y, cot_luuluong, cot_nguonnuoc, cot_thongsodautien, list_thongso } = {}) => {
    return new APIServiceFile({ token: token }).postDataDown({
      path: `DoanhNghiep_BaoCao_Temp/GetExcelFromThietLapConvert?sheetname=${sheetname}&cot_mau_kyhieu=${cot_mau_kyhieu}&cot_mau_ten=${cot_mau_ten}&cot_mau_tinhtrang=${cot_mau_tinhtrang}&cot_mau_mota=${cot_mau_mota}&cot_donvithuchien=${cot_donvithuchien}&cot_nguoilaymaus=${cot_nguoilaymaus}&cot_nguoigiamsats=${cot_nguoigiamsats}&cot_ngaylaymau=${cot_ngaylaymau}&cot_ngaynhanmau=${cot_ngaynhanmau}&cot_ngayphantich=${cot_ngayphantich}&cot_dotQuanTracSo=${cot_dotQuanTracSo}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_luuluong=${cot_luuluong}&cot_nguonnuoc=${cot_nguonnuoc}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso,
      fileName: 'Mau_Convert_KQQT.xlsx'
    });
  }
  // TODO: CV Excel
  ConvertExcelKQQT_Temp = async ({ token, idgiayphep, filename, sheetname, dong_cmax, dongbatdau, dongketthuc, cot_mau_kyhieu, cot_mau_ten, cot_mau_tinhtrang, cot_mau_mota, cot_donvithuchien, cot_nguoilaymaus, cot_nguoigiamsats, cot_ngaylaymau, cot_ngaynhanmau, cot_ngayphantich, cot_dotQuanTracSo, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitriquantrac_diadiem, cot_vitriquantrac_x, cot_vitriquantrac_y, cot_luuluong, cot_nguonnuoc, cot_thongsodautien, list_thongso, kyhieuBoQua, kyhieuLonHon, kyHieuNhoHon } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao_Temp/ConvertExcelKQQT?idgiayphep=${idgiayphep}&filename=${filename}&dong_cmax=${dong_cmax}&dongbatdau=${dongbatdau}&dongketthuc=${dongketthuc}&sheetname=${sheetname}&cot_mau_kyhieu=${cot_mau_kyhieu}&cot_mau_ten=${cot_mau_ten}&cot_mau_tinhtrang=${cot_mau_tinhtrang}&cot_mau_mota=${cot_mau_mota}&cot_donvithuchien=${cot_donvithuchien}&cot_nguoilaymaus=${cot_nguoilaymaus}&cot_nguoigiamsats=${cot_nguoigiamsats}&kyhieuBoQua=${kyhieuBoQua}&kyhieuLonHon=${kyhieuLonHon}&kyHieuNhoHon=${kyHieuNhoHon}&cot_ngaylaymau=${cot_ngaylaymau}&cot_ngaynhanmau=${cot_ngaynhanmau}&cot_ngayphantich=${cot_ngayphantich}&cot_dotQuanTracSo=${cot_dotQuanTracSo}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_luuluong=${cot_luuluong}&cot_nguonnuoc=${cot_nguonnuoc}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso
    });
  };

  GetQT_DMQCVN_MDSD_All = async ({ token } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN/DMQCVN_MDSD_All`,
      project: "cscore.tnn"
    });
  };

  GetQT_DMQCVN_MDSD = async ({ token, doituongqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QT_DMQCVN_TNN/DMQCVN_MDSD`,
      data: { doituongqt },
      project: "cscore.tnn"
    });
  };

  //De nghi cap nhat
  GetDeNghiCapNhatCPs_For_DN = async ({ token, strSearch, page, size, trangthai } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_DeNghiCapNhatCP/GetDeNghiCapNhatCPs_For_DN`,
      data: { strSearch, page, size, trangthai },
      project: "cscore.tnn"
    });
  };
  GetDeNghiCapNhatCPs_CV = async ({ token, strSearch, page, size, trangthai } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_DeNghiCapNhatCP/GetDeNghiCapNhatCPs_CV`,
      data: { strSearch, page, size, trangthai },
      project: "cscore.tnn"
    });
  };
  GetDoanhNghiep_DeNghiCapNhatCP = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_DeNghiCapNhatCP/${id}`,
      project: "cscore.tnn"
    });
  };
  PostDoanhNghiep_DeNghiCapNhatCP = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_DeNghiCapNhatCP`,
      data: data,
      project: "cscore.tnn",
    });
  };
  PutDoanhNghiep_DeNghiCapNhatCP = async ({ token, id, data } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DoanhNghiep_DeNghiCapNhatCP?id=${id}`,
      project: "cscore.tnn",
      data: data
    });
  };
  DeleteDoanhNghiep_DeNghiCapNhatCP = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DoanhNghiep_DeNghiCapNhatCP/${id}`,
      project: "cscore.tnn",
    });
  };
  // TODO: Thông tin chi tiết XT
  GetGpByDoanhNghieps = async ({ token, iddoanhnghiep, madvhc } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/getgpbydoanhnghieps?madvhc=${madvhc}`,
      data: iddoanhnghiep,
      project: "cscore.tnn"
    });
  };

  // TODO: Bieu do so lan vuot nguong
  GetBieuDo_SoLanVuotNguong = async ({ token, id_giayphep, doituongqt, id_tramqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/BieuDo_SoLanVuotNguong`,
      data: {
        id_giayphep, doituongqt, id_tramqt
      },
      project: "cscore.tnn"
    });
  };
  GetThongSos_Exist = async ({ token, id_giayphep, doituongqt, id_tramqt } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/ThongSos_Exist`,
      data: { id_giayphep, doituongqt, id_tramqt },
      project: "cscore.tnn"
    });
  };
  GetBieuDo_ThongSos_GiaTris_Timeline = async ({ token, idThongSo, idThongSo2, idGiayPhep, id_TramQT, doiTuongQT } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DoanhNghiep_BaoCao/BieuDo_ThongSos_GiaTris_Timeline`,
      data: { idThongSo, idThongSo2, idGiayPhep, id_TramQT, doiTuongQT },
      project: "cscore.tnn"
    });
  };
  // TODO:deleta file
  DeleteExcelKQQT = async ({ token, filename } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao/DeleteExcelKQQT?filename=${filename}`,
      project: "cscore.tnn"
    });
  };
  // TODO: UPLOAD FILE 
  UploadFile = async ({ token, data } = {}) => {
    return new APIServiceFile({ token: token }).postDataUp({
      path: `DoanhNghiep_BaoCao/UploadExcelKQQT`,
      project: "cscore.tnn",
      data: data,
    });
  }
  // TODO: get thiet lap mau 
  GetExcelFromThietLapConvert = async ({ token, sheetname, cot_mau_kyhieu, cot_mau_ten, cot_mau_tinhtrang, cot_mau_mota, cot_donvithuchien, cot_nguoilaymaus, cot_nguoigiamsats, cot_ngaylaymau, cot_ngaynhanmau, cot_ngayphantich, cot_dotQuanTracSo, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitriquantrac_diadiem, cot_vitriquantrac_x, cot_vitriquantrac_y, cot_luuluong, cot_nguonnuoc, cot_thongsodautien, list_thongso } = {}) => {
    return new APIServiceFile({ token: token }).postDataDown({
      path: `DoanhNghiep_BaoCao/GetExcelFromThietLapConvert?sheetname=${sheetname}&cot_mau_kyhieu=${cot_mau_kyhieu}&cot_mau_ten=${cot_mau_ten}&cot_mau_tinhtrang=${cot_mau_tinhtrang}&cot_mau_mota=${cot_mau_mota}&cot_donvithuchien=${cot_donvithuchien}&cot_nguoilaymaus=${cot_nguoilaymaus}&cot_nguoigiamsats=${cot_nguoigiamsats}&cot_ngaylaymau=${cot_ngaylaymau}&cot_ngaynhanmau=${cot_ngaynhanmau}&cot_ngayphantich=${cot_ngayphantich}&cot_dotQuanTracSo=${cot_dotQuanTracSo}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_luuluong=${cot_luuluong}&cot_nguonnuoc=${cot_nguonnuoc}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso,
      fileName: 'Mau_Convert_KQQT.xlsx'
    });
  }
  // TODO: CV Excel
  ConvertExcelKQQT = async ({ token, idgiayphep, filename, sheetname, dong_cmax, dongbatdau, dongketthuc, cot_mau_kyhieu, cot_mau_ten, cot_mau_tinhtrang, cot_mau_mota, cot_donvithuchien, cot_nguoilaymaus, cot_nguoigiamsats, cot_ngaylaymau, cot_ngaynhanmau, cot_ngayphantich, cot_dotQuanTracSo, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitriquantrac_diadiem, cot_vitriquantrac_x, cot_vitriquantrac_y, cot_luuluong, cot_nguonnuoc, cot_thongsodautien, list_thongso, kyhieuBoQua, kyhieuLonHon, kyHieuNhoHon } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DoanhNghiep_BaoCao/ConvertExcelKQQT?idgiayphep=${idgiayphep}&filename=${filename}&dong_cmax=${dong_cmax}&dongbatdau=${dongbatdau}&dongketthuc=${dongketthuc}&sheetname=${sheetname}&cot_mau_kyhieu=${cot_mau_kyhieu}&cot_mau_ten=${cot_mau_ten}&cot_mau_tinhtrang=${cot_mau_tinhtrang}&cot_mau_mota=${cot_mau_mota}&cot_donvithuchien=${cot_donvithuchien}&cot_nguoilaymaus=${cot_nguoilaymaus}&cot_nguoigiamsats=${cot_nguoigiamsats}&kyhieuBoQua=${kyhieuBoQua}&kyhieuLonHon=${kyhieuLonHon}&kyHieuNhoHon=${kyHieuNhoHon}&cot_ngaylaymau=${cot_ngaylaymau}&cot_ngaynhanmau=${cot_ngaynhanmau}&cot_ngayphantich=${cot_ngayphantich}&cot_dotQuanTracSo=${cot_dotQuanTracSo}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_luuluong=${cot_luuluong}&cot_nguonnuoc=${cot_nguonnuoc}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso
    });
  };

  GetDSGiayPhep_ByNguonNuoc = async ({ token, idsong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_NguonNuocNoiTinh/GetDSGiayPhep_ByNguonNuoc`,
      data: { idsong },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Download nguồn nước
  Download_NguonNuoc = async ({ token, strSearch, phanloainguonnuoc, mahuyen, maxa } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `BieuNguonNuocNoiTinh/download?strSearch=${strSearch}&phanloainguonnuoc=${phanloainguonnuoc}&mahuyen=${mahuyen}&maxa=${maxa}`,
      project: "cscore.tnn",
      fileName: 'BieuNguonNuocNoiTinh.xlsx'
    });
  };
  GetDM_NguonNuocNoiTinhByMa = async ({ token, masong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_NguonNuocNoiTinh/GetDM_NguonNuocNoiTinh`,
      data: { masong },
      project: "cscore.tnn"
    });
  };
  GetDM_SongToanQuoc_maSong = async ({ token, masong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_SongToanQuoc/GetDM_SongToanQuoc_ByMaSong`,
      data: { masong },
      project: "cscore.tnn"
    });
  };

  GetDSGiayPhep_ByMaNguonNuoc = async ({ token, masong } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_NguonNuocNoiTinh/GetDSGiayPhep_ByMaNguonNuoc`,
      data: { masong },
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Download doanh nghiep
  Download_KQTimKiem_By_DoanhNghiep = async ({ token, dulieu_doanhnghiep } = {}) => {
    return new APIServiceFile({ token: token }).postDataDown({
      path: `DuLieu_DoanhNghiep/Download_KQTimKiem_By_DoanhNghiep`,
      data: dulieu_doanhnghiep,
      project: "cscore.tnn",
      fileName: 'KQTimKiem_DoanhNghiep.xlsx'
    });
  };
  //TODO: [Common] GET thong tin lien he
  GetDM_ThongTinLienHes = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_ThongTinLienHe`,
      data: { strSearch, page, size },
      project: "cscore.tnn"
    });
  }

  //TODO: [Common] GET thong tin lien he by id
  GetDM_ThongTinLienHe = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `DM_ThongTinLienHe/${id}`,
      project: "cscore.tnn"
    });
  }

  //TODO: [Common] Post thong tin lien he 
  PostDM_ThongTinLienHe = async ({ token, dmtt } = {}) => {
    return new APIService({ token: token }).postData({
      path: `DM_ThongTinLienHe`,
      project: "cscore.tnn",
      data: dmtt
    });
  };
  //TODO: [Common] Put thong tin lien he
  PutDM_ThongTinLienHe = async ({ token, id, dmtt } = {}) => {
    return new APIService({ token: token }).putData({
      path: `DM_ThongTinLienHe/${id}`,
      project: "cscore.tnn",
      data: dmtt
    });
  };
  //TODO: [Common] Delete thong tin lien he
  DeleteDM_ThongTinLienHe = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `DM_ThongTinLienHe/${id}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] GET thu tục hành chính
  GetQD_ThuTucHanhChinhs = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QD_ThuTucHanhChinh`,
      data: { strSearch, page, size },
      project: "cscore.tnn"
    });
  }

  //TODO: [Common] GET thu tục hành chính by id
  GetQD_ThuTucHanhChinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QD_ThuTucHanhChinh/${id}`,
      project: "cscore.tnn"
    });
  }

  //TODO: [Common] Post thu tục hành chính
  PostQD_ThuTucHanhChinh = async ({ token, objBaocao } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QD_ThuTucHanhChinh`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Put thu tục hành chính
  PutQD_ThuTucHanhChinh = async ({ token, id, objBaocao } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QD_ThuTucHanhChinh/PutQD_ThuTucHanhChinh?id=${id}`,
      project: "cscore.tnn",
      data: objBaocao
    });
  };
  //TODO: [Common] Delete thu tục hành chính
  DeleteQD_ThuTucHanhChinh = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QD_ThuTucHanhChinh/${id}`,
      project: "cscore.tnn"
    });
  };
  // TODO:deleta file
  DeleteExcelKQQT_CLN = async ({ token, filename } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_KQQT_Tram/DeleteExcelKQQT?filename=${filename}`,
      project: "cscore.tnn"
    });
  };
  // TODO: UPLOAD FILE 
  UploadFile_CLN = async ({ token, data } = {}) => {
    return new APIServiceFile({ token: token }).postDataUp({
      path: `QT_KQQT_Tram/UploadExcelKQQT`,
      project: "cscore.tnn",
      data: data,
    });
  }
  // TODO: get thiet lap mau 
  GetExcelFromThietLapConvert_CLN = async ({ token, sheetname, cot_nam, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitri_kyhieu, cot_vitriquantrac_diadiem, cot_vitriquantrac_x, cot_vitriquantrac_y, cot_thongsodautien, list_thongso_donvi } = {}) => {
    return new APIServiceFile({ token: token }).postDataDown({
      path: `QT_KQQT_Tram/GetExcelFromThietLapConvert?sheetname=${sheetname}&cot_nam=${cot_nam}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitri_kyhieu=${cot_vitri_kyhieu}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso_donvi,
      fileName: 'Mau_Convert_KQQT.xlsx'
    });
  }
  // TODO: CV Excel
  ConvertExcelKQQT_CLN = async ({ token, dtqt, filename, sheetname, dongbatdau, dongketthuc, cot_nam, cot_dotQuanTrac_Ten, cot_vitriquantrac, cot_vitri_kyhieu, cot_vitriquantrac_diadiem,
    cot_vitriquantrac_x, cot_vitriquantrac_y, cot_thongsodautien, kyhieuBoQua, kyhieuLonHon, kyHieuNhoHon, list_thongso_donvi } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QT_KQQT_Tram/ConvertExcelKQQT?dtqt=${dtqt}&filename=${filename}&sheetname=${sheetname}&dongbatdau=${dongbatdau}&dongketthuc=${dongketthuc}&kyhieuBoQua=${kyhieuBoQua}&kyhieuLonHon=${kyhieuLonHon}&kyHieuNhoHon=${kyHieuNhoHon}&cot_nam=${cot_nam}&cot_dotQuanTrac_Ten=${cot_dotQuanTrac_Ten}&cot_vitriquantrac=${cot_vitriquantrac}&cot_vitri_kyhieu=${cot_vitri_kyhieu}&cot_vitriquantrac_diadiem=${cot_vitriquantrac_diadiem}&cot_vitriquantrac_x=${cot_vitriquantrac_x}&cot_vitriquantrac_y=${cot_vitriquantrac_y}&cot_thongsodautien=${cot_thongsodautien}`,
      project: "cscore.tnn",
      data: list_thongso_donvi
    });
  };

  //TODO: [Common] Download lich su giay phep NDD
  GetCT_GiayPhepCTNDD = async ({ token, idgiayphep, xuatexcel, fileName } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNDD?idgiayphep=${idgiayphep}&xuatexcel=${xuatexcel}`,
      project: "cscore.tnn",
      fileName: fileName
    });
  };
  //TODO: [Common] Download lich su giay phep XT
  GetCT_GiayPhepXT = async ({ token, idgiayphep, xuatexcel, fileName } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `CT_CapPhepTNN/GetCT_GiayPhepXT?idgiayphep=${idgiayphep}&xuatexcel=${xuatexcel}`,
      project: "cscore.tnn",
      fileName: fileName
    });
  };
  //TODO: [Common] Download lich su giay phep NM
  GetCT_GiayPhepCTNM = async ({ token, idgiayphep, xuatexcel, fileName } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCTNM?idgiayphep=${idgiayphep}&xuatexcel=${xuatexcel}`,
      project: "cscore.tnn",
      fileName: fileName
    });
  };
  //TODO: [Common] Download lich su giay phep NB
  GetCT_GiayPhepKTNB = async ({ token, idgiayphep, xuatexcel, fileName } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `CT_CapPhepTNN/GetCT_GiayPhepKTNB?idgiayphep=${idgiayphep}&xuatexcel=${xuatexcel}`,
      project: "cscore.tnn",
      fileName: fileName
    });
  };
  //TODO: [Common] Download lich su giay phep NB
  GetCP_CapPhepHanhNghe = async ({ token, idgiayphep, xuatexcel, fileName } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `CP_CapPhepHanhNgheTNN/GetCP_CapPhepHanhNghe?idgiayphep=${idgiayphep}&xuatexcel=${xuatexcel}`,
      project: "cscore.tnn",
      fileName: fileName
    });
  };

  //TODO: [Common] Get token
  GenToken = async ({ token } = {}) => {
    return new APIService({ token: token }).postData({
      path: `GiamSat/token`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Get token lgsp
  GenToken_LGSP = async ({ token } = {}) => {
    return new APIService({ token: token }).postData({
      path: `GiamSat/lgsp_token`,
      project: "cscore.tnn",
    });
  };

  //TODO: [Common] GET luu tam
  GetCT_GiayPhepCongTrinh_Temp = async ({ token, controller } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCongTrinh_Temp?controller=${controller}`,
      project: "cscore.tnn"
    });
  }

  //TODO: [Common] Post luu tam
  PostCT_GiayPhepCongTrinh_Temp = async ({ token, temp } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCT_GiayPhepCongTrinh_Temp`,
      project: "cscore.tnn",
      data: temp
    });
  };

  //TODO: [Common] Delete luu tam
  DeleteCT_GiayPhepCongTrinh_Temp = async ({ token, controller } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `CT_CapPhepTNN/DeleteCT_GiayPhepCongTrinh_Temp?controller=${controller}`,
      project: "cscore.tnn"
    });
  };
  //lich su
  PostCT_GiayPhepCongTrinh_LichSu = async ({ token, lichsu } = {}) => {
    return new APIService({ token: token }).postData({
      path: `CT_CapPhepTNN/PostCT_GiayPhepCongTrinh_LichSu`,
      project: "cscore.tnn",
      data: lichsu
    });
  };

  GetCT_GiayPhepCongTrinh_LichSu = async ({ token, idgiayphep } = {}) => {
    return new APIService({ token: token }).getData({
      path: `CT_CapPhepTNN/GetCT_GiayPhepCongTrinh_LichSu?idgiayphep=${idgiayphep}`,
      project: "cscore.tnn"
    });
  }

  //Import json
  //TODO: [Common] 
  Import_GiayPhepHanhNghe_NDD_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_hanhnghe_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //TODO: [Common] 
  Import_GiayPhepKhaiThac_NDD_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_khaithacndd_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //TODO: [Common] 
  Import_GiayPhepNB_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_nuocbien_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //TODO: [Common] 
  Import_GiayPhepNM_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_nuocmat_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //TODO: [Common] 
  Import_GiayPhepThamDo_NDD_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_thamdondd_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //TODO: [Common] 
  Import_GiayPhepXNT_Json = async ({ token, data } = {}) => {
    return new APIService({ token: token }).postData({
      path: `ketnoi/import_giayphep_xanuocthai_json`,
      project: "cscore.tnn",
      data: data
    });
  };
  //Download mau excel
  //TODO: [Common] 
  Download_MauExcel_HanhNghe = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_hanhnghe`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_HanhNghe.xlsx'
    });
  };
  //TODO: [Common] 
  Download_MauExcel_KTNDD = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_ktndd`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_KTNDD.xlsx'
    });
  };
  //TODO: [Common] 
  Download_MauExcel_NB = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_nb`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_NuocBien.xlsx'
    });
  };
  //TODO: [Common] 
  Download_MauExcel_NM = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_nm`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_NuocMat.xlsx'
    });
  };
  //TODO: [Common] 
  Download_MauExcel_TDNDD = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_tdndd`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_TDNDD.xlsx'
    });
  };
  //TODO: [Common] 
  Download_MauExcel_XT = async ({ token } = {}) => {
    return new APIServiceFile({ token: token }).getDataDown({
      path: `ketnoi/download_excel_xt`,
      project: "cscore.tnn",
      fileName: 'ConvertGP_XaThai.xlsx'
    });
  };
  //conver excel
  // TODO: 
  ConvertGP_HanhNgheFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_hanhnghefromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  // TODO: 
  ConvertGP_KTNDDFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_ktnddfromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  // TODO: 
  ConvertGP_NuocBienFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_nuocbienfromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  // TODO: 
  ConvertGP_NuocMatFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_nuocmatfromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  // TODO: 
  ConvertGP_TDNDDFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_tdnddfromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  // TODO: 
  ConvertGP_XaThaiFromXLSX = async ({ token, fileName, sheet, dong_ketthuc, dong_batdau } = {}) => {
    return new APIService({ token: token }).getData({
      path: `ketnoi/convertgp_xathaifromxlsx`,
      project: "cscore.tnn",
      data: { fileName, sheet, dong_ketthuc, dong_batdau }
    });
  };
  
  //TODO: [Common] GET QLKN TNN
  GetQLKNS_Data = async ({ token, page, size, strSearch, maTinh,isFirst_Api } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QuanLyApi`,
      data: {  page, size, strSearch, maTinh,isFirst_Api},
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET QLKN TNN
  GetQLKN_Data = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `QuanLyApi/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Post QLKN TNN
  PostQLKN_Data = async ({ token, objQuanlyketnoi } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QuanLyApi`,
      project: "cscore.tnn",
      data: objQuanlyketnoi
    });
  };
  //TODO: [Common] Post DBAPI TNN
  PostDbApi_Data = async ({ token, id } = {}) => {
    return new APIService({ token: token }).postData({
      path: `QuanLyApi/DongBo_Api?id=${id}`,
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] Put QLKN TNN
  PutQLKN_Data = async ({ token, id, objQuanlyketnoi } = {}) => {
    return new APIService({ token: token }).putData({
      path: `QuanLyApi/${id}`,
      project: "cscore.tnn",
      data: objQuanlyketnoi
    });
  };
  //TODO: [Common] Delete QLKN TNN
  DeleteQLKN_Data = async ({ token, id } = {}) => {
    return new APIService({ token: token }).deleteData({
      path: `QuanLyApi/${id}`,
      project: "cscore.tnn",
    });
  };
   //TODO: [Common] GET QLLSKN TNN
   GetQLLSKNS_Data = async ({ token, page, size, strSearch, maTinh, from,to,status} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TrangThaiApi`,
      data: {  page, size, strSearch, maTinh, from, to,status},
      project: "cscore.tnn"
    });
  };
  //TODO: [Common] GET QLLSKNER TNN
  GetQLLSKNERS_Data = async ({ token, page, size, strSearch, maTinh,from,id} = {}) => {
    return new APIService({ token: token }).getData({
      path: `TrangThaiApi/Error`,
      data: {  page, size, strSearch, maTinh,from,id},
      project: "cscore.tnn"
    });
  };
   //TODO: [Common] GET QLKN TNN
   GetQLLSKN_Data = async ({ token, id } = {}) => {
    return new APIService({ token: token }).getData({
      path: `TrangThaiApi/${id}`,
      project: "cscore.tnn",
    });
  };
  //TODO: [Common] Tờ khai công trình nước mặt
  GetListTKDK_CongTrinhKhaiThacNuocMat = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkcongtrinhkhaithacnuocmat/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhKhaiThacNuocMatById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkcongtrinhkhaithacnuocmat/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateTKDK_CongTrinhKhaiThacNuocMat = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkcongtrinhkhaithacnuocmat-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteTKDK_CongTrinhKhaiThacNuocMat = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkcongtrinhkhaithacnuocmat/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

   //TODO: [Common] Sử dụng nước mặt
   GetListTKDK_SuDungNuocMat = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdksudungnuocmat/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_SuDungNuocMatById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdksudungnuocmat/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateTKDK_SuDungNuocMat = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdksudungnuocmat-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteTKDK_SuDungNuocMat = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdksudungnuocmat/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };
  // check công trình bai đập
  CheckCongTrinhTKDK_NuocMat_Dap = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/dap/checkexistence`,
      project: "cscore.tnn",
      data: dataRes
    });
  };
   // check công trình cống
   CheckCongTrinhTKDK_NuocMat_Cong = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/cong/checkexistence`,
      project: "cscore.tnn",
      data: dataRes
    });
  };
   // check công trình hồ chứa
   CheckCongTrinhTKDK_NuocMat_HoChua = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/hochua/checkexistence`,
      project: "cscore.tnn",
      data: dataRes
    });
  };
    // check công trình nhà máy nước
    CheckCongTrinhTKDK_NuocMat_NhaMayNuoc = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `congtrinhtkdk/nuocmat/nhamaynuoc/checkexistence`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
      // check công trình thủy điện
      CheckCongTrinhTKDK_NuocMat_ThuyDien = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/thuydien/checkexistence`,
      project: "cscore.tnn",
      data: dataRes
    });
  };
 
    // check công trình trạm bơm
    CheckCongTrinhTKDK_NuocMat_TramBom = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `congtrinhtkdk/nuocmat/trambom/checkexistence`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
     // check công trình trạm cấp nước
     CheckCongTrinhTKDK_NuocMat_TramCapNuoc = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `congtrinhtkdk/nuocmat/tramcapnuoc/checkexistence`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
    // Trình phê duyệt giấy phép
    PutTrinhPheDuyetGiayPhep = async ({ token, sogiayphep, ngaycapphep } = {}) => {
      return new APIService({ token: token }).putData({
        path: `CT_CapPhepTNN/PutTrinhPheDuyetGiayPhep?sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
        project: "cscore.tnn"
      });
    };
     // Từ chối phê duyệt giấy phép
     PutTuChoiPheDuyetGiayPhep = async ({ token, sogiayphep, ngaycapphep } = {}) => {
      return new APIService({ token: token }).putData({
        path: `CT_CapPhepTNN/PutTuChoiPheDuyetGiayPhep?sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
        project: "cscore.tnn"
      });
    };

      // Trình phê duyệt giấy phép hành nghề 
      PutTrinhPheDuyetGiayPhepHN = async ({ token, sogiayphep, ngaycapphep } = {}) => {
        return new APIService({ token: token }).putData({
          path: `CT_CapPhepTNN/PutTrinhPheDuyetGiayPhepHN?sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
          project: "cscore.tnn"
        });
      };
       // Từ chối phê duyệt giấy phép hành nghề
       PutTuChoiPheDuyetGiayPhepHN = async ({ token, sogiayphep, ngaycapphep } = {}) => {
        return new APIService({ token: token }).putData({
          path: `CT_CapPhepTNN/PutTuChoiPheDuyetGiayPhepHN?sogiayphep=${sogiayphep}&ngaycapphep=${ngaycapphep}`,
          project: "cscore.tnn"
        });
      };


      //TODO: [Common] Tờ khai công trình nước mặt cống
  GetListCongTrinhTKDK_NuocMat_Cong = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/cong/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_CongById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/cong/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  
  AddorUpdateCongTrinhTKDK_NuocMat_Cong = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/cong-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_Cong = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/cong/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

   //TODO: [Common] Tờ khai công trình nước mặt đập
   GetListCongTrinhTKDK_NuocMat_Dap = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/dap/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_DapById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/dap/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_Dap = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/dap-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_Dap = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/dap/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Tờ khai công trình nước mặt hồ chứa
  GetListCongTrinhTKDK_NuocMat_HoChua = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/hochua/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_HoChuaById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/hochua/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_HoChua = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/hochua-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_HoChua = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/hochua/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

  //TODO: [Common] Tờ khai công trình nước mặt nhà máy nước
  GetListCongTrinhTKDK_NuocMat_NhaMayNuoc = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/nhamaynuoc/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_NhaMayNuocById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/nhamaynuoc/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_NhaMayNuoc = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/nhamaynuoc-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_NhaMayNuoc = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/nhamaynuoc/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

  
  //TODO: [Common] Tờ khai công trình nước mặt thủy điện
  GetListCongTrinhTKDK_NuocMat_ThuyDien = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/thuydien/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_ThuyDienById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/thuydien/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_ThuyDien = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/thuydien-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_ThuyDien = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/thuydien/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

  
  //TODO: [Common] Tờ khai công trình nước mặt trạm bơm
  GetListCongTrinhTKDK_NuocMat_TramBom = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/trambom/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_TramBomById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/trambom/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_TramBom = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/trambom-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_TramBom = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/trambom/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

   //TODO: [Common] Tờ khai công trình nước mặt trạm cấp nước
   GetListCongTrinhTKDK_NuocMat_TramCapNuoc = async ({ token, tuKhoa, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/tramcapnuoc/getlist?tuKhoa=${tuKhoa}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_CongTrinhTKDK_NuocMat_TramCapNuocById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `congtrinhtkdk/nuocmat/tramcapnuoc/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateCongTrinhTKDK_NuocMat_TramCapNuoc = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/tramcapnuoc-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteCongTrinhTKDK_NuocMat_TramCapNuoc = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `congtrinhtkdk/nuocmat/tramcapnuoc/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

   //TODO: [Common] Tờ khai ao hồ
   GetListTKDK_DaoAoHo = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkdaoaoho/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_DaoAoHoById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkdaoaoho/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateTKDK_DaoAoHo = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkdaoaoho-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteTKDK_DaoAoHo = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkdaoaoho/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };

   //TODO: [Common] Tờ khai nước biển
   GetListTKDK_KhaiThacNuocBien = async ({ token, strSearch, page, size } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkkhaithacnuocbien/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
      project: "cscore.tnn"
    });
  };
  GetTKDK_KhaiThacNuocBienById = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).getData({
      path: `tkdkkhaithacnuocbien/${madinhdanh}`,
      project: "cscore.tnn",
    });
  };
  AddorUpdateTKDK_KhaiThacNuocBien = async ({ token, dataRes } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkkhaithacnuocbien-upsert`,
      project: "cscore.tnn",
      data: dataRes
    });
  };

  DeleteTKDK_KhaiThacNuocBien = async ({ token, madinhdanh } = {}) => {
    return new APIService({ token: token }).postData({
      path: `tkdkkhaithacnuocbien/delete/${madinhdanh}`,
      project: "cscore.tnn"
    });
  };
    //TODO: [Common] Tờ khai nước dưới đất hộ gia đình
    GetListTKDK_NuocDuoiDat_HoGiaDinh = async ({ token, strSearch, page, size } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidathogiadinh/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
        project: "cscore.tnn"
      });
    };
    GetTKDK_NuocDuoiDat_HoGiaDinhById = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidathogiadinh/${madinhdanh}`,
        project: "cscore.tnn",
      });
    };
    AddorUpdateTKDK_NuocDuoiDat_HoGiaDinh = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidathogiadinh-upsert`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
  
    DeleteTKDK_NuocDuoiDat_HoGiaDinh = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidathogiadinh/delete/${madinhdanh}`,
        project: "cscore.tnn"
      });
    };

    //TODO: [Common] Tờ khai nước dưới đất khoáng sản
    GetListTKDK_NuocDuoiDat_KhaiThacKhoangSan = async ({ token, strSearch, page, size } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidatkhaithackhoangsan/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
        project: "cscore.tnn"
      });
    };
    GetTKDK_NuocDuoiDat_KhaiThacKhoangSanById = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidatkhaithackhoangsan/${madinhdanh}`,
        project: "cscore.tnn",
      });
    };
    AddorUpdateTKDK_NuocDuoiDat_KhaiThacKhoangSan = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidatkhaithackhoangsan-upsert`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
  
    DeleteTKDK_NuocDuoiDat_KhaiThacKhoangSan = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidatkhaithackhoangsan/delete/${madinhdanh}`,
        project: "cscore.tnn"
      });
    };

     //TODO: [Common] Tờ khai nước dưới đất quy mô nhỏ
     GetListTKDK_NuocDuoiDat_QuyMoNho = async ({ token, strSearch, page, size } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidatquymonho/getlist?strSearch=${strSearch}&page=${page}&size=${size}`,
        project: "cscore.tnn"
      });
    };
    GetTKDK_NuocDuoiDat_QuyMoNhoById = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).getData({
        path: `tkdknuocduoidatquymonho/${madinhdanh}`,
        project: "cscore.tnn",
      });
    };
    AddorUpdateTKDK_NuocDuoiDat_QuyMoNho = async ({ token, dataRes } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidatquymonho-upsert`,
        project: "cscore.tnn",
        data: dataRes
      });
    };
  
    DeleteTKDK_NuocDuoiDat_QuyMoNho = async ({ token, madinhdanh } = {}) => {
      return new APIService({ token: token }).postData({
        path: `tkdknuocduoidatquymonho/delete/${madinhdanh}`,
        project: "cscore.tnn"
      });
    };

   
}