import React from "react";
import helper from "../../../../helper/helper";
import typo from '../../../../constants/dictionaries'
import Status from "../../../../components/Table/Status";

const columns = ({handleShowDetail} = {}) =>
  [
    // {
    //   title: "#",
    //   dataIndex: "stt",
    //   width: "0px",
    // },
    {
      title: "Số giấy phép",
      dataIndex: "soVanBan",
      width: "140px",
      render: (value, row) => <a onClick={() => handleShowDetail(row)}>{value}</a>
    },
    {
      title: "Ngày ký",
      dataIndex: "ngayKy",
      // width: "100px",
      render: (value, row) => (helper.formatTableDate(row.ngayKy)),
    },
    {
      title: typo.cscore_tnn_license_available_date,
      dataIndex: "ngayBDHieuLuc",
      // width: "100px",
      render: (value, row) => (helper.formatTableDate(row.ngayBDHieuLuc)),
    },
    {
      title: typo.cscore_tnn_license_available_end_date,
      dataIndex: "ngayKTHieuLuc",
      // width: "100px",
      render: (value, row) => (helper.formatTableDate(row.ngayKTHieuLuc)),
    },
    {
      title: typo.cscore_tnn_status,
      dataIndex: "ngayKTHieuLuc",
      width: "120px",
      render: (value, row) => <Status value={value} trangthai={row.trangThai} />
    },


  ].map(col => ({ ...col}));

export default columns;
