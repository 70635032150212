import React, { Component } from "react";
import { connect } from 'react-redux'

//component
import { Row, Col, Collapse } from 'antd'
import ItemInfo from "./tb.itemInfo"
import ItemInfo_ToanQuoc from "./tb.itemInfo_toanQuoc"
import NNNT from "../../../../components/AttachTable/DMNguonNuocNoiTinh/Table";
import DanhMucDVHC from "../../../../components/AttachTable/DanhMucDVHC/Table"
import Constant from "../../../../constants/constant"
// helper
import Api_tnn from '../../../../api/api.tnn';

const { Panel } = Collapse;

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: undefined,
            tongLuongNMs: [],
            dM_NguonNuocNoiTinh_DVHCs: []
        }
    }
    componentDidMount = () => {
        {
            Number(Constant.CAP_TRIEN_KHAI) === 1 ?
                this.getData_Bo()
                :
                this.getData();
        }
    }

    getData = () => {
        if (!this.props.id) return
        new Api_tnn()
            .GetDM_NguonNuocNoiTinh({
                token: this.props.user.access_token,
                id: this.props.id,
            })
            .then((res) => {
                this.setState({
                    item: res,
                    tongLuongNMs: res.tongLuongNMs,
                    dM_NguonNuocNoiTinh_DVHCs: res ? res.dM_NguonNuocNoiTinh_DVHCs : [],
                });
            }).catch(err => { });
    }
    getData_Bo = () => {
        if (!this.props.id) return
        new Api_tnn()
            .GetDM_SongToanQuoc({
                token: this.props.user.access_token,
                id: this.props.id,
            })
            .then((res) => {
                this.setState({
                    item: res,
                    tongLuongNMs: res.tongLuongNMs,
                    dM_NguonNuocNoiTinh_DVHCs: res ? res.dM_NguonNuocNoiTinh_DVHCs : [],
                });
            }).catch(err => { });
    }
    handleUpdateNNNT = (items) => {
        this.setState({ tongLuongNMs: items })
    }
    handleUpdateDanhMucDVHC = (item) => {
        this.setState({ dM_NguonNuocNoiTinh_DVHCs: item })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            Number(Constant.CAP_TRIEN_KHAI) === 1 ?
                this.getData_Bo()
                :
                this.getData();
        }
    }
    render() {
        const { item, tongLuongNMs, dM_NguonNuocNoiTinh_DVHCs } = this.state
        const { bando } = this.props
        return (
            <div>
                {item &&
                    <Row type="flex" gutter={50}>
                        <Col span={bando ? 24 : 12}>
                            <Collapse bordered={false} defaultActiveKey={['1']}>
                                <Panel header={<a>Thông tin chung</a>} key="1" className='detail_collapse_item'>
                                    <div className='div-table-collapse'>
                                        {Number(Constant.CAP_TRIEN_KHAI) === 1 ?
                                            <ItemInfo_ToanQuoc item={item} />
                                            :
                                            <ItemInfo item={item} />
                                        }
                                    </div>
                                </Panel>
                            </Collapse>
                        </Col>
                        {Number(Constant.CAP_TRIEN_KHAI) === 2 &&
                            <Col span={bando ? 24 : 12}>
                                {dM_NguonNuocNoiTinh_DVHCs && dM_NguonNuocNoiTinh_DVHCs.length> 0 &&
                                    <Collapse bordered={false} defaultActiveKey={["1"]}>
                                        <Panel header={<a>Danh mục nguồn nước</a>} key="1" className='detail_collapse_item'>
                                            <div className='div-table-collapse'>
                                                <DanhMucDVHC dataSource={dM_NguonNuocNoiTinh_DVHCs} handleUpdate={this.handleUpdateDanhMucDVHC} IdNguonNuoc={item.id} mode="view" />
                                            </div>
                                        </Panel>
                                    </Collapse>}
                                <Collapse bordered={false} defaultActiveKey={["1"]}>
                                    <Panel header={<a>Tổng lượng nước</a>} key="1" className='detail_collapse_item'>
                                        <div className='div-table-collapse'>
                                            <NNNT dataSource={tongLuongNMs} handleUpdate={this.handleUpdateNNNT} mode="view" />
                                        </div>
                                    </Panel>
                                </Collapse>
                            </Col>}
                    </Row>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ user: state.oidc.user });
export default connect(mapStateToProps)(Detail);