import typo from "../../../../constants/dictionaries"
import helper from "../../../../helper/helper";
const columns = ({} = {}) =>
  [
    // {
    //   title: "#",
    //   dataIndex: "stt",
    //   width: "45px",
    //   align: 'center',
    // },
    {
      title: typo.cscore_tnn_soHieu,
      dataIndex: "soHieu",
      defaultSortOrder: "descend",
      width: "80px",
    },
    {
      title: typo.cscore_tnn_diaDiem,
      dataIndex: "diaDiem",
    },
    {
      title: "Tọa độ",
      children: [
        {
          title: "X",
          dataIndex: "toaDo",
          width: 80,
          render: (value) => helper.getToaDoX(value)
        },
        {
          title: "Y",
          dataIndex: "toaDo",
          width: 80,
          render: (value) => helper.getToaDoY(value)
        }
      ]
    },
    {
      title: typo.cscore_tnn_luuLuongNuocThai,
      dataIndex: "luuLuongNuocThai",
      width: "100px",
    },
  ].map(col => ({ ...col}));

export default columns;
