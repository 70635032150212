import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import Helper from "../../../helper/helper";

class GeoJson_Layer extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: {
        data: null,
        style: null,
        key: null
      }
    }

  }



  componentDidMount() {
    const { idcongtrinh, loaicongtrinh, ktt_do, ktt_phut, muichieu, toado } = this.props
    if (idcongtrinh && loaicongtrinh) {
      this.getApiCongTrinhSpatial(idcongtrinh, loaicongtrinh)
    }
    if (!Helper.isNullOrEmptyItems(ktt_do) && !Helper.isNullOrEmptyItems(ktt_phut) && !Helper.isNullOrEmptyItems(muichieu) && !Helper.isNullOrEmptyItems(toado)) {
      this.CheckCongTrinhDiem(ktt_do, ktt_phut, muichieu, toado)
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { idcongtrinh, loaicongtrinh, ktt_do, ktt_phut, muichieu, toado } = this.props
    if (prevProps.idcongtrinh !== idcongtrinh) {
      if(idcongtrinh && loaicongtrinh){
        this.getApiCongTrinhSpatial(idcongtrinh, loaicongtrinh)
      }
    }
    if (prevProps.ktt_do !== ktt_do || prevProps.ktt_phut !== ktt_phut || prevProps.muichieu !== muichieu || prevProps.toado !== toado) {
      if (!Helper.isNullOrEmptyItems(ktt_do) && !Helper.isNullOrEmptyItems(ktt_phut) && !Helper.isNullOrEmptyItems(muichieu) && !Helper.isNullOrEmptyItems(toado)) {
        this.CheckCongTrinhDiem(ktt_do, ktt_phut, muichieu, toado)
      }
    }
  }

  getApiCongTrinhSpatial = (idcongtrinh, loaicongtrinh) => {
    const { zoomOut } = this.props
    new Api_spatial()
      .GetSpatialCT({
        token: this.props.user.access_token,
        idcongtrinh: idcongtrinh,
        loaicongtrinh: loaicongtrinh,
      })
      .then((res) => {
        if (res != null && res.result != null) {
          const a = JSON.parse(res.result)
          const newGeom = { newGeom: (delete a.geometry['crs'], a) };
          const Json_newGeom = { ...newGeom.newGeom }
          const b = JSON.stringify(Json_newGeom)
          this.setState({
            GeoJson_data: {
              data: Json_newGeom,
              key: b
            }
          })
          var latlong = [a.geometry.coordinates[1], a.geometry.coordinates[0]]
          zoomOut(latlong)
        } else {
          this.setState({
            GeoJson_data: {
              data: null,
              style: null,
              key: null
            }
          })
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        //message.error(err.message);
      });
  };
  CheckCongTrinhDiem = (ktt_do, ktt_phut, muichieu, toado) => {
    const { zoomOut } = this.props
    new Api_spatial().CheckCongTrinhDiem({
      token: this.props.user.access_token,
      ktt_do: ktt_do,
      ktt_phut: ktt_phut,
      muichieu: muichieu,
      toado: toado,
    })
      .then((res) => {
        if (res != null && res.result != null) {
          const a = JSON.parse(res.result)
          const newGeom = { newGeom: (delete a.geometry['crs'], a) };
          const Json_newGeom = { ...newGeom.newGeom }
          const b = JSON.stringify(Json_newGeom)
          this.setState({
            GeoJson_data: {
              data: Json_newGeom,
              key: b
            },
          })
          var latlong = [a.geometry.coordinates[1], a.geometry.coordinates[0]]
          zoomOut(latlong)
        } else {
          this.setState({
            GeoJson_data: {
              data: null,
              style: null,
              key: null
            }
          })
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        //message.error(err.message);
      });
  };


  render() {

    return (
      <div>
        {this.state.GeoJson_data.data != null &&
          <GeoJSON ref="geojson" key={this.state.GeoJson_data.key} data={this.state.GeoJson_data.data} />
        }
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

