import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon } from 'antd'
import { Formik, FastField } from 'formik'
import typo from '../../../../constants/dictionaries'
import { isEmpty } from "lodash";
import helper from "../../../../helper/helper"

// Utils
import constant from "../../../../constants/constant";

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import ValidatedText from "../../../Common/ValidatedText";
import DisplayFormikState from "../../../Table/DisplayFormikState";
import NumberField from "../../../Common/NumberField";
import Select from '../../../Common/Select'
import { VALIDATE_MESSAGE } from "../../../../constants/dictionaries";
import styled from "styled-components";
import Api_tnn from "../../../../api/api.tnn";
import Message from "../../../Messages";

const schema = Yup.object().shape({
  heSoKQ: Yup.number(),
  heSoKF: Yup.number(),
  heSoK: Yup.number(),
  idQCApDung: Yup.string().required(),
  tenQCApDung: Yup.string(),
  idMucDichSuDung: Yup.string().required(),
  tenMDSD: Yup.string(),
});
const CachTinhCmax = [
  { id: 1, name: "Tính tự động" },
  { id: 2, name: "Nhập thủ công" },
]
class CLNuocThaiModal extends React.Component {
  constructor() {
    super();
    this.state = {
      listQuyChuanAD: [],
      listCotAB: [],
      initialValuesCL: []
    }
  }

  componentDidMount() {
    const { mode, initialValues } = this.props
    const {initialValuesCL} = this.state
    this.getListQCAP()
    if(mode === "add"){
      this.setState({
        initialValuesCL: {...initialValuesCL, Cmax: 2}
      })
    }
    if (mode === "edit") {
      this.getListCotAB(initialValues.idQCApDung)
      this.setState({
        initialValuesCL: {...initialValues, Cmax: 2}
      })
    }
  }

  getListQCAP = () => {
    const { user } = this.props;
    new Api_tnn().getQT_DMQCVN_TNNs({ token: user.access_token, doituongqt: 3 })
      .then((res) => {
        this.setState({ listQuyChuanAD: res.data })
      })
      .catch(err => Message.error(err.message))
  };

  getListCotAB = (idDM_QCVN) => {
    const { user } = this.props;
    new Api_tnn().getQT_MucDichSuDungs({ token: user.access_token, IdDM_QCVN: idDM_QCVN })
      .then((res) => {
        this.setState({ listCotAB: res.data })
      })
      .catch(err => Message.error(err.message))
  }

  render() {
    const { isOpen, handleCancel, handleOk, initialValues, mode } = this.props;
    const { listQuyChuanAD, listCotAB, initialValuesCL } = this.state;
    return (
      <Modal
        visible={isOpen}
        title="Chất lượng nước thải"
        footer={false}
        onCancel={handleCancel}
      >
        <Formik
          enableReinitialize
          initialValues={initialValuesCL}
          onSubmit={(value, actions) => {
            handleOk(value);
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({ handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <Col span={12}>
                  <GroupWrapper>
                    <Select
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_quyChuanApDung)}
                      label={typo.cscore_tnn_quyChuanApDung}
                      dataSource={listQuyChuanAD}
                      name="maQuyChuan"
                      name_value="id"
                      onSelect={(value) => {
                        setFieldValue("idQCApDung", value);
                        setFieldValue("tenQCApDung", helper.getFieldValueFromID(value, listQuyChuanAD, "maQuyChuan"));
                        setFieldValue("idMucDichSuDung", undefined);
                        this.getListCotAB(value)
                      }} defaultValue={values.idQCApDung} key={values.idQCApDung} />

                    <Select
                      mandatory={false}
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_heSoKQ)}
                      label={typo.cscore_tnn_heSoKQ}
                      dataSource={constant.HE_SO_KQ}
                      name="ten"
                      name_value="value"
                      onSelect={(value) => {
                        setFieldValue("heSoKQ", value);
                      }} defaultValue={values.heSoKQ} key={values.heSoKQ} />
                    <Select
                      mandatory={false}
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_heSoKF)}
                      label={typo.cscore_tnn_heSoKF}
                      dataSource={constant.HE_SO_KF}
                      name="ten"
                      name_value="value"
                      onSelect={(value) => {
                        setFieldValue("heSoKF", value);
                      }} defaultValue={values.heSoKF} key={values.heSoKF} />
                  </GroupWrapper>
                </Col>
                <Col span={12}>
                  <GroupWrapper>
                    <Select
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_cotAB)}
                      label={typo.cscore_tnn_cotAB}
                      dataSource={listCotAB}
                      name="kyHieu"
                      name_value="id"
                      onSelect={(value) => {
                        setFieldValue("idMucDichSuDung", value);
                        setFieldValue("tenMDSD", helper.getFieldValueFromID(value, listCotAB, "kyHieu"));
                      }}
                      disabled={values && !values.idQCApDung} key={values.idMucDichSuDung ? values.idMucDichSuDung : undefined}
                      value={values.idMucDichSuDung ? values.idMucDichSuDung : undefined} />
                    <Select
                      mandatory={false}
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_heSoK)}
                      label={typo.cscore_tnn_heSoK}
                      dataSource={constant.HE_SO_KQ}
                      name="ten"
                      name_value="value"
                      onSelect={(value) => {
                        setFieldValue("heSoK", value);
                      }}
                      defaultValue={values.heSoK} key={values.heSoK} />
                    <Select
                      mandatory={false}
                      width="100%" placeholder={helper.genSelectPL(typo.cscore_tnn_cach_tinh_Cmax)}
                      label={typo.cscore_tnn_cach_tinh_Cmax}
                      dataSource={CachTinhCmax}
                      name="name"
                      name_value="id"
                      onSelect={(value) => {
                        setFieldValue("Cmax", value);
                      }} defaultValue={values.Cmax} key={values.Cmax} />
                  </GroupWrapper>
                </Col>
              </Row>
              <div className="mt-20" />
              <Row type="flex" justify="end" align="middle">
                <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors) || !dirty}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                <div className="ml-10" />
                <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
              </Row>
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )} />
      </Modal>
    )
  }
};

CLNuocThaiModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(CLNuocThaiModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

