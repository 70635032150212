
import React, { useState, useEffect } from "react";
import L from 'leaflet'
import styled from "styled-components";
import Api from "../../../../api";
import message from "../../../../components/Messages";
import Constant from "../../../../constants/constant"
import Api_tnn from "../../../../api/api.tnn";
import Api_spatial from "../../../../api/api.spatial";
import Api_dms from "../../../../api/api.dms";
import { Col, Tabs, Row, Radio } from "antd";
import { FastField } from "formik";
import FilterWrapper from "../../../../components/Common/FilterWrapper";
import columns from "../Colums/CTThuocSongList.column";
import columnsGieng from "../Colums/GiengList.column";
import columnsDXT from "../Colums/DXTThuocSongList.column";
import columnsGP from "../Colums/GiayPhepList.column";
import Select from "../../../../components/Common/Select";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import helper from "../../../../helper/helper";
import SearchInput from "../../../../components/Common/Input/SearchInput";
import Table from "../../../../components/Table";
const { TabPane } = Tabs;
const ControllerCheckbox = styled.div`
  margin-bottom: 15px;
`;
const FuncMap = ({ dulieu_congtrinh, user, tenTinh, tenHuyen, cap, maDVHCbyUser, maTinh, dvhcTinhs }) => {
    const [filterInfo, setfilterInfo] = useState({
        strSearch: "",
        idTinh: undefined,
        idHuyen: undefined,
        idXa: undefined,
        idTimKiem: "",
    });
    const [data_tinh, setdata_tinh] = useState([]);
    const [data_huyen, setdata_huyen] = useState([]);
    const [dataCTbyGiayPhep, setdataCTbyGiayPhep] = useState([]);
    const [dataThuocHuyen, setdataThuocHuyen] = useState([]);
    const [pagination, setpagination] = useState({});
    const [loaiTimKiem, setloaiTimKiem] = useState(1);
    const [displayInfo, setdisplayInfo] = useState("none");
    const [selectedItemId, setselectedItemId] = useState(null);
    const [selectedItemXtId, setselectedItemXtId] = useState(null);
    const [selectedItemIdG, setselectedItemIdG] = useState(null);
    const [selectedItemGP, setselectedItemGP] = useState(null);
    useEffect(() => {
        if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG) {
            if (cap === Constant.Role_user.LD_CV_BO) {
                if (maDVHCbyUser === "1000") {
                    getApiTinhs();
                } else {
                    getApiHuyenByTinhs(maTinh);
                }
            } else if (cap === Constant.Role_user.LD_CV_SO) {
                getApiHuyenByTinhs(maTinh);
            } else {
                getApiTinhs();
            }
        } else if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG) {
            getApiHuyenByTinhs(Constant.MA_TINH)
            setfilterInfo({
                strSearch: "",
                idTinh: String(Constant.MA_TINH),
                idHuyen: undefined,
                idXa: undefined,
                idTimKiem: "",
            })
        }
    }, []);

    function getApiTinhs() {
        setdata_tinh(dvhcTinhs)
    }
    function getApiHuyenByTinhs(maTinh) {
        new Api()
            .getHuyenByTinhs({
                token: user.access_token,
                maTinh: maTinh,
            })
            .then((res) => {
                setdata_huyen(res)
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    function GetCT_GiayPheps(strSearch, page, size) {
        new Api_tnn()
            .GetCT_GiayPheps({
                token: user.access_token,
                strSearch: strSearch,
                roledvhc: maDVHCbyUser,
                page: page,
                size: size,
            })
            .then((res) => {
                setdataCTbyGiayPhep(res && res.data.map((item, index) => ({
                    ...item,
                    stt: index + 1,
                    svb_ngayky: item.soVanBan + " - " + helper.formatDate(item.ngayKy),
                })))
                setpagination(res && res.pagination)
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    function GetSpatialThuocDVHC(tenhuyen, tentinh) {
        new Api_spatial()
            .GetSpatialThuocDVHC({
                token: user.access_token,
                tenhuyen: tenhuyen,
                tentinh: tentinh,
            })
            .then((res) => {
                setdataThuocHuyen(res)
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    function GetCT_GiayPhepXTByIDCongTrinh(idcongtrinh, loaicongtrinh) {
        return new Api_tnn()
            .GetCT_GiayPhepXTByIDCongTrinh({
                token: user.access_token,
                idcongtrinh: idcongtrinh,
                loaicongtrinh: loaicongtrinh,
            })
            .then((res) => {
                if (res.idFile !== undefined && res.tenTaiLieu !== undefined && res.tenTaiLieu !== null) {
                    downFile(res);
                } else if (res.idFile === undefined || res.tenTaiLieu === undefined || res.tenTaiLieu === null) {
                    message.warning(MESSAGE.cscore_tnn_THIEU_TAI_LIEU);
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    }
    function downFile(data) {
        new Api_dms().DownloadFile({
            token: user.access_token,
            data: { oid: data.idFile, filename: data.tenTaiLieu },
        });
    }
    function showFile(value) {
        GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, value.loaicongtrinh)
    }
    function showFileDXT(value) {
        GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, 0)
    }
    function showFileG(value) {
        GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, 2)
    }
    function onChangeTimKiem(e) {
        setloaiTimKiem(e.target.value)
    }
    function searchGiayPhep(strSearch) {
        setdataThuocHuyen([])
        setdisplayInfo(["block"])
        GetCT_GiayPheps(strSearch, 1, 10)
    }
    function handleTableParamsChange(pagination, filteredInfo, sortedInfo, value) {
        GetCT_GiayPheps(value.strSearch, pagination.current, 10)
    }
    function SelectTinh(value, name) {
        setdisplayInfo(["block"])
        setdataCTbyGiayPhep([])
        GetSpatialThuocDVHC("", name.props.children)
    }
    function SelectHuyen(value, name) {
        GetSpatialThuocDVHC(name.props.children, "")
        setdisplayInfo(["block"])
        setdataCTbyGiayPhep([])
    }
    return (
        <ControllerCheckbox>
            <Row type="flex" justify="start" style={{ paddingTop: "10px" }}>
                <Col>
                    <FilterWrapper
                        initialValues={filterInfo}
                        hideButton={true}
                        handleApplyFilter={() => { }}
                    >
                        {({ handleChange, setFieldValue, values, handleSubmit, }) => (
                            <Col>
                                <Row style={{ marginLeft: "25px" }}>
                                    <Radio.Group onChange={(e) => onChangeTimKiem(e)} value={loaiTimKiem}>
                                        <Radio value={1}><b style={{ fontWeight: 600 }}>Tìm theo từ khóa</b></Radio>
                                        <Radio value={2}><b style={{ fontWeight: 600 }}>Tìm theo đơn vị hành chính</b></Radio>
                                    </Radio.Group>
                                </Row>
                                <div className="mt-10" />
                                <Row type="flex" style={{ marginLeft: "25px" }}>
                                    {Number(loaiTimKiem) === 1 && (
                                        <Col>
                                            <FastField name="strSearch" render={({ field }) => (
                                                <SearchInput width="450px" {...field}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setFieldValue("idTinh", undefined);
                                                            setFieldValue("idHuyen", undefined);
                                                            setFieldValue("idXa", undefined);
                                                            setFieldValue("strSearch", e.target.value);
                                                            searchGiayPhep(e.target.value);
                                                        }
                                                    }}
                                                />
                                            )}
                                            />
                                            <div style={{ color: "#aba2a2", marginTop: "10px" }}>Nhập từ khóa về giấy phép, địa điểm, tổ chức cá nhân,... Ví dụ: Hà Nội, 234/GP-BTNMT</div>
                                        </Col>
                                    )}
                                </Row>
                                <div className="mt-10" />
                                <Row style={{ marginLeft: "25px" }}>
                                    {Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG && cap === Constant.Role_user.LD_CV_BO && maDVHCbyUser === "1000" &&
                                        Number(loaiTimKiem) === 2 && (
                                            <Col>
                                                <Select
                                                    mandatory={false}
                                                    placeholder={helper.genSelectPL(typo.cscore_tnn_province_placeholder)}
                                                    onChange={(value, name) => {
                                                        setFieldValue("idTinh", value);
                                                        setFieldValue("tentinh", name.props.children);
                                                        setFieldValue("strSearch", undefined);
                                                        SelectTinh(value, name);
                                                        tenTinh(name.props.children)
                                                        tenHuyen(undefined)
                                                    }}
                                                    dataSource={data_tinh}
                                                    width="450px"
                                                    name="tenDVHC"
                                                    name_value="maTinh"
                                                    defaultValue={values.idTinh}
                                                    key={values.idTinh}
                                                    alowClear={true}
                                                />
                                            </Col>
                                        )}
                                    {Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG && cap < Constant.Role_user.LD_CV_BO && maDVHCbyUser === "1000" &&
                                        Number(loaiTimKiem) === 2 && (
                                            <Col>
                                                <Select
                                                    mandatory={false}
                                                    placeholder={helper.genSelectPL(typo.cscore_tnn_province_placeholder)}
                                                    onChange={(value, name) => {
                                                        setFieldValue("idTinh", value);
                                                        setFieldValue("tentinh", name.props.children);
                                                        setFieldValue("strSearch", undefined);
                                                        SelectTinh(value, name);
                                                        tenTinh(name.props.children)
                                                        tenHuyen(undefined)
                                                    }}
                                                    dataSource={data_tinh}
                                                    width="450px"
                                                    name="tenDVHC"
                                                    name_value="maTinh"
                                                    defaultValue={values.idTinh}
                                                    key={values.idTinh}
                                                    alowClear={true}
                                                />
                                            </Col>
                                        )}
                                    {Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG && cap === Constant.Role_user.LD_CV_SO &&
                                        Number(loaiTimKiem) === 2 && (
                                            <Col>
                                                <Select
                                                    mandatory={false}
                                                    placeholder={helper.genSelectPL(typo.cscore_tnn_district_placeholder)}
                                                    onChange={(value, name) => {
                                                        setFieldValue("idHuyen", value);
                                                        setFieldValue("tenhuyen", name.props.children);
                                                        setFieldValue("idXa", undefined);
                                                        setFieldValue("strSearch", undefined);
                                                        SelectHuyen(value, name);
                                                        tenTinh(undefined)
                                                        tenHuyen(name.props.children)
                                                    }}
                                                    dataSource={data_huyen}
                                                    width="450px"
                                                    name="tenDVHC"
                                                    name_value="maHuyen"
                                                    defaultValue={values.idHuyen}
                                                    key={values.idHuyen}
                                                />
                                            </Col>
                                        )}
                                </Row>
                                <div className="mt-10" />
                                <Row style={{ marginLeft: "25px" }}>
                                    {Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG &&
                                        Number(loaiTimKiem) === 2 && (
                                            <Col>
                                                <Select
                                                    mandatory={false}
                                                    placeholder={helper.genSelectPL(typo.cscore_tnn_district_placeholder)}
                                                    onChange={(value, name) => {
                                                        setFieldValue("idHuyen", value);
                                                        setFieldValue("tenhuyen", name.props.children);
                                                        setFieldValue("idXa", undefined);
                                                        setFieldValue("strSearch", undefined);
                                                        SelectHuyen(value, name);
                                                        tenTinh(undefined)
                                                        tenHuyen(name.props.children)
                                                    }}
                                                    dataSource={data_huyen}
                                                    width="450px"
                                                    name="tenDVHC"
                                                    name_value="maHuyen"
                                                    defaultValue={values.idHuyen}
                                                    key={values.idHuyen}
                                                />
                                            </Col>
                                        )}
                                </Row>
                                <div className="mt-10" />
                                <Row>
                                    <Col style={{ width: "450px", marginLeft: "25px", display: displayInfo }}>
                                        {dataCTbyGiayPhep && !dataThuocHuyen.tnn_congtrinh_diem_intersect && (
                                            <Tabs type="card">
                                                <TabPane tab="Giấy phép" key="1">
                                                    <Table
                                                        columns={columnsGP({
                                                            showFile: downFile,
                                                        })}
                                                        selectedRowId={selectedItemGP}
                                                        onRowClick={(record) => {
                                                            dulieu_congtrinh(record.idCongTrinh, record.loaiCongTrinh)
                                                            setselectedItemGP(record.id)
                                                        }}
                                                        dataSource={dataCTbyGiayPhep}
                                                        onChange={(pagination, filteredInfo, sortedInfo) => handleTableParamsChange(pagination, filteredInfo, sortedInfo, values)}
                                                        pagination={{
                                                            total: pagination.total,
                                                            pageSize: 10,
                                                            current: pagination.currentPage,
                                                        }}
                                                        scroll={{ y: 540 }}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ width: "450px", marginLeft: "25px", display: displayInfo }}>
                                        <Tabs type="card">
                                            {dataThuocHuyen.tnn_congtrinh_diem_intersect && (
                                                <TabPane tab="CT-Nước mặt" key="2">
                                                    <Table
                                                        columns={columns({
                                                            showFileModal: showFile,
                                                        })}
                                                        selectedRowId={selectedItemId}
                                                        onRowClick={(record) => {
                                                            dulieu_congtrinh(record.idcongtrinh, record.loaicongtrinh)
                                                            setselectedItemId(record.gid)
                                                        }}
                                                        dataSource={dataThuocHuyen.tnn_congtrinh_diem_intersect
                                                            ? dataThuocHuyen.tnn_congtrinh_diem_intersect.map((item, index) => ({ ...item, stt: index + 1, }))
                                                            : []}
                                                        pagination={false}
                                                        scroll={{ y: 540 }}
                                                    />
                                                </TabPane>
                                            )}
                                            {dataThuocHuyen.tnn_diemxathai_intersect && (
                                                <TabPane tab="CT-Xả thải" key="3">
                                                    <Table
                                                        columns={columnsDXT({
                                                            showFileModal: showFileDXT,
                                                        })}
                                                        selectedRowId={selectedItemXtId}
                                                        onRowClick={(record) => {
                                                            dulieu_congtrinh(record.idcongtrinh, 10)
                                                            setselectedItemXtId(record.gid)
                                                        }}
                                                        dataSource={dataThuocHuyen.tnn_diemxathai_intersect
                                                            ? dataThuocHuyen.tnn_diemxathai_intersect.map((item, index) => ({ ...item, stt: index + 1, }))
                                                            : []}
                                                        pagination={false}
                                                        scroll={{ y: 540 }}
                                                    />
                                                </TabPane>
                                            )}
                                            {dataThuocHuyen.tnn_congtrinh_gieng_intersect && Number(Constant.MA_TINH) !== 31 && (
                                                <TabPane tab="CTKT-Nước dưới đất" key="4">
                                                    <Table
                                                        columns={columnsGieng({
                                                            showFileModal: showFileG,
                                                        })}
                                                        selectedRowId={selectedItemIdG}
                                                        onRowClick={(record) => {
                                                            dulieu_congtrinh(record.idcongtrinh, record.loaicongtrinh)
                                                            setselectedItemIdG(record.gid)
                                                        }}
                                                        dataSource={dataThuocHuyen.tnn_congtrinh_gieng_intersect
                                                            ? dataThuocHuyen.tnn_congtrinh_gieng_intersect.map((item, index) => ({ ...item, stt: index + 1, }))
                                                            : []}
                                                        pagination={false}
                                                        scroll={{ y: 540 }}
                                                    />
                                                </TabPane>
                                            )}
                                            {/* {dataThuocHuyen.tnn_diemquantrac_intersect && Number(Constant.MA_TINH) !== 31 && (
                                                    <TabPane tab="Điểm quan trắc" key="5">
                                                        <Table
                                                            columns={columnsDQT({
                                                            })}
                                                            selectedRowId={selectedItemDQT}
                                                            onRowClick={(record) => {
                                                                this.setState({ selectedItemDQT: record.iddiemquantrac });
                                                            }}
                                                            dataSource={dataThuocHuyen.tnn_diemquantrac_intersect
                                                                ? dataThuocHuyen.tnn_diemquantrac_intersect.map((item, index) => ({ ...item, stt: index + 1 }))
                                                                : []
                                                            }
                                                            pagination={false}
                                                            scroll={{ y: 540 }}
                                                        />
                                                    </TabPane>
                                                )}
                                                {dataThuocHuyen.tnn_tramquantrac_intersect && Number(Constant.MA_TINH) !== 31 && (
                                                    <TabPane tab="Trạm quan trắc" key="6">
                                                        <Table
                                                            columns={columnsTQT({
                                                            })}
                                                            selectedRowId={selectedItemTQT}
                                                            onRowClick={(record) => {
                                                                this.setState({ selectedItemTQT: record.idtramquantrac });
                                                            }}
                                                            dataSource={dataThuocHuyen.tnn_tramquantrac_intersect
                                                                ? dataThuocHuyen.tnn_tramquantrac_intersect.map((item, index) => ({ ...item, stt: index + 1 }))
                                                                : []
                                                            }
                                                            pagination={false}
                                                            scroll={{ y: 540 }}
                                                        />
                                                    </TabPane>
                                                )} */}
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </FilterWrapper>
                </Col>
            </Row>
        </ControllerCheckbox>
    );
}
export default FuncMap