import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

const {Title} = Typography;

// Components
const UnitOfMeasure = lazy(() => import('./UnitOfMeasure'));
const TuyenQT = lazy(() => import('./TuyenQuanTrac'));
const DonViThucHien = lazy(() => import('./DonViThucHien'));
const PhuongPhapLM = lazy(() => import('./PhuongPhapLM'));
const PhuongPhapPT = lazy(() => import('./PhuongPhapPT'));
const MucDichSuDung = lazy(() => import('./MucDichSuDung'));
const WaterMonitoringPhase = lazy(() => import('./WaterMonitoringPhase'));
const MonitoringPhase = lazy(() => import('./MonitoringPhase'));
const ThongSoQuanTrac = lazy(() => import('./ThongSoQuanTrac'));
const TieuChuanApDung = lazy(() => import('./TieuChuanApDung'));
const TieuChuanDanhGia = lazy(() => import('./TieuChuanDanhGia'));
const TramQuanTracNuoc = lazy(() => import('./TramQuanTracNuoc'));

export default ({match}) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/Don-vi-do`} component={UnitOfMeasure}/>
        <Route exact path={`${match.path}/Tuyen-quan-trac`} component={TuyenQT}/>
        <Route exact path={`${match.path}/Don-vi-thuc-hien`} component={DonViThucHien}/>
        <Route exact path={`${match.path}/Phuong-phap-lay-mau`} component={PhuongPhapLM}/>
        <Route exact path={`${match.path}/Phuong-phap-phan-tich`} component={PhuongPhapPT}/>
        <Route exact path={`${match.path}/Muc-dich-su-dung`} component={MucDichSuDung}/>
        <Route exact path={`${match.path}/Dot-quan-trac`} component={WaterMonitoringPhase}/>
        <Route exact path={`${match.path}/Ky-quan-trac-kttv`} component={MonitoringPhase}/>
        <Route exact path={`${match.path}/Thong-so-quan-trac`} component={ThongSoQuanTrac}/>
        <Route exact path={`${match.path}/Tieu-chuan-ap-dung`} component={TieuChuanApDung}/>
        <Route exact path={`${match.path}/Tieu-chuan-danh-gia`} component={TieuChuanDanhGia}/>
        <Route exact path={`${match.path}/Tram-quan-trac-nuoc`} component={TramQuanTracNuoc}/>
      </Switch>
    </Suspense>
  )
}