import React, {Component} from "react";
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {isEmpty} from "lodash";

//component
import {Modal, Typography, Col, Row, Icon} from 'antd';
import Input from "../../../Common/Input"
import Button from "../../../Buttons";
import NumberField from "../../../Common/NumberField"
// helper
import typo, {MESSAGE} from "../../../../constants/dictionaries";
import {FastField, Formik} from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({
  key: Yup.string()
    .required(typo.cscore_tnn_input_Required),
});

const {Text, Title} = Typography;

class Add extends Component {
  render() {
    const {action, item, handleCancel, handleSubmit} = this.props;
    const title = action === "add" ? typo.cscore_tnn_themToaDo : typo.cscore_tnn_capNhatToaDo;

    let initialValues = {
      stt: item ? item.stt : undefined,
      key: item ? item.key : undefined,
      x: item ? item.x : undefined,
      y: item ? item.y : undefined,
    };
    return (
      <Modal
        title={title}
        centered
        closable={true}
        visible={true}
        width="400px" footer={null}
        onCancel={handleCancel}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => handleSubmit(values)}
          render={({errors, handleSubmit, touched, dirty, values, setFieldValue}) => (
            <form onSubmit={handleSubmit}>
              <FastField name="key" render={({field}) => (<Input label={typo.cscore_tnn_key} {...field} />)}/>
              {touched.key && (<Text type="danger">{errors.key}</Text>)}

              <NumberField
                label={<span>{typo.cscore_tnn_coordinate} <b>X</b></span>}
                id="x"
                min={100000}
                onChange={value => setFieldValue("x", value)}
                value={values.x}
              />
              <NumberField
                label={<span>{typo.cscore_tnn_coordinate} <b>Y</b></span>}
                id="y"
                min={100000}
                onChange={value => setFieldValue("y", value)}
                value={values.y}
              />
              <Row type='flex' justify="end">
                <Col>
                  <Button
                    className="btn-save-in-modal"
                    type="primary"
                    onClick={handleSubmit}
                    // disabled={!isEmpty(errors) || !dirty}><Icon type="plus"/>{typo.cscore_tnn_btn_add}</Button>
                    disabled={!isEmpty(errors) || !dirty}><Icon type="plus"/>Lưu</Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </Modal>
    )
  }
}

Add.propTypes = {
  action: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(Add);