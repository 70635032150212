import React, { Component } from "react";
import { connect } from 'react-redux'
import { GeoJSON } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";

class GeoJson_Layer extends Component {
    constructor(props) {
        super(props);
        const { functions } = props;
        this.state = {
            GeoJson_data: {},
        }

    }



    componentDidMount() {
        const { GeoJson_data } = this.props
        this.setState({GeoJson_data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const { GeoJson_data } = this.state
        return (
            <div>
                {GeoJson_data !== null && GeoJson_data.data != null &&
                    <GeoJSON ref="geojson" key={GeoJson_data.key} data={GeoJson_data.data} />
                }
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

