import React from 'react'
import {Row} from 'antd'

const EditorEmpty = () => {
  return (
    <Row type="flex" justify="center" align="top">
      Không có dữ liệu
    </Row>
  )
};

export default EditorEmpty