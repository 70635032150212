import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import {Checkbox, InputNumber} from 'antd'

class EditorNumberField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: props.value};
  }

  getValue = () => {
    const {column} = this.props;
    return {[column.key]: this.state.value}
  };

  getInputNode = () => {
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  };

  onChange = (value) => {
    this.setState({value: value})
  };

  render() {
    const {value} = this.state;
    return (
      <InputNumber onChange={this.onChange} style={{width: '100%', height: '100%'}}/>
    );
  }
}

EditorNumberField.propTypes = {

};

export default EditorNumberField;
