const colors = {
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#e83e8c",
  red: "#f86c6b",
  orange: "#f8cb00",
  yellow: "#ffc107",
  green: "#4dbd74",
  teal: "#20c997",
  cyan: "#17a2b8",
  white: "#fff",
  gray: "#73818f5a",
  gray_dark: "#2f353a",
  light_blue: "#63c2de",
  primary: "#20a8d8",
  sub_primary: "#20a8d81a",
  secondary: "#c8ced3",
  success: "#4dbd74",
  info: "#63c2de",
  light: "#f0f3f5",
  dark: "#2f353a",
  border: "rgba(224, 224, 224, 1)"
};

export default colors;

export function getColorFromStatus(status) {
  switch (status) {
    case "validated":
      return colors.green;
    case "expiringSoon":
      return colors.orange;
    case "expired":
      return colors.red;
    case "wait":
      return "#9a9494";
    case "cscore_tnn_validated":
      return colors.green;
    case "cscore_tnn_expiringSoon":
      return colors.orange;
    case "cscore_tnn_expired":
      return colors.red;
    case "cscore_tnn_wait":
      return "#9a9494";
    default:
      return colors.white;
  }
}
