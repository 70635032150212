import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'
import typo from "../../constants/dictionaries";
const {Title} = Typography;

// Components
const TBSC = lazy(() => import('./ThongKeSuCo'));
const NKHT = lazy(() => import('./NhatKyHeThong'));
const NKDB = lazy(() => import('./NhatKyDongBo'));

export default ({match}) => {
  const groupName = 'quan-tri-he-thong'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/thong-ke-su-co`} component={TBSC}/>
        <Route exact path={`${match.path}/nhat-ky-he-thong`} component={NKHT}/>
        <Route exact path={`${match.path}/nhat-ky-dong-bo`} component={NKDB}/>
      </Switch>
    </Suspense>
  )
}