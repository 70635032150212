import React from 'react'
import { Col, Collapse, Row, Icon, Modal } from "antd";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "styled-components";
import styles from "./Modal.module.scss";
// Components
import Container from "../../../../containers/Container";
import Organization from '../../../../components/License/Organization'
import LicenseResult from '../../../../components/License/LicenseResult'
import ConstructionInfo from '../../../../components/License/ConstructionInfo'
import GioiHanThongSoView from '../../../../components/AttachTable/GioiHanThongSo/View'
import Licenses from '../../../../components/CongTrinh/GiayPhep/View'
import typo, { MESSAGE } from '../../../../constants/dictionaries'
import helper from "../../../../helper/helper";
import HangMucTableView from '../../../../components/CongTrinh/HangMuc/View'
import FileDinhKemView from '../../../../components/CongTrinh/FileDinhKem/View'
import TTKT from "../../../../components/AttachTable/TTKT/Table";
import TCQKTSD from "../../../../components/AttachTable/TCQKTSD/Table";
import BVPCKP from "../../../../components/AttachTable/BVPCKP/Table";
import ThamSoHC from "../../../../components/AttachTable/ThamSoHC/View";
import Constant from '../../../../constants/constant';
import ThanhTraDN from "../../../../components/AttachTable/ThanhTraDN/Table";
import Button from "../../../../components/Buttons"
import message from '../../../../components/Messages';
const { Panel } = Collapse;
const ControllerButton = styled.div`
  align-items: flex-end;
  padding-top: 10px;
  text-align: right;
`;
class SurfaceMining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      congTrinh: {},
      loaiCT: undefined,
      tenCT: undefined,
      openModal: false
    }
  }

  componentDidMount() {
    const { data } = this.props
    this.checkCongTrinh(data)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props
    if (prevProps.data !== data) {
      this.checkCongTrinh(data)
    }
  }
  checkCongTrinh = (data) => {
    if (data.ctnM_ThuyDien) {
      this.checkQuyen(data.ctnM_ThuyDien, Constant.DICT_LOAI_CT.NM_THUYDIEN)
    } else if (data.ctnM_HoChua) {
      this.checkQuyen(data.ctnM_HoChua, Constant.DICT_LOAI_CT.NM_HOCHUA)
    } else if (data.ctnM_BaiDap) {
      this.checkQuyen(data.ctnM_BaiDap, Constant.DICT_LOAI_CT.NM_BAIDAP)
    } else if (data.ctnM_TramBom) {
      this.checkQuyen(data.ctnM_TramBom, Constant.DICT_LOAI_CT.NM_TRAMBOM)
    } else if (data.ctnM_Cong) {
      this.checkQuyen(data.ctnM_Cong, Constant.DICT_LOAI_CT.NM_CONG)
    } else if (data.ctnM_TramCapNuoc) {
      this.checkQuyen(data.ctnM_TramCapNuoc, Constant.DICT_LOAI_CT.NM_TRAMCAPNUOC)
    } else if (data.ctnM_NhaMayNuoc) {
      this.checkQuyen(data.ctnM_NhaMayNuoc, Constant.DICT_LOAI_CT.NM_NHAMAYNUOC)
    }
  }
  checkQuyen = (dataCT, loaiCT) => {
    const { data, cap, maDVHCbyUser, maTinh } = this.props
    if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.TRUNGUONG) {
      if (cap === Constant.Role_user.LD_CV_BO) {
        this.setState({
          congTrinh: dataCT,
          loaiCT: loaiCT,
          data: data,
          tenCT: data.tenLoaiCongTrinh,
          openModal: true
        })
      } else if (cap === Constant.Role_user.LD_CV_SO) {
        if (dataCT && dataCT.maTinh === maTinh) {
          this.setState({
            congTrinh: dataCT,
            loaiCT: loaiCT,
            data: data,
            tenCT: data.tenLoaiCongTrinh,
            openModal: true
          })
        } else {
          message.warning(MESSAGE.CSCORE_KHONG_CO_QUYEN_TRUY_CAP)
        }
      } else if (cap < Constant.Role_user.LD_CV_BO) {
        this.setState({
          congTrinh: dataCT,
          loaiCT: loaiCT,
          data: data,
          tenCT: data.tenLoaiCongTrinh,
          openModal: true
        })
      }
    } else if (Constant.CAP_TRIEN_KHAI === Constant.CAP_TK.DIAPHUONG) {
      this.setState({
        congTrinh: dataCT,
        loaiCT: loaiCT,
        data: data,
        tenCT: data.tenLoaiCongTrinh,
        openModal: true
      })
    }
  }
  handleCancel = () => {
    this.setState({
      openModal: false
    })
  }
  render() {
    const { data, congTrinh, loaiCT, tenCT, openModal } = this.state;
    const { ctnM_GiayPhepCongTrinh, giayPhepHis, hangMucs, hoSoXinCapPheps, tsHoChuas, thanhTra_KiemTras, tienCapQuyens, thanhTras, bvpckP_View } = data;
    const ttkts = thanhTra_KiemTras ? JSON.parse(thanhTra_KiemTras) : [];
    const tcqs = tienCapQuyens ? JSON.parse(tienCapQuyens) : [];
    let bvkp = [];
    const bhvs = bvpckP_View ? bvkp.concat(bvpckP_View) : []
    return (
      <Modal
        title={typo.cscore_tnn_thong_tin_chi_tiet}
        centered
        closable={true}
        visible={openModal}
        width="80%"
        footer={null}
        onCancel={this.handleCancel}
      >
        <Scrollbars ref="scrollbars" style={{ height: window.innerHeight * 0.8 }} autoHeightMax={window.innerHeight} autoHide autoHideTimeout={1000} autoHideDuration={200}>
          <Container style={{ padding: 20 }}>
            {data.soVanBan &&
              <Row type="flex">
                <Col span={12} style={{ paddingRight: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_license_organization, 1)}</a>} key="1">
                      <Organization data={data} />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_lichsu, 2)}</a>} key="1">
                      <Licenses dataSource={giayPhepHis ? giayPhepHis : []} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>}
            {data.soVanBan && <div className="mt-20" />}
            <Row type="flex">
              {data.soVanBan &&
                <Col span={12} style={{ paddingRight: 10 }}>
                  <Collapse bordered={false} defaultActiveKey={['1']}>
                    <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_ketQuaGP, 3)}</a>} key="1">
                      <LicenseResult data={data} loaiGP="Nước mặt" />
                    </Panel>
                  </Collapse>
                </Col>}
              <Col span={12} style={{ paddingLeft: 10 }}>
                <Collapse bordered={false} defaultActiveKey={['1']}>
                  <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_ct_info, data.soVanBan ? 4 : 1)}</a>} key="1">
                    <ConstructionInfo
                      congTrinh={congTrinh}
                      loaiCT={loaiCT}
                      banDo={data.soVanBan ? "Đã được cấp phép" : "Chưa được cấp phép"}
                      tenCT={tenCT}
                    />
                  </Panel>
                </Collapse>
              </Col>
              {!data.soVanBan &&
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_hangMucCongTrinh, 1.1)} </a>
                  <HangMucTableView dataSource={congTrinh ? congTrinh.hangMucs : []} />
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_thamSoHC, 1.2)} </a>
                  <ThamSoHC mode="presentation" dataSource={tsHoChuas || []} />
                </Col>}
            </Row>
            <div className="mt-20" />
            {data.soVanBan &&
              <Row>
                <Col span={12} style={{ paddingRight: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_tcqktsd, 3.1)} </a>
                  <TCQKTSD dataSource={tcqs || []} mode="presentation" />
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_tittle_BVVBHVS, 3.2)} </a>
                  <BVPCKP dataSource={bhvs || []} mode="presentation" />
                </Col>
              </Row>}
            <div className="mt-20" />
            {data.soVanBan &&
              <Row>
                {Constant.CAP_TRIEN_KHAI === 1 ?
                  <Col span={12} style={{ paddingRight: 10 }}>
                    <a>{helper.generateIndexForLabel(typo.cscore_tnn_ttkt, 3.3)} </a>
                    <TTKT mode="presentation" dataSource={ttkts || []} />

                  </Col>
                  :
                  <Col span={12} style={{ paddingRight: 10 }}>
                    <a>{helper.generateIndexForLabel(typo.cscore_tnn_ThanhTraDN, 3.3)}</a>
                    <ThanhTraDN dataSource={thanhTras || []} mode="presentation" />
                  </Col>
                }
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_fileDinhkem, 3.4)} </a>
                  <FileDinhKemView mode="presentation" dataSource={hoSoXinCapPheps || []} />
                </Col>
              </Row>}
            <div className="mt-20" />
            {data.soVanBan &&
              <Row>
                <Col span={12} style={{ paddingRight: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_hangMucCongTrinh, 4.1)} </a>
                  <HangMucTableView dataSource={congTrinh ? congTrinh.hangMucs : []} />
                </Col>
                <Col span={12} style={{ paddingLeft: 10 }}>
                  <a>{helper.generateIndexForLabel(typo.cscore_tnn_thamSoHC, 4.2)} </a>
                  <ThamSoHC mode="presentation" dataSource={tsHoChuas || []} />
                </Col>
              </Row>}
            <div className="mt-20" />
            {data.soVanBan &&
              <Row>
                <Collapse bordered={false} defaultActiveKey={['1']}>
                  <Panel header={<a>{helper.generateIndexForLabel(typo.cscore_tnn_Giam_Sat_khai_thac, 5)}</a>} key="1">
                    <GioiHanThongSoView giayPhepCT={data.giamSat_KTSD_NuocMat_JSON} />
                  </Panel>
                </Collapse>
              </Row>}
            <ControllerButton>
              <Button
                type="cancel"
                className={styles.buttonMargin}
                onClick={this.handleCancel}
              >
                <Icon type="arrow-right" />
                {typo.cscore_tnn_exit}
              </Button>
            </ControllerButton>
          </Container>
        </Scrollbars>
      </Modal>
    )
  }
}

SurfaceMining.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(SurfaceMining)