import React, {Component} from "react";
import {connect} from 'react-redux'
import helper from "../../../../helper/helper"
class ItemInfo extends Component {
  render() {
    const {item} = this.props
    return (
      item &&
      <table className='detail-table'>
        <tbody className='detail-tbody'>
        <tr>
          <th className='detail-th'>Mã sông suối</th>
          <td className='detail-td'>{item.masong}</td>
        </tr>
        <tr>
          <th className='detail-th'>Tên sông, suối</th>
          <td className='detail-td'>{item.tensongsuoi}</td>
        </tr>
        <tr>
          <th className='detail-th'>Chảy ra sông</th>
          <td className='detail-td'>{item.chayra}</td>
        </tr>
        <tr>
          <th className='detail-th'>Chảy qua</th>
          <td className='detail-td'>{item.dvhc}</td>
        </tr>
        <tr>
          <th className='detail-th'>Chiều dài (Km)</th>
          <td className='detail-td'>{item.chieudai}</td>
        </tr>
        <tr>
          <th className='detail-th'>Diện tích lưu vực (Km2)</th>
          <td className='detail-td'>{item.dienTichLuuVuc}</td>
        </tr>
        <tr>
          <th className='detail-th'>Thuộc hệ thống sông</th>
          <td className='detail-td'>{item.thuocHTSong}</td>
        </tr>
        <tr>
          <th className='detail-th'>Ghi chú</th>
          <td className='detail-td'>{item.ghiChu}</td>
        </tr>
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(ItemInfo);