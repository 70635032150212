import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import Constant, { functionsCode, groupFunctionsCode } from '../../constants/constant'
import Message from "../../components/Messages";
import typo, { MESSAGE } from "../../constants/dictionaries";
import Helper from '../../helper/helper';

class BarChart extends Component {
  getKeys(data) {
    var keys = []
    if (data && data[0]) Object.keys(data[0]).map(key => { if (key !== 'time') keys.push(key) })
    return keys
  }
  ClickTable = (id, indexValue) => {
    var times = indexValue + "-12-31"
   
    const { functions,MaDVHC } = this.props
    const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
    if (id === Constant.TEN_GP.GPCTKTNDD) {

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
          this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

      }
    }
    else if (id === Constant.TEN_GP.GPCTNM) {
     

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
          this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

      }
    }
    else if (id === Constant.TEN_GP.GPCTNXT) {

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
          this.props.history.push(`/cap-phep/xa-thai?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
      }
    }
    else if (id === Constant.TEN_GP.GPCTTDNDD) {

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_TD) ?
          this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
      }
    }
    else if (id === Constant.TEN_GP.GPCTNB) {

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
          this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
      }
    }
    else {
      

      {
        groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_HNKNDD) ?
          this.props.history.push(`/cap-phep/hanh-nghe-khoan-nuoc?NangCao=true&PageNumber=1&PageSize=10&NgayKyTu=${indexValue}&NgayKyDen=${times}&MaDVHC=${MaDVHC}&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

          :
          Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
      }
    }
  }

  render() {
    const { data, hiddenLegends, history } = this.props
    const keys = this.getKeys(data)

    return (
    
      <ResponsiveBar
        onClick={(e) => this.ClickTable(e.id, e.indexValue)}
        data={data}
        keys={keys}
        indexBy="time"
        margin={{ top: 50, right: hiddenLegends ? 10 : 270, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Thời gian',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Số lượng',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#000"
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 220,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 210,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
     
    )
  }
};
const mapPropsToState = (state) => ({ user: state.oidc.user });
export default connect(mapPropsToState)(BarChart)