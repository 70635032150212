import React from 'react'
import { Row, Col, Typography } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import typo from "../../../constants/dictionaries";
import StatusGP from '../../Table/Status/StatusGP'
import { RowTableDetail, TableDetail } from "../../Common/View/TableViewDetail";
import constant from '../../../constants/constant'

const { Text } = Typography;

const ConstructionInfo = ({ congTrinh, loaiCT, banDo, tenCT }) => {
  const {
    tenDVHC,
    diaDiem,
    tenCongTrinh,
    mucDichKTSD,
    luuLuongKT,
    luongNuocKhaiThac,
    nguonNuocKTSD,
    cheDoKhaiThac,
    phuongThucKhaiThac,
    thuocSong,
    thuocLVS,
    luuLuongXaDongChayMin,
    luuLuongKhaiThacMax,
    mucNuocDangBinhThuong,
    mucNuocCaoNhatTruocLu,
    luuLuongXaDongChayToiThieu,
    congSuatLapMay,
    luuLuongKhaiThacLonNhat,
    luuLuongLonNhatQuaNMTD,
    mucNuocChet,
    mucNuocDonLu,
    dungTichHuuIch,
    dungTichToanBo,
    hangMucs = [],
    tsHoChuas
  } = congTrinh || {};


  return (
    <Row gutter={50}>
      <Col>
        <Row>
          <TableDetail>
            {banDo && <RowTableDetail title={typo.cscore_tnn_tinh_trang}><StatusGP trangthai={banDo} /></RowTableDetail>}
            {banDo && <RowTableDetail title={typo.cscore_tnn_groupUnit}>{tenCT}</RowTableDetail>}
            <RowTableDetail title={typo.cscore_tnn_license_construction}>{tenCongTrinh}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_license_mining_location}>{diaDiem}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_license_purpose}>{mucDichKTSD}</RowTableDetail>
            {/*<RowTableDetail title={typo.cscore_tnn_luuLuongKhaiThac}>{luuLuongKT}</RowTableDetail>*/}
            <RowTableDetail title={typo.cscore_tnn_luongNuocKhaiThac}>{luongNuocKhaiThac}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_license_mining_mode}>{cheDoKhaiThac}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_license_mining_method}>{phuongThucKhaiThac}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_license_resource}>{nguonNuocKTSD}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_thuocSong}>{thuocSong}</RowTableDetail>
            <RowTableDetail title={typo.cscore_tnn_thuocLVS}>{thuocLVS}</RowTableDetail>
            {loaiCT === constant.DICT_LOAI_CT.NM_HOCHUA &&
              <>
                <RowTableDetail title={typo.cscore_tnn_luuLuongXaDongChayToiThieu}>{luuLuongXaDongChayMin}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_luuLuongKhaiThacLonNhat}>{luuLuongKhaiThacMax}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_dungTichHuuIch}>{dungTichHuuIch}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_dungTichToanBo}>{dungTichToanBo}</RowTableDetail>
              </>}

            {loaiCT === constant.DICT_LOAI_CT.NM_THUYDIEN &&
              <>
                <RowTableDetail title={typo.cscore_tnn_congSuatLapMay}>{congSuatLapMay}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_luuLuongMaxQuaNMTD}>{luuLuongLonNhatQuaNMTD}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_luuLuongXaDongChayToiThieu}>{luuLuongXaDongChayToiThieu}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_dungTichHuuIch}>{dungTichHuuIch}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_dungTichToanBo}>{dungTichToanBo}</RowTableDetail>
              </>}

            {(loaiCT === constant.DICT_LOAI_CT.NM_THUYDIEN || loaiCT === constant.DICT_LOAI_CT.NM_HOCHUA) &&
              <>
                <RowTableDetail title={typo.cscore_tnn_mucNuocChet}>{mucNuocChet}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_mucNuocDonLu}>{mucNuocDonLu}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_mucNuocDangBinhThuong}>{mucNuocDangBinhThuong}</RowTableDetail>
                <RowTableDetail title={typo.cscore_tnn_mucNuocCaoNhatTruocLu}>{mucNuocCaoNhatTruocLu}</RowTableDetail>
              </>}
          </TableDetail>
        </Row>
      </Col>
    </Row>
  )
};

ConstructionInfo.propTypes = {
  congTrinh: PropTypes.any.isRequired,
  loaiCT: PropTypes.number.isRequired
};

export default connect()(ConstructionInfo)