import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const { Title } = Typography;

// Components
const TNN = lazy(() => import('./TNN'))
const TTHC = lazy(() => import('./ThuTucHanhChinh'))
export default ({ match }) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/TNN`} component={TNN} />
        <Route exact path={`${match.path}/TTHC`} component={TTHC} />
      </Switch>
    </Suspense>
  )
}