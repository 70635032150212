/*
 *  @2019 Cs-core project
 *
 */

/* eslint-disable */
import React from "react";
import numeral from "numeral";
import moment from "moment";
import { trim, isEmpty } from "lodash";
import querystring from "query-string";
import Auth from "../services/auth";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Api_spatial from "../api/api.spatial";
import Message from "../components/Messages";
import Api from "../api";
import constant from "../constants/constant"
import Api_tnn from "../api/api.tnn";
import jwt from 'jwt-decode'
import { loadModules } from 'esri-loader';
import { GISPOTTAL } from "../config"
const option = { url: 'https://js.arcgis.com/4.20/' }
const Helper = {
  // FIXME: form comment
  iterationCopy: src => {
    const target = {};
    for (const prop in src) {
      if (src.hasOwnProperty(prop)) {
        target[prop] = src[prop];
      }
    }
    return target;
  },

  DownloadHDSD(user) {
    return new Api_tnn()
      .DownloadFileHDSD({
        token: user.access_token,
      })
  },

  formatDate(value, format = "DD/MM/YYYY") {
    if (!value) {
      return null;
    }
    return moment(value).format(format);
  },

  formatTableDate(value, format) {
    if (!value) {
      return null;
    }
    return moment(value).format(format || "DD/MM/YYYY");
  },

  formatUTCDate(value, format) {
    if (!value) {
      return null;
    }
    return moment(value)
      .set({ hour: "0", minute: "0", second: "0", millisecond: "0" })
      .toISOString();
  },

  formatDateBold(value, format) {
    return (
      <span style={{ fontWeight: 600, color: "#e8be22" }}>
        {moment(value).format(format || "DD MMM YYYY")}
      </span>
    );
  },

  renderDateFromTo(from, to) {
    if (!from) {
      return <span>To {this.formatDateBold(to)}</span>;
    }

    return (
      <span>
        From {this.formatDateBold(from)} to {this.formatDateBold(to)}
      </span>
    );
  },

  formatTime(value, format) {
    if (!value) {
      return null;
    }
    return moment(value).format(format || "DD MMM YYYY hh:mm A");
  },

  formatDateTimeSecond(value, format) {
    if (!value) {
      return null;
    }
    return moment(value).format(format || "DD-MM-YYYY HH:mm:ss");
  },

  getLocateTime(value) {
    if (!value) {
      return null;
    }
    return moment.utc(value).local();
  },

  compareDate: (date1, date2) => {
    const moment1 = moment(date1, "YYYY-MM-DD");
    const moment2 = moment(date2, "YYYY-MM-DD");
    return moment1.isAfter(moment2);
  },

  subtractDate: (date, number, unit = 'd') => {
    return moment(date).subtract(number, unit)
  },

  copyToClipboard: r => {
    const inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = r;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();
  },

  getFileNameFromInfoSheet: fileName => {
    try {
      const a = fileName.split("/");
      return a[a.length - 1];
    } catch (err) {
      return null;
    }
  },

  isSuperAdmin: () => {
    const userInfo = new Auth().getUserInfo();
    if (userInfo) {
      return userInfo["Role"].split("-").includes("SuperAdmin");
    }
  },

  isSystemAdmin: () => {
    const userInfo = new Auth().getUserInfo();
    return userInfo && userInfo["Role"].split("-").includes("SystemAdmin");
  },

  getBase64FromFile: async (fileContent, cb) => {
    if (!fileContent) {
      return null;
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let file;
      fileReader.onload = function (fileLoadedEvent) {
        // let file = fileLoadedEvent.target.result;
        file = fileLoadedEvent.target.result;
        // Print data in console
        // cb(file);
        resolve(file);
      };
      fileReader.readAsDataURL(fileContent);
    });
  },

  getBase64FileFromInput: (input, cb) => {
    if (input.files.length > 0) {
      Helper.getBase64FromFile(input.files[0], cb);
    } else {
      return null;
    }
  },

  formatMoney: (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = amount < 0 ? "-" : "";
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
          : "")
      );
    } catch (e) {
    }
  },

  formatPhoneNumber: phoneNo => {
    if (!phoneNo) {
      return null;
    }
    if (phoneNo.includes("+")) {
      return `(${phoneNo})`;
    } else {
      return `(+${phoneNo})`;
    }
  },

  formatMoneyNumeral: function (value) {
    if (value === undefined || value === null || value === "") {
      return "";
    }
    return numeral(value).format("$0,0.00");
  },

  formatMoneyNumeralTrueData: function (value) {
    if (value === undefined || value === null || value === "") {
      return "";
    }
    return numeral(value).format("$0,0.00");
  },

  formatPercentage: function (value) {
    return numeral(Number(value / 100)).format("0.00%");
  },

  openInNewTab: function (url) {
    const win = window.open(url, "_blank");
    win.focus();
  },

  renderAddressCombine: function ({
    Block = "",
    Street = "",
    Floor = "",
    Unit = "",
    Building = "",
    NameOfTown = "Singapore",
    Postal = ""
  } = {}) {
    return trim(
      `${Block ? Block : ""} ${Street ? Street : ""} ${Floor || Unit ? "#" : ""
        }${Floor ? Floor : ""}${Floor && Unit ? "-" : ""}${Unit ? Unit : ""} ${Building ? Building : ""
        } ${NameOfTown ? NameOfTown : ""} ${Postal ? Postal : ""}`.replace(
          /\s+/g,
          " "
        )
    );
  },

  stringtifyURL: function ({ path = "", params = {} }) {
    return path + "?" + querystring.stringify(params);
  },

  offuscateString: function (str, isEmail = false) {
    if (!str || typeof str !== "string") {
      return null;
    }
    return isEmail ? `${str.slice(0, 4)} ****` : `**** ${str.slice(-4)}`;
  },

  trySplit(value) {
    if (!value) {
      return [];
    }
    if (typeof value !== "string") {
      return [];
    }
    return value.split(",").map(o => o.trim());
  },

  parseNumeral: number => {
    return numeral(number).value();
  },

  stringIsNullOrEmpty: str => {
    if (!str) {
      return true;
    }
    if (!str.trim()) {
      return true;
    }
    return false;
  },
  // Curry function
  sortString: field => (a, b) => {
    return a[field] !== b[field] ? (a[field] < b[field] ? -1 : 1) : 0;
  },
  sortArrayNumber: (array, field) => {
    array.sort(function (a, b) {
      return a[field] - b[field];
    });
    return array
  },
  sortArrayString: (array) => {
    array.sort(function (a, b) {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return array
  },

  // generate placeholder
  genSelectPL: (text) => {
    return `--- ${text} ---`
  },

  // update object in array
  replaceItem: (newObj, array, field = "id") => {
    return array.reduce((accumulator, currentObj) => {
      if (currentObj[field] === newObj[field]) {
        return [...accumulator, newObj]
      } else {
        return [...accumulator, currentObj]
      }
    }, [])
  },
  replaceItemNamThu: (newObj, array, field = "namThu") => {
    return array.reduce((accumulator, currentObj) => {
      if (currentObj[field] === newObj[field]) {
        return [...accumulator, newObj]
      } else {
        return [...accumulator, currentObj]
      }
    }, [])
  },

  removeItemNamThu: (targetObj, array) => {
    return array.reduce((accumulator, currentObj) => {
      if (currentObj.namThu === targetObj.namThu) {
        return accumulator
      } else {
        return [...accumulator, currentObj]
      }
    }, [])
  },

  removeItem: (targetObj, array) => {
    return array.reduce((accumulator, currentObj) => {
      if (currentObj.id === targetObj.id) {
        return accumulator
      } else {
        return [...accumulator, currentObj]
      }
    }, [])
  },

  updateArray: (item, dataSource, isEdit) => {
    var newData = [];
    if (isEdit) {
      if (item.id === 0) {
        dataSource.map((x, idx) => {
          if (x.stt === item.stt) {
            dataSource.splice(idx, 1);
          }
        });
        newData = dataSource;
      } else {
        newData = dataSource.map((x, idx) => ({
          ...x,
          daXoa: x.id === item.id ? 1 : x.daXoa
        }));
      }
    } else {
      dataSource.map((x, idx) => {
        if (x.stt === item.stt) {
          dataSource.splice(idx, 1);
        }
      });
      newData = dataSource;
    }
    return newData
  },


  addSttToDataSource: (dataSource) => {
    if (!dataSource) return [];
    let newItems = [];
    if (dataSource) {
      newItems = dataSource.map((item, index) => ({ ...item, stt: index + 1 }))
    }
    return newItems;
  },

  addSttToDatasourceForReport: (datasource) => {
    return datasource.map((item, index) => ({ stt: index === 0 ? "" : index, ...item }))
  },

  leaveLastToFirst: (datasource) => {
    var dataSource2 = datasource
    if (dataSource2 && dataSource2.length > 0) {
      var dataSource3 = [dataSource2[dataSource2.length - 1]]
      dataSource2 = dataSource2.slice(0, dataSource2.length - 1)
      dataSource2 = dataSource3.concat(dataSource2)
    }
    return dataSource2
  },

  checkInt: value => {
    return (!isNaN(value) && /^-?(0|[1-9][0-9]*)?$/.test(value)) || value === '' || value === '-'
  },
  checkFloat: value => {
    return (!isNaN(value) && /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/.test(value)) || value === '' || value === '-'
  },

  getFieldValueFromID: (id, array, field = "ten") => {
    if (id && !isEmpty(array)) {
      const item = array.find((item) => item.id === id);
      return item[field]
    }
    return null;
  },

  exportPdf: (dom, orientation, fileName) => {
    if (!dom || !fileName) return
    html2canvas(dom)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF(orientation);//'landscape', 'portrait'
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const domWidth = dom.offsetWidth;
        const domHeight = dom.offsetHeight;
        var width = domWidth * 0.2825880907072629;
        var height = domHeight * 0.27962923509394877;
        if (width > pdfWidth) {
          width = width * (pdfWidth / width)
          height = height * (pdfWidth / width)
        }
        if (height > pdfHeight) {
          width = width * (pdfHeight / height)
          height = height * (pdfHeight / height)
        }
        const x = (pdfWidth - width) > 0 ? Math.round((pdfWidth - width) / 2) : 0
        const y = (pdfHeight - height) > 0 ? Math.round((pdfHeight - height) / 2) : 0
        pdf.addImage(imgData, 'JPEG', x, y, width, height);
        pdf.save(fileName);
      })
      ;
  },
  /*
  * TODO ============================ Nghiệp vụ =============================================*/
  gettenDVHC: (xas, huyens, tinhs, maXa, maHuyen, maTinh) => {
    var xa = (maXa && xas) ? xas.filter((i) => i.maXa === maXa) : undefined;
    var huyen = (maHuyen && huyens) ? huyens.filter((i) => i.maHuyen === maHuyen) : undefined;
    var tinh = (maTinh && tinhs) ? tinhs.filter((i) => i.maTinh === maTinh) : undefined;
    return "" + (((xa && xa.length > 0) ? (xa[0].tenDVHC + ", ") : "") + ((huyen && huyen.length > 0) ? (huyen[0].tenDVHC + ", ") : "") + ((tinh && tinh.length > 0) ? tinh[0].tenDVHC : ""))
  },

  getToaDoX: (toaDo) => {
    if (!toaDo) {
      return null
    }
    return toaDo.split(",")[0]
  },
  getToaDoY: (toaDo) => {
    if (!toaDo) {
      return null
    }
    return toaDo.split(",")[1]
  },

  mergeToaDo: (x, y) => {
    if (x && y) {
      return `${x},${y}`
    }
    return null
  },

  validateToaDo: toaDo => {
    if (!toaDo || toaDo === "") return undefined;
    var pair = toaDo.split(",");
    if (!pair || pair.length !== 2) return "Tọa độ có định dạng x,y";
    if (pair[0] === "") return "x không được để trống";
    if (pair[1] === "") return "y không được để trống";
    if (!Helper.checkFloat(pair[0])) return "x phải là số nguyên";
    if (!Helper.checkFloat(pair[1])) return "y phải là số nguyên";
  },
  validateToaDoVung: toaDo => {
    if (toaDo && toaDo !== "" && toaDo?.length > 0) {
      if (toaDo.split(";").length === 2) return false
    }
    return true
  },

  // Tạo list mã chức năng theo nhóm chức năng
  handleGroupFunctions: (listFunc, groupFunctionsCode) => {
    if (!isEmpty(listFunc)) {
      return listFunc.reduce(function (obj, item) {
        obj[item.moduleCode] = obj[item.moduleCode] || [];
        obj[item.moduleCode].push(item.maChucNang);
        return obj;
      }, {});
    }
    return []
  },

  handleGetEditableFunction: (listFunctions, pageCode) => {
    if (!isEmpty(listFunctions)) {
      return listFunctions.find(item => item.maChucNang === pageCode)
    }
  },

  isNullOrUndefined: (param) => {
    return param === null || param === undefined || param === '' || param.length <= 0
  },



  isNullOrEmptyItems: (param) => {
    return param === null || param === undefined || param.length <= 0
  },


  /*TODO: =================== Cấp phép ========================*/

  /*TODO: Khong gian*/
  saveCTDiem: async (ct, token, vl) => {
    const { id, tenCongTrinh, loaiCongTrinh, nhomCongTrinh, toaDo, hangMucs, diemXaThais, giengs, diemKhaiThacs, giengThamDos, kinhTuyenTruc, muiChieu } = ct;

    // Get KTT by ID
    const { degree, minute } = await Helper.getKTTById(token, kinhTuyenTruc);

    const congtrinh = !isEmpty(toaDo) ? {
      idcongtrinh: id,
      toado: toaDo,
      tencongtrinh: tenCongTrinh,
      loaicongtrinh: loaiCongTrinh,
      nhomcongtrinh: nhomCongTrinh,
      macongtrinh: ct.maCongTrinh ? ct.maCongTrinh : undefined,
      sogiayphep: vl.soVanBan ? vl.soVanBan : undefined,
      idloaicapphep: vl.loaiHinhGP ? vl.loaiHinhGP : undefined,
      loaigiayphep: Helper.getFieldValueFromID(vl.loaiHinhGP, constant.LOAI_HINH_GIAY_PHEP, "ten"),
      ngaycapphep: vl.ngayKy ? vl.ngayKy : undefined,
      iddonvixcp: vl.idDonViXinCapPhep ? vl.idDonViXinCapPhep : undefined,
      tendonvixcp: vl.diaChiDonViXCP ? vl.diaChiDonViXCP : undefined,
      matinh: ct.maTinh ? ct.maTinh : undefined,
      mahuyen: ct.maHuyen ? ct.maHuyen : undefined,
      maxa: ct.maXa ? ct.maXa : undefined,
      tendvhc: ct.maTinh ? ct.maTinh : undefined + "," + ct.maHuyen ? ct.maHuyen : undefined + "," + ct.maXa ? ct.maXa : undefined,
    } : null;

    const hangmucs = !isEmpty(hangMucs) ? hangMucs.map(i => (
      {
        toado: i.toaDo,
        idcongtrinh: id,
        tenhangmuc: i.tenHangMuc,
        idhangmuc: i.id,
        loaicongtrinh: i.loaiCongTrinh ? i.loaiCongTrinh : constant.DICT_LOAI_CT.NB_KT,
        nhomcongtrinh: i.nhomCongTrinh ? i.nhomCongTrinh : constant.DICT_NHOM_CT.NB
      })) : null;

    const diemxathais = !isEmpty(diemXaThais) ? diemXaThais.map(i => ({
      geom: i.toaDo,
      idcongtrinh: id,
      diadiem: i.diaDiem,
      iddiemxathai: i.id,
      loaicongtrinh: constant.DICT_LOAI_CT.XT,
      nhomcongtrinh: constant.DICT_NHOM_CT.XT,
      tencongtrinh: tenCongTrinh,
    })) : null;

    const spa_giengs = !isEmpty(giengs) ? giengs.map(i => ({
      geom: i.toaDo,
      sohieu: i.soHieu,
      idgieng: i.id,
      idcongtrinh: id,
      loaicongtrinh: loaiCongTrinh,
      nhomcongtrinh: nhomCongTrinh,
      tencongtrinh: tenCongTrinh,
    })) : null;

    const giengthamdos = !isEmpty(giengThamDos) ? giengThamDos.map(i => ({
      geom: i.toaDo,
      sohieu: i.soHieu,
      idcongtrinh: id,
      loaicongtrinh: loaiCongTrinh,
      nhomcongtrinh: nhomCongTrinh,
      tencongtrinh: tenCongTrinh,
    })) : null;

    const diemkhaithacs = !isEmpty(diemKhaiThacs) ? diemKhaiThacs.map(i => ({
      geom: i.toaDo,
      sohieu: i.soHieu,
      idcongtrinh: id,
      loaicongtrinh: loaiCongTrinh,
      nhomcongtrinh: nhomCongTrinh,
      tencongtrinh: tenCongTrinh,
    })) : null;

    const point_CT = {
      congtrinh,
      diemxathais,
      hangmucs,
      diemkhaithacs,
      giengthamdos,
      giengs: spa_giengs
    };
    new Api_spatial().post_CTDiem({ token, point_CT, degree, minute, muiChieu: muiChieu || 0 })
      .then(res => Message.success("Lưu tọa độ điểm thành công"))
      .catch(err => Message.error(err.message))
  },

  saveCTVung: async (ct, token, vl) => {
    const { id, tenCongTrinh, loaiCongTrinh, nhomCongTrinh, toaDo, kinhTuyenTruc, muiChieu } = ct;

    // Get KTT by ID
    const { degree, minute } = await Helper.getKTTById(token, kinhTuyenTruc);

    const area_CT = {
      idCongTrinh: id,
      toado: toaDo,
      tenCongTrinh: tenCongTrinh,
      loaiCongTrinh: loaiCongTrinh,
      nhomCongTrinh: nhomCongTrinh,
      macongtrinh: ct.maCongTrinh ? ct.maCongTrinh : undefined,
      sogiayphep: vl.soVanBan ? vl.soVanBan : undefined,
      idloaicapphep: vl.loaiHinhGP,
      loaigiayphep: Helper.getFieldValueFromID(vl.loaiHinhGP, constant.LOAI_HINH_GIAY_PHEP, "ten"),
      ngaycapphep: vl.ngayKy ? vl.ngayKy : undefined,
      iddonvixcp: vl.idDonViXinCapPhep ? vl.idDonViXinCapPhep : undefined,
      tendonvixcp: vl.diaChiDonViXCP ? vl.diaChiDonViXCP : undefined,
      matinh: ct.maTinh ? ct.maTinh : undefined,
      mahuyen: ct.maHuyen ? ct.maHuyen : undefined,
      maxa: ct.maXa ? ct.maXa : undefined,
      tendvhc: ct.maTinh ? ct.maTinh : undefined + "," + ct.maHuyen ? ct.maHuyen : undefined + "," + ct.maXa ? ct.maXa : undefined,
    };
    if (degree !== 0) {
      new Api_spatial().post_CTVung({ token, area_CT, degree, minute, muiChieu: muiChieu || 0 })
        .then(res => Message.success("Lưu tọa độ vùng thành công"))
        .catch(err => Message.error(err.message))
    }
  },

  funcWait: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve('bac'), 1000)
    })
  },

  getKTTById: async (token, id) => {
    return new Promise((resolve, reject) => {
      if (id) {
        new Api().Getdmkinhtuyentruc({ token, id })
          .then(res => {
            if (res) {
              resolve({ degree: res.do, minute: res.phut });
            } else {
              resolve({ degree: 0, minute: 0 });
            }
          })
          .catch(err => {
            Message.error(err.message);
            resolve({ degree: 0, minute: 0 });
          })
      } else {
        resolve({ degree: 0, minute: 0 });
      }
    })
  },

  generateIndexForLabel: (label, index) => {
    return `${index}. ${label}`
  },
  timeoutPromise(ms, promise, type) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error("promise timeout"))
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          if (res.ok) {
            if (res.status === 299) {
              return res.text().then(msg => {
                resolve({
                  code: res.status,
                  message: msg,
                  type: "warning"
                })
              })
            } else {
              if (type === "json") {
                return res.json().then((data) => {
                  resolve({
                    code: 200,
                    data: data,
                    type: "success"
                  })
                })
              } else if (type === "text") {
                return res.text().then((data) => {
                  resolve({
                    code: 200,
                    data: data,
                    type: "success"
                  })
                })
              }
            }
          } else {
            if (res.status === 500) {
              return res.text().then(msg => {
                resolve({
                  code: res.status,
                  message: msg,
                  type: "error"
                })
              })
            } else {
              resolve({
                code: res.status,
                message: res ? res.statusText : "",
                type: "error"
              })
            }
          }
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    })
  },
  getToken: (access_token, check) => {
    if (check < 2) {
      return new Promise((resolve, reject) => {
        new Api_tnn().GenToken({ token: access_token })
          .then(response => {
            if (response) {
              return resolve(JSON.parse(response).token)
            } else {
              return resolve(false)
            }
          })
          .catch(err => {
            return resolve(false)
          });
      })
    } else {
      return false
    }
  },
  checkToken: async (access_token, token) => {
    let tokenDay = token.expires
    let day = new Date().valueOf()
    if (tokenDay > day) {
      return false
    } else {
      return new Promise((resolve, reject) => {
        new Api_tnn().GenToken({ token: access_token })
          .then(response => {
            if (response) {
              return resolve(JSON.parse(response).token)
            } else {
              return resolve(null)
            }
          })
          .catch(err => {
            return reject(err)
          });
      })
    }
  },

  getToken_lgsp: (access_token, check) => {
    if (check < 2) {
      return new Promise((resolve, reject) => {
        new Api_tnn().GenToken_LGSP({ token: access_token })
          .then(response => {
            if (response) {
              return resolve(JSON.parse(response).token)
            } else {
              return resolve(false)
            }
          })
          .catch(err => {
            return resolve(false)
          });
      })
    } else {
      return false
    }
  },
  checkToken_lgsp: async (access_token, token) => {
    const user = jwt(token)
    let tokenDay = user.exp * 1000
    let day = new Date().valueOf()
    if (tokenDay > day) {
      return false
    } else {
      return new Promise((resolve, reject) => {
        new Api_tnn().GenToken_LGSP({token: access_token})
          .then(res => {
            if (res) {
              let token_ = JSON.parse(res)
              return resolve(token_.access_token)
            } else {
              return resolve(false)
            }
          })
          .catch(err => {
            return reject(err)
          });
      })
    }
  },
  fetch_api_gp: async (token, selectedRow) => {
    var results = await loadModules([
      "esri/tasks/QueryTask",
      "esri/tasks/support/Query"
    ], option)
    if (results) {
      let dulieu = await Helper.exec_task_find_gp(results[0], results[1], token, selectedRow)
      return dulieu
    }
  },
  exec_task_find_gp: async (QueryTask, Query, token, selectedRow) => {
    let citiesLayerUrl = `${GISPOTTAL}server/rest/services/TNN/TramQuanTracTNN/MapServer/1/query?token=${token}`; // Represents the REST endpoint for a layer of cities.
    let queryTask = new QueryTask({
      url: citiesLayerUrl
    });
    let query = new Query();
    query.f = "json"
    query.spatialRel = "esriSpatialRelIntersects"
    query.returnGeometry = false;
    query.outFields = ["*"];
    query.where = `(SoGiayPhep = '${selectedRow.soVanBan}' AND NgayCapPhep = date '${moment(new Date(selectedRow.ngayKy)).format("YYYY-MM-DD")}')`;
    // When resolved, returns features and graphics that satisfy the query.
    try {
      let results = await queryTask.execute(query)
      if (results) {
        let dulieu = await Helper.then_api_find_gp(results, QueryTask, Query, token)
        return dulieu
      } else { return false }
    } catch (error) {
      return false
    }
  },
  then_api_find_gp: async (results, QueryTask, Query, token) => {
    let data = []
    if (results.features && results.features.length > 0) {
      results.features.map((item, idx) => {
        data.push(item.attributes)
      })
    } else { return false }
    if (data && data.length > 0) {
      let dulieu = await Helper.exec_task_find_chiso(data[0].KyHieuCongTrinh, QueryTask, Query, token)
      return dulieu
    } else { return false }
  },
  exec_task_find_chiso: async (congtrinh, QueryTask, Query, token) => {
    let citiesLayerUrl = `${GISPOTTAL}server/rest/services/TNN/TramQuanTracTNN/MapServer/0/query?token=${token}`; // Represents the REST endpoint for a layer of cities.
    let queryTask = new QueryTask({
      url: citiesLayerUrl
    });
    let query = new Query();
    query.f = "json"
    query.spatialRel = "esriSpatialRelIntersects"
    query.returnGeometry = false;
    query.outFields = "Status";
    query.groupByFieldsForStatistics = "Status"
    query.where = `(KyHieuCongTrinh = '${congtrinh}')`;
    query.orderByFields = "KyHieuCongTrinh";
    query.outStatistics = [{ "statisticType": "count", "onStatisticField": "KyHieuCongTrinh", "outStatisticFieldName": "KyHieuCongTrinh" }, { "statisticType": "count", "onStatisticField": "KyHieuTram", "outStatisticFieldName": "KyHieuTram" }]
    // When resolved, returns features and graphics that satisfy the query.
    try {
      let results = await queryTask.execute(query)
      if (results) {
        let data = []
        if (results.features && results.features.length > 0) {
          results.features.map((item, idx) => {
            data.push(item.attributes)
          })
          return data
        } else { return false }
      } else return false
    } catch (error) {
      return false
    }
  },
  check_status_gp: (data, row) => {
    let dulieu = data.filter(x => x.sogiayphep === row.soVanBan && x.ngayky.valueOf() === row.ngayKy.valueOf())
    if (dulieu && dulieu.length > 0) {
      return dulieu[0]
    } else return false
  },

  check_suaxoa: (cap, capThamQuyen,trangthai) => {
    if (cap === constant.Role_user.LD_CV_BO) {
      if (capThamQuyen === constant.CAP_TK.TRUNGUONG && trangthai !== 2) {
        return false
      } else if (capThamQuyen === constant.CAP_TK.DIAPHUONG) {
        return true
      } else {
        return true
      }
    } else if (cap === constant.Role_user.LD_CV_SO) {
      if (capThamQuyen === constant.CAP_TK.TRUNGUONG) {
        return true
      } else if (capThamQuyen === constant.CAP_TK.DIAPHUONG && trangthai !== 2) {
        return false
      } else {
        return false
      }
    } else {
      return false
    }
  },
 
  check_suaxoa_vbdp: (cap, maDVHCbyUser, row) => {
    if (cap === constant.Role_user.LD_CV_BO) {
      return true
    } else if (cap === constant.Role_user.LD_CV_SO) {
      if (row.maDVHC === maDVHCbyUser) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  },
  check_pheduyet: (coquyenpheduyet, trangThai, cap, capThamQuyen) => {
    if (coquyenpheduyet === true && trangThai === constant.TRANG_THAI.chopheduyet) {
      if (cap === constant.Role_user.LD_CV_BO) {
        if (capThamQuyen === constant.CAP_TK.TRUNGUONG) {
          return true
        } else if (capThamQuyen === constant.CAP_TK.DIAPHUONG) {
          return false
        } else {
          return false
        }
      } else if (cap === constant.Role_user.LD_CV_SO) {
        return true
      } else {
        return true
      }
    } else {
      return false
    }
  },

  check_trinhpheduyet: (coquyenpheduyet, trangThai, cap, capThamQuyen) => {
    if (coquyenpheduyet === false && (trangThai === constant.TRANG_THAI.trinhpheduyet || trangThai === constant.TRANG_THAI.tuchoiduyet)) {
      if (cap === constant.Role_user.LD_CV_BO) {
        if (capThamQuyen === constant.CAP_TK.TRUNGUONG) {
          return true
        } else if (capThamQuyen === constant.CAP_TK.DIAPHUONG) {
          return false
        } else {
          return false
        }
      } else if (cap === constant.Role_user.LD_CV_SO) {
        return true
      } else {
        return true
      }
    } else {
      return false
    }
  },
  
   isNumber : (value) => {
    return typeof value === 'number' && !isNaN(value);
}
};

export default Helper;
