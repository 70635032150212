import { combineReducers } from 'redux';
import { reducer as oidcReducer } from "redux-oidc";

// reducers
import commonReducer from './common-reducer';
import subscriptionReducer from "../redux/reducers/subscriptionReducer";
import cachingReducer from "../redux/reducers/cachingReducer";
import functionReducer from '../redux/reducers/functionReducer'
// combine

export default combineReducers({
    commonReducer: commonReducer,
    oidc: oidcReducer,
    subscription: subscriptionReducer,
    caching: cachingReducer,
    functions: functionReducer
  });
