import React, { Component } from "react";

class Policy extends Component {
    constructor(props) {
        super(props);
        const { functions } = props;
        this.state = {
        };

    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
               <strong>Chính sách quyền riêng tư</strong> <p>
                  Ứng dụng Giám sát tài nguyên nước của Bộ Tài nguyên và môi trường là ứng dụng miễn phí, dành cho cán bộ, chuyên viên Cục quản lý Tài nguyên nước và lãnh đạo Bộ. Ứng dụng này phục vụ việc giám sát hoạt động khai thác, sử dụng nước trên lãnh thổ Việt Nam của Bộ Tài nguyên và môi trường.
                </p> <p>
                  Trang này được sử dụng để thông báo cho khách truy cập về
                  các chính sách với việc thu thập, sử dụng và tiết lộ Thông tin cá nhân
                  Thông tin nếu có ai quyết định sử dụng Dịch vụ của chúng tôi.
                </p> <p>
                  Nếu bạn chọn sử dụng Dịch vụ của chúng tôi, nghĩa là bạn đồng ý với
                  việc thu thập và sử dụng thông tin liên quan đến việc này
                  chính sách. Thông tin Cá nhân mà chúng tôi thu thập là
                  được sử dụng để cung cấp và cải thiện Dịch vụ. Chúng tôi sẽ không sử dụng hoặc chia sẻ thông tin của bạn với
                  bất kỳ ai ngoại trừ được mô tả trong Chính sách quyền riêng tư này.
                </p> <p>
                  Các điều khoản được sử dụng trong Chính sách Bảo mật này có cùng ý nghĩa
                  như trong Điều khoản và Điều kiện của chúng tôi, có thể truy cập tại
                  Ứng dụng Giám sát tài nguyên nước của Bộ tài nguyên và môi trường trừ khi được định nghĩa khác trong Chính sách quyền riêng tư này.
                </p> <p><strong>Thu thập và sử dụng thông tin</strong></p> <p>
                  Để có trải nghiệm tốt hơn, khi sử dụng Dịch vụ của chúng tôi, chúng tôi
                  có thể yêu cầu bạn cung cấp cho chúng tôi một số thông tin cá nhân
                  thông tin nhận dạng, bao gồm nhưng không giới hạn Họ và tên, Số điện thoại, email, hình ảnh từ điện thoại, video từ điện thoại. Thông tin mà
                  chúng tôi yêu cầu sẽ được chúng tôi giữ lại và sử dụng như được mô tả trong chính sách bảo mật này.
                </p> <div><p>
                    Ứng dụng sử dụng các dịch vụ của bên thứ ba có thể thu thập
                    thông tin được sử dụng để nhận dạng bạn.
                  </p> <p>
                    Liên kết đến chính sách bảo mật của các nhà cung cấp dịch vụ bên thứ ba được sử dụng
                    bởi ứng dụng
                  </p> <ul><li><a href="https://cloud.google.com/maps-platform/terms" target="_blank" rel="noopener noreferrer">Google Maps</a></li></ul></div> <p><strong>Log Data</strong></p> <p>
                  Chúng tôi muốn thông báo cho bạn rằng bất cứ khi nào bạn
                  sử dụng Dịch vụ của chúng tôi, trong trường hợp xảy ra lỗi trong ứng dụng
                  chúng tôi thu thập dữ liệu và thông tin (thông qua bên thứ ba
                  sản phẩm) trên điện thoại của bạn được gọi là Dữ liệu Nhật ký. Dữ liệu Nhật ký này có thể
                  bao gồm thông tin chẳng hạn như Giao thức Internet của thiết bị của bạn
                  (“IP”) địa chỉ, tên thiết bị, phiên bản hệ điều hành,
                  cấu hình của ứng dụng khi sử dụng Dịch vụ của chúng tôi,
                  ngày và giờ bạn sử dụng Dịch vụ và
                  số liệu thống kê.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookie là các tệp có một lượng nhỏ dữ liệu
                  thường được sử dụng làm định danh duy nhất ẩn danh. Những thứ này đã được gửi đi
                  vào trình duyệt của bạn từ các trang web mà bạn truy cập và
                  được lưu trữ trên bộ nhớ trong của thiết bị của bạn.
                </p> <p>
                  Dịch vụ này không sử dụng các “cookie” này một cách rõ ràng. Tuy vậy,
                  ứng dụng có thể sử dụng mã của bên thứ ba và các thư viện sử dụng
                  "Cookie" để thu thập thông tin và cải thiện dịch vụ của họ.
                  Bạn có tùy chọn chấp nhận hoặc từ chối các cookie này
                  và biết khi nào một cookie được gửi đến thiết bị của bạn. nếu bạn
                  chọn từ chối cookie của chúng tôi, bạn có thể không sử dụng được một số
                  các phần của Dịch vụ này.
                </p> <p><strong>Các nhà cung cấp dịch vụ</strong></p> <p>
                  Chúng tôi có thể thuê các công ty bên thứ ba và
                  cá nhân do những lý do sau:
                </p> <ul><li>Để tạo điều kiện cho Dịch vụ của chúng tôi;</li> <li>Để cung cấp Dịch vụ thay mặt chúng tôi;</li> <li>Để thực hiện các dịch vụ liên quan đến Dịch vụ; hoặc</li> <li>Để hỗ trợ chúng tôi phân tích cách Dịch vụ của chúng tôi được sử dụng.</li></ul> <p>
                  Chúng tôi muốn thông báo cho người dùng về Dịch vụ này
                  rằng các bên thứ ba này có quyền truy cập vào Cá nhân của họ
                  Thông tin. Lý do là để thực hiện các nhiệm vụ được giao cho
                  họ thay mặt cho chúng tôi. Tuy nhiên, họ có nghĩa vụ không
                  tiết lộ hoặc sử dụng thông tin cho bất kỳ mục đích nào khác.
                </p> <p><strong>Bảo mật thông tin</strong></p> <p>
                  Chúng tôi đánh giá cao sự tin tưởng của bạn trong việc cung cấp cho chúng tôi
                  Thông tin Cá nhân, do đó chúng tôi đang cố gắng sử dụng cho mục đích thương mại
                  có thể chấp nhận được để bảo vệ nó. Nhưng hãy nhớ rằng không có phương pháp
                  truyền qua internet, hoặc phương thức điện tử
                  lưu trữ an toàn và đáng tin cậy 100% và chúng tôi không thể
                  đảm bảo an ninh tuyệt đối của nó.
                </p> <p><strong>Liên kết đến các trang web khác</strong></p> <p>
                  Dịch vụ này có thể chứa các liên kết đến các trang web khác. Nếu bạn nhấp vào
                  một liên kết của bên thứ ba, bạn sẽ được dẫn đến trang web đó. Ghi chú
                  rằng các trang web bên ngoài này không do chúng tôi điều hành.
                  Do đó, chúng tôi đặc biệt khuyên bạn nên xem lại
                  Chính sách bảo mật của các trang web này. Chúng ta có
                  không kiểm soát và không chịu trách nhiệm về nội dung,
                  chính sách bảo mật hoặc thực tiễn của bất kỳ trang web của bên thứ ba nào hoặc
                  dịch vụ.
                </p> <p><strong>Quyền riêng tư của trẻ em</strong></p> <div><p>
                    Những Dịch vụ này không dành cho người dưới 18 tuổi.
                  </p></div><p><strong>Các thay đổi đối với Chính sách quyền riêng tư này</strong></p> <p>
                  Chúng tôi có thể cập nhật Chính sách quyền riêng tư của mình từ
                  thỉnh thoảng. Vì vậy, bạn nên xem lại trang này
                  định kỳ cho bất kỳ thay đổi nào. Chúng tôi sẽ
                  thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên
                  trang này.
                </p> <p>Chính sách này có hiệu lực kể từ ngày 10 tháng 04 năm 2022</p> <p><strong>Liên hệ với chúng tôi</strong></p> <p>
                  Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào về
                  Chính sách bảo mật, đừng ngần ngại liên hệ với chúng tôi tại cirensoft@monre.gov.vn.
                </p>
            </div>
        )
    }
}


export default Policy;
