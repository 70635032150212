import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON, Popup, Tooltip } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";

class GeoJson_Layer extends Component {
    constructor(props) {
        super(props);
        const { functions } = props;
        this.state = {
            GeoJson_data: [],
        }

    }



    componentDidMount() {
        const { GeoJson_data } = this.props
        this.setState({GeoJson_data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    render() {
        const { GeoJson_data } = this.state
        const { viewToado } = this.props
        return (
            <div>
                {GeoJson_data.data != null &&
                    <GeoJSON key={GeoJson_data.key} data={GeoJson_data.data} style={this.style} ref="geojson">
                        <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                            <b>{"Công trình: "}</b> {GeoJson_data.data.properties && GeoJson_data.data.properties.tencongtrinh}
                        </Tooltip>
                        {/* <Popup>
                            {GeoJson_data.data.properties && GeoJson_data.data.properties.tencongtrinh}
                        </Popup> */}
                    </GeoJSON>
                }
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

