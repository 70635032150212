import moment from "moment"
export default class CT_GiayPhepCongTrinh {
    constructor(item) {
        this.id =  item.id
        this.cheDoXaThai =  item.cheDoXaThai
        this.chieuCaoDap =  item.chieuCaoDap
        this.chieuDaiDap =  item.chieuDaiDap
        this.chieuRongDap =  item.chieuRongDap
        this.congXuat =  item.congXuat
        this.donViCapPhep =  item.donViCapPhep
        this.dungTichHoChua =  item.dungTichHoChua
        this.idCongTrinh =  item.idCongTrinh
        this.loaiCongTrinh =  item.loaiCongTrinh
        this.luuLuongNuocMax =  item.luuLuongNuocMax
        this.luuLuongNuocMin =  item.luuLuongNuocMin
        this.luuLuongXaThaiMin =  item.luuLuongXaThaiMin
        this.luuLuongXaThaiTB =  item.luuLuongXaThaiTB
        this.mucNuocChet =  item.mucNuocChet
        this.mucNuocDangBT =  item.mucNuocDangBT
        this.ngayCapPhep =  this.formatDate(item.ngayCapPhep)
        this.nguonNuocKT =  item.nguonNuocKT
        this.nguonTiepNhan =  item.nguonTiepNhan
        this.nguonNuocXaSSD =  item.nguonNuocXaSSD
        this.nhomCongTrinh =  item.nhomCongTrinh
        this.soGiayPhep =  item.soGiayPhep
        this.phuongThucXaThai =  item.phuongThucXaThai
        this.quyTrinhVanHanh =  item.quyTrinhVanHanh
        this.soCuaLayNuoc =  item.soCuaLayNuoc
        this.soDiemXa =  item.soDiemXa
        this.soToMay =  item.soToMay
        this.thoiHan =  item.thoiHan
        this.toaDo =  item.toaDo
        this.tongLuongKhaiThac =  item.tongLuongKhaiThac
        this.tongSoGieng =  item.tongSoGieng
        this.ketQuaXuLy =  item.ketQuaXuLy
        this.soVanBan =  item.soVanBan
        this.nguoiKy =  item.nguoiKy
        this.idDonViXinCapPhep =  item.idDonViXinCapPhep
        this.diaChiDonViXCP =  item.diaChiDonViXCP
        this.loaiHinhGP =  item.loaiHinhGP
        this.ngayKy =  item.ngayKy
        this.chucVu =  item.chucVu
        this.ngayBDHieuLuc =  item.ngayBDHieuLuc
        this.ngayKTHieuLuc =  item.ngayKTHieuLuc
        this.coQuanCap =  item.coQuanCap
        this.noiNhan =  item.noiNhan
        this.tenVanBan =  item.tenVanBan
        this.mucDichKhaiThac =  item.mucDichKhaiThac
        this.cheDoKhaiThac =  item.cheDoKhaiThac
        this.phuongThucKhaiThac =  item.phuongThucKhaiThac
        this.thoiHanGiaHan =  item.thoiHanGiaHan
        this.noiDungDieuChinh =  item.noiDungDieuChinh
        this.idCha =  item.idCha
        this.noiDungCapLai =  item.noiDungCapLai
        this.trangThai =  item.trangThai
        this.searchVector =  item.searchVector
        this.idFile = item.idFile
        this.tenTaiLieu = item.tenTaiLieu
        this.hoSoXinCapPheps = item.hoSoXinCapPheps
        }

        formatDate = (date) => {
            if(!date) return date
            date = moment(date)
            if(date.year() === 1) date = undefined
            return date
        }
    }
      