import { combineReducers } from "redux";
import { reducer as oidcReducer } from "redux-oidc";
import subscriptionReducer from "./subscriptionReducer";
import cachingReducer from "./cachingReducer";
import functionReducer from './functionReducer'

export default combineReducers({
  oidc: oidcReducer,
  subscription: subscriptionReducer,
  caching: cachingReducer,
  functions: functionReducer
});
