import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Typography } from "antd";

const { Title } = Typography;

// Components
const TKDKCongTrinhKhaiThacNuocMat = lazy(() =>
  import("./CongTrinhKhaiThacNuocMat/list")
);
const DKTKCongTrinhKhaiThacNuocMatForm = lazy(() =>
  import("./CongTrinhKhaiThacNuocMat/form")
);

const TKDKSuDungNuocMat = lazy(() => import("./SuDungNuocMat/list"));
const SuDungNuocMatForm = lazy(() => import("./SuDungNuocMat/form"));

const DKTKDaoHoAo = lazy(() => import("./DaoHoAo/list"));
const DKTKDaoHoAoForm = lazy(() => import("./DaoHoAo/form"));

const KhaiThacNuoiDuoiDatHoGiaDinh = lazy(() =>
  import("./KhaiThacNuoiDuoiDatHoGiaDinh/list")
);
const KhaiThacNuoiDuoiDatHoGiaDinhForm = lazy(() =>
  import("./KhaiThacNuoiDuoiDatHoGiaDinh/form")
);

const KhaiThacNuocDuoiDatQuyMoNho = lazy(() =>
  import("./KhaiThacNuocDuoiDatQuyMoNho/list")
);
const KhaiThacNuocDuoiDatQuyMoNhoForm = lazy(() =>
  import("./KhaiThacNuocDuoiDatQuyMoNho/form")
);

const KhaiThacNuocDuoDatKhaiThacKS = lazy(() =>
  import("./KhaiThacNuocDuoDatKhaiThacKS/list")
);
const KhaiThacNuocDuoDatKhaiThacKSForm = lazy(() =>
  import("./KhaiThacNuocDuoDatKhaiThacKS/form")
);

const KhaiThacNuocBien = lazy(() => import("./KhaiThacNuocBien/list"));
const KhaiThacNuocBienForm = lazy(() => import("./KhaiThacNuocBien/form"));

export default ({ match }) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route
          exact
          path={`${match.path}/cong-trinh-khai-thac-nuoc-mat`}
          component={TKDKCongTrinhKhaiThacNuocMat}
        />
        <Route
          path={`${match.path}/cong-trinh-khai-thac-nuoc-mat/add`}
          render={() => <DKTKCongTrinhKhaiThacNuocMatForm />}
        />
        <Route
          path={`${match.path}/cong-trinh-khai-thac-nuoc-mat/edit/:id`}
          render={() => <DKTKCongTrinhKhaiThacNuocMatForm />}
        />
        <Route
          exact
          path={`${match.path}/su-dung-nuoc-mat`}
          component={TKDKSuDungNuocMat}
        />
        <Route
          path={`${match.path}/su-dung-nuoc-mat/add`}
          render={() => <SuDungNuocMatForm />}
        />
        <Route
          path={`${match.path}/su-dung-nuoc-mat/edit/:id`}
          render={() => <SuDungNuocMatForm />}
        />
        <Route exact path={`${match.path}/dao-ho-ao`} component={DKTKDaoHoAo} />

        <Route
          path={`${match.path}/dao-ho-ao/add`}
          render={() => <DKTKDaoHoAoForm />}
        />
        <Route
          path={`${match.path}/dao-ho-ao/edit/:id`}
          render={() => <DKTKDaoHoAoForm />}
        />

        <Route
          exact
          path={`${match.path}/ke-khai-khai-thac-ndd-ho-gd`}
          component={KhaiThacNuoiDuoiDatHoGiaDinh}
        />

        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-ho-gd/add`}
          render={() => <KhaiThacNuoiDuoiDatHoGiaDinhForm />}
        />
        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-ho-gd/edit/:id`}
          render={() => <KhaiThacNuoiDuoiDatHoGiaDinhForm />}
        />

        <Route
          exact
          path={`${match.path}/ke-khai-khai-thac-ndd-quy-mo-nho`}
          component={KhaiThacNuocDuoiDatQuyMoNho}
        />

        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-quy-mo-nho/add`}
          render={() => <KhaiThacNuocDuoiDatQuyMoNhoForm />}
        />
        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-quy-mo-nho/edit/:id`}
          render={() => <KhaiThacNuocDuoiDatQuyMoNhoForm />}
        />

        <Route
          exact
          path={`${match.path}/ke-khai-khai-thac-ndd-ks`}
          component={KhaiThacNuocDuoDatKhaiThacKS}
        />

        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-ks/add`}
          render={() => <KhaiThacNuocDuoDatKhaiThacKSForm />}
        />
        <Route
          path={`${match.path}/ke-khai-khai-thac-ndd-ks/edit/:id`}
          render={() => <KhaiThacNuocDuoDatKhaiThacKSForm />}
        />

        <Route
          exact
          path={`${match.path}/ke-khai-khai-thac-nuoc-bien`}
          component={KhaiThacNuocBien}
        />

        <Route
          path={`${match.path}/ke-khai-khai-thac-nuoc-bien/add`}
          render={() => <KhaiThacNuocBienForm />}
        />
        <Route
          path={`${match.path}/ke-khai-khai-thac-nuoc-bien/edit/:id`}
          render={() => <KhaiThacNuocBienForm />}
        />
        {/* <Route path={`${match.path}/xa-thai/:id`} component={DischargeDetail} /> */}
      </Switch>
    </Suspense>
  );
};
