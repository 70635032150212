import React from 'react'
import ReactDOM from 'react-dom'
import {Checkbox} from 'antd'

class EditorCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  getValue = () => {
    const {column} = this.props
    return {[column.key]: this.state.value}
  };

  getInputNode = () => {
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  };

  onChange = (e) => {
    this.setState({value: e.target.checked})
  };
  render() {
    const {value} = this.state;

    return (
      <Checkbox onChange={this.onChange} checked={value} style={{paddingTop: 7, paddingLeft: 9}}/>
    );
  }
}

export default EditorCheckbox;
