import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

const {Title} = Typography;

// Components
const CauHinhThongSoNuoc = lazy(() => import('./CauHinhThongSoDoiTuong'));
const CauHinhThongSoTheoQuyChuan = lazy(() => import('./CauHinhThongSoTheoQuyChuan'));


export default ({match}) => {
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/Cau-hinh-thong-so-doi-tuong`} component={CauHinhThongSoNuoc}/>
        <Route exact path={`${match.path}/Cau-hinh-thong-so-theo-quy-chuan`} component={CauHinhThongSoTheoQuyChuan}/>
     
      </Switch>
    </Suspense>
  )
}