import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Typography } from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const { Title } = Typography;

// Components
const BieuMauSo6 = lazy(() => import('./BieuMauSo6'));
const BieuMauSo9 = lazy(() => import('./BieuMauSo9'));
const BieuMauSo10 = lazy(() => import('./BieuMauSo10'));
const BieuMauSo11 = lazy(() => import('./BieuMauSo11'));
const BieuMauSo12 = lazy(() => import('./BieuMauSo12'));
const BieuMauSo15 = lazy(() => import('./BieuMauSo15'));
const BieuMauSo16 = lazy(() => import('./BieuMauSo16'));
const BieuMauSo17 = lazy(() => import('./BieuMauSo17'));
const BieuMauSo19 = lazy(() => import('./BieuMauSo19'));
const BieuMauSo20 = lazy(() => import('./BieuMauSo20'));
export default ({ match }) => {
  const groupName = 'Biểu mẫu thông tư 31/2018/TT-BTNMT'
  const BieuMauSo6Link = 'bieu-mau-so-6'
  const BieuMauSo9Link = 'bieu-mau-so-9'
  const BieuMauSo10Link = 'bieu-mau-so-10'
  const BieuMauSo11Link = 'bieu-mau-so-11'
  const BieuMauSo12Link = 'bieu-mau-so-12'
  const BieuMauSo15Link = 'bieu-mau-so-15'
  const BieuMauSo16Link = 'bieu-mau-so-16'
  const BieuMauSo17Link = 'bieu-mau-so-17'
  const BieuMauSo19Link = 'bieu-mau-so-19'
  const BieuMauSo20Link = 'bieu-mau-so-20'
 

  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}/${BieuMauSo6Link}`} render={(props) => <BieuMauSo6 {...props} path={`${match.path}/${BieuMauSo6Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo6} />} />
        <Route exact path={`${match.path}/${BieuMauSo9Link}`} render={(props) => <BieuMauSo9 {...props} path={`${match.path}/${BieuMauSo9Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo9} />} />
        <Route exact path={`${match.path}/${BieuMauSo10Link}`} render={(props) => <BieuMauSo10 {...props} path={`${match.path}/${BieuMauSo10Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo10} />} />
        <Route exact path={`${match.path}/${BieuMauSo11Link}`} render={(props) => <BieuMauSo11 {...props} path={`${match.path}/${BieuMauSo11Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo11} />} />
        <Route exact path={`${match.path}/${BieuMauSo12Link}`} render={(props) => <BieuMauSo12 {...props} path={`${match.path}/${BieuMauSo12Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo12} />} />
        <Route exact path={`${match.path}/${BieuMauSo16Link}`} render={(props) => <BieuMauSo16 {...props} path={`${match.path}/${BieuMauSo16Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo16} />} />
        <Route exact path={`${match.path}/${BieuMauSo17Link}`} render={(props) => <BieuMauSo17 {...props} path={`${match.path}/${BieuMauSo17Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo17} />} />
        <Route exact path={`${match.path}/${BieuMauSo19Link}`} render={(props) => <BieuMauSo19 {...props} path={`${match.path}/${BieuMauSo19Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo19} />} />
        <Route exact path={`${match.path}/${BieuMauSo15Link}`} render={(props) => <BieuMauSo15 {...props} path={`${match.path}/${BieuMauSo15Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo15} />} />
        <Route exact path={`${match.path}/${BieuMauSo20Link}`} render={(props) => <BieuMauSo20 {...props} path={`${match.path}/${BieuMauSo20Link}`} groupName={groupName} name={typo.cscore_tnn_BieuMauSo20} />} />
      </Switch>
    </Suspense>
  )
}