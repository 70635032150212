import React, { Component, useState, useRef } from "react";
import L from 'leaflet';
import { MapContainer, TileLayer, WMSTileLayer, LayersControl, useMap } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import CustomWMSLayer from "./ClickInfo";
import Api_tnn from "../../../api/api.tnn";
import message from "../../../components/Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import TTCT_XT from "./ThongTinChiTiet/TTCT_XT";
import TTCT_NM from "./ThongTinChiTiet/TTCT_NM";
import { Modal, Tooltip } from "antd"
import ControllerSearch from "./TimKiem/CustomSearch"
import ControllerButton_Custom from "./GroupButton"
import ChuThich_Custom from "./ChuThich"
import Geocoder_marker from "./geocode"
import BanDoVietNam from "./BanDoVN"
import constants from "../../../constants/constant"
import 'leaflet-measure'
import Api_spatial from "../../../api/api.spatial";
import { APP_GEO_URI } from "../../../config"
import helper from "../../../helper/helper"
let check = 0
const TraCuuTNN = ({ user, cap, maDVHCbyUser, maTinh, dvhcTinhs }) => {
  const center = [16.371824, 107.549195]
  const URL = APP_GEO_URI
  const [data_giayphep, setdata_giayphep] = useState({
    xaThai: {},
    nuocMat: {},
  });
  const [displayTab, setdisplayTab] = useState("none");
  const [displayToaDo, setdisplayToaDo] = useState("none");
  const [displayLayToaDo, setdisplayLayToaDo] = useState("none");
  const [idcongtrinh, setidcongtrinh] = useState(null);
  const [loaicongtrinh, setloaicongtrinh] = useState(null);


  function showData(data) {
    if (data) {
      setdata_giayphep(
        {
          xaThai: data.ctxT_GiayPhep_View,
          nuocMat: data.ctnM_GiayPhep_View,
        })
    }
  }
  function dulieu_congtrinh(idcongtrinh, loaicongtrinh) {
    setidcongtrinh(idcongtrinh)
    setloaicongtrinh(loaicongtrinh)
  }
  function hideTab() {
    if (displayTab === "none") {
      setdisplayTab("block")
    } else {
      setdisplayTab("none")
    }
  }
  function hideToaDo() {
    if (displayToaDo === "block") {
      setdisplayToaDo("none")
    } else {
      setdisplayToaDo("block")
    }
  }
  function hideLayToaDo() {
    if (displayLayToaDo === "block") {
      setdisplayLayToaDo("none")
    } else {
      setdisplayLayToaDo("block")
    }
  }
  function Location_Tinh() {
    const map = useMap();
    if (check === 0) {
      if (Number(constants.CAP_TRIEN_KHAI) === constants.CAP_TK.DIAPHUONG) {
        new Api_spatial()
          .GetSpatialTinhs({
            token: user.access_token,
            tentinh: constants.TEN_DVHC
          })
          .then((res) => {
            if (res && res.result) {
              let toado = res.result.slice(6, -1).split(" ")
              map.flyTo([Number(toado[1]), Number(toado[0])], 10);
              check += 1
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      } else if (Number(constants.CAP_TRIEN_KHAI) === constants.CAP_TK.TRUNGUONG) {
        if (maDVHCbyUser !== "1000" && cap > constants.Role_user.Admin) {
          let tinhs = dvhcTinhs.filter(x => x.id === maDVHCbyUser)
          if (!helper.isNullOrUndefined(tinhs)) {
            new Api_spatial()
              .GetSpatialTinhs({
                token: user.access_token,
                tentinh: tinhs[0].tenDVHC
              })
              .then((res) => {
                if (res && res.result) {
                  let toado = res.result.slice(6, -1).split(" ")
                  map.flyTo([Number(toado[1]), Number(toado[0])], 10);
                  check += 1
                }
              })
              .catch((err) => {
                message.error(err.message);
              });
          }
        }
      }
      return null
    } else {
      return null
    }
  }
  return (
    <div>
      <MapContainer center={center} zoom={6} style={{ height: window.innerHeight - 42 }} className="control_layers">
        <Location_Tinh />
        <ControllerSearch user={user}
          displayTab={displayTab}
          hideTab={() => hideTab()}
          dulieu_congtrinh={(idcongtrinh, loaicongtrinh) => { dulieu_congtrinh(idcongtrinh, loaicongtrinh) }}
          cap={cap}
          maDVHCbyUser={maDVHCbyUser}
          maTinh={maTinh}
          dvhcTinhs={dvhcTinhs}
        />
        <ControllerButton_Custom user={user} displayToaDo={displayToaDo} hideToaDo={() => hideToaDo()} displayLayToaDo={displayLayToaDo} hideLayToaDo={() => hideLayToaDo()} />
        <ChuThich_Custom />
        <LayersControl position="topright">
          <BanDoVietNam opacity={1} />
          <LayersControl.Overlay checked name="Google map vệ tinh (Tham khảo)">
            {Number(constants.CAP_TRIEN_KHAI) === 1 &&
              <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_BO} type={'satellite'} zIndex='3' />
            }
            {Number(constants.CAP_TRIEN_KHAI) === 2 && Number(constants.MA_TINH) === 31 &&
              <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_DP} type={'satellite'} zIndex='3' />
            }
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Google map đường phố (Tham khảo)">
            {Number(constants.CAP_TRIEN_KHAI) === 1 &&
              <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_BO} type={'hybrid'} zIndex='4' />
            }
            {Number(constants.CAP_TRIEN_KHAI) === 2 && Number(constants.MA_TINH) === 31 &&
              <ReactLeafletGoogleLayer apiKey={constants.KEY_GG_DP} type={'hybrid'} zIndex='4' />
            }
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Lớp công trình">
            < WMSTileLayer
              layers={"cscore.spatial" + constants.GEO_WMS + ":tnn_diem"}
              params={{
                transparent: true,
                format: 'image/png',
              }}
              url={URL}
            />
          </LayersControl.Overlay >
          <CustomWMSLayer
            url={URL}
            showData={(data) => showData(data)}
            layer={["cscore.spatial" + constants.GEO_WMS + ":tnn_diem"]}
            user={user}
          />
        </LayersControl>
      </MapContainer >

      <div>
        {data_giayphep.xaThai && (
          <TTCT_XT
            data={data_giayphep.xaThai}
            user={user}
            cap={cap}
            maDVHCbyUser={maDVHCbyUser}
            maTinh={maTinh}
          />
        )}
        {data_giayphep.nuocMat && (
          <TTCT_NM
            data={data_giayphep.nuocMat}
            user={user}
            cap={cap}
            maDVHCbyUser={maDVHCbyUser}
            maTinh={maTinh}
          />
        )}
      </div>
    </div>
  )
}
export default TraCuuTNN;

