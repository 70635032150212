import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
// Services
import userManager from "../../services/auth";

class CallBack extends React.Component {
  constructor() {
    super();
  }

  successCallback = () => {
    const { history } = this.props;
    history.push("");
  };

  errorCallback = err => {

  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div></div>
      </CallbackComponent>
    );
  }
}

const mapStateToProps = state => ({
  oidc: state.oidc
});

export default connect(mapStateToProps)(CallBack);
