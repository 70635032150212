import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON, Popup } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import icon from '../../../assets/images/kk_3.png';
import iconShadow from '../../../assets/images/kk_2.png';
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import constants from "../../../constants/constant"
import Helper from "../../../helper/helper";

class GeoJson_Layer extends Component {
    constructor(props) {
        super(props);
        const { functions } = props;
        this.state = {
            GeoJson_data: [],
        }

    }



    componentDidMount() {
        const { ktt_do, ktt_phut, muichieu, toado } = this.props
        if (!Helper.isNullOrEmptyItems(ktt_do) && !Helper.isNullOrEmptyItems(ktt_phut) && !Helper.isNullOrEmptyItems(muichieu) && !Helper.isNullOrEmptyItems(toado)) {
            this.CheckCongTrinhVung(ktt_do, ktt_phut, muichieu, toado)
          }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { ktt_do, ktt_phut, muichieu, toado } = this.props
        if (prevProps.ktt_do !== ktt_do || prevProps.ktt_phut !== ktt_phut || prevProps.muichieu !== muichieu || prevProps.toado !== toado) {
            if (!Helper.isNullOrEmptyItems(ktt_do) && !Helper.isNullOrEmptyItems(ktt_phut) && !Helper.isNullOrEmptyItems(muichieu) && !Helper.isNullOrEmptyItems(toado)) {
                this.CheckCongTrinhVung(ktt_do, ktt_phut, muichieu, toado)
            }
          }
    }

    CheckCongTrinhVung = (ktt_do, ktt_phut, muichieu, toado) => {
        const { zoomOut } = this.props
        new Api_spatial()
            .CheckCongTrinhVung({
                token: this.props.user.access_token,
                ktt_do: ktt_do,
                ktt_phut: ktt_phut,
                muichieu: muichieu,
                toado: toado,
            })
            .then((res) => {
                if (res != null && res.result !== null) {
                    const a = JSON.parse(res.result)
                    const newGeom = { newGeom: (delete a.geometry['crs'], a) };
                    const Json_newGeom = { ...newGeom.newGeom }
                    const key = JSON.stringify(Json_newGeom)
                    this.setState({
                        GeoJson_data: {
                            data: Json_newGeom,
                            key: key
                        }
                    })
                    {
                        var arr = Json_newGeom.geometry.coordinates[0]
                        var minX, maxX, minY, maxY;
                        for (var i = 0; i < arr.length; i++) {
                            minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
                            maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
                            minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
                            maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
                        }
                        var latlong = [(minY + maxY) / 2, (minX + maxX) / 2]
                        zoomOut(latlong)
                    }
                } else {
                    message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
                }
            })
            .catch((err) => {
                //message.error(err.message);
            });
    };

    render() {
        const { GeoJson_data } = this.state
        return (
            <div>
                {GeoJson_data.data != null &&
                    <GeoJSON key={GeoJson_data.key} data={GeoJson_data.data} style={this.style} ref="geojson">
                    </GeoJSON>
                }
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

