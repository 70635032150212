/*
 *  @2019 CS-Core project.
 *
 */

import APIService from "./APIService";
import { async } from "q";
import { APP_NAME } from '../config'
export default class Api {
    /*TODO: [Common] GET functions by role*/
    getFunctionsByRole = async ({ token, app_code = APP_NAME } = {}) => {
        return new APIService({ token: token }).getData({
            path: "functions/funcbyrole",
            data: { app_code }
        })
    };
    Get_Apps = async ({ token, page, size, strSearch } = {}) => {
        return new APIService({ token: token }).getData({
            path: "apps",
            data: { page, size, strSearch }
        });
    };

    GetQT_DonViDos = async ({ token, page, size, strSearch } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMDonViDo`,
            data: { page, size, strSearch }

        });
    };
    /*TODO: [Common] GET profilr by user*/
    Profile = async ({ token } = {}) => {
        return new APIService({ token: token }).getData({
            path: "user/profile",
        })
    };

    /*TODO: [Common] getTinhs*/
    getTinhs = async ({ token } = {}) => {
        return new APIService({ token: token }).getData({ path: "dmdvhc/tinh" })
    };

    /*TODO: [Common] getHuyenByTinhs*/
    getHuyenByTinhs = async ({ token, maTinh } = {}) => {
        return new APIService({ token: token }).getData({ path: `dmdvhc/huyenbytinh`, data: { matinh: maTinh } })
    };

    /*TODO: [Common] getXaByHuyens*/
    getXaByHuyens = async ({ token, maHuyen } = {}) => {
        return new APIService({ token: token }).getData({ path: `dmdvhc/xabyhuyen`, data: { mahuyen: maHuyen } })
    };

    //TODO:[Common] Get spatial huyen
    GetSpatialXas = async ({ token, maHuyen } = {}) => {
        return new APIService({ token: token }).postData({
            path: `TNNSpatial/getspatialhuyen?mahuyen=${maHuyen}`,
        });
    };

    //TODO: [Common] GET loai vb
    GetDMCoQuanQuanLys = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `dmcoquanquanly?page=${page}&size=${size}`,
        });
    };
    //TODO: [Common] GET loai vb by id
    GetDMCoQuanQuanLy = async ({ token, id } = {}) => {
        return new APIService({ token: token }).getData({
            path: `dmcoquanquanly/${id}`,
        });
    };
    //TODO: [Common] Post loai vb
    PostDMCoQuanQuanLy = async ({ token, dm } = {}) => {
        return new APIService({ token: token }).postData({
            path: `dmcoquanquanly`,
            data: dm
        });
    };


    //TODO: [Common] Put loai vb
    PutDMCoQuanQuanLy = async ({ token, id, dm } = {}) => {
        return new APIService({ token: token }).putData({
            path: `dmcoquanquanly/${id}`,
            data: dm
        });
    };
    //TODO: [Common] Delete loai vb
    DeleteDMCoQuanQuanLy = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `dmcoquanquanly/${id}`,
        });
    };
    //TODO: [Common] GET khu cong nghiep
    GetDMKhuCongNghieps = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMKhuCongNghiep?page=${page}&size=${size}`,

        });
    };
    //TODO: [Common] GET khu cong nghiep
    GetDMKhuCongNghiep = async ({ token, id } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMKhuCongNghiep/${id}`,
        });
    };
    //TODO: [Common] Post khu cong nghiep
    PostDMKhuCongNghiep = async ({ token, dm } = {}) => {
        return new APIService({ token: token }).postData({
            path: `DMKhuCongNghiep`,
            data: dm
        });
    };

    //TODO: [Common] Put khu cong nghiep
    PutDMKhuCongNghiep = async ({ token, id, dm } = {}) => {
        return new APIService({ token: token }).putData({
            path: `DMKhuCongNghiep/${id}`,
            data: dm
        });
    };
    //TODO: [Common] Delete khu cong nghiep
    DeleteDMKhuCongNghiep = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `DMKhuCongNghiep/${id}`,
        });
    };
    //TODO: [Common] GET don vi xin cap phep
    Get_DonViXinCapPheps_paged = async ({ token, page, size, strSearch } = {}) => {
        return new APIService({ token: token }).getData({
            path: `donvixincapphep/paged?page=${page}&size=${size}&strSearch=${strSearch}`,

        });
    };
    //TODO: [Common] GET don vi xin cap phep
    Get_DonViXinCapPhep = async ({ token, id } = {}) => {
        return new APIService({ token: token }).getData({
            path: `donvixincapphep/${id}`,
        });
    };
    //TODO: [Common] Post don vi xin cap phep
    Post_DonViXinCapPhep = async ({ token, donvi } = {}) => {
        return new APIService({ token: token }).postData({
            path: `donvixincapphep`,
            data: donvi
        });
    };

    //TODO: [Common] Put don vi xin cap phep
    Put_DonViXinCapPhep = async ({ token, id, donvi } = {}) => {
        return new APIService({ token: token }).putData({
            path: `donvixincapphep/${id}`,
            data: donvi
        });
    };
    //TODO: [Common] Delete don vi xin cap phep
    Delete_DonViXinCapPhep = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `donvixincapphep/${id}`,
        });
    };
    //TODO: [Common] GET lang nghe
    GetDMLangNghes = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMLangNghe?page=${page}&size=${size}`,

        });
    };
    //TODO: [Common] GET lang nghe
    GetDMLangNghe = async ({ token, id } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMLangNghe/${id}`,
        });
    };
    //TODO: [Common] Post lang nghe
    PostDMLangNghe = async ({ token, dm } = {}) => {
        return new APIService({ token: token }).postData({
            path: `DMLangNghe`,
            data: dm
        });
    };

    //TODO: [Common] Put lang nghe
    PutDMLangNghe = async ({ token, id, dm } = {}) => {
        return new APIService({ token: token }).putData({
            path: `DMLangNghe/${id}`,
            data: dm
        });
    };
    //TODO: [Common] Delete lang nghe
    DeleteDMLangNghep = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `DMLangNghe/${id}`,
        });
    };
    //TODO: [Common] GET nha may
    GetDMNhaMays = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMNhaMay?page=${page}&size=${size}`,

        });
    };
    //TODO: [Common] GET nha may
    GetDMNhaMay = async ({ token, id } = {}) => {
        return new APIService({ token: token }).getData({
            path: `DMNhaMay/${id}`,
        });
    };
    //TODO: [Common] Post nha may
    PostDMNhaMay = async ({ token, dm } = {}) => {
        return new APIService({ token: token }).postData({
            path: `DMNhaMay`,
            data: dm
        });
    };

    //TODO: [Common] Put nha may
    PutDMNhaMay = async ({ token, id, dm } = {}) => {
        return new APIService({ token: token }).putData({
            path: `DMNhaMay/${id}`,
            data: dm
        });
    };
    //TODO: [Common] Delete nha may
    DeleteDMNhaMay = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `DMNhaMay/${id}`,
        });
    };
    //TODO: [Common] GET kinh tuyen truc
    Getdmkinhtuyentrucs = async ({ token, cap, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `dmkinhtuyentruc`,
            data: {cap, page, size}
        });
    };
    //TODO: [Common] GET kinh tuyen truc by dvhc
    GetkinhtuyentrucByDVHC = async ({ token, matinh } = {}) => {
        return new APIService({ token: token }).getData({
            path: `dmkinhtuyentruc/getbymatinh?matinh=${matinh}`,
        });
    };
    // TODO:  GET kinh tuyen truc by id
    Getdmkinhtuyentruc = async ({ token, id } = {}) => {
        return new APIService({ token }).getData({
            path: `dmkinhtuyentruc/${id}`,
        })
    };
    //TODO: [Common] Post kinh tuyen truc
    Postdmkinhtuyentruc = async ({ token, dm } = {}) => {
        return new APIService({ token: token }).postData({
            path: `dmkinhtuyentruc`,
            data: dm
        });
    };

    //TODO: [Common] Put kinh tuyen truc
    Putdmkinhtuyentruc = async ({ token, id, dm } = {}) => {
        return new APIService({ token: token }).putData({
            path: `dmkinhtuyentruc/${id}`,
            data: dm
        });
    };
    //TODO: [Common] Delete kinh tuyen truc
    Deletedmkinhtuyentruc = async ({ token, id } = {}) => {
        return new APIService({ token: token }).deleteData({
            path: `dmkinhtuyentruc/${id}`,
        });
    };
    /*
      * Cấp phép
      * */
    // TODO: Đơn vị xin cấp phép
    getDonViXinCapPhep = async ({ token } = {}) => {
        return new APIService({ token: token }).getData({
            path: `donvixincapphep`,
        })
    };

    // TODO: Đơn vị xin cấp phép
    get_DonViXinCapPhepById = async ({ token, id } = {}) => {
        return new APIService({ token }).getData({
            path: `donvixincapphep/${id}`,
        })
    };

    /*TODO: [Common] get chuc vu*/
    GetDMChucVus = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({ path: `dmchucvu?page=${page}&size=${size}` })
    };
    //TODO: [Common] Post profile
    UpdateProfile = async ({ token, model } = {}) => {
        return new APIService({ token: token }).postData({
            path: `user/profile`,
            data: model
        });
    };
    //TODO: [Common] Post update password
    ResetPassword = async ({ token, curentPassword, model } = {}) => {
        return new APIService({ token: token }).postData({
            path: `user/resetpassword?curentPassword=${curentPassword}`,
            data: model
        });
    };
    //TODO: [Common] GET cau hinh
    GetCauHinhTheoMa = async ({ token, macauhinh } = {}) => {
        return new APIService({ token: token }).getData({
            path: `CauHinhCSCore/GetCauHinhTheoMa`,
            data: { macauhinh }
        });
    };
    //TODO: [Common] GET cau hinh
    GetCauHinhs = async ({ token, page, size } = {}) => {
        return new APIService({ token: token }).getData({
            path: `CauHinhCSCore`,
            data: { page, size }
        });
    };
}
