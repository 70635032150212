import React from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
//Model

// Components
import ViewTable from '../../../Table/ViewTable'
import Button from '../../../Buttons'
import RowTools from "../../../Table/RowTools";
import helper from "../../../../helper/helper";
import BVPCKLModal from "../Modal";
import Constant from '../../../../constants/constant'
import DateView from "../../../../components/Common/View/DateView";
import Api_tnn from "../../../../api/api.tnn";
import Api_dms from "../../../../api/api.dms";
import message from "../../../Messages";
import {connect} from 'react-redux'

// Columns
const columns_form = (showEditModal, deleteRowModal,showFile) => [
  {
    title: "#",
    dataIndex: "stt",
    width: "45px",
  },
  {
    title: "Tên quy hoạch",
    dataIndex: "tenQuyHoach",
  },
  {
    title: "Số quyết định",
    dataIndex: "quyetDinhPheDuyet",
    width: "20%",
  },
  {
    title: "Ngày quyết định",
    dataIndex: "ngayPheDuyet",
    width: "25%",
    render: (value, row) => <DateView value={value} />,
  },
  {
    title: "Thao tác",
    render: (value, row) => (
      <RowTools row={row} deleteRowModal={() => deleteRowModal(row)} showEditModal={showEditModal}  iconFile={true}
      showFile={() => showFile(row)} />
    ),
    width: "100px"
  },
];

const columns_presentation = (showFile) => [
  // {
  //   title: "#",
  //   dataIndex: "stt",
  //   width: "45px",
  // },
  {
    title: "Tên quy hoạch",
    dataIndex: "tenQuyHoach",
    render: (value, row) => <a onClick={() => showFile(row)}>{value}</a>
  },
  {
    title: "Số quyết định",
    dataIndex: "quyetDinhPheDuyet",
  },
  {
    title: "Ngày quyết định",
    dataIndex: "ngayPheDuyet",
    width: "120px",
    render: (value, row) => <DateView value={value} />,
  },
];

 class BVPCKP extends React.Component {
  constructor() {
    super();
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null,
      data_QDPD: []
    }
  }



  handleOpenAddModal = () => {
    this.setState({
      isAddModalOpen: true
    })
  };

  handleOpenEditModal = (row) => {
    this.setState({
      selectedRow: row,
      isEditModalOpen: true
    })
  };


  showFile = (data) =>{
    if (data.fileDinhKems.length > 0)
    {
    new Api_dms().DownloadFile({
      token: this.props.user.access_token,
      data: {oid: data.fileDinhKems[0].idFile, filename: data.fileDinhKems[0].tenTaiLieu}
    })
  }
  else{
    message.warning("Thiếu file đính kèm")
  }
  }

  handleCloseModal = () => {
    this.setState({
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: null
    })
  };

  handleAdd = (item) => {
    const { dataSource, handleUpdate, loaiks } = this.props;
    const newData = [{ ...item, stt: dataSource.length + 1, daXoa: 0 }, ...dataSource];
    this.handleCloseModal();
    handleUpdate(newData);
  };

  handleEdit = (item) => {
    const { dataSource, handleUpdate, field } = this.props;
    const newData = helper.replaceItem(item, dataSource, "stt");
    this.handleCloseModal();
    handleUpdate(newData, field);
  };

  handleDelete = (item) => {
    const { dataSource, handleUpdate, field, isEdit } = this.props;
    const newData = helper.updateArray(item, dataSource, isEdit)
    handleUpdate(newData, field);
  };

  addSttToItems = (items) => {
    if (!items) return [];
    let newItems = [];
    if (items) {
      newItems = items.map((item, index) => ({ ...item, stt: index + 1 }))
    }
    return newItems;
  };

  render() {
    const { isAddModalOpen, isEditModalOpen, selectedRow, data_QDPD } = this.state;
    const { dataSource, mode } = this.props;

    return (
      <Row>
        { dataSource.length < 1 &&
          <Row type="flex" justify="end" align="middle">
            {mode !== "presentation" &&
              <Button type="primary" onClick={this.handleOpenAddModal} icon="plus" />}
          </Row>
        }
        <div className="mt-10" />
        <ViewTable
          bordered pagination={false}
          columns={mode !== "presentation" ? columns_form(this.handleOpenEditModal, this.handleDelete, this.showFile) : columns_presentation(this.showFile)}
          dataSource={this.addSttToItems(dataSource)}
        />

        {isAddModalOpen &&
          <BVPCKLModal
            title="Thêm mới quyết định"
            mode="add"
            isOpen={isAddModalOpen}
            handleCancel={this.handleCloseModal}
            handleOk={this.handleAdd}
          />}

        {isEditModalOpen &&
          <BVPCKLModal
            title="Sửa quyết định"
            mode="edit"
            initialValues={selectedRow}
            isOpen={isEditModalOpen}
            handleCancel={this.handleCloseModal}
            handleOk={this.handleEdit}
          />

        }
      </Row>
    )
  }
}

BVPCKP.propTypes = {
  // MODE: string "form" and "presentation"
  mode: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func,
};
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(BVPCKP);
