import React, { Component } from 'react'; import { Row, Col } from 'antd'
import Constant, { functionsCode, groupFunctionsCode } from '../../constants/constant';
import { isEmpty } from 'lodash'
import Helper from '../../helper/helper';
import Message from "../../components/Messages";
import { MESSAGE } from "../../constants/dictionaries";
export default class ContainerTotalStatistic extends Component {

    render() {
        const { functions } = this.props
        return (
            <Row type="flex" justify="start" className='box-items-statistic' gutter={16}>
                {this.props.data.map(items => (items.map(item => <BoxTotalStatistic data={item} history={this.props.history} functions={functions} />)))}
            </Row>
        )
    }
}

class BoxTotalStatistic extends Component {
    ChuyenTrang = (value) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
        if (value === "Giấy phép") {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push('/cap-phep/khai-thac-nuoc-duoi-dat')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
        else if (value === "Giấy phép ") {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push('/cap-phep/khai-thac-nuoc-mat')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
        else if (value === " Giấy phép") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push('/cap-phep/xa-thai')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push('/cap-phep/khai-thac-nuoc-bien')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)

            }
        }
    }

    ChuyenTrangshhl = (value_shhltb) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_shhltb === "Sắp hết hiệu lực") {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_shhltb === " Sắp hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&Giayphep_Sort=${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_shhltb === "Sắp hết hiệu lực ") {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

    }
    ChuyenTrangHP = (value) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
        if (value === "Giấy phép") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push('/cap-phep/khai-thac-nuoc-mat')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value === "Giấy phép ") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push('/cap-phep/xa-thai')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value === "Quyết định phê duyệt") {

            {
                groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_TCQ) ?
                    this.props.history.push('/Thong-tin-tai-nguyen-nuoc/Tien-cap-quyen')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value === "Văn bản quy phạm pháp luật") {

            {
                groupFunctions[groupFunctionsCode.TNN_INFO].includes(functionsCode.TNN_INFO_VBQPPL) ?
                    this.props.history.push('/Thong-tin-tai-nguyen-nuoc/Van-ban-quy-pham-phap-luat')
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
    }

    ChuyenTranghhl = (value_hhl) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);


        if (value_hhl === " Hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

        else if (value_hhl === "Hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }

    }
    ChuyenTranghhltb = (value_hhltb) => {

        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);
        if (value_hhltb === "Hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

        else if (value_hhltb === " Hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_hhltb === "Hết hiệu lực ") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_hhltb === "  Hết hiệu lực") {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[1].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

    }
    ChuyenTrangshhlhp = (value_shhl) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);


        if (value_shhl === " Sắp hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }

        else if (value_shhl === "Sắp hết hiệu lực") {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[2].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
    }

    ChuyenTrangctt_tb = (value_ctt) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_ctt === 'Giấy phép') {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NDD_KT) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_ctt === 'Giấy phép ') {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_ctt === ' Giấy phép') {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
        else if (value_ctt === 'Giấy phép  ') {
            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NB) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-bien?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }

    }
    ChuyenTrangctt_hp = (value_ctt) => {
        const { functions } = this.props
        const groupFunctions = Helper.handleGroupFunctions(functions, groupFunctionsCode);

        if (value_ctt === 'Giấy phép') {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_NM) ?
                    this.props.history.push(`/cap-phep/khai-thac-nuoc-mat?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)
     
                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }

        }
        else if (value_ctt === 'Giấy phép ') {

            {
                groupFunctions[groupFunctionsCode.LICENSE].includes(functionsCode.LICENSE_XT) ?
                    this.props.history.push(`/cap-phep/xa-thai?HieuLuc=${Constant.HIEU_LUC_GIAY_PHEP_TINH[3].id}&PageNumber=1&PageSize=10&${Constant.GiayPhep_Sort[0].id}&Sort=${Constant.Sort[1].id}`)

                    :
                    Message.warning(MESSAGE.cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP)
            }
        }
    }
    render() {
        const { data } = this.props
        if (Number(Constant.MA_TINH) === Constant.MaTinh.ThaiBinh) {
            return (
                <Col xs={24} sm={12} md={6} className='col-box-item-statistic'>
                    <div className='box-item-statistic' style={{ background: data.backGroundColor }}>
                        <div onClick={() => { this.ChuyenTrang(data.indicator) }} >
                            <div className='item-value' style={{ fontSize: '20px', textAlign: 'center' }}>{data.tittle} </div>
                            <Row type='flex'>
                                <Col span={8} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp'>{data.value}</div>
                                </Col>
                                <Col span={16} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTrangshhl(data.indicator_shhltb)} >
                            <Row type='flex'>
                                <Col span={8} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#ecf415" }}>{data.value_shhltb}</div>
                                </Col>
                                <Col span={16} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator_shhltb}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTranghhltb(data.indicator_hhltb)} >
                            <Row type='flex'>
                                <Col span={8} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#f5222d" }}>{data.value_hhltb}</div>
                                </Col>
                                <Col span={16} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator_hhltb}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTrangctt_tb(data.indicator)}>
                            <Row type='flex'>
                                <Col span={8} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#321fdb" }}>{data.value_ctt}</div>
                                </Col>
                                <Col span={16} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator_chuathaythe}</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            )
        }
        else if (Number(Constant.MA_TINH) === Constant.MaTinh.HaiPhong) {
            return (
                <Col xs={24} sm={12} md={6} className='col-box-item-statistic'>
                    <div className='box-item-statistic' style={{ background: data.backGroundColor }}>
                        <div onClick={() => { this.ChuyenTrangHP(data.indicator) }} >
                            <div className='item-value' style={{ fontSize: '20px', textAlign: 'center' }}>{data.tittle} </div>
                            <Row type='flex'>
                                <Col span={6} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp'>{data.value}</div>
                                </Col>
                                <Col span={18} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTrangshhlhp(data.indicator_shhl)} >
                            <Row type='flex'>
                                <Col span={6} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#ecf415" }}>{data.value_shhl}</div>
                                </Col>
                                <Col span={18} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator_shhl}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTranghhl(data.indicator_hhl)} >
                            <Row type='flex'>
                                <Col span={6} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#f5222d" }}>{data.value_hhl}</div>
                                </Col>
                                <Col span={18} style={{ paddingTop: "10px" }}>
                                    <div className='item-indicator'>{data.indicator_hhl}</div>
                                </Col>
                            </Row>
                        </div>
                        <div onClick={() => this.ChuyenTrangctt_hp(data.indicator)}>
                            <Row type='flex'>
                                <Col span={6} style={{ paddingRight: '15px', textAlign: 'right' }}>
                                    <div className='item-value-hp' style={{ color: "#321fdb" }}>{data.value_ctt}</div>
                                </Col>
                                <Col span={18}>
                                    <div className='item-indicator'>{data.indicator_chuathaythe}</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            )
        }
    }

}