

import L from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet'
import Api_tnn from '../../../../api/api.tnn';
import Constant from '../../../../constants/constant';
import icon from '../../../../assets/images/kk_1.png';
let DefaultIcon = L.icon({
    iconUrl: icon,
    className: 'custom-icon',
    popupAnchor: [1, -34]
});

L.Marker.prototype.options.icon = DefaultIcon;
const CustomWMSLayer = ({ url, showData, layer, user }) => {
    const mapClear = useMap()
    var layerGroup = L.layerGroup().addTo(mapClear);
    const map = useMapEvents({
        click: (evt) => {
            layerGroup.clearLayers();
            var point = map.latLngToContainerPoint(evt.latlng, map.getZoom()),
                size = map._size,
                params = {
                    request: 'GetFeatureInfo',
                    service: 'WMS',
                    srs: 'EPSG:4326',
                    styles: "",
                    transparent: true,
                    version: "1.1.1",
                    format: "image/png",
                    bbox: map.getBounds().toBBoxString(),
                    height: size.y,
                    width: size.x,
                    layers: layer,
                    query_layers: layer,
                    info_format: 'application/json'
                };

            params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
            params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;

            var urlpath = url + L.Util.getParamString(params, url, true);

            if (Number(Constant.CAP_TRIEN_KHAI) === 2) {
                new Api_tnn().GetDuLieuBySong({ token: user.access_token, url: urlpath })
                    .then((res) => {
                        if (res !== null && res.length > 0) {
                            showData(res)
                        } else {
                            fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Constant.KEY_GG_IF}&address=${evt.latlng.lat},${evt.latlng.lng}`)
                                .then(response => response.json())
                                .then(data => {
                                    if (data && data.results && data.results.length > 0) {
                                        L.marker(evt.latlng).addTo(layerGroup).bindPopup(data.results[0].formatted_address).openPopup()
                                    }
                                }).catch(error => {

                                });
                        }
                    })
                    .catch((err) => {
                        fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Constant.KEY_GG_IF}&address=${evt.latlng.lat},${evt.latlng.lng}`)
                            .then(response => response.json())
                            .then(data => {
                                if (data && data.results && data.results.length > 0) {
                                    L.marker(evt.latlng).addTo(layerGroup).bindPopup(data.results[0].formatted_address).openPopup()
                                }
                            }).catch(error => {

                            });
                    });
            } else if (Number(Constant.CAP_TRIEN_KHAI) === 1) {
                new Api_tnn().GetDuLieuBySong_CapBo({ token: user.access_token, url: urlpath })
                    .then((res) => {
                        if (res !== null && res.length > 0) {
                            showData(res)
                        } else {
                            fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Constant.KEY_GG_IF}&address=${evt.latlng.lat},${evt.latlng.lng}`)
                                .then(response => response.json())
                                .then(data => {
                                    if (data && data.results && data.results.length > 0) {
                                        L.marker(evt.latlng).addTo(layerGroup).bindPopup(data.results[0].formatted_address).openPopup()
                                    }
                                }).catch(error => {

                                });
                        }
                    })
                    .catch((err) => {
                        fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Constant.KEY_GG_IF}&address=${evt.latlng.lat},${evt.latlng.lng}`)
                            .then(response => response.json())
                            .then(data => {
                                if (data && data.results && data.results.length > 0) {
                                    L.marker(evt.latlng).addTo(layerGroup).bindPopup(data.results[0].formatted_address).openPopup()
                                }
                            }).catch(error => {

                            });
                    });
            }
        }
    })



    return null
}

export default CustomWMSLayer