export default {
  //  Dashboard
  cscore_tnn_dashboard_title: "Quản trị hệ thông thông tin",
  cscore_tnn_object: 'Đối tượng',
  cscore_tnn_group: 'Nhóm',
  cscore_tnn_HLGP: "Hiệu lực giấy phép",
  cscore_tnn_groupUnit: 'Phân loại công trình',
  cscore_tnn_chart: 'Biểu đồ',
  cscore_tnn_phase: 'Giai đoạn',
  cscore_tnn_period: 'Chu kỳ',
  cscore_tnn_from: 'Từ',
  cscore_tnn_to: 'Đến',
  cscore_tnn_cap_bo: 'Cấp bộ',
  cscore_tnn_cap_dp: 'Cấp sở',

  cscore_tnn_statistic: 'Thống kê',
  cscore_tnn_exportPdf: 'Xuất pdf',
  cscore_tnn_testing: "Trường thử nghiệm",
  cscore_tnn_expired: "Hết hiệu lực",
  cscore_tnn_wait: "Chưa phê duyệt",
  cscore_tnn_validated: "Còn hiệu lực",
  cscore_tnn_chuabatdau: "Chưa có hiệu lực",
  cscore_tnn_chuaxuly: "Chưa xử lý",
  cscore_tnn_daxuly: "Đã Xử lý",
  cscore_tnn_expiringSoon: "Sắp hết hiệu lực",
  cscore_tnn_info_mau_QT: "Số liệu",
  //  Drawer
  cscore_tnn_drawer_ct: "Công trình",
  cscore_tnn_drawer_ctnm: "Công trình nước mặt",
  cscore_tnn_drawer_ctnm_qltd: "Thủy điện",
  cscore_tnn_drawer_ctnm_qlhc: "Hồ chứa",
  cscore_tnn_drawer_ctnm_qltb: "Trạm bơm",
  cscore_tnn_drawer_ctnm_qlbd: "Đập",
  cscore_tnn_drawer_ctnm_qlc: "Cống",
  cscore_tnn_drawer_ctnm_qltcn: "Trạm cấp nước",
  cscore_tnn_drawer_ctnm_qlnmn: "Nhà máy nước",

  cscore_tnn_drawer_ctndd: "Công trình nước dưới đất",
  cscore_tnn_drawer_ctndd_tdndd: "Thăm dò",
  cscore_tnn_drawer_ctndd_ktndd: "Khai thác",
  cscore_tnn_drawer_ctxt: "Công trình xả thải",
  cscore_tnn_drawer_ctnb: "Công trình nước biển",

  cscore_tnn_drawer_cp_tnn: "Giấy phép",
  cscore_tnn_drawer_cp_xt: "Xả nước thải vào nguồn nước",
  cscore_tnn_drawer_cp_ktnm: "Khai thác, sử dụng nước mặt",
  cscore_tnn_drawer_cp_ktnb: "Khai thác, sử dụng nước biển",
  cscore_tnn_drawer_cp_tdndd: "Thăm dò nước dưới đất",
  cscore_tnn_drawer_cp_ktndd: "Khai thác, sử dụng NDD",
  cscore_tnn_drawer_cp_hnkndd: "Hành nghề khoan nước dưới đất",
  cscore_tnn_drawer_import_dulieu: "Nhập dữ liệu giấy phép vào hệ thống",
  cscore_tnn_drawer_quanlyketnoi: "Quản lý kết nối",
  

  cscore_tnn_drawer_management_tnn: "Thông tin Tài nguyên nước",
  cscore_tnn_drawer_management_svlvs: "Sông và lưu vực sông",
  cscore_tnn_drawer_management_tcbds: "Tra cứu bản đồ sông",
  cscore_tnn_drawer_management_vbqppl: "Văn bản quy phạm pháp luật",
  cscore_tnn_drawer_management_csdlqh: "Quy hoạch tài nguyên nước",
  cscore_tnn_drawer_management_ttdt: "Điều tra đánh giá",
  cscore_tnn_drawer_management_bcpckp: "Bảo vệ phòng chống khắc phục",
  cscore_tnn_drawer_management_other: "Thông tin tài nguyên nước khác",
  cscore_tnn_drawer_management_map: "Dữ liệu bản đồ",
  cscore_tnn_drawer_management_kqgqkn: "Kết quả giải quyết kiến nghị cử tri",
  cscore_tnn_drawer_management_nccbv: "Nguồn nước cần bảo vệ",
  cscore_tnn_drawer_management_kqtlpabc: "Kết quả trả lời phản ánh báo chí",


  cscore_tnn_drawer_dm_qt: "Danh mục quan trắc",
  cscore_tnn_drawer_qt_tnn: "Quan trắc tài nguyên nước",
  cscore_tnn_drawer_qt_tt: "Thông số quan trắc",
  cscore_tnn_drawer_qt_tcad: "Tiêu chuẩn áp dụng",
  cscore_tnn_drawer_qt_tcdg: "Tiêu chuẩn đánh giá",
  cscore_tnn_drawer_qt_dqt: "Điểm quan trắc định kỳ",
  cscore_tnn_drawer_qt_dqtct: "Điểm quan trắc công trình",
  cscore_tnn_drawer_qt_tqtn: "Trạm quan trắc",
  cscore_tnn_drawer_qt_batch: "Đợt quan trắc",
  cscore_tnn_drawer_qt_mqt: "Mẫu quan trắc định kỳ",
  cscore_tnn_drawer_qt_mqtct: "Mẫu quan trắc công trình",
  cscore_tnn_drawer_qt_chtdt: " Cấu hình thông số quan trắc",
  cscore_tnn_drawer_qt_chtstc: " Cấu hình thông số quy chuẩn",
  cscore_tnn_drawer_qt_ktqt: "Đánh giá kết quả quan trắc nước",
  cscore_tnn_drawer_qt_dlqtdk: "Dữ liệu quan trắc định kỳ",
  cscore_tnn_drawer_qt_chts: "Cấu hình thông số",
  cscore_tnn_drawer_dlqt_nm: "Nước mặt",
  cscore_tnn_drawer_dlqt_ndd: "Nước dưới đất",
  cscore_tnn_drawer_dlqt_nxt: "Nước xả thải",
  cscore_tnn_drawer_dlqt_kt: "Khí tượng",
  cscore_tnn_drawer_dlqt_tv: "Thủy văn",
  cscore_tnn_drawer_dlqt_dk: "Dữ liệu quan trắc định kỳ",
  cscore_tnn_drawer_lvb_dp: "Loại văn bản địa phương",
  cscore_tnn_drawer_ql_dp: "Quản lý văn bản địa phương",

  cscore_tnn_drawer_qtkttv_tnn: "Quan trắc khí tượng thủy văn",
  cscore_tnn_drawer_qtkttv_qtkt: "Thông số quan trắc khí tượng",
  cscore_tnn_drawer_qtkttv_qttv: "Thông số quan trắc thủy văn",
  cscore_tnn_drawer_qtkttv_tqt: "Trạm quan trắc",
  cscore_tnn_drawer_qtkttv_kqt: "Kỳ quan trắc",
  cscore_tnn_drawer_qtkttv_ktqt: "Kết quả quan trắc KTTV",
  cscore_tnn_drawer_qtkttv_tsqt: "Cấu hình thông số quan trắc KTTV",


  cscore_tnn_drawer_categories: "Danh mục",
  cscore_tnn_drawer_categories_qlvb: "Loại văn bản",
  cscore_tnn_drawer_categories_qlkcn: "Khu công nghiệp",
  cscore_tnn_drawer_categories_qlct: "Đơn vị quản lý công trình",
  cscore_tnn_drawer_categories_qldv: "Tổ chức/cá nhân được cấp phép",
  cscore_tnn_drawer_categories_qlln: "Làng nghề",
  cscore_tnn_drawer_categories_qlnm: "Nhà máy",

  cscore_tnn_drawer_map: "Bản đồ",
  cscore_tnn_drawer_map_tnn: "Tra cứu tài nguyên nước",

  cscore_tnn_drawer_bctk: "Báo cáo thống kê",
  cscore_tnn_drawer_log: "Nhật ký hệ thống",
  cscore_tnn_drawer_log_db: "Nhật ký đồng bộ",
  cscore_tnn_drawer_water_construction: "Công trình nước mặt",
  cscore_tnn_drawer_statistic: "Báo cáo thống kê",



  // Login
  cscore_tnn_login_user_name: "Tài khoản",
  cscore_tnn_login_password: "Mật Khẩu",
  cscore_tnn_login_repassword: "Nhập lại mật khẩu",
  cscore_tnn_login_sign_in: "Đăng Nhập",
  cscore_tnn_forgot_password: "Quên Mật Khẩu",
  cscore_tnn_forgot_password_reset: "Mật khẩu mới",
  cscore_tnn_login_required_user_name: "Tài khoản là bắt buộc",
  cscore_tnn_login_required_func_name: "Tên là bắt buộc",
  cscore_tnn_login_required_password: "Mật khẩu là bắt buộc",
  cscore_tnn_login_min: "Tên đăng nhập ít nhất 6 ký tự",
  cscore_tnn_login_max: "Mật khẩu nhiều nhất là 30 ký tự",
  cscore_tnn_login_create_account: "Thêm mới người dùng",
  cscore_tnn_login_create_function: "Thêm mới chức năng",
  cscore_tnn_re_password: "Nhập lại mật khẩu",
  cscore_tnn_old_password: "Mật khẩu hiện tại",

  //Input
  cscore_tnn_input_pladceholder: "Nhập",
  cscore_tnn_input_pladceholder_account: "Nhập tài khoản",
  cscore_tnn_input_pladceholder_password: "Nhập mật khẩu",
  cscore_tnn_input_pladceholder_name: "Nhập họ và tên",
  cscore_tnn_input_pladceholder_phone: "Nhập số điện thoại",
  cscore_tnn_input_pladceholder_email: "Nhập email",
  cscore_tnn_input_pladceholder_andress: "Nhập địa chỉ",
  cscore_tnn_input_pladceholder_object: "Chọn đối tượng quan trắc",
  cscore_tnn_create_modal: "Thêm mới",
  cscore_tnn_ThemMoiLoi: "Báo cáo sự cố",
  cscore_tnn_input_pladceholder_cascader: "Tình trạng cấp phép",
  cscore_tnn_input_name: "Họ và tên",
  cscore_tnn_input_CV: "Chức vụ",
  cscore_tnn_input_functionName: "Tên chức năng",
  cscore_tnn_input_description: "Mô tả",
  cscore_tnn_input_App: "Ứng dụng",
  cscore_tnn_input_LinhVuc: "Lĩnh vực",
  cscore_tnn_input_PB: "Phòng ban",
  cscore_tnn_input_Module: "Module",
  cscore_tnn_input_company: "Cơ quan quản lý",
  cscore_tnn_input_phone: "Điện thoại",
  cscore_tnn_input_email: "Email",
  cscore_tnn_input_address: "Địa chỉ",
  cscore_tnn_input_Required: "Không được để trống",
  cscore_tnn_input_min: "Nhập ít nhất 5 ký tự",
  cscore_tnn_input_max: "Nhập nhiều nhất là 30 ký tự",

  cscore_tnn_agency: "Tên cơ quan",
  cscore_tnn_describe: "Mô tả",
  cscore_tnn_fax: "Fax",
  cscore_tnn_website: "Website",
  cscore_tnn_agency_code: "Mã cơ quan",
  cscore_tnn_unit_code: "Mã đơn vị",
  cscore_tnn_create_agency: "Thêm mới cơ quan",
  cscore_tnn_info_agency: "Thông tin cơ quan",
  cscore_tnn_linh_vuc: "Tên lĩnh vực",
  cscore_tnn_linh_vuc_code: "Mã lĩnh vực",
  cscore_tnn_create_linh_vuc: "Thêm mới lĩnh vực",
  cscore_tnn_info_linh_vuc: "Thông tin lĩnh vực",
  cscore_tnn_create_lv: "Thêm mới lĩnh vực",
  cscore_tnn_create_pb: "Thêm mới phòng ban",
  cscore_tnn_phong_ban: "Tên phòng ban",
  cscore_tnn_phong_ban_code: "Mã phòng ban",
  cscore_tnn_info_phong_ban: "Thông tin phòng ban",
  cscore_tnn_create_cv: "Thêm mới chức vụ",
  cscore_tnn_chuc_vu: "Tên chức vụ",
  cscore_tnn_chuc_vu_code: "Mã chức vụ",
  cscore_tnn_info_chuc_vu: "Thông tin chức vụ",
  cscore_tnn_create_Api: "Thêm mới Api",
  cscore_tnn_Api_name: "Tên Web Api",
  cscore_tnn_info_Api: "Thông tin Api",
  cscore_tnn_api_name_in: "Tên hệ thống",
  cscore_tnn_group_name: "Nhóm người dùng",
  cscore_tnn_add_group: "Thêm mới nhóm người dùng",
  cscore_tnn_info_app: "Thông tin ứng dụng hệ thống",
  cscore_tnn_dich_vu: "Tên dịch vụ",
  cscore_tnn_status: "Trạng thái",
  cscore_tnn_info_khonggian: "Thông tin CSDL không gian",
  cscore_tnn_search_place_holder: "Nhập từ khóa ...",
  cscore_tnn_ten_doanh_nghiep: "Tên doanh nghiệp",
  cscore_tnn_ftp: "Danh mục FTP",
  cscore_tnn_info_ftp: "Thông tin ftp",
  cscore_tnn_tencutri: "Tên người phản ánh",
  cscore_tnn_doanh_nghiep: "Doanh nghiệp",

  cscore_tnn_BDnen: "Danh mục bản đồ nền",
  cscore_tnn_info_BDnen: "Thông tin bản đồ nền",
  cscore_tnn_stt: "STT",
  cscore_tnn_number_input: "Chỉ được nhập kí tự số",

  //Buttons
  cscore_tnn_back: "Quay lại",
  cscore_tnn_save: "Lưu",
  cscore_tnn_update: "Cập nhật",
  cscore_tnn_btn_add: "Thêm mới",
  cscore_tnn_exit: "Thoát",
  cscore_tnn_next: "Tiếp tục",
  cscore_tnn_luutam: "Lưu nháp",
  cscore_tnn_xemlichsuchinhsua:"Xem lịch sử chỉnh sửa",
  cscore_tnn_lichsuchinhsua:"Lịch sử chỉnh sửa",

  // Common
  cscore_tnn_cancel: "Hủy",
  cscore_tnn_collections: "Bộ sưu tập",
  cscore_tnn_components: "Components",
  cscore_tnn_submit: "Lưu",
  cscore_tnn_required: "Thông tin bắt buộc",
  cscore_tnn_validate_number: "Trường thông tin số",
  cscore_tnn_name: "Tên",
  cscore_tnn_note: "Ghi chú",
  cscore_tnn_cors: "Cors",
  cscore_tnn_api: "API",
  cscore_tnn_code: "Nhập code",
  cscore_tnn_code_name: "Code",
  cscore_tnn_login: "Đăng nhập",
  cscore_tnn_logout: "Đăng xuất",
  cscore_tnn_field: "Lĩnh vực",
  cscore_tnn_search: "Tìm kiếm",
  cscore_tnn_boloc: "Bộ lọc",
  cscore_tnn_application: "Ứng dụng",
  cscore_tnn_function: "Chức năng",
  cscore_tnn_pladceholder_group: "Chọn nhóm",
  cscore_tnn_unauthorized: "Người dùng chưa xác thực",
  cscore_tnn_cscore_tnn: "Hệ thống quản lý tài nguyên nước",
  cscore_tnn_common_info: "Thông tin chung",
  cscore_tnn_ct_info: "Thông tin công trình",
  cscore_tnn_ctndd_info: "Thông tin về công trình khai thác được cấp phép",
  cscore_tnn_ketQuaGP: "Kết quả giấy phép",
  cscore_tnn_TTCGPNDD: "Thông tin chung về giấy phép",
  cscore_tnn_organization: "Tên tổ chức",
  cscore_tnn_dkkd_qdtl: "Số ĐKKD/QĐTL",
  cscore_tnn_ngay_cap: "Ngày cấp",
  cscore_tnn_input_ngayhethan: "Ngày kết thúc hiệu lực",
  cscore_tnn_noi_cap: "Nơi cấp",
  cscore_tnn_phone: "Số điện thoại",
  cscore_tnn_email: "Địa chỉ email",
  cscore_tnn_representative: "Người đại diện",
  cscore_tnn_owner_representative: "Đại diện chủ sở hữu",
  cscore_tnn_profile_user: "Thông tin người dùng",
  cscore_tnn_profile_change_password: "Đổi mật khẩu",
  cscore_tnn_hdsd: "Hướng dẫn sử dụng",
  cscore_tnn_ngaythang: "Ngày tháng",
  cscore_tnn_theodoi_sauGP: "Theo dõi quá trình sau giấy phép",
  cscore_tnn_theodoi_ketnoi: "Theo dõi quá trình thực hiện kết nối theo TT47",
  cscore_tnn_luuLuong_gieng: "Lưu lượng theo giếng",
  //home
  cscore_tnn_app: 'Ứng dụng',
  cscore_tnn_admin: 'QTHT',
  cscore_tnn_tnn: 'TNN',
  cscore_tnn_nameApp: 'Hệ thống quản lý cơ sở dữ liệu tài nguyên nước',
  cscore_tnn_dashboard: 'MAIN',


  // Status
  cscore_tnn_Activated: "Kích hoạt",
  cscore_tnn_Deactivated: "Không kích hoạt",
  cscore_tnn_Banned: "Bị khoá",

  // water_construction
  cscore_tnn_hydropower: "Thủy điện",
  cscore_tnn_reservoir: "Quản lý hồ chứa",
  cscore_tnn_pump_station: "Quản lý trạm bơm",
  cscore_tnn_bai_dap: "Quản lý đập",
  //Kqgqkn
  cscore_tnn_nguoi_Tra_Loi: "Người trả lời",
  cscore_tnn_thang: "Tháng",
  cscore_tnn_chonthang: "Chọn tháng",
  cscore_tnn_nam: "Năm",
  cscore_tnn_noidungkiennghi: "Nội dung phản ánh",
  cscore_tnn_noidungtraloi: "Nội dung trả lời",

  //HoChua
  cscore_tnn_maCongTrinh: 'Ký hiệu công trình',
  cscore_tnn_ho_chua: 'Hồ chứa',
  cscore_tnn_hoChua: 'Hồ chứa',
  cscore_tnn_caoTrinhDinhDap: 'Cao trình đỉnh đập (m)',
  cscore_tnn_chieuCaoDap: 'Chiều cao đập (m)',
  cscore_tnn_chieuDaiDap: 'Chiều dài đập (m)',
  cscore_tnn_chieuRongDap: 'Chiều rộng đập (m)',
  cscore_tnn_congSuatDamBaoPhat: 'Công suất đảm bảo phát (MW)',
  cscore_tnn_congSuatLapMayPhatDien: 'Công suất máy phát điện (MW)',
  cscore_tnn_dienLuongBinhQuan: 'Điện lượng bình quân (MW)',
  cscore_tnn_dienTichLuuVuc: 'Diện tích lưu vực (ha)',
  cscore_tnn_dienTichNuoiTrong: 'Diện tích nuôi trồng  (ha)',
  cscore_tnn_dienTichTuoiThietKe: 'Diện tích tưới thiết kế  (ha)',
  cscore_tnn_dungTich: 'Dung tích (Triệu m3)',
  cscore_tnn_dungTichChet: 'Dung tích chết (Triệu m3)',
  cscore_tnn_dungTichCuoiMuaLu_DauMuaCan: 'Dung tích cuối mùa lũ - đầu mùa cạn (triệu m3)',
  cscore_tnn_dungTichHuuIch: 'Dung tích hữu ích (triệu m3)',
  cscore_tnn_dungTichPhongLu: 'Dung tích phòng lũ (triệu m3)',
  cscore_tnn_dungTichToanBo: 'Dung tích toàn bộ (triệu m3)',
  cscore_tnn_hienTrangCongTrinh: 'Hiện trạng công trình',
  cscore_tnn_hinhThucTranXaLu: 'Hình thức tràn xả lũ',
  cscore_tnn_luuLuongPhatDienTK: 'Lưu lượng phát điện thiết kế (m3/s)',
  cscore_tnn_mucNuocDangGiaCuong: 'Mực nước dâng gia cường (m)',
  cscore_tnn_quyTrinhVanHanh: 'Quy trình vận hành',
  cscore_tnn_quyTrinhVanHanhDap: 'Quy trình vận hành đập',
  cscore_tnn_soCuaLayNuoc: 'Số cửa lấy nước',
  cscore_tnn_soHoCapNuoc: 'Số hồ cấp nước',
  cscore_tnn_mdsdluuluong: "Lưu lượng",
  cscore_tnn_select_lhnt: "Chọn loại hình nước thải",
  // song: 'Sông',
  cscore_tnn_soToMay: 'Số tổ máy (tổ)',
  cscore_tnn_tenLuuVucSong: 'Tên lưu vực sông',
  cscore_tnn_thoiGianKT: 'Thời gian khai thác',
  cscore_tnn_lichsu: "Lịch sử cấp phép",
  cscore_tnn_gplienquan: "Giấy phép liên quan của đơn vị được cấp phép",
  cscore_tnn_lich_su_trao_doi: "Lịch sử trao đổi",
  cscore_tnn_binh_luan: "Nội dung trao đổi",
  cscore_tnn_tuchoipeduyet: "Từ chối phê duyệt",
  //TramBom
  cscore_tnn_tramBom: 'Trạm bơm',
  cscore_tnn_congSuatMayBom: 'Công suất máy bơm (MW)',
  cscore_tnn_dienTichTuoiThucTe: 'Diện tích tưới thực tế (ha)',
  cscore_tnn_luuLuongKhaiThac: 'Lưu lượng khai thác (m3/ngày.đêm)',
  cscore_tnn_luuLuongKT: 'Lưu lượng khai thác (m3/h)',
  cscore_tnn_luuLuongThietKe: 'Lưu lượng thiết kế (m3/s)',
  cscore_tnn_luuLuongThucTe: 'Lưu lượng thực tế (m3/s)',
  cscore_tnn_soMayBom: 'Số máy bơm (máy)',
  cscore_tnn_thoiGianKhaiThac: 'Thời gian khai thác',
  cscore_tnn_mucDichSuDung: 'Mục đích sử dụng',
  cscore_tnn_mucDichKTSD: 'Mục đích khai thác sử dụng',

  //bai dap
  cscore_tnn_mucDichSD: 'Mục đích sử dụng',
  cscore_tnn_nguonNuocKTSD: 'Nguồn nước khai thác sử dụng',
  cscore_tnn_luongNuocKhaiThac: 'Lượng nước khai thác, sử dụng',
  cscore_tnn_phuongThucKhaiThac: 'Phương thức khai thác',
  cscore_tnn_chieuCao: 'Chiều cao (m)',
  cscore_tnn_chieuDai: 'Chiều dài (m)',
  cscore_tnn_chieudaikm: 'Chiều dài(Km)',
  cscore_tnn_chieuRong: 'Chiều rộng (m)',
  cscore_tnn_dienTichTuoiTK: 'Diện tích nuôi thiết kế (ha)',
  cscore_tnn_baiDap: 'Đập',

  //cong
  cscore_tnn_cong: 'Cống',
  cscore_tnn_caoTrinhCotNuocTK: 'Cao trình cột nước thiết kế',
  cscore_tnn_dienTichThietKe: 'Diện tích thiết kế (ha)',
  cscore_tnn_dienTichThucTe: 'Diện tích thực tế (ha)',
  cscore_tnn_dienTichTieuThietKe: 'Diện tích tiêu thiết kế (ha)',
  cscore_tnn_dienTichTieuThucTe: 'Diện tích tiêu thực tế (ha)',
  cscore_tnn_duongKinh: 'Đường kính (m)',
  cscore_tnn_soCua: 'Số cửa',
  cscore_tnn_trenBo: 'Trên bờ',
  cscore_tnn_caoTrinhDayCong: 'Cao trình đầy cống',
  cscore_tnn_tenKenh: 'Tên kênh',
  cscore_tnn_theo_km_de: 'Theo km đê',
  cscore_tnn_tuyen_de: 'Tuyến đê',
  //TramCapNuoc
  cscore_tnn_TramCapNuoc: 'Trạm cấp nước',
  cscore_tnn_congSuatMayBomThietKe: 'Công suất máy bơm thiết kế (MW)',
  cscore_tnn_congSuatMayBomThucTe: 'Công suất máy bơm thực tế (MW)',
  cscore_tnn_dienTichTK: 'Diện tích thiết kế (ha)',
  cscore_tnn_luuLuongTK: 'Lưu lượng thiết kế (m3/s)',
  cscore_tnn_capNuoc: 'Cấp nước',
  cscore_tnn_soHo: 'Số hồ',
  cscore_tnn_soHoThucCap: 'Số hồ thực cấp',
  cscore_tnn_thongSoCongTrinh: 'Thông số công trình',

  //NhaMayNuoc
  cscore_tnn_NhaMayNuoc: 'Nhà máy nước',

  //CongTrinhXaThai
  cscore_tnn_CongTrinhXaThai: 'Công trình xả thải',
  cscore_tnn_luuLuongNuocThai: 'Lưu lượng nước thải (m3/ngày.đêm)',
  cscore_tnn_luuLuongNuocThaiLonNhat: 'Lưu lượng nước thải lớn nhất (m3/ngày.đêm)',
  cscore_tnn_luuLuongXaTrungBinh: "Lưu lượng xả trung bình (m3/s)",
  cscore_tnn_luuLuongXaLonNhat: "Lưu lượng xả lớn nhất (m3/ngày.đêm)",
  cscore_tnn_soDiemXa: 'Số điểm xả',
  cscore_tnn_cheDoXaThai: 'Chế độ xả',
  cscore_tnn_loaiHinhNuocThai: 'Loại hình nước thải',
  cscore_tnn_donViThamDinh: 'Đơn vị thẩm định',
  cscore_tnn_nguonTiepNhan: 'Nguồn tiếp nhận',
  cscore_tnn_chonnguontiepnhan: 'Chọn nguồn tiếp nhận',
  cscore_tnn_phuongThucXaThai: 'Phương thức xả thải',
  cscore_tnn_quyChuanApDung: 'Quy chuẩn áp dụng',
  cscore_tnn_thongtu: "Thông tư",
  cscore_tnn_gstheothongso: "Giám sát theo thông tư",
  cscore_tnn_quyChuanVN: 'Quy chuẩn Việt Nam',
  cscore_tnn_cotAB: 'Cột A/B',
  cscore_tnn_heSoK: 'Hệ số K',
  cscore_tnn_thuocLuuVucSong: 'Thuộc lưu vực sông',
  cscore_tnn_diemXaThai: 'Điểm xả thải',
  cscore_tnn_diem_xa_nươc_thai: "Điểm xả thải vào nguồn nước",
  cscore_tnn_soHieu: 'Số hiệu',
  cscore_tnn_caoDo: 'Cao độ',
  cscore_tnn_themDiemXaThai: 'Thêm điểm xả thải',
  cscore_tnn_capNhatDiemXaThai: 'Cập nhật điểm xả thải',
  cscore_tnn_thongtindiemxathai: "Thông tin điểm xả thải",
  cscore_tnn_laKieuSo: ' là kiểu sô',
  cscore_tnn_laKieuSoThapPhan: " là kiểu số thập phân",

  //CongTrinhNuocDuoiDat
  //KhaiThac
  cscore_tnn_KhaiThac: 'Khai thác',
  cscore_tnn_ThamDo: 'Thăm dò',
  cscore_tnn_ctxt: "Xả thải",
  cscore_tnn_nuocbien: "Nước biển",
  cscore_tnn_tongLuongKhaiThac: 'Tổng lượng nước khai thác (m3/ngày.đêm)',
  cscore_tnn_soGieng: 'Số giếng',
  cscore_tnn_soGiengKT: "Số giếng khai thác, dự phòng",
  cscore_tnn_sgquantrac: "Số giếng quan trắc",
  cscore_tnn_tongSoGieng: 'Tổng số giếng',
  cscore_tnn_gieng: 'Giếng',
  cscore_tnn_gieng_info: 'Thông tin giếng',
  cscore_tnn_gieng_ds: 'Danh sách giếng',
  cscore_tnn_gieng_add: 'Thêm mới giếng',
  cscore_tnn_chieuSauMucNuoc: 'Chiều sâu mực nước',
  cscore_tnn_chieuSauMucNuocTinh: 'Chiều sâu mực nước tĩnh (m)',
  cscore_tnn_mnTinh: 'Tĩnh (m)',
  cscore_tnn_chieuSauMucNuocDong: 'Chiều sâu mực nước lớn nhất cho phép (m)',
  cscore_tnn_mnDong: 'Động (m)',
  cscore_tnn_tangChuaNuocKhaiThac: 'Tầng chứa nước khai thác',
  cscore_tnn_chieuSauGieng: 'Chiều sâu giếng (m)',
  cscore_tnn_chieuSauDoanThuNuoc: 'Chiều sâu đoạn thu nước từ',
  cscore_tnn_chieuSauDoanThuNuocTu: 'Chiều sâu đoạn thu nước từ (m)',
  cscore_tnn_mnTu: 'Từ (m)',
  cscore_tnn_chieuSauDoanThuNuocDen: 'Chiều sâu đoạn thu nước đến (m)',
  cscore_tnn_mnDen: 'Đến (m)',
  cscore_tnn_cheDoKhaiThac: 'Chế độ khai thác',
  cscore_tnn_toaDo: 'Tọa độ',
  cscore_tnn_toaDo_palceholder: 'x,y',
  cscore_tnn_diemKhaiThac: 'Điểm khai thác',
  cscore_tnn_chieuSauDatOngLoc: 'Chiều sâu đặt ống lọc (m)',
  cscore_tnn_chieuSauMucNuocDongMax: 'Chiều sâu mức nước đóng lớn nhất (m)',
  cscore_tnn_loaiGieng_placeholder: 'Chọn loại giếng',
  cscore_tnn_loaiGieng: 'Loại giếng',
  cscore_tnn_thuocSong: 'Thuộc sông',
  cscore_tnn_thuocLVS: 'Thuộc lưu vực sông',
  cscore_tnn_thuocHC: 'Thuộc hồ chứa',
  //ThamDo
  cscore_tnn_thamDo: "Thăm dò",
  cscore_tnn_mucDichThamDo: "Mục đích thăm dò",
  cscore_tnn_giengThamDo: 'Giếng thăm dò',
  cscore_tnn_dienTichThamDo: 'Diện tích thăm dò (ha)',
  cscore_tnn_tongLuongThamDo: 'Tổng lượng thăm dò (m3)',
  cscore_tnn_tangChuaNuocThamDo: 'Tầng chứa nước thăm dò',
  cscore_tnn_luuLuongThamDo: 'Lưu lượng thăm dò',

  //LuuLuongKhaiThac
  cscore_tnn_congSuatLapMay: 'Công suất lắp máy(MW)',
  cscore_tnn_luuLuongMaxQuaNMTD: `Lưu lượng lớn nhất qua nhà máy thủy điện (m3/s)`,
  cscore_tnn_luuLuongLonNhatQuaNMTD: `Lưu lượng lớn nhất qua nhà máy thủy điện (m3/s)`,
  cscore_tnn_luuLuongXaDongChayToiThieu: 'Lưu lượng xả dòng chảy tối thiểu (m3/s)',
  cscore_tnn_luuLuongXaDongChayMin: 'Lưu lượng xả dòng chảy tối thiểu (m3/s)',

  //statistic
  cscore_tnn_statistic_1: "Biểu 01",
  cscore_tnn_statistic_2: "Biểu 02",
  //WaterMonitoring
  cscore_tnn_sign: "Kí hiệu",
  cscore_tnn_parameter: "Thông số",
  cscore_tnn_unit: "Đơn vị tính",
  cscore_tnn_add_water_moni_para: "Thêm mới thông số quan trắc nước",
  cscore_tnn_edit_moni_param: "Sửa thông số",

  //Applied Standard
  cscore_tnn_ma: "Mã",
  cscore_tnn_name_standard: "Tên quy chuẩn",
  cscore_tnn_date_issued: "Ngày ban hành",
  cscore_tnn_issuing_agency: "Cơ quan phát hành",
  cscore_tnn_hieuluc: "Hiệu lực",
  cscore_tnn_chonHieuLuc: "Chọn hiệu lực",
  cscore_tnn_file: "Văn bản đính kèm",
  cscore_tnn_applied_standard: "Quản lý quy chuẩn áp dụng",
  cscore_tnn_info_applied_standard: "Sửa quy chuẩn",
  cscore_tnn_add_applied_standard: "Thêm mới quy chuẩn",
  //action
  cscore_tnn_add: "Thêm mới",
  cscore_tnn_edit: "Cập nhật",
  cscore_tnn_detail: "Chi tiết",

  // Licenses
  cscore_tnn_license: "Giấy phép",
  cscore_tnn_qd_pheduyettcq: "Quyết định phê duyệt tiền cấp quyền",
  cscore_tnn_license_number: "Số giấy phép",
  cscore_tnn_license_document: "Tên văn bản",
  cscore_tnn_license_agency: "Cơ quan cấp phép",
  cscore_tnn_license_organization: "Tổ chức/cá nhân được cấp phép",
  cscore_tnn_license_organization_placeholder: "--- Tổ chức/cá nhân được cấp phép ---",
  cscore_tnn_license_end_date: "Đến ngày",
  cscore_tnn_license_available_date: "Có hiệu lực từ ngày",
  cscore_tnn_license_available_end_date: "Có hiệu lực đến ngày",
  cscore_tnn_license_available_date_placeholder: "--- Có hiệu lực từ ngày ---",
  cscore_tnn_license_period: "Thời hạn",
  cscore_tnn_license_add: "Thêm mới cấp phép",
  cscore_tnn_license_edit: "Sửa thông tin cấp phép",
  cscore_tnn_license_signed_person: "Người ký",
  cscore_tnn_license_signed_date: "Ngày ký",
  cscore_tnn_license_position: "Chức vụ",
  cscore_tnn_pladceholder_chucvu: "Nhập chức vụ",
  cscore_tnn_license_place_received: "Nơi nhận",
  cscore_tnn_license_result: "Kết quả xử lý",
  cscore_tnn_license_type: "Loại hình giấy phép",
  cscore_tnn_loai_gp: "Loại giấy phép",
  cscore_tnn_license_construction: "Công trình",
  cscore_tnn_license_type_construction: "Loại công trình",
  cscore_tnn_license_purpose: "Mục đích khai thác",
  cscore_tnn_thoiHanGiayPhep: "Thời hạn giấy phép",
  cscore_tnn_diaChiDonViXCP: "Địa chỉ tổ chức/cá nhân được cấp phép",
  cscore_tnn_nhomCT: "Nhóm công trình",
  cscore_tnn_pladceholder_nhomCT: "Chọn nhóm công trình",
  cscore_tnn_pladceholder_loaiCT: "Chọn loại công trình",
  cscore_tnn_pladceholder_CT: "Chọn công trình",
  cscore_tnn_ttkt: "Thanh tra - Kiểm tra",
  cscore_tnn_tgthanhtra: "Thời gian thanh tra",

  cscore_tnn_tcqktsd: "Tiền cấp quyền khai thác sử dụng",
  cscore_tnn_tenDot: "Tên đợt",
  cscore_tnn_tenDonViThucHien: "Tên đơn vị thực hiện",
  cscore_tnn_tenTruongDoan: "Tên trưởng đoàn",
  cscore_tnn_namThucHien: "Năm thực hiện",
  cscore_tnn_soQuyetDinh: "Số quyết định",
  cscore_tnn_tongTien: "Tổng tiền (VNĐ)",
  cscore_tnn_kttd: "Kiểm tra tọa độ",
  cscore_tnn_kiem_tra: "Kiểm tra",
  cscore_tnn_cach_tinh_Cmax: "Cách tính Cmax",
  cscore_tnn_nam_cap_phep: "Năm cấp phép",
  cscore_tnn_nam_het_han: "Năm hết hạn cấp phép",
  cscore_tnn_vi_tri_quan_trac: "Vị trí quan trắc",
  // CP CTNM
  cscore_tnn_thamSoHC: "Tham số hồ chứa",
  cscore_tnn_chatLuongNuocMat: "Chất lượng nước mặt",
  cscore_tnn_license_construction_info: "Thông tin công trình",
  cscore_tnn_license_hnkndd_info: "Thông tin giấy phép hành nghề",
  cscore_tnn_license_working_items: "Thông tin hạng mục ",
  cscore_tnn_license_attach_files: "Thông tin tài liệu đính kèm",
  cscore_tnn_license_mining_quantity: "Lưu lượng khai thác, sử dụng (m3/s)",
  cscore_tnn_license_mining_quantity_day: "Lưu lượng khai thác, sử dụng (m3/ngày đêm)",
  cscore_tnn_license_resource: "Nguồn nước khai thác",
  cscore_tnn_license_mining_mode: "Chế độ khai thác",
  cscore_tnn_license_mining_method: "Phương thức khai thác",
  cscore_tnn_license_mining_period: "Thời hạn giấy phép",
  cscore_tnn_thoiHanGiaHan: "Thời hạn gia hạn",
  cscore_tnn_license_mining_location: "Thông tin vị trí công trình:",
  cscore_tnn_noiDungCapLai: "Nội dung cấp lại",
  cscore_tnn_soGiayPhepCu: "Số giấy phép cũ",
  cscore_tnn_ngayCapPhepCu: "Ngày cấp phép cũ",
  cscore_tnn_noiDungDieuChinh: "Nội dung điều chỉnh",
  cscore_tnn_thongSoQuanTrac: "Thông số quan trắc",
  cscore_tnn_gioiHanThongSo: "Giới hạn thông số",
  cscore_tnn_luu_luong_theo_mdktsd: "Lưu lượng theo mục đích khai thác sử dụng",
  cscore_tnn_luu_luong_theo_mdtd: "Lưu lượng theo mục đích thăm dò",
  cscore_tnn_tittle_BVVBHVS: "Quyết định phê duyệt vùng bảo hộ vệ sinh",
  cscore_tnn_noidung: "Nội dung",

  // CP_NDD
  cscore_tnn_license_underground_mining: "Giấy phép khai thác nước dưới đất",
  cscore_tnn_license_underground_scanning: "Giấy phép thăm dò nước dưới đất",
  cscore_tnn_license_bang_mdkt: "Mục đích khai thác sử dụng nước",
  cscore_tnn_license_bang_ddkt: "Các thông số của công trình cụ thể",
  cscore_tnn_capThamQuyen: "Cấp thẩm quyền",
  cscore_tnn_thoiHanKT: "Thời hạn khai thác",
  cscore_tnn_tongLuongKT: "Tổng lượng nước khai thác (m3/ngày.đêm)",
  cscore_tnn_tenMucDich: "Tên mục đích",
  cscore_tnn_mucDichKhaiThac: "Mục đích khai thác",
  cscore_tnn_luuLuong: "Tổng lượng (m3/ngày.đêm)",
  cscore_tnn_quyMoThamDo: "Quy mô thăm dò",

  // CPXT
  cscore_tnn_chatLuongNuocThai: "Chất lượng nước thải",
  cscore_tnn_tsndNuocThai: "Giới hạn thông số",
  cscore_tnn_license_discharge: "Giấy phép xả thải",
  cscore_tnn_nguonNuocXaSSD: "Nguồn nước tiếp nhận nước xả thải",
  cscore_tnn_quyMoHanhNghe: "Quy mô hành nghề",
  cscore_tnn_thoiHanHanhNghe: "Thời hạn hành nghề",
  cscore_tnn_viTriXaThai: "Vị trí xả thải",
  cscore_tnn_heSoKQ: "Hệ số kq",
  cscore_tnn_heSoKF: "Hệ số kf",
  cscore_tnn_tenGiaiDoan: "Tên giai đoạn",
  cscore_tnn_loaiThoiHan: "Loại thời hạn",
  cscore_tnn_tenThoiHan: "Tên thời hạn",
  cscore_tnn_thoiGianTu: "Từ ngày",
  cscore_tnn_thoiGianDen: "Đến ngày",
  cscore_tnn_toanThoiGian: "Toàn thời gian",
  cscore_tnn_tenDiemXT: "Tên điểm xả thải",

  // CP HNKNDD
  cscore_tnn_license_hnkndd: "Giấy phép hành nghề khoan nước dưới đẩt",

  // CP CTNB
  cscore_tnn_license_seaWaterMining: "Giấy phép khai thác nước biển",
  cscore_tnn_chatLuongNuocBien: "Chất lượng nước biển",
  //CTNM_ThuyDien
  cscore_tnn_tenCongTrinh: "Tên công trình",
  cscore_tnn_tenDVHC: 'Đơn vị hành chính',
  cscore_tnn_hydropower_name: "Tên công trình",
  cscore_tnn_hydropower_code: "Ký hiệu công trình",
  cscore_tnn_hydropower_place: "Địa điểm",
  cscore_tnn_diaDiem: "Địa điểm",
  cscore_tnn_province: "Tỉnh/Thành phố",
  cscore_tnn_province_placeholder: "Chọn tỉnh/ Thành phố",
  cscore_tnn_district: "Quận/Huyện",
  cscore_tnn_district_placeholder: "Chọn huyện",
  cscore_tnn_ward: "Xã/Phường",
  cscore_tnn_ward_placeholder: "Chọn xã",
  cscore_tnn_donViQuanLy: "Đơn vị quản lý",
  cscore_tnn_donViQuanLy_placeholder: "Chọn đơn vị quản lý",
  cscore_tnn_nhiemVu: "Nhiệm vụ",
  cscore_tnn_nguonNuocKhaiThac: "Nguồn nước khai thác",
  cscore_tnn_loaiHinhToChuc: "Loại hình tổ chức",
  cscore_tnn_namXayDung: "Năm xây dựng",
  cscore_tnn_namSuDung: "Năm sử dụng",
  cscore_tnn_tanSuatQuanTrac: "Tần suất quan trắc",
  cscore_tnn_congSuat: "Công suất (MW)",
  cscore_tnn_dungTichHoChua: "Dung tích hồ chứa(Triệu m3)",
  cscore_tnn_mucNuocDangBinhThuong: 'Mực nước dâng bình thường (m)',
  cscore_tnn_mucNuocCaoNhatTruocLu: 'Mực nước cao nhất trước lũ (m)',
  cscore_tnn_mucNuocDonLu: 'Mực nước đón lũ (m)',
  cscore_tnn_mucNuocChet: 'Mực nước chết (m)',
  cscore_tnn_luuLuongKhaiThacLonNhat: 'Lưu lượng lớn nhất qua nhà máy (m3/ngày.đêm)',
  cscore_tnn_luuLuongKhaiThacNhoNhat: 'Lưu lượng nhỏ nhất qua nhà máy (m3/ngày.đêm)',
  cscore_tnn_kinhTuyenTruc: 'Kinh tuyến trục(VN2000)',
  cscore_tnn_kinhTuyenTruc_placeholder: 'Kinh tuyến trục',
  cscore_tnn_loaiKTT: 'Loại kinh tuyến trục',
  cscore_tnn_muiChieu: 'Múi chiếu',
  cscore_tnn_muiChieu_placeholder: 'Chọn múi chiếu',
  cscore_tnn_ghiChu: 'Ghi chú',
  cscore_tnn_key: 'Ký hiệu',
  cscore_tnn_themFileDinhkem: 'Thêm tài liệu liên quan',
  cscore_tnn_themMultiFileDinhkem: 'Thêm nhiều tài liệu liên quan',
  cscore_tnn_capNhatFileDinhkem: 'Cập nhật tài liệu liên quan',
  cscore_tnn_themToaDo: 'Thêm tọa độ',
  cscore_tnn_capNhatToaDo: 'Cập nhật tọa độ',
  cscore_tnn_themHangMuc: 'Thêm hạng mục',
  cscore_tnn_capNhatHangMuc: 'Cập nhật hạng mục',
  cscore_tnn_hangmuc: 'Hạng mục',
  cscore_tnn_themLuuLuongKhaiThac: 'Thêm lưu lượng khai thác',
  cscore_tnn_capNhatLuuLuongKhaiThac: 'Cập nhật lưu lượng khai thác',
  cscore_tnn_x: 'Tọa độ x',
  cscore_tnn_y: 'Tọa độ y',
  cscore_tnn_coordinate: 'Tọa độ',
  cscore_tnn_toaDoCongTrinh: 'Tọa độ công trình',

  // Hang muc
  cscore_tnn_work_item_name: "Tên hạng mục",
  cscore_tnn_work_item_coordinateX: "Tọa độ X",
  cscore_tnn_work_item_coordinateY: "Tọa độ Y",
  cscore_tnn_hangMucCongTrinh: 'Hạng mục công trình',

  //FileDinhkem
  cscore_tnn_fileDinhkem: 'Tài liệu liên quan',
  cscore_tnn_fileTaiLieu: 'File tài liệu',
  cscore_tnn_tenTaiLieu: 'Tên tài liệu',
  cscore_tnn_soTaiLieu: 'Số tài liệu',
  cscore_tnn_ngayKy: 'Ngày ký',

  // LNKT
  cscore_tnn_quantity_capacity: "Công suất lắp máy",
  cscore_tnn_quantity_maximum_flowing: "Lưu lượng lớn nhất qua NMTĐ (m3/s)",
  cscore_tnn_quantity_maximum_mining: "Lưu lượng khai thác lớn nhất (m3/s)",
  //Tram Qt 
  cscore_tnn_tramQTTnn: "Quản lý trạm quan trắc",
  cscore_tnn_pladceholder_tramQT: "Chọn trạm quan trắc",
  cscore_tnn_pladceholder_kyQT: "Chọn kỳ quan trắc",
  cscore_tnn_add_tramQT: "Thêm mới trạm quan trắc",
  cscore_tnn_edit_tramQT: "Sửa trạm quan trắc",
  cscore_tnn_tramQT_KTTV: "Quản lý trạm quan trắc khí tượng thủy văn",
  cscore_tnn_add_tramQT_KTTV: "Thêm mới trạm quan trắc",
  cscore_tnn_edit_tramQT_KTTV: "Sửa trạm quan trắc",
  cscore_tnn_hang_tram: "Hạng trạm",
  cscore_tnn_kinh_tuyen_truc: "Kinh tuyến trục",
  cscore_tnn_dm_kinh_tuyen_truc: "Danh mục kinh tuyến trục",
  cscore_tnn_add_kinh_tuyen_truc_TW: "Thêm kinh tuyến trục cấp trung ương",
  cscore_tnn_add_kinh_tuyen_truc_DP: "Thêm kinh tuyến trục cấp địa phương",
  cscore_tnn_edit_kinh_tuyen_truc: "Sửa kinh tuyến trục",
  cscore_tnn_cap: "Cấp",
  cscore_tnn_chon_cap: "Chọn cấp",
  cscore_tnn_do: "Độ",
  cscore_tnn_phut: "Phút",
  cscore_tnn_so_gieng: "Số giếng",
  cscore_tnn_so_hieu_gieng: "Số hiệu giếng",
  cscore_tnn_so_hieu: "Số hiệu",
  cscore_tnn_gieng_kt_ndd: "Giếng khai thác nước dưới đất",
  cscore_tnn_song: "Sông",
  cscore_tnn_ten_DVHC: "Đơn vị hành chính",
  cscore_tnn_luu_vuc_song: "Lưu vực sông",
  cscore_tnn_ten_tram: "Tên trạm",
  cscore_tnn_file_excel: "Nhập file Excel",
  cscore_tnn_out_excel: "Xuất file Excel",
  //Qt Tieu chuan danh gia
  cscore_tnn_pladceholder_TCAD: "Chọn Quy chuẩn áp dụng",
  cscore_tnn_gioi_han_min: "Giới hạn nhỏ nhất",
  cscore_tnn_gioi_han_max: "Giới hạn lớn nhất",
  cscore_tnn_phan_loai: "Phân loại",
  cscore_tnn_edit_tieu_chuan_danh_gia: "Thông tin tiêu chuẩn đánh giá",
  cscore_tnn_add_tieu_chuan_danh_gia: "Thêm tiêu chuẩn đánh giá",
  //QT Diem QT
  cscore_tnn_ki_hieu_diem: "Ký hiệu điểm",
  cscore_tnn_mui_chieu: "Múi chiếu",
  cscore_tnn_ten_diem: "Tên điểm",
  cscore_tnn_diem_quan_trac: "Quản lý điểm quan trắc định kỳ",
  cscore_tnn_add_diem_quan_trac: "Thêm mới điểm quan trắc",
  cscore_tnn_edit_diem_quan_trac: "Sửa điểm quan trắc",
  cscore_tnn_toa_do: "Tọa độ (X,Y)",
  cscore_tnn_pladceholder_diem_qt: "Chọn điểm quan trắc",
  cscore_tnn_tuyen_QT: "Tuyến quan trắc",
  cscore_tnn_dm_tuyen_qt: "Quản lý tuyến quan trắc",
  cscore_tnn_name_tuyen_qt: "Tên tuyến quan trắc",
  cscore_tnn_add_tuyen_qt: "Thêm tuyến quan trắc",
  cscore_tnn_edit_tuyen_qt: "Sửa tuyến quan trắc",
  cscore_tnn_diem_quan_trac_CT: "Quản lý điểm quan trắc công trình",
  cscore_tnn_add_diem_quan_trac_CT: "Thêm mới điểm quan trắc công trình",
  cscore_tnn_edit_diem_quan_trac_CT: "Sửa điểm quan trắc công trình",
  cscore_tnn_inp_diem_qt: "Điểm quan trắc",
  cscore_tnn_inp_dot_qt: "Đợt quan trắc",
  //QT Dot QT
  cscore_tnn_dot_quan_trac: "Quản lý đợt quan trắc",
  cscore_tnn_add_dot_quan_trac: "Thêm mới đợt quan trắc",
  cscore_tnn_edit_dot_quan_trac: "Sửa đợt quan trắc",
  cscore_tnn_name_dot_quan_trac: "Tên dợt quan trắc",
  cscore_tnn_time_start: "Ngày bắt đầu",
  cscore_tnn_time_end: "Ngày kết thúc",
  cscore_tnn_ke_hoach_QT: "Kế hoạch quan trắc",
  cscore_tnn_noi_dung_QT: "Nội dung quan trắc",
  cscore_tnn_don_vi_thuc_hien: "Đơn vị thực hiện",
  cscore_tnn_dm_don_vi_TH: "Danh mục đơn vị thực hiện",
  cscore_tnn_add_don_vi_TH: "Thêm mới đơn vị",
  cscore_tnn_edit_don_vi_TH: "Sửa đơn vị",
  cscore_tnn_name_don_vi_TH: "Tên đơn vị",
  cscore_tnn_pladceholder_dot_QT: "Chọn đợt quan trắc",
  cscore_tnn_start: "Bắt đầu",
  cscore_tnn_end: "Kết thúc",

  //Cấu hình thông số quan trắc

  // danh muc đánh dấu lỗi

  cscore_tnn_add_dgl: "Thêm mới sự cố",
  cscore_tnn_select_dgl: "Loại sự cố",
  cscore_tnn_select_dgl_choose: "Chọn loại sự cố",
  cscore_tnn_nd_dgl: "Nội dung sự cố",
  cscore_tnn_nd_xuly: "Nội dung xử lý sự cố",

  //Mẫu quan trắc
  cscore_tnn_mau_quan_trac: "Quản lý mẫu quan trắc định kỳ",
  cscore_tnn_mau_quan_trac_CT: "Quản lý mẫu quan trắc công trình",
  cscore_tnn_sample_name: "Tên mẫu",
  cscore_tnn_ngay_lay_mau: "Ngày lấy mẫu",
  cscore_tnn_ngay_phan_tich: "Ngày phân tích",
  cscore_tnn_ket_qua_QT: "Kết quả quan trắc",
  cscore_tnn_ds_ket_qua_qt: "Danh sách kết quả quan trắc",
  cscore_tnn_value: "Giá trị",
  cscore_tnn_don_vi: "Đơn vị",
  cscore_tnn_add_mau_QT: "Thêm mới mẫu quan trắc",
  cscore_tnn_edit_mau_QT: "Sửa mẫu quan trắc",
  cscore_tnn_add_mau_QT_CT: "Thêm mẫu quan trắc công trình",
  cscore_tnn_edit_mau_QT_CT: "Sửa mẫu quan trắc công trình",
  cscore_tnn_pladceholder_mau_QT: "Chọn mẫu quan trắc",
  cscore_tnn_danh_gia: "Đánh giá",
  cscore_tnn_danh_gia_QT: "Đánh giá kết quả quan trắc nước",
  cscore_tnn_danh_gia_QT_CT: "Đánh giá kết quả quan trắc công trình",
  cscore_tnn_phuong_phap_LM: "Phương pháp lấy mẫu",
  cscore_tnn_name_Phuong_phap_LM: "Tên phương pháp",
  cscore_tnn_DM_PP_LM: "Quản lý phương pháp lấy mẫu",
  cscore_tnn_add_PP: "Thêm phương pháp",
  cscore_tnn_edit_PP: "Sửa phương pháp",
  cscore_tnn_phuong_phap_PT: "Phương pháp phân tích",
  cscore_tnn_DM_PP_PT: "Quản lý phương pháp phân tích",
  cscore_tnn_doi_tuong_quan_trac: "Đối tượng quan trắc",
  cscore_tnn_quan_ly_du_lieu_quan_trac_nm: "Quản lý dữ liệu quan trắc nước mặt",
  cscore_tnn_quan_ly_du_lieu_quan_trac_ndd: "Quản lý dữ liệu quan trắc nước dưới đất",
  cscore_tnn_quan_ly_du_lieu_quan_trac_xt: "Quản lý dữ liệu quan trắc nước xả thải",
  cscore_tnn_quan_ly_du_lieu_quan_trac_kt: "Quản lý dữ liệu quan trắc khí tượng",
  cscore_tnn_quan_ly_du_lieu_quan_trac_tv: "Quản lý dữ liệu quan trắc thủy văn",
  //Thong so quan trac khi tuong
  cscore_tnn_khi_tuong_QT: "Quản lý thông số quan trắc khí tượng",
  cscore_tnn_add_khi_tuong_QT: "Thêm thông số quan trắc khí tượng",
  cscore_tnn_edit_thong_so_khi_tuong: "Sửa thông số quan trắc khí tượng",
  cscore_tnn_QT_khi_tuong: "Quan trắc khí tượng",
  cscore_tnn_nuoc_mat: "Nước mặt",
  cscore_tnn_nuoc_duoi_dat: "Nước dưới đất",
  cscore_tnn_nuoc_xa_thai: "Nước xả thải",
  //Thong so quan trac thuy van
  cscore_tnn_thuy_van_QT: "Quản lý thông số quan trắc thủy văn",
  cscore_tnn_add_thuy_van_QT: "Thêm thông số quan trắc thủy văn",
  cscore_tnn_edit_thong_so_thuy_van: "Sửa thông số quan trắc thủy văn",
  cscore_tnn_QT_thuy_van: "Quan trắc thủy văn",
  //Ky quan trac
  cscore_tnn_ky_QT: "Tên kỳ",
  cscore_tnn_phan_loai_quan_trac: "Chọn loại quan trắc",
  cscore_tnn_Loai_quan_trac: "Loại quan trắc",
  cscore_tnn_cau_hinh_thong_so_KTTV: "Cấu hình thông số quan trắc khí tượng thủy văn",
  cscore_tnn_ky_quan_trac: "Quản lý kỳ quan trắc",
  cscore_tnn_add_ky_QT: "Thêm mới kỳ quan trắc",
  cscore_tnn_edit_ky_QT: "Sửa kỳ quan trắc",
  //Danh muc
  cscore_tnn_DM_Loai_VB: "Danh mục loại văn bản",
  cscore_tnn_add_loai_VB: "Thêm mới loại văn bản",
  cscore_tnn_edit_loai_vb: "Sửa loại văn bản",
  cscore_tnn_ma_vb: "Mã loại văn bản",
  cscore_tnn_ten_vb: "Tên loại văn bản",
  cscore_tnn_QL_cong_trinh: "Danh mục đơn vị quản lý công trình",
  cscore_tnn_Add_QL_cong_trinh: "Thêm mới đơn vị quản lý công trình",
  cscore_tnn_Edit_QL_cong_trinh: "Sửa thông tin đơn vị quản lý công trình",
  cscore_tnn_co_quan_chu_quan: "Cơ quan chủ quản",
  cscore_tnn_khu_cong_nghiep: "Danh mục khu công nghiệp",
  cscore_tnn_add_khu_cong_nghiep: "Thêm khu công nghiệp",
  cscore_tnn_edit_khu_cong_nghiep: "Sửa thông tin khu công nghiệp",
  cscore_tnn_name_khu_cong_nghiep: "Tên khu công nghiệp",
  cscore_tnn_key_khu_cong_nghiep: "Mã khu công nghiệp",
  cscore_tnn_chu_dau_tu: "Chủ đầu tư",
  cscore_tnn_dien_tich: "Diện tích",
  cscore_tnn_ngay_sua: "Ngày sửa",
  cscore_tnn_ngay_tao: "ngày tạo",
  cscore_tnn_nguoi_sua: "Người sửa",
  cscore_tnn_nguoi_tao: "Người tạo",
  cscore_tnn_qdtl_so: "Quyết định thành lập số",
  cscore_tnn_thoi_diem: "Thời điểm",
  cscore_tnn_vi_tri: "vị trí",
  cscore_tnn_ngay_cap_chung_nhan: "Ngày cấp chứng nhận",
  cscore_tnn_noi_cap_chung_nhan: "Nơi cấp chứng nhận",
  cscore_tnn_so_chung_nhan: "Số chứng nhận",
  cscore_tnn_hien_trang_hoat_dong: "Hiện trạng hoạt động",
  cscore_tnn_loai_hinh_san_xuat: "Loại hình sản xuất",
  cscore_tnn_lang_nghe_name: "Tên làng nghề",
  cscore_tnn_tong_SH_SX_SoHo: "Tổng số hộ sản xuất",
  cscore_tnn_version: "Phiên bản",
  cscore_tnn_lang_nghe: "Danh mục làng nghề",
  cscore_tnn_add_lang_nghe: "Thêm làng nghề",
  cscore_tnn_edit_lang_nghe: "Sửa thông tin làng nghề",
  cscore_tnn_nha_may: "Danh mục nhà máy",
  cscore_tnn_add_nha_may: "Thêm mới nhà máy",
  cscore_tnn_diachict: "Địa chỉ công trình",
  cscore_tnn_edit_nha_may: "Sửa thông tin nhà máy",
  cscore_tnn_so_giay_phep: "Số giấy phép",
  cscore_tnn_ngay_cap_phep: "Ngày bắt đầu hiệu lực",
  cscore_tnn_tinh_trang: "Tình trạng",
  cscore_tnn_tru_so_chinh: "Trụ sở chính",
  cscore_tnn_DKKD_ngay_cap: "Ngày cấp đăng kí kinh doanh",
  cscore_tnn_DKKD_noi_cap: "Nơi cấp đăng kí kinh doanh",
  cscore_tnn_DKKD_so: "Số đăng kí kinh doanh",
  cscore_tnn_CNDT_ngay_cap: "Ngày cấp chứng nhận đầu tư",
  cscore_tnn_CNDT_noi_cap: "Nơi cấp chứng nhận đầu tư",
  cscore_tnn_CNDT_so: "Số chứng nhận đầu tư",
  cscore_tnn_loai_hinh_doanh_nghiep: "Loại hình doanh nghiệp",
  cscore_tnn_nam_thanh_lap: "Năm thành lập",
  cscore_tnn_NDD_chuc_vu: "Chức vụ người đại diện",
  cscore_tnn_NDD_DiaChi: "Địa chỉ người đại diện",
  cscore_tnn_NDD_SDD_NgayCap: "Ngày cấp số định danh người đại diện",
  cscore_tnn_NDD_SDD_NoiCap: "Nơi cấp số định danh người đại diện",
  cscore_tnn_NDD_SDD_So: "Số định danh người đại diện",
  cscore_tnn_NDD_SoDienThoai: "Số điện thoại người đại diện",
  cscore_tnn_NDD_Ten: "Tên người đại diện",
  cscore_tnn_NDD: "Người đại diện",
  cscore_tnn_NganhNgheKinhDoanh: "Ngành nghề kinh doanh",
  cscore_tnn_QuyMo: "Quy mô",
  cscore_tnn_TKNH_ChiNhanh: "Chi nhánh tài khoản ngân hàng",
  cscore_tnn_TKNH_So: "Số tài khoản ngân hàng",

  //Ket qua QT KTTV
  cscore_tnn_danh_sach_KQ_KTTV: "Quản lý kết quả quan trắc khí tượng thủy văn",
  cscore_tnn_add_DS_KQ_KTTV: "Thêm mới kết quả khí tượng thủy văn",
  cscore_tnn_edit_DS_KQ_KTTV: "Sửa kết quả khí tượng thủy văn",
  cscore_tnn_ngay_QT: "Ngày quan trắc",
  cscore_tnn_tooltip_KQQT: "Bạn chưa chọn trạm quan trắc hoặc kỳ quan trắc",
  cscore_tnn_add_KQ_KTTV: "Thêm mới kết quả quan trắc",
  cscore_tnn_edit_KQQT: "Sửa kết quả quan tắc",
  //Thông tin công trình bản đồ
  cscore_tnn_addDVQL: "Thêm đơn vị quản lý",
  cscore_tnn_loaicongtrinh: "Loại công trình",
  cscore_tnn_dmloaicongtrinh: "Danh mục loại công trình",
  cscore_tnn_addloaicongtrinh: "Thêm mới loại công trình",
  cscore_tnn_editloaicongtrinh: "Sửa loại công trình",
  cscore_tnn_maloaicongtrinh: "Mã loại công trình",
  cscore_tnn_loaigiayphep: "Loại cấp phép",
  cscore_tnn_macongtrinh: "Ký hiệu công trình",
  cscore_tnn_matramkhit: "",
  cscore_tnn_matramthuy: "Mã trạm thủy văn",
  cscore_tnn_mucdichsudung: "Mục đích sử dụng",
  cscore_tnn_donvithamdinh: "Đơn vị thẩm định",
  cscore_tnn_dm_mucdichsudung: "Quản lý mục đích sử dụng",
  cscore_tnn_add_mucdichsudung: "Thêm mục đích sử dụng",
  cscore_tnn_add_donvithamdinh: "Thêm đơn vị thẩm định",
  cscore_tnn_edit_donvithamdinh: "Sửa đơn vị thẩm định",
  cscore_tnn_edit_mucdichsudung: "Sửa mục đích sử dụng",
  cscore_tnn_name_mucdichsudung: "Tên mục đích",
  cscore_tnn_name_donvithamdinh: "Đơn vị thẩm định",
  cscore_tnn_namkhaithac: "Năm khai thác",
  cscore_tnn_namxaydung: "Năm xây dựng",
  cscore_tnn_ngaycapphep: "Ngày cấp phép",
  cscore_tnn_sogiayphep: "Số giấy phép",
  cscore_tnn_tencongtrinh: "Tên công trình",
  cscore_tnn_tendonvicapphep: "Tên đơn vị cấp phép",
  cscore_tnn_tendonvixcp: "Tên tổ chức/cá nhân được cấp phép",
  // tenhuyen: "huyện Đông Hưng"
  // tenxa: "xã Hồng Châu"
  cscore_tnn_thoihangiahan: "Thời hạn giấy phép",

  //Don vi tinh
  cscore_tnn_don_vi_do: "Đơn vị đo",
  cscore_tnn_dm_don_vi_do: "Quản lý đơn vị đo",
  cscore_tnn_add_don_vi: "Thêm mới đơn vị đo",
  cscore_tnn_edit_don_vi: "Sửa đơn vị đo",
  cscore_tnn_name_don_vi: "Tên đơn vị đo",

  //QL VB DP
  cscore_tnn_add_ql_vb_dp: "Thêm mới văn bản địa phương",
  cscore_tnn_edit_ql_vb_dp: "Sửa đơn văn bản địa phương",

  //Bieu mau
  cscore_tnn_BieuMauTT31: "Biểu mẫu TT 31/2018/TT-BTNMT",
  cscore_tnn_BieuMauTT20: "Biểu mẫu TT 20/2018/TT-BTNMT",
  cscore_tnn_Phu_Luc_TT: "Phụ lục",
  cscore_tnn_BieuMauSo1: 'Biểu mẫu số 1',
  cscore_tnn_BieuMauSo6: 'Biểu mẫu số 6',
  cscore_tnn_BieuMauSo9: 'Biểu mẫu số 9',
  cscore_tnn_BieuMauSo10: 'Biểu mẫu số 10',
  cscore_tnn_BieuMauSo11: 'Biểu mẫu số 11',
  cscore_tnn_BieuMauSo12: 'Biểu mẫu số 12',
  cscore_tnn_BieuMauSo15: 'Biểu mẫu số 15',
  cscore_tnn_BieuMauSo16: 'Biểu mẫu số 16',
  cscore_tnn_BieuMauSo17: 'Biểu mẫu số 17',
  cscore_tnn_BieuMauSo19: 'Biểu mẫu số 19',
  cscore_tnn_BieuMauSo20: 'Biểu mẫu số 20',
  cscore_tnn_BieuMauSo0203: 'Biểu mẫu 0203',
  cscore_tnn_BieuMau0205: 'Biểu mẫu 0205',
  cscore_tnn_BieuMau0206: 'Biểu mẫu 0206',
  cscore_tnn_PhuLuc2: 'Phụ lục 2',
  cscore_tnn_PhuLuc3: 'Phụ lục 3',
  cscore_tnn_PhuLuc5: 'Phụ lục 5',
  cscore_tnn_PhuLuc6: 'Phụ lục 6',
  cscore_tnn_TK_GP_SO: 'Thống kê giấy phép cấp sở',
  cscore_tnn_QTHT: "Quản trị hệ thống",
  cscore_tnn_thongkesuco: "Thống kê sự cố",
  cscore_tnn_xuatFileExcel: 'Xuất file Excel',
  cscore_tnn_lamMoi: 'Làm mới',
  cscore_tnn_year_placeholder: "Chọn kỳ báo cáo",
  //Thong tin tai nguyen nuoc
  cscore_tnn_dm_SVLVS: "Quản lý thông tin sông và lưu vực sông",
  cscore_tnn_pladceholder_CapSong: "Chọn cấp sông",
  cscore_tnn_pladceholder_Song: "Chọn sông",
  cscore_tnn_pladceholder_LuuVuc: "Chọn lưu vực sông",
  cscore_tnn_QPPL: "Văn bản quy phạm pháp luật",
  cscore_tnn_phan_loai_bao_cao: "Phân loại báo cáo",
  cscore_tnn_add_PL_bao_cao: "Thêm mới loại báo cáo",
  cscore_tnn_edit_PL_bao_cao: "Sửa loại báo cáo",
  cscore_tnn_loai_ban_do: "Loại bản đồ",
  cscore_tnn_TNN_duoi_dat: "Đánh giá tài nguyên nước dưới đất",
  cscore_tnn_thanh_tra: "Thanh tra",
  cscore_tnn_thanhtrakiemtra: "Thanh tra kiểm tra",
  cscore_tnn_KQ_TT: "Kết quả thanh tra",
  cscore_tnn_KQ_KT_DN: "Kết quả kiểm tra từng doanh nghiệp",
  cscore_tnn_ThanhTraDN: "Thanh tra doanh nghiệp",
  cscore_tnn_KQ_KT: "Kết quả kiểm tra",
  cscore_tnn_ma_csdl: "Cơ sở dữ liệu",
  cscore_tnn_dm_csdl: "Danh mục cơ sở dữ liệu",
  cscore_tnn_TTHC: "Thủ tục hành chính",
  cscore_tnn_add_csdl: "Thêm cơ sở dữ liệu",
  cscore_tnn_edit_csdl: "Sửa cơ sở dữ liệu",
  cscore_tnn_ma_cs_dl: "Mã cơ sở dữ liệu",
  cscore_tnn_name_csdl: "Tên cơ sở dữ liệu",
  cscore_tnn_bao_cao_name: "Loại báo cáo",
  cscore_tnn_add_loai_ban_do: "Thêm mới loại bản đồ",
  cscore_tnn_edit_loai_ban_do: "Sửa loại bản đồ",
  cscore_tnn_add_QPPL: "Thêm văn bản quy phạm pháp luật",
  cscore_tnn_edit_QPPL: "Sửa văn bản quy phạm pháp luật",
  cscore_tnn_co_quan_ban_hanh: "Cơ quan ban hành",
  cscore_tnn_ngay_hieu_luc: "Ngày có hiệu lực",
  cscore_tnn_add_quy_hoach_TNN: "Thêm mới tài liệu quy hạch tài nguyên nước",
  cscore_tnn_edit_quy_hoach_TNN: "Sửa thông tin quy hoạch tài nguyên nước",
  cscore_tnn_ten_quy_hoach: "Tên báo cáo",
  cscore_tnn_nam_thuc_hien: "Năm thực hiện",
  cscore_tnn_add_DG_TNN_duoi_dat: "Thêm mới đánh giá ",
  cscore_tnn_edit_DG_TNN_duoi_dat: "Sửa đánh giá",
  cscore_tnn_dien_tich_dieu_tra: "Diện tích điều tra",
  cscore_tnn_tang_chua_nuoc: "Tầng chứa nước",
  cscore_tnn_ti_le_dieu_tra: "Tỉ lệ điều tra",
  cscore_tnn_vung_dieu_tra: "Vùng điều tra",
  cscore_tnn_khu_vuc: "Khu vực",
  cscore_tnn_add_DTDG: "Thêm mới báo cáo điều tra đánh giá",
  cscore_tnn_edit_DTDG: "Sửa báo cáo điều tra đánh giá",
  cscore_tnn_thanh_tra_TNN: "Thanh tra - kiểm tra tài nguyên nước",
  cscore_tnn_add_thanh_tra: "Thêm báo cáo thanh tra",
  cscore_tnn_edit_thanh_tra: "Sửa báo cáo thanh tra",
  cscore_tnn_ten_thanh_tra: "Tên thanh tra",
  cscore_tnn_xu_phat: "Xử phạt",
  cscore_tnn_DT_thanh_tra: "Đối tượng thanh tra",
  cscore_tnn_nam_thanh_tra: "Năm thanh tra",
  cscore_tnn_so_tien: "Số tiền",
  cscore_tnn_add_bao_cao: "Thêm mới báo cáo",
  cscore_tnn_edit_bao_cao: "Sửa báo cáo",
  cscore_tnn_add_ban_do: "Thêm mới dữ liệu bản đồ",
  cscore_tnn_edit_ban_do: "Sửa dữ liệu bản đồ",
  cscore_tnn_ty_le: "Tỷ lệ",
  cscore_tnn_ten_ban_do: "Tên bản đồ",
  cscore_tnn_pham_vi_thuc_hien: "Phạm vi thực hiện",
  cscore_tnn_dm_NKHT: "Danh mục nhật ký hệ thống",
  cscore_tnn_dm_SuCo: "Thống kê sự cố",
  cscore_tnn_thongtingieng: "Thông tin giếng",
  cscore_tnn_doanhngiepbaocao: "Báo cáo định kỳ",
  cscore_tnn_ketquakiennghi: "Kết quả giải quyết kiến nghị cử tri",
  cscore_tnn_ketquaphananhbaochi: "Kết quả trả lời phản ánh báo chí",
  cscore_tnn_add_kqgqkn: "Thêm mới kết quả giải quyết kiến nghị",
  cscore_tnn_edit_kqgqkn: "Sửa kết quả giải quyết kiến nghị",
  cscore_tnn_add_kqtlpabc: "Thêm mới kết quả trả lời phản ánh báo chí",
  cscore_tnn_edit_kqtlpabc: "Sửa kết quả trả lời phản ánh báo chí",
  cscore_tnn_quanlydoanhngiepbaocao: "Quản lý báo cáo định kỳ doanh nghiệp",
  cscore_tnn_chonquy: "Chọn quý",
  cscore_tnn_chonnam: "Chọn năm",
  cscore_tnn_sldoankt: "Số lượng đoàn kiểm tra",
  cscore_tnn_ndThanhTra: "Nội dung thanh tra",
  cscore_tnn_sldonvixp: "Số lượng đơn vị bị xử phạt",
  cscore_tnn_namBaoCao: "Năm báo cáo",
  cscore_tnn_quybaocao: "Quý báo cáo",
  cscore_tnn_maBaoCao: "Mã báo cáo",
  cscore_tnn_noiDungBaoCao: "Nội dung báo cáo",
  cscore_tnn_sotienxuphat: "Số tiền xử phạt",
  cscore_tnn_soluongtochuccanhan: "Số lượng tổ chức cá nhân",
  cscore_tnn_quyetDinhPheDuyet: " Số quyết định phê duyệt",
  cscore_tnn_thoiGianThucHien: "Thời gian thực hiện",
  cscore_tnn_phamvi: "Phạm vi",
  cscore_tnn_kinhPhi: "Kinh phí",
  cscore_tnn_tiencapquyen: "Tiền cấp quyền",
  cscore_tnn_tunam: "Từ năm",
  cscore_tnn_dennam: "Đến năm",
  cscore_tnn_ngayquyetdinh: "Ngày quyết định",
  cscore_tnn_phuongannoptien: "Phương án nộp tiền",
  cscore_tnn_tongsotien: "Tổng số tiền",
  cscore_tnn_sotiendathu: "Số tiền đã thu",
  cscore_tnn_sotienphaithu: "Số tiền phải thu",
  cscore_tnn_namthu: "Năm thu",
  cscore_tnn_themTCQ: "Thêm mới tiền cấp quyền",
  cscore_tnn_suaTCQ: "Sửa tiền cấp quyền",
  cscore_tnn_tralai: "Trả lại",
  cscore_tnn_ngaypheduyet: "Ngày phê duyệt",

  cscore_tnn_pheduyet: "Phê duyệt",
  cscore_tnn_ngaykiemtra: "Ngày kiểm tra",
  cscore_tnn_kq_khac_phuc: "Kết quả khác phục",
  cscore_tnn_loi_xu_phat: "Các lỗi chuyển xử phạt",
  cscore_tnn_kq_xu_phat: "Kết quả xử phạt",
  cscore_tnn_nam: "Năm",
  cscore_tnn_dong: "Đóng",
  cscore_tnn_delete: "Xóa",
  cscore_tnn_danh_sach_bao_cao: "Danh sách báo cáo",
  cscore_tnn_muc_dich_su_dung_nuoc: "Mục đích sử dụng nước",
  cscore_tnn_ql_muc_dich_su_dung_nuoc: "Quản lý mục đích sử dụng nước",
  cscore_tnn_ql_loai_su_co: "Quản lý danh mục loại sự cố",
  cscore_tnn_ql_don_vi_tham_dinh: "Quản lý đơn vị thẩm định",
  cscore_tnn_ql_loai_hinh_nuoc_thai: "Quản lý loại hình nước thải",
  cscore_tnn_add_loai_hinh_nuoc_thai: "Thêm mới loại hình nước thải",
  cscore_tnn_edit_loai_hinh_nuoc_thai: "Sửa loại hình nước thải",
  cscore_tnn_QD_Nguon_Nuoc_Can_Bao_Ve: "Quyết định nguồn nước cần lập hành lăng bảo vệ",
  cscore_tnn_Add_Quyet_Dinh: "Thêm mới quyết định",
  cscore_tnn_Edit_Quyet_Dinh: "Sửa quyết định",
  cscore_tnn_DM_Quyet_Dinh: "Danh mục quyết định phê duyệt nguồn nước cần lập hành lang bảo vệ",
  cscore_tnn_DM_Nguon_Nuoc: "Danh mục nguồn nước",
  cscore_tnn_name_nguon_nuoc: "Tên nguồn nước",
  cscore_tnn_Add_Nguon_Nuoc: "Thêm mới nguồn nước",
  cscore_tnn_Edit_Nguon_Nuoc: "Sửa nguồn nước",
  cscore_tnn_Giam_Sat_khai_thac: "Giám sát khai thác sử dụng",
  cscore_tnn_Chat_Luong_NDD: "Chất lượng nước dưới đất",
  cscore_tnn_duoc_cap_phep: "Được cấp phép khai thác",
  cscore_tnn_loai_tram: "Loại trạm",
  cscore_tnn_chon_ct_tu_gp_da_co: "Chọn công trình từ giấy phép bị thay thế",
  cscore_tnn_ct_tu_gp_da_co: "Nhập số giấy phép để chọn công trình từ giấy phép bị thay thế",

  cscore_tnn_vung_kinh_te: "Vùng kinh tế xã hội",
  cscore_tnn_dm_vung_kinh_te: "Danh mục vùng kinh tế xã hội",
  cscore_tnn_post_vung_kinh_te: "Thêm mới vùng kinh tế",
  cscore_tnn_put_vung_kinh_te: "Sửa vùng kinh tế",
  cscore_tnn_ma_vung: "Mã vùng",
  cscore_tnn_ten_vung: "Tên vùng",

  cscore_tnn_dot_bao_cao: "Đợt báo cáo",
  //Danh mục nguồn nước nôi tỉnh
  cscore_tnn_dm_nguon_nuoc_noi_tinh: "Danh mục nguồn nước",
  cscore_tnn_nguon_nuoc_noi_tinh: "Danh mục nguồn nước",
  cscore_tnn_add_nnnt: "Thêm mới nguồn nước",
  cscore_tnn_edit_nnnt: "Sửa nguồn nước",
  cscore_tnn_input_tensongsuoi: "Tên nguồn nước",
  cscore_tnn_input_ma_songsuoi: "Mã nguồn nước",
  cscore_tnn_input_chieudai: "Chiều dài (Km)",
  cscore_tnn_input_dosau: "Độ sâu (m)",
  cscore_tnn_input_dtlucvuc: "Diện tích lưu vực (Km2)",
  cscore_tnn_input_chayrasong: "Chảy ra",
  cscore_tnn_input_truluong: "Trữ lượng (Triệu m3)",
  cscore_tnn_input_llmm: "Lượng nước mùa mưa (triệu m3)",
  cscore_tnn_input_llmk: "Lượng nước mùa khô (triệu m3)",
  cscore_tnn_input_llcn: "Lượng nước cả năm (triệu m3)",
  cscore_tnn_slect_nam: " năm",
  cscore_tnn_input_ma_song: "Mã sông",
  cscore_tnn_title_TNL: "Tổng lượng nước",
  cscore_tnn_select_song: "Chọn loại nguồn nước",
  cscore_tnn_input_song: "Loại nguồn nước",

  // danh mục loại sự cố
  cscore_tnn_dm_loai_su_co: "Loại sự cố",
  cscore_tnn_ten_su_co: "Tên sự cố",
  cscore_tnn_ten_loai_su_co: "Tên loại sự cố",
  cscore_tnn_add_loaisuco: " Thêm mới loại sự cố",
  cscore_tnn_edit_loaisuco: " Sửa loại sự cố",
  cscore_tnn_update_ndsc: "Cập nhật trạng thái sự cố",
  cscore_tnn_dot_quan_trac_ten: "Tên đợt quan trắc",
  cscore_tnn_dot_quan_trac_number: "Đợt quan trắc số",
  cscore_tnn_nguoi_lay_mau: "Người lấy mẫu",
  cscore_tnn_nguoi_giam_sat: "Người giám sát",
  cscore_tnn_ngay_nhan_mau: "Ngày nhận mẫu",
  cscore_tnn_cap_nhat_kqqt: "Cập nhật kết quả quan trắc",
  cscore_tnn_them_moi_kqqt: "Thêm mới kết quả quan trắc",
  // danh muc co quan ban hanh
  cscore_tnn_title_coquanbanhanh: "Cơ quan ban hành",
  cscore_tnn_add_coquanbanhanh: "Thêm mới cơ quan ban hành",
  cscore_tnn_edit_coquanbanhanh: "Sửa cơ quan ban hành",
  // danh muc loai vb dia phuong
  cscore_tnn_title_loaVBDP: "Loại Văn bản địa phương",
  cscore_tnn_add_loaVBDP: "Thêm mới loại Văn bản địa phương",
  cscore_tnn_edit_loaVBDP: "Sửa loại Văn bản địa phương",
  //qlkt thuy van
  cscore_tnn_title_kttv: "Dữ liệu khí tượng thủy văn",
  cscore_tnn_add_khituongtv: "Thêm mới dữ liệu khí tượng thủy văn",
  cscore_tnn_edit_khituongtv: "Sửa thông tin dữ liệu khí tượng thủy văn",
  cscore_tnn_thong_tin_chi_tiet: "Thông tin chi tiết",
  cscore_tnn_gui: "Gửi",
  cscore_tnn_ghi_chu_de_nghi: "Không tìm thấy thông tin cấp phép trong hệ thống, bạn có thể gửi đề nghị cập nhật bổ sung cấp phép còn thiếu lên hệ thống",
  cscore_tnn_convertExcel: "Nhập từ Excel",
  cscore_tnn_sheetname: "Tên sheet",
  cscore_tnn_dongbatdau: "Dòng bắt đầu",
  cscore_tnn_dongketthuc: "Dòng kết thúc",
  cscore_tnn_dong_cmax: "Dòng Cmax",
  cscore_tnn_cot_mau_kyhieu: "Cột ký hiệu mẫu",
  cscore_tnn_cot_mau_ten: "Cột tên mẫu",
  cscore_tnn_cot_mau_tinhtrang: "Cột tình trạng mẫu",
  cscore_tnn_cot_mau_mota: "Cột mô tả mẫu",
  cscore_tnn_cot_donvithuchien: "Cột đơn vị thực hiện",
  cscore_tnn_cot_nguoilaymaus: "Cột người lấy mẫu",
  cscore_tnn_cot_nguoigiamsats: "Cột người giám sát",
  cscore_tnn_cot_ngaylaymau: "Cột ngày lấy mẫu",
  cscore_tnn_cot_ngaynhanmau: "Cột ngày nhận mẫu",
  cscore_tnn_cot_ngayphantich: "Cột ngày phân tích",
  cscore_tnn_cot_dotQuanTracSo: "Cột đợt quan trắc số",
  cscore_tnn_cot_dotQuanTrac_Ten: "Cột đợt quan trắc tên",
  cscore_tnn_cot_vitriquantrac: "Cột vị trí quan trắc",
  cscore_tnn_cot_vitriquantrac_diadiem: "Cột địa điểm vị trí quan trắc",
  cscore_tnn_cot_vitriquantrac_x: "Cột tọa độ X",
  cscore_tnn_cot_vitriquantrac_y: "Cột tọa độ Y",
  cscore_tnn_cot_luuluong: "Cột lưu lượng",
  cscore_tnn_cot_nguonnuoc: "Cột nguồn nước KTSD",
  cscore_tnn_cot_thongsodautien: "Cột thông số đầu tiên",
  cscore_tnn_cot_kyquantrac: "Cột kỳ quan trắc",
  cscore_tnn_tai_file_theo_thong_so: "Tải xuống file Excel theo tham số đã thiết lập",
  cscore_tnn_download_Excel: "Tải mẫu Excel",
  cscore_tnn_convert_Excel: "Chuyển đổi",
  cscore_tnn_kyhieuBoQua: "Ký hiệu bỏ qua (kpt, -,...)",
  cscore_tnn_kyhieuLonHon: "Ký hiệu lớn hơn",
  cscore_tnn_kyHieuNhoHon: "Ký hiệu nhỏ hơn",
  cscore_tnn_upload_excel: "Tải tệp lên",
  cscore_tnn_json: "Dữ liệu Json",
  cscore_tnn_review: "Xem trước dữ liệu",
  cscore_tnn_convert_du_lieu: "Chuyển đổi dữ liệu",
  cscore_tnn_KF: "Kf",
  cscore_tnn_KQ: "Kq",
  cscore_tnn_title_tcnd: "Tra cứu dữ liệu của doanh nghiệp/tổ chức cá nhân",
  cscore_vuot_chi_so: "Vượt chỉ số",
  cscore_chi_so: "Chỉ số",
  cscore_trong_nguong: "Bình thường",
  cscore_chua_danh_gia: "Chưa đánh giá",
  cscore_luu_tam: "Chưa gửi phê duyệt",
  cscore_cho_phe_duyet: "Chờ phê duyệt",
  cscore_khong_phe_duyet: "Đã bị trả lại",
  cscore_binh_luan: "Nội dung trao đổi",
  cscore_lich_su_trao_doi: "Lịch sử trao đổi",
  cscore_gui: "Gửi",
  cscore_tnn_TCAD_DG: "Chọn quy chuẩn áp dụng để đánh giá",
  cscore_tnn_nameLienHe: "Tên liên hệ",
  cscore_tnn_thong_tin_lien_he: "Thông tin liên hệ",
  cscore_tnn_hinhthucnop: "Hình thức nộp",
  cscore_tnn_thoigiangiaiquyet: "Thời gian giải quyết",
  cscore_tnn_lephi: "Lệ phí",
  cscore_tnn_cachthuc: "Cách thức thực hiện",
  cscore_tnn_maso: "Mã số",
  cscore_tnn_trinhtuthuchien: "Trình tự thực hiện",
  cscore_tnn_yeucauthuchien: "Yêu cầu thực hiện",
  cscore_tnn_loaigiayto: "Loại giấy tờ",
  cscore_tnn_ngaygui: "Ngày gửi",
  cscore_tnn_khoangcach: "Đo khoảng cách",
  cscore_tnn_ttcb: "Thông tin cơ bản",
  cscore_tnn_ttbs: "Thông tin bổ sung",
  cscore_tnn_giamsat_matketnoi: "Mất kết nối",
  cscore_tnn_giamsat_chuagui: "Chưa gửi dữ liệu",
  cscore_tnn_giamsat_kyhieutram: "Ký hiệu trạm",
  cscore_tnn_giamsat_trangthai: "Trạng thái kết nối",
  cscore_tnn_giamsat_tansuat: "Tần suất gửi dữ liệu",
  cscore_tnn_giamsat_td: "Trạm tự động",
  cscore_tnn_giamsat_dk: "Trạm định kỳ",
  cscore_tnn_giamsat_timegui: "Thời gian gửi",
  cscore_tnn_giamsat_timedo: "Thời gian đo",
  cscore_tnn_giamsat_vuotnguong: "Vượt ngưỡng",
  cscore_tnn_giamsat_lichsu: "Lịch sử kết nối",
  cscore_tnn_giamsat_timenhan: "Thời gian nhận",
  cscore_tnn_giamsat_timeKT: "Thời gian kiểm tra",
  cscore_tnn_giamsat_dulieuvuotnguong: "Dữ liệu vượt ngưỡng",
    // chu thich tinh trang ket noi
    cscore_tinhtrangketnoi:"Tình trạng kết nối",
    cscore_matketnoi:"Mất kết nối",
    cscore_chuaketnoi:"Chưa kết nối",

    
//QUản lý kết nối
  cscore_tnn_qlkn:"Quản lý kết nối"
};

export const MESSAGE = {
  cscore_tnn_ERROR: "Có lỗi xảy ra",
  cscore_tnn_THERE_IS_NO_DATA: "Không có dữ liệu",
  cscore_tnn_THERE_IS_SOMETHING_WRONG: "There is something wrong, please try again.",
  cscore_tnn_APP_ADD_SUCCESS: "Tạo mới ứng dung thành công",
  cscore_tnn_APP_ADD_FAILURE: "Tạo mới ứng dung thất bại, có lỗi xẩy ra",
  cscore_tnn_APP_UPDATE_SUCCESS: "Cập nhật ứng dung thành công",
  cscore_tnn_APP_UPDATE_FAILURE: "Cập nhật ứng dung thất bại, có lỗi xẩy ra",
  cscore_tnn_UPDATE_PASSWORD: "Nhập lại mật khẩu không đúng",
  cscore_tnn_ADD_SUCCESS: "Thêm mới thành công",
  cscore_tnn_ADD_SUCCESS_SUCO: "Báo cáo sự cố thành công",
  cscore_tnn_REMOVE_SUCCESS: "Xóa thành công",
  cscore_tnn_REMOVE_FAILURE: "Lỗi khi xóa",
  cscore_tnn_USER_ADD_SUCCESS: "Tạo tài khoản thành công",
  cscore_tnn_USER_UPDATE_SUCCESS: "Cập nhật tài khoản thành công",
  cscore_tnn_UPDATE_SUCCESS: "Cập nhật thành công",
  cscore_tnn_KHoiPhuc_SUCCESS:"Khôi phục dữ liệu của phiên bản cũ thành công",
  cscore_tnn_UPDATE_FAILURE: "Cập nhật thất bại",
  cscore_tnn_ADD_FAILURE: "Thêm không thành công",
  cscore_tnn_UPLOAD_FILE_SUCCESS: 'Upload file thành công',
  cscore_tnn_UPLOAD_FILE_FAILURE: 'Lỗi khi upload file',
  cscore_tnn_GET_DATA_SUCCESS: "Lấy dữ liệu thành công",
  cscore_tnn_GET_DATA_FAILURE: "Lấy dữ liệu thất bại",
  cscore_tnn_GET_KQQT_FAILURE: "Bạn chưa chọn trạm quan trắc hoặc kỳ quan trắc",
  cscore_tnn_GET_LOAI_KQQT_FAILURE: "Bạn chưa chọn loại quan trắc",
  cscore_tnn_GET_XA: "Bạn chưa chọn xã",
  cscore_tnn_THONG_SO_TRONG: "Tên thông số không được trống",
  cscore_tnn_DON_VI_TRONG: "Dơn vị đo không được trống",
  cscore_tnn_DOI_TUONG_TIEU_CHUAN: "Bạn chưa chọn tiêu chuẩn áp dụng",
  cscore_tnn_MAU_QT: "Bạn chưa nhập tên mẫu quan trắc",
  cscore_tnn_SELECT_MAU_QT: "Bạn chưa chọn mẫu quan trắc",
  cscore_tnn_DIEM_QT: "Bạn chưa chọn điểm quan trắc",
  cscore_tnn_DOT_QT: "Bạn chưa chọn đợt quan trắc",
  cscore_tnn_TRAM_QT: "Bạn chưa chọn trạm quan trắc",
  cscore_tnn_KY_QT: "Bạn chưa chọn kỳ quan trắc",
  cscore_tnn_NGAY_QT: "Ngày quan trắc không được trống",
  cscore_tnn_MUC_DICH_SU_DUNG: "Bạn chưa chọn mục đích sử dụng",
  cscore_tnn_NHOM_CONG_TRINH: "Bạn chưa chọn nhóm công trình",
  cscore_tnn_LOI_CONG_TRINH: "Thông tin công trình bị thiếu",
  cscore_tnn_CONG_TRINH: "Bạn chưa chọn công trình",
  cscore_tnn_CONG_TRINH_CAP_PHEP: "Bạn không thể xóa công trình đã được cấp phép",
  cscore_tnn_toa_do_dem: "Cập nhật thành công nhưng chưa lưu được tọa độ",
  cscore_tnn_xa_toa_do_diem: "Xóa thành công nhưng xóa tọa độ thất bại",
  cscore_tnn_DOI_TUONG_QT: "Bạn chưa chọn đối tượng quan trắc",
  cscore_tnn_CHON_CAP: "Bạn chưa chọn cấp",
  cscore_tnn_CHON_TOA_DO: "Bạn phải nhập 3 tọa độ hoặc chỉ nhập 1 tọa độ",
  cscore_tnn_CHON_NAM_BC: "Bạn chưa chọn năm báo cáo",
  cscore_tnn_NAM_BD: "Bạn chưa chọn năm bắt đầu",
  cscore_tnn_NAM_KT: "Bạn chưa chọn năm kết thúc",
  cscore_tnn_THIEU_TAI_LIEU: "Công trình chưa có giấy phép",
  cscore_tnn_KHONG_CO_GIAY_PHEP: "Thiếu file giấy phép đính kèm",
  cscore_tnn_KHONG_CO_QUYEN_TRUY_CAP: " Bạn không có quyền truy cập",
  cscore_tnn_KHONG_CO_FILE: "Không có file đính kèm",
  cscore_tnn_PHE_DUYET_THANH_CONG: "Phê duyệt thành công",
  cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO: "Không có dữ liệu bản đồ",
  cscore_tnn_Khong_Co_Du_lieu: "Không có dữ liệu công trình",
  cscore_tnn_CHECKGP: "Giấy phép này đã tồn tại trong hệ thống, không thể tiếp tục thêm mới",
  CSCORE_THIET_LAP_MAU: "Thiết lập mẫu thành công",
  CSCORE_WARNING_THIET_LAP_MAU: "Bạn chưa chọn đơn vị trữ lượng",
  CSCORE_WARNING_THANH_PHAN_MT: "Bạn chưa chọn thành phần môi trường",
  CSCORE_KHONG_CO_QUYEN_TRUY_CAP: "Không có quyền truy cập",
  CSCORE_CHUA_CHON_FILE: "Bạn chưa chọn file Excel để chuyển đổi",
  CSCORE_CHUYEN_DOI_THANH_CONG: "Chuyển đổi Excel thành công",
  CSCORE_WARNING_QUAN_TRAC: "Bạn cần chọn vị trí quan trắc",
  CSCORE_ERROR_THONG_TIN: "Bạn cần nhập đầy đủ thông tin bị đánh dấu đỏ",
  CSCORE_GUI_PHE_DUYET: "Gửi phê duyệt thành công",
  CSCORE_KHONG_PHE_DUYET: "Đã từ chối phê duyệt",
  CSCORE_GIOI_HAN_THONG_SO: "Chỉ chọn tối đa 2 thông số",
  CSCORE_CHECK_PH: "Giá trị C có dạng a-b (a,b là số và a < b)",
  CSCORE_CHECK_GIATRI: "Giá trị C phải là số",
  CSCORE_DU_LIEU_DOANH_NHGIEP_TRONG: "Dữ liệu doanh nghiệp đang trống",
  CSCORE_PHE_DUYET_THANH_CONG: "Phê duyệt thành công",
  CSCORE_CAMERA_KHONG_KHA_DUNG: "Camera không khả dụng",
  CSCORE_LUU: "Lưu thành công",
};

export const VALIDATE_MESSAGE = {
  cscore_tnn_required: "Thông tin này bắt buộc nhập",
  cscore_tnn_notEnoughPoint: '2 điểm tọa độ không đủ xác định vùng của công trình',
};

export const API_ENDPOINT_DEV = "https://gengateway.azure-api.net";

export const TypeFiles = ["", "text/plain", "application/pdf", "application/vnd.ms-word", "application/vnd.msword", "application/msword", "application/doc", "application/vnd.ms-excel", 
'application/vnd.openxmlformats-officedocument.wordprocessingml.document', "application/word", "appl/text", "application/winword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/png", "image/jpeg", "image/jpeg", "image/gif", "text/csv",
  'application/x-rar-compressed', 'application/x-rar', 'application/octet-stream', 'application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip']
