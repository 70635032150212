import React from "react";
import moment from "moment";
import { Tag } from "antd";
import Constant from "../../../constants/constant";
// typo

import typo from "../../../constants/dictionaries";
import helper from '../../../helper/helper'

const Status = ({ value, trangthai, thoiGian } = {}) => {
  switch (trangthai) {
    case Constant.TRANG_THAI.hethieuluc:
      return <Tag color="red">{typo.cscore_tnn_expired}</Tag>
    case Constant.TRANG_THAI.conhieuluc:
      const validateDate = (date) => {
        const now = moment();
        const convertDate = moment(date);
        const before90days = helper.subtractDate(convertDate, thoiGian ? thoiGian : 120);
        if (helper.compareDate(now, convertDate)) {
          return 'red'
        } else {
          if (helper.compareDate(now, before90days)) {
            return 'gold'
          } else {
            return 'green'
          }
        }
      };
      return <Tag color={validateDate(value)}>{validateDate(value) === 'red' ? typo.cscore_tnn_expired : validateDate(value) === 'gold' ? typo.cscore_tnn_expiringSoon : typo.cscore_tnn_validated}</Tag>;
    case Constant.TRANG_THAI.chopheduyet:
      return <Tag color="blue">{'Chờ phê duyệt'}</Tag>
    case Constant.TRANG_THAI.saphethieuluc:
      return <Tag color="gold">{typo.cscore_tnn_expiringSoon}</Tag>
    case Constant.TRANG_THAI.hethieulucchuathaythe:
      return <Tag color="red">{typo.cscore_tnn_expired}</Tag>
    case Constant.TRANG_THAI.chuabatdauhieuluc:
      return <Tag color="gray">{typo.cscore_tnn_chuabatdau}</Tag>
      case Constant.TRANG_THAI.trinhpheduyet:
        return <Tag color="gray">{"Chưa trình duyệt"}</Tag>
        case Constant.TRANG_THAI.tuchoiduyet:
          return <Tag color="purple">{'Trả lại'}</Tag>
    default:
      return <div></div>
  }
};

export default Status;
