// saga
import { call, put, takeLatest, select, take } from 'redux-saga/effects';
// service
import * as DanhMucService from '../apis/danhmuc-service';

import * as ActionTypes from "../../reducers/actions/action-type"
import Helper from '../../helper/helper';
import Constant from '../../constants/constant';

// DVHC
function* getDsDvhcTinh(params) {
    try {
        const response = yield call(DanhMucService.getTinhs, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_DVHC_DS_TINH_SUCCEED,
                data: response && response.length > 0 ? response : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_DVHC_DS_TINH_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_DVHC_DS_TINH_FAILED,
            message: error.message
        });
    }
}
function* getDsDvhcHuyenByTinh(params) {
    try {
        const response = yield call(DanhMucService.getHuyenByTinhs, params.user, params.maTinh);
        if (response) {
            yield put({
                type: ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_SUCCEED,
                data: response && response.length > 0 ? response : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_FAILED,
            message: error.message
        });
    }
}
function* getDsDvhcXaByHuyen(params) {
    try {
        const response = yield call(DanhMucService.getApiXaByHuyens, params.user, params.maHuyen);
        if (response) {
            yield put({
                type: ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_SUCCEED,
                data: response && response.length > 0 ? response : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_FAILED,
            message: error.message
        });
    }
}

//Profile
function* getProfile(params) {
    try {
        const response = yield call(DanhMucService.getProfile, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_PROFILE_SUCCEED,
                data: response ? response : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_PROFILE_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        console.log("aaaaaaaaaaaaaaaaaaaa", error)
        debugger
        yield put({
            type: ActionTypes.GET_PROFILE_FAILED,
            message: error.message
        });
    }
}
//Get token
function* getTokenGiamSat(params) {
    try {
        const response = yield call(DanhMucService.getTokenGiamSat, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_TOKEN_GIAMSAT_SUCCEED,
                data: response ? JSON.parse(response) : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_TOKEN_GIAMSAT_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_TOKEN_GIAMSAT_FAILED,
            message: error.message
        });
    }
}
function* getTokenLGSP(params) {
    try {
        const response = yield call(DanhMucService.getTokenLGSP, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_TOKEN_LGSP_SUCCEED,
                data: response ? JSON.parse(response).access_token : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_TOKEN_LGSP_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_TOKEN_LGSP_FAILED,
            message: error.message
        });
    }
}
// Cấu hình hết hạn giấy phép
function* getCauHinhGiayPhep(params) {
    try {
        const response = yield call(DanhMucService.getCauHinhGiayPhep, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_CAUHINH_GP_SUCCEED,
                data: response && response.data ? response.data : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_CAUHINH_GP_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_CAUHINH_GP_FAILED,
            message: error.message
        });
    }
}
// Loại công trình
function* getCTLoaiCongTrinh(params) {
    try {
        const response = yield call(DanhMucService.getCTLoaiCongTrinh, params.user, params.nhomct);
        if (response) {
            yield put({
                type: ActionTypes.GET_LOAI_CT_SUCCEED,
                data: response && response.data ? response.data : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_LOAI_CT_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_LOAI_CT_FAILED,
            message: error.message
        });
    }
}
//Kinh tuyến trục
function* getKTT_TW(params) {
    try {
        const response = yield call(DanhMucService.Getdmkinhtuyentrucs, params.user, Constant.CAP_TK.TRUNGUONG);
        if (response) {
            yield put({
                type: ActionTypes.GET_KTT_TW_SUCCEED,
                data: response && response.data ? response.data : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_KTT_TW_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_KTT_TW_FAILED,
            message: error.message
        });
    }
}
function* getKTT_DP(params) {
    try {
        const response = yield call(DanhMucService.Getdmkinhtuyentrucs, params.user, Constant.CAP_TK.DIAPHUONG);
        if (response) {
            yield put({
                type: ActionTypes.GET_KTT_DP_SUCCEED,
                data: response && response.data ? response.data : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_KTT_DP_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_KTT_DP_FAILED,
            message: error.message
        });
    }
}
//Lưu vự sông
function* getLUUVUC_SONG(params) {
    try {
        const response = yield call(DanhMucService.getLuuVucSong, params.user);
        if (response) {
            yield put({
                type: ActionTypes.GET_LUUVUC_SONG_SUCCEED,
                data: response ? response : null,
                message: ''
            });
        } else {
            yield put({
                type: ActionTypes.GET_LUUVUC_SONG_FAILED,
                message: 'failed'
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_LUUVUC_SONG_FAILED,
            message: error.message
        });
    }
}
function* danhMucSagas() {
    yield takeLatest(ActionTypes.GET_DVHC_DS_TINH_REQUEST, getDsDvhcTinh);
    yield takeLatest(ActionTypes.GET_DVHC_DS_HUYEN_BY_TINH_REQUEST, getDsDvhcHuyenByTinh);
    yield takeLatest(ActionTypes.GET_DVHC_DS_XA_BY_HUYEN_REQUEST, getDsDvhcXaByHuyen);
    yield takeLatest(ActionTypes.GET_PROFILE_REQUEST, getProfile);
    yield takeLatest(ActionTypes.GET_TOKEN_GIAMSAT_REQUEST, getTokenGiamSat);
    yield takeLatest(ActionTypes.GET_TOKEN_LGSP_REQUEST, getTokenLGSP);
    yield takeLatest(ActionTypes.GET_CAUHINH_GP_REQUEST, getCauHinhGiayPhep);
    yield takeLatest(ActionTypes.GET_LOAI_CT_REQUEST, getCTLoaiCongTrinh);
    yield takeLatest(ActionTypes.GET_KTT_TW_REQUEST, getKTT_TW);
    yield takeLatest(ActionTypes.GET_KTT_DP_REQUEST, getKTT_DP);
    yield takeLatest(ActionTypes.GET_LUUVUC_SONG_REQUEST, getLUUVUC_SONG);
}

// export
export default danhMucSagas;