import React, { Component } from "react";
import { connect } from 'react-redux';
import Api from "../../../api";
import Message from "../../../components/Messages";
import Constant from "../../../constants/constant";
import { MESSAGE } from "../../../constants/dictionaries";
import Helper from "../../../helper/helper";
import Map_TraCuuTNN from "./index"
class TraCuuTNN extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      cap: undefined,
      maDVHCbyUser: undefined,
      maTinh: undefined
    }
  }
  componentDidMount() {
    this.Profile()
  }
  Profile = () => {
    const { user, profile } = this.props;
    let resSort = Helper.sortArrayNumber(profile.roles, "cap")
    this.setState({
      cap: resSort[0].cap,
      maDVHCbyUser: resSort[0].cap === Constant.Role_user.LD_CV_BO || resSort[0].cap === Constant.Role_user.LD_CV_SO ? profile.maDVHC : "1000",
      maTinh: profile.maTinh
    })
  };
  render() {
    const { user, dvhcTinhs } = this.props
    const { cap, maDVHCbyUser, maTinh } = this.state
    return (
      <div>
        <Map_TraCuuTNN user={user} cap={cap} maDVHCbyUser={maDVHCbyUser} maTinh={maTinh} dvhcTinhs={dvhcTinhs}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions,
  profile: state.commonReducer.profile.data,
  dvhcTinhs: state.commonReducer.dvhcTinhs.data,
});

export default connect(mapStateToProps)(TraCuuTNN);
