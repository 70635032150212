import React from "react";
// Components
import RowTools from "../../../components/Table/RowTools";
import DateView from "../../../components/Common/View/DateView";
// helper

const columns = ({ showFileModal } = {}) =>
    [
        {
            // key: "Id",
            title: "#",
            dataIndex: "stt",
            width: "65px"
        },
        {
            // key: "",
            title: "Tên công trình",
            dataIndex: "tencongtrinh",
            render: value => <span className="row-name">{value}</span>
        },
        {
            // key: "",
            title: "Địa chỉ",
            dataIndex: "diadiem",
        },
        {
            // key: "",
            title: "Số giấy phép",
            dataIndex: "sogiayphep",
            width: "10%",
        },
        {
            title: "Ngày ký",
            dataIndex: "ngayky",
            width: "10%",
        },
        {
            // key: "",
            title: "Đơn vị xin cấp phép",
            dataIndex: "donvixcp",
        },
        {
            // key: "",
            title: "Địa chỉ đơn vị xin cấp phép",
            dataIndex: "diachidonvixcp",
        },
        {
            key: "tools",
            title: "",
            dataIndex: "tools",
            align: "center",
            width: "40px",
            render: (value, row) => (
                <RowTools row={row} iconDelete={true} iconEdit={true} iconFile={true} showFile={showFileModal} />
            )
        }
    ].map(col => ({
        ...col,
    }));

export default columns;
