import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

//style
import './index.css'
import typo from "../../constants/dictionaries";

const {Title} = Typography;

// Components
const CongTrinhList = lazy(() => import('./CongTrinh/List'));
const CongTrinhDetail = lazy(() => import('./CongTrinh/Detail'));
const CongTrinhForm = lazy(() => import('./CongTrinh/Form'));

export default ({match}) => {
  const groupName = 'Công trình xả thải'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}`} render={(props) => <CongTrinhList {...props} path={`${match.path}`} groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/add`} render={(props) => <CongTrinhForm {...props} type="add" groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/:id/edit`} render={(props) => <CongTrinhForm {...props} groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/:id/detail`} render={(props) => <CongTrinhDetail {...props} groupName={groupName} name={""}/>}/>
      </Switch>
    </Suspense>
  )
}