import React, {lazy, Suspense} from 'react'
import {Switch, Route} from 'react-router-dom'
import {Typography} from 'antd'

//style
import './index.css'

const {Title} = Typography;

// Components
const KTNBList = lazy(() => import('./KTNB/List'));
const KTNBDetail = lazy(() => import('./KTNB/Detail'));
const KTNBForm = lazy(() => import('./KTNB/Form'));

export default ({match}) => {
  const groupName = 'Công trình nước biển'
  return (
    <Suspense fallback={() => <Title level={4}>Loading</Title>}>
      <Switch>
        <Route exact path={`${match.path}`} render={(props) => <KTNBList {...props} path={`${match.path}`} groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/add`} render={(props) => <KTNBForm {...props} type="add" groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/:id/edit`} render={(props) => <KTNBForm {...props} groupName={groupName} name={""}/>}/>
        <Route path={`${match.path}/:id/detail`} render={(props) => <KTNBDetail {...props} groupName={groupName} name={""}/>}/>
      </Switch>
    </Suspense>
  )
}