import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icon, Row, Popconfirm, Tooltip } from "antd";

export default function RowTools({
  row,
  showAddModal,
  iconTuChoi,
  tuChoiDuyet,
  titleCapNhat,
  handleDongbo,
  handleTatAuto,
  handleBatAuto,
  handleBatDongBoThuCong,
  iconCapnhatthucong,
  iconCapnhat,
  showEditModal,
  deleteRowModal,
  downloadFile,
  showMapModal,
  showDanhGiaModal,
  showCameraModal,
  showFile,
  iconPheDuyet,
  showPheDuyet,
  guiPheDuyet,
  pheDuyet,
  guiDeNghi,
  xacNhan,
  showPhanHoiModal,
  showDetail,
  iconEdit,
  iconMap,
  iconDanhGia,
  iconDelete,
  iconFile,
  iconFileCT,
  iconGuiPheDuyet,
  iconPheDuyets,
  iconXacNhan,
  iconGuiDeNghi,
  iconDetail,
  iconXemPhanHoi,
  iconReRoll,
  iconCamera,
  ReRoll,
  iconDownload,
  iconAdd,
  iconDongbo,
  popconfirmInfor = {
    placement: "top",
    title: "Xóa bản ghi này ?",
    okText: "Xóa",
    cancelText: "Hủy",
    onConfirm: () => deleteRowModal(row.id),
  },
  popconfirmInforDongbo = {
    placement: "top",
    title: "Đồng bộ bản ghi này ?",
    okText: "Đồng bộ",
    cancelText: "Hủy",
    onConfirm: () => handleDongbo(row),
  },
  popconfirmInforTatAuto = {
    placement: "top",
    title: "Tắt Auto ?",
    okText: "Tắt",
    cancelText: "Hủy",
    onConfirm: () => handleTatAuto(row),
  },
  popconfirmInforBatAuto = {
    placement: "top",
    title: "Bật Auto ?",
    okText: "Bật",
    cancelText: "Hủy",
    onConfirm: () => handleBatAuto(row),
  },
}) {
  return (
    <Container
      type="flex"
      justify="center"
      align="middle"
      style={{ flexFlow: "row" }}
    >
      <Row type="flex" justify="space-between" align="middle">
        {iconGuiPheDuyet ? (
          <Tooltip placement="bottom" title="Trình phê duyệt">
            <Popconfirm
              placement="top"
              title="Bạn muốn trình phê duyệt giấy phép này không?"
              onConfirm={() => guiPheDuyet(row)}
              onCancel={() => undefined}
              okText=" Trình phê duyệt"
              cancelText="Hủy"
            >
              <CustomIcon color="#63c2de" type="audit" />
            </Popconfirm>
          </Tooltip>
        ) : undefined}
        {iconTuChoi ? (
          <Tooltip placement="bottom" title="Từ chối phê duyệt">
            <Popconfirm
              placement="top"
              title="Bạn muốn từ chối phê duyệt giấy phép này không?"
              onConfirm={() => tuChoiDuyet(row)}
              onCancel={() => undefined}
              okText="Từ chối phê duyệt"
              cancelText="Hủy"
            >
              <CustomIcon color="red" type="close-square" />
            </Popconfirm>
          </Tooltip>
        ) : undefined}
        {iconPheDuyets ? (
          <Tooltip placement="bottom" title="Phê duyệt">
            <CustomIcon
              color="#63c2de"
              type="audit"
              onClick={() => {
                pheDuyet(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconXacNhan ? (
          <Tooltip
            placement="bottom"
            title={titleCapNhat ? titleCapNhat : "Phê duyệt"}
          >
            <CustomIcon
              color="#63c2de"
              type="check"
              onClick={() => {
                xacNhan(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconGuiDeNghi ? (
          <Tooltip placement="bottom" title="Trình phê duyệt">
            <CustomIcon
              color="#63c2de"
              type="caret-right"
              onClick={() => {
                guiDeNghi(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconXemPhanHoi ? (
          <Tooltip placement="bottom" title="Xem phản hồi">
            <CustomIcon
              color="#63c2de"
              type="message"
              onClick={() => {
                showPhanHoiModal(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconDetail ? (
          <Tooltip placement="bottom" title="Xem chi tiết">
            <CustomIcon
              color="#63c2de"
              type="info-circle"
              onClick={() => {
                showDetail(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconMap ? (
          <Tooltip placement="bottom" title="Bản đồ">
            <CustomIcon
              color="#63c2de"
              type="global"
              onClick={() => {
                showMapModal(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconCamera ? (
          <Tooltip placement="bottom" title="Xem camera">
            <CustomIcon
              color="#63c2de"
              type="camera"
              onClick={() => {
                showCameraModal(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconDanhGia ? (
          <Tooltip placement="bottom" title="Đánh giá mẫu quan trắc">
            <CustomIcon
              color="#63c2de"
              type="check-square"
              onClick={() => {
                showDanhGiaModal(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconFile ? (
          <Tooltip placement="bottom" title="Tài liệu đính kèm">
            <CustomIcon
              color="#63c2de"
              type="file-pdf"
              onClick={() => {
                showFile(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconDownload ? (
          <Tooltip placement="top" title="Tải xuống">
            <CustomIcon
              color="#63c2de"
              type="download"
              onClick={() => {
                downloadFile(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconFileCT ? (
          <Tooltip placement="bottom" title="Xem file giấy phép">
            <CustomIcon
              color="#63c2de"
              type="file-pdf"
              onClick={() => {
                showFile(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        ) : undefined}
        {iconPheDuyet ? (
          <Tooltip placement="bottom" title="Phê duyệt">
            <Popconfirm
              placement="top"
              title="Bạn muốn phê duyệt giấy phép này không"
              onConfirm={() => showPheDuyet(row)}
              onCancel={() => undefined}
              okText="Phê duyệt"
              cancelText="Hủy"
            >
              <CustomIcon color="red" type="audit" />
            </Popconfirm>
          </Tooltip>
        ) : undefined}

        {iconDongbo ? (
          <Tooltip placement="bottom" title="Đồng bộ">
            <Popconfirm
              placement={popconfirmInforDongbo.placement}
              title={popconfirmInforDongbo.title}
              onConfirm={() => popconfirmInforDongbo.onConfirm()}
              onCancel={() =>
                popconfirmInforDongbo.onCancel
                  ? popconfirmInforDongbo.onCancel()
                  : undefined
              }
              okText={popconfirmInforDongbo.okText}
              cancelText={popconfirmInforDongbo.cancelText}
            >
              <CustomIcon color="#52c41a" type="cloud" />
            </Popconfirm>
            {/* <CustomIcon
               color="#52c41a"
              type="cloud"
              onClick={() => {
                handleDongbo(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            /> */}
          </Tooltip>
        ) : undefined}

        {iconCapnhat ? (
          <Tooltip placement="bottom" title="Tắt Auto">
            <Popconfirm
              placement={popconfirmInforTatAuto.placement}
              title={popconfirmInforTatAuto.title}
              onConfirm={() => popconfirmInforTatAuto.onConfirm()}
              onCancel={() =>
                popconfirmInforTatAuto.onCancel
                  ? popconfirmInforTatAuto.onCancel()
                  : undefined
              }
              okText={popconfirmInforTatAuto.okText}
              cancelText={popconfirmInforTatAuto.cancelText}
            >
              <CustomIcon color="#63c2de" type="setting" />
            </Popconfirm>
          </Tooltip>
        ) : undefined}

        {iconCapnhatthucong ? (
          <>
            <Tooltip placement="bottom" title="Đồng bộ thủ công">
              <CustomIcon
                color="#63c2de"
                type="form"
                onClick={() => {
                  handleBatDongBoThuCong(row);
                }}
                style={{ paddingRight: "2px" }}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Bật Auto">
              <Popconfirm
                placement={popconfirmInforBatAuto.placement}
                title={popconfirmInforBatAuto.title}
                onConfirm={() => popconfirmInforBatAuto.onConfirm()}
                onCancel={() =>
                  popconfirmInforBatAuto.onCancel
                    ? popconfirmInforBatAuto.onCancel()
                    : undefined
                }
                okText={popconfirmInforBatAuto.okText}
                cancelText={popconfirmInforBatAuto.cancelText}
              >
                <CustomIcon color="orange" type="setting" />
              </Popconfirm>
            </Tooltip>
          </>
        ) : undefined}

        {iconEdit ? undefined : (
          <Tooltip placement="bottom" title="Sửa">
            <CustomIcon
              color="#63c2de"
              type="edit"
              onClick={() => {
                showEditModal(row);
              }}
              style={{ paddingRight: "2px", paddingLeft: "2px" }}
            />
          </Tooltip>
        )}

        {iconDelete ? undefined : (
          <>
            {popconfirmInfor ? (
              <Tooltip placement="bottom" title="Xóa">
                <Popconfirm
                  placement={popconfirmInfor.placement}
                  title={popconfirmInfor.title}
                  onConfirm={() => popconfirmInfor.onConfirm()}
                  onCancel={() =>
                    popconfirmInfor.onCancel
                      ? popconfirmInfor.onCancel()
                      : undefined
                  }
                  okText={popconfirmInfor.okText}
                  cancelText={popconfirmInfor.cancelText}
                >
                  <CustomIcon color="red" type="delete" />
                </Popconfirm>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title="Xóa">
                <CustomIcon
                  color="red"
                  type="delete"
                  onClick={() => {
                    deleteRowModal(row);
                  }}
                />
              </Tooltip>
            )}
          </>
        )}
        {iconReRoll ? (
          <Tooltip placement="top" title="Sử dụng dữ liệu của phiên bản này">
            <Popconfirm
              placement="top"
              title="Bạn có muốn sử dụng dữ liệu của phiên bản cũ"
              onConfirm={() => ReRoll(row)}
              onCancel={() => undefined}
              okText="Có"
              cancelText="Không"
            >
              <CustomIcon color="#63c2de" type="undo" />
            </Popconfirm>
          </Tooltip>
        ) : undefined}
      </Row>
    </Container>
  );
}

RowTools.propTypes = {
  row: PropTypes.object.isRequired,
  showEditModal: PropTypes.func.isRequired,
  deleteRowModal: PropTypes.func.isRequired,
};

const Container = styled(Row)`
  // max-width: 50px;
`;

const CustomIcon = styled(Icon)`
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => props.color} !important;
`;
