import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";

class GeoJson_Layer extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: {
        data: null,
        style: null,
        key: null
      }
    }
   
  }



  componentDidMount() {
    const { idsong } = this.props
    idsong && this.GetSpatialSong(idsong)

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { idsong } = this.props
    if (prevProps.idsong !== idsong) {
      this.GetSpatialSong(idsong)
    }
  }

  GetSpatialSong = (idsong) => {
    const { zoomOut } = this.props
    new Api_spatial()
      .GetSpatialSong({
        token: this.props.user.access_token,
        idsong: idsong,
      })
      .then((res) => {
        if (res != null && res.result != null) {
          const a = JSON.parse(res.result)
          const newGeom = { newGeom: (delete a.geometry['crs'], a) };
          const Json_newGeom = { ...newGeom.newGeom }
          const b = JSON.stringify(Json_newGeom)
          this.setState({
            GeoJson_data: {
              data: Json_newGeom,
              key: b
            }
          })
        }else {
          message.warning(MESSAGE.cscore_tnn_KHONG_CO_DU_LIEU_BAN_DO);
        }
      })
      .catch((err) => {
        //message.error(err.message);
      });
  };
  
  style(feature) {
    return {
      // the fillColor is adapted from a property which can be changed by the user (segment)
      // fillColor: this.getColor(feature.properties.scores[this.state.segment]),
      weight: 6,
      //stroke-width: to have a constant width on the screen need to adapt with scale 
      opacity: 1,
      color: "#38f",
      // dashArray: '3',
      // fillOpacity: 0.5
    };
  };

  render() {

    return (
      <div>
        {this.state.GeoJson_data.data != null &&
          <GeoJSON ref="geojson" key={this.state.GeoJson_data.key} data={this.state.GeoJson_data.data} style={this.style}/>
        }
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

