/*
 *  @2019 CS-Core project.
 *
 */

import React, {Component} from "react";
import {connect} from "react-redux";

import styled from "styled-components";
import {Link} from "react-router-dom";
import {Menu, Dropdown, Icon, Typography} from "antd";
import Button from "../../components/Buttons";
import typo from "../../constants/dictionaries";

// Services
import userManager from "../../services/auth";

// Styles
const {Text} = Typography;

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: true
    };
  }

  handleLogin = () => {
    userManager.signinRedirect();
  };

  handleLogout = () => {
    userManager.signoutRedirect();
  };

  render() {
    const {user} = this.props;
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/my-profile">{typo.cscore_tnn_profile_user}</Link>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="0">
          <Link to="/change-password">{typo.cscore_tnn_profile_change_password}</Link>
        </Menu.Item>
        <Menu.Divider/>
        {user ? (
          <Menu.Item key="3"><div className='btn-log' onClick={this.handleLogout}>{typo.cscore_tnn_logout}</div></Menu.Item>
        ) : (
          <Menu.Item key="3"><div className='btn-log' onClick={this.handleLogin}>{typo.cscore_tnn_login}</div></Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Wrapper>
          <Icon type="user" style={{color:'white'}}/>
          <div className="mr-10"/>
          <UserTitle>{user && user.profile.name}</UserTitle>
        </Wrapper>
      </Dropdown>
    );
  }
}

const Wrapper = styled.div`
  line-height: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const UserTitle = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  color: #fff !important;
`;

const mapStateToProps = state => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(ProfileMenu);
