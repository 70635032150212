import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import * as Yup from 'yup'
import {Row, Col, Modal, Icon} from 'antd'
import {FastField, Formik} from 'formik'
import typo from '../../../constants/dictionaries'

// Components
import Input from '../../Common/Input'
import Button from '../../Buttons'
import ValidatedText from "../../Common/ValidatedText";
import DisplayFormikState from "../../Table/DisplayFormikState";
import Api from '../../../api';
import Message from "../../Messages";

const schema = Yup.object().shape({
  ten: Yup.string().required("Tên tổ chức/cá nhân không được trống"),
  diaChi: Yup.string().required("Địa chỉ tổ chức/cá nhân không được trống"),
});

const DonViDCPModal = ({
  isOpen = false,
  handleOk,
  handleCancel,
  user
  }) => {
  return (
    <Modal
      visible={isOpen}
      title={"Thêm mới đơn vị được cấp phép"}
      footer={false}
      onCancel={handleCancel}
    >
      <Formik
        key={new Date()}
        enableReinitialize
        initialValues={{}}
        onSubmit={(value, actions) => {
          actions.resetForm({});
          new Api().Post_DonViXinCapPhep({token: user.access_token, donvi: value})
            .then(res => {
              handleOk(res);
            })
            .catch((err) => Message.error(err.message))
        }}
        validationSchema={schema}
        render={({handleChange, handleSubmit, values, errors, touched}) => (
          <form onSubmit={handleSubmit} autoComplete={false}>
            <Row>
              <FastField
                name="ten"
                render={({field}) => <Input label={typo.cscore_tnn_tendonvixcp} id="ten" {...field}/>}
              />
              {touched.ten && <ValidatedText type="danger">{errors.ten}</ValidatedText>}

              <FastField
                name="diaChi"
                render={({field}) => <Input label={typo.cscore_tnn_diaChiDonViXCP} id="diaChi" {...field}/>}
              />
              {touched.diaChi && <ValidatedText type="danger">{errors.diaChi}</ValidatedText>}
            </Row>
            <div className="mt-20"/>
            <Row type="flex" justify="end" align="middle">
              <Button type="submit" onClick={handleSubmit}><Icon type="save"/>{typo.cscore_tnn_save}</Button>
              <div className="ml-10"/>
              <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
            </Row>
            {/*<DisplayFormikState error={errors} value={values}/>*/}
          </form>
        )}/>
    </Modal>
  )
};

DonViDCPModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  user: store.oidc.user
});

export default connect(mapStateToProps)(DonViDCPModal);

