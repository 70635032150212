import React, { Component } from "react";
import { connect } from 'react-redux'
import L from 'leaflet'
import { GeoJSON, Popup, Tooltip } from 'react-leaflet'
import Api_spatial from "../../../api/api.spatial";
import icon_HM from '../../../assets/images/HM-24.png';
import message from "../../Messages";
import typo, { MESSAGE } from "../../../constants/dictionaries";
import icon_NM from '../../../assets/images/NM-24.png';
import constants from "../../../constants/constant"
const NM_Icon = new L.Icon({
  iconUrl: icon_NM,
  className: 'custom-icon',
  popupAnchor: [1, -34]
})
const HM_Icon = new L.Icon({
  iconUrl: icon_HM,
  className: 'custom-icon',
  popupAnchor: [1, -34]
})
class GeoJson_Layer extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      GeoJson_data: [],
      GeoJson_CT: {}
    }

  }



  componentDidMount() {
    const { GeoJson_data, GeoJson_CT } = this.props
    this.setState({
      GeoJson_data,
      GeoJson_CT
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
  }

  render() {
    const { GeoJson_data, GeoJson_CT } = this.state
    const { viewToado } = this.props
    return (
      <div>
        {GeoJson_data && GeoJson_data?.length > 0 && GeoJson_data?.map((item, index) => (
          item?.data != null &&
          <GeoJSON ref="geojson" key={1} data={item.data} pointToLayer={(geoJsonPoint, latlng) => { return L.marker(latlng, { icon: HM_Icon }) }}>
            <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
              <b>{"Hạng mục: "}</b>{item?.data?.properties?.tenhangmuc || ""}
              {/* <b>{"Hạng mục: "}</b>{viewToado.hangMucs && viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc).length > 0 ? viewToado.hangMucs.filter(x => x.id === item.data.properties.idhangmuc)[0].tenHangMuc : ""} */}
              <Popup><b>{"Công trình: "}</b>{GeoJson_CT?.data?.properties?.tencongtrinh}
                <br />
                <b>{"Tên hạng mục: "}</b>{viewToado?.hangMucs?.filter(x => x.id === item?.data?.properties?.idhangmuc).length > 0 ? viewToado?.hangMucs?.filter(x => x.id === item?.data?.properties?.idhangmuc)[0]?.tenHangMuc : ""}
                <br />
                <b>{"Tọa độ (x, y): "}</b>{viewToado?.hangMucs?.filter(x => x.id === item?.data?.properties?.idhangmuc).length > 0 ? viewToado?.hangMucs.filter(x => x.id === item?.data?.properties?.idhangmuc)[0]?.toaDo : ""}
              </Popup>
            </Tooltip>
          </GeoJSON>
        ))}
        {GeoJson_CT?.data != null &&
          <div>
            <div className="leaflet-mini-map-congtrinh">{"Công trình: "} {GeoJson_CT?.data?.properties?.tencongtrinh}</div>
            <GeoJSON ref="geojson" key={GeoJson_CT?.key} data={GeoJson_CT?.data} pointToLayer={(geoJsonPoint, latlng) => { return L.marker(latlng, { icon: NM_Icon }) }}>
              <Tooltip direction="bottom" offset={[0, -10]} opacity={1} permanent>
                <b>{"Công trình: "}</b>{GeoJson_CT?.data?.properties?.tencongtrinh}
                <Popup><b>{"Công trình: "}</b>{GeoJson_CT?.data?.properties?.tencongtrinh}
                  <br />
                  <b>{"Địa điểm: "}</b> {viewToado?.diaDiem}
                  <br />
                  <b>{"Tọa độ (x, y): "}</b> {viewToado?.toaDo}
                </Popup>
              </Tooltip>
            </GeoJSON>
          </div>

        }
      </div>

    );
  }
}
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(GeoJson_Layer);

