import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import typo from '../../constants/dictionaries';
import Api from '../../api/index'
import defaultappicon from "../../assets/images/defaultappicon.png";

class SmallMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_app: []
        }
    }
    componentDidMount() {
        this.Get_Apps()
    }
    Get_Apps = () => {
        new Api()
            .Get_Apps({
                token: this.props.user.access_token,
                page: 0,
                size: 0,
                strSearch: ""
            }).then(res => {
                this.setState({
                    data_app: res && res.data
                })
            }).catch(err => {
                console.log(err);
            });
    };
    render() {
        const { data_app } = this.state
        const menu = (
            <Menu>
                <div className='box-menu'>
                    {data_app.map((item, idx) =>
                        <a className='box-menu-item-full' href={item.postLogoutRedirectUris}>
                            <img className='menu-item-img' src={defaultappicon} />
                            <div className='box-menu-item-title'>{item.name}</div>
                        </a>
                    )}
                </div>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} trigger={["click"]}>
                <div className='box-small-menu tooltip'>
                    <svg class="box-small-menu-svg" focusable="false" viewBox="0 0 24 24"><path d={smallMenuIcon}></path></svg>
                    <span class="tooltiptext tooltip-small-menu">{typo.cscore_tnn_app}</span>
                </div>
            </Dropdown>
        )
    }

}
const smallMenuIcon = "M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"
const mapStoreToProps = data => ({ user: data.oidc.user })
export default connect(mapStoreToProps)(SmallMenu)