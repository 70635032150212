import React, { Component } from 'react'
import styled from "styled-components";
import { Col, Row, Icon, Tabs, Descriptions } from "antd";
import Table from "../../components/Table";
import columns from './NguonNuoc/CongTrinh.column'
import helper from '../../helper/helper';
import { connect } from 'react-redux'
import message from "../../components/Messages"
import typo, { MESSAGE } from "../../constants/dictionaries"
import Constant from '../../constants/constant';
import Api_tnn from '../../api/api.tnn';
import Api_dms from '../../api/api.dms';
import DanhMucDVHC from "../../components/AttachTable/DanhMucDVHC/Table"
import Box from "../../components/Box";
import { Scrollbars } from 'react-custom-scrollbars';
import NNNT from "../../components/AttachTable/DMNguonNuocNoiTinh/Table";
const { TabPane } = Tabs;
const ControllerFile = styled.div`
  padding-top: 30px;
`;
class NguonNuoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data_thongtinchung: {},
            data_congTrinh: []
        }
    }
    componentDidMount() {
        const { item } = this.props
        this.GetApiNNNT(item.id)
        item.maSongSuoi && this.GetDSGiayPhep_ByMaNguonNuoc(item.maSongSuoi)
    }

    componentDidUpdate = (prevProps) => {
        const { item } = this.props
        if (prevProps.item !== item) {
            this.GetApiNNNT(item.id)
            item.maSongSuoi && this.GetDSGiayPhep_ByMaNguonNuoc(item.maSongSuoi)
        }
    };

    GetApiNNNT = (id) => {
        return new Api_tnn()
            .GetDM_NguonNuocNoiTinh({
                token: this.props.user.access_token,
                id: id,
            })
            .then((res) => {
                this.setState({
                    data_thongtinchung: res
                })
            }).catch(err => {
                message.error(err.message)
            });
    };
    GetDSGiayPhep_ByMaNguonNuoc = (masong) => {
        return new Api_tnn()
            .GetDSGiayPhep_ByMaNguonNuoc({
                token: this.props.user.access_token,
                masong: masong,
            })
            .then((res) => {
                this.setState({
                    data_congTrinh: res
                })
            }).catch(err => {
                message.error(err.message)
            });
    };
    downFile = (data) => {
        new Api_dms().DownloadFile({
            token: this.props.user.access_token,
            data: { oid: data.idFile, filename: data.tenTaiLieu }
        })
    }
    GetCT_GiayPhepXTByIDCongTrinh = (idcongtrinh, loaicongtrinh) => {
        return new Api_tnn()
            .GetCT_GiayPhepXTByIDCongTrinh({
                token: this.props.user.access_token,
                idcongtrinh: idcongtrinh,
                loaicongtrinh: loaicongtrinh,
            })
            .then((res) => {
                if (res.idFile !== undefined && res.tenTaiLieu !== undefined && res.tenTaiLieu !== null) {
                    this.downFile(res)
                } else if (res.idFile === undefined || res.tenTaiLieu === undefined || res.tenTaiLieu === null) {
                    message.warning(MESSAGE.cscore_tnn_THIEU_TAI_LIEU)
                }
            }).catch(err => {
                message.error(err.message)
            });
    };
    showFileXT = (value) => {
        this.GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, 0)
    }
    showFileNM = (value) => {
        this.GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, value.loaicongtrinh)
    }
    render() {
        const { data_thongtinchung, data_congTrinh } = this.state
        const { item } = this.props
        return (
            <div>
                <Tabs onChange={() => { }} type="card">
                    <TabPane tab="Thông tin chi tiết" key="1">
                        <Descriptions>
                            {!helper.isNullOrUndefined(data_thongtinchung.maSongSuoi) && <Descriptions.Item label="Mã nguồn nước">{data_thongtinchung.maSongSuoi}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.tenSongSuoi) && <Descriptions.Item label="Tên nguồn nước">{data_thongtinchung.tenSongSuoi}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.chieuDai) && <Descriptions.Item label="Chiều dài">{data_thongtinchung.chieuDai + " Km"}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.doSau) && <Descriptions.Item label="Độ sâu">{data_thongtinchung.doSau}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.dienTichLuuVuc) && <Descriptions.Item label="Diện tích lưu vực">{data_thongtinchung.dienTichLuuVuc}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.chayRaSong) && <Descriptions.Item label="Chảy ra">{data_thongtinchung.chayRaSong}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.phanLoaiNguonNuoc) && <Descriptions.Item label="Loại nguồn nước">{helper.getFieldValueFromID(data_thongtinchung.phanLoaiNguonNuoc, Constant.NNNT, "ten")}</Descriptions.Item>}
                            {!helper.isNullOrUndefined(data_thongtinchung.chucNangSong) && <Descriptions.Item label="Chức năng sông">{data_thongtinchung.chucNangSong}</Descriptions.Item>}
                            {data_thongtinchung.phanLoaiNguonNuoc !== Constant.NNNT[3].id && !helper.isNullOrUndefined(data_thongtinchung.tenDVHC) && <Descriptions.Item label="Chảy qua huyện">{data_thongtinchung.tenDVHC}</Descriptions.Item>}
                        </Descriptions>

                        {data_thongtinchung && data_thongtinchung.dM_NguonNuocNoiTinh_DVHCs && data_thongtinchung.dM_NguonNuocNoiTinh_DVHCs.length > 0 &&
                            <ControllerFile>
                                <Box size="small" label="Đơn vị hành chính">
                                    <DanhMucDVHC dataSource={data_thongtinchung.dM_NguonNuocNoiTinh_DVHCs} handleUpdate={() => { }} IdNguonNuoc={data_thongtinchung.id} mode="view" />
                                </Box>
                            </ControllerFile>}
                        {data_thongtinchung.tongLuongNMs && data_thongtinchung.tongLuongNMs.length > 0 &&
                            <ControllerFile>
                                <Box size="small" label={typo.cscore_tnn_title_TNL}>
                                    <NNNT dataSource={data_thongtinchung.tongLuongNMs} handleUpdate={() => { }} mode="view"/>
                                </Box>
                            </ControllerFile>}
                    </TabPane>
                    {data_congTrinh && data_congTrinh.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.XT).length > 0 &&
                        <TabPane tab="Công trình xả thải vào nguồn nước" key="2">
                            <Scrollbars ref="scrollbars" autoHeight autoHeightMax={500} autoHide autoHideTimeout={1000} autoHideDuration={200}>
                                <Table
                                    columns={columns({
                                        showFileModal: this.showFileXT
                                    })}
                                    dataSource={data_congTrinh.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.XT).map((value, idx) => ({ ...value, stt: idx + 1 }))}
                                    pagination={false}
                                />
                            </Scrollbars>
                        </TabPane>}
                    {data_congTrinh && data_congTrinh.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.NM).length > 0 &&
                        <TabPane tab="Công trình khai thác sử dụng nước mặt" key="3">
                            <Scrollbars ref="scrollbars" autoHeight autoHeightMax={500} autoHide autoHideTimeout={1000} autoHideDuration={200}>
                                <Table
                                    columns={columns({
                                        showFileModal: this.showFileNM
                                    })}
                                    dataSource={data_congTrinh.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.NM).map((value, idx) => ({ ...value, stt: idx + 1 }))}
                                    pagination={false}
                                />
                            </Scrollbars>
                        </TabPane>}
                </Tabs>
            </div>
        )
    }
};


const mapPropsToState = (state) => ({
    user: state.oidc.user,
    functions: state.functions
});
export default connect(mapPropsToState)(NguonNuoc)