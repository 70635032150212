import React from "react";
import PropTypes from 'prop-types'
import {Row} from 'antd'
import ReactDataGrid from "react-data-grid";
import EditorEmpty from './EditorEmpty'

class DataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: []
    }
  }

  getData = () => {
    return this.state.rows
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setState({rows: this.props.dataSource})
    }
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(state => {
      const rows = state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    }, () => this.props.updateDataSource(this.state.rows));
  };

  render() {
    const {rows} = this.state;
    const {columns, dataSource, enableCellSelect} = this.props;
    return (
      <Row className="data-grid-custom">
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={dataSource.length}
          onGridRowsUpdated={this.onGridRowsUpdated}
          minHeight={dataSource.length ? 300 : 100}
          headerRowHeight={50}
          emptyRowsView={EditorEmpty}
          enableCellSelect={enableCellSelect}
          enableCellAutoFocus={false}
        />
      </Row>
    );
  }
}

DataGrid.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
  updateDataSource: PropTypes.func.isRequired,
  enableCellSelect: PropTypes.bool.isRequired,
};

export default DataGrid
