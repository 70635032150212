import React, {Component} from "react";
import {connect} from 'react-redux'

//component
import {Table} from 'antd';
import {MESSAGE} from '../../../../constants/dictionaries'
import Message from "../../../../components/Messages";
//helper
import columns from './columns'
import CT_FileDinhKem from '../../../../models/CT_FileDinhKem'
import Api_dms from '../../../../api/api.dms';
import ViewTable from '../../../Table/ViewTable'
class Files extends Component {
  downFile = (data) => {
    if(data.idFile && data.tenTaiLieu){
      new Api_dms().DownloadFile({
        token: this.props.user.access_token,
        data: {oid: data.idFile, filename: data.tenTaiLieu},
        downLoad: true
      })
    }else{
      Message.warning(MESSAGE.cscore_tnn_KHONG_CO_FILE)
    }
  }
  showFile = (data) => {
    if(data.idFile && data.tenTaiLieu){
      new Api_dms().DownloadFile({
        token: this.props.user.access_token,
        data: {oid: data.idFile, filename: data.tenTaiLieu}
      })
    }else{
      Message.warning(MESSAGE.cscore_tnn_KHONG_CO_FILE)
    }
  }

  render() {
    let {dataSource} = this.props;
    let dataSourceIndex = dataSource.map((item, index) => ({...new CT_FileDinhKem(item), stt: index + 1}));
    return (
      <div className='div-table-detail'>
        <ViewTable
          columns={columns({downFile: this.downFile, showFile: this.showFile})}
          dataSource={dataSourceIndex}
          pagination={{hideOnSinglePage: true}}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(Files);