import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Field, Formik, FastField, Form } from 'formik'
// Components
import Container from "../../containers/Container";
import PageWrapper from "../../components/Common/PageWrapper";
import { Row, Col, Icon, Typography } from "antd";
import message from "../../components/Messages";
import DisplayFormikState from '../../components/Table/DisplayFormikState'
import Box from "../../components/Box"
import Input from '../../components/Common/Input'
import Button from '../../components/Buttons'
import * as Yup from "yup";
import styles from "./Modal.module.scss";
// typo
import typo, { MESSAGE } from "../../constants/dictionaries";
import Api from "../../api";
const { Text } = Typography;
const Wrapper = styled.div`
  // padding: 16px 0 20px 0;
  // max-width: 80%;
  padding: 5px 10px; 
`;
const editParaSchema = Yup.object().shape({
  fullName: Yup.string().required(typo.cscore_tnn_input_Required),
  email: Yup.string().required(typo.cscore_tnn_input_Required),
});
class Profile extends Component {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      data: [],
      chucvu: undefined,
      phongban: undefined,
      coquanquanly: undefined,
    };

  }

  componentDidMount() {
    this.Profile()
  }

  Profile = () => {
    const { user } = this.props;
    new Api().Profile({
      token: user.access_token,
    })
      .then(res => {
        this.setState({
          data: res,
          chucvu: res.chucVu,
          phongban: res.phongBan,
          coquanquanly: res.coQuanQuanLy,

        })
      })
      .catch(err => message.error(MESSAGE.cscore_tnn_ERROR))
  };

  render() {
    const {
      data,
      chucvu,
      phongban,
      coquanquanly,
    } = this.state;
    const { user } = this.props;
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          new Api()
            .UpdateProfile({
              token: user.access_token,
              model: {
                ...values,
                chucVuId: chucvu ? chucvu.id : undefined,
                phongBanId: phongban ? phongban.id : undefined,
                CoQuanQuanLyId: coquanquanly ? coquanquanly.id : undefined
              },
            })
            .then(res => {
              message.success(MESSAGE.cscore_tnn_UPDATE_SUCCESS);
            }).catch(err => {
              message.error(err.message)
            })
        }}
        enableReinitialize
        validationSchema={editParaSchema}
        render={({ values, errors, dirty, handleChange, handleSubmit, setFieldValue, touched }) => (
          <form action="">
            <PageWrapper title={typo.cscore_tnn_profile_user}>
              <Container>
                <Row>
                  <Col span={8}></Col>
                  <Col span={8}>
                    <Box size="small">
                      <Wrapper>
                        <Row>
                          <FastField
                            name="userName"
                            render={({ field }) => <Input label={typo.cscore_tnn_login_user_name} mandatory={false} disabled={true} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          <FastField
                            name="fullName"
                            render={({ field }) => <Input label={typo.cscore_tnn_input_name} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          {touched.fullName && (<Text type="danger" className={styles.textFont}>{errors.fullName}</Text>)}
                          <FastField
                            name="mobile"
                            render={({ field }) => <Input label={typo.cscore_tnn_input_phone} mandatory={false} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          <FastField
                            name="email"
                            render={({ field }) => <Input label={typo.cscore_tnn_input_email} style={{ marginBottom: "5px" }} {...field} />}
                          />
                          <FastField
                            name="address"
                            render={({ field }) => <Input label={typo.cscore_tnn_input_address} mandatory={false} style={{ marginBottom: "5px" }} {...field} />}
                          />

                          <Input label={typo.cscore_tnn_license_position} value={chucvu ? chucvu.ten : undefined} mandatory={false} style={{ marginBottom: "5px" }} disabled={true} />

                          <Input label={typo.cscore_tnn_input_PB} value={phongban ? phongban.ten : undefined} mandatory={false} style={{ marginBottom: "5px" }} disabled={true} />

                          <Input label={typo.cscore_tnn_input_company} value={coquanquanly ? coquanquanly.ten : undefined} mandatory={false} style={{ marginBottom: "10px" }} disabled={true} />
                        </Row>
                        <Row type="flex" justify="end" align="middle">
                          <Button type="submit" onClick={handleSubmit}
                            disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                        </Row>
                      </Wrapper>
                    </Box>
                  </Col>
                </Row>
                {/* <DisplayFormikState values={values} errors={errors} /> */}

              </Container>
            </PageWrapper>
          </ form>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(Profile);
