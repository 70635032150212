
import React, {useState, useRef} from "react";
import L from 'leaflet'
import { Row, Icon } from "antd"
import styled from "styled-components";
import SearchInfo from "./SearchInfo"
import GeoJson_Layer_Song from "../../../../components/BanDo/GeoJson_Song/GeoJson_Layer";
import GeoJson_Layer from "../../../../components/BanDo/GeoJson_traCuu/GeoJson_Layer";
import { useMap } from 'react-leaflet'
const ControllerTable = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #ffffffcf;
  z-index: 1000
`;
const ControllerIcon = styled.div`
  cursor: pointer;
  float: right;
  margin-right: -23px;
  border: 1px solid #f6f6f6;
  height: 48px;
  line-height: 48px;
  width: 23px;
  text-align: center;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
  color: #20a8d8;
  &:hover {
    background-color: #20a8d8;
    color:  #fff;
  }
`;
const ControllerSearch = ({ displayTab, hideTab, url_clickInfo, gid_song, data_congtrinh }) => {
  const divRef = useRef(null);
  const [idcongtrinh, setidcongtrinh] = useState(null);
  const [loaicongtrinh, setloaicongtrinh] = useState(null);
  const map = useMap();

  function FlyToButton(latlong) {
    map.flyTo(latlong, 12);
  }
  React.useEffect(() => {
    L.DomEvent.disableClickPropagation(divRef.current);
  });

  return (
    <ControllerTable ref={divRef} style={{ backgroundColor: displayTab === "none" ? "#ffffff00" : "#ffffffcf", }}>
      <Row>
        <ControllerIcon onClick={() => hideTab()}>
          <Icon type={displayTab === "none" ? "search" : "caret-left"} />
        </ControllerIcon>
      </Row>
      <Row style={{ display: displayTab, width: "500px" }}>
        <SearchInfo url_clickInfo={url_clickInfo}
          dulieu_congtrinh={(id, loai) => {
            setidcongtrinh(id);
            setloaicongtrinh(loai)
          }} 
          zoomOut={(latlong) => FlyToButton(latlong)}
          data_congtrinh={data_congtrinh}
          />
      </Row>
      <GeoJson_Layer_Song idsong={gid_song} />
      <GeoJson_Layer idcongtrinh={idcongtrinh} loaicongtrinh={loaicongtrinh} zoomOut={(latlong) => FlyToButton(latlong)} />
    </ControllerTable>
  );
}
export default ControllerSearch