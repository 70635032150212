import React, { Component } from "react";
import { Typography } from "antd";

const withAuth = WrapperComponent => {
  return class extends React.Component{
    constructor(props) {
      super(props)
    }
    render() {
      return <WrapperComponent {...this.props}/>
    }
  }
};

export default withAuth;