import React, { useState } from 'react'
import styled from "styled-components";
import moment from "moment";
import { connect } from 'react-redux'
import { Icon, Row, Col, Collapse, Card, Tooltip, Modal, Layout } from 'antd'
import typo, { MESSAGE } from "../../../constants/dictionaries";
import Select from '../../../components/Common/Select'
import Button from '../../../components/Buttons'
import helper from '../../../helper/helper'
import DownloadFileModal from '../../../components/DownloadFile'
import Api from '../../../api';
import message from "../../../components/Messages";
import constant, { functionsCode } from '../../../constants/constant'
import Container from "../../../containers/Container";
import PageWrapper from "../../../components/Common/PageWrapper";
import { isEmpty } from 'lodash';
import Api_tnn from '../../../api/api.tnn';
import SearchByURI from "../../../helper/SearchByURI";
import Api_dms from '../../../api/api.dms';
// import DNBCTNNModal from "../../licenses/BaoCaoDinhKy/Modal/DNBCModal"
import Status from "../../../components/Table/Status";
import LoaiGP from '../../../components/Table/Status/LoaiGP';
const { Content, Footer } = Layout;
const { Panel } = Collapse;
const { Meta } = Card;
let timeout;
let currentValue;
const TitleWrapper = styled.div`
  background-color: #fff;
  padding: 8px 16px;
`;
const CustomIcon = styled(Icon)`
  font-size: 16px;
  cursor: pointer;
  color: ${props => props.color} !important;
`;
function fetch(value, callback, user) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  if (value === "null") {
    callback([]);
  } else {
    function fake() {
      new Api().Get_DonViXinCapPheps_paged({ token: user.access_token, page: 0, size: 0, strSearch: currentValue })
        .then(res => {
          if (currentValue === value) {
            callback(res && res.data);
          }
        })
        .catch((err) => message.error(err.message))
    }
    timeout = setTimeout(fake, 800);
  }
}
class TimKiemDoanhNghiep extends React.PureComponent {
  constructor(props) {
    super(props);
    const { functions } = props;
    this.state = {
      data: [],
      dataDTM: [],
      dataBVMT: [],
      dataTTKT: [],
      dataGP: [],
      searchDoanhNghiep: [],
      selectdFile: [],
      selectedRow: undefined,
      timeout: null,
      isShowEditTNNModal: false,
      setModal: false,
      list_MT: {},
      cap: undefined,
      maDVHCbyUser: "1000",
      editable: !isEmpty(functions) ? helper.handleGetEditableFunction(functions, functionsCode.TKDN).editable : false,
    };
    this.searchParams = {
      idDoanhNghiep: undefined,
    };

    this.searchByURI = new SearchByURI({ defaultParams: this.searchParams });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      const { functions } = this.props;
      if (!isEmpty(functions)) {
        const func = helper.handleGetEditableFunction(functions, functionsCode.TKDN);
        this.setState({ editable: func.editable })
      }
    }
  }
  componentDidMount = () => {
    const searchParams = this.searchByURI.getSearchParmas();
    if (searchParams.idDoanhNghiep) {
      this.setState({
        searchDoanhNghiep: JSON.parse(searchParams.idDoanhNghiep)
      })
      this.Profile()
    }
  }
  Profile = () => {
    const searchParams = this.searchByURI.getSearchParmas();
    const { user, profile } = this.props;
    let resSort = helper.sortArrayNumber(profile.roles)
    this.setState({
      cap: resSort[0].cap,
      maDVHCbyUser: profile.maDVHC,
    })
    this.GetGpByDoanhNghiep(JSON.parse(searchParams.idDoanhNghiep), profile.maDVHC ? profile.maDVHC : "1000")
    this.getDVXCP("", JSON.parse(searchParams.idDoanhNghiep))
  };

  getDVXCP = (strSearch, idDoanhNghiep) => {
    const { user } = this.props
    new Api().Get_DonViXinCapPheps_paged({ token: user.access_token, page: 0, size: 0, strSearch: strSearch })
      .then(res => {
        if (idDoanhNghiep) {
          let data_doanhnghiep = []
          idDoanhNghiep.length > 0 && idDoanhNghiep.map((vl, idx) => {
            let obj = res && res.data.find(e => e.id === vl);
            data_doanhnghiep.push(obj)
          })
          this.setState({ data: data_doanhnghiep.map((item) => ({ ...item, tenvsdiachi: item.ten + ". Địa chỉ: " + item.diaChi })) })
        } else {
          this.setState({ data: res && res.data.map((item) => ({ ...item, tenvsdiachi: item.ten + ". Địa chỉ: " + item.diaChi })) })
        }
      })
      .catch((err) => message.error(err.message))
  }
  downFileExcel = () => {
    new Api_tnn().Download_KQTimKiem_By_DoanhNghiep({
      token: this.props.user.access_token,
      dulieu_doanhnghiep: { ...this.state.list_MT, list_TNN: this.state.dataGP }
    })
  }

  GetGpByDoanhNghiep = (iddoanhnghiep, madvhc) => {
    const { user } = this.props
    new Api_tnn().GetGpByDoanhNghieps({ token: user.access_token, iddoanhnghiep: iddoanhnghiep, madvhc })
      .then(res => {
        this.setState({
          dataGP: res
        })
      })
      .catch((err) => message.error(err.message))
  }

  GetDoanhNgiep_BaoCao = (id) => {
    return new Api_tnn()
      .GetDoanhNgiep_BaoCao({
        token: this.props.user.access_token,
        id: id,
      })
      .then((res) => {
        this.setState({
          isShowEditTNNModal: true,
          selectedRow: res,
        });
      }).catch(err => {
        message.error(err.message)
      });
  };
  handleCancel = () => {
    this.setState({
      selectedRow: undefined,
      isShowEditTNNModal: false
    })
  }
  SelectSearch = value => {
    const { user } = this.props;
    if (value && value !== "" && value !== undefined) {
      fetch(
        value,
        res => this.setState({ data: res.map((item) => ({ ...item, tenvsdiachi: item.ten + ". Địa chỉ: " + item.diaChi })) }),
        user
      );
    } else {
      fetch(
        "null",
        res => this.setState({ data: res }),
        user
      );
    }
  };
  giayPhep = (value, nhomCT, loaiCT, item) => {
    const { cap } = this.state
    if ((cap === constant.Role_user.LD_CV_BO && item.capThamQuyen === constant.CAP_TK.TRUNGUONG)) {
      this.routerGP(value, nhomCT, loaiCT)
    } else if (cap < constant.Role_user.LD_CV_BO) {
      this.routerGP(value, nhomCT, loaiCT)
    } else if (cap === constant.Role_user.LD_CV_SO && item.capThamQuyen === constant.CAP_TK.DIAPHUONG) {
      this.routerGP(value, nhomCT, loaiCT)
    } else {
      message.warning(MESSAGE.CSCORE_KHONG_CO_QUYEN_TRUY_CAP)
    }
  }
  routerGP = (value, nhomCT, loaiCT) => {
    if (nhomCT === constant.DICT_NHOM_CT.XT) {
      this.props.history.push(`/cap-phep/xa-thai/edit/${value}`)
    } else if (nhomCT === constant.DICT_NHOM_CT.NM) {
      this.props.history.push(`/cap-phep/khai-thac-nuoc-mat/edit/${value}`)
    } else if (nhomCT === constant.DICT_NHOM_CT.NB) {
      this.props.history.push(`/cap-phep/khai-thac-nuoc-bien/edit/${value}`)
    } else {
      if (loaiCT === constant.DICT_LOAI_CT.NDD_THAMDO) {
        this.props.history.push(`/cap-phep/tham-do-nuoc-duoi-dat/edit/${value}`)
      } else if (loaiCT === constant.DICT_LOAI_CT.NDD_KHAITHAC) {
        this.props.history.push(`/cap-phep/khai-thac-nuoc-duoi-dat/edit/${value}`)
      }
    }
  }
  searchDoanhNghiep = (value) => {
    this.searchByURI.navigate({
      idDoanhNghiep: JSON.stringify(value),
    })
  }
  showFile = (data) => {
    if (data.files !== undefined && data.files !== [] && data.files.length > 0) {
      if (data.files.length > 1) {
        this.setState({
          setModal: true,
          selectdFile: data.files
        })
      } else {
        this.downFile(data.files[0])
      }
    } else {
      message.warning(MESSAGE.cscore_tnn_KHONG_CO_FILE)
    }
  }
  downFile = (data) => {
    if (data === undefined || data === []) {
      message.warning(MESSAGE.cscore_tnn_KHONG_CO_GIAY_PHEP)
    } else {
      new Api_dms().DownloadFile({
        token: this.props.user.access_token,
        data: { oid: data.idFile, filename: data.tenTaiLieu }
      })
    }
  }
  handleCancel = () => {
    this.setState({ setModal: false })
  }
  render() {
    const { data, dataGP, searchDoanhNghiep, setModal, selectdFile } = this.state;
    const searchParams = this.searchByURI.getSearchParmas();
    let title = ""
    if (constant.CAP_TRIEN_KHAI === constant.CAP_TK.TRUNGUONG) {
      title = "Nhà máy Nhiệt điện Thăng Long"
    } else if (constant.CAP_TRIEN_KHAI === constant.CAP_TK.DIAPHUONG && Number(constant.MA_TINH) === 31) {
      title = "Bệnh viện đa khoa Hải Phòng"
    }
    return (
      <PageWrapper title={typo.cscore_tnn_title_tcnd}>
        <Container>
          <div>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={12}>
                <Layout className='pagewrapper-layout'>
                  <div className='pagewrapper-layout-container'>
                    <TitleWrapper className='layout-container-title'>
                      <div style={{ textAlign: "center" }}>TRA CỨU DỮ LIỆU CỦA DOANH NGHIỆP/ TỔ CHỨC</div>
                    </TitleWrapper>
                    <Content className='layout-container-content' style={{ minHeight: 0 }}>
                      <Select
                        placeholder="Nhập tên/ địa chỉ để tìm kiếm..."
                        width="100%"
                        name="tenvsdiachi"
                        name_value="id"
                        mandatory={false}
                        defaultValue={searchParams.idDoanhNghiep ? JSON.parse(searchParams.idDoanhNghiep) : undefined}
                        onSearch={this.SelectSearch}
                        onChange={(value, e) => this.setState({ searchDoanhNghiep: value })}
                        showArrow={false}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={null}
                        dataSource={data}
                        size="large"
                        modeSelect="multiple"
                      />
                      <div style={{ color: "#aba2a2", marginTop: "10px" }}>{`Ví dụ: Nhập từ khóa "${title}" để tìm kiếm "${title}"`}</div>

                      <div className="mt-10" />
                      <Button
                        type="submit"
                        style={{ marginTop: 10, marginLeft: "35%" }}
                        onClick={() => this.searchDoanhNghiep(searchDoanhNghiep)}>
                        <Icon type="search" style={{ marginLeft: '0px' }} />Tìm kiếm</Button>


                      <Button
                        style={{ marginLeft: "1%" }}
                        type="excel"
                        onClick={() => this.downFileExcel()}
                      >
                        <Icon type="file-excel" />
                        {typo.cscore_tnn_out_excel}
                      </Button>

                    </Content>
                  </div>
                </Layout>
              </Col>
            </Row>
            <hr />
            <Row className="mt-20" />
            <Row>
              {dataGP.length > 0 &&
                <div>
                  <Collapse bordered={false} defaultActiveKey={['4']}>
                    <Panel header={typo.cscore_tnn_license} key="4">
                      {dataGP.map((item, index) => {
                        return <Row>
                          <Col xs={24} md={16}>
                            <div style={{ padding: "10px" }}>
                              <div style={{ margin: "0 0 0 20px" }}>
                                <div onClick={() => this.giayPhep(item.id, item.nhomCongTrinh, item.loaiCongTrinh, item)}>
                                  {window.innerWidth < 500 ?
                                    <div className="ant-card-meta-title">
                                      <a>{"Số giấy phép: " + item.soVanBan}</a>
                                      <div><a>{"Ngày ký: " + moment(item.ngayKy).format("DD-MM-YYYY")}</a></div>
                                      <div><LoaiGP NhomCongTrinh={item.nhomCongTrinh} /></div>
                                    </div>
                                    :
                                    <Row className="ant-card-meta-title" type="flex" >
                                      <a>{`Số giấy phép: ${item.soVanBan}, Ngày ký: ${moment(item.ngayKy).format("DD-MM-YYYY")}  `}</a>
                                      <div style={{ marginLeft: 10 }}><LoaiGP NhomCongTrinh={item.nhomCongTrinh} /></div>
                                    </Row>
                                  }
                                  <Row className="ant-card-meta-description" type="flex">
                                    <div>Tên đơn vị xin cấp phép: </div>
                                    <div style={{ color: "#000000c2", marginRight: 5 }}>{item.tenDonViXCP}</div>
                                  </Row>
                                  {item.diaChiDonViXCP &&
                                    <Row className="ant-card-meta-description" type="flex">
                                      <div>Địa chỉ đơn vị xin cấp phép: </div>
                                      <div style={{ color: "#000000c2", marginRight: 5 }}>{item.diaChiDonViXCP}</div>
                                    </Row>}
                                  <Row className="ant-card-meta-description" type="flex">
                                    <div>Tên công trình: </div>
                                    <div style={{ color: "#000000c2", marginRight: 5 }}>{item.tenCongTrinh}</div>
                                  </Row>
                                  {item.diaDiemCongTrinh &&
                                    <Row className="ant-card-meta-description" type="flex">
                                      <div>Địa chỉ công trính: </div>
                                      <div style={{ color: "#000000c2", marginRight: 5 }}>{item.diaDiemCongTrinh}</div>
                                    </Row>}
                                </div>
                                <Tooltip placement="top" title="Xem file" className="ant-card-meta-description">
                                  <CustomIcon
                                    color="#63c2de"
                                    type="file-pdf"
                                    onClick={() => this.showFile(item)}
                                    style={{ padding: "5px 10px 0 2px" }}
                                  />
                                </Tooltip>
                                <Status value={item.ngayKTHieuLuc} trangthai={item.trangThai} />
                                {item.list_BCDK.length > 0 &&
                                  <Collapse bordered={false}>
                                    <Panel header={typo.cscore_tnn_ds_bcdk}>
                                      {item.list_BCDK.map((v, idx) => {
                                        return <Row>
                                          <Col xs={24} md={16}>
                                            <div style={{ padding: "10px" }}>
                                              <div style={{ margin: "0 0 8px 20px" }}>
                                                <div onClick={() => this.GetDoanhNgiep_BaoCao(v.id)}>
                                                  {window.innerWidth < 500 ?
                                                    <div className="ant-card-meta-title" style={{ fontSize: "14px" }}>
                                                      <a>{"Đợt báo cáo: " + v.dotBaoCao}</a>
                                                      <div><a>{"Kỳ báo cáo: " + v.namBaoCao}</a></div>
                                                    </div>
                                                    :
                                                    <div className="ant-card-meta-title" style={{ fontSize: "14px" }}>
                                                      <a>{"Đợt báo cáo: " + v.dotBaoCao + ", " + "Kỳ báo cáo: " + v.namBaoCao}</a>
                                                    </div>
                                                  }

                                                  <div className="ant-card-meta-description" style={{ fontSize: "13px" }}>
                                                    {"Số báo cáo: " + v.maBaoCao}
                                                  </div>
                                                  <div className="ant-card-meta-description" style={{ fontSize: "13px" }}>
                                                    {"Tên báo cáo: " + v.tenBaoCao}
                                                  </div>
                                                </div>
                                                <Tooltip placement="top" title="Xem file" className="ant-card-meta-description">
                                                  <CustomIcon
                                                    color="#63c2de"
                                                    type="file-pdf"
                                                    onClick={() => { v.fileDinhKems && this.showFile(v.fileDinhKems) }}
                                                    style={{ padding: "5px 2px 0 2px" }}
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      })}
                                    </Panel>
                                  </Collapse>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      }
                      )}
                    </Panel>
                  </Collapse>
                  <hr />
                  <Row className="mt-20" />
                </div>}
            </Row>
            {setModal &&
              <DownloadFileModal
                isOpen={setModal}
                handleCancel={this.handleCancel}
                selectdFile={selectdFile}
              />}
          </div>
        </Container>
      </PageWrapper>

    )
  };
}
TimKiemDoanhNghiep.propTypes = {

};
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions,
  profile: state.commonReducer.profile.data,
});

export default connect(mapStateToProps)(TimKiemDoanhNghiep);

