import React, { Component } from "react";
import {connect} from 'react-redux'

//component
import {Upload, Icon} from 'antd';
import Button from "../../../Buttons";

// helper
import typo from "../../../../constants/dictionaries";
import Api_dms from '../../../../api/api.dms'
import {TypeFiles, MESSAGE} from '../../../../constants/dictionaries'
import message from '../../../../components/Messages'

class UpFile extends Component {
    constructor(props){
        super(props)
        this.state={
            fileList: this.props.item ? [{uid: 1, name: this.props.item.tenTaiLieu, status: 'done', idFile: this.props.item.idFile}] : []
        }
    }

    handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        this.setState({ fileList });
    };

    customRequest = (options) => {
        var data = new FormData()
        data.append('file', options.file)
        if(TypeFiles.includes(options.file.type)){
            this.uploadFile(data)
        }else{
            message.error("File " + options.file.name + " không hỗ trợ định dạng")
        }
    }

    uploadFile = (data) =>{
        new Api_dms().UploadFile({token: this.props.user.access_token, data: data}).then((result)=>{
            if(this.state.fileList.length !== 1) return
            var file = this.state.fileList[0]
            var fileList = []
            if(result && result.result && result.result.length > 0){
                file = {...file, status: "done", tenTaiLieu: result.result[0].fileName, extension: result.result[0].extension, idFile: result.result[0].oid}
                fileList = [file]
            }else{fileList=[]; message.error(MESSAGE.cscore_tnn_UPLOAD_FILE_FAILURE)}
            this.setState({ fileList});
            if(this.props.parentHandle) {this.props.parentHandle(fileList, this.props.setFieldValue)}
            this.props.setFieldValue("idFile", fileList.length > 0 ? fileList[0].idFile : undefined)// sẽ bỏ hàm này
            this.props.setFieldValue("tenTaiLieu", fileList.length > 0 ? fileList[0].name : undefined)// sẽ bỏ hàm này
        })
    }

    render(){
        return (
            <Upload onChange={this.handleChange} customRequest={this.customRequest} multiple={false} fileList={this.state.fileList}><Button style={{marginTop: 10}}><Icon type="upload" />{typo.cscore_tnn_fileTaiLieu}</Button></Upload>
        )
    }
}
const mapStateToProps = (state) => ({user: state.oidc.user});
export default connect(mapStateToProps)(UpFile);