import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Icon, Row, Col } from 'antd'
import PropTypes from 'prop-types'
import typo from "../../../constants/dictionaries";
import Select from '../../Common/Select'
import Button from '../../../components/Buttons'
import DonViXCPModal from './DonViDCPModal'
import { FastField } from "formik";
import ValidatedText from "../../Common/ValidatedText";
import helper from '../../../helper/helper'
import Input from '../../Common/Input'
import Api from '../../../api';
import message from "../../Messages";


let timeout;
let currentValue;

function fetch(value, callback, user) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  if (value === "null") {
    callback([]);
  } else {
    function fake() {
      new Api().Get_DonViXinCapPheps_paged({ token: user.access_token, page: 0, size: 0, strSearch: currentValue })
        .then(res => {
          if (currentValue === value) {
            callback(res && res.data);
          }
        })
        .catch((err) => message.error(err.message))
    }
    timeout = setTimeout(fake, 800);
  }
}
class DonViXinCapPhep extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      setModal: false,
      data: [],
      timeout: null
    }
  }
  componentDidUpdate = (prevProps) => {
    const { values } = this.props
    if (values.idDonViXinCapPhep) {
      if (values.idDonViXinCapPhep !== prevProps.values.idDonViXinCapPhep) {
        this.getDVXCP(values.tenDonViXCP)
      }
    }
  };
  componentDidMount = () => {
    const { values } = this.props
    if (values.idDonViXinCapPhep) {
      this.getDVXCP(values.tenDonViXCP)
    }
  }

  getDVXCP = (strSearch) => {
    const { user } = this.props
    new Api().Get_DonViXinCapPheps_paged({ token: user.access_token, page: 0, size: 0, strSearch: strSearch })
      .then(res => {
        this.setState({ data: res && res.data })
      })
      .catch((err) => message.error(err.message))
  }
  SelectSearch = value => {
    const { user } = this.props;
    if (value && value !== "" && value !== undefined) {
      fetch(
        value,
        res => this.setState({ data: res }),
        user
      );
    } else {
      fetch(
        "null",
        res => this.setState({ data: res }),
        user
      );
    }
  };
  render() {
    const { setModal, data } = this.state;
    const { values, setFieldValue, handleGP, mode, view } = this.props
    return (
      <Row>
        <Row type="flex">
          <Col span={view === 'view' ? 24 : 21}>
            <Select placeholder={typo.cscore_tnn_license_organization_placeholder}
              width="100%"
              label={typo.cscore_tnn_license_organization}
              name="ten"
              name_value="id"
              defaultValue={values.idDonViXinCapPhep}
              key={values.idDonViXinCapPhep}
              onSearch={this.SelectSearch}
              onSelect={(value, e) => {
                setFieldValue("idDonViXinCapPhep", value);
                setFieldValue("tenDonViXCP", e.props.children);
                setFieldValue("diaChiDonViXCP", helper.getFieldValueFromID(value, data, "diaChi"));
                handleGP(value)
              }}
              showArrow={false}
              filterOption={false}
              defaultActiveFirstOption={false}
              notFoundContent={null}
              dataSource={data}
            // disabled={mode === "edit" ? true : false}
            />
          </Col>
          <Col span={view === 'view' ? 0 : 3}>
            <Row type="flex" align="bottom" style={{ height: "100%", float: "right" }}>
              <Button type="primary" onClick={() => this.setState({ setModal: true })}>
                <Icon style={{ color: '#fff' }} type="plus" />
              </Button>
            </Row>
          </Col>
        </Row>
        <FastField
          name="diaChiDonViXCP"
          render={({ field }) => <Input label={typo.cscore_tnn_diaChiDonViXCP} id="diaChiDonViXCP"
            //  disabled={mode === "edit" ? true : false}
            {...field} mandatory={false} />}
        />

        <DonViXCPModal
          handleCancel={() => this.setState({ setModal: false })}
          handleOk={() => {
            this.setState({ setModal: false });
          }}
          isOpen={setModal} />
      </Row>
    )
  };
}
DonViXinCapPhep.propTypes = {
  handleGP: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.oidc.user,
  functions: state.functions
});

export default connect(mapStateToProps)(DonViXinCapPhep);

