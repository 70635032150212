/*
 *  @2019 Cs-core project
 *
 */

import React from "react";
import ReactDOM from "react-dom";

import { Spin } from "antd";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Box = styled.div`
  background-color: #fff;
  padding: 15px 20px 10px;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
`;
export const FullLoading = () => (
  <Overlay>
    <Box>
      <Spin />
    </Box>
  </Overlay>
);

export default {
  show: function() {
    if (window.isLoading) {
      return null;
    }
    window.isLoading = true;
    // do stm here

    window.domLoading = document.createElement("div");
    document.body.append(window.domLoading);

    ReactDOM.render(<FullLoading />, window.domLoading);
  },
  hide: function() {
    window.isLoading = false;
    if (window.domLoading) {
      ReactDOM.unmountComponentAtNode(window.domLoading);
      try {
        document.body.removeChild(window.domLoading);
      } catch (e) {}
    }
  }
};
