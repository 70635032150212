import React from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Table from "../../Table";
import InputNumber from "../../Common/NumberField"

// Components
import EditableTable from "../../Table/EditableTable";
import DataGrid from "../../DataGrid";
import Api_tnn from "../../../api/api.tnn";
import Message from "../../Messages";
import EditorNumberField from "../../DataGrid/EditorNumberField";
import EditorCheckbox from "../../DataGrid/EditorCheckbox";
import UnClickableCheckbox from "../../Common/Checkbox/UnClickableCheckBox";
import helper from '../../../helper/helper'
import { keyframes } from 'styled-components'

const columns = ({ } = {}) => [
  {
    title: "STT",
    dataIndex: "stt",
    width: 50,
  },
  {
    title: "Quan trắc tự động",
    dataIndex: "qT_TuDong",
    width: 100,
    render: (value, row) =>
      <div style={{ textAlign: "center" }}>
        <Checkbox defaultChecked={row.qT_TuDong} onChange={value => { row.qT_TuDong = value.target.checked }} />
      </div>
    // formatter: ({ value }) => <UnClickableCheckbox checked={value} />,
    // formatter: ({value}) => <Checkbox checked={value} disabled={true}/>,
  },
  {
    title: "Quan trắc định kỳ",
    dataIndex: "qT_DinhKy",
    width: 100,
    render: (value, row) =>
      <div style={{ textAlign: "center" }}>
        <Checkbox defaultChecked={row.qT_DinhKy} value={row.qT_DinhKy} onChange={value => { row.qT_DinhKy = value.target.checked }} />
      </div>
    // editor: EditorCheckbox,
    // formatter: ({ value }) => <UnClickableCheckbox checked={value} />,
    // formatter: ({value}) => <Checkbox checked={value} disabled={true}/>,
  },
  {
    title: "Thông số",
    dataIndex: "tenThongSo",
    editable: true,
  },
  {
    title: "Đơn vị",
    dataIndex: "tenDonVi",
    width: 120,
  },

  {
    title: "Giá trị Cmax từ",
    dataIndex: "gioiHanMin",
    width: 100,

    // editor: EditorNumberField
    render: (value, row) =>
      <div>

        <InputNumber
          mandatory={false}
          width="100%"
          defaultValue={value ? value : 0}
          onChange={value => row.gioiHanMin = value}
        />

      </div>,
  },
  {
    title: "Giá trị Cmax đến",
    dataIndex: "gioiHanMax",
    width: 100,

    render: (value, row) =>
      <div>

        <InputNumber
          mandatory={false}
          width="100%"
          defaultValue={value ? value : 0}
          onChange={value => row.gioiHanMax = value}
        />

      </div>,
    // editor: EditorNumberField
  },
];

class TSNDNuocThai extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  componentDidMount() {
    const { initialValue } = this.props;
    initialValue && this.setState({ dataSource: initialValue.map((item, index) => ({ ...item, stt: index + 1 })) })
  }

  getDataSource = () => {
    return this.state.dataSource
  };

  get_GioiHanThongSo = () => {
    const { user, listCLNuocThai } = this.props;
    const idDM_QCVNs = listCLNuocThai && listCLNuocThai.map((item) => item.idQCApDung);
    const clnt_gioihants_jsons = this.getDataSource();
    if (!isEmpty(listCLNuocThai)) {
      new Api_tnn().postCLNT_GioiHanThongSo_Init({
        token: user.access_token,
        auto: listCLNuocThai[0].Cmax === 1 ? "yes" : "no",
        mdsd_id: listCLNuocThai[0].idMucDichSuDung,
        kq: listCLNuocThai[0].heSoKQ ? listCLNuocThai[0].heSoKQ : 0,
        kf: listCLNuocThai[0].heSoKF ? listCLNuocThai[0].heSoKF : 0,
        k: listCLNuocThai[0].heSoK ? listCLNuocThai[0].heSoK : 0,
        obj: {
          'idDM_QCVNs': idDM_QCVNs,
          'clnt_gioihants_jsons': clnt_gioihants_jsons
        }
      })
        .then(res => {
          this.setState({ dataSource: res.map((item, index) => ({ ...item, stt: index + 1 })) })
        })
        .catch(err => Message.error(err.message))
    } else {
      this.setState({ dataSource: [] })
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ dataSource: this.props.initialValue.map((item, index) => ({ ...item, stt: index + 1 })) })
    } else if (prevProps.listCLNuocThai !== this.props.listCLNuocThai) {
      this.get_GioiHanThongSo()
    }
  }

  render() {
    const { mode } = this.props;
    const { dataSource } = this.state;
    return (
      <Row>
        <Table
        
          dataSource={dataSource}
          columns={columns({})}
          pagination={false}
          scroll={{ y: 400 }}
          
        />
      </Row>
    )
  }
}

TSNDNuocThai.propTypes = {
  initialValue: PropTypes.object,
  mode: PropTypes.string.isRequired,
  listCLNuocThai: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
};

export default TSNDNuocThai
