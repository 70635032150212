import React from 'react';
import {Row} from 'antd'
import PropTypes from 'prop-types'
import ViewField from "../../../Common/ViewField";

import typo from '../../../../constants/dictionaries'
import GioiHanThongSo from "../index";

const GioiHanThongSoView = ({giayPhepCT}) => {

    const {id_qcvn, ma_qcvn, list_gioihan_thongso} = giayPhepCT ? JSON.parse(giayPhepCT) : []

  return (
    <Row>
      <ViewField label={typo.cscore_tnn_quyChuanApDung}>
        {ma_qcvn}
      </ViewField>
      <GioiHanThongSo mode="presentation" quyChuanApDung={id_qcvn} initialValue={list_gioihan_thongso}/>
    </Row>
  )
};

GioiHanThongSoView.propTypes = {
  giayPhepCT: PropTypes.object.isRequired
};

export default GioiHanThongSoView