/*
 *  @2019 CS-Core project.
 *
 */

import React from "react";
import { Alert } from "antd";
import { MESSAGE } from "../../constants/dictionaries";

const NewUpdate = (
  <div>
    <h1>New update avaiable!</h1>
    <p>Please update this page to get latest version.</p>
    <a href={window.location.pathname} className="ant-btn primary">
      Refresh now
    </a>
  </div>
);

export default ({
  error = {},
  className = "mt-20",
  title = MESSAGE.cscore_tnn_THERE_IS_SOMETHING_WRONG
  // description
}) => {
  if (!error) {
    return false;
  }

  if (error === true) {
    return (
      <Alert
        type="error"
        message="Ops.."
        description={title}
        showIcon
        className={className}
      />
    );
  }

  if (Array.isArray(error.pureMessage)) {
    return (
      <Alert
        type="error"
        showIcon
        className={className}
        message={
          <div>
            {error.pureMessage.length > 1 ? (
              <ul style={{ paddingLeft: 20, marginLeft: 20 }}>
                {error.pureMessage.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            ) : (
              error.pureMessage[0]
            )}
          </div>
        }
      />
    );
  }

  switch (error.status) {
    case 404:
      return (
        <Alert
          type="error"
          message="404"
          description="Page not found"
          showIcon
        />
      );
    case 503:
      return (
        <Alert
          type="error"
          message="503"
          description="Server maintenance in processing"
          showIcon
        />
      );
    case 403:
      return (
        <Alert
          type="error"
          message="403"
          description={MESSAGE.YOU_DONT_HAVE_PERMISSION_TO_VIEW_PAGE}
          showIcon
        />
      );
    // doesn't need to show when 409 or 401.
    case 409:
    case 401:
      return false;

    case 500:
      return (
        <Alert
          type="error"
          message={MESSAGE.cscore_tnn_THERE_IS_SOMETHING_WRONG}
          showIcon
        />
      );

    case 999:
      return <NewUpdate />;

    default:
      const strError = typeof error === "object" ? error.toString() : error;
      if (
        strError.includes("Loading chunk") ||
        strError.includes("Minified React error")
      ) {
        return <NewUpdate />;
      }
      return (
        <Alert
          type="error"
          showIcon
          className={className}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: strError
                  ? strError.replace("Error:", "").replace(/;/g, "<br/>")
                  : ""
              }}
            />
          }
        />
      );
  }
};
