import React from 'react'
import { Card, Typography, Row } from 'antd'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom";

//Components
import Container from "../../containers/Container";
import PageWrapper from '../../components/Common/PageWrapper';
import DashboardDP from "../dashboard";
import DashboardBo from '../../components/dashboardBo'

// Utils
import typo from '../../constants/dictionaries'
import Constant from '../../constants/constant';
import { Scrollbars } from 'react-custom-scrollbars';
const { Title } = Typography;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (

      <div>
        <Scrollbars ref="scrollbars" style={{ height: window.innerHeight - 45 }} autoHide autoHideTimeout={1000} autoHideDuration={200} >
          {Constant.CAP_TRIEN_KHAI === 1 ?
            <Row style={{ backgroundColor: "#eaecef" }}>
              <DashboardBo
                history={this.props.history}
                functions={this.props.functions}
              />
            </Row>
            :
            <DashboardDP
              history={this.props.history}
              functions={this.props.functions}
            />

          }
        </Scrollbars>
      </div>

    )
  }
}

Dashboard.propTypes = {

};

export default withRouter(Dashboard)