import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon } from 'antd'
import { Formik, FastField } from 'formik'
import typo from '../../../../constants/dictionaries'
import { isEmpty } from "lodash";
import moment from "moment";

// Utils

// Components
import Input from '../../../Common/Input'
import Button from '../../../Buttons'
import styled from "styled-components";
import ValidatedText from "../../../Common/ValidatedText";
import NumberField from "../../../Common/NumberField"
import Helper from "../../../../helper/helper";
import Select from "../../..//Common/Select";

const schema = Yup.object().shape({

});
class TCQKTSDModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      years: []
    }
  }

  componentDidMount() {
    this.getYears()
  }
  getYears = () => {
    var c_year = new Date().getFullYear(); var years = []
    for (var i = c_year + 30; i >= 1975; i--) { years.push({ year: i }) }
    this.setState({ years: years })
  }
  handleUpdateCLNuocThai = (list) => {
    this.setState({ listCLNuocThai: list })
  };

  render() {
    const { isOpen, handleCancel, handleOk, initialValues, mode, user, isEdit } = this.props;
    const { years } = this.state
    return (
      <Modal
        visible={true}
        title={typo.cscore_tnn_title_TNL}
        footer={false}
        onCancel={handleCancel}
        width="500px"
        centered
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(value, actions) => {
            handleOk(value);
            actions.resetForm({});
          }}
          validationSchema={schema}
          render={({ handleChange, handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
            <form onSubmit={handleSubmit} autoComplete={false}>
              <Row>
                <NumberField
                  label={typo.cscore_tnn_input_llmk}
                  placeholder={typo.cscore_tnn_input_llmk}
                  mandatory={false} id="luongNuocMuaKho"

                  onChange={value => setFieldValue("luongNuocMuaKho", value ? value : 0)}
                  defaultValue={values.luongNuocMuaKho}
                />
                <NumberField
                  label={typo.cscore_tnn_input_llmm}
                  placeholder={typo.cscore_tnn_input_llmm}
                  mandatory={false} id="luongNuocMuaMua"

                  onChange={value => setFieldValue("luongNuocMuaMua", value ? value : 0)}
                  defaultValue={values.luongNuocMuaMua}
                />
                <NumberField
                  label={typo.cscore_tnn_input_llcn}
                  placeholder={typo.cscore_tnn_input_llcn}
                  mandatory={false} id="luongNuocCaNam"

                  onChange={value => setFieldValue("luongNuocCaNam", value ? value : 0)}
                  defaultValue={values.luongNuocCaNam}
                />
                <Select
                  dataSource={years}
                  defaultValue={values.nam ? Number(values.nam) : undefined}
                  onChange={(value) => { setFieldValue("nam", value) }}
                  placeholder={typo.cscore_tnn_nam}
                  mandatory={false}
                  width="100%"
                  name="year"
                  name_value="year"
                  key={values.nam}
                  label={typo.cscore_tnn_nam}
                />
              </Row>
              <div className="mt-20" />
              {mode !== "presentation" &&
                <Row type="flex" justify="end" align="middle">
                  <Button type="submit" onClick={handleSubmit} disabled={!isEmpty(errors)}><Icon type="save" />{typo.cscore_tnn_save}</Button>
                  <div className="ml-10" />
                  <Button type="cancel" onClick={handleCancel}><Icon type="arrow-right" />{typo.cscore_tnn_exit}</Button>
                </Row>}
              {/*<DisplayFormikState error={errors} value={values} touched={touched}/>*/}
            </form>
          )} />
      </Modal>
    )
  }
}

TCQKTSDModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  /*Mode: add, edit, presentation*/
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.oidc.user
});

export default connect(mapStateToProps)(TCQKTSDModal)

const GroupWrapper = styled.div`
  max-width: 80%;
  padding: 5px 10px; 
`;

