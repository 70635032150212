import React, { Component } from 'react'
import { Col, Row, Icon } from "antd";
import typo from "../../constants/dictionaries";
import BarChart from './bar'
import helper from '../../helper/helper';
import Select from "../../components/Common/Select";
import { constant, values } from 'lodash';
import Api from '../../api';
import { connect } from 'react-redux'
import Constant from '../../constants/constant';
import Button from '../../components/Buttons'
import Api_tnn from '../../api/api.tnn';
import messages from '../../components/Messages'
import moment from 'moment'
class BoxChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DL: [],
            data_tinh: [],
            madvhc: "",
            from: moment(new Date().setMonth(new Date().getMonth() - 12)), to: moment(new Date()),
            capthamquyen: 0
        }
    }
    componentDidMount() {
        const { tinhs, profile, madvhc_byuser } = this.props
        this.setState({
            DL: this.props.data
        })
        this.getYears();
        if (!helper.isNullOrUndefined(tinhs)) {
            this.setState({ data_tinh: tinhs })
        }
        if (profile && profile.userName) {
            let resSort = helper.sortArrayNumber(profile.roles, "cap")
            if (resSort && resSort.length > 0) {
              if (resSort[0].cap === Constant.Role_user.LD_CV_BO) {
                  this.setState({capthamquyen: Constant.Select_Cap[0].id})
              } else if (resSort[0].cap === Constant.Role_user.LD_CV_SO) {
                this.setState({capthamquyen: Constant.Select_Cap[2].id, madvhc: madvhc_byuser})
              } else {
                this.setState({capthamquyen: Constant.Select_Cap[0].id})
              }
            }
          }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.data !== prevProps.data) {
            {
                this.setState({
                    DL: this.props.data
                })
            }
        }
    };

    getYears = () => {
        var c_year = new Date().getFullYear(); var years = []; var nam = [];
        for (var i = c_year + 20; i >= 1975; i--) { years.push({ year: i }) }
        for (var i = c_year; i >= 1975; i--) { nam.push({ year: i }) }
        this.setState({ nam: nam, years: years, selectedFromYear: c_year - 14, selectedToYear: c_year })
    }
    groupDataByKey = (data) => {
        var outData = []; var valuesOfKey = []
        data.map(item => {
            if (valuesOfKey.includes(item.time)) {
                outData.filter((dataItem, index) => {
                    if (dataItem.time === item.time) { outData[index][item.id] = item.value }
                })
            } else {
                if (item.time) {
                    var outDataItem = { time: item.time }; outDataItem[item.id] = item.value;
                    outData.push(outDataItem); valuesOfKey.push(item.time)
                }
            }
        })
        return outData
    }
    ThongKe = () => {
        new Api_tnn().statistic({
            token: this.props.user.access_token,
            madvhc: this.state.madvhc,
            from: this.state.selectedFromYear + "-01-01",
            to: this.state.selectedToYear + "-12-31",
            chart: "thay_doi",
            obj: "giay_phep",
            group: "nhom_giay_phep",
            phase: "theo_thoi_gian",
            period: "nam",
            capnguoidung: this.props.capnguoidung,
            capthamquyen: this.state.capthamquyen
        })
            .then((result) => {
                if (result) {
                    this.setState({
                        DL: this.groupDataByKey(result)
                    })
                } else { messages.error("Lấy dữ liệu lỗi") }
            })
            .catch((err) => { messages.error("Lấy dữ liệu lỗi") })
    }
    XuatFilePDF = () => {
        const input = document.getElementById('chart-to-export-pdf-bieudo-cot');
        helper.exportPdf(input, 'landscape', "BieuDo.pdf")
    }

    render() {
        const { data_tinh, years, nam, selectedFromYear, selectedToYear, madvhc, DL, capthamquyen } = this.state
        const { functions, history, madvhc_byuser, profile } = this.props
        let resSort = helper.sortArrayNumber(profile.roles, "cap")
        return (
            <div className="box-char-css" >
                <div className='box-chart-title'>{this.props.title}</div>
                <Row type='flex'>
                    <Col style={{ marginLeft: '10px' }}>
                        <Select mandatory={false} label={typo.cscore_tnn_chon_cap}
                            placeholder={typo.cscore_tnn_chon_cap}
                            onChange={value => {this.setState({ capthamquyen: value }) }}
                            dataSource={resSort[0].cap === Constant.Role_user.LD_CV_SO ? Constant.Select_Cap.filter(x => x.id !== 0) : Constant.Select_Cap} 
                            name="name" name_value="id" width="100px" 
                            value={!helper.isNullOrEmptyItems(capthamquyen) ? capthamquyen : undefined}
                            />
                    </Col>
                    <Col style={{ marginLeft: '10px' }}>
                        <Select mandatory={false}
                            label={typo.cscore_tnn_from}
                            onChange={value => this.setState({ selectedFromYear: value })}
                            dataSource={nam} name="year" name_value="year" width="100px" defaultValue={selectedFromYear} value={selectedFromYear} />
                    </Col>
                    <Col style={{ marginLeft: '10px' }}>
                        <Select mandatory={false} label={typo.cscore_tnn_to}
                            onChange={value => this.setState({ selectedToYear: value })}
                            dataSource={years} name="year" name_value="year" width="100px" defaultValue={selectedToYear} value={selectedToYear} />
                    </Col>
                    {madvhc_byuser === "1000" &&
                        <Col style={{ marginLeft: '10px' }}>
                            <Select
                                mandatory={false}
                                modeSelect="multiple"
                                placeholder={helper.genSelectPL(typo.cscore_tnn_province_placeholder)}
                                label={typo.cscore_tnn_province}
                                onChange={(value) => {
                                    this.setState({
                                        madvhc: value
                                    })
                                }}
                                dataSource={data_tinh}
                                width="300px"
                                name="tenDVHC"
                                name_value="id"
                                defaultValue={madvhc ? madvhc : undefined}
                                allowClear={true}
                            />
                        </Col>}
                    <Col style={{ marginTop: '15px', marginLeft: '10px' }}>
                        <Button onClick={this.ThongKe} className='chart-btn-submit'>
                            <Icon type='bar-chart'></Icon>
                            {typo.cscore_tnn_statistic}
                        </Button>
                    </Col>
                    <Col style={{ marginTop: '15px', marginLeft: '10px' }}>
                        <Button onClick={() => this.XuatFilePDF()} type="pdf" className='chart-btn-submit'>
                            <Icon type='file-pdf'></Icon>
                            {typo.cscore_tnn_exportPdf}
                        </Button>
                    </Col>
                </Row>

                <div className='box-chart-content' id='chart-to-export-pdf-bieudo-cot'>
                    {DL &&
                        <BarChart capthamquyen={capthamquyen} data={DL} hiddenLegends={false} history={history} MADVHC={this.state.madvhc} functions={functions}
                        />}

                </div>
            </div>
        )
    }
};


const mapPropsToState = (state) => ({
    user: state.oidc.user,
    functions: state.functions,
    tinhs: state.commonReducer.dvhcTinhs.data,
    profile: state.commonReducer.profile.data,
});
export default connect(mapPropsToState)(BoxChart)