

import L from 'leaflet';
import { useMap, useMapEvents } from 'react-leaflet'
import Constant from "../constants/constant"

const Geocode_gg = ({ }) => {
  const mapClear = useMap()
    var layerGroup = L.layerGroup().addTo(mapClear);
  const map = useMapEvents({
    click: (evt) => {
      layerGroup.clearLayers();
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${Constant.KEY_GG_IF}&address=${evt.latlng.lat},${evt.latlng.lng}`)
        .then(response => response.json())
        .then(data => {
          if (data && data.results && data.results.length > 0) {
            L.marker(evt.latlng,).addTo(layerGroup).bindPopup(data.results[0].formatted_address).openPopup()
          }
        }).catch(error => {

        });
    }
  })
  return null
}

export default Geocode_gg