import {  notification } from "antd";

const DURATION = 2;

const openNotificationWithIcon = (type, lable, content) => {
  notification[type]({
    message: lable,
    description: content,
    placement: 'bottomRight'
  });
};

const Message = {
  
  success: (content,cb, duration = DURATION) =>
  openNotificationWithIcon('success', content),
  warning: (content, cb, duration = DURATION) =>
  openNotificationWithIcon('warning', content),
  error: (content, cb, duration = DURATION) =>
  openNotificationWithIcon('error', content),
  info: (content, cb, duration = DURATION) =>
  openNotificationWithIcon('info', content),
  loading: (content, cb, duration = DURATION) =>
  openNotificationWithIcon('loading', content),
};

export default Message;
