import React from 'react'
import {Table} from 'antd'

import styles from './style.module.scss'
import {MESSAGE} from "../../../constants/dictionaries";

export default function ({
                           size = "middle",
                           ...rest
                         }) {
  return (
    <div className="div-table-detail">
        <Table
          bordered={false}
          locale={{ emptyText: MESSAGE.cscore_tnn_THERE_IS_NO_DATA }}
          size={size}
          {...rest}
        />
      </div>
  )
}