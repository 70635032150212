import React from 'react'
import { Col, Tabs, Row, Radio } from "antd";
import { FastField } from "formik";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from "styled-components";
import FilterWrapper from "../../../../components/Common/FilterWrapper";
import Constant from "../../../../constants/constant";
import Api from '../../../../api';
import Select from "../../../../components/Common/Select";
import typo, { MESSAGE } from "../../../../constants/dictionaries";
import message from "../../../../components/Messages";
import helper from "../../../../helper/helper";
import SearchInput from "../../../../components/Common/Input/SearchInput";
import Detail from "../../../ThongTinTaiNguyenNuoc/NguonNuocNoiTinh/detail"
import ItemInfo from "./ItemInfo/tb.itemInfo"
import ItemInfoLVS from "./ItemInfo/tb.itemInfolvs"
import Table from "../../../../components/Table";
import Api_tnn from '../../../../api/api.tnn';
import Api_dms from '../../../../api/api.dms';
import Api_spatial from '../../../../api/api.spatial';

import columns from "../../TraCuuTNN/Colums/CTThuocSongList.column";
import columnsDXT from "../../TraCuuTNN/Colums/DXTThuocSongList.column";
import columnsGieng from "../../TraCuuTNN/Colums/GiengList.column";

import { GeoJSON } from 'react-leaflet'
import Helper from '../../../../helper/helper';
const { TabPane } = Tabs;

let timeout;
let currentValue;

function fetch(value, callback, user) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    if (value === "null") {
        callback([]);
    } else {
        function fake() {
            new Api_spatial().GetDSSong({ token: user.access_token, strSearch: currentValue })
                .then(res => {
                    if (currentValue === value) {
                        callback(res);
                    }
                })
                .catch((err) => message.error(err.message))
        }
        timeout = setTimeout(fake, 1000);
    }
}

const ControllerCheckbox = styled.div`
  margin-bottom: 15px;
`;
class SearchInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInfo: {
                song: undefined,
                luuVucSong: undefined,
            },
            GeoJson_data: {
                data: null,
                key: null
            },
            dataSong: [],
            dataLuuVucSong: [],
            dataTTSong: [],
            dataThuocSong: undefined,
            idsong_TT: undefined,
            nameLVS: undefined,
            selectedItemId: undefined,
            selectedItemXtId: undefined,
            selectedItemIdG: undefined,
        }
    }

    componentDidMount() {
        const { url_clickInfo } = this.props
        if (url_clickInfo) {
            this.infoSong(url_clickInfo)
        }
        this.GetLVS_SC()

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const { url_clickInfo } = this.props
        if (prevProps.url_clickInfo !== url_clickInfo) {
            this.infoSong(url_clickInfo)
        }
    }
    GetApiNNNTs = (strSearch) => {
        new Api_tnn()
            .GetDM_NguonNuocNoiTinhs({
                token: this.props.user.access_token,
                strSearch: strSearch,
                page: 0,
                size: 0,
            })
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.setState({ idsong_TT: res.data[0].id })
                }
            }).catch(err => {
                message.error(err.message)
            });
    };

    GetDM_NguonNuocNoiTinhByMa = (masong) => {
        return new Api_tnn()
            .GetDM_NguonNuocNoiTinhByMa({
                token: this.props.user.access_token,
                masong: masong,
            })
            .then((res) => {
                res && this.setState({ idsong_TT: res.id })
            }).catch(err => {
                message.error(err.message)
            });
    };

    GetDM_SongToanQuoc_maSong = (masong) => {
        return new Api_tnn()
            .GetDM_SongToanQuoc_maSong({
                token: this.props.user.access_token,
                masong: masong,
            })
            .then((res) => {
                res && this.setState({ idsong_TT: res.id })
            }).catch(err => {
                message.error(err.message)
            });
    };

    GetLVS_SC = () => {
        new Api_spatial()
            .GetLVS_SC({
                token: this.props.user.access_token,
            })
            .then(res => {
                if (res && res.length > 0) {
                    this.setState({
                        dataLuuVucSong: res.filter(x => x.tensong !== null)
                    });
                }
            }).catch(err => {
                // message.error(err.message)
            });
    };

    GetApiSpatialSong = (idsong) => {
        new Api_spatial()
            .GetSpatialSong({
                token: this.props.user.access_token,
                idsong: idsong,
            })
            .then(res => {
                const a = JSON.parse(res.result)
                const newGeom = { newGeom: (delete a.geometry['crs'], a) };
                const Json_newGeom = { ...newGeom.newGeom }
                const key = JSON.stringify(Json_newGeom)
                this.setState({
                    GeoJson_data: {
                        data: Json_newGeom,
                        key: key
                    }
                })
                {
                    var arr = Json_newGeom.geometry.coordinates[0]
                    var minX, maxX, minY, maxY;
                    for (var i = 0; i < arr.length; i++) {
                        minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
                        maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
                        minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
                        maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
                    }
                    var latlong = [(minY + maxY) / 2, (minX + maxX) / 2]
                    this.props.zoomOut(latlong)
                }
            }).catch(err => {
                // message.error(err.message)
            });
    };

    GetSpatialThuocSong = (idsong) => {
        new Api_spatial()
            .GetSpatialThuocSong({
                token: this.props.user.access_token,
                idsong: idsong,
            })
            .then(res => {
                this.setState({
                    dataThuocSong: res
                });
                this.getToaDo_CT(res)
            }).catch(err => {
                // message.error(err.message)
            });
    };

    GetSpatialThuocLVS = (idsong) => {
        new Api_spatial()
            .GetSpatialThuocLVS({
                token: this.props.user.access_token,
                idsong: idsong,
            })
            .then(res => {
                this.setState({
                    dataThuocSong: res
                });
                this.getToaDo_CT(res)
            }).catch(err => {
                // message.error(err.message)
            });
    };

    GetCT_GiayPhepXTByIDCongTrinh = (idcongtrinh, loaicongtrinh) => {
        return new Api_tnn()
            .GetCT_GiayPhepXTByIDCongTrinh({
                token: this.props.user.access_token,
                idcongtrinh: idcongtrinh,
                loaicongtrinh: loaicongtrinh,
            })
            .then((res) => {
                if (res.idFile !== undefined && res.tenTaiLieu !== undefined && res.tenTaiLieu !== null) {
                    this.downFile(res)
                } else if (res.idFile === undefined || res.tenTaiLieu === undefined || res.tenTaiLieu === null) {
                    message.warning(MESSAGE.cscore_tnn_THIEU_TAI_LIEU)
                }
            }).catch(err => {
                message.error(err.message)
            });
    };
    getToaDo_CT = (data) => {
        const { data_congtrinh } = this.props
        let data_CT = []
        if (data && data.tnn_diemxathai_intersect && data.tnn_diemxathai_intersect.length > 0) {
            data.tnn_diemxathai_intersect.map((value, index) => {
                if (value.idcongtrinh) {
                    data_CT.push({
                        idcongtrinh: value.idcongtrinh,
                        nhomcongtrinh: Constant.DICT_NHOM_CT.XT,
                        loaicongtrinh: Constant.DICT_LOAI_CT.XT
                    })
                }
            })
        }
        if (data && data.tnn_congtrinh_diem_intersect && data.tnn_congtrinh_diem_intersect.length > 0) {
            data.tnn_congtrinh_diem_intersect.map((value, index) => {
                if (value.idcongtrinh) {
                    data_CT.push({
                        idcongtrinh: value.idcongtrinh,
                        nhomcongtrinh: value.nhomcongtrinh,
                        loaicongtrinh: value.loaicongtrinh
                    })
                }
            })
        }
        data_congtrinh(data_CT)
    }

    showFile = (value) => {
        this.GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, value.loaicongtrinh)
    }
    showFileDXT = (value) => {
        this.GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, 0)
    }
    showFileG = (value) => {
        this.GetCT_GiayPhepXTByIDCongTrinh(value.idcongtrinh, 2)
    }
    downFile = (data) => {
        new Api_dms().DownloadFile({
            token: this.props.user.access_token,
            data: { oid: data.idFile, filename: data.tenTaiLieu }
        })
    }

    SelectSearchSong = value => {
        const { user } = this.props;
        if (value && value !== "" && value !== undefined) {
            fetch(
                value,
                res => this.setState({ dataSong: res }),
                user
            );
        }
    };

    onSelectSong = (value, name) => {
        this.setState({
            dataThuocSong: undefined,
            idsong_TT: undefined
        })
        this.GetApiNNNTs(name)
        this.GetApiSpatialSong(value)
        this.GetSpatialThuocSong(value)
    }
    onSelectLuuVucSong = (value, name) => {
        if (value) {
            this.setState({
                nameLVS: name.props.children,
                dataTTSong: undefined,
                dataThuocSong: undefined,
                idsong_TT: undefined
            })
            this.GetSpatialThuocLVS(value)
        }
    }

    infoSong = (item) => {
        {
            Number(Constant.CAP_TRIEN_KHAI === 1) ?
                item && item.length > 0 && item[0].manguonuoc && this.GetDM_SongToanQuoc_maSong(item[0].manguonuoc)
                :
                item && item.length > 0 && item[0].manguonuoc && this.GetDM_NguonNuocNoiTinhByMa(item[0].manguonuoc)
        }
        item && item.length > 0 && this.setState({
            dataThuocSong: {
                tnn_congtrinh_diem_intersect: item.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.NM),
                tnn_diemxathai_intersect: item.filter(value => value.nhomcongtrinh === Constant.DICT_NHOM_CT.XT),
                tnn_congtrinh_gieng_intersect: [],
                infoSong: true
            },
            displayTab: "block",
            displayTable: "block",
            dataTTSong: {
                idsong_thuoctinh: item[0].gid_song
            }
        })
    }

    render() {
        const { filterInfo, dataSong, dataLuuVucSong, dataTTSong, idsong_TT, nameLVS,
            dataThuocSong, selectedItemId, selectedItemXtId, selectedItemIdG
        } = this.state
        const { dulieu_congtrinh } = this.props
        return (
            <ControllerCheckbox>
                <Row type="flex" justify="start" style={{ paddingTop: "10px" }}>
                    <Col span={20}>
                        <FilterWrapper handleApplyFilter={this.handleFilterParamsChange} initialValues={filterInfo} hideButton={true} styleButton={{ textAlign: "left" }}>
                            {({ handleChange, setFieldValue, values, handleSubmit }) => (
                                <Col span={20}>
                                    {Constant.CAP_TRIEN_KHAI === 1 &&
                                        <div>
                                            <Row style={{ marginLeft: '25px' }}>
                                                <Col>
                                                    <Select
                                                        dataSource={dataSong}
                                                        defaultValue={values.song ? Number(values.song) : undefined}
                                                        onSearch={this.SelectSearchSong}
                                                        onSelect={(value, name) => {
                                                            setFieldValue("song", value);
                                                            setFieldValue("luuVucSong", undefined);
                                                            this.onSelectSong(value, name.props.children)
                                                        }}
                                                        placeholder={typo.cscore_tnn_pladceholder_Song}
                                                        mandatory={false}
                                                        width="460px"
                                                        name="tensong"
                                                        name_value="gid"
                                                        className='select-right'
                                                        allowClear={true}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        defaultActiveFirstOption={false}
                                                        notFoundContent={null}
                                                        key={values.song}
                                                    />

                                                </Col>
                                            </Row>
                                            <div className="mt-20" />
                                            <Row type="flex" style={{ marginLeft: '25px' }}>
                                                <Select
                                                    dataSource={dataLuuVucSong}
                                                    defaultValue={values.luuVucSong ? Number(values.luuVucSong) : undefined}
                                                    onChange={(value, name) => {
                                                        setFieldValue("luuVucSong", value);
                                                        setFieldValue("song", undefined);
                                                        this.onSelectLuuVucSong(value, name)
                                                    }}
                                                    placeholder={typo.cscore_tnn_pladceholder_LuuVuc}
                                                    mandatory={false}
                                                    width="450px"
                                                    name="tensong"
                                                    name_value="gid"
                                                    key={values.luuVucSong}
                                                    allowClear={true}
                                                />
                                            </Row>
                                        </div>}
                                    <div className="mt-10" />
                                    <Row style={{ marginLeft: '25px' }}>
                                        <Col style={{ width: "450px" }}>
                                            <Tabs type="card">
                                                <TabPane tab="Thông tin sông" key="7">
                                                    {dataTTSong ?
                                                        <div className='div-table-collapse'>
                                                            {!helper.isNullOrUndefined(dataTTSong.idsong_thuoctinh) && idsong_TT ?
                                                                <Detail id={idsong_TT} bando={true} />
                                                                :
                                                                <ItemInfo item={dataTTSong} />
                                                            }
                                                        </div>
                                                        :
                                                        <div className='div-table-collapse'>
                                                            <ItemInfoLVS item={nameLVS} />
                                                        </div>
                                                    }
                                                </TabPane>
                                                <TabPane tab="CT-Nước mặt" key="1">
                                                    {dataThuocSong &&
                                                        <Table
                                                            columns={columns({
                                                                showFileModal: this.showFile
                                                            })}
                                                            selectedRowId={selectedItemId}
                                                            onRowClick={(record) => {
                                                                dulieu_congtrinh(record.idcongtrinh, record.loaicongtrinh)
                                                                this.setState({
                                                                    selectedItemId: record.idcongtrinh,
                                                                    loaiCongTrinh: record.loaicongtrinh,
                                                                    selectedItemXtId: undefined
                                                                })
                                                            }
                                                            }
                                                            dataSource={dataThuocSong.tnn_congtrinh_diem_intersect ? dataThuocSong.tnn_congtrinh_diem_intersect.map((item, index) => ({ ...item, stt: index + 1 })) : undefined}
                                                            // // onChange={this.handleTableParamsChange}
                                                            pagination={false}
                                                            scroll={{ y: 540 }}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tab="CT-Xả thải" key="3">
                                                    {dataThuocSong &&
                                                        <Table
                                                            columns={columnsDXT({
                                                                showFileModal: this.showFileDXT
                                                            })}
                                                            selectedRowId={selectedItemXtId}
                                                            onRowClick={(record) => {
                                                                dulieu_congtrinh(record.idcongtrinh, 10)
                                                                this.setState({
                                                                    selectedItemXtId: record.idcongtrinh,
                                                                    loaiCongTrinh: record.loaicongtrinh,
                                                                    selectedItemId: undefined
                                                                })
                                                            }
                                                            }
                                                            dataSource={dataThuocSong.tnn_diemxathai_intersect ? dataThuocSong.tnn_diemxathai_intersect.map((item, index) => ({ ...item, stt: index + 1 })) : undefined}
                                                            // // onChange={this.handleTableParamsChange}
                                                            pagination={false}
                                                            scroll={{ y: 540 }}
                                                        />
                                                    }
                                                </TabPane>
                                                {Number(Constant.MA_TINH) !== 31 &&
                                                    <TabPane tab="CT-Nước dưới đất" key="4">
                                                        {dataThuocSong &&
                                                            <Table
                                                                columns={columnsGieng({
                                                                    showFileModal: this.showFileG
                                                                })}
                                                                selectedRowId={selectedItemIdG}
                                                                onRowClick={(record) => {
                                                                    dulieu_congtrinh(record.idcongtrinh, record.loaicongtrinh)
                                                                    this.setState({
                                                                        selectedItemIdG: record.gid
                                                                    })
                                                                }
                                                                }
                                                                dataSource={dataThuocSong.tnn_congtrinh_gieng_intersect ? dataThuocSong.tnn_congtrinh_gieng_intersect.map((item, index) => ({ ...item, stt: index + 1 })) : undefined}
                                                                // // onChange={this.handleTableParamsChange}
                                                                pagination={false}
                                                                scroll={{ y: 540 }}
                                                            />
                                                        }
                                                    </TabPane>}
                                            </Tabs>

                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </FilterWrapper>
                    </Col>
                </Row>
                {this.state.GeoJson_data.data != null &&
                    <GeoJSON ref="geojson" key={this.state.GeoJson_data.key} data={this.state.GeoJson_data.data} style={this.style} />
                }
            </ControllerCheckbox>
        )
    }
}

SearchInfo.propTypes = {
    dulieu_congtrinh: PropTypes.func.isRequired
};


const mapStateToProps = (state) => ({
    user: state.oidc.user
});

export default connect(mapStateToProps)(SearchInfo)