// common api

import Api from '../../api';
import Api_tnn from '../../api/api.tnn';

/**
 * @param {*} data 
 */
//DVHC
export const getTinhs = (user) => new Api().getTinhs({ token: user.access_token });
export const getHuyenByTinhs = (user, maTinh) => new Api().getHuyenByTinhs({ token: user.access_token, maTinh: maTinh });
export const getApiXaByHuyens = (user, maHuyen) => new Api().getXaByHuyens({ token: user.access_token, maHuyen: maHuyen });

//Profile
export const getProfile = (user) => new Api().Profile({ token: user.access_token });

//Token
export const getTokenGiamSat = (user) => new Api_tnn().GenToken({ token: user.access_token });
export const getTokenLGSP = (user) => new Api_tnn().GenToken_LGSP({ token: user.access_token });

//Cấu hình hết hạn giấy phép
export const getCauHinhGiayPhep = (user) => new Api().GetCauHinhs({ token: user.access_token });

//Loại công Trình
export const getCTLoaiCongTrinh = (user, nhomct) => new Api_tnn().getCTLoaiCongTrinh({ token: user.access_token, nhomct: nhomct });

//Kinh tuyến trục
export const Getdmkinhtuyentrucs = (user, cap) => new Api().Getdmkinhtuyentrucs({ token: user.access_token, cap: cap });

//Lưu vực sông
export const getLuuVucSong = (user) => new Api_tnn().GetDM_LVSongs({ token: user.access_token });

