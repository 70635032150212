import React, { useState } from "react";
import L from "leaflet";
import { Row, Icon, Tooltip, Col } from "antd";
import styled from "styled-components";
import Button from "../../../../components/Buttons";
import KiemTraToaDo from "./KiemTraToaDo";
import { useMap } from "react-leaflet";
import GeoJson_Layer from "../../../../components/BanDo/GeoJson_traCuu/GeoJson_Layer";
import GeoJson_Polygon from "../../../../components/BanDo/GeoJson_traCuu/GeoJson_Polygon_KTTD";
import DoKhoangCach from "./DoKhoangCach"
import LayToaDo from "./LayToaDo";
const Controllerbutton = styled.div`
  position: absolute;
  right: 60px;
  top: 0;
  background-color: #fff0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
`;
const ControllerCheckCoordinates = styled.div`
  position: absolute;
  right: 60px;
  top: 25px;
  background-color: #ffffffcf;
  padding: 10px;
  z-index: 1000;
`;

const ControllerButton_Custom = ({ displayToaDo, hideToaDo, displayLayToaDo, hideLayToaDo, user }) => {
    const divRef = React.useRef(null);
    const [ktt_do, setktt_do] = useState();
    const [ktt_phut, setktt_phut] = useState();
    const [muichieu, setmuichieu] = useState();
    const [toado, settoado] = useState(null);
    const [checkDiem, setcheckDiem] = useState(0);
    const [doKhoangCach, setdoKhoangCach] = useState('none');
    const map = useMap();
    function FlyToButton(latlong) {
        map.flyTo(latlong, 13);
    }
    function CheckCongTrinh(ktt_do, ktt_phut, muichieu, toado, check) {
        setcheckDiem(check)
        setktt_do(ktt_do);
        setktt_phut(ktt_phut);
        setmuichieu(muichieu);
        settoado(toado);
    }
    React.useEffect(() => {
        L.DomEvent.disableClickPropagation(divRef.current);
    });

    return (
        <div ref={divRef}>
            <Controllerbutton>
                <Row type="flex" justify="flex-end">
                    <Col>
                        <Tooltip placement="topRight" title="Lấy tọa độ theo vị trí">
                            <Button
                                type="primary"
                                onClick={() => hideLayToaDo()}
                                style={{ marginRight: 2 }}
                            >
                                <Icon type="environment" />
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip placement="topRight" title="Kiểm tra tọa độ">
                            <Button
                                type="primary"
                                onClick={() => hideToaDo()}
                                style={{ marginRight: 2 }}
                            >
                                <Icon type="plus-circle" />
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip placement="topRight" title="Đo khoảng cách">
                            <Button
                                type="primary"
                                onClick={() => { doKhoangCach === 'none' ? setdoKhoangCach('block') : setdoKhoangCach('none')}}
                                style={{ marginRight: 2 }}
                            >
                                <Icon type="drag" />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Controllerbutton>
            {displayLayToaDo === 'block' &&
                <ControllerCheckCoordinates>
                    <LayToaDo user={user}/>
                </ControllerCheckCoordinates>}
            {displayToaDo === 'block' &&
                <ControllerCheckCoordinates>
                    <KiemTraToaDo
                        user={user}
                        CheckCongTrinhDiem={(ktt_do, ktt_phut, muichieu, toado) =>
                            CheckCongTrinh(ktt_do, ktt_phut, muichieu, toado, 1)
                        }
                        CheckCongTrinhVung={(ktt_do, ktt_phut, muichieu, toado) =>
                            CheckCongTrinh(ktt_do, ktt_phut, muichieu, toado, 3)
                        }
                    />
                </ControllerCheckCoordinates>}
            {doKhoangCach === 'block' &&
                <ControllerCheckCoordinates>
                    <DoKhoangCach />
                </ControllerCheckCoordinates>}

            {ktt_do !== null && ktt_phut !== null && muichieu !== null && toado !== null && checkDiem === 1 && (
                <GeoJson_Layer
                    ktt_do={ktt_do}
                    ktt_phut={ktt_phut}
                    muichieu={muichieu}
                    toado={toado}
                    zoomOut={(latlong) => FlyToButton(latlong)}
                />
            )}
            {ktt_do !== null && ktt_phut !== null && muichieu !== null && toado !== null && toado.split(";").length > 2 && checkDiem === 3 && (
                <GeoJson_Polygon
                    ktt_do={ktt_do}
                    ktt_phut={ktt_phut}
                    muichieu={muichieu}
                    toado={toado}
                    zoomOut={(latlong) => FlyToButton(latlong)}
                />
            )}
        </div>
    );
};
export default ControllerButton_Custom;
