import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Row, Col, Modal, Icon, Typography } from 'antd'
import { FastField, Formik } from 'formik'


// Components
import Input from '../Common/Input'
import Button from '../Buttons'
import ValidatedText from "../Common/ValidatedText";
import DisplayFormikState from "../Table/DisplayFormikState";
import Api from '../../api';
import Message from "../../components/Messages";
import typo, { MESSAGE } from "../../constants/dictionaries";
import constants from "../../constants/constant"
import helper from "../../helper/helper"
import Api_tnn from "../../api/api.tnn";
import Api_dms from "../../api/api.dms";
import Api_spatial from "../../api/api.spatial";
const { Text } = Typography;
class DownloadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }

    downFile = (data) => {
        if (data === undefined || data === []) {
            Message.warning(MESSAGE.cscore_tnn_KHONG_CO_GIAY_PHEP)
        } else {
            new Api_dms().DownloadFile({
                token: this.props.user.access_token,
                data: { oid: data.idFile, filename: data.tenTaiLieu }
            })
        }
    }
    render() {
        const { user, isOpen, handleCancel, selectdFile } = this.props
        return (
            <Modal
                visible={isOpen}
                footer={false}
                onCancel={handleCancel}
                centered={true}
                width="600px"
            >
                {selectdFile.map((item, index) => {
                    return <div style={{ display: "flex" }}>
                        <Icon style={{ marginTop: "4px" }} type="paper-clip" />
                        <p style={{ marginLeft: "5px", cursor: "pointer" }} className="row-name" onClick={() => this.downFile(item)}>{item.tenTaiLieu}</p>
                    </div>
                })}
            </Modal>
        )
    };
}
DownloadFile.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    user: store.oidc.user
});

export default connect(mapStateToProps)(DownloadFile);

