/*
 *  @2019 CS-Core project.
 *
 */

const isDevelopment = process.env.NODE_ENV === "development";

const PROD_API_IDENTITY_URI =
  process.env["REACT_APP_API_IDENTITY_URI"] || "http://localhost:5000";

const PROD_API_CORE_URI =
  process.env["REACT_APP_API_CORE_URI"] || "http://localhost:8082/api/admin/";

const PROD_API_DMS_URI =
  process.env["REACT_APP_API_DMS_URI"] || "http://localhost:8082/api/dms/";

const PROD_API_SPATIAL_URI =
  process.env["REACT_APP_API_SPATIAL_URI"] || "http://localhost:8082/api/spatial/";

const PROD_API_TNN_URI =
  process.env["REACT_APP_API_TNN_URI"] || "http://localhost:8082/api/tnn/";

const PROD_APP_ADMIN_URI =
  process.env["REACT_APP_ADMIN_URI"] || "http://localhost:5100/";

const PROD_APP_DASHBOARD_URI =
  process.env["REACT_APP_DASHBOARD_URI"] || "http://localhost:5001/";

const PROD_APP_TNN_URI =
  process.env["REACT_APP_TNN_URI"] || "http://localhost:5300/";

const PROD_APP_GEO_URI =
  process.env["REACT_APP_API_GEO_URI"] || "http://10.151.130.225:8080/geoserver/wms";

const PROD_APP_TNN_REDIRECT_URL =
  process.env["REACT_APP_TNN_REDIRECT_URI"] || "http://localhost:5300/callback";

const PROD_APP_TNN_SILENT_REDIRECT_URL =
  process.env["REACT_APP_TNN_SILENT_REDIRECT_URI"] || "http://localhost:5300/silentRenew";

export const API_IDENTITY_URI = isDevelopment
  ? "http://localhost:5000"
  : PROD_API_IDENTITY_URI;

export const API_CORE_URI = isDevelopment
  ? "http://localhost:8082/api/admin/"
  : PROD_API_CORE_URI;

export const API_TNN_URI = isDevelopment
  ? "http://localhost:8082/api/tnn/"
  : PROD_API_TNN_URI;

export const API_DMS_URI = isDevelopment
  ? "http://localhost:8082/api/dms/"
  : PROD_API_DMS_URI;

export const API_SPATIAL_URI = isDevelopment
  ? "http://localhost:8082/api/spatial/"
  : PROD_API_SPATIAL_URI;

export const APP_ADMIN_URI = isDevelopment
  ? "http://localhost:5100/"
  : PROD_APP_ADMIN_URI;

export const APP_DASHBOARD_URI = isDevelopment
  ? "http://localhost:5001/"
  : PROD_APP_DASHBOARD_URI;

export const APP_TNN_URI = isDevelopment
  ? "http://localhost:5300/"
  : PROD_APP_TNN_URI;

export const APP_GEO_URI = isDevelopment
  ? "http://10.151.130.225:8080/geoserver/wms"
  : PROD_APP_GEO_URI;

export const APP_TNN_REDIRECT_URL = isDevelopment
  ? "http://localhost:5300/callback"
  : PROD_APP_TNN_REDIRECT_URL;

export const APP_TNN_SILENT_REDIRECT_URL = isDevelopment
  ? "http://localhost:5300/silentRenew"
  : PROD_APP_TNN_SILENT_REDIRECT_URL;

export const GISPOTTAL = "https://gstnn.monre.gov.vn/";
export const APP_NAME = "cscore_tnn_spa";